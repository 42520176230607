import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-lesson-note-popup',
  templateUrl: './lesson-note-popup.component.html',
  styleUrls: ['./lesson-note-popup.component.scss']
})
export class LessonNotePopupComponent implements OnInit {

  description: string = "";
  noteForm: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<LessonNotePopupComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    if(this.data.description != null)
      this.description = this.data.description;
  }

  closePopUp() {
    var note =  this.noteForm.value.trim();

    if(note != null && note.length > 0) {
      if(this.description.length > 0)
        this.description += "\r\nNotes: " + note;
      else
        this.description += "Notes: " + note;
    }

    this.dialogRef.close(this.description);
  }

  onNoClick() {
    this.dialogRef.close();
  }
}

import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { environment } from 'src/environments/environment';

export const routes: Routes = [
    { path: 'classrooms', loadComponent: () => import('./pages/classroom/classroom.component').then(m => m.ClassroomComponent), canActivate: [AuthGuard] },
    { path: 'classroom/:id', loadComponent: () => import('./pages/classroom-id/classroom-id.component').then(m => m.ClassroomIdComponent), canActivate: [AuthGuard] },
    { path: 'lessons', loadComponent: () => import('./pages/lessons/lessons.component').then(m => m.LessonsComponent), canActivate: [AuthGuard] },
    { path: 'profile', loadComponent: () => import('./pages/profile-user/profile-user.component').then(m => m.ProfileUserComponent), canActivate: [AuthGuard] },
    { path: 'users', loadComponent: () => import('./pages/user-list/user-list.component').then(m => m.UserListComponent), canActivate: [AuthGuard] },
    { path: 'calendar', loadComponent: () => import('./pages/calendar/calendar.component').then(m => m.CalendarComponent), canActivate: [AuthGuard] },
    { path: 'calendar/:id', loadComponent: () => import('./pages/calendar-user-list/calendar-user-list.component').then(m => m.CalendarUserListComponent), canActivate: [AuthGuard] },
    { path: 'registry', loadComponent: () => import('./pages/registry/registry.component').then(m => m.RegistryComponent), canActivate: [AuthGuard] },
    { path: 'classroomstatus/:id', loadComponent: () => import('./pages/classroom-status/classroom-status.component').then(m => m.ClassroomStatusComponent), canActivate: [AuthGuard] },
    { path: 'userstatus/:id', loadComponent: () => import('./pages/user-status/user-status.component').then(m => m.UserStatusComponent), canActivate: [AuthGuard] },
    { path: 'lessonvideo', loadComponent: () => import('./pages/lesson-video/lesson-video.component').then(m => m.LessonVideoComponent), canActivate: [AuthGuard] },
    { path: 'changelog', loadComponent: () => import('./pages/changelog/changelog.component').then(m => m.ChangelogComponent), canActivate: [AuthGuard] },
    { path: 'courses', loadComponent: () => import('./pages/course/course-list/course-list.component').then(m => m.CourseListComponent), canActivate: [AuthGuard] },
    { path: 'course/:id', loadComponent: () => import('./pages/course/course-profile/course-profile.component').then(m => m.CourseProfileComponent), canActivate: [AuthGuard] },
    { path: 'course-content/:id', loadComponent: () => import('./pages/course/course-video/course-video.component').then(m => m.CourseVideoComponent), canActivate: [AuthGuard] },
    { path: 'coursetracker/:id', loadComponent: () => import('./pages/course/course-video/course-view/course-view.component').then(m => m.CourseViewComponent), canActivate: [AuthGuard] },
    { path: 'course-analytics/:id', loadComponent: () => import('./pages/course/course-analytics/course-analytics.component').then(m => m.CourseAnalyticsComponent), canActivate: [AuthGuard] },
    { path: 'authorprofile/:id', loadComponent: () => import('./pages/authorprofile/authorprofile.component').then(m => m.AuthorprofileComponent), canActivate: [AuthGuard] },
    { path: 'translations', loadComponent: () => import('./pages/translations/translations.component').then(m => m.TranslationsComponent), canActivate: [AuthGuard] },
    { path: 'dashboard', loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent), canActivate: [AuthGuard] },
    { path: 'dashboard-customers', loadComponent: () => import('./pages/dashboard/dashboard-customers/dashboard-customers.component').then(m => m.DashboardCustomersComponent), canActivate: [AuthGuard] },
    { path: 'streamings', loadComponent: () => import('./pages/streaming-info/streaming-info.component').then(m => m.StreamingInfoComponent), canActivate: [AuthGuard] },
    { path: 'masters', loadComponent: () => import('./pages/master/master-list/master-list.component').then(m => m.MasterListComponent), canActivate: [AuthGuard] },
    { path: 'master/:id', loadComponent: () => import('./pages/master/master-profile/master-profile.component').then(m => m.MasterProfileComponent), canActivate: [AuthGuard] },
    { path: 'aula', loadComponent: () => import('./pages/stanza/stanza.component').then(m => m.StanzaComponent), canActivate: [AuthGuard] },
    { path: 'showcase', loadComponent: () => import('./pages/showcase/showcase.component').then(m => m.ShowcaseComponent), canActivate: [AuthGuard] },
    { path: 'wall', loadComponent: () => import('./wall/wall.component').then(m => m.WallComponent), canActivate: [AuthGuard] },
    { path: 'wall-page', loadComponent: () => import('./wall/wall-page/wall-page.component').then(m => m.WallPageComponent), canActivate: [AuthGuard] },
    { path: 'subscription', loadComponent: () => import('./ecommerce/subscription-home.component').then(m => m.SubscriptionHomeComponent), canActivate: [AuthGuard] },
    { path: 'subscription-seller', loadComponent: () => import('./ecommerce/subscription-seller/subscription-seller.component').then(m => m.SubscriptionSellerComponent), canActivate: [AuthGuard] },
    { path: 'subscription-buyer', loadComponent: () => import('./ecommerce/subscription-buyer/subscription-buyer.component').then(m => m.SubscriptionBuyerComponent), canActivate: [AuthGuard] },
    { path: 'forgot', loadComponent: () => import('./pages/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent) },
    { path: 'forgot/:token', loadComponent: () => import('./pages/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent) },
    { path: 'confirm/:type/:token', loadComponent: () => import('./pages/public-user-confirm/public-user-confirm.component').then(m => m.PublicUserConfirmComponent) },
    { path: 'cart', loadComponent: () => import('./ecommerce/cart-page/cart-page.component').then(m => m.CartPageComponent) },
    { path: 'success', loadComponent: () => import('./ecommerce/success/success.component').then(m => m.SuccessComponent) },
    { path: 'failed', loadComponent: () => import('./ecommerce/failed/failed.component').then(m => m.FailedComponent) },
    { path: 'purchased', loadComponent: () => import('./ecommerce/purchased/purchased.component').then(m => m.PurchasedComponent) },
    { path: 'orders', loadComponent: () => import('./ecommerce/orders/orders.component').then(m => m.OrdersComponent) , canActivate: [AuthGuard] },
    { path: 'onboard', loadComponent: () => import('./ecommerce/start-onboarding/start-onboarding.component').then(m => m.StartOnboardingComponent) , canActivate: [AuthGuard] },
    { path: 'reauthenticate', loadComponent: () => import('./ecommerce/reauthenticate/reauthenticate.component').then(m => m.ReauthenticateComponent) },
    { path: 'returnstripe', loadComponent: () => import('./ecommerce/returnstripe/returnstripe.component').then(m => m.ReturnstripeComponent) },
    { path: 'survey-creator', loadComponent: () => import('./survey/survey-pages/survey-creator-page/survey-creator-page.component').then(m => m.SurveyCreatorPageComponent), canActivate: [AuthGuard] },
    { path: 'survey-creator/:id', loadComponent: () => import('./survey/survey-pages/survey-creator-page/survey-creator-page.component').then(m => m.SurveyCreatorPageComponent), canActivate: [AuthGuard] },
    { path: 'surveys-template', loadComponent: () => import('./survey/survey-pages/survey-template-list-page/survey-template-list-page.component').then(m => m.SurveyTemplateListPageComponent), canActivate: [AuthGuard] },
    { path: 'survey/:id', loadComponent: () => import('./survey/survey-pages/survey-page/survey-page.component').then(m => m.SurveyPageComponent), canActivate: [AuthGuard] },
    { path: 'survey-result/:id', loadComponent: () => import('./survey/survey-pages/survey-result-page/survey-result-page.component').then(m => m.SurveyResultPageComponent), canActivate: [AuthGuard] },
    { path: 'survey-results/:id', loadComponent: () => import('./survey/survey-pages/survey-results-page/survey-results-page.component').then(m => m.SurveyResultsPageComponent), canActivate: [AuthGuard] },
    { path: 'survey-analytics/:id', loadComponent: () => import('./survey/survey-pages/survey-analytics/survey-analytics.component').then(m => m.SurveyAnalyticsComponent), canActivate: [AuthGuard] },
    { path: 'deletion/:id', loadComponent: () => import('./pages/data-deletion/data-deletion.component').then(m => m.DataDeletionComponent) },
    { path: 'package/:id', loadComponent: () => import('./pages/package/package.component').then(m => m.PackageComponent), canActivate: [AuthGuard] },
    { path: 'warnings', loadComponent: () => import('./pages/warning-list/warning-list.component').then(m => m.WarningListComponent), canActivate: [AuthGuard] },
    { path: 'smily-recordings/:id', loadComponent: () => import('./pages/smily-recording/smily-recording.component').then(m => m.SmilyRecordingComponent), canActivate: [AuthGuard] },
    { path: 'smily-dashboard/:id', loadComponent: () => import('./pages/smily-dashboard/smily-dashboard.component').then(m => m.SmilyDashboardComponent), canActivate: [AuthGuard] },
    { path: 'login', loadComponent: () => import('./pages/logins/login/login.component').then(m => m.LoginComponent) },
    { path: 'level/:key', loadComponent: () => import('./pages/subscription-level/subscription-level.component').then(m => m.SubscriptionLevelComponent) },
    { path: 'eduplan-list', loadComponent: () => import('./pages/eduplan-list/eduplan-list.component').then(m => m.EduplanListComponent), canActivate: [AuthGuard] },
    { path: 'eduplans', loadComponent: () => import('./pages/eduplan-showcase/eduplan-showcase.component').then(m => m.EduplanShowcaseComponent), canActivate: [AuthGuard] },
    { path: 'content-scorm-view/:id', loadComponent: () => import('./pages/course/course-video/content-scorm-view/content-scorm-view.component').then(m => m.ContentScormViewComponent), canActivate: [AuthGuard] },
    { path: 'conference/:id', loadComponent: () => import('./pages/conference-session/conference-session.component').then(m => m.ConferenceSessionComponent), canActivate: [AuthGuard] },
    { path: 'conference-report/:id', loadComponent: () => import('./pages/conferences/conference-report/conference-report.component').then(m => m.ConferenceReportComponent), canActivate: [AuthGuard] },
    { path: 'conferences', loadComponent: () => import('./pages/conferences/conferences.component').then(m => m.ConferencesComponent), canActivate: [AuthGuard]},
    { path: 'customer-confirm/:type/:id/:token', loadComponent: () => import('./pages/customer-confirm/customer-confirm.component').then(m => m.CustomerConfirmComponent) },
    { path: 'login-dt', loadComponent: () => import('./pages/logins/dentaltrey-login/dentaltrey-login.component').then(m => m.DentaltreyLoginComponent) },
    { path: 'webinar/:id', loadComponent: () => import('./pages/webinar-session/webinar-session.component').then(m => m.WebinarSessionComponent), canActivate: [AuthGuard] },
    { path: 'webinar-showcase/:id', loadComponent: () => import('./pages/webinars/webinar-showcase/webinar-showcase.component').then(m => m.WebinarShowcaseComponent), canActivate: [AuthGuard] },
    { path: 'webinars', loadComponent: () => import('./pages/webinars/webinar-list/webinar-list.component').then(m => m.WebinarListComponent), canActivate: [AuthGuard] },
    { path: 'login-viqirobot', loadComponent: () => import('./pages/logins/viqirobot-login/viqirobot-login.component').then(m => m.ViqirobotLoginComponent) },
    { path: 'payments', loadComponent: () => import('./ecommerce/payments/payments.component').then(m => m.PaymentsComponent), canActivate: [AuthGuard] },
    { path: 'webinar-customer', loadComponent: () => import('./pages/webinars/webinar-customer/webinar-customer.component').then(m => m.WebinarCustomerComponent), canActivate: [AuthGuard] },
    { path: 'login-sunlight', loadComponent: () => import('./pages/logins/sunlight-login/sunlight-login.component').then(m => m.SunlightLoginComponent)},
    { path: 'login-smartcabinet360', loadComponent: () => import('./pages/logins/smartcabinet360-login/smartcabinet360-login.component').then(m => m.Smartcabinet360LoginComponent) },
    { path: 'login-fitxp', loadComponent: () => import('./pages/logins/fitxp-login/fitxp-login.component').then(m => m.FitxpLoginComponent) },
    { path: 'login-gammadonna', loadComponent: () => import('./pages/logins/gammadonna-login/gammadonna-login.component').then(m => m.GammadonnaLoginComponent) },
    { path: 'mobile-app-link/:type/:id', loadComponent: () => import('./components/mobile-app-link/mobile-app-link.component').then(m => m.MobileAppLinkComponent) },
    { path: 'login-hro', loadComponent: () => import('./pages/logins/hroconsulting-login/hroconsulting-login.component').then(m => m.HroconsultingLoginComponent) },
    { path: 'login-hf', loadComponent: () => import('./pages/logins/hufriedy-login/hufriedy-login.component').then(m => m.HufriedyLoginComponent) },
    { path: 'login-aa', loadComponent: () => import('./pages/logins/aixpacademy-login/aixpacademy-login.component').then(m => m.AixpacademyLoginComponent) },
    { path: 'login-lcr', loadComponent: () => import('./pages/logins/licar-login/licar-login.component').then(m => m.LicarLoginComponent) },
    { path: 'login-idn', loadComponent: () => import('./pages/logins/ideandum-login/ideandum-login.component').then(m => m.IdeandumLoginComponent) },
    { path: 'login-cmt', loadComponent: () => import('./pages/logins/community-login/community-login.component').then(m => m.CommunityLoginComponent) },
    { path: 'masters-user', loadComponent: () => import('./pages/master/master-list-user/master-list-user.component').then(m => m.MasterListUserComponent), canActivate: [AuthGuard] },
    { path: 'lesson/:id', loadComponent: () => import('./pages/lesson-session/lesson-session.component').then(m => m.LessonSessionComponent), canActivate: [AuthGuard] },
    { path: 'user/:id', loadComponent: () => import('./pages/dashboard-user/dashboard-user.component').then(m => m.DashboardUserComponent), canActivate: [AuthGuard] },
    { path: 'login-ezl', loadComponent: () => import('./pages/logins/ezlanguage-login/ezlanguage-login.component').then(m => m.EzlanguageLoginComponent) },
    { path: 'login-srt', loadComponent: () => import('./pages/logins/saratoga-login/saratoga-login.component').then(m => m.SaratogaLoginComponent) },
    { path: 'showcase-list', loadComponent: () => import('./pages/showcase-list/showcase-list.component').then(m => m.ShowcaseListComponent), canActivate: [AuthGuard] },
    { path: 'showcase-edit/:id', loadComponent: () => import('./pages/showcase-edit/showcase-edit.component').then(m => m.ShowcaseEditComponent), canActivate: [AuthGuard] },
    {
        path: '**', redirectTo: environment.mainPage, pathMatch: "full"
    }
];

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Helper } from "src/app/helpers/helper";
import { WarningDTO } from "src/app/models/dto/warningDTO";
import { WarningTypeDTO } from "src/app/models/dto/warningTypeDTO";

@Injectable({
    providedIn: 'root'
})
export class WarningService {
    private _warnings: WarningDTO[] = [];

    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlWarnings = `${this.bckEndUrlBaseUrl}/warnings`;

    public set warnings(value: WarningDTO[]) { this._warnings = value != null ? value : []; }
    public get warnings(): WarningDTO[] { return this._warnings; }

    constructor(private http: HttpClient) { }

    public updateWarnings(): Promise<WarningDTO[]> {
        return new Promise((resolve) => {
            this.getWarningsOfUser()
                .subscribe({
                    next: output => {
                        this.warnings = output;
                        resolve(this.warnings);
                    }, error: () => {
                        this.warnings = [];
                        resolve(this.warnings);
                    }
                });
        });
    }

    public getWarningsOfUser(): Observable<WarningDTO[]> {
        return this.http.get<WarningDTO[]>(`${this.bckEndUrlWarnings}`, { headers: Helper.getAuthHeader() });
    }

    public getWarningTypes(): Observable<WarningTypeDTO[]> {
        return this.http.get<WarningTypeDTO[]>(`${this.bckEndUrlWarnings}/types`, { headers: Helper.getAuthHeader() });
    }

    public acknowledgeWarning(warningId: number) {
        return this.http.post(`${this.bckEndUrlWarnings}/acknowledge/${warningId}`, null, { headers: Helper.getAuthHeader() });
    }
}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MasterContentDTO } from 'src/app/models/dto/masterContentDTO';
import { MasterContentType } from 'src/app/models/masterContentType';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-master-content-table',
  templateUrl: './master-content-table.component.html',
  styleUrl: './master-content-table.component.scss'
})
export class MasterContentTableComponent {

  @Input()
  masterAuthorId: number = undefined;

  @Input()
  masterState: number = undefined;

  @Input()
  set contents(value: MasterContentDTO[]) {
    if (!value)
      return;

    this.dataSource.data = value;
  }

  @Input()
  hideEdit: boolean = false;

  @Input()
  hideDelete: boolean = false;

  @Output()
  onView: EventEmitter<MasterContentDTO> = new EventEmitter<MasterContentDTO>();

  @Output()
  onEdit: EventEmitter<MasterContentDTO> = new EventEmitter<MasterContentDTO>();

  @Output()
  onDelete: EventEmitter<MasterContentDTO> = new EventEmitter<MasterContentDTO>();

  dataSource: MatTableDataSource<MasterContentDTO> = new MatTableDataSource<MasterContentDTO>();
  columns: string[] = ['type', 'name', 'description', 'created', 'action'];

  currentUser: User;

  @ViewChild('contentsPag') set lessonsPag(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  @ViewChild('contentsSort') set lessonsSort(value: MatSort) {
    this.dataSource.sort = value;
  }

  constructor(private auth: AuthService) {
    this.currentUser = this.auth.getCurrentUser();
  }

  getIcon(type: number) {
    return type === MasterContentType.video
         ? 'smart_display'
         : type === MasterContentType.pdf
         ? 'picture_as_pdf'
         : type === MasterContentType.audio
         ? 'mic'
         : 'insert_drive_file';
  }

  getLabel(type: number) {
    return type === MasterContentType.video
         ? 'VIDEO'
         : type === MasterContentType.pdf
         ? 'PDF'
         : type === MasterContentType.audio
         ? 'AUDIO'
         : 'FILE';
  }

}

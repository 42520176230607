import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentCustomerType, EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-sunlight-login',
  templateUrl: './sunlight-login.component.html',
  styleUrls: ['./sunlight-login.component.scss']
})
export class SunlightLoginComponent {

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  completeLogo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.Sunlight, EnvironmentParameterType.Logo);
  logo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.Sunlight, this.themeService.isSetDark ? EnvironmentParameterType.LogoLight : EnvironmentParameterType.LogoDark);
  backgroundImageUrl: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.Sunlight, EnvironmentParameterType.BackgroundImage)})`;

  idCustomer: number = 88;
  customerCode: string = 'sunlight2023';

  constructor(private themeService: DarkThemeService,
              private deviceService: DeviceDetectorService,
              private navBar: NavBarService) { 
    this.isMobile = this.deviceService.isMobile();
    this.navBar.hide('topbar');
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

}

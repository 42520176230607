import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-content-locked-popup',
  templateUrl: './content-locked-popup.component.html',
  styleUrls: ['./content-locked-popup.component.scss']
})
export class ContentLockedPopupComponent implements OnInit {

  contentToRead: string = null;
  numberContentToRead: number = 0;

  constructor(public dialogRef: MatDialogRef<ContentLockedPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.contentToRead = this.data.contentName;
    this.numberContentToRead = this.data.orderIndex;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

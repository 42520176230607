export class TranslationDTO {
    public id: number;
    public idUser: number;
    public it_IT: string;
    public en_US: string;
    public es_ES: string;
    public fr_FR: string;
    public de_DE: string;
    public ru_RU: string;
    public pt_PT: string;
    public state: number;
    public created: Date;
}

export class TranslationEdit {
    public idUser: number;
    public it_IT: string;
    public en_US: string;
    public es_ES: string;
    public fr_FR: string;
    public de_DE: string;
    public ru_RU: string;
    public pt_PT: string;
    public state: number;

    public static fromDTO(translation: TranslationDTO): TranslationEdit {
        return <TranslationEdit>{
            idUser: translation?.idUser,
            it_IT: translation?.it_IT,
            en_US: translation?.en_US,
            es_ES: translation?.es_ES,
            fr_FR: translation?.fr_FR,
            de_DE: translation?.de_DE,
            ru_RU: translation?.ru_RU,
            pt_PT: translation?.pt_PT
        };
    }
}

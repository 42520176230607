
<div class="component-main">
    <mat-card appearance="outlined" class="mat-elevation-z4">
        <mat-card-content>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" *ngIf="surveyDto != null">
                <button mat-stroked-button (click)="goBack()" fxFlex="10">
                    <mat-icon matListIcon>arrow_back</mat-icon>
                    {{ 'Back' | translate }}
                </button>
                <mat-form-field appearance="outline" fxFlex color="primary">
                    <mat-label>{{ 'Survey' | translate }}</mat-label>
                    <input matInput [(ngModel)]="surveyDto.name" readonly>
                </mat-form-field>
            </div>

            <div  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                
                <mat-form-field appearance="outline" fxFlex="10" color="primary">
                   <mat-label>{{ 'Score' | translate }}</mat-label>
                   <input matInput [value]="surveyAnswer.score" readonly>
                </mat-form-field>
    
                <mat-form-field appearance="outline" fxFlex="90" color="primary">
                   <mat-label>{{ 'Notes' | translate }}</mat-label>
                   <input matInput [(ngModel)]="surveyAnswer.scoreNote" readonly>
                </mat-form-field>

             </div>
             <div class="survey-container">
                <survey [model]="survey"></survey>
            </div>

        </mat-card-content>
    </mat-card>    
</div>

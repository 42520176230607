<div class="component-main">
    <div fxLayoutAlign="center center" style="margin-bottom: 20px;">
        <button mat-stroked-button (click)="goBack()">
          <mat-icon matListIcon>arrow_back</mat-icon>
          {{ 'Back' | translate }}
        </button>
    </div>
    <div *ngIf="authorProfile" style="width: 80%; min-height: 50vh;" class="mat-elevation-z8 center-item">
        <div style="position: relative; margin-top: 10px;">
            <img class="header" id="headerImg" (load)="setImageBrightness($event, 'headerImg')" crossOrigin="anonymous" [src]="authorProfile.backgroundImageUrl" loading="lazy">
            <img class="headerProfile mat-elevation-z4" [src]="authorProfile.authorPhotoUrl" loading="lazy">
            <h3 class="headerText" [style.color]="textColor">{{ authorProfile.nameExtendend }}</h3>
        </div>
        <mat-tab-group mat-stretch-tabs class="mat-tab-fill-height">
            <mat-tab [label]="'Summary' | translate">
                <div class="tabAlign" [innerHTML]="authorProfile.authorResume">
                </div>
            </mat-tab>
            <mat-tab label="CV">
                <div class="tabAlign" [innerHTML]="authorProfile.authorCV">
                </div>
            </mat-tab>
            <mat-tab [label]="'Contact me' | translate" *ngIf="authorProfile.user.isAuthor">
                <div fxLayout="column" fxLayoutAlign="center center" class="tabAlign">
                    <div class="contactForm" fxLayout="row" fxLayoutAlign="center start">
                        <div fxFlex="40">
                            <h3>{{ 'Contact form' | translate }}</h3>
                        </div>
                    </div>
                    <div class="contactForm" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="40" color="primary">
                            <mat-label>{{ 'Name' | translate }}</mat-label>
                            <input matInput [formControl]="senderName">
                            <mat-error *ngIf="senderName.hasError('required')">
                              {{ 'Name is required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="contactForm" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="40" color="primary">
                            <mat-label>{{ 'Surname' | translate }}</mat-label>
                            <input matInput [formControl]="senderSurname">
                            <mat-error *ngIf="senderSurname.hasError('required')">
                              {{ 'Last Name is required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="contactForm" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="40" color="primary">
                            <mat-label>{{ 'Email' | translate }}</mat-label>
                            <input matInput [formControl]="senderEmail">
                            <mat-error *ngIf="senderEmail.hasError('required') || senderEmail.hasError('email')">
                              {{ 'Email is required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="contactForm" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="40" color="primary">
                            <mat-label>{{ 'Subject' | translate }}</mat-label>
                            <input matInput [formControl]="subject">
                            <mat-error *ngIf="subject.hasError('required')">
                              {{ 'Subject is required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="contactForm" fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex="40" color="primary">
                            <mat-label>{{ 'Message' | translate }}</mat-label>
                            <textarea matInput [formControl]="message"></textarea>
                            <mat-error *ngIf="message.hasError('required')">
                              {{ 'Message is required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="contactForm" fxLayout="row" fxLayoutAlign="center start" style="margin-top: 10px;">
                        <div fxFlex="40">
                            <button mat-raised-button color="accent" (click)="sendEmail()" [disabled]="!senderName.valid || !senderSurname.valid || !senderEmail.valid || !subject.valid ||!message.valid || sendingForm">
                                <mat-icon>
                                    send
                                </mat-icon>
                                {{ 'Send' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div *ngIf="!authorProfile">
        <app-error-message customClass="warning"
            [errorTitle]="'This profile is empty' | translate"
            [errorContent]="'This profile hasnt been completed' | translate">
        </app-error-message>
    </div>
</div>

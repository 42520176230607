import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentCustomerType, EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hufriedy-login',
  templateUrl: './hufriedy-login.component.html',
  styleUrl: './hufriedy-login.component.scss'
})
export class HufriedyLoginComponent {

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  logo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.HuFriedy, this.themeService.isSetDark ? EnvironmentParameterType.LogoLight : EnvironmentParameterType.LogoDark);
  backgroundImageUrl: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.HuFriedy, EnvironmentParameterType.BackgroundImage)})`;
  backgroundImageMobile: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.HuFriedy, EnvironmentParameterType.BackgroundImageMobile)})`;
  motto: string = environment.applicationMotto;

  idCustomer: number = 178;
  customerCode: string = 'TNT2024';

  constructor(
    private themeService: DarkThemeService,
    private navBar: NavBarService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.themeService.onLogOut();
    this.navBar.hide('topbar');
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OpenViduLoggerConfiguration = void 0;
var OpenViduLoggerConfiguration;
(function (OpenViduLoggerConfiguration) {
  OpenViduLoggerConfiguration["disabled"] = "disabled";
  OpenViduLoggerConfiguration["debug"] = "debug";
  OpenViduLoggerConfiguration["debug_app"] = "debug_app";
})(OpenViduLoggerConfiguration = exports.OpenViduLoggerConfiguration || (exports.OpenViduLoggerConfiguration = {}));

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CheckListAnswerDTO } from 'src/app/models/dto/CheckListDTO';

export interface DialogData {
  answers: CheckListAnswerDTO[];
  name: string;
  description: string;
  workingState: number;
}

@Component({
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  
  columnsToDisplay = ['user', 'question', 'answer', 'opendate', 'closedate'];

  constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void { }

  MarkAsWorking(): void {
    this.dialogRef.close(true);
  }

  getOpenIssue(): number {
    return this.data.answers.filter(a => a.fixed == null).length;
  }

}

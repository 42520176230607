import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { GetDataPointReply } from "src/app/models/signalR/getDataPointReply";
import { DataPointType } from "src/app/models/signalR/dataPointType";
import { SetDataPointRequest } from "src/app/models/signalR/setDataPointRequest";
import { SetDataPointReply } from "../models/signalR/setDataPointReply";
import { SetPointType } from "../models/signalR/setPointType";
import { SignalRMessage } from "../models/signalR/signalRMessage";
import { SignalRReplyType } from "../models/signalR/signalRReplyType";
import { Helper } from "../helpers/helper";
import { SignalRRequestType } from "../models/signalR/signalRRequestType";
import { ConnStatusReply, StationConnStatus } from "../models/signalR/connStatusReply";
import { User } from "../models/user";
import { GrantAccessRequest } from "../models/signalR/grantAccessRequest";
import { GrantAccessReply } from "../models/signalR/grantAccessReply";
import { GenericPopupComponent, GenericPopupData } from "../popup/generic-popup/generic-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { Location } from '@angular/common';
import { StationAccess } from "../models/signalR/stationAccess";
import { UserRole } from "../models/userRole";

const SEND_DATA_STATION: string = "SendDataToStation";
const RECEIVE_DATA: string = "ReceiveData";

@Injectable({
    providedIn: 'root'
})
export class SignalRService {

    private hubConn: HubConnection = null;

    private currentUser: User = null;
    private accessGranted: StationAccess[] = [];

    public get connectionStatus(): string { return this.hubConn != null ? this.hubConn.state : "Disconnected"; }
    public get isConnected(): boolean { return this.connectionStatus === "Connected"; }

    private dataPointSource = new Subject<GetDataPointReply>();
    public dataPoints = this.dataPointSource.asObservable();

    private setPointAckSource = new Subject<SetDataPointReply>();
    public setPointAck = this.setPointAckSource.asObservable();

    private deviceConnSource = new Subject<StationConnStatus>();
    public deviceConnStatus = this.deviceConnSource.asObservable();

    constructor(private dialog: MatDialog,
                private location: Location) { }

    public async init(token: string, currentUser: User): Promise<void> {

        if (this.hubConn != null)
            return;

        this.currentUser = currentUser;

        this.hubConn = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.apiUrl}/hub/smily`, { accessTokenFactory: () => token })
            .withAutomaticReconnect()
            .build();

        this.hubConn.on(RECEIVE_DATA, (userId: number, stationId: number, msg: SignalRMessage) => {

            console.log(msg);

            try {

                if (!Helper.isValidEnumValue(SignalRReplyType, msg.type))
                    return;

                let repType: SignalRReplyType = msg.type as SignalRReplyType;
                let dataReceived: any = null;

                try {

                    dataReceived = JSON.parse(msg.data);
        
                } catch (err) {
    
                    dataReceived = msg.data;
    
                }

                switch (repType) {

                    case SignalRReplyType.GetDataPoint:

                        this.dataPointSource.next(dataReceived as GetDataPointReply);
                        break;

                    case SignalRReplyType.SetDataPoint:

                        this.setPointAckSource.next(dataReceived as SetDataPointReply);
                        break;

                    case SignalRReplyType.GrantAccess:

                        let accessReply = dataReceived as GrantAccessReply;

                        if (!accessReply.accessGranted) {

                            if (this.isStationAsGranted(stationId))
                                this.removeStationAsGranted(stationId);

                            let responseBody = accessReply.type === "occupied" ? `Access was denied for this station because someone is alredy connected` : 
                                               accessReply.type === "user" ? `Access was denied for this station by ${accessReply.surname} ${accessReply.name}` :
                                               `Access was denied for this station`;

                            const dialogRef = this.dialog.open(GenericPopupComponent,
                                {
                                  width: '400px',
                                  data: <GenericPopupData>{
                                    title: 'Access denied',
                                    body: responseBody
                                  }
                                });

                            dialogRef.afterClosed()
                                .subscribe(() => this.location.back());

                        } else {
                            let access: StationAccess = {
                                                            stationId: stationId,
                                                            userId: userId,
                                                            name: accessReply.name,
                                                            surname: accessReply.surname 
                                                        };

                            this.addStationAsGranted(access);

                            // Utilizzo setPointAckSource per una questione di comodita'
                            this.setPointAckSource.next(new SetDataPointReply('access', JSON.stringify(access), true));
                        }

                        break;

                    case SignalRReplyType.GetConnStatus:

                        this.deviceConnSource.next(new StationConnStatus(dataReceived as ConnStatusReply, stationId));
                        break;

                    default:
                        break;

                }

            } catch (err) {
                console.error(err);
            }
            
        });

        await this.hubConn
            .start()
            .catch(err => console.error(err));
    }

    public async stop(): Promise<void> {

        if (this.hubConn == null)
            return;

        await this.hubConn
            .stop()
            .catch(err => console.error(err));

        this.hubConn = null;

        this.currentUser = null;
        this.accessGranted = [];
    }

    public async setDataPoint(stationId: number, setPoint: SetPointType, value: string): Promise<boolean> {

        if (!this.isConnected)
            return false;

        try {

            let data = new SetDataPointRequest(setPoint, value);

            await this.hubConn.invoke(SEND_DATA_STATION,
                                      stationId,
                                      new SignalRMessage(SignalRRequestType.SetDataPoint,
                                                         data));

        } catch (err) {
            console.error(err);
            return false;
        }

        return true;
    }

    public async getDataPoint(stationId: number, dataPoint: DataPointType): Promise<boolean> {

        if (!this.isConnected)
            return false;

        try {

            await this.hubConn.invoke(SEND_DATA_STATION,
                                      stationId,
                                      new SignalRMessage(SignalRRequestType.GetDataPoint,
                                                         dataPoint));

        } catch (err) {
            console.error(err);
            return false;
        }

        return true;
    }

    public async getStationStatus(stationId: number): Promise<boolean> {

        if (!this.isConnected)
            return false;

        try {

            await this.hubConn.invoke(SEND_DATA_STATION,
                                      stationId,
                                      new SignalRMessage(SignalRRequestType.GetConnStatus,
                                                         null));

        } catch (err) {
            console.error(err);
            return false;
        }

        return true;
    }

    public async requestAccess(stationId: number): Promise<boolean> {

        if (!this.isConnected)
            return false;

        let request: GrantAccessRequest = new GrantAccessRequest(this.currentUser.name,
                                                                 this.currentUser.surname,
                                                                 UserRole.codeAsString(this.currentUser));

        try {

            await this.hubConn.invoke(SEND_DATA_STATION,
                                      stationId,
                                      new SignalRMessage(SignalRRequestType.GrantAccess,
                                                         request));

        } catch (err) {
            console.error(err);
            return false;
        }

        return true;
    }

    public async revokeAccess(stationId: number): Promise<boolean> {

        if (!this.isConnected)
            return false;

        this.removeStationAsGranted(stationId);

        try {

            await this.hubConn.invoke(SEND_DATA_STATION,
                                      stationId,
                                      new SignalRMessage(SignalRRequestType.RevokeAccess,
                                                         null));

        } catch (err) {
            console.error(err);
            return false;
        }

        return true;
    }

    public getGrantedStation(stationId: number): StationAccess {
        return this.accessGranted.find(s => s.stationId === stationId);
    }

    private addStationAsGranted(station: StationAccess): void {
        if (!this.isStationAsGranted(station.stationId))
            this.accessGranted.push(station);
    }

    private removeStationAsGranted(stationId: number): void {
        let index = this.accessGranted.findIndex(s => s.stationId === stationId);

        if (index !== -1)
            this.accessGranted.splice(index, 1);
    }

    private isStationAsGranted(stationId: number): boolean {
        return this.getGrantedStation(stationId) != null;
    }

}

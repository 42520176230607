import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-picture-preview',
  templateUrl: './picture-preview.component.html',
  styleUrls: ['./picture-preview.component.scss']
})
export class PicturePreviewComponent implements OnInit {

  private _mode: 'desktop' | 'mobile' | 'custom' = 'custom';

  private _width: string;
  private _height: string;
  private _fit: string;

  radius: string = '0px';

  @Input()
  src: string = null;

  @Input()
  tooltip: string = null;

  @Input()
  get width(): string { return this._width; }
  set width(value: string) {

    if (this.mode !== 'custom')
      return;

    this._width = value;

  }

  @Input()
  get height(): string { return this._height; }
  set height(value: string) {

    if (this.mode !== 'custom')
      return;

    this._height = value;

  }

  @Input()
  get fit(): string { return this._fit; }
  set fit(value: string) {

    if (this.mode !== 'custom')
      return;

    this._fit = value;

  }

  @Input()
  get mode(): 'desktop' | 'mobile' | 'custom' { return this._mode; }
  set mode(value: 'desktop' | 'mobile' | 'custom') {

    this._mode = value;

    if (value === 'desktop') {
      
      this._width = '285px';
      this._height = '160px';
      this._fit = 'cover';
      this.radius = '0px';

    } else if (value === 'mobile') {

      this._width = '150px';
      this._height = '150px';
      this._fit = 'cover';
      this.radius = '20%';

    } else {

      this.radius = '0px';

    }

  }

  constructor() { }

  ngOnInit(): void { }

}

<h1 mat-dialog-title>{{ 'Edit educational plan content' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Sequence index' | translate }}</mat-label>
            <input matInput type="number" [formControl]="seqIndex" required>
            <mat-error *ngIf="seqIndex.hasError('required')">
                {{ 'Sequence index is required' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field appearance="fill">
            <mat-label>{{ 'Expiration date' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="expirationDate" [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="expirationDate.hasError('required')">
                {{ 'Description is required' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="saveContent()" [disabled]="btnDisabled()" color="accent">{{ 'Ok' | translate }}</button>
</div>

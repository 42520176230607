<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>{{ 'Educational plans' | translate }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom: 20px;">
        <button mat-fab
            color="accent"
            (click)="addEduPlan()"
            *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin">
            <mat-icon>
                add
            </mat-icon>
        </button>
        <mat-form-field [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Search' | translate }}...</mat-label>
            <input matInput [(ngModel)]="searchWord" (ngModelChange)="resetData(false)">
            <button matSuffix mat-icon-button [disabled]="!searchWord" [matTooltip]="'Search' | translate">
                <mat-icon>search</mat-icon>
            </button>
            <button matSuffix mat-icon-button [disabled]="!searchWord" [matTooltip]="'Clear' | translate" (click)="resetData()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Classification' | translate }}</mat-label>
            <mat-select [(ngModel)]="classification" (selectionChange)="resetData()">
                <mat-option [value]="0">{{ 'All classifications' | translate }}</mat-option>
                <mat-option *ngFor="let class of classifications" [value]="class.id">
                  {{ class.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-accordion class="my-headers-align">
            <mat-expansion-panel *ngFor="let plan of eduPlans" (opened)="getPlanContents(plan.id)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ plan.name }}
                    </mat-panel-title>
                    <mat-panel-description>
                        <div fxFlex>
                            {{ plan.state === 0 ? 'OFFLINE' : 'ONLINE' }}
                        </div>
                        <div fxFlex>
                            {{ plan.year }}
                        </div>
                        <div fxFlex>
                            {{ plan.classification.name }}
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="column" fxLayoutGap="20px">
                            <span class="infoTitle">{{ 'Actions' | translate }}</span>
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                                <mat-slide-toggle color="primary" [checked]="plan.state === 1 ? true : false"
                                    [disabled]="!eduPlanContents && eduPlanContents.length === 0"
                                    (change)="togglePlan(plan, $event)">
                                    {{ plan.state === 1 ? 'Online' : 'Offline' }}
                                </mat-slide-toggle>
                                <button mat-fab color="primary" [disabled]="plan.state === 1"
                                    (click)="editEduPlan(plan)"
                                    [matTooltip]="'edit' | translate">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-fab color="accent"
                                    (click)="startSelection(plan)"
                                    [disabled]="plan.state != 0"
                                    [matTooltip]="'Contents' | translate">
                                    <mat-icon>playlist_add</mat-icon>
                                </button>
                                <button mat-fab color="accent"
                                    (click)="addUserToEduPlan(plan)"
                                    [disabled]="plan.state != 1"
                                    [matTooltip]="'Users' | translate">
                                    <mat-icon>people</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxFlex
                            *ngIf="eduPlanContents && eduPlanContents.length > 0">
                            <span class="infoTitle">{{ 'Contents' | translate }}</span>
                            <div fxLayout="row wrap">
                                <mat-card appearance="outlined" *ngFor="let content of eduPlanContents"
                                    fxLayout="column"
                                    style="width: 300px; height: 220px; cursor: pointer; margin-top: 10px; margin-right: 10px;"
                                    class="mat-elevation-z2">
                                    <img mat-card-image [src]="getContent(content, 'imageUrl')" (click)="goToContent(content)" loading="lazy" style="height: 100px; min-height: 100px; object-fit: cover;">
                                    <mat-card-header>
                                        <mat-card-title class="truncate-text" style="max-width: 168px; font-size: 14px;">
                                            {{ getContent(content, 'name') }}
                                        </mat-card-title>
                                    </mat-card-header>
                                    <div fxFlex></div>
                                    <mat-card-actions fxLayout="row" fxLayoutAlign="space-between end">
                                        <button mat-fab color="primary"
                                            (click)="editContent(content, plan)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button mat-raised-button color="accent"
                                            (click)="goToContent(content)">
                                            <mat-icon>launch</mat-icon>
                                            <div class="truncate-text" style="max-width: 130px">{{ (content.idCourse != null ? 'Go to course' : 'Go to master') | translate }}</div>
                                        </button>
                                    </mat-card-actions>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-paginator
            *ngIf="getFilteredData(searchWord).length > 0"
            [length]="getFilteredData(searchWord).length"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 20]"
            (page)="changePage($event)"
            class="mat-elevation-z3"
            [color]="isDark() ? 'accent' : 'primary'">
        </mat-paginator>
    </div>
</div>

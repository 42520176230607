import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { Helper } from 'src/app/helpers/helper';
import { TranslateService } from '@ngx-translate/core';

const LANGSTORAGE: string = 'language';

export enum LanguageCodes {
    IT = 'it',
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    DE = 'de',
    RU = 'ru',
    PT = 'pt'
}

export const LANGUAGES: Language[] = [
    { code: LanguageCodes.EN, lang: 'en_US', description: 'English' },
    { code: LanguageCodes.IT, lang: 'it_IT', description: 'Italiano' },
    { code: LanguageCodes.ES, lang: 'es_ES', description: 'Español' },
    { code: LanguageCodes.FR, lang: 'fr_FR', description: 'Français' },
    { code: LanguageCodes.RU, lang: 'ru_RU', description: 'Pусский' },
    { code: LanguageCodes.PT, lang: 'pt_PT', description: 'Português' }
];

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    private _currentLang: Language = LANGUAGES[0]; // Modificare solo tramite setLang()
    public get currentLang(): Language { return this._currentLang; }

    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlTranslation = `${this.bckEndUrlBaseUrl}/translation`;
    
    constructor(private http: HttpClient, private translate: TranslateService) {
        translate.addLangs(LANGUAGES.map(l => l.code));
        translate.setDefaultLang(LanguageCodes.EN);

        this.setLang();
    }

    public isValidLanguage(code: LanguageCodes): boolean {
        return LANGUAGES.some(l => l.code === code);
    }

    public setLang(code?: LanguageCodes): Language {
        if (!code)
            code = localStorage.getItem(LANGSTORAGE) as LanguageCodes;
        
        code = this.isValidLanguage(code) ? code : LanguageCodes.EN;

        localStorage.setItem(LANGSTORAGE, code);

        this.translate.use(code);
        this._currentLang = LANGUAGES.find(l => l.code === code);

        return this.currentLang;
    }

    // ---- API ---- //

    public getTranslationsOfUser(userId: number): Observable<TranslationDTO[]> {
        return this.http.get<TranslationDTO[]>(`${this.bckEndUrlTranslation}/user/${userId}`, { headers: Helper.getAuthHeader() });
    }

    public editTranslation(translationId: number, translation: TranslationEdit) {
        return this.http.put(`${this.bckEndUrlTranslation}/${translationId}`, translation, { headers: Helper.getAuthHeader() });
    }
}

export class Language {
    public code: string;
    public lang: string;
    public description: string;
}

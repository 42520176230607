import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalendarService } from 'src/app/services/calendar.service';
import { Location } from '@angular/common';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserOnlineTime } from 'src/app/models/userOnlineTime';
import { firstBy } from 'thenby';

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss']
})
export class UserStatusComponent implements OnInit {
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: { 
          color: "#6f6f6f"
        },
        ticks: {
          color: "#868585"
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#6f6f6f"
        }, 
        ticks: {
          color: "#868585"
        }
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  barChartLabels: string[] = [];
  barChartType: ChartType = 'bar';
  barChartPlugins = [DataLabelsPlugin];

  barChartData: ChartDataset[] = [{data: [0], label: 'null', backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)'}];
  //chart
  monthsName: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  constructor(private calendar: CalendarService,
              private route: ActivatedRoute,
              private location: Location) { }

  year: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
  userId: number;
  userData: UserOnlineTime[] = [];
  years: number[] = [];
  months: number[] = [];
  name: string;
  surname: string;

  ngOnInit() {
    this.userId = +this.route.snapshot.paramMap.get('id');

    if (this.route.snapshot.queryParamMap.has('name'))
      this.name = this.route.snapshot.queryParamMap.get('name');

    if (this.route.snapshot.queryParamMap.has('surname'))
      this.surname = this.route.snapshot.queryParamMap.get('surname');

    this.getData();
    
  }

  getData() {
    this.calendar.getUserOnlineTime(this.userId)
      .subscribe(output => {
        this.userData = output;
        this.userData.sort(firstBy((d: UserOnlineTime) => d.year, 1).thenBy((d: UserOnlineTime) => d.month, 1));

        if (this.userData.length != 0) {
          this.userData.forEach(d => {
            if(!this.years.includes(d.year))
              this.years.push(d.year);
          });

          this.yearChange();
        }
      });
  }

  yearChange() {
    var time: number[] = [];
    this.months = [];
    this.barChartData = [];
    this.barChartLabels = [];

    if(this.year.value == 0) {
      this.years.forEach(y => {
        this.userData.forEach(d => {

          if(y == d.year) {
            time.push(this.convertToTime(d.totalseconds));
            this.barChartLabels.push(this.monthsName[d.month-1] + " " + d.year.toString());
          }  
        });
        
      });
      
      this.barChartData.push({data: time, label: "HH.mm", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)'});
    } else {
      this.userData.forEach(d => {
        if(!this.months.includes(d.month) && d.year == this.year.value) {
          this.months.push(d.month);
          time.push(this.convertToTime(d.totalseconds));
        }  
      });
  
      this.barChartData = [{data: time, label: "HH.mm", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)'}];
      
      this.months.forEach(m => {
        this.barChartLabels.push(this.monthsName[m-1]);
      });
    }
  }

  convertToTime(seconds: number) {
    if(seconds != null) {
      var hours = seconds/3600;
      var minutes = (seconds % 3600)/ 60;
  
      return Number(hours.toFixed(0) + "." + minutes.toFixed(0));
    } else {
      return 0;
    }
  }

  goBack() {
    this.location.back();
  }
}

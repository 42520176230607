<div class="component-main" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

    <mat-card appearance="outlined" *ngIf="type === 'invite'" class="card">

        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
                {{ 'Change password' | translate }}
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div fxFlexLayout="row" fxLayoutGap="20">
                <mat-form-field fxFlex color="primary">
                    <mat-label>
                        {{ 'New password' | translate }}
                    </mat-label>
                    <input matInput type="password" [formControl]="password">
                    <mat-error *ngIf="password.hasError('required')">
                        {{ 'New password is required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="password.hasError('pattern')">
                        {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex color="primary">
                    <mat-label>
                        {{ 'Repeat new password' | translate }}
                    </mat-label>
                    <input matInput type="password" [formControl]="passwordConfirm">
                    <mat-error *ngIf="passwordConfirm.hasError('required')">
                        {{ 'New password is required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-error style="margin-top:5px" *ngIf="!checkNewPassword() && password.hasError('pattern') === false">
                    {{ 'New password and password confirmation are not the same' | translate }}
                </mat-error>
            </div>
        </mat-card-content>

        <mat-card-actions fxLayoutAlign="center center" class="actions">
            <button mat-raised-button color="accent"
                (click)="checkToken()"
                [disabled]="!checkNewPassword() ||
                            sending">
                <mat-icon>person_add</mat-icon>
                {{ 'Create' | translate }}
            </button>
        </mat-card-actions>

    </mat-card>

    <app-error-message customClass="warning"
        *ngIf="info"
        [errorTitle]="info.title | translate"
        [errorContent]="info.body | translate"
        [center]="false">
    </app-error-message>
</div>

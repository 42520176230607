import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { CropperPosition } from 'ngx-image-cropper';

const ASPECT_RATIO_ERROR: number = 0.5;

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit, AfterViewInit {

  private _showCropper: boolean = false;

  @Input()
  control: UntypedFormControl = undefined;

  @Input()
  maxSize: number = undefined;

  @Input()
  maxHeight: number = undefined;

  @Input()
  maxWidth: number = undefined;

  @Input()
  aspectRatio: number = undefined;

  @Input()
  acceptedTypes: string = '*';

  @Input()
  enableCrop: boolean = false;

  @Input()
  rejectedErrorMessage: string =  `File type must be accepted in ${this.acceptedTypes} and have a size smaller than ${this.maxSize} MB`;

  @Input()
  deletedErrorMessage: string = 'Please select a file';

  @Output()
  acceptedFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  rejectedFile: EventEmitter<'type' | 'size' | 'height' | 'width' | 'aspectRatio'> = new EventEmitter<'type' | 'size' | 'height' | 'width' | 'aspectRatio'>();

  @Output()
  deletedFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  croppingFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {

    event.preventDefault();
    event.stopPropagation();

    if (!this.activateRipple && !this.control.disabled) {

      this.activateRipple = true;
      this.mainBtn.ripple.launch({ centered: true, persistent: true });

    }

  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {

    event.preventDefault();
    event.stopPropagation();

    this.activateRipple = false;
    this.mainBtn.ripple.fadeOutAll();

  }

  @HostListener('body:dragover', ['$event'])
  onBodyDragOver(event: DragEvent) {

    event.preventDefault();
    event.stopPropagation();

  }

  @HostListener('body:drop', ['$event'])
  onBodyDrop(event: DragEvent) {

    event.preventDefault();

  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {

    event.preventDefault();
    event.stopPropagation();

    this.validateFiles(event.dataTransfer.files[0]);

  }

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('mainBtn') mainBtn: MatButton;

  action: 'accepted' | 'rejected' | 'deleted' = undefined;
  fileSelected: boolean = false;
  fileImg: string = undefined;
  fileName: string = undefined;
  activateRipple: boolean = false;

  originalImg: string = undefined;
  cropperPosition: CropperPosition = undefined;

  private cropperBackup: { img: string, position: CropperPosition } = { img: undefined, position: undefined };
  cropper: { img: Blob, position: CropperPosition } = { img: undefined, position: undefined };

  get showCropper(): boolean { return this._showCropper; }
  set showCropper(value: boolean) {

    if (value !== this._showCropper)
      this.croppingFile.emit(value);

    this._showCropper = value;

  }

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
      
    this.fileInput.nativeElement.onchange = () => this.validateFiles(this.fileInput.nativeElement.files[0]);

  }

  async validateFiles(file: File) {

    if (this.control.disabled)
      return;

    if (!file) {

      this.deleteFile();
      return;
      
    }

    if (!this.verifySize(file.size)) {

      this.rejectFile('size');
      return;

    }

    if (!this.verifyType(file.type)) {

      this.rejectFile('type');
      return;

    }

    if (this.isImg(file.type)) {

      let img = await this.getLoadedImg(file);

      if (!this.verifyHeight(img?.height)) {

        this.rejectFile('height');
        return;

      }

      if (!this.verifyWidth(img?.width)) {

        this.rejectFile('width');
        return;

      }

      if (!this.verifyAspectRatio(img?.width, img?.height)) {

        this.rejectFile('aspectRatio');
        return;

      }

    }

    await this.acceptFile(file);

  }

  fileSelection() {

    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();

  }

  async acceptFile(file: File) {

    this.toggleCropper(false);

    this.fileSelected = true;
    this.fileName = file.name;

    if (this.isImg(file?.type)) {
      this.fileImg = await this.fileToBase64(file);

      if (this.originalImg == undefined || this.fileImg !== this.cropperBackup.img) {
        this.originalImg = this.fileImg;
        this.cropperBackup = { img: undefined, position: undefined };
      }
    }

    this.action = 'accepted';
    this.control.setValue(file);

    this.acceptedFile.emit(true);

  }

  rejectFile(reason: 'type' | 'size' | 'height' | 'width' | 'aspectRatio') {

    this.toggleCropper(false);

    this.originalImg = undefined;

    this.cropperBackup = { img: undefined, position: undefined };
    this.cropper = { img: undefined, position: undefined };

    this.fileSelected = false;
    this.fileImg = undefined;
    this.fileName = undefined;

    this.action = 'rejected';
    this.control.reset();
    this.fileInput.nativeElement.value = '';

    this.rejectedFile.emit(reason);

  }

  deleteFile() {

    this.toggleCropper(false);

    this.originalImg = undefined;

    this.cropperBackup = { img: undefined, position: undefined };
    this.cropper = { img: undefined, position: undefined };

    this.fileSelected = false;
    this.fileImg = undefined;
    this.fileName = undefined;

    this.action = 'deleted';
    this.control.reset();
    this.fileInput.nativeElement.value = '';

    this.deletedFile.emit(true);

  }

  isImg(type: string) {
    return new RegExp('image/*').exec(type);
  }

  async saveCroppedImg() {
    this.toggleCropper(false);

    if (this.cropper.img)
      this.cropperBackup.img = await this.fileToBase64(this.cropper.img as File);

    if (this.cropper.position)
      this.cropperBackup.position = this.cropper.position;

    this.validateFiles(this.cropper.img as File);
  }

  toggleCropper(show: boolean = !this.showCropper) {
    this.showCropper = show;

    if (!show) {

      if (this.cropperBackup.position == undefined)
        this.cropperBackup.position = this.cropperPosition;

      this.cropperPosition = undefined;

    }
  }

  cropperLoadFailed() {
    console.error('error loading cropper');
  }

  cropperImageLoaded($event: any) {
    console.log('loaded cropper img');
  }

  cropperReady() {
    console.log('cropper ready');

    if (this.cropperBackup.position != undefined)
      this.cropperPosition = this.cropperBackup.position;
  }

  private verifySize(size: number) {

    if (!this.maxSize)
      return true;

    if (!size)
      return false;

    if (size > 0)
      size = size / 1024; // Byte to KB

    return size <= this.maxSize;

  }

  private verifyType(type: string) {

    if (this.acceptedTypes === '*')
      return true;

    if (!type)
      return false;

    let allowed = this.acceptedTypes
      .split(',')
      .map(x => x.replace('.', '').trim());

    let split = type.split('/');

    let base = `${split[0]}/*`;
    let specific = split[1];

    return allowed.includes(type)
        || allowed.includes(base)
        || allowed.includes(specific);

  }

  private verifyHeight(height: number) {

    if (!this.maxHeight)
      return true;

    if (!height)
      return false;

    return height <= this.maxHeight;

  }

  private verifyWidth(width: number) {

    if (!this.maxWidth)
      return true;

    if (!width)
      return false;

    return width <= this.maxWidth;

  }

  private verifyAspectRatio(width: number, height: number) {

    if (!this.aspectRatio)
      return true;

    if (!width || !height)
      return false;

    let imgAspectRatio = width / height;

    return Math.abs(this.aspectRatio - imgAspectRatio) < ASPECT_RATIO_ERROR;
  }

  private fileToBase64(file: File): Promise<string> {

    if (!file)
      return undefined;

    return new Promise<string>((resolve, reject) => {

      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() ?? undefined);
      reader.onerror = () => reject(undefined);

    });

  }

  private async getLoadedImg(file: File): Promise<HTMLImageElement> {

    if (!file)
      return undefined;

    return new Promise(async (resolve, reject) => {

      let img = new Image();

      img.onload = () => resolve(img);
      img.onerror = () => reject(undefined);

      img.src = await this.fileToBase64(file);

    });

  }

}

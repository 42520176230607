import { Component, OnInit } from '@angular/core';
import { CartService } from '../service/cart.service'
import { CheckoutService } from '../service/checkout.service';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from 'src/app/helpers/helper';
import { CartItemDTO } from '../models/cart-item-dto';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {

  loading: boolean = true;

  displayedColumns: string[] = ['image', 'name', 'unitAmount', 'actions'];
  listofCustomer: CustomerDTO[] = [];
  currency: string = "eur";
  invoice: boolean = false;
  discountCode: string = null;

  get currencySymbol () {
    if (this.currency == "eur")
      return "€";
    if (this.currency == "usd")
      return "$";
    if (this.currency == "gbp")
      return "£";

    return "";
  }

  constructor(
    private cartService: CartService,
    private checkout: CheckoutService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.cartService.updateList()
      .then(() => {
        this.loading = false;

        this.listofCustomer = this.cartService.getCustomers();
      });
  }

  getItems(index: number, reference: boolean = true) {
    return this.cartService.getList(this.currency, this.listofCustomer[index], reference);
  }

  getTotal(items: CartItemDTO[]) {
    return items.reduce((a, b) => a + b.unitAmount, 0);
  }

  getInvoiceEnabled(index: number) {
    return this.listofCustomer[index].billingEnabled == 1;
  }

  async payNowwithIndex(index: number) {

    let items = this.getItems(index, false);
    let amount = this.getTotal(items);

    if (amount <= 0)
      return;

    let discountPerc: number = undefined;

    if (!Helper.isNullOrEmpty(this.discountCode)) {

      try {

        discountPerc = (await firstValueFrom(this.checkout.checkDiscountCode(this.discountCode))).discountPercentage;

      } catch (e) {
        console.error(e);
        this.snackBar.open('Discount code not valid', 'Dismiss', { duration: 5000 });
      }

      if (!discountPerc)
        return;

      items.forEach(i => i.unitAmount -= (i.unitAmount * discountPerc / 100));

      amount = this.getTotal(items);
    }

    sessionStorage.removeItem("currentCarttobepurchased");
    this.checkout.startCheckout(items,
                                this.listofCustomer[index].billingEnabled == 1 ?
                                {
                                  customer: this.listofCustomer[index].id,
                                  elements: items,
                                  price: amount,
                                  currency: this.currencySymbol,
                                  type: this.invoice ? 'company' : 'person'
                                } :
                                null);

  }

  removeItem (cartItem: CartItemDTO) {
    this.cartService.removeItems([cartItem]);
  }

}

<h1 mat-dialog-title>{{ ((classId ? 'Edit' : 'Add') | translate) + ' Virtual Classroom' }}</h1>
<div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="20px">
        <mat-form-field color="primary">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput [formControl]="name">
            <mat-error>
                {{ 'The name is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    
        <mat-form-field color="primary">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <input matInput [formControl]="description">
            <mat-error>
                {{ 'The description is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>

        <div *ngIf="classId == undefined && (currentUser.isTeacher || currentUser.isTutor)">
            <mat-checkbox
              [disabled]="!currentUser.googleEnabled"
              [(ngModel)]="googleCalendar">
              Google {{ 'calendar' | translate }}
            </mat-checkbox>
        </div>

        <p *ngIf="customerName != undefined" style="margin: 0px">
            {{ 'Customer' | translate }}: "{{ customerName }}"
        </p>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="save()" [disabled]="btnDisabled()" color="accent">Ok</button>
</div>

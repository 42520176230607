<video #videoplayer disablepictureinpicture
    class="player"
    [controlsList]="controls"
    [style.width]="width"
    [style.height]="height"
    [style.object-fit]="objectFit"
    [class.mat-elevation-z8]="shadow"
    crossorigin="anonymous"
    oncontextmenu="return false;">
    <source *ngIf="media" [src]="media" type="video/mp4"/>
    <track *ngIf="caption" [src]="caption.url" [label]="caption.label" kind="captions" />
</video>

<button mat-icon-button *ngIf="showMute"
    (click)="mute = !mute"
    [matTooltip]="(mute ? 'Unmute' : 'Mute') | translate"
    [style.top]="height"
    matTooltipPosition="below"
    color="accent"
    class="mute-btn">
    <mat-icon>{{ mute ? 'volume_off' : 'volume_up' }}</mat-icon>
</button>

<button mat-icon-button *ngIf="showPlay"
    (click)="pause = !pause"
    [matTooltip]="(pause ? 'Play' : 'Pause') | translate"
    [style.top]="height"
    matTooltipPosition="below"
    color="accent"
    class="play-btn">
    <mat-icon>{{ pause ? 'play_circle_filled' : 'pause_circle_filled' }}</mat-icon>
</button>

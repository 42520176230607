import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { CustomerDemoPost } from 'src/app/models/customerDemoPost';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-demo-pop-up',
  templateUrl: './demo-pop-up.component.html',
  styleUrls: ['./demo-pop-up.component.scss']
})
export class DemoPopUpComponent implements OnInit {

  submitProcessing: boolean = false;

  name: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  surname: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  email: UntypedFormControl = new UntypedFormControl('', [Validators.required,Validators.email]);
  customerName: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  constructor(private dialogRef: MatDialogRef<DemoPopUpComponent>,
              private dialog: MatDialog,
              private userService: UserService,
              private translate: TranslateService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void { }

  requestDemo() {

    this.submitProcessing = true;

    let customerDemoPost: CustomerDemoPost = {
      customerName: this.customerName.value,
      email: this.email.value,
      name: this.name.value,
      surname: this.surname.value,
      timezone: Helper.getTimezone()
    };

    firstValueFrom(this.userService.requestDemo(customerDemoPost))
      .then(async () => {

        this.clearRegistrationForm();
        this.dialogRef.close();

        this.dialog.open(GenericPopupComponent,
          {
            width: '400px',
            data: <GenericPopupData>{
              title: await firstValueFrom(this.translate.get('Demo request')),
              body: await firstValueFrom(this.translate.get('Your demo request has been taken care of. Check your mailbox and confirm your address by clicking on the link sent to you')),
              hideCancelBtn: true
            }
          });

      }).catch(err => {
          console.error(err);

          this.snackBar.open(err.error.Message, undefined, { duration: 3000 });

          if(err.status ===  406)
            this.email.setErrors({ alreadyUsed: true });

          if(err.status === 409)
            this.customerName.setErrors({ alreadyUsed: true });

      }).finally( () => {
          this.submitProcessing = false;
      });
  }

  getDemoBtnDisabled(): boolean {
    return !this.name.valid ||
           !this.surname.valid ||
           !this.email.valid ||
           !this.customerName.valid ||
           this.submitProcessing;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }
  
  clearRegistrationForm() {
    this.name.reset();
    this.surname.reset();
    this.email.reset();
    this.customerName.reset();

    this.name.setErrors(null);
    this.surname.setErrors(null);
    this.email.setErrors(null);
    this.customerName.setErrors(null);
  }
}

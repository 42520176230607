import { RoomDTO } from './roomDTO';

export class StationDTO {
    public id: number;
    public roomId: number;
    public name: string;
    public description: string;
    public macAddress: string;
    public macAddress2: string;
    public state: number;
    public idUserAccount?: number;
    public workingState: number;
    public mapRow?: number;
    public mapColumn?: number;
    public mapPosition?: number;
    public onlineState?: number;
    public lastCheck: Date;
    public created: Date;
    public room: RoomDTO;
}

<h1 mat-dialog-title>{{ ((packageToEdit ? 'Edit' : 'Add') | translate) + ' ' + ('Package' | translate) }}</h1>
<div mat-dialog-content>

    <mat-stepper #stepper (selectedIndexChange)="stepperIndex = $event">

        <mat-step>

            <div class="stepper-gap">

                <app-translations-form
                    [label]="'Name' | translate"
                    [disabled]="sending"
                    [(translation)]="nameTranslation">
            
                    <mat-form-field color="primary" currentLanguageForm>
                        <mat-label>{{ 'Name' | translate }}</mat-label>
                        <input matInput type="text" [formControl]="name">
                        <mat-error *ngIf="name.hasError('required')">
                            {{ 'Name is required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="name.hasError('minlength')">
                            {{ 'Name should be at least 4 characters' | translate }}
                        </mat-error>
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>
            
                </app-translations-form>

                <app-translations-form
                    [label]="'Description' | translate"
                    [disabled]="sending"
                    [mode]="'textarea'"
                    [(translation)]="descriptionTranslation">

                    <mat-form-field color="primary" currentLanguageForm>
                        <mat-label>{{ 'Description' | translate }}</mat-label>
                        <textarea matInput [formControl]="description" style="height: 100px"></textarea>
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>

                <app-translations-form
                    [label]="'Header' | translate"
                    [disabled]="sending"
                    [(translation)]="headerTranslation">

                    <mat-form-field color="primary" currentLanguageForm>
                        <mat-label>{{ 'Header' | translate }}</mat-label>
                        <input matInput type="text" [formControl]="header">
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>

                <app-translations-form
                    [label]="'Footer' | translate"
                    [disabled]="sending"
                    [(translation)]="footerTranslation">

                    <mat-form-field color="primary" currentLanguageForm>
                        <mat-label>{{ 'Footer' | translate }}</mat-label>
                        <input matInput type="text" [formControl]="footer">
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>

                <app-translations-form
                    [label]="'Summary' | translate"
                    [disabled]="sending"
                    [mode]="'textarea'"
                    [(translation)]="showcaseTranslation">

                    <mat-form-field color="primary" currentLanguageForm>
                        <mat-label>{{ 'Summary' | translate }}</mat-label>
                        <textarea matInput [formControl]="showcase" style="height: 100px"></textarea>
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">

                    <mat-form-field fxFlex color="primary">
                        <mat-label>{{ 'Duration' | translate }}</mat-label>
                        <mat-select [formControl]="duration">
                            <mat-option [value]="7">{{ 'One' | translate }} {{ 'week' | translate }}</mat-option>
                            <mat-option [value]="30">{{ 'One' | translate }} {{ 'month' | translate }}</mat-option>
                            <mat-option [value]="90">{{ 'Three' | translate }} {{ 'months' | translate }}</mat-option>
                            <mat-option [value]="180">{{ 'Six' | translate }} {{ 'months' | translate }}</mat-option>
                            <mat-option [value]="360">{{ 'One' | translate }} {{ 'year' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div fxFlex="50" *ngIf="currentUser.isAdmin">
                        <mat-checkbox [formControl]="visibility">{{ 'Public to all customers' | translate }}</mat-checkbox>
                    </div>

                </div>

            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div fxFlex>
                        <mat-label>{{ 'Picture' | translate }}</mat-label>
                        <app-file-input
                            [control]="imageSrc"
                            [maxSize]="2000"
                            [acceptedTypes]="'image/*'"
                            [aspectRatio]="8/5"
                            [maxHeight]="2000"
                            [maxWidth]="2000"
                            [enableCrop]="true"
                            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '8:5', height: '2000px', width: '2000px', size: '2MB' }"
                            [deletedErrorMessage]="'Please select a file' | translate"
                            (acceptedFile)="setPicturePreview()"
                            (deletedFile)="setPicturePreview()"
                            (croppingFile)="$event ? desktopPreview = undefined : setPicturePreview()"
                            style="width: 100%">
                        </app-file-input>
                    </div>
                    <app-picture-preview
                        *ngIf="desktopPreview"
                        [src]="desktopPreview"
                        [tooltip]="'Current picture' | translate"
                        mode="desktop"
                        fxFlex="50">
                    </app-picture-preview>
                </div>

            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div fxFlex>
                        <mat-label>{{ 'Mobile app picture' | translate }}</mat-label>
                        <app-file-input
                            [control]="mobileImageSrc"
                            [maxSize]="1000"
                            [acceptedTypes]="'image/*'"
                            [aspectRatio]="1/1"
                            [maxHeight]="800"
                            [maxWidth]="800"
                            [enableCrop]="true"
                            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '1:1', height: '800px', width: '800px', size: '1MB' }"
                            [deletedErrorMessage]="'Please select a file' | translate"
                            (acceptedFile)="setPicturePreview()"
                            (deletedFile)="setPicturePreview()"
                            (croppingFile)="$event ? mobilePreview = undefined : setPicturePreview()"
                            style="width: 100%">
                        </app-file-input>
                    </div>
                    <app-picture-preview
                        *ngIf="mobilePreview"
                        [src]="mobilePreview"
                        [tooltip]="'Current mobile app picture' | translate"
                        mode="mobile"
                        fxFlex="50">
                    </app-picture-preview>
                </div>

            </div>

        </mat-step>

    </mat-stepper>

</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
    <button mat-stroked-button (click)="onNoClick()" [disabled]="sending">{{ 'Cancel' | translate }}</button>
    
    <div fxLayout="row">
        <button mat-stroked-button
            *ngIf="stepperIndex !== 0"
            (click)="stepper.previous()">
            {{ 'Back' | translate }}
        </button>
        <button mat-flat-button color="primary"
            *ngIf="stepperIndex !== 2"
            [disabled]="nextBtnDisabled()"
            (click)="stepper.next()">
            {{ 'Next' | translate }}
        </button>
        <button mat-flat-button color="accent"
            *ngIf="stepperIndex === 2"
            (click)="savePackage()"
            [disabled]="okBtnDisabled()">
            Ok
        </button>
    </div>

</div>

<div *ngIf="sending" class="cssload-container" style="top: 50%">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/models/user';
import { UserRole } from 'src/app/models/userRole';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { AuthService } from 'src/app/services/auth.service';
import { CsvExporterService, ExportDtUserCsv, ExportUserCsv } from 'src/app/services/csv-exporter.service';
import { environment } from 'src/environments/environment';
import { AddUserComponent } from '../add-user/add-user.component';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthorProfileDTO } from 'src/app/models/dto/authorProfileDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrl: './user-table.component.scss',
  animations: [
      trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ])
  ]
})
export class UserTableComponent implements OnInit {

  env = environment;
  currentUser: User;
  
  datasource: MatTableDataSource<User> = new MatTableDataSource<User>();
  displayedColumns: string[] = ['thumbnail', 'username', 'surname', 'name', 'email', 'role', 'date'];
  paginatorDefault: number = 25;
  paginatorPages: number[] = [10, 25, 50, 100];

  selectedUser: { id: number, profile: AuthorProfileDTO, classrooms: ClassroomDTO[] };

  @ViewChild('usersPaginator') set usersPaginator(value: MatPaginator) {
    this.datasource.paginator = value;
  }

  @ViewChild('usersSort') set usersSort(value: MatSort) {
    this.datasource.sort = value;

    this.datasource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'email':
          return item.email;
        case 'role':
          return this.getRole(item);
        case 'date':
          return item.timeStamp;
        default:
          return item[property];
      };
    }
  }

  @Input()
  set users(value: User[]) {
    if (!value)
      value = [];

    value.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());

    this.datasource.data = value;
  }

  @Output()
  updateUsers: EventEmitter<'edit' | 'delete' | 'enable'> = new EventEmitter();

  constructor(
    private auth: AuthService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private router: Router,
    private rolePipe: RolePipe,
    private userService: UserService,
    private exporter: CsvExporterService,
  ) { }

  ngOnInit(): void {
      
    this.currentUser = this.auth.getCurrentUser();

    if (this.currentUser.isAdmin || this.currentUser.isCustomerAdmin)
      this.displayedColumns.push('actions');

  }

  editUser(user: User) {
    let dialogRef = this.dialog.open(AddUserComponent,
    {
      width: '600px',
      data: {
        userId: user.id,
        customerName: user.customerName,
        customerId: user.idCustomer
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.updateUsers.emit('edit');
    });
  }

  async deleteUser(id: number) {
    let dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Disable user')),
        body: await firstValueFrom(this.translate.get('Are you sure you want to disable this user?'))
      }
    });
    
    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.userService.deleteUser(id)
        .subscribe(async () => {
          this.updateUsers.emit('delete');
          this.snackbar.open(await firstValueFrom(this.translate.get('User disabled!')), undefined, { duration: 3000 });
      });
    });
  }

  async enableUser(id: number) {
    let dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom (this.translate.get('Enable user')),
        body: await firstValueFrom (this.translate.get('Are you sure you want to enable this user?'))
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.userService.enableUser(id)
        .subscribe({
          next: async () => {
            this.updateUsers.emit('enable');
            this.snackbar.open(await firstValueFrom(this.translate.get('User enabled!')), undefined, { duration: 3000 });
          },
          error: async () => {
            this.snackbar.open(await firstValueFrom(this.translate.get('Error, an user with this email or username already exists')), undefined, { duration: 3000 });
          }
      });
    });
  }

  goToUserDashboard(userId: number) {
    this.router.navigate(['/user', userId]);
  }

  searchWord(event: Event) {
    let value = (event.target as HTMLInputElement).value;

    this.datasource.filter = value.trim().toLowerCase();
  }

  getRole(user: User) {
    return this.rolePipe.transform(UserRole.role(user));
  }

  exportToCsv() {
    let data: ExportUserCsv[] = [];

    this.datasource.data.forEach(u => {
      data.push({
        username: u.username,
        surname: u.surname,
        name: u.name,
        email: u.email,
        role: this.getRole(u),
        date: new Date(u.timeStamp).toLocaleString()
      });
    });

    this.exporter.exportDataToCsv(
      data,
      ["Username", "Surname", "Name", "Email", "Role", "Creation Date"],
      `User list of ${this.auth.getCurrentUser().customerName}`
    );
  }

  exportDtUserToCsv() {
    let data: ExportDtUserCsv[] = [];

    this.datasource.data.forEach(u => {
      data.push({
        email: u.email,
        name: u.name,
        surname: u.surname,
        province: u.province,
        phoneNumber: u.telephone,
        qualification: u.qualification
      });
    });

    this.exporter.exportDataToCsv(
      data,
      ["Email", "Name", "Surname", "Province", "Phone Number", "Qualification"],
      `User list of Dental Trey E-Learning`
    );
  }

  isSaratogaMode() {
    return this.env.mode === 'saratoga';
  }

  isDT() {
    return this.auth.isDT();
  }

}

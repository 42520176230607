import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ClassroomService } from 'src/app/services/classroom.service';
import { Location } from '@angular/common';
import { CalendarService } from 'src/app/services/calendar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { EduPlanService } from 'src/app/services/eduplan.service';
import { firstValueFrom } from 'rxjs';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { UserRole } from 'src/app/models/userRole';
import { UserDTO } from 'src/app/models/dto/userDTO';

@Component({
  selector: 'app-classroom-id',
  templateUrl: './classroom-id.component.html',
  styleUrls: ['./classroom-id.component.scss']
})
export class ClassroomIdComponent implements OnInit {

  currentUser: User;

  id: number;
  title: string;
  idAuthor: number;
  authorName: string;
  created: Date;
  hasCalendar: boolean = false;
  mode: 'standard' | 'eduplan' = 'standard';
  
  idEduPlan: number;

  users: MatTableDataSource<UserDTO> = new MatTableDataSource();
  allUsers: MatTableDataSource<UserDTO> = new MatTableDataSource();

  publishers: UserDTO[] = [];
  teachers: UserDTO[] = [];
  contents: UserDTO[] = [];

  displayedColumns: string[] = ['name', 'surname', 'role', 'actions'];
  paginatorDefault: number = 25;
  paginatorPages: number[] = [10, 25, 50, 100];
  sending: boolean = false;

  @ViewChild('usersSort') set usersSort(matSort: MatSort) {
    this.users.sort = matSort;

    this.users.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'role':
          return this.getRole(item);
        default:
          return item[property];
      }
    };
  }

  @ViewChild('allUsersSort') set allUsersSort(matSort: MatSort) {
    this.allUsers.sort = matSort;

    this.allUsers.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'role':
          return this.getRole(item);
        default:
          return item[property];
      }
    };
  }

  @ViewChild('usersPaginator') set usersPaginator(matPaginator: MatPaginator) {
    this.users.paginator = matPaginator;
  }

  @ViewChild('allUsersPaginator') set allUsersPaginator(matPaginator: MatPaginator) {
    this.allUsers.paginator = matPaginator;
  }

  constructor(private auth: AuthService,
              private userService: UserService,
              private router: Router,
              private classroomService: ClassroomService,
              private eduPlanService: EduPlanService,
              private route: ActivatedRoute,
              private location: Location,
              private calendar: CalendarService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private darkService: DarkThemeService,
              private translate: TranslateService,
              private rolePipe: RolePipe) { }

  ngOnInit() {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.title =  this.route.snapshot.queryParamMap.get('title');
    this.hasCalendar = this.route.snapshot.queryParamMap.get('calendar') == 'true' ? true : false;

    if (this.route.snapshot.queryParamMap.has('idAuthor'))
      this.idAuthor = Number(this.route.snapshot.queryParamMap.get('idAuthor'));

    if (this.route.snapshot.queryParamMap.has('authorName'))
      this.authorName = this.route.snapshot.queryParamMap.get('authorName');

    if (this.route.snapshot.queryParamMap.has('created'))
      this.created = new Date(this.route.snapshot.queryParamMap.get('created'));

    if (this.route.snapshot.queryParamMap.has('idEduPlan')) {
      this.idEduPlan = Number(this.route.snapshot.queryParamMap.get('idEduPlan'));
      this.mode = 'eduplan';
    }

    this.currentUser = this.auth.getCurrentUser();
    this.getUsersByClassroom();
  }

  async getUsersByClassroom() {
    this.publishers = await firstValueFrom(this.classroomService.getUsersOfClassroom(this.id, 1, true));
    this.teachers = await firstValueFrom(this.classroomService.getUsersOfClassroom(this.id, 1));
    this.contents = await firstValueFrom(this.classroomService.getUsersOfClassroom(this.id, 2));

    let users = this.teachers.concat(this.contents);

    users.sort((a: UserDTO, b: UserDTO) => a.surname.toUpperCase() > b.surname.toUpperCase() ? 1 : -1);

    this.users.data = users;

    this.getUsersNotInClassroom();
  }

  getUsersNotInClassroom() {
    let availableRoles = [];

    if (this.currentUser.isTeacher || this.currentUser.isTutor || this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) {
      availableRoles.push(UserRole.Student);
      availableRoles.push(UserRole.Teacher);
    }

    if (this.currentUser.isTeacher || this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) {
      availableRoles.push(UserRole.Teacher);
    }

    if (this.currentUser.isTutor || this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) {
      availableRoles.push(UserRole.Speaker);
      availableRoles.push(UserRole.Tutor);
    }

    firstValueFrom(this.userService.getAll(availableRoles, undefined, undefined))
      .then(res => {
        let users = res.filter(x => this.users.data.findIndex(u => u.id === x.id) === -1) as UserDTO[];

        users.sort((a, b) => a.surname.toUpperCase() > b.surname.toUpperCase() ? 1 : -1);

        this.allUsers.data = users;
      });
  }

  async removeUserFromClass(user: any) {
    let dialogData = <GenericPopupData>{
      title: 'User action',
      body: 'Are you sure you want to remove this user from this classroom?'
    };

    if (this.isEduPlanMode()) {
      dialogData = <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Remove user from educational plan')),
        body: await firstValueFrom(this.translate.get('Are you sure to remove {{surname}} {{name}} from this educational plan?', { surname: user.surname, name: user.name }))
      };
    }
    
    const dialogRef = this.dialog.open(GenericPopupComponent, { width: '400px', data: dialogData });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.sending = true;

      let operation = this.isEduPlanMode()
                    ? this.eduPlanService.removeUserFromEduPlan(this.idEduPlan, user.id)
                    : this.classroomService.removeUserFromClass(user.id, this.id);

      operation.subscribe({
        next: () => this.getUsersByClassroom(),
        error: err => this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 }),
        complete: () => this.sending = false
      });
    });
  }

  async addUserToCurrentClass(user: any) {
    let dialogData = <GenericPopupData>{
      title: 'User action',
      body: 'Are you sure you want to add this user to this classroom?'
    };

    if (this.isEduPlanMode()) {
      dialogData = <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Add user to educational plan')),
        body: await firstValueFrom(this.translate.get('Are you sure to add {{surname}} {{name}} to this educational plan?', { surname: user.surname, name: user.name }))
      };
    }

    const dialogRef = this.dialog.open(GenericPopupComponent, { width: '400px', data: dialogData });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.sending = true;

      let operation = this.isEduPlanMode()
                    ? this.eduPlanService.addUserToEduPlan(this.idEduPlan, user.id)
                    : this.classroomService.addUserToClass(user.id, this.id);

      operation.subscribe({
        next: () => this.getUsersByClassroom(),
        error: err => this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 }),
        complete: () => this.sending = false
      });
    });
  }

  async toggleUserAsPublisher(user: UserDTO) {
    let isPublisher = this.isPublisher(user.id);

    let title = isPublisher
              ? await firstValueFrom(this.translate.get('Remove name from publishers', { name: `${user.name} ${user.surname}` }))
              : await firstValueFrom(this.translate.get('Set name as publisher', { name: `${user.name} ${user.surname}` }));

    let body = isPublisher
             ? await firstValueFrom(this.translate.get('Are you sure to remove name from publishers?', { name: `${user.name} ${user.surname}` }))
             : await firstValueFrom(this.translate.get('Are you sure to set name as publisher?', { name: `${user.name} ${user.surname}` }));

    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: title,
        body: body
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      (isPublisher ? this.classroomService.removeUserFromPublishers(this.id, user.id) : this.classroomService.setUserAsPublisher(this.id, user.id))
        .subscribe({
          next: async () => {
            this.snackBar.open(await firstValueFrom(this.translate.get(isPublisher ? 'name removed from publishers' : 'name added as publisher', { name: `${user.name} ${user.surname}` })));
            this.getUsersByClassroom();
          },
          error: err => this.snackBar.open(err.Message, 'Dismiss', { duration: 3000 })
        });
    });
  }

  async transferClassroom() {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Classroom transfer')),
          body: await firstValueFrom(this.translate.get('Are you sure to transfer this classroom?'))
        }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.calendar.trasferClassroom(this.id)
        .subscribe({
          next: () => {
            this.snackBar.open("Classroom transfer completed", 'Dismiss', { duration: 3000 });
            this.router.navigate(['/classrooms']);
          },
          error: err => this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 })
        });
    });
  }

  addCalendar() {
    const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: 'Google calendar',
          body: 'Are you sure to add a Google calendar to this classroom?'
        }
      });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;
      
      this.sending = true;

      this.calendar.addCalendarToClassroom(this.id)
      .subscribe({
        next: () => {
          this.hasCalendar = true;
          this.snackBar.open('Calendar added to classroom', 'Dismiss', { duration: 3000 });
        },
        error: err => {
          this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
        }
      })
      .add(() => this.sending = false);
    });
  }

  removeCalendar() {
    const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: 'Google calendar',
          body: 'Are you sure to remove Google calendar from this classroom?'
        }
      });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.calendar.removeCalendarFromClassroom(this.id)
      .subscribe({
        next: () => {
          this.hasCalendar = false;
          this.snackBar.open('Calendar removed from classroom', 'Dismiss', { duration: 3000 });
        },
        error: err => {
          this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
        }
      })
      .add(() => this.sending = false);
    });
  }

  searchWord(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    this.users.filter = value.trim().toLowerCase();
    this.allUsers.filter = value.trim().toLowerCase();
  }

  isDark() {
    return this.darkService.isSetDark;
  }

  isTeacher(userId: number) {
    return this.teachers.some(u => u.id === userId);
  }

  isPublisher(userId: number) {
    return this.publishers.some(p => p.id === userId);
  }

  getRole(user: UserDTO) {
    return this.rolePipe.transform(UserRole.role(user));
  }

  isStandardMode() {
    return this.mode === 'standard';
  }

  isEduPlanMode() {
    return this.mode === 'eduplan';
  }

  goBack() {
    this.location.back();
  }
}

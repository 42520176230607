<div class="component-main" [class.margin]="!isAuthenticated()">
    <div *ngIf="!isMobile; else mobileView " fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <button mat-stroked-button (click)="goBack()">
                <mat-icon matListIcon>arrow_back</mat-icon>
                {{ 'Back' | translate }}
            </button>
            <button mat-fab color="primary" (click)="addPaidUsers()" [matTooltip]="'Users' | translate"
                *ngIf="currentUser?.isCustomerAdmin && currentUser?.idCustomer === course.idCustomer"
                [disabled]="course.state !== 1">
                <mat-icon>people</mat-icon>
            </button>
            <mat-form-field *ngIf="currentUser?.isAdmin" color="primary">
                <mat-select [(ngModel)]="course.visibility"
                    (selectionChange)="changeVisibility($event)">
                    <mat-option [value]="0">{{ 'Private' | translate }}</mat-option>
                    <mat-option [value]="1">{{ 'Public' | translate }}</mat-option>
                    <mat-option [value]="2">{{ 'Public to all customers' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-stroked-button [cdkCopyToClipboard]="href">
                <mat-icon matListIcon>content_copy</mat-icon>
                {{ 'Copy link' | translate }}
            </button>
        </div>
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
            
            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex >
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Digital Contents' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                    <div fxLayoutAlign="center end" fxFlex="20">
                        <button mat-fab style="background-color: #26c6da;" class="mat-elevation-z0 btnNoHover">
                            <mat-icon style="color: white;">
                                video_library
                            </mat-icon>
                        </button>
                    </div>
                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                        <h2>
                           {{ totVideo + totFile }}
                        </h2>

                        <h4 *ngIf="this.totVideo > 0">
                            {{ totVideo + ' ' + ('hi-res videos' | translate) }}
                            {{ totFile > 0 ? (' ' + ('and' | translate)  + ' ' + totFile + ' ' + ('pdf files' | translate)) : '' }}
                        </h4>
                        
                        <h4 *ngIf="this.totVideo === 0 && this.totFile > 0">
                            {{ totFile + ' ' + ('pdf files' | translate) }}
                        </h4>

                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex>
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Course duration' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                    <div fxLayoutAlign="center end" fxFlex="20">
                        <button mat-fab style="background-color: #26c6da;" class="mat-elevation-z0 btnNoHover">
                            <mat-icon style="color: white;">
                                timer
                            </mat-icon>
                        </button>
                    </div>
                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                        <h2>
                           {{ getDuration() }}
                        </h2>
                        <h4>
                            {{ getDuration() + ' ' + ('hours of training' | translate) }}
                        </h4>
                    </div>
                </mat-card-content>
            </mat-card>
           
            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex>
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Surveys' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                    <div fxLayoutAlign="center end" fxFlex="20">
                        <button mat-fab style="background-color: #ffb22b;" class="mat-elevation-z0 btnNoHover">
                            <mat-icon style="color: white;">
                                assignment
                            </mat-icon>
                        </button>
                    </div>
                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                        <h2>
                            {{ totSurvey }}
                        </h2>
                        <h4>{{ 'Surveys available' | translate }}</h4>
                    </div>
                </mat-card-content>
            </mat-card>

            <app-price-card class="mat-elevation-z4" fxFlex
                [routeEnabled]="course.state === getCourseState().online &&
                                (currentUser?.id === course.idAuthor || currentUserIsParticipant || (currentUser?.isCustomerAdmin && currentUser?.idCustomer === course.idCustomer))"
                [routeUrl]="'/course'"
                [routeId]="course.id"
                [queryParams]="{ page: 'sidenav' }"
                [routeMessage]="'Go to course'"
                [subtitle]="getSubTitle()"
                [subscription]="course.subscription"
                [buyShow]="course.state === getCourseState().online &&
                           (currentUser?.isStudent || currentUser?.isSpeaker || currentUser?.isTeacher || currentUser?.isTutor) &&
                           currentUser?.id !== course.idAuthor &&
                           !fromPackage"    
                [buyDisabled]="currentUserIsParticipant || !canpurchased || currentstate != 1 || !isstartget"
                (buyFree)="payCourseFree()"
                (buyPay)="addToCartCourse()"
                [logInToBuy]="true">
            </app-price-card>
        </div>

        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">

            <div fxLayout="column" fxFlex="20">

                <div *ngIf="previews.length > 0" class="preview-container">
                    <app-player-events
                        [media]="previews[0].media"
                        [pause]="previews[0].pause"
                        [enableControls]="false"
                        [disableSeekCheck]="true"
                        [showMute]="true"
                        [showPlay]="true"
                        [mute]="false"
                        [(startTime)]="previews[0].marker"
                        (paused)="updateVideo($event, 0)"
                        (time)="updateVideo($event, 0)"
                        (ended)="restartVideo(0)">
                    </app-player-events>
                </div>

            </div>

            <mat-card appearance="outlined" *ngIf="course" class="mat-elevation-z4" fxLayout="column" fxFlex>
                <img mat-card-image [src]="course.imageUrl" [alt]="course.name" loading="lazy">
                <mat-card-header>
                    <mat-card-title>
                        {{ course.name }}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{ (course.created | date: 'dd/MM/yyyy') }}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxLayoutGap="10px" style="overflow: hidden;">
                    <span *ngIf="course.header" style="font-size: 14px; font-weight: bold;">{{ course.header }}</span>
                    <div [innerHtml]="course.description">
                    </div>
                    <div [innerHTML]="course.showcaseSummary">
                    </div>
                </mat-card-content>
                <div fxFlex></div>
                <mat-card-actions *ngIf="course" fxLayout="row" fxLayoutAlign="end center">
                    <!--div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                        <button mat-button [matTooltip]="'Share' | translate" (click)="share()">
                            <mat-icon>share</mat-icon>
                        </button>
                    </div-->
                </mat-card-actions>
            </mat-card>

            <div fxLayout="column" fxFlex="20">

                <div *ngIf="previews.length > 1" class="preview-container">
                    <app-player-events
                        [media]="previews[1].media"
                        [pause]="previews[1].pause"
                        [enableControls]="false"
                        [disableSeekCheck]="true"
                        [showMute]="true"
                        [showPlay]="true"
                        [mute]="false"
                        [(startTime)]="previews[1].marker"
                        (paused)="updateVideo($event, 1)"
                        (time)="updateVideo($event, 1)"
                        (ended)="restartVideo(1)">
                    </app-player-events>
                </div>

            </div>

        </div>
    </div>

    <ng-template #mobileView>
        <p *ngIf="isDev()">DEV Version</p>
        <div style="height:2vh"></div>
        <div style="display:flex;justify-content: center;flex-direction:column;align-items:center; background-size: 100%;" [style.height]="!showDeepLinkButton ? '90vh' : ''">
            <app-showcase-card
                [routeUrl]="'/course'"
                [routeId]="course.id"
                [queryParams]="{ page: 'sidenav' }"
                [imgUrl]="course.imageUrl"
                [title]="course.name"
                [subtitle_extra]="course.header"
                [subtitle]="course.created | date: 'dd/MM/yyyy'"
                [description]="course.description"
                [isMobile]="true"
                [subscription]="course?.subscription"
                [buyShow]="course.state === getCourseState().online &&
                           (currentUser?.isStudent || currentUser?.isSpeaker || currentUser?.isTeacher || currentUser?.isTutor) &&
                           !fromPackage"    
                [buyDisabled]="currentUserIsParticipant || !canpurchased || currentstate != 1 || !isstartget || currentUser?.id === course.idAuthor"
                (buyFree)="payCourseFree()"
                (buyPay)="addToCartCourse()">

                <app-showcase-card-author-info bodyBottom
                    [author]="course.author"
                    mode="author"
                    padding="0 16px">
                </app-showcase-card-author-info>

            </app-showcase-card>
            <ng-container *ngIf="showDeepLinkButton && (isDT() || isAixp() || isDev())">
                <button style="margin-top:25px;height:10vh;width:180px;"
                    color="primary"
                    (click)="createDeepLink(course.id)"
                    mat-raised-button>
                    {{ "Open in app" | translate }}
                </button>
            </ng-container>
        </div>
    </ng-template>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CalendarService } from 'src/app/services/calendar.service';
import { CustomerNewEntity } from 'src/app/models/dto/customerNewEntity';
import { CartItemDTO } from 'src/app/ecommerce/models/cart-item-dto';
import { CheckoutService } from 'src/app/ecommerce/service/checkout.service';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { SubscriptionLevelDTO } from 'src/app/models/dto/subscriptionLevelDTO';
import { firstValueFrom } from 'rxjs';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss']
})
export class CustomerAddComponent implements OnInit {
  
  name: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(4)]);
  description: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  isDemo:UntypedFormControl = new UntypedFormControl(1, [Validators.required]);
  demoDuration:UntypedFormControl = new UntypedFormControl(30, [Validators.required, Validators.min(30), Validators.max(365)]);
  storagePath: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_]*$/)]);
  email: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);

  isLevel: boolean = false;
  subscriptionLevel: SubscriptionLevelDTO = null

  constructor(public dialogRef: MatDialogRef<CustomerAddComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private calendar: CalendarService,
              private snackBar: MatSnackBar,
              private router: Router,
              private checkout: CheckoutService,
              private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.subscriptionLevel = this.data?.level;
    this.isLevel = this.subscriptionLevel != null;

    if (this.isLevel)
      this.isDemo.setValue(0);
  }

  async saveCustomer() {
    this.toggleDisable(true);

    let customer: CustomerNewEntity = {
      name: this.name.value,
      description: this.description.value,
      storagePath: this.storagePath.value,
      isDemo: this.isDemo.value,
      demoDuration: this.demoDuration.value,
      email: this.email.value,
      frontEndType: EnvironmentHelper.isSaratoga() ? 0 : 1
    };

    if (this.isLevel) {

      let sub = await firstValueFrom(this.subscriptionService.getById(this.subscriptionLevel.idSubscription));
  
      let item = new CartItemDTO();
      item.id = 1;
      item.subscriptionId = sub.id;
      item.name = sub.name;
      item.description = sub.description;
      item.image = this.subscriptionLevel.imageUrl;
      item.priceEuro = sub.subscriptionFee?.euro ?? 0;
      item.priceUsd = sub.subscriptionFee?.dollar ?? 0;
      item.priceGbp = sub.subscriptionFee?.pound ?? 0;
      item.currency = "eur";
      item.unitAmount = item.priceEuro;
      item.customer = sub.customer;
      item.subscription = sub;
      item.nestedSubscriptions = [];
      item.jsonData = JSON.stringify(customer);

      this.checkout
        .startCheckout([item])
        .then(() => this.toggleDisable(false));

    } else {

	    this.calendar.createCustomer(customer)
	      .subscribe({
	        next: () => {
	          this.snackBar.open('Customer added successfully','Dismiss', { duration: 3000 });
	          this.toggleDisable(false);
	          this.dialogRef.close(true);
	
	          this.router.navigate(['/dashboard']);
	        },
	        error: err => {
	          this.snackBar.open(err.error.Message,'Dismiss', { duration: 3000 });
	          this.toggleDisable(false);
	          this.onNoClick();
	        }
	      });

    }
  }
  
  onNoClick() {
    this.dialogRef.close(false);
  }

  btnDisabled() {
    let check = !this.name.valid ||
                !this.description.valid ||
                !this.storagePath.valid ||
                !this.isDemo.valid;

    if (this.isLevel)
      return check ||
             !this.email.valid;

    if (this.isDemo.value !== 0)
     return check ||
            !this.demoDuration.valid;

    return check;
  }
  
  toggleDisable(toggle: boolean) {
    if(toggle) {
      this.name.disable();
      this.description.disable();
      this.storagePath.disable();
      this.isDemo.disable();
      this.demoDuration.disable();
      this.email.disable();
    } else {
      this.name.enable();
      this.description.enable();
      this.storagePath.enable();
      this.isDemo.enable();
      this.demoDuration.enable();
      this.email.enable();
    }
  }
}

globalThis.getScreenId = function (firefoxString, callback, custom_parameter) {
  if (navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob)) {
    callback({
      video: true
    });
    return;
  }
  if (!!navigator.mozGetUserMedia) {
    callback(null, 'firefox', {
      video: {
        mozMediaSource: firefoxString,
        mediaSource: firefoxString
      }
    });
    return;
  }
  globalThis.addEventListener('message', onIFrameCallback);
  function onIFrameCallback(event) {
    if (!event.data) return;
    if (event.data.chromeMediaSourceId) {
      if (event.data.chromeMediaSourceId === 'PermissionDeniedError') {
        callback('permission-denied');
      } else {
        callback(null, event.data.chromeMediaSourceId, getScreenConstraints(null, event.data.chromeMediaSourceId, event.data.canRequestAudioTrack));
      }
      globalThis.removeEventListener('message', onIFrameCallback);
    }
    if (event.data.chromeExtensionStatus) {
      callback(event.data.chromeExtensionStatus, null, getScreenConstraints(event.data.chromeExtensionStatus));
      globalThis.removeEventListener('message', onIFrameCallback);
    }
  }
  if (!custom_parameter) {
    setTimeout(postGetSourceIdMessage, 100);
  } else {
    setTimeout(function () {
      postGetSourceIdMessage(custom_parameter);
    }, 100);
  }
};
function getScreenConstraints(error, sourceId, canRequestAudioTrack) {
  var screen_constraints = {
    audio: false,
    video: {
      mandatory: {
        chromeMediaSource: error ? 'screen' : 'desktop',
        maxWidth: globalThis.screen.width > 1920 ? globalThis.screen.width : 1920,
        maxHeight: globalThis.screen.height > 1080 ? globalThis.screen.height : 1080
      },
      optional: []
    }
  };
  if (!!canRequestAudioTrack) {
    screen_constraints.audio = {
      mandatory: {
        chromeMediaSource: error ? 'screen' : 'desktop'
      },
      optional: []
    };
  }
  if (sourceId) {
    screen_constraints.video.mandatory.chromeMediaSourceId = sourceId;
    if (screen_constraints.audio && screen_constraints.audio.mandatory) {
      screen_constraints.audio.mandatory.chromeMediaSourceId = sourceId;
    }
  }
  return screen_constraints;
}
function postGetSourceIdMessage(custom_parameter) {
  if (!iframe) {
    loadIFrame(function () {
      postGetSourceIdMessage(custom_parameter);
    });
    return;
  }
  if (!iframe.isLoaded) {
    setTimeout(function () {
      postGetSourceIdMessage(custom_parameter);
    }, 100);
    return;
  }
  if (!custom_parameter) {
    iframe.contentWindow.postMessage({
      captureSourceId: true
    }, '*');
  } else if (!!custom_parameter.forEach) {
    iframe.contentWindow.postMessage({
      captureCustomSourceId: custom_parameter
    }, '*');
  } else {
    iframe.contentWindow.postMessage({
      captureSourceIdWithAudio: true
    }, '*');
  }
}
var iframe;
globalThis.getScreenConstraints = function (callback) {
  loadIFrame(function () {
    getScreenId(function (error, sourceId, screen_constraints) {
      if (!screen_constraints) {
        screen_constraints = {
          video: true
        };
      }
      callback(error, screen_constraints.video);
    });
  });
};
function loadIFrame(loadCallback) {
  if (iframe) {
    loadCallback();
    return;
  }
  iframe = document.createElement('iframe');
  iframe.onload = function () {
    iframe.isLoaded = true;
    loadCallback();
  };
  iframe.src = 'https://openvidu.github.io/openvidu-screen-sharing-chrome-extension/';
  iframe.style.display = 'none';
  (document.body || document.documentElement).appendChild(iframe);
}
globalThis.getChromeExtensionStatus = function (callback) {
  if (!!navigator.mozGetUserMedia) {
    callback('installed-enabled');
    return;
  }
  globalThis.addEventListener('message', onIFrameCallback);
  function onIFrameCallback(event) {
    if (!event.data) return;
    if (event.data.chromeExtensionStatus) {
      callback(event.data.chromeExtensionStatus);
      globalThis.removeEventListener('message', onIFrameCallback);
    }
  }
  setTimeout(postGetChromeExtensionStatusMessage, 100);
};
function postGetChromeExtensionStatusMessage() {
  if (!iframe) {
    loadIFrame(postGetChromeExtensionStatusMessage);
    return;
  }
  if (!iframe.isLoaded) {
    setTimeout(postGetChromeExtensionStatusMessage, 100);
    return;
  }
  iframe.contentWindow.postMessage({
    getChromeExtensionStatus: true
  }, '*');
}
exports.getScreenId = globalThis.getScreenId;

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VideoSessionService } from 'src/app/services/video-session.service';
import { EmergencyService } from 'src/app/services/emergency.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { AirTimeDTO } from 'src/app/models/dto/airTimeDTO';
import { firstBy } from 'thenby';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { firstValueFrom } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { User } from 'src/app/models/user';
import { UserRole } from 'src/app/models/userRole';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';

const DATA_CHECK_INTERVAL: number = 5000; //ms

@Component({
  selector: 'app-streaming-info',
  templateUrl: './streaming-info.component.html',
  styleUrls: ['./streaming-info.component.scss']
})
export class StreamingInfoComponent implements OnInit, OnDestroy {

  currentUser: User;
  dataInterval: any;

  sessions = [];
  backupSessions = [];
  selectedSessionId: string;
  selectedSession: MatTableDataSource<any>;
  airtimes: MatTableDataSource<any>;

  streamingColumns: string[] = ['usr', 'urole', 'role', 'connId', 'ids', 'platform', 'date'];
  usersColumns: string[] = ['usr', 'cname', 'role', 'fdate', 'station'];
  paginatorDefault: number = 10;
  paginatorPages: number[] = [10, 25, 50];
  
  @ViewChild('airtimesPaginator') airtimesPaginator: MatPaginator;
  @ViewChild('airtimesSort') airtimesSort: MatSort;

  constructor(private auth: AuthService,
              private videoSessionService: VideoSessionService,
              private caledar: CalendarService,
              private emergencyService: EmergencyService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private rolePipe: RolePipe,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.getSessions();
    this.getAirTimes();

    this.startDataCheck();
  }

  ngOnDestroy(): void {
    this.stopDataCheck();
  }

  getSessions() {
    this.videoSessionService.getOpenSessions()
      .subscribe(output => {

        this.backupSessions = output;
        this.backupSessions.forEach(s => s.connections.content.sort((a: any, b: any) => a.role > b.role ? 1 : b.role > a.role ? -1 : 0));

        let updateAll = true;

        if (this.selectedSession) {
          
          let currentIndex = this.sessions.findIndex(s => s.id === this.selectedSessionId);
          let newIndex = this.backupSessions.findIndex(s => s.id === this.selectedSessionId);

          if (currentIndex !== -1 && newIndex !== -1) {
            updateAll = false;

            //this.sessions[currentIndex] = this.backupSessions[newIndex];
            this.sessions[currentIndex].connections.numberOfElements = this.backupSessions[newIndex].connections.numberOfElements;
            this.setCurrentSession(this.backupSessions[newIndex]);
          }

        }

        if (updateAll)
          this.sessions = this.backupSessions.slice();

      });
  }

  getAirTimes() {
    this.caledar.getOnlineUsersAirTime()
      .subscribe(output => {

        if (!output || output.length === 0) {
          this.airtimes = undefined;
          return;
        }

        output.sort(firstBy((c: AirTimeDTO) => c.user.customerId).thenBy((u: AirTimeDTO) => u.user.id));

        if (!this.airtimes) {
          this.airtimes = new MatTableDataSource();
          this.airtimes.paginator = this.airtimesPaginator;
          this.airtimes.sort = this.airtimesSort;
  
          this.airtimes.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'cname':
                return item.user.customer.name;
              case 'usr':
                return `${item.user.name} ${item.user.surname}`;
              case 'role':
                return this.rolePipe.transform(this.getUserRole(item.user));
              case 'fdate':
                return item.startDate;
              case 'station':
                return item.station?.name ?? 'Remote Connection';
              default:
                return item[property];
            }
          };
        }

        this.airtimes.data = output;

      });
  }

  async closeSession(id: number) {
    let dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Close lesson')),
        body: await firstValueFrom(this.translate.get('Are you sure you want to close your open lessons?'))
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result)
        return;

      await firstValueFrom(this.emergencyService.UnlockLessons(id))
        .then(success => {
          this.snackBar.open('Session closed successfully', '', { duration: 3000 });
        }).catch(err => {
          console.error(err);
          this.snackBar.open('Error closing session', '', { duration: 3000 });
        }).finally(() => {
          this.getSessions();
        });
    });
  }

  setCurrentSession(session?: any) {
    if (session) {

      this.selectedSessionId = session.id;
      this.selectedSession = this.selectedSession
                           ? this.selectedSession.data = session.connections?.content ?? []
                           : new MatTableDataSource(session.connections?.content ?? []);

    } else {

      this.selectedSessionId = undefined;
      this.selectedSession = undefined;

      this.sessions = this.backupSessions.slice();

    }
  }

  getStreamIds(obj: any) {
    return obj.role == 'PUBLISHER' ?
           obj.publishers.map((element: any) => `${element.streamId}\n`) :
           obj.subscribers.map((element: any) => `${element.streamId}\n`);
  }

  getUserRole(user: UserDTO) {
    return UserRole.role(user);
  }

  getLessonType(typeId: number) {
    if (typeId === 2)
      return 'Quick Lesson';

    if (typeId === 3)
      return 'Master Lesson';

    if (typeId === 4)
      return 'Survey';

    if (typeId === 5)
      return 'Course Survey';

    if (typeId === 6)
      return 'Conference';

    return 'Standard Lesson';
  }

  startDataCheck() {
    this.stopDataCheck();

    this.dataInterval = setInterval(() => {

      this.getSessions();
      this.getAirTimes();

    }, DATA_CHECK_INTERVAL);
  }

  stopDataCheck() {
    if (!this.dataInterval)
      return;

    clearInterval(this.dataInterval);
    this.dataInterval = undefined;
  }
}

import { Helper } from "./helper";

const FB_URL: string = 'https://www.facebook.com/sharer/sharer.php';
const TW_URL: string = 'https://twitter.com/intent/tweet';
const IN_URL: string = 'https://www.linkedin.com/sharing/share-offsite/';
const PI_URL: string = 'https://pinterest.com/pin/create/button/';
const WA_URL: string = 'https://web.whatsapp.com/send';
const TG_URL: string = 'https://t.me/share/url'
const SK_URL: string = 'https://web.skype.com/share';
const ML_URL: string = 'mailto:';

export class SocialShare {

    constructor() {}

    public static share(provider: SocialProviders, title?: string): void {
        window.open(this.getFinalUrl(provider, this.getCurrentUrl(), title), '_blank');
    }

    private static getCurrentUrl(): string {
        return Helper.getUrl();
    }

    private static getTitle(provider: SocialProviders, title: string): string {
        if (Helper.isNullOrEmpty(title)) {
            if (provider === SocialProviders.mail) return `?body=`;

            return null;
        }

        var text = encodeURIComponent(title);

        if (provider === SocialProviders.facebook) return null;
        if (provider === SocialProviders.twitter) return `&text=${text}`;
        if (provider === SocialProviders.linkedin) return `&summary=${text}`;
        if (provider === SocialProviders.pinterest) return `&description=${text}`;
        if (provider === SocialProviders.whatsapp) return ` ${text}`;
        if (provider === SocialProviders.telegram) return `&text=${text}`;
        if (provider === SocialProviders.skype) return `&text=${text}`;
        if (provider === SocialProviders.mail) return `?subject=${text}&body=`;
    }

    private static getFinalUrl(provider: SocialProviders, url: string, title?: string): string {
        var url = encodeURIComponent(url);
        var title = this.getTitle(provider, title);

        if (provider === SocialProviders.facebook) return `${FB_URL}?u=${url}`;
        if (provider === SocialProviders.twitter) return `${TW_URL}?url=${url}${title}`;
        if (provider === SocialProviders.linkedin) return `${IN_URL}?url=${url}${title}`;
        if (provider === SocialProviders.pinterest) return `${PI_URL}?url=${url}&media=${url}${title}`;
        if (provider === SocialProviders.whatsapp) return `${WA_URL}?text=${url}${title}`;
        if (provider === SocialProviders.telegram) return `${TG_URL}?url=${url}${title}`;
        if (provider === SocialProviders.skype) return `${SK_URL}?url=${url}&source=button${title}`;
        if (provider === SocialProviders.mail) return `${ML_URL}${title}${url}`;

        return null;
    }
}

export enum SocialProviders {
    facebook = 'fb',
    twitter = 'tw',
    linkedin = 'in',
    pinterest = 'pi',
    whatsapp = 'wa',
    telegram = 'tg',
    skype = 'sk',
    mail = 'ml'
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { WarningDTO } from 'src/app/models/dto/warningDTO';
import { WarningType } from 'src/app/models/dto/warningTypeDTO';
import { User } from 'src/app/models/user';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { WarningService } from 'src/app/services/warning.service';

@Component({
  selector: 'app-warning-list',
  templateUrl: './warning-list.component.html',
  styleUrls: ['./warning-list.component.scss']
})
export class WarningListComponent implements OnInit {

  currentUser: User;
  warnings: WarningDTO[] = [];

  constructor(private warningService: WarningService,
              private auth: AuthService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.getWarnings();
  }

  async getWarnings() {
    this.warnings = await this.warningService.updateWarnings();
  }

  async acknowledgeWarning(id: number) {
    var dialogRef = this.dialog.open(GenericPopupComponent, {
      data: <GenericPopupData> {
        title:  await firstValueFrom(this.translate.get('Notification acknowledge')),
        body: await firstValueFrom(this.translate.get('Are you sure to acknowledge this notification?'))
      }
    });
    
    dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.warningService.acknowledgeWarning(id)
            .subscribe({
              next: async () => {
                this.snackBar.open(await firstValueFrom(this.translate.get('Warning aknowledged')), 'Dismiss', { duration: 3000 });
                this.getWarnings();
              },
              error: err => this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 })
            });
        }
    });
  }

  acknowledgeEnabled(warning: WarningDTO) {
    if ((this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) && warning.idSourceCustomer === this.currentUser.idCustomer)
      return true;

    if (warning.idTargetUser === this.currentUser.id)
      return true;

    return false;
  }

  getWarningIcon(type: number) {
    if (type === WarningType.DemoExpiration)
      return 'schedule';
  }
}

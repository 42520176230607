import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SurveyService } from 'src/app/services/survey.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { addHours, addMinutes, isBefore } from 'date-fns';
import { Helper } from 'src/app/helpers/helper';

@Component({
  selector: 'app-add-survey-pop-up',
  templateUrl: './add-survey-pop-up.component.html',
  styleUrls: ['./add-survey-pop-up.component.scss']
})
export class AddSurveyPopUpComponent implements OnInit {
  currentUser: User;
  sending: boolean = false;
  
  dialogTitle: string;
  eventData: EventDialogData;
  surveys: SurveyDTO[];
  page: string;

  // Mostra l'ora da cambiare oppure il calendario (input type 'time' oppure 'date')
  showDateOrTime: boolean = true; //default 'date' (true)

  titleForm: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  descriptionForm: UntypedFormControl = new UntypedFormControl('',[Validators.required]);
  teacherForm: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  startDateForm: UntypedFormControl = new UntypedFormControl(null, [Validators.required, this.dateValidator]);
  endDateForm: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
  classroomForm: UntypedFormControl = new UntypedFormControl(undefined);
  selectedSurvey: number = 0;

  //Solo per survey come contenuto dei corsi
  orderForm: UntypedFormControl = new UntypedFormControl(1, [Validators.required]);

  constructor(private auth: AuthService,
              private dialogRef: MatDialogRef<AddSurveyPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) private data,
              private datePipe: DatePipe,
              private cdr: ChangeDetectorRef,
              private surveyService: SurveyService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.page = this.data.page;
    this.dialogTitle = this.eventData?.dialogTitle;
    this.eventData = this.data.eventData;
    this.showDateOrTime = this.data?.dateOrTime ?? true;

    let startDate = addMinutes(new Date(), 5);
    let endDate = addHours(startDate, 2);

    if (this.eventData.startData)
      startDate = new Date(this.eventData.startData);

    if (this.eventData.endData)
      endDate = new Date(this.eventData.endData);

    if (endDate < startDate)
      endDate = addHours(startDate, 2);

    this.eventData.startData = startDate;

    this.startDateForm.setValue(this.datePipe.transform(startDate, this.showDateOrTime ? 'yyyy-MM-ddTHH:mm' : 'HH:mm'));
    this.endDateForm.setValue(this.datePipe.transform(endDate, 'HH:mm'));

    this.orderForm.setValue(this.eventData.mode === 1 ? 1 : 3);

    if (this.eventData.teacherId != null)
      this.teacherForm.setValue(this.eventData.teacherId, { emitEvent: true });

    if (this.eventData.title != null)
      this.titleForm.setValue(this.eventData.title);

    if (this.eventData.description != null)
      this.descriptionForm.setValue(this.eventData.description);

    if (this.eventData.orderIndex && this.eventData.mode === 1)
      this.orderForm.setValue(this.eventData.orderIndex + 1);

    if (this.eventData.classId != null)
      this.classroomForm.setValue(this.eventData.classId, { emitEvent: true });

    this.surveyService.getSurveys()
      .subscribe(res => {
        this.surveys = res;
        this.selectedSurvey = 0;
    });
  }

  isStandardSurvey() {
    return this.eventData?.type === 4;
  }

  isCourseSurvey() {
    return this.eventData?.type === 5;
  }

  okBtnDisabled() {
    let check = this.sending;

    if (this.selectedSurvey === 0)
      check = check ||
              !this.titleForm.valid ||
              !this.descriptionForm.valid;

    if (!this.isCourseSurvey())
      check = check ||
              this.negativeSpan();

    return check;
  }

  onNoClick(): void {
    this.dialogRef.close(); 
  }

  negativeSpan(): boolean {
    let startDate = this.getStartDate();
    let endDate = this.getEndDate();

    if (endDate <= startDate) {
       this.endDateForm.setErrors({ negativeSpan: this.endDateForm.value });
       this.cdr.detectChanges();
       return true;
    }

    return false;
  }

  dateValidator(control: UntypedFormControl): { [s: string]: boolean } {
    if (!control.value)
      return null;

    if (isBefore(new Date(control.value), new Date()))
      return { 'invalidDate': true };
    
    return null;
  }

  async getResult() {
    this.sending = true;

    this.eventData.startData = this.getStartDate().toUTCString();
    this.eventData.endData = this.getEndDate().toUTCString();
    this.eventData.title = this.titleForm.value;
    this.eventData.description = this.descriptionForm.value;
    // this.eventData.recordPlan = this.recordForm.value;
    // this.eventData.private = this.privateForm.value;
    // this.eventData.roomId = this.roomForm.value;
    this.eventData.classId = this.classroomForm.value;
    this.eventData.teacherId = this.teacherForm.value;
    this.eventData.orderIndex = this.orderForm.value;

    // se ho scelto un survey lo restituisco
    if (this.selectedSurvey !== 0)
      this.eventData.survey = this.getSurvey(this.selectedSurvey)

    this.dialogRef.close(this.eventData);
  }

  getSurvey(id: number): SurveyDTO {
    return this.surveys.find(sur => sur.id === id);
  }

  isLessonPage() {
    return this.page === "lesson";
  }

  private getStartDate() {
    if (this.showDateOrTime)
      return new Date(this.startDateForm.value);

    let startDate = new Date(this.eventData.startData);
    let startTime = Helper.convertTimeToHMS(this.startDateForm.value);

    startDate.setHours(startTime[0]);
    startDate.setMinutes(startTime[1]);
    startDate.setSeconds(startTime[2]);

    return startDate;
  }

  private getEndDate() {
    let endTime = Helper.convertTimeToHMS(this.endDateForm.value);
    let endDate = this.getStartDate();

    endDate.setHours(endTime[0]);
    endDate.setMinutes(endTime[1]);
    endDate.setSeconds(endTime[2]);

    return endDate;
  }

}

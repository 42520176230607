import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { CourseService } from './course.service';
import { CourseContentEdit } from 'src/app/models/courseContentEdit';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbortController } from "@azure/abort-controller";
import { MasterService } from './master.service';
import { CustomerStorage } from 'src/app/models/dto/customerDTO';
import { CalendarService } from './calendar.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { MasterContentEdit } from '../models/masterContentEdit';
import { Helper } from '../helpers/helper';
//import CryptoES from 'crypto-es';

@Injectable({
  providedIn: 'root'
})
export class AzureStorageService {

  private blobServiceClient: BlobServiceClient = null;

  private _uploads: CurrentUpload[] = [];
  public get uploads(): CurrentUpload[] { return this._uploads; }

  private _showCurrentUploads: boolean = false;
  public get showCurrentUploads(): boolean { return this._showCurrentUploads; }

  private _customerStorage: CustomerStorage = null;
  public get customerStorage(): CustomerStorage { return this._customerStorage; }

  private idCustomer: number = null;

  constructor(private courseService: CourseService,
              private masterService: MasterService,
              private calendar: CalendarService,
              private snackBar: MatSnackBar,
              private http: HttpClient) { }

  public setToken (azureToken: string) {
    this.blobServiceClient = new BlobServiceClient(azureToken);
  }

  public async updateCustomerStorage (customerId?: number): Promise<CustomerStorage> {
    if (customerId)
      this.idCustomer = customerId;

    return await firstValueFrom(this.calendar.getCurrentAzureState(this.idCustomer))
      .then(res => this._customerStorage = res)
      .catch(() => this._customerStorage = null)
      .finally(() => { return this.customerStorage });
  }

  public clearCurrentUploads (force: boolean = false): void {
    if (force) {

      this.uploads.forEach((u, index) => {
        if(u.percentage !== -1 && u.percentage !== 100)
          this.clearCurrentUpload(index);
      });

    } else {

      if (this.uploads.findIndex(u => u.percentage !== -1 && u.percentage !== 100) !== -1) {
        this.snackBar.open('Uploads running, cannot clear the queue', 'Dismiss', { duration: 3000 });
        return;
      }

    }

    this._uploads = [];
    this._showCurrentUploads = false;
  }

  public clearCurrentUpload (index: number): void {
    this.uploads[index].abort.abort();
    this.uploads[index].percentage = -1;
  }

  public async getFileFromUrl(url: string, contentType: string): Promise<Blob> {

    try {

      let res = await firstValueFrom(this.http.get(url, { responseType: "blob" }));

      return new Blob([res], { type: contentType });

    } catch (err) {
      console.error(err);
    }

    return null;

  }

  // Entity specific methods

  public async postContentFolder (courseContent: CourseContentEdit, thumbnailFile: File = null): Promise<number> {

    let thumbnail = await Helper.getThumbnailBlob(null, thumbnailFile, { type: courseContent.Type, entity: 'coursecontent' });
    courseContent.HeaderImageUrl = await this.uploadFile(thumbnail);

    if (courseContent.HeaderImageUrl == null) {
      this.snackBar.open('Error adding content, no thumbnail provided', 'Dismiss', { duration: 3000 });
      return null;
    }

    try {

      let res =  await firstValueFrom(this.courseService.postFolder(courseContent));

      this.snackBar.open('Folder added successfully, please reload the page', 'Dismiss', { duration: 3000 });

      return Number((res as any).Message);

    } catch (e) {

      console.log(e);

      let msg = e.error.Message ? e.error.Message : e;
      this.snackBar.open(msg, 'Dismiss', { duration: 3000 });

    }

    return null;
  }

  public async postContent (courseContent: CourseContentEdit, file: File, thumbnailFile: File = null): Promise<number> {
    //let hash = await this.getFileHash(file);

    //Se l'hash e presente invio l'hash al backend
    //if (await this.hashIsPresent(hash)) {

      //courseContent.MediaUrl = null;
      //courseContent.MediaHash = hash;

    //} else { //Se l'hash non e presente nel DB allora eseguo l'upload

      courseContent.MediaUrl = await this.uploadFile(file);
      
    //}

    if (courseContent.MediaUrl == null) { // && courseContent.MediaHash == null) {
      this.snackBar.open('Error adding content, no media provided', 'Dismiss', { duration: 3000 });
      return null;
    }

    let thumbnail = await Helper.getThumbnailBlob(file, thumbnailFile, { type: courseContent.Type, entity: 'coursecontent' });
    courseContent.HeaderImageUrl = await this.uploadFile(thumbnail);

    if (courseContent.HeaderImageUrl == null) {
      this.snackBar.open('Error adding content, no thumbnail provided', 'Dismiss', { duration: 3000 });
      return null;
    }

    try {

      let res = await firstValueFrom(this.courseService.postContent(courseContent));

      this.snackBar.open('Content added successfully, please reload the page', 'Dismiss', { duration: 3000 });
      
      return Number((res as any).Message);

    } catch (e) {

      console.log(e);

      let msg = e.error.Message ? e.error.Message : e;
      this.snackBar.open(msg, 'Dismiss', { duration: 3000 });

    }

    return null;
  }

  public async putContent (id: number, courseContent: CourseContentEdit, file: File = null, thumbnailFile: File = null): Promise<void> {
    if (file != null) {

      /*
      let hash = await this.getFileHash(file);

      //Se l'hash e presente invio l'hash al backend
      if (await this.hashIsPresent(hash)) {

        courseContent.MediaUrl = null;
        courseContent.MediaHash = hash;

      } else { //Se l'hash non e presente nel DB allora eseguo l'upload

        courseContent.MediaUrl = await this.uploadFile(file);

      }
      */

      courseContent.MediaUrl = await this.uploadFile(file);

      if (courseContent.MediaUrl == null) { //&& courseContent.MediaHash == null) {
        this.snackBar.open('Error uploading media', 'Dismiss', { duration: 3000 });
        return;
      }

    }
    
    if (thumbnailFile != null || file != null) {

      let thumbnail = await Helper.getThumbnailBlob(file, thumbnailFile, { type: courseContent.Type, entity: 'coursecontent' });
      courseContent.HeaderImageUrl = await this.uploadFile(thumbnail);

    }

    try {

      await firstValueFrom(this.courseService.putContent(id, courseContent));

      let msg = 'Content edited successfully';

      if (file != null)
        msg += ', please reload the page';

      this.snackBar.open(msg, 'Dismiss', { duration: 3000 });

    } catch (e) {

      console.log(e);

      let msg = e.error.Message ? e.error.Message : e;
      this.snackBar.open(msg, 'Dismiss', { duration: 3000 });

    }
  }

  public async postMasterContent(
    idMaster: number,
    masterContent: MasterContentEdit,
    file: File,
    thumbnailFile: File = null): Promise<number> {

    masterContent.mediaUrl = await this.uploadFile(file);

    if (masterContent.mediaUrl == null) {
      this.snackBar.open('Error adding content, no media provided', 'Dismiss', { duration: 3000 });
      return null;
    }

    let thumbnail = await Helper.getThumbnailBlob(file, thumbnailFile);
    masterContent.headerImageUrl = await this.uploadFile(thumbnail);

    if (masterContent.headerImageUrl == null) {
      this.snackBar.open('Error adding content, no thumbnail provided', 'Dismiss', { duration: 3000 });
      return null;
    }

    try {

      let res = await firstValueFrom(this.masterService.postMasterContent(idMaster, masterContent));

      this.snackBar.open('Content added successfully, please reload the page', 'Dismiss', { duration: 3000 });
      
      return Number((res as any).Message);

    } catch (e) {

      console.log(e);

      let msg = e.error.Message ? e.error.Message : e;
      this.snackBar.open(msg, 'Dismiss', { duration: 3000 });

    }

    return null;

  }

  public async putMasterContent(
    idMaster: number,
    idMasterContent: number,
    masterContent: MasterContentEdit,
    file: File = null,
    thumbnailFile: File = null): Promise<void> {

    if (file != null) {

      masterContent.mediaUrl = await this.uploadFile(file);

      if (masterContent.mediaUrl == null) {
        this.snackBar.open('Error uploading media', 'Dismiss', { duration: 3000 });
        return;
      }

    }

    if (thumbnailFile != null || file != null) {

      let thumbnail = await Helper.getThumbnailBlob(file, thumbnailFile);
      masterContent.headerImageUrl = await this.uploadFile(thumbnail);

    }

    try {

      await firstValueFrom(this.masterService.putMasterContent(idMaster, idMasterContent, masterContent));

      let msg = 'Content edited successfully';

      if (file != null)
        msg += ', please reload the page';

      this.snackBar.open(msg, 'Dismiss', { duration: 3000 });

    } catch (e) {

      console.log(e);

      let msg = e.error.Message ? e.error.Message : e;
      this.snackBar.open(msg, 'Dismiss', { duration: 3000 });

    }
  }

  public async uploadFile (file: File): Promise<string> {
    if (file && file.size > 0) {
      let url = this.getFileTempName(file);
      
      if (await this.uploadBlob(url, file))
        return url;
    }

    return null;
  }

  // Private methods

  //True -> Caricato con successo, False -> Errore nel caricamento
  private async uploadBlob (name: string, file: File): Promise<boolean> {
    let index = null;

    try {
      const containerClient = this.blobServiceClient.getContainerClient('');
      const blockBlobClient = containerClient.getBlockBlobClient(name);

      //Evita di aggiunge elementi agli upload attivi se si sta caricando un immagine (Immagine del corso oppure la thumbnail del video)
      if (!file.type.split('/')[0].includes('image')) { 
        this.uploads.push({ name: file.name, percentage: 0, abort: new AbortController() });
        index = this.uploads.length - 1;
        this._showCurrentUploads = true;
      }

      await blockBlobClient.uploadData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
        abortSignal: index != null ? this.uploads[index].abort.signal : null,
        onProgress: e => (index != null ? this.getProgress(e.loadedBytes, file.size, index) : null)
      });

      console.log(`Upload block blob ${file.name} successfully`);
    } catch (err) {
      if (index != null) {
        this.clearCurrentUpload(index);
      }

      this.snackBar.open('Error uploading file', 'Dismiss', { duration: 3000 });
      console.log(`uploadStream failed, statusCode - ${err.statusCode}`);

      return false;
    }
    
    this.updateCustomerStorage();

    return true;
  }

  private getProgress (bytesUploaded: number, fileSize: any, index: number): void {
    let percentage = ((bytesUploaded / fileSize) * 100).toFixed(0);
    this.uploads[index].percentage = Number(percentage);
  }

  private getFileTempName (file: File): string {
    let filename = file.name ? file.name.replace(/[/\\?%*:|"<>\s]/g, '_') : `${Math.floor(Math.random() * 100000000).toString()}.jpeg`;

    let index = filename.lastIndexOf('.');
    let name = filename.substring(0, index);
    let extension = filename.substring(index, filename.length);

    return `${name.substring(0, 200)}${extension}`;
  }

  /*
  private async getFileHash(file: File): Promise<string> {
    let buffer = await file.arrayBuffer();
    let words = CryptoES.lib.WordArray.create(buffer);
    
    return CryptoES.MD5(words).toString(CryptoES.enc.Hex);
  }

  private async hashIsPresent(hash: string): Promise<boolean> {
    return await firstValueFrom(this.courseService.checkFileIsPresent(hash))
      .then(() => { return true; })
      .catch(() => { return false; });
  }
  */
}

export class CurrentUpload {
  name: string;
  percentage: number;
  abort: AbortController;
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CalendarService } from 'src/app/services/calendar.service';
import { ChartDataset, ChartOptions } from 'chart.js';
import * as bcrypt from 'bcryptjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorProfileService } from 'src/app/services/author.service';
import { AuthorProfileEdit } from 'src/app/models/authorProfileEdit';
import { AzureStorageService } from 'src/app/services/storage.service';
import { AuthorProfileDTO } from 'src/app/models/dto/authorProfileDTO';
import { Router } from '@angular/router';
import { TeacherLesson } from 'src/app/models/TeacherData';
import { firstBy } from 'thenby';
import { GoogleService } from 'src/app/services/google.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { Helper } from 'src/app/helpers/helper';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { UserRole } from 'src/app/models/userRole';

/*
export const passwordMismatch: ValidatorFn = (control: FormGroup):
ValidationErrors | null => {
  const oldPasswordServer = control.get('oldPasswordServer');
  const oldPassword = control.get('oldPassword');
  const newPassword = control.get('newPassword');
  const newPasswordRepeat = control.get('newPasswordRepeat');

  return oldPasswordServer && oldPassword && newPassword && newPasswordRepeat && 
    newPassword.value === newPasswordRepeat.value && 
    oldPasswordServer.value === oldPassword.value && 
    oldPassword.value === newPassword.value ? { 'passwordMismatch': true} : null;
};

const passwordForm = new FormGroup({
  'oldPasswordServer': new FormControl("", [Validators.required]),
  'oldPassword': new FormControl("", [Validators.required]),
  'newPassword': new FormControl("", [Validators.required, Validators.minLength(8)]),
  'newPasswordRepeat': new FormControl("", [Validators.required, Validators.minLength(8)])
}, { validators: passwordMismatch });
*/

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss']
})

export class ProfileUserComponent implements OnInit {
  currentUser: User;
  lessonAvgDuration: Date = new Date(new Date().setHours(0, 0, 0, 0));
  lessonAvgPlannedDuration: Date = new Date(new Date().setHours(0, 0, 0, 0));
  teacherAvgAttendance: number = 0.00;
  storageStatus: number = 0.00;
  availableStorage: number = 0.00;
  durationSpinnerValue;
  authorProfile: AuthorProfileDTO = null;
  enableAuthorProfile: boolean = false;
  enableChangePassword: boolean = false;
  enableUserProfile: boolean = false;
  enableGoogleIntegration: boolean = false;
  sendingAuthorProfile: boolean = false;
  sendingChangePassword: boolean = false;
  sendingUserProfile: boolean = false;
  sendingGoogleIntegration: boolean = false;
  profilePicture: string = null;

  nameUser: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  surnameUser: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  emailUser: UntypedFormControl = new UntypedFormControl("", [Validators.required, Validators.email]);
  summaryUser: UntypedFormControl = new UntypedFormControl(null);
  timezone: UntypedFormControl = new UntypedFormControl(2, [Validators.required]);
  userProfilePicture: UntypedFormControl = new UntypedFormControl(undefined);

  oldPasswordServer: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  oldPassword: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  newPassword: UntypedFormControl = new UntypedFormControl("", [Validators.required, Validators.pattern(Helper.getValidPasswordRegex())]);
  newPasswordRepeat: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

  profilePictureSrc: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  profileBackgroundSrc: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  profileName: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  profileSummary: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  profileCV: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

  googleEmail = new UntypedFormControl('', [Validators.required, Validators.email]);

  lineChartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        fill: 'origin',
        tension: 0.4
      },
      point:{
        radius: 0,
        hitRadius: 0
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        display: true,
        ticks: {
          stepSize: 50
        },
        min: 0,
        max: 100
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };
  public lineChartType = 'line';
  public lineChartData: ChartDataset[] = [{data: [0], label: '%'}];
  public lineChartLabels: string[] = [];

  timeZone = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  constructor(private auth: AuthService,
              private userService: UserService,
              private calendar: CalendarService,
              private profileService: AuthorProfileService,
              public snackBar: MatSnackBar,
              public azureService: AzureStorageService,
              private authSocialService: SocialAuthService,
              private router: Router,
              private googleService: GoogleService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.getUser();

    this.toggleUserProfileEdits();
    this.toggleUserGoogleEdits();

    this.getPassword();
    this.toggleChangePasswordEdits();

    this.getAuthorProfile();
    this.toggleAuthorProfileEdits();

    if (this.currentUser.isAuthor || this.currentUser.isCustomerAdmin)
      this.getCustomerStorageData();

    if(this.currentUser.isTeacher)
      this.getTeacherData();
  }

  async getUser() {
    await firstValueFrom(this.userService.get(this.currentUser.id))
      .then((res: User) => {
        this.auth.updateCurrentUser(res);
        this.currentUser = res;
  
        //Necessario per evitare che sia inviato l'url della foto (es. https://qualcosa.com) al backend
        this.profilePicture = this.currentUser.profilePictureUrl;
        this.currentUser.profilePictureUrl = null;
  
        this.fillUserData();
      });
  }

  fillUserData() {
    this.nameUser.setValue(this.currentUser.name);
    this.surnameUser.setValue(this.currentUser.surname);
    this.emailUser.setValue(this.currentUser.email);
    this.summaryUser.setValue(this.currentUser.userSummary);
    this.timezone.setValue(this.currentUser.timezone);
    this.googleEmail.setValue(this.currentUser.googleEmail);

    if (this.googleEmail.value != null) this.googleEmail.disable();
  }

  getTeacherData() {
    this.teacherAvgAttendance = null;

    this.calendar.getTeacherData(this.currentUser.id)
      .subscribe(output => {

        if(output != null) {
          if(output.lessonAvgDuration != null && output.lessonAvgDuration != "NaN" && output.lessonAvgPlannedDuration != null && output.lessonAvgPlannedDuration != "NaN" && output.lessons != null) {
            this.lessonAvgDuration.setSeconds(Number(output.lessonAvgDuration));
            this.lessonAvgPlannedDuration.setSeconds(Number(output.lessonAvgPlannedDuration));
            this.teacherAvgAttendance = output.teacherAvgAttendance;
            this.durationSpinnerValue = Math.round((Number(output.lessonAvgDuration) / Number(output.lessonAvgPlannedDuration)) * 10000) / 100;

            var percentages = [];

            output.lessons.sort(
              firstBy((l: TeacherLesson) => {
                var t = new Date(l.created);
                t.getFullYear();
              }, 1)
              .thenBy((l: TeacherLesson) => {
                var t = new Date(l.created);
                t.getMonth();
              }, 1)
            );

            output.lessons.forEach(l => {
              percentages.push(l.teacherAttendance);

              var date = new Date(l.created);
              this.lineChartLabels.push((date.getMonth() + 1) + "/" + date.getFullYear())
            });

            this.lineChartData = [{data: percentages, label: "%", borderColor: 'rgba(103, 58, 183, 1)', backgroundColor: 'rgba(103, 58, 183, 0.5)'}];
          } else {
            this.teacherAvgAttendance = 0.00;
          }
        }
      });
  }

  spinnerValue(value: number) {
    if(value.toFixed(2).split('.')[1] == "00")
        return value + "%";
    else
        return value.toFixed(2) + "%";
  }

  async update() {
    this.lockUserProfile(true);

    this.currentUser.name = this.nameUser.value;
    this.currentUser.surname = this.surnameUser.value;
    this.currentUser.email = this.emailUser.value;
    this.currentUser.userSummary = this.summaryUser.value;
    this.currentUser.timezone = this.timezone.value;
    this.currentUser.profilePictureUrl = await this.azureService.uploadFile(this.userProfilePicture.value);

    firstValueFrom(this.userService.updateUser(this.currentUser, this.currentUser.id))
      .then(res => {
        this.snackBar.open('User edited successfully', 'Dismiss', {
          duration: 4000,
          verticalPosition: 'bottom'
        });

        this.getUser();
      }).catch(err => {
        console.error(err);

        if(err.status == 406) {
          this.snackBar.open('Wrong email', 'Dismiss', {
            duration: 4000,
            verticalPosition: 'bottom'
          });
        } else {
          this.snackBar.open('Error editing user', 'Dismiss', {
            duration: 4000,
            verticalPosition: 'bottom'
          });
        }
      }).finally(() => {
        this.userProfilePicture.reset();
        this.lockUserProfile(false);
      });
  }

  async getPassword() {
    firstValueFrom(this.userService.getPassword(this.currentUser.id)).then(res => {
      if(res != null)
        this.oldPasswordServer.setValue(atob(res));
      else {
        this.snackBar.open('Cannot connect to server, change password disabled', 'Dismiss', { duration: 4000, verticalPosition: 'bottom' });
        this.oldPasswordServer.setValue(null);
      }
    }).catch(err => {
      this.snackBar.open('Cannot connect to server, change password disabled', 'Dismiss', { duration: 4000, verticalPosition: 'bottom' });
      this.oldPasswordServer.setValue(null);
    });
  }

  async updatePassword() {
    this.lockPasswordChange(true);

    if(bcrypt.compareSync(this.oldPassword.value, this.oldPasswordServer.value)) {
      firstValueFrom(this.userService.updatePassword(btoa(this.newPassword.value), this.currentUser.id)).then(async res => {
         this.snackBar.open('Password changed successfully, now you can log in again', 'Dismiss', {
            duration: 4000,
            verticalPosition: 'bottom'
          });

          this.auth.logout();
        }).catch(err => {
          this.snackBar.open('Error changing password', 'Dismiss', {
            duration: 3000,
            verticalPosition: 'bottom'
          });

          this.lockPasswordChange(false);
        });
    } else {
      this.snackBar.open('The current password is incorrect', 'Dismiss', {
        duration: 3000,
        verticalPosition: 'bottom'
      });
      
      this.lockPasswordChange(false);
    }

    this.oldPassword.reset();
    this.newPassword.reset();
    this.newPasswordRepeat.reset();

    this.oldPassword.setErrors(null);
    this.newPassword.setErrors(null);
    this.newPasswordRepeat.setErrors(null);
  }

  checkNewPassword() {
    return this.newPassword.valid
        && this.newPasswordRepeat.valid
        && this.newPassword.value === this.newPasswordRepeat.value
        && this.oldPassword.value !== this.newPassword.value;
  }

  getAuthorProfile() {
    this.profileService.getAuthorProfileOfUser(this.currentUser.id)
      .subscribe(output => {
        if(output != null) {
          this.authorProfile = output;
          this.profileName.setValue(this.authorProfile.nameExtendend);
          this.profileSummary.setValue(this.authorProfile.authorResume);
          this.profileCV.setValue(this.authorProfile.authorCV);
        }
      });
  }

  goToAuthorProfile() {
    this.router.navigate(['/authorprofile', this.authorProfile.idUser]);
  }

  async saveAuthorProfile() {
    this.lockAuthorProfile(true);

    var profile = new AuthorProfileEdit();

    profile.NameExtendend = this.profileName.value;
    profile.AuthorResume = this.profileSummary.value;
    profile.AuthorCV = this.profileCV.value;
    profile.IdUser = this.currentUser.id;
    profile.AuthorPhotoUrl = await this.azureService.uploadFile(this.profilePictureSrc.value);
    profile.BackgroundImageUrl = await this.azureService.uploadFile(this.profileBackgroundSrc.value);

    if(this.authorProfile == null) {
      firstValueFrom(this.profileService.postAuthorProfile(profile))
        .then(() => {
          this.snackBar.open('Profile added successfully', 'Dismiss', { duration: 3000 });
        }).catch(err => {
          console.error(err);
          this.snackBar.open('Error adding profile', 'Dismiss', { duration: 3000 });
        }).finally(() => {
          this.cleanProfile();
          this.lockAuthorProfile(false);
          this.getAuthorProfile();
        });
    } else {
      firstValueFrom(this.profileService.putAuthorProfile(this.authorProfile.id, profile))
        .then(() => {
          this.snackBar.open('Profile edited successfully', 'Dismiss', { duration: 3000 });
        }).catch(err => {
          console.error(err);
          this.snackBar.open('Error editing profile', 'Dismiss', { duration: 3000 });
        }).finally(() => {
          this.cleanProfile();
          this.lockAuthorProfile(false);
          this.getAuthorProfile();
        });
    }
  }

  grantGoogleAccess() {
    this.lockGoogleIntegration(true);

    //Il login Google con offline_access = true restituisce solo l'AuthorizationToken e non un oggetto SocialUser
    this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID, { scope: 'https://www.googleapis.com/auth/calendar', offline_access: true })
      .then(res => {

        this.googleService.grantAccess(res.authorizationCode, res.email)
          .subscribe({
            next: async () => {
              this.auth.toggleGoogleAccess(true);
              await this.getUser();
              
              this.snackBar.open('Google calendar access granted!', 'Dismiss', { duration: 3000 });
            },
            error: err => {
              console.error(err);
              this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
            }
          })
          .add(() => this.lockGoogleIntegration(false));
      })
      .catch(err => {
        console.error(err);
        this.lockGoogleIntegration(false);
      });
  }

  revokeGoogleAccess() {

    const dialog = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: 'Warning',
          body: 'Are you sure to revoke access to your Google account? EasyTeach won\'t be able to add events on your behalf to the classrooms Google calendars you belong to'
        }
      });

    dialog.afterClosed().subscribe(result => {
      if (result == true) {
        this.lockGoogleIntegration(true);

        this.googleService.revokeAccess()
          .subscribe({
            next: async () => {
              this.auth.toggleGoogleAccess(false);
              await this.getUser();
      
              this.snackBar.open('Google calendar access revoked!', 'Dismiss', { duration: 3000 });
            },
            error: err => {
              console.error(err);
              this.snackBar.open('Error revoking Google access', 'Dismiss', { duration: 3000 });
            }
          })
          .add(() => this.lockGoogleIntegration(false));
      }
    });
  }

  addGoogleEmail() {
    this.lockGoogleIntegration(true);

    this.googleService.addEmail(this.googleEmail.value)
      .subscribe({
        next: async () => {
          await this.getUser();

          this.snackBar.open('Google email added successfully', 'Dismiss', { duration: 3000 });
          this.lockGoogleIntegration(false);

          this.dialog.open(GenericPopupComponent,
            {
              width: '400px',
              data: <GenericPopupData>{
                title: 'Warning',
                body: 'If any of the classes you belong to have a Google calendar then you will receive in the email you just provided the invite to view the classrooms lessons'
              }
            });
        },
        error: err => {
          console.error(err);
          this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
          this.lockGoogleIntegration(false);
        }
      });
  }

  removeGoogleEmail() {

    const dialog = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: 'Warning',
          body: 'Are you sure to remove your Google account email? You will lose access to all classrooms Google calendars'
        }
      });

    dialog.afterClosed().subscribe(result => {
      if (result == true) {
        this.lockGoogleIntegration(true);

        this.googleService.removeEmail()
          .subscribe({
            next: async () => {
              await this.getUser();
      
              this.snackBar.open('Google email removed successfully', 'Dismiss', { duration: 3000 });
              this.lockGoogleIntegration(false);
            },
            error: err => {
              console.error(err);
              this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
              this.lockGoogleIntegration(false);
            }
          });
      }
    });
  }

  cleanProfile() {
    this.profileName.reset();
    this.profileSummary.reset();
    this.profileCV.reset();
    this.profilePictureSrc.reset();
    this.profileBackgroundSrc.reset();
  }

  lockAuthorProfile(lock: boolean) {
    if(lock) {
      this.profileName.disable();
      this.profileSummary.disable();
      this.profileCV.disable();
      this.profilePictureSrc.disable();
      this.profileBackgroundSrc.disable();
    } else {
      this.profileName.enable();
      this.profileSummary.enable();
      this.profileCV.enable();
      this.profilePictureSrc.enable();
      this.profileBackgroundSrc.enable();
    }

    this.sendingAuthorProfile = lock;
  }

  lockPasswordChange(lock: boolean) {
    if(lock) {
      this.oldPassword.disable();
      this.newPassword.disable();
      this.newPasswordRepeat.disable();
    } else {
      this.oldPassword.enable();
      this.newPassword.enable();
      this.newPasswordRepeat.enable();
    }

    this.sendingChangePassword = lock;
  }

  lockUserProfile(lock: boolean) {
    if(lock) {
      this.nameUser.disable();
      this.surnameUser.disable();
      this.emailUser.disable();
      this.userProfilePicture.disable();
      this.summaryUser.disable();
      this.timezone.disable();
    } else {
      this.nameUser.enable();
      this.surnameUser.enable();
      this.emailUser.enable();
      this.userProfilePicture.enable();
      this.summaryUser.enable();
      this.timezone.enable();
    }

    this.sendingUserProfile = lock;
  }

  lockGoogleIntegration(lock: boolean) {
    if (lock) {
      this.googleEmail.disable();
    } else if (
                (this.googleEmail.value == null || this.googleEmail.value == '') &&
                !this.currentUser.isTeacher &&
                !this.currentUser.isTutor &&
                this.currentUser.provider !== 'GOOGLE'
              ) {
      this.googleEmail.enable();
    }

    this.sendingGoogleIntegration = lock;
  }

  toggleAuthorProfileEdits() {
    this.enableAuthorProfile = !this.enableAuthorProfile;
    this.lockAuthorProfile(this.enableAuthorProfile);
  }

  toggleChangePasswordEdits() {
    this.enableChangePassword = !this.enableChangePassword;
    this.lockPasswordChange(this.enableChangePassword);
  }

  toggleUserProfileEdits() {
    this.enableUserProfile = !this.enableUserProfile;
    this.lockUserProfile(this.enableUserProfile);
  }

  toggleUserGoogleEdits() {
    this.enableGoogleIntegration = !this.enableGoogleIntegration;
    this.lockGoogleIntegration(this.enableGoogleIntegration);
  }

  getCustomerStorageData() {
    this.azureService.updateCustomerStorage();
    this.storageStatus = (this.azureService.customerStorage.currentSize / this.azureService.customerStorage.maxSize) * 100;
    this.availableStorage = Math.round((this.azureService.customerStorage.maxSize - this.azureService.customerStorage.currentSize) * 100) / 100;
  }
  
  googleAccessDisabled() {
    return this.enableGoogleIntegration ||
           this.sendingGoogleIntegration ||
           Helper.isNullOrEmpty(environment.googleClientId);
  }

  googleEmailDisabled() {
    return (this.googleEmail.enabled ? !this.googleEmail.valid : this.enableGoogleIntegration) ||
           this.sendingGoogleIntegration ||
           Helper.isNullOrEmpty(environment.googleClientId);
  }

  getRole() {
    return UserRole.role(this.currentUser);
  }
}

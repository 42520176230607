<h1 mat-dialog-title>{{ 'Change master status' | translate }}</h1>
<mat-dialog-content fxLayout="column" fxLayoutGap="10px">
    <div>
        {{ 'Are you sure to change the status of master to status?' | translate:{ name: masterName, value: (getMasterState() | translate) } }}
    </div>
    <div *ngIf="isOnline()">
        {{ 'Master will be visible to all users and available for booking. Some of the properties can not be changed anymore.' | translate }}
    </div>
    <div *ngIf="isPlanned()">
        {{ 'Master will be purchasable. Some of the properties can not be changed anymore.' | translate }}
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" (click)="closePopUp()" cdkFocusInitial>Ok</button>
</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { SelectionCancel, SelectionService } from 'src/app/services/selection.service';

@Component({
  selector: 'app-selection-viewer',
  templateUrl: './selection-viewer.component.html',
  styleUrls: ['./selection-viewer.component.scss']
})
export class SelectionViewerComponent implements OnInit {
  open: boolean = true;
  sending: boolean = false;

  constructor(public selectionService: SelectionService) { }

  ngOnInit () { }

  openList () {
    this.open = true;
  }

  closeList () {
    this.open = false;
  }

  cancelList () {
    this.selectionService.cancelSelection(SelectionCancel.userCanceled);
  }

  async saveElements () {
    this.sending = true;
    await this.selectionService.saveElements();
    this.sending = false;
  }

  removeElement (index: number) {
    this.selectionService.removeElementByIndex(index);
  }
}

import { TranslationEdit } from "./dto/translationDTO";

export class CourseContentEdit {
    public IdCourse: number;
    public IdAuthor: number;
    public Type: number;
    public Name: string;
    public Description: string;
    public MediaUrl: string;
    public MediaLanguage: string;
    public HeaderImageUrl: string;
    public MobileHeaderImageUrl: string;
    //public MediaHash: string;
    public Downloadable: number;
    public OrderIndex: number;
    public Duration: number;
    public Language: string;
    public SubtitlesLanguages: string[];
    public State: number;
    public NameTranslation: TranslationEdit;
    public DescriptionTranslation: TranslationEdit;
}

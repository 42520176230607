import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { CourseBlogDTO } from 'src/app/models/dto/courseBlogDTO';
import { CourseLikesDTO } from 'src/app/models/dto/courseLikesDTO';
import { FormControl, Validators } from '@angular/forms';
import { CourseService } from 'src/app/services/course.service';
import { CourseBlogEdit } from 'src/app/models/courseBlogEdit';
import { PlayerEventsComponent } from 'src/app/components/player-events/player-events.component';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { AuthService } from 'src/app/services/auth.service';
import { CourseFavouriteDTO } from 'src/app/models/dto/courseFavouriteDTO';
import { SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { Helper } from 'src/app/helpers/helper';
import { ContentAddComponent } from '../content-add/content-add.component';
import { CoursePopupComponent } from '../course-popup/course-popup.component';
import { User } from 'src/app/models/user';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { EditSubtitlesComponent } from 'src/app/popup/edit-subtitles/edit-subtitles.component';
import { ContentHelper } from 'src/app/helpers/contentHelper';
import { CARD_WIDTH } from '../course.component';
import linkifyHtml from 'linkify-html';
import { CourseContentType } from 'src/app/models/courseContentType';
import { AzureStorageService } from 'src/app/services/storage.service';

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 40; //px

const GRID_CONTAINER_SIZE: number = 60; //%

@Component({
  selector: 'app-course-video',
  templateUrl: './course-video.component.html',
  styleUrls: ['./course-video.component.scss']
})

export class CourseVideoComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.rowSize = Math.floor(((window.innerWidth - (PAGE_PADDING * 2)) * (GRID_CONTAINER_SIZE / 100)) / this.cardSize);
  }

  id: number;
  currentUser: User = null;
  content: CourseContentDTO = undefined;
  blogs: CourseBlogDTO[] = [];
  likes: CourseLikesDTO[] = [];
  contents: CourseContentDTO[] = [];
  download: string = "";
  commentsMargin: string = "10px";
  postContent: FormControl<string> = new FormControl('', [Validators.required]);
  showBlogForm: number = 0;
  selectedBlog: CourseBlogDTO = null;
  userLike: boolean = false;
  userFav: boolean = false;
  href: string = "";
  scormUrl: string = null;
  sending: boolean = false;
  editing: boolean = false;
  subtitleStatus: undefined | 'generating' | 'available' = undefined;
  //customOrder: boolean = false;
  contentsMode: 'cards' | 'table' = 'cards';

  //Taglio video
  isVideoCut: boolean = false;
  startTime: number = 0;
  endTime: number = 0;

  private _videoDuration: number = 0;

  get videoDuration(): number { return this._videoDuration; }
  set videoDuration(value: number) {
    this._videoDuration = Math.floor(value);

    this.endTime = this._videoDuration;
  }

  cardSize: number = 0;
  rowSize: number = 0;

  //Info per il tracker
  trackerStartDate: Date = null;
  trackerStartPosition: number = 0;
  trackerStopPosition: number = 0;

  constructor(
    private auth: AuthService,
    private courseService: CourseService,
    private location: Location,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private selectionService: SelectionService,
    private playerComponent: PlayerEventsComponent,
    private darkService: DarkThemeService,
    private translate: TranslateService,
    private storage: AzureStorageService
  ) { }

  ngOnInit() {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();
    this.href = Helper.getUrl();
    this.trackerStartDate = null;

    this.cardSize = CARDS_GAP + CARD_WIDTH;
    this.onResize(undefined);

    if (this.route.snapshot.queryParamMap.has('start'))
      this.trackerStartPosition = Number(this.route.snapshot.queryParamMap.get('start'));

    //if (this.route.snapshot.queryParamMap.has('customOrder'))
    //  this.customOrder = this.route.snapshot.queryParamMap.get('customOrder') === 'true';

    this.trackerStopPosition = this.trackerStartPosition;

    this.getContentById();
    this.getContentBlogs();
    this.getLikes();
  }

  ngOnDestroy() {
    if (this.content?.type === CourseContentType.Pdf)
      this.addTracker();
  }

  goBack() {
    this.location.back();
  }

  getContentById() {
    this.courseService.getContentById(this.id)
      .subscribe(output => {
        this.content = output;

        if (output.type === CourseContentType.Folder)
          this.getContentsOfFolder();

        if (output.mediaUrl != null && (output.type == CourseContentType.Video || output.type == CourseContentType.Audio)) {

          if (!output.downloadable)
            this.download = "nodownload";
            
        }

        if (output.hashTag && output.hashTag.length > 0)
          this.commentsMargin = "26px";

        if (output.courseFavourite && output.courseFavourite.length > 0)
          this.userFav = true;

        if (!Helper.isNullOrEmpty(output.mediaLanguage))
          this.subtitleStatus = output.mediaSubtitlesAvailable === 1
                              ? 'available'
                              : 'generating';
        else
          this.subtitleStatus = undefined;

      });
  }

  getContentBlogs() {
    this.blogs = [];

    this.courseService.getContentBlogs(this.id)
      .subscribe(output => {

        output = output
          .map(b => {
            b.created = new Date(b.created);
            b.postContent = linkifyHtml(b.postContent,  {
              defaultProtocol: 'https',
              target: "_blank"
            });

            return b;
          });

        output.forEach(blog => blog.blogs = output.filter(b => b.idParent === blog.id));

        this.blogs = output
          .filter(b => b.idParent === 0)
          .sort((a, b) => a.created.getTime() - b.created.getTime());
      });
  }

  updateContent() {
    this.editing = true;

    const dialogRef = this.dialog.open(ContentAddComponent, {
      data: { idCourse: this.content.idCourse, content: this.content, mode: this.content.course.mode }, //, customOrder: this.customOrder },
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.editing = false;

      if (result) {
        this.getContentById();
        this.getContentBlogs();
      }
    });
  }

  deleteContent() {
    const dialogRef = this.dialog.open(CoursePopupComponent, {
      data: {name: this.content.name, type: 'deleteContent'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.courseService.deleteContent(this.id)
          .subscribe({
            next: () => {
              this.openSnackbar('Content deleted');
              this.location.back();
            },
            error: err => {
              this.openSnackbar('Error deleting content');
            }
          });
      }
    });
  }

  editBlog(action: boolean) {
    let blog = new CourseBlogEdit();
    
    blog.IdAuthor = this.currentUser.id;
    blog.IdContent = this.content.id;
    blog.PostContent = this.postContent.value;

    if (this.selectedBlog != null)
      blog.IdParent = this.selectedBlog.id;

    if (action) {
      this.courseService.postBlog(blog)
        .subscribe({
          next: () => {
            this.openSnackbar('Blog added successfully');
            this.getContentBlogs();
          },
          error: err => {
            this.openSnackbar('Error adding blog');
          }
        });
    } else {
      this.courseService.putBlog(this.selectedBlog.id, blog)
        .subscribe({
          next: () => {
            this.openSnackbar('Blog edited successfully');
            this.getContentBlogs();
          },
          error: err => {
            this.openSnackbar('Error editing blog');
          }
        });
    }

    this.showBlog(0);
  }

  showBlog(show: number, blog: CourseBlogDTO = null) {
    this.selectedBlog = blog;
    this.showBlogForm = show;

    if (show == 2)
      this.postContent.setValue(this.selectedBlog.postContent);
    else
      this.postContent.reset();
  }

  getLikes() {
    this.likes = [];

    this.courseService.getContentLikes(this.id)
      .subscribe(output => {
        this.likes = output;
        this.userLike = output.findIndex(l => l.idUser == this.currentUser.id) !== -1;
      });
  }

  deleteLike() {
    this.courseService.deleteLike(this.content.id)
      .subscribe({
        next: () => {
          this.openSnackbar('Like removed');
          this.getLikes();
        },
        error: err => {
          this.openSnackbar('Error removing like');
        }
      });
  }

  postLike() {
    this.courseService.postLike(this.content.id)
      .subscribe({
        next: output => {
          this.openSnackbar('Like added');
          this.getLikes();
        },
        error: err => {
          this.openSnackbar('Error adding like');
        }
      });
  }

  getLastLikes() {
    if (!this.likes || this.likes.length === 0)
      return '';

    let arr = this.likes.length > 10
            ? this.likes.slice(0, 10)
            : this.likes.slice();

    let likes = arr
      .map(l => `${l.user.name} ${l.user.surname}`)
      .join('\n');

    return this.likes.length > 10
         ? `${likes}...`
         : likes;
  }

  openSnackbar(msg: string) {
    this.snackBar.open(msg, 'Dismiss', { duration: 3000 });
  }

  startTracking() {
    //Non eseguo il tracking se si è in selezione contenuti
    if (!this.selectionService.showSelection) {
      console.log('tracking set');

      this.trackerStartDate = new Date();
    }
  }

  pageChanged($event: number) {
    this.trackerStopPosition = $event;
  }

  addTracker(seeked?: number[]) {
    if (this.trackerStartDate != null) {

      if (this.content.type === CourseContentType.Video || this.content.type === CourseContentType.Audio)
        this.trackerStopPosition = Math.round(seeked ? seeked[0] : this.playerComponent.currentTime);

      if (this.trackerStartPosition != this.trackerStopPosition) {
        this.courseService.postTracker(this.id, this.trackerStartDate, this.trackerStartPosition, this.trackerStopPosition)
          .subscribe({
            next: () => {
              console.log('tracked');

              this.startTracking();
            },
            error: err => console.log(err.error.Message)
          });
      }
    }
  }
  
  goToTracker() {
    if (this.content.type === CourseContentType.Scorm)
      this.router.navigate(['/content-scorm-view', this.id], {
        queryParams: {
          title: this.content.name
        }
      });
    else
      this.router.navigate(['/coursetracker', this.id], {
        queryParams: {
          duration: this.content.duration,
          title: this.content.name,
          type: this.content.type === CourseContentType.Video
              ? 'video'
              : this.content.type === CourseContentType.Pdf
              ? 'pdf'
              : this.content.type === CourseContentType.Audio
              ? 'audio'
              : 'unknown'
        }
      });
  }

  addFavourite() {
    this.courseService.addFavourite(this.content.id)
      .subscribe({
        next: (fav: any) => {
          this.content.courseFavourite.push(new CourseFavouriteDTO().id = fav.Message);
          this.userFav = true;

          this.openSnackbar('Added as favourite');
        },
        error: err => this.openSnackbar(err.Message)
      });
  }

  removeFavourite() {
    this.courseService.removeFavourite(this.content.id)
      .subscribe({
        next: () => {
          this.content.courseFavourite = [];
          this.userFav = false;

          this.openSnackbar('Removed from favourites');
        },
        error: err => this.openSnackbar(err.Message)
      });
  }

  playScorm() {
    if (this.isPreviewUser())
        this.courseService.getScormPreviewUrl(this.content.id)
          .subscribe(res => {
            this.scormUrl = res.launchLink;
          });
    else
      this.courseService.getScormUrl(this.content.id)
        .subscribe(res => {
          this.scormUrl = res.launchLink;
        });
  }

  isPreviewUser() {
    return this.currentUser.id === this.content?.idAuthor
        || this.currentUser.isCustomerAdmin
        || this.currentUser.isAdmin;
  }
  
  async deleteBlog(idBlog: number) {
    const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Delete comment')),
          body: await firstValueFrom(this.translate.get('Are you sure you want to delete this comment?'))
        }
      });
  
      dialogRef.afterClosed().subscribe(async res => {
        if (!res)
          return;
  
        this.courseService.deleteBlog(idBlog)
          .subscribe({
            next: () => {
              this.openSnackbar('Comment deleted');
              this.getContentBlogs();
            },
            error: err => {
              this.openSnackbar(err.Message);
            }
          });
      });
  }

  getHashTags() {
    return this.content?.hashTag?.join(' ');
  }

  isDT() {
    return this.auth.isDT();
  }

  cutVideo() {
    this.sending = true;

    this.courseService.createContentFromVideoCut(this.id, this.startTime, this.endTime)
      .subscribe({
        next: res => {
          this.router.navigate(['/course-content', (res as any).Message]);

          this.sending = false;
        },
        error: err => {
          console.error(err);

          this.snackBar.open(err.error.Message, 'Dismiss', { duration: 5000 });

          this.sending = false;
        }
      });
  }

  editSubtitles() {
    if (this.subtitleStatus !== 'available')
      return;

    this.sending = true;

    const dialogRef = this.dialog.open(EditSubtitlesComponent,
    {
      width: '900px',
      disableClose: true,
      data: {
        id: this.content.id,
        language: this.content.mediaLanguage
      }
    });
  
    dialogRef.afterClosed().subscribe(async res => {
      this.sending = false;

      if (!res)
        return;

      this.getContentById();
      this.getContentBlogs();
    });
  }

  sliderLabel(seconds: number) {
    let hours = seconds / 3600;
    seconds = seconds % 3600;

    let minutes = seconds / 60;
    seconds = seconds % 60;

    hours = isNaN(hours) ? 0 : Math.floor(hours);
    minutes = isNaN(minutes) ? 0 : Math.floor(minutes);
    seconds = isNaN(seconds) ? 0 : Math.floor(seconds);

    let res = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    if (hours > 0)
      res = `${String(hours).padStart(2, '0')}:${res}`;  

    return res;
  }

  startSelection() {
    this.selectionService.startSelection(this.content.id,
                                         SelectionMode.folder,
                                         this.content.idCourse,
                                         this.contents.map(c => {

                                          c.headerImageUrl = c.contentHelper.headerImg();
                                          return c;
                                          
                                         }));
  }

  selectionShowing() {
    return this.selectionService.showSelection;
  }

  getContentsOfFolder() {
    this.courseService.getContentsOfFolder(this.id)
      .subscribe(res => this.contents = res.map((content, i, contents) => {
        content.contentHelper = new ContentHelper(this.router,
                                                  this.dialog,
                                                  this.courseService,
                                                  this.auth.getCurrentUser(),
                                                  content,
                                                  contents,
                                                  this.content.course.idAuthor,
                                                  this.content.course.mode);
        return content;
      }));
  }

  async downloadFile() {
    let blob: Blob = await this.storage.getFileFromUrl(this.content.mediaUrl, '');

    if (!blob)
      return;

    let downloadLink = document.createElement('a');

    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = Helper.getFileNameFromURI(this.content.mediaUrl);

    downloadLink.click();
    downloadLink.remove();
  }

  gridTemplateColumns() {
    let total = this.rowSize;

    if (this.contents.length < total)
      total = this.contents.length;

    let columns = '';

    for (let i=0; i<total; i++)
      columns += 'auto ';

    return columns;
  }

  isDark() {
    return this.darkService.isSetDark;
  }

  isVideo() {
    return this.content?.type === CourseContentType.Video;
  }

  isPdf() {
    return this.content?.type === CourseContentType.Pdf;
  }

  isScorm() {
    return this.content?.type === CourseContentType.Scorm;
  }

  isAudio() {
    return this.content?.type === CourseContentType.Audio;
  }

  isFolder() {
    return this.content?.type === CourseContentType.Folder;
  }

  isGeneric() {
    return this.content?.type === CourseContentType.Generic;
  }

}

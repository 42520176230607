import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChartDataset, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-view-graph',
  templateUrl: './view-graph.component.html',
  styleUrl: './view-graph.component.scss'
})
export class ViewGraphComponent {

  title: string = undefined;
  width: string = '400px'; // Aspect ratio di 16:8
  height: string = '200px';

  dataset: ChartDataset[] = [];
  labels: string[] = [];
  plugins: any[] = [];
  options: ChartOptions = undefined;
  type: string = undefined;

  constructor(
    private dialogRef: MatDialogRef<ViewGraphComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewGraphData
  ) {

    if (data.title)
      this.title = data.title;

    if (data.width)
      this.width = data.width;

    if (data.height)
      this.height = data.height;

    if (data.dataset)
      this.dataset = data.dataset;

    if (data.labels)
      this.labels = data.labels;

    if (data.plugins)
      this.plugins = data.plugins;

    if (data.options)
      this.options = data.options;

    if (data.type)
      this.type = data.type;

  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

export class ViewGraphData {
  title: string = undefined;
  width?: string = undefined;
  height?: string = undefined;

  dataset: ChartDataset[] = [];
  labels: string[] = [];
  plugins: any[] = [];
  options: ChartOptions = undefined;
  type: string = undefined;
}

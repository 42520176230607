<div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px;" fxFlex>
    <mat-card appearance="outlined" style="width: 30%;" class="mat-elevation-z8">
        <mat-card-content appearance="legacy" style="width: 100%;">
            <h2>{{ 'Status' | translate }}</h2>
            <h4>{{ (!userDeletion ? 'No request found' :
                    userDeletion.state === 0 ? 'Deletion process completed' : userDeletion.state === 1 ? 'Request received' : 'Request taken over') | translate }}
                <mat-icon style="vertical-align: middle; margin-left: 5px;">
                    {{ !userDeletion ? 'clear' : (userDeletion.state === 0 ? 'done' : userDeletion.state === 1 ? 'mail' : 'autorenew') }}
                </mat-icon>
            </h4>
            <h4 *ngIf="userDeletion">{{ 'Updated on' | translate }} {{ userDeletion.lastUpdate | date: "HH:mm - dd/MM/YY"}}</h4>
            <h4 *ngIf="userDeletion && userDeletion.message">{{ 'Message' | translate }}: {{ userDeletion.message }}</h4>
        </mat-card-content>
    </mat-card>
</div>

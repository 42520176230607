
<div id="header-div"  fxLayout="row" fxLayoutAlign="center center" class="mat-elevation-z6">
    <div fxFlex="40" fxLayoutAlign="start center" style="margin-left: 5px; color: white">
        {{ title }}
    </div>
    <div fxFlex="20" fxLayoutAlign="center center">
        <button *ngIf="selectedVideo > 0" mat-icon-button (click)="changeVideo(false)" [matTooltip]="'Previous video' | translate">
            <mat-icon style="color: white;">
                skip_previous
            </mat-icon>
        </button>
        <button *ngIf="selectedVideo < videos.length-1" mat-icon-button (click)="changeVideo(true)" [matTooltip]="'Next video' | translate">
            <mat-icon style="color: white;">
                skip_next
            </mat-icon>
        </button>
        <button mat-icon-button
            [matMenuTriggerFor]="visualTypePlayer" [matTooltip]="'Change video appearance' | translate">
            <mat-icon style="color: white;">
                settings_overscan
            </mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="goBack()" [matTooltip]="'Close' | translate">
            <mat-icon>
                power_settings_new
            </mat-icon>
        </button>
    </div>
    <div fxFlex="40" fxLayoutAlign="end center" style="margin-right: 5px; color: white">
        {{ videos.length > 1 ? ('Video ' + (selectedVideo + 1) + ' ' + ('Oof' | translate) + ' ' + videos.length + '') : '' }}
    </div>
</div>
<div fxLayout="row" fxFlex="100" style="margin-top: 50px; height: calc(100vh - 50px); overflow: hidden;">
    <app-player-events *ngFor="let recordingUrl of videos[selectedVideo]?.orderedRecordings"
        [shadow]="false"
        [media]="recordingUrl"
        [enableControls]="true"
        [disableSeekCheck]="true"
        [objectFit]="visualType"
        [startTime]="videoTime"
        [pause]="videoPause"
        (paused)="videoPause = true; setCurrentTime($event)"
        (played)="videoPause = false; setCurrentTime($event)"
        fxFlex>
    </app-player-events>
</div>
<mat-menu #visualTypePlayer="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="setVisualType('fill')" [class.mat-menu-highlight]="visualType === 'fill'">{{ 'Fill' | translate }}</button>
        <button mat-menu-item (click)="setVisualType('cover')" [class.mat-menu-highlight]="visualType === 'cover'">{{ 'Cover' | translate }}</button>
        <button mat-menu-item (click)="setVisualType('contain')" [class.mat-menu-highlight]="visualType === 'contain'">{{ 'Contain' | translate }}</button>
    </ng-template>
</mat-menu>

import { UserDTO } from "./userDTO";

export class VirtualRoomDTO {
    id: number;
    idUser: number;
    name: string;
    description: string;
    permanentRoom: number;
    state: number;
    created: string;

    // Per test rooms
    idLesson?: number;
    users: UserDTO[];
}

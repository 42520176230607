import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentCustomerType, EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-community-login',
  templateUrl: './community-login.component.html',
  styleUrl: './community-login.component.scss'
})
export class CommunityLoginComponent {

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  backgroundImageUrl: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.AixpCommunity, EnvironmentParameterType.BackgroundImage)})`;
  backgroundImageMobile: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.AixpCommunity, EnvironmentParameterType.BackgroundImageMobile)})`;

  idCustomer: number = 187;
  customerCode: string = 'AIXPCOMMUNITY2024';

  constructor(
    private themeService: DarkThemeService,
    private navBar: NavBarService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.themeService.onLogOut();
    this.navBar.hide('topbar');
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerDTO, CustomerStorage } from 'src/app/models/dto/customerDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { LessonSessionDTO, LessonContentEdit } from 'src/app/models/dto/lessonSessionDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { AirTimeDTO } from 'src/app/models/dto/airTimeDTO';
import { tap } from 'rxjs/operators';
import { StudentsStatus } from 'src/app/models/studentsStatus';
import { UserOnlineTime } from 'src/app/models/userOnlineTime';
import { StudentsVideoStatus } from 'src/app/models/studentsVideoStatus';
import { AttendanceDTO } from 'src/app/models/dto/attendanceDTO';
import { CheckListDTO, CheckListQuestionDTO, CheckListAnswerDTO } from 'src/app/models/dto/CheckListDTO';
import { StationDTO } from 'src/app/models/dto/stationDTO';
import { TeacherData } from 'src/app/models/TeacherData';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { CustomerUpdateEntity } from 'src/app/models/dto/customerUpdateEntity';
import { StationStatus } from 'src/app/models/stationStatus';
import { Helper } from 'src/app/helpers/helper';
import { BannerDTO } from 'src/app/models/dto/bannerDTO';
import { TranslationService } from './translation.service';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { SmilyRecordedBlobDTO } from 'src/app/models/dto/smilyRecordedBlobDTO';
import { CustomerNewEntity } from '../models/dto/customerNewEntity';
import { CustomerResultDTO } from '../models/dto/customerResultDTO';
import { CustomerStorageSizeTrackerDTO } from '../models/dto/customerStorageSizeTrackerDTO';
import { LessonResultsTotalDTO } from '../models/dto/lessonResultsTotalDTO';
import { CourseDTO } from '../models/dto/courseDTO';
import { reportLink } from '../models/reportLink';
import { StreamingQualityEdit } from '../models/streamingQualityEdit';
import { NetworkInformation } from '../helpers/networkInformation';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LessonAggregateData } from '../models/lessonAggregateData';
import { JoinedLessonDTO } from '../models/dto/joinedLessonDTO';
import { ImportExternalData } from '../models/importExternalData';
import { CustomerUpdateStripe } from '../models/customerUpdateStripe';
import { UserResultsTotalDTO } from '../models/dto/userResultsTotalDTO';
import { PublishedStreamingTime } from '../models/publishedStreamingTime';
import { StreamingTime } from '../models/streamingTime';
import { CustomerOnlineTime } from '../models/customerOnlineTime';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlCustumerAdmin = `${this.bckEndUrlBaseUrl}/customers`;
    private bckEndUrlTeacherAdmin = `${this.bckEndUrlBaseUrl}/teachers/forAdmin`;
    private bckEndUrlTeacherStudent = `${this.bckEndUrlBaseUrl}/teachers/forStudent`;
    private bckEndUrlStudent = `${this.bckEndUrlBaseUrl}/students/byLesson`;
    private bckEndUrlLessonSessions = `${this.bckEndUrlBaseUrl}/lessons`;
    private bckEndUrlLessonSessionsTeacher = `${this.bckEndUrlBaseUrl}/lessons/teacher`;
    private bckEndUrlLessonSessionsAirTime = `${this.bckEndUrlBaseUrl}/lessons/airTime`;
    private bckEndUrlAirTime = `${this.bckEndUrlBaseUrl}/airtime`;
    private bckEndUrlOnlineStudents = `${this.bckEndUrlAirTime}/onlinestudents`;
    private bckEndUrlStudentsStatus = `${this.bckEndUrlAirTime}/statusstudents`;
    private bckEndUrlStudentData = `${this.bckEndUrlAirTime}/studentdata`;
    private bckEndUrlStudentsVideoStatus = `${this.bckEndUrlAirTime}/studentsvideostatus`;
    private bckEndUrlAttendance = `${this.bckEndUrlBaseUrl}/attendance`;
    private bckEndUrlAttendanceClassroom = `${this.bckEndUrlAttendance}/class`;
    private bckEndUrlLessonStorage = `${this.bckEndUrlLessonSessions}/storage`;
    private bckEndUrlCheckList = `${this.bckEndUrlBaseUrl}/checklist`;
    private bckEndUrlTeacherData = `${this.bckEndUrlBaseUrl}/teachers/teacher`;
    private bckEndUrlClassroom = `${this.bckEndUrlBaseUrl}/classrooms`;
    private bckEndUrlCustomerStreaming = `${this.bckEndUrlCustumerAdmin}/streaming`;
    private bckEndUrlStation = `${this.bckEndUrlBaseUrl}/station`;

    //private bckEndUrlClassroomDuration = `${this.bckEndUrlBaseUrl}/classrooms/classroomduration`;
    //private bckEndUrlClassroomAttendance = `${this.bckEndUrlBaseUrl}/classrooms/classroomattendance`;
    
    constructor(private http: HttpClient,
                private translationService: TranslationService,
                private deviceService: DeviceDetectorService) { }

    /**
     * Get the list of customers. 
     * This method returns a value only if it is an Admin / CustumAdmin
     * */
    public getCustomerForAdmin(): Observable<CustomerDTO[]> {
        return this.http.get<CustomerDTO[]>(`${this.bckEndUrlCustumerAdmin}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    /**
     * Get the a customers.
     * This method returns a value only if it is an Admin / CustumAdmin
     * @param custumerId
     */
    public getCustumerForAdmin(custumerId: number): Observable<CustomerDTO[]> {
        return this.http.get<CustomerDTO[]>(`${this.bckEndUrlCustumerAdmin}/${custumerId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    /**
     * Get classroom by id. 
     * */
    public getClassroom(idClassroom : number)  {
        return this.http.get<ClassroomDTO>(`${this.bckEndUrlClassroom}/${idClassroom}`, {headers: Helper.getAuthHeader()});
    }

    /**
     * Get the list of all the teachers in a class, seen by an administrator.
     * Only a global administrator can read classes and teachers from all the customers.
     * If you are not admin 404 error returns
     * @param roomId id classroom
     * @param custumerId id customer
     */
    public getTeachersForAdmin(roomId: number, custumerId: number): Observable<UserDTO[]> {
        var param: HttpParams = new HttpParams()
            .set("roomId", `${roomId}`)
            .set("custumerId", `${custumerId}`);

        return this.http.get<UserDTO[]>(`${this.bckEndUrlTeacherAdmin}`, { headers: Helper.getAuthHeader(), params: param }).pipe(tap(console.log));
    }

    public getTeachersForAll(custumerId: number): Observable<UserDTO[]> {
        var param: HttpParams = new HttpParams()
            .set("custumerId", `${custumerId}`);

        return this.http.get<UserDTO[]>(`${this.bckEndUrlBaseUrl}/teachers`, { headers: Helper.getAuthHeader(), params: param }).pipe(tap(console.log));
    }

    public getRoomsForAll(customerId?: number): Observable<RoomDTO[]> {
        var options: any = { headers: Helper.getAuthHeader() };

        if (customerId)
            options.params = new HttpParams().set("customerId", `${customerId}`);

        return this.http.get<RoomDTO[]>(`${this.bckEndUrlCustumerAdmin}/rooms`, options).pipe(tap(console.log));
    }

    /**
     * Get the list of all the teachers in a class, as seen by a student.
     * @param roomId id classroom
     * @param studentId id student
     */

    //Cancellare
    public getTeachersForStudent(roomId: number, studentId: number): Observable<UserDTO[]> {
        var param: HttpParams = new HttpParams()
            .set("roomId", `${roomId}`)
            .set("studentId", `${studentId}`);

        return this.http.get<UserDTO[]>(`${this.bckEndUrlTeacherStudent}`, { headers: Helper.getAuthHeader(), params: param }).pipe(tap(console.log));
    }

    /**
     * Retry the list of students of a lession.
     * @param lessonId
     */
    public getStudentOfLesson(lessonId: number): Observable<UserDTO[]> {
        return this.http.get<UserDTO[]>(`${this.bckEndUrlStudent}/${lessonId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    /**
     * Recupera una singola lezione
     * @param lessonId
     */
    public getLesson(lessonId: number): Observable<LessonSessionDTO> {
        return this.http.get<LessonSessionDTO>(`${this.bckEndUrlLessonSessions}/${lessonId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));

    }

    public getLessons(
        startDate?: Date,
        endDate?: Date,
        classroomId?: number,
        teacherId?: number,
        roomId?: number,
        state?: number,
        customerId?: number,
        types?: number[],
        conferenceData?: boolean,
        surveyData?: boolean,
        classroomData?: boolean,
        userId?: number
    ): Observable<LessonSessionDTO[]> {
        let params = new HttpParams();

        if (startDate != undefined)
            params = params.append('startDate', startDate.toISOString());

        if (endDate != undefined)
            params = params.append('endDate', endDate.toISOString());

        if (classroomId != undefined)
            params = params.append('classroomId', classroomId);

        if (teacherId != undefined)
            params = params.append('teacherId', teacherId);

        if (roomId != undefined)
            params = params.append('roomId', roomId);

        if (state != undefined)
            params = params.append('state', state);

        if (customerId != undefined)
            params = params.append('customerId', customerId);

        if (types && types.length > 0)
            params = params.append('types', types.join(','));

        if (conferenceData != undefined)
            params = params.append('conferenceData', conferenceData);

        if (surveyData != undefined)
            params = params.append('surveyData', surveyData);

        if (classroomData != undefined)
            params = params.append('classroomData', classroomData);

        if (userId != undefined)
            params = params.append('userId', userId);

        return this.http.get<LessonSessionDTO[]>(`${this.bckEndUrlLessonSessions}`, { headers: Helper.getAuthHeader(), params: params });
    }

    public getAirTimeOfLesson(lessonId: number): Observable<AirTimeDTO[]> {
        return this.http.get<AirTimeDTO[]>(`${this.bckEndUrlLessonSessionsAirTime}/${lessonId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getJoinedLessons(lessonId: number, aggregate?: boolean): Observable<JoinedLessonDTO[]> {
        let param = new HttpParams();

        if (aggregate)
            param = param.append('aggregate', aggregate);

        return this.http.get<JoinedLessonDTO[]>(`${this.bckEndUrlLessonSessions}/joinedLessons/${lessonId}`, { headers: Helper.getAuthHeader(), params: param }).pipe(tap(console.log));
    }

    public getAggregateDataOfLesson(lessonId: number, userType?: 0 | 1 | 2): Observable<LessonAggregateData[]> {
        let param = new HttpParams();

        if (userType)
            param = param.append('userType', userType);

        return this.http.get<LessonAggregateData[]>(`${this.bckEndUrlLessonSessions}/data/${lessonId}/aggregate`, { headers: Helper.getAuthHeader(), params: param }).pipe(tap(console.log));
    }

    /**
     * Active a lessons
     * @param lesson lesson to activate from state 2 to 1
     */
    public activateLesson(lessonId: number): Observable<any> {
        return this.http.get(`${this.bckEndUrlLessonSessionsTeacher}/${lessonId}/activate`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }
    /**
     * Add a new lesson on the Calendar.
     * @param lesson lessons to add
     */
    public postLesson(lesson: LessonSessionDTO): Observable<any> {
        return this.http.post(`${this.bckEndUrlLessonSessionsTeacher}`, lesson, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public editLesson(lesson: LessonSessionDTO): Observable<any> {
        return this.http.put(`${this.bckEndUrlLessonSessionsTeacher}/${lesson.id}`, lesson, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }
    /**
     * Remove a lesson from the Calendar.
     * @param lessonId
     */
    public deleteLesson(lessonId: number): Observable<any> {
        return this.http.delete(`${this.bckEndUrlLessonSessionsTeacher}/${lessonId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getOnlineStudents(classId: number): Observable<UserDTO[]> {
        return this.http.get<UserDTO[]>(`${this.bckEndUrlOnlineStudents}/${classId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getStatusStudents(classId: number): Observable<StudentsStatus[]> {
        return this.http.get<StudentsStatus[]>(`${this.bckEndUrlStudentsStatus}/${classId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getUserOnlineTime(userId: number): Observable<UserOnlineTime[]> {
        return this.http.get<UserOnlineTime[]>(`${this.bckEndUrlStudentData}/${userId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getStudentsVideoStatus(classId: number, lessonId: number): Observable<StudentsVideoStatus[]> {
        return this.http.get<StudentsVideoStatus[]>(`${this.bckEndUrlStudentsVideoStatus}/${classId}/${lessonId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    /*
    public getClassroomDuration(classId: number): Observable<ClassroomDuration> {
        this.setHeader();
        return this.http.get<ClassroomDuration>(`${this.bckEndUrlClassroomDuration}/${classId}`, { headers: this.customHeaders }).pipe(tap(console.log));
    }

    public getClassroomAttendance(classId: number): Observable<ClassroomAttendance[]> {
        this.setHeader();
        return this.http.get<ClassroomAttendance[]>(`${this.bckEndUrlClassroomAttendance}/${classId}`, { headers: this.customHeaders }).pipe(tap(console.log));
    }
    */

    public getClassroomAttendance(classId: number): Observable<AttendanceDTO[]> {
        return this.http.get<AttendanceDTO[]>(`${this.bckEndUrlAttendanceClassroom}/${classId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getLessonAttendance(lessonId: number): Observable<AttendanceDTO> {
        return this.http.get<AttendanceDTO>(`${this.bckEndUrlAttendanceClassroom}/${lessonId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getLessonVideos(lessonId: number): Observable<{ index: number, orderedRecordings: string[] }[]> {
        return this.http.get<{ index: number, orderedRecordings: string[] }[]>(`${this.bckEndUrlLessonStorage}/${lessonId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getCheckListOfCustomer(customerId: number): Observable<CheckListDTO[]> {
        return this.http.get<CheckListDTO[]>(`${this.bckEndUrlCheckList}/customer/${customerId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getQuestionsOfList(checkListId: number): Observable<CheckListQuestionDTO[]> {
        return this.http.get<CheckListQuestionDTO[]>(`${this.bckEndUrlCheckList}/checklist/${checkListId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getAnswersOfQuestion(questionId: number): Observable<CheckListAnswerDTO[]> {
        return this.http.get<CheckListAnswerDTO[]>(`${this.bckEndUrlCheckList}/answers/${questionId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getAnswersOfList(checkListId: number): Observable<CheckListQuestionDTO[]> {
        return this.http.get<CheckListQuestionDTO[]>(`${this.bckEndUrlCheckList}/allanswers/${checkListId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public createAnswers(answers: CheckListAnswerDTO[]): Observable<CheckListAnswerDTO[]> {
        return this.http.post<CheckListAnswerDTO[]>(`${this.bckEndUrlCheckList}/answers`, answers, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getStationsOfCustomer(customerId: number): Observable<StationDTO[]> {
        return this.http.get<StationDTO[]>(`${this.bckEndUrlCustumerAdmin}/stations/${customerId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getStationAnswers(stationId: number): Observable<CheckListAnswerDTO[]> {
        return this.http.get<CheckListAnswerDTO[]>(`${this.bckEndUrlCustumerAdmin}/stationanswers/${stationId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public setStationWorkingState(stationId: number) {
        return this.http.post<StationDTO>(`${this.bckEndUrlCustumerAdmin}/station/${stationId}`, null, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getStationGroups(): Observable<StationStatus[]> {
        return this.http.get<StationStatus[]>(`${this.bckEndUrlStation}/groups`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getTeacherData(teacherId: number): Observable<TeacherData> {
        return this.http.get<TeacherData>(`${this.bckEndUrlTeacherData}/${teacherId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public trasferClassroom(classId: number): Observable<any> {
        return this.http.put<any>(`${this.bckEndUrlClassroom}/transferclassroom/${classId}`, "", { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getOpenLessonInClass(classId: number): Observable<LessonSessionDTO> {
        return this.http.get<LessonSessionDTO>(`${this.bckEndUrlClassroom}/lesson/${classId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getCurrentAzureState(customerId: number): Observable<CustomerStorage> {
        return this.http.get<CustomerStorage>(`${this.bckEndUrlCustumerAdmin}/storage/${customerId}`, { headers: Helper.getAuthHeader() });
    }
    
    public getCurrentAzureStates(): Observable<CustomerStorage[]> {
        return this.http.get<CustomerStorage[]>(`${this.bckEndUrlCustumerAdmin}/storage`, { headers: Helper.getAuthHeader() });
    }

    public getCurrentLessonContent(lessonId: number, contentId: number): Observable<CourseContentDTO> {
        return this.http.get<CourseContentDTO>(`${this.bckEndUrlLessonSessions}/content/${lessonId}/${contentId}`, { headers: Helper.getAuthHeader() });
    }

    public setCurrentLessonContent(lessonId: number, lessonContent: LessonContentEdit): Observable<CourseContentDTO> {
        return this.http.post<CourseContentDTO>(`${this.bckEndUrlLessonSessions}/content/${lessonId}`, lessonContent, { headers: Helper.getAuthHeader() });
    }

    public addLessonContents(lessonId: number, contents: CourseContentDTO[]) {
        return this.http.post(`${this.bckEndUrlLessonSessions}/contents/${lessonId}`, contents, { headers: Helper.getAuthHeader() });
    }

    public getLessonContents(lessonId: number): Observable<CourseContentDTO[]> {
        return this.http.get<CourseContentDTO[]>(`${this.bckEndUrlLessonSessions}/contents/${lessonId}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
    }

    public putCustomer(data: CustomerUpdateEntity, id: number): Observable<any> {
        return this.http.put(`${this.bckEndUrlCustumerAdmin}/updatecustomer/${id}`, data, { headers: Helper.getAuthHeader() });
    }

    public putCustomerStripe(data: CustomerUpdateStripe, id: number) {
        return this.http.put(`${this.bckEndUrlCustumerAdmin}/updatecustomer/${id}/stripe`, data, { headers: Helper.getAuthHeader() });
    }

    public getUsersOnlineTime(userIds: number[]): Observable<UserOnlineTime[]> {
        let params: HttpParams = new HttpParams();

        userIds.map(id => params = params.append('userIds', id));

        return this.http.get<UserOnlineTime[]>(`${this.bckEndUrlAirTime}/userOnlineTime`, { params: params, headers: Helper.getAuthHeader() });
    }

    public getCustomerOnlineTime(customerId: number): Observable<CustomerOnlineTime[]> {
        return this.http.get<CustomerOnlineTime[]>(`${this.bckEndUrlAirTime}/customerOnlineTime/${customerId}`, { headers: Helper.getAuthHeader() });
    }

    public getCustomerStreaming(customerId: number): Observable<StreamingTime[]> {
        return this.http.get<StreamingTime[]>(`${this.bckEndUrlCustomerStreaming}/time/${customerId}`, { headers: Helper.getAuthHeader() });
    }

    public getCustomerPublishedStreaming(customerId: number): Observable<PublishedStreamingTime[]> {
        return this.http.get<PublishedStreamingTime[]>(`${this.bckEndUrlCustomerStreaming}/publishedtime/${customerId}`, { headers: Helper.getAuthHeader() });
    }

    public getOnlineUsersAirTime(userType: number = null): Observable<AirTimeDTO[]> {
        let param: HttpParams = null;

        if (userType != null)
            param = new HttpParams().set("userType", userType);

        return this.http.get<AirTimeDTO[]>(`${this.bckEndUrlAirTime}/onlineUsers`, { headers: Helper.getAuthHeader(), params: param });
    }

    public getAuthorsOfCustomer(customerId: number): Observable<UserDTO[]> {
        return this.http.get<UserDTO[]>(`${this.bckEndUrlCustumerAdmin}/authors/${customerId}`, { headers: Helper.getAuthHeader() });
    }

    public getBanner(): Observable<BannerDTO> {
        return this.http.get<BannerDTO>(`${this.bckEndUrlCustumerAdmin}/banner`, { headers: Helper.getAuthHeader() });
    }

    public createCustomer(customer: CustomerNewEntity) {
        return this.http.post(`${this.bckEndUrlCustumerAdmin}/customer`, customer, { headers: Helper.getAuthHeader() });
    }

    public getCustomerResults(customerId: number): Observable<CustomerResultDTO[]> {
        return this.http.get<CustomerResultDTO[]>(`${this.bckEndUrlCustumerAdmin}/results/${customerId}`, { headers: Helper.getAuthHeader() });
    }

    public getCustomersResults(): Observable<CustomerResultDTO[]> {
        return this.http.get<CustomerResultDTO[]>(`${this.bckEndUrlCustumerAdmin}/customersResults`, {headers: Helper.getAuthHeader() });
    }

    public getCustomerStorageSizeTracker(customerId: number): Observable<CustomerStorageSizeTrackerDTO[]>{
        return this.http.get<CustomerStorageSizeTrackerDTO[]>(`${this.bckEndUrlCustumerAdmin}/storageSizeTracker/${customerId}`,{ headers: Helper.getAuthHeader() });
    }

    public getCustomersStorageSizeTracker(): Observable<CustomerStorageSizeTrackerDTO[]>{
        return this.http.get<CustomerStorageSizeTrackerDTO[]>(`${this.bckEndUrlCustumerAdmin}/allStorageSizeTracker`,{ headers: Helper.getAuthHeader() });
    }

    public getDailyCustomersStorageSizeTracker(month: number, year: number): Observable<CustomerStorageSizeTrackerDTO[]>{
        return this.http.get<CustomerStorageSizeTrackerDTO[]>(`${this.bckEndUrlCustumerAdmin}/storageByDay/${month}/${year}`,{ headers: Helper.getAuthHeader() });
    }
    
    public getRegisteredUsers(customerId:number, month:number, year: number) {
        return this.http.get<number>(`${this.bckEndUrlCustumerAdmin}/registeredUsers/${customerId}/${month}/${year}`,{ headers: Helper.getAuthHeader() });
    }

    public getRegisteredUsersForYear(customerId: number, year: number) {
        return this.http.get<number[]>(`${this.bckEndUrlCustumerAdmin}/registeredUsersForYear/${customerId}/${year}`,{ headers: Helper.getAuthHeader() });
    }

    public getAllRegisteredUsersForMonth(month: number, year: number) {
        return this.http.get<number>(`${this.bckEndUrlCustumerAdmin}/allRegisteredUsers/${month}/${year}`,{ headers: Helper.getAuthHeader() });
    }

    public getAllRegisteredUsersForYear(year:number) {
        return this.http.get<number[]>(`${this.bckEndUrlCustumerAdmin}/allRegisteredUsersForYear/${year}`, {headers: Helper.getAuthHeader() });
    }

    public getPresentersOfPerformedWebinarForMonth(customerId:number, month:number, year: number) {
        return this.http.get<number>(`${this.bckEndUrlCustumerAdmin}/conferences/presenters/${customerId}/${month}/${year}`,{ headers: Helper.getAuthHeader() });
    }

    public getPresentersOfPerformedWebinarForAllCustomers(month:number, year:number) {
        return this.http.get<number>(`${this.bckEndUrlCustumerAdmin}/conferences/presenters/allCustomers/${month}/${year}`,{ headers: Helper.getAuthHeader() });
    }

    public getAllFeeObjects() {
        return this.http.get<CourseDTO[]>(`${this.bckEndUrlCustumerAdmin}/allFeeObjects`, {headers: Helper.getAuthHeader() });
    }

    public getReportLink(customerId:number, month:number, year:number) {
        return this.http.get<reportLink>(`${this.bckEndUrlCustumerAdmin}/report/${customerId}/${month}/${year}`, {headers: Helper.getAuthHeader() });
    }

    public addCalendarToClassroom(classId: number) {
        return this.http.post(`${this.bckEndUrlClassroom}/calendar/${classId}`, null, { headers: Helper.getAuthHeader() });
    }

    public removeCalendarFromClassroom(classId: number) {
        return this.http.delete(`${this.bckEndUrlClassroom}/calendar/${classId}`, { headers: Helper.getAuthHeader() });
    }

    public getLessonUsersSmilyRecording(lessonId: number): Observable<UserDTO[]> {
        return this.http.get<UserDTO[]>(`${this.bckEndUrlLessonSessions}/smilyRecordings/${lessonId}/users`, { headers: Helper.getAuthHeader() });
    }

    public getLessonSmilyRecording(lessonId: number, userId: number): Observable<SmilyRecordedBlobDTO[]> {
        return this.http.get<SmilyRecordedBlobDTO[]>(`${this.bckEndUrlLessonSessions}/smilyRecordings/${lessonId}/${userId}`, { headers: Helper.getAuthHeader() });
    }

    public addRoom(room: RoomDTO) {
        return this.http.post(`${this.bckEndUrlCustumerAdmin}/room`, room, { headers: Helper.getAuthHeader() });
    }

    public editRoom(roomId: number, room: RoomDTO) {
        return this.http.put(`${this.bckEndUrlCustumerAdmin}/room/${roomId}`, room, { headers: Helper.getAuthHeader() });
    }

    public addStation(station: StationDTO) {
        return this.http.post(this.bckEndUrlStation, station, { headers: Helper.getAuthHeader() });
    }

    public editStation(stationId: number, station: StationDTO) {
        return this.http.put(`${this.bckEndUrlStation}/${stationId}`, station, { headers: Helper.getAuthHeader() });
    }

    public deactivateStation(stationId: number, credentials?: { macAddress: string, isHmi: boolean, username: string, password: string }) {
        return this.http.post(`${this.bckEndUrlStation}/${stationId}/deactivate`, credentials == null ? {} : credentials, { headers: Helper.getAuthHeader() });
    }

    public createDemoCustomer(subscriptionLevelId: number) {
        return this.http.post(`${this.bckEndUrlCustumerAdmin}/demo/${subscriptionLevelId}`, null, { headers: Helper.getAuthHeader() });
    }

    public importExternalDataCheck(file: File, customerId?: number) {
        let formData = new FormData();
        formData.append('File', file);

        let params = new HttpParams();

        if (customerId != undefined)
            params = params.append('customerId', customerId);

        return this.http.post<ImportExternalData>(`${this.bckEndUrlCustumerAdmin}/import/check`, formData, { headers: Helper.getAuthHeader(), params: params });
    }

    public importExternalData(data: ImportExternalData) {
        return this.http.post(`${this.bckEndUrlCustumerAdmin}/import`, data, { headers: Helper.getAuthHeader() });
    }

    public addStreamingQuality(lessonId: number) {
        let data: StreamingQualityEdit = {
            downlink: NetworkInformation.bandwidth(),
            downlinkMax: NetworkInformation.maxBandwidth(),
            effectiveType: NetworkInformation.connectionType(),
            rtt: NetworkInformation.rtt(),
            communicationType: NetworkInformation.type(),
            browserType: this.deviceService.browser,
            browserVersion: this.deviceService.browser_version,
            os: this.deviceService.os,
            osVersion: this.deviceService.os_version,
            deviceType: this.deviceService.deviceType
        };

        return this.http.post(`${this.bckEndUrlLessonSessions}/quality/${lessonId}`, data, { headers: Helper.getAuthHeader() });
    }

    public getLessonResults(
        customerId?: number,
        lessonId?: number,
        month?: number,
        year?: number,
        lessonTypes?: number[],
        conferenceModes?: number[]
    ) {
        let params = new HttpParams();

        if (customerId != undefined)
            params = params.append('customerId', customerId);

        if (lessonId != undefined)
            params = params.append('lessonId', lessonId);

        if (month != undefined)
            params = params.append('month', month);

        if (year != undefined)
            params = params.append('year', year);

        if (lessonTypes != undefined)
            lessonTypes.map(lt => params = params.append('lessonTypes', lt));

        if (conferenceModes != undefined)
            conferenceModes.map(cm => params = params.append('conferenceModes', cm));

        return this.http.get<LessonResultsTotalDTO[]>(`${this.bckEndUrlLessonSessions}/results`, { params: params, headers: Helper.getAuthHeader() });
    }

    public getLessonUserResults(
        userId?: number,
        lessonId?: number,
        month?: number,
        year?: number,
        lessonTypes?: number[],
        conferenceModes?: number[]
    ) {
        let params = new HttpParams();

        if (userId != undefined)
            params = params.append('userId', userId);

        if (lessonId != undefined)
            params = params.append('lessonId', lessonId);

        if (month != undefined)
            params = params.append('month', month);

        if (year != undefined)
            params = params.append('year', year);

        if (lessonTypes != undefined)
            lessonTypes.map(lt => params = params.append('lessonTypes', lt));

        if (conferenceModes != undefined)
            conferenceModes.map(cm => params = params.append('conferenceModes', cm));

        return this.http.get<UserResultsTotalDTO[]>(`${this.bckEndUrlLessonSessions}/userResults`, { params: params, headers: Helper.getAuthHeader() });
    }
}

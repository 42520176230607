import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IsolaDTO } from 'src/app/models/dto/isolaDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { CheckListAnswerDTO } from 'src/app/models/dto/CheckListDTO';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { StationDTO } from 'src/app/models/dto/stationDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { Helper } from 'src/app/helpers/helper';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { SignalRService } from 'src/app/services/signalr.service';
import { User } from 'src/app/models/user';
import { AddStationComponent } from 'src/app/popup/add-station/add-station.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { StationDeactivatePopupComponent } from './station-deactivate-popup/station-deactivate-popup.component';

const STATION_CHECK_INTERVAL: number = 1000; //ms

@Component({
  selector: 'app-isola',
  templateUrl: './isola.component.html',
  styleUrls: ['./isola.component.scss']
})
export class IsolaComponent implements OnInit, OnDestroy {

  currentUser: User = null;

  @Input() isola: IsolaDTO;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();

  answers: CheckListAnswerDTO[] = [];

  stationCheckInterval: any = null;

  @ViewChild('openActionMenu', { static: true }) actionMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  constructor(private auth: AuthService,
              private calendar: CalendarService,
              private datePipe: DatePipe,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private router: Router,
              private signalR: SignalRService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.signalR.deviceConnStatus.subscribe(res => {

      if (this.isola.pos1.station != null && res.stationId === this.isola.pos1.station.id)
        this.isola.pos1.boardOnline = res.boardIsConnected;

      if (this.isola.pos2.station != null && res.stationId === this.isola.pos2.station.id)
        this.isola.pos2.boardOnline = res.boardIsConnected;

      if (this.isola.pos3.station != null && res.stationId === this.isola.pos3.station.id)
        this.isola.pos3.boardOnline = res.boardIsConnected;

      if (this.isola.pos4.station != null && res.stationId === this.isola.pos4.station.id)
        this.isola.pos4.boardOnline = res.boardIsConnected;

    });

    this.doStatusCheck();
    this.stationCheckInterval = setInterval(() => this.doStatusCheck(), STATION_CHECK_INTERVAL);
  }

  ngOnDestroy(): void {
    if (this.stationCheckInterval != null)
      clearInterval(this.stationCheckInterval);
  }

  private doStatusCheck() {
    if (this.isola.pos1.station != null)
      this.signalR.getStationStatus(this.isola.pos1.station.id);

    if (this.isola.pos2.station != null)
      this.signalR.getStationStatus(this.isola.pos2.station.id);

    if (this.isola.pos3.station != null)
      this.signalR.getStationStatus(this.isola.pos3.station.id);

    if (this.isola.pos4.station != null)
      this.signalR.getStationStatus(this.isola.pos4.station.id);
  }

  showErrorPostazione(station: StationDTO)
  {
    //if (station.workingState === 0){
    this.answers = null;

    this.calendar.getStationAnswers(station.id)
      .subscribe(output => {
        this.answers = [];
        if (output.length > 0){
          this.answers = output;
        }
        this.openDialog(station.id, station.name, station.description, station.workingState);
      });
    // }
  }


  openDialog(stationId, stName, stDescription, workingState): void {
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        width: '800px',
        data: {answers: this.answers, name: stName, description: stDescription, workingState},
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.calendar.setStationWorkingState(stationId)
            .subscribe(error => {
              if(error.Message == "") {
                this.refresh.emit(true);
                this.snackBar.open('Smily marked as fixed', 'Dismiss', {
                  duration: 3000,
                  verticalPosition: 'top'
                });
              } else {
                this.refresh.emit(false);
                this.snackBar.open('Error, Smily not marked as fixed', 'Dismiss', {
                  duration: 3000,
                  verticalPosition: 'top'
                });
              }
            }


          );
        }
      });
  }

  getImg(n){
    const postazione = this.isola['pos' + n].station;

    if (postazione) {

      if (postazione.onlineState === 1) {
        if (postazione.workingState === 0)
          return '../../assets/img/postazione_on_errore.png';
  
        return '../../assets/img/postazione_on.png';
      }
  
      if (postazione.onlineState === 0) {
        if (postazione.workingState === 0)
          return '../../assets/img/postazione_off_errore.png';
  
        return '../../assets/img/postazione_off.png';
      }

    }

    return '../../assets/img/postazione_off.png';
  }

  showTooltip(n){
    const postazione: StationDTO = this.isola['pos' + n].station;
    let msg = '';
    if (postazione) {
      let status = ' - ';

      if(postazione.onlineState === 0)
        status += ' Last ' + this.datePipe.transform(postazione.lastCheck, 'HH:mm - dd/MM/yyyy');
      else if(postazione.onlineState === 1)
        status += ' On';

      msg = postazione.description +
            status +
            (postazione.macAddress ? ' - MAC: ' + postazione.macAddress : '');
    }

    return msg;
  }

  getStationName(name: string): string {
    return Helper.getTruncateShowcase(name, 'No name', 20);
  }

  goToDashboard(stationId: number, stationName: string) {
    this.router.navigate(['smily-dashboard', stationId], { queryParams: { stationName: stationName } });
  }

  editStation(station: StationDTO) {
    const dialogRef = this.dialog.open(AddStationComponent, {
      width: '800px',
      data: { station: station }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this.refresh.emit(true);
    });
  }

  deactivateStation(station: StationDTO) {
    if (this.currentUser.isAdmin) {

      const dialogRef = this.dialog.open(GenericPopupComponent,
        {
          width: '400px',
          data: <GenericPopupData>{
            title: 'Smily deactivation',
            body: `Are you sure you want to deactivate ${station.name}?`
          }
        });
  
      dialogRef.afterClosed().subscribe(res => {
        if (!res)
          return;

        this.calendar.deactivateStation(station.id)
          .subscribe({
            next: () => {
              this.snackBar.open(`Smily ${station.name} deactivated successfully`, 'Dismiss', {
                duration: 3000,
                verticalPosition: 'bottom'
              });

              this.refresh.emit(true);
            },
            error: err => this.snackBar.open(err.error.Message, 'Dismiss', {
              duration: 3000,
              verticalPosition: 'bottom'
            })
          });
      });

    } else {
      const dialogRef = this.dialog.open(StationDeactivatePopupComponent,
        {
          width: '400px',
          data: station
        });

      dialogRef.afterClosed().subscribe(res => {
        if (!res)
          return;

        this.snackBar.open(`Smily ${station.name} deactivated successfully`, 'Dismiss', {
          duration: 3000,
          verticalPosition: 'bottom'
        });

        this.refresh.emit(true);
      });
    }
  }

  openMenu($event: MouseEvent, station: StationDTO) {
    this.contextMenuPosition.x = `${$event.clientX}px`;
    this.contextMenuPosition.y = `${$event.clientY}px`;
    this.actionMenu.menuData = { item: station };
    this.actionMenu.menu.focusFirstItem('mouse');
    this.actionMenu.openMenu();
  }

  isActive(station: StationDTO) {
    return !Helper.isNullOrEmpty(station.macAddress) || !Helper.isNullOrEmpty(station.macAddress2);
  }

}

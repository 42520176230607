<mat-card appearance="outlined" *ngIf="open" class="mat-elevation-z8" style="width: 400px;">
  <mat-card-header style="justify-content: space-between; align-items: center;">
    <mat-card-title>
      <mat-icon style="margin-right: 10px; vertical-align: middle;">list</mat-icon>
      {{ 'Selected elements' | translate }} ({{ selectionService.elements.length }})
    </mat-card-title>
    <div>
      <button mat-icon-button (click)="closeList()" [disabled]="sending" [matTooltip]="'Hide' | translate">
        <mat-icon>remove</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-list role="list" *ngIf="selectionService.elements.length > 0" style="max-height: 50vh; overflow: auto;">
      <mat-list-item *ngFor="let element of selectionService.elements; let i = index" style="margin-bottom: 10px">
        <img matListItemAvatar [src]="element.pictureUrl" loading="lazy" style="object-fit: cover">
        <span matListItemTitle>{{ element.name }}</span>
        <button matListItemMeta mat-icon-button
          (click)="removeElement(i)"
          [disabled]="sending"
          [matTooltip]="'Remove' | translate">
          <mat-icon>
            clear
          </mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
    <p *ngIf="selectionService.elements.length === 0">
      {{ 'Select some elements' | translate }}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="cancelList()" [disabled]="sending" style="margin-right: 10px">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="sending || selectionService.elements.length === 0" (click)="saveElements()">{{ 'Save' | translate }}</button>
  </mat-card-actions>
</mat-card>

<button mat-fab color="primary" (click)="openList()" *ngIf="!open" [matTooltip]="'Selection' | translate" matTooltipPosition="left">
  <mat-icon>list</mat-icon>
</button>

import { CartItemDTO } from 'src/app/ecommerce/models/cart-item-dto';
import { ClassroomDTO } from './classroomDTO';
import { CustomerDTO } from './customerDTO';
import { MasterContentDTO } from './masterContentDTO';
import { SubscriptionDTO } from './subscriptionDTO';
import { UserDTO } from './userDTO';
import { TranslationDTO } from './translationDTO';

export class MasterDTO {
    id: number;
    idOrigin?: number;
    idAuthor: number;
    type: number;
    idSubscription: number;
    idCustomer: number;
    idClassroom: number;
    name: string;
    idStringName: number;
    description: string;
    idStringDescription: number;
    header: string;
    idStringHeader: number;
    footer: string;
    idStringFooter: number;
    summary: string;
    idStringSummary: number;
    imageUrl: string;
    startDate?: Date;
    stopDate?: Date;
    durationInDays?: number;
    maxParticipants: number;
    hashTag: string[];
    visibility: number;
    state: number;
    lessonsNo?: number;
    totalLessonsTime?: number;
    lessonsTimes: string;
    created: Date;
    author: UserDTO;
    classroom: ClassroomDTO;
    masterContent: MasterContentDTO[];
    subscription: SubscriptionDTO;
    subscriptionFee: CartItemDTO;
    customer: CustomerDTO;
    derivedMaster: MasterDTO[];
    nameTranslation: TranslationDTO;
    descriptionTranslation: TranslationDTO;
    headerTranslation: TranslationDTO;
    footerTranslation: TranslationDTO;
    summaryTranslation: TranslationDTO;
}

export enum MasterType {
    standard = 0,
    oneToOne = 1,
    template = 2
}

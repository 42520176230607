import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Classroom } from 'src/app/models/classroom';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { Helper } from 'src/app/helpers/helper';
import { ClassroomDTO } from '../models/dto/classroomDTO';
import { Observable } from 'rxjs';
import { UserDTO } from '../models/dto/userDTO';

@Injectable({
  providedIn: 'root'
})
export class ClassroomService {

  bckEndUrlClassrooms: string = `${environment.apiUrl}/api/classrooms`;
  bckEndUrlClassroomsV2: string = `${environment.apiUrl}/api/v2/classrooms`;

  constructor(private http: HttpClient) { }

  getClassrooms(
    authorId?: number,
    type?: number,
    customerId?: number,
    oldObj?: boolean
    //userId?: number
  ): Observable<ClassroomDTO[] | Classroom[]> {
    let params = new HttpParams();

    if (authorId != undefined)
      params = params.append('authorId', authorId);

    if (type != undefined)
      params = params.append('type', type);

    if (customerId != undefined)
      params = params.append('customerId', customerId);

    if (oldObj != undefined)
      params = params.append('oldObj', oldObj);

    return this.http.get<ClassroomDTO[] | Classroom[]>(`${this.bckEndUrlClassrooms}`, { headers: Helper.getAuthHeader(), params: params });
  }

  deleteClassroom(id: number) {
    return this.http.delete(`${this.bckEndUrlClassrooms}/${id}`, { headers: Helper.getAuthHeader() })
      .pipe(map(() => {
        console.log('deleted class');
      }))
  }

  updateClassroom(classroom: Classroom, id: number): Observable<ClassroomDTO> {
    return this.http.put<ClassroomDTO>
      (
        `${this.bckEndUrlClassrooms}/${id}`,
        {
          Name: classroom.name,
          Description: classroom.description
        },
        {
          headers: Helper.getAuthHeader()
        }
      );
  }

  createClassroom(classroom: Classroom, idCustomer?: number, googleCaledar?: boolean): Observable<ClassroomDTO> {
    let params = new HttpParams();

    if (googleCaledar != undefined)
      params = params.append('calendar', googleCaledar);

    return this.http.post<ClassroomDTO>
      (
        `${this.bckEndUrlClassrooms}`,
        {
          name: classroom.name,
          description: classroom.description,
          idCustomer: idCustomer
        },
        {
          headers: Helper.getAuthHeader(),
          params: params
        }
      );
  }

  removeUserFromClass(id: number, classId: number) {
    return this.http.delete<User[]>(`${this.bckEndUrlClassrooms}/${classId}/user/${id}`, { headers: Helper.getAuthHeader() });
  }

  addUserToClass(id: number, classId: number) {
    return this.http.post(`${this.bckEndUrlClassrooms}/${classId}/user/${id}`, undefined, { headers: Helper.getAuthHeader() });
  }

  setUserAsPublisher(classId: number, userId: number) {
    return this.http.post(`${this.bckEndUrlClassroomsV2}/${classId}/publishers/${userId}`, { }, { headers: Helper.getAuthHeader() });
  }

  removeUserFromPublishers(classId: number, userId: number) {
    return this.http.delete(`${this.bckEndUrlClassroomsV2}/${classId}/publishers/${userId}`, { headers: Helper.getAuthHeader() });
  }

  getUsersOfClassroom(classId: number, type?: 0 | 1 | 2, publishers?: boolean) {
    let params = new HttpParams();

    if (type)
      params = params.append('userType', type);

    if (publishers)
      params = params.append('publishers', publishers);

    return this.http.get<UserDTO[]>(`${this.bckEndUrlClassroomsV2}/${classId}/users`, { params: params, headers: Helper.getAuthHeader() });
  }

}

<div fxLayout="column" class="component-main" style="width: 100%; height: 100%; align-items: center;">
    <h2>Changelog</h2>
    <div fxLayout="column" fxLayoutGap="40px" style="width: 60%; height: 100%;">
        <mat-card appearance="outlined" *ngFor="let changelog of changelogs" class="mat-elevation-z8">
            <mat-card-header>
                <mat-card-title>
                    Release {{ changelog.version }}
                </mat-card-title>
                <mat-card-subtitle style="margin-top: 10px;">
                    {{ changelog.releaseDate }}
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style="margin-left: 20px; margin-right: 20px;">
                <div *ngIf="changelog.newFeatures.length > 0" fxLayout="row" class="tile">
                    <div fxLayout="column" fxFlex="20%" fxLayoutAlign="start center">
                        <button mat-fab color="primary" style="pointer-events: none;">
                            <mat-icon>star</mat-icon>
                        </button>
                    </div>
                    <div fxLayout="column" fxFlex="80%">
                        <div fxLayout="row" fxFlex="20%" class="title">
                            New Features
                        </div>
                        <div fxLayout="row" fxFlex>
                            <mat-list>
                                <mat-list-item *ngFor="let feature of changelog.newFeatures">
                                    <mat-icon matListItemIcon>{{ feature[1] }}</mat-icon>
                                    {{ feature[0] }}
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                </div>
                <mat-divider *ngIf="changelog.fixAndImprovements.length > 0"></mat-divider>
                <div *ngIf="changelog.fixAndImprovements.length > 0" fxLayout="row" class="tile">
                    <div fxLayout="column" fxFlex="20%" fxLayoutAlign="start center">
                        <button mat-fab color="primary" style="pointer-events: none;">
                            <mat-icon>bug_report</mat-icon>
                        </button>
                    </div>
                    <div fxLayout="column" fxFlex="80%">
                        <div fxLayout="row" fxFlex="20%" class="title">
                            Bug Fixes / Improvements
                        </div>
                        <div fxLayout="row" fxFlex>
                            <mat-list>
                                <mat-list-item *ngFor="let fix of changelog.fixAndImprovements">
                                    <mat-icon matListItemIcon>{{ fix[1] }}</mat-icon>
                                    {{ fix[0] }}
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                </div>
                <mat-divider *ngIf="changelog.breakingChanges.length > 0"></mat-divider>
                <div *ngIf="changelog.breakingChanges.length > 0" fxLayout="row" class="tile">
                    <div fxLayout="column" fxFlex="20%" fxLayoutAlign="start center">
                        <button mat-fab color="primary" style="pointer-events: none;">
                            <mat-icon>new_releases</mat-icon>
                        </button>
                    </div>
                    <div fxLayout="column" fxFlex="80%">
                        <div fxLayout="row" fxFlex="20%" class="title">
                            Breaking Changes
                        </div>
                        <div fxLayout="row" fxFlex>
                            <mat-list>
                                <mat-list-item *ngFor="let break of changelog.breakingChanges">
                                    <mat-icon matListItemIcon>{{ break[1] }}</mat-icon>
                                    {{ break[0] }}
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { ProvinceDTO } from 'src/app/models/dto/provinceDTO';
import { UserService } from 'src/app/services/user.service';
import { BillingData } from '../models/billingData';

@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.scss']
})
export class BillingInfoComponent implements OnInit {
  
  currentUser: User;

  profileName: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  nation: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  province: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  city: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  cap: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  address: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  pec: UntypedFormControl = new UntypedFormControl('', [Validators.email]);
  recipientCode: UntypedFormControl = new UntypedFormControl('');

  name: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  surname: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  codiceFiscale: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  rSociale: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  pIva: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  codFiscaleAz: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  type: 0 | 1 = 0;

  provinces: ProvinceDTO[];

  constructor(private dialogRef: MatDialogRef<BillingInfoComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private auth: AuthService,
              private userService: UserService) {
    
    this.currentUser = this.auth.getCurrentUser();
    this.profileName.setValue(this.currentUser.username);
    this.name.setValue(this.currentUser.name);
    this.surname.setValue(this.currentUser.surname);

    if (this.data.type === 'company')
      this.type = 1;
    if (this.data.type === 'person')
      this.type = 0;
    
  }

  ngOnInit(): void { 
    this.getProvinces();
  }

  isValid(): boolean {
    let check = this.profileName.valid &&
                this.nation.valid &&
                this.province.valid &&
                this.city.valid &&
                this.cap.valid &&
                this.address.valid &&
                this.pec.valid;

    if (this.type === 0)
      return check &&
             this.name.valid &&
             this.surname.valid &&
             this.codiceFiscale.valid;
    else
      return check &&
             this.rSociale.valid &&
             (this.pIva.valid || this.codFiscaleAz.valid);
  }

  sendData() {
    this.dialogRef.close(<BillingData>{
      type: this.type,
      destinationCustomer: this.data.customer,
      elements: this.data.elements,
      price: this.data.price,
      currency: this.data.currency,
      profileName: this.profileName.value,
      nation: this.nation.value,
      province: this.province.value,
      city: this.city.value,
      CAP: this.cap.value,
      address: this.address.value,
      PEC: this.pec.value,
      recipientCode: this.recipientCode.value,
      name: this.name.value,
      surname: this.surname.value,
      codFiscale: this.codiceFiscale.value,
      rSociale: this.rSociale.value,
      PIVA: this.pIva.value,
      codFiscaleAz: this.codFiscaleAz.value
    });
    
  }

  onNoClick() {
    this.dialogRef.close(undefined);
  }

  getProvinces() {
    this.userService.getProvinces().subscribe({
        next: res => {
            this.provinces = res;
        },
        error: err => {
            console.log(err);
        }
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'src/app/helpers/helper';
import { DarkThemeService } from 'src/app/services/dark-theme.service';

@Component({
  selector: 'app-quick-viewer',
  templateUrl: './quick-viewer.component.html',
  styleUrls: ['./quick-viewer.component.scss']
})
export class QuickViewerComponent implements OnInit {

  fileUrl: string = null;
  fileType: FileType = FileType.unknown;

  constructor(public dialogRef: MatDialogRef<QuickViewerComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              public darkService: DarkThemeService) { }

  ngOnInit(): void {
    this.fileUrl = this.data;

    var extension = Helper.getFileExtension(Helper.getFileNameFromURI(this.fileUrl));

    if (extension === 'pdf')
      this.fileType = FileType.pdf;

    if (extension === 'mp4' ||
        extension === 'mkv' ||
        extension === 'webm')
     this.fileType = FileType.video;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  isPdf() {
    return this.fileType === FileType.pdf;
  }
}

enum FileType {
  unknown = 0,
  video = 1,
  pdf = 2
}

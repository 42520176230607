import { ApplicationConfig } from "@angular/core";
import { importProvidersFrom } from '@angular/core';
import { GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { provideHttpClient, HttpClient } from '@angular/common/http';
import { TranslateLoader, provideTranslateService } from '@ngx-translate/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { SpeedTestModule } from 'ng-speed-test';
import { RouteReusePath } from "./models/routeReusePath";
import { PlayerEventsComponent } from "./components/player-events/player-events.component";
import { routes } from "./app.routes";
import { environment } from "src/environments/environment";
import { RolePipe } from "./pipes/rolePipe";
import { provideQuillConfig } from "ngx-quill";
import { YOUTUBE_PLAYER_CONFIG } from "@angular/youtube-player";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(),
        provideAnimations(),
        provideNativeDateAdapter(),
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            }
        }),
        provideCharts(withDefaultRegisterables()),
        importProvidersFrom(
            CalendarModule.forRoot({
                provide: DateAdapter,
                useFactory: adapterFactory
            })
        ),
        importProvidersFrom(SpeedTestModule),
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusePath
        },
        provideQuillConfig({
            modules: {
                toolbar: [
                    [{ header: [] }],
                    ['bold', 'italic', 'underline', 'strike', { 'script': 'sub'}, { 'script': 'super' }, 'clean'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }], // { 'align': [] }
                    ['link'] // 'blockquote', 'code-block'
                    // [{ 'color': [] }, { 'background': [] }],
                    // [{ 'font': [] }],
                    // [{ 'direction': 'rtl' }]
                ]
            },
            format: 'html',
            theme: 'snow'
        }),
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'it-IT'
        },
        {
            provide: YOUTUBE_PLAYER_CONFIG,
            useValue: {
                disablePlaceholder: true
            }
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.googleClientId)
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebookAppId)
                    }
                ]
            } as SocialAuthServiceConfig,
        },
        {
            provide: OverlayContainer,
            useClass: FullscreenOverlayContainer
        },
        DatePipe,
        RolePipe,
        PlayerEventsComponent
    ]
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

<mat-card appearance="outlined" class="card">
    <div class="column-left">
        <mat-card-header>
            <mat-card-title class="card-title truncate-text">
                {{ title }}
            </mat-card-title>
            <mat-card-subtitle *ngIf="subtitle" class="truncate-text" style="font-size: 18px">
                {{ subtitle }}
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="card-content">
            <div [innerHtml]="description" class="card-description"></div>
            <div class="card-footer">
                <ng-content select="[leftBottom]"></ng-content>
                <div>
                    <button mat-raised-button color="accent" (click)="goTo()" *ngIf="!isMobile">
                        <mat-icon>launch</mat-icon>
                        <div class="truncate-text">{{ routeMessage | translate }}</div>
                    </button>
                </div>
            </div>
        </mat-card-content>
    </div>
    <div class="column-right">
        <img [src]="imageUrl" [alt]="title" loading="lazy">
        <div class="shadow"></div>
        <button mat-fab (click)="onClose.emit(true)" class="close-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-card>

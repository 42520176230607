import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyService } from 'src/app/services/survey.service';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SmartEditComponent } from '../../survey-components/smart-edit/smart-edit.component';
import { SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';

const UPDATE_SURVEY_INTERVAL: number = 10000; //ms

@Component({
  selector: 'app-survey-creator-page',
  templateUrl: './survey-creator-page.component.html',
  styleUrls: ['./survey-creator-page.component.scss']
})
export class SurveyCreatorPageComponent implements OnInit, OnDestroy {

  surveyLesson: SurveyLessonDTO;
  surveyDto: SurveyDTO;
  nameForm: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  descriptionForm: UntypedFormControl = new UntypedFormControl('');
  teacherForm: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  idSurvey: number;
  json: any;
  updateInterval: any = undefined;
  currentUser: User;

  @ViewChildren(SmartEditComponent) smartEdits: QueryList<SmartEditComponent>;

  constructor(private route: ActivatedRoute,
              private surveyService: SurveyService,
              private snackbar: MatSnackBar,
              private auth: AuthService,
              private location: Location) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.surveyDto = new SurveyDTO();
    
    if (this.route.snapshot.params['id']) {

        this.idSurvey = this.route.snapshot.params['id'];

        this.surveyService.getSurvey(this.idSurvey)
          .subscribe(output => {

            this.surveyDto = output;
            this.json = output.surveyJSON;

          });

    }

    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = undefined;
    }
    this.updateInterval = setInterval(() => this.updateSurvey(), UPDATE_SURVEY_INTERVAL);
  }

  ngOnDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }

    this.updateSurvey();
  }

  onSurveySaved(survey: any) {
    this.surveyDto.surveyJSON = JSON.stringify(survey);
  }

  updateSurvey() {
    if (this.surveyDto.idAuthor === this.currentUser.id) {
      console.log('Survey saved', this.surveyDto);
      this.surveyService.putSurvey(this.surveyDto.id, this.surveyDto)
        .subscribe({
          next: data => console.log(data),
          error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
        });
    }
  }      

  goBack() {
    this.location.back();
  }       

  saveName(event: any) {
    this.surveyDto.name = event;
    this.updateSurvey();
  }

  saveDescription(event: any) {
    this.surveyDto.description = event;
    this.updateSurvey();
  }

}

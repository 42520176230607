<div mat-dialog-content style="max-height: 90vh !important;">
    <ngx-extended-pdf-viewer *ngIf="isPdf()"
        [useBrowserLocale]="true"
        [src]="fileUrl"
        [textLayer]="true"
        [showBorders]="false"
        [showOpenFileButton]="false"
        [showBookmarkButton]="false"
        [showPresentationModeButton]="true"
        [enablePrint]="false"
        [showDownloadButton]="false"
        [contextMenuAllowed]="false"
        [theme]="darkService.isSetDark ? 'dark' : 'light'"
        height="85vh">
    </ngx-extended-pdf-viewer>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">
        {{ 'Close' | translate }}
    </button>
</div>

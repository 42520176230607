import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationStart, Router } from "@angular/router";
import { Subscription, firstValueFrom } from "rxjs";
import { PackageContentType } from "../models/packageState";
import { CalendarService } from "./calendar.service";
import { EduPlanService } from "./eduplan.service";
import { PackageService } from "./package.service";
import { CourseService } from "./course.service";
import { WallService } from "../wall/service/wall.service";
import { SocialWallContentDTO } from "../models/dto/SocialWallContentDTO";

const MAX_LENGTH_DEFAULT = 4;

@Injectable({
    providedIn: 'root'
})
export class SelectionService {
    private routerSub: Subscription;

    private _elements: SelectionElement[] = [];
    private _mode: SelectionMode = null;
    private _showSelection: boolean = false;
    private _maxLength: number = MAX_LENGTH_DEFAULT;
    private _backupElements: SelectionElement[] = []; //Utilizzato solo per il SocialWall SC
    private _entityId: number = null; //Utilizzata solo per le chiamate alle API
    private _redirectId: number = null; //Utilizzato solo per il reindirizzamento a fine selezione

    private _data: any = null; //Utilizzato per casi specifici, contiene informazioni aggiuntive
    public set data(values: any) { this._data = values; }
    public get data(): any { return this._data; }

    public get elements(): SelectionElement[] { return this._elements; }
    public get mode(): SelectionMode { return this._mode; }
    public get showSelection(): boolean { return this._showSelection; }
    public get maxLength(): number { return this._maxLength; }
    public get backupElements(): SelectionElement[] { return this._backupElements; } //Utilizzato solo per il SocialWall SC

    constructor (private router: Router,
                 private calendar: CalendarService,
                 private packageService: PackageService,
                 private eduPlanService: EduPlanService,
                 private courseService: CourseService,
                 private wallService: WallService,
                 private snackBar: MatSnackBar) {
        
        this.routerSub = router.events.subscribe((event) => {

            if (!(event instanceof NavigationStart))
                return;

            if (!this.showSelection)
                return;

            let url = event.url.split('/');
            let page = url.length > 1 ? url[1] : '';
            let id = url.length > 2 ? url[2].replace(/\?.*$/, '') : undefined;

            let check = (this.mode === SelectionMode.lessonContents && !page.includes('course'))
                     || (this.mode === SelectionMode.socialWallContents && !page.includes('wall') && !page.includes('course'))
                     || (this.mode === SelectionMode.masterContents && !page.includes('course'))
                     || (this.mode === SelectionMode.package && !page.includes('showcase') && !page.includes('course') && !page.includes('master'))
                     || (this.mode === SelectionMode.eduPlan && !page.includes('showcase') && !page.includes('course'))
                     || (this.mode === SelectionMode.folder && !(page === 'course' && id === String(this._redirectId)) && !(page === 'course-content' && id === String(this._entityId)));

            if (check)
                this.cancelSelection(SelectionCancel.exitedContainer);
            
        });
    }

    public startSelection (entityId: number, mode: SelectionMode, redirectId: number = entityId, restoreElements?: any[]) {
        if (this._showSelection) {
            this.snackBar.open('There is already a selection in progress, cannot start another one');
            return;
        }

        this._elements = [];
        this._entityId = entityId;
        this._redirectId = redirectId;
        this._mode = mode;

        this._showSelection = true;
        this.restoreElements(restoreElements);

        if (this._mode === SelectionMode.lessonContents ||
            this._mode === SelectionMode.masterContents ||
            this._mode === SelectionMode.socialWallContents) {
                this._maxLength = MAX_LENGTH_DEFAULT;
                this.router.navigate(['/courses']);
        } else if (this._mode === SelectionMode.package ||
                   this._mode === SelectionMode.eduPlan) {
            this._maxLength = 100;
            this.router.navigate(['/showcase']);
        } else if (this._mode === SelectionMode.folder) {
            this._maxLength = 1000;
            this.router.navigate(['/course', this._redirectId], { queryParams: { page: 'sidenav' } });
        }
    }

    public cancelSelection (reason: SelectionCancel) {
        this._showSelection = false;

        if (reason === SelectionCancel.userCanceled)
            this.snackBar.open('Selection canceled', '', { duration: 4000 });
        if (reason === SelectionCancel.exitedContainer)
            this.snackBar.open('Selection canceled, when selecting don\'t exit the course pages', '', { duration: 4000 });

        //Reindirizzamento
        if (reason !== SelectionCancel.logout) {

            if (this._mode === SelectionMode.lessonContents)
                this.router.navigate(['/calendar']);
            else if (this._mode === SelectionMode.socialWallContents)
                this.router.navigate(['/wall-page'], { queryParams: { selection: reason === SelectionCancel.apiUpload } });
            else if (this._mode === SelectionMode.masterContents)
                this.router.navigate(['/master', this._redirectId], { queryParams: { page: 'sidenav' } });
            else if (this._mode === SelectionMode.package)
                this.router.navigate(['/package', this._redirectId]);
            else if (this._mode === SelectionMode.eduPlan)
                this.router.navigate(['/eduplan-list']);
            else if (this._mode === SelectionMode.folder)
                this.router.navigate(['/course-content', this._entityId]);

        }

        //Reset
        this._elements = [];
        this._entityId = null;
        this._redirectId = null;
        this._mode = null;
        this._data = null;
    }

    public removeElement (id: number, type: number = 0) {
        let index = this.elements.findIndex(c => c.id === id && c.type === type);
        return index !== -1 ? this.removeElementByIndex(index) : null;
    }

    public removeElementByIndex (index: number) {
        return this.elements.splice(index, 1);
    }

    public elementExist (id: number, type: number = 0) {
        return this.elements.findIndex(c => c.id === id && c.type === type) !== -1;
    }

    public addElement (element: SelectionElement) {
        if (!this.elementExist(element.id, element.type) && !this.isFull())
            this.elements.push(element);
    }

    public isFull () {
        return this.elements.length >= this.maxLength;
    }

    //Chiama le API per salvare i dati
    public async saveElements() {
        //Salvataggio dati
        if (this._mode === SelectionMode.lessonContents || this._mode === SelectionMode.masterContents) {

            await firstValueFrom(this.calendar.addLessonContents(this._entityId, this.elements.map(e => e.originEntity.id)))
                .then(() => this.snackBar.open('Contents added successfully to lesson', '', { duration: 3000 }))
                .catch(err => {
                    console.error(err);
                    this.snackBar.open('Error adding contents to lesson', '', { duration: 3000 });
                });

        } else if (this._mode === SelectionMode.socialWallContents) {

            this._backupElements = this._elements;

            if (this._entityId != undefined)
                this._elements.forEach(async e => {

                    let link = new SocialWallContentDTO();
                    link.contentLink = "" + e.originEntity.id;
                    link.description = e.originEntity.name;
                    link.type = 3;

                    await firstValueFrom(this.wallService.postSocialWallContent(link));

                });

        } else if (this._mode === SelectionMode.package) {

            await firstValueFrom(this.packageService.addEntities(this._entityId, this.elements.map(e => e.originEntity)))
                .then(() => this.snackBar.open('Elements added successfully to package', '', { duration: 3000 }))
                .catch(err => {
                    console.error(err);
                    this.snackBar.open('Error adding elements to package', '', { duration: 3000 });
                });

        } else if (this._mode === SelectionMode.eduPlan) {

            await firstValueFrom(this.eduPlanService.addEduPlanContents(this._entityId, this.elements.map(e => e.originEntity)))
                .then(() => this.snackBar.open('Contents added successfully to educational plan', '', { duration: 3000 }))
                .catch(err => {
                    console.error(err);
                    this.snackBar.open('Error adding contents to educational plan', '', { duration: 3000 });
                });

        } else if (this._mode === SelectionMode.folder) {

            await firstValueFrom(this.courseService.addContentsToFolder(this._entityId, this.elements.map(e => e.id)))
                .then(() => this.snackBar.open('Contents added successfully to folder', '', { duration: 3000 }))
                .catch(err => {
                    console.error(err);
                    this.snackBar.open('Error adding contents to folder', '', { duration: 3000 });
                });

        }

        //Reset
        this.cancelSelection(SelectionCancel.apiUpload);
    }

    //Utilizzata solamente nel SocialWall quando viene eseguito un post SC
    public clearBackupElements() {
        this._backupElements = [];
    }

    //Chiama l'API per avere gli elementi precedentemente selezionati
    private restoreElements (elementsToRestore: any[]) {
        if (this._mode === SelectionMode.lessonContents || this._mode === SelectionMode.masterContents) {
            this.calendar.getLessonContents(this._entityId)
                .subscribe({
                    next: output => {
                        if (output)
                            output.forEach(c => this.addElement(new SelectionElement(c.id, c.name, c.headerImageUrl, c)));
                    },
                    error: err => {
                        this.snackBar.open('Error getting lesson contents', '', { duration: 3000 });
                    }
                });
        } else if (this._mode === SelectionMode.socialWallContents) {
            this._elements = this._backupElements;
            this._backupElements = [];
        } else if (this._mode === SelectionMode.package) {
            elementsToRestore.forEach(e => {

                //if (e.type === PackageContentType.master)
                //    this.addElement(new SelectionElement(e.master.id, e.master.name, e.master.imageUrl, e, e.type));
                if (e.type === PackageContentType.course)
                    this.addElement(new SelectionElement(e.course.id, e.course.name, e.course.imageUrl, e, e.type));
                    
            });
        } else if (this._mode === SelectionMode.eduPlan) {
            this.eduPlanService.getEduPlanContents(this._entityId)
                .subscribe({
                    next: output => {
                        if (!output)
                            return;

                        output.forEach(c => {
                            
                            if (c.idCourse != null)
                                this.addElement(new SelectionElement(c.course.id, c.course.name, c.course.imageUrl, c, 0));
                            if (c.idMaster != null)
                                this.addElement(new SelectionElement(c.master.id, c.master.name, c.master.imageUrl, c, 1));

                        });
                    },
                    error: err => {
                        this.snackBar.open('Error getting educational plan contents', '', { duration: 3000 });
                    }
                });
        } else if (this._mode === SelectionMode.folder) {
            this._elements = elementsToRestore.map(e => new SelectionElement(e.id, e.name, e.headerImageUrl, e));
        }
    }
}

export class SelectionElement {
    id: number; //Id dell'entita, per ricerca
    name: string; //Nome dell'entita, solo per visualizzazione
    type: number; //Tipo dell'entita, per ricerca
    pictureUrl: string; //Foto dell'entita, solo per visualizzazione
    originEntity: any; //Entita, per salvataggio

    constructor (id: number,
                 name: string,
                 pictureUrl: string,
                 originEntity: any,
                 type: number = 0) {

        this.id = id;
        this.name = name;
        this.type = type;
        this.pictureUrl = pictureUrl;
        this.originEntity = originEntity;
    }
}

export enum SelectionMode {
    lessonContents = 0,
    socialWallContents = 1,
    masterContents = 2,
    package = 3,
    eduPlan = 4,
    folder = 5
}

export enum SelectionCancel {
    userCanceled = 0,
    apiUpload = 1,
    exitedContainer = 2,
    logout = 3,
}

import { Component, OnInit } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { CheckListAnswerDTO } from 'src/app/models/dto/CheckListDTO';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-checklist-popup',
  templateUrl: './checklist-popup.component.html',
  styleUrls: ['./checklist-popup.component.scss']
})
export class ChecklistPopupComponent implements OnInit {

  currentUser;
  checkListId: number = 0;
  questions: any[] = [];
  answers: CheckListAnswerDTO[] = [];
  state: boolean = false;

  constructor(public dialogRef: MatDialogRef<ChecklistPopupComponent>,
              private auth: AuthService,
              private calendar: CalendarService) { 
    dialogRef.disableClose = true;
    dialogRef.updateSize('25vw');
  }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();

    this.getCheckList();
  }

  getCheckList() {
    this.calendar.getCheckListOfCustomer(this.currentUser.idCustomer)
      .subscribe(output => {
        if(output.length != 0){
          this.checkListId = output[0].id;
          this.getQuestions();
        }
    });
  }

  getQuestions() {
    this.questions = null;
    this.calendar.getQuestionsOfList(this.checkListId)
      .subscribe(output => {
        this.questions = output;
        this.questions.forEach(q => {
          q.yesNo = false;
        });
    });
  }

  setAnswers() {
    for(var i=0; i<this.questions.length; i++){
      this.answers[i] = new CheckListAnswerDTO();
      this.answers[i].idStudent = this.currentUser.id;
      this.answers[i].idQuestion = this.questions[i].id;
      this.answers[i].yesNo = this.questions[i].yesNo;
      this.answers[i].response = this.questions[i].response;
    }

    this.calendar.createAnswers(this.answers)
      .subscribe(error => 
        console.log(error)
      );
  }

  checkAnswers() {
    var temp = true;

    this.questions.forEach(q => {
      if(!q.yesNo && !q.response)
        temp = false;
    });

    this.state = temp;
  }

  closePopUp() {
    this.setAnswers();
    this.dialogRef.disableClose = false;

    this.dialogRef.close(true);
  }

  onNoClick() {
    if(this.state)
      this.dialogRef.close(false);
  }
}

export class SetDataPointReply {
    public setPoint: string;
    public value: string;
    public valueSet: boolean;

    constructor(setPoint: string,
                value: string,
                valueSet: boolean) {

        this.setPoint = setPoint;
        this.value = value;
        this.valueSet = valueSet;
    }
}

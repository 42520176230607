import { Component, HostListener, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentCustomerType, EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';

const BOTTOM_BAR_HEIGHT: number = 64; //px
const GRID_COLS_NUMBER: number = 5;
const GRID_ROWS_NUMBER: number = 5;

@Component({
  selector: 'app-licar-login',
  templateUrl: './licar-login.component.html',
  styleUrl: './licar-login.component.scss'
})
export class LicarLoginComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.rowSize = Math.floor((window.innerHeight - BOTTOM_BAR_HEIGHT) / GRID_ROWS_NUMBER) - 1;
  }

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  logo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.Licar, EnvironmentParameterType.Logo);
  backgroundImageUrl: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.Licar, EnvironmentParameterType.BackgroundImage)})`;
  backgroundImageMobile: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.Licar, EnvironmentParameterType.BackgroundImageMobile)})`;

  idCustomer: number = 175;
  customerCode: string = 'LICAR2024';

  colsNumber: number = GRID_COLS_NUMBER;
  rowSize: number = 0;

  constructor(
    private themeService: DarkThemeService,
    private navBar: NavBarService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.themeService.onLogOut();
    this.navBar.hide('topbar');
  }

  ngOnInit(): void {
    this.onResize(undefined);
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { differenceInMinutes } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewGraphComponent, ViewGraphData } from 'src/app/popup/view-graph/view-graph.component';
import { DarkThemeService } from 'src/app/services/dark-theme.service';

@Component({
  selector: 'app-course-view',
  templateUrl: './course-view.component.html',
  styleUrls: ['./course-view.component.scss']
})
export class CourseViewComponent implements OnInit {

  previewViewsChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          display: false,
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: true,
          color: this.darkService.isSetDark ? 'grey' : ''
        }
      }
    },
    events: [],
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: this.darkService.isSetDark ? 'white' : ''
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        color: this.darkService.isSetDark ? 'white' : ''
      }
    }
  };

  previewMinutesChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: true,
          color: this.darkService.isSetDark ? 'grey' : ''
        }
      }
    },
    events: [],
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: this.darkService.isSetDark ? 'white' : ''
        }
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        color: this.darkService.isSetDark ? 'white' : ''
      }
    }
  };

  viewsChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: true,
          color: this.darkService.isSetDark ? 'grey' : ''
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: this.darkService.isSetDark ? 'white' : ''
        }
      },
      datalabels: {
        color: this.darkService.isSetDark ? 'white' : ''
      }
    }
  };

  minutesChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          display: true,
          color: this.darkService.isSetDark ? 'grey' : ''
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: this.darkService.isSetDark ? 'white' : ''
        }
      },
      datalabels: {
        color: this.darkService.isSetDark ? 'white' : ''
      }
    }
  };

  barChartType: ChartType = 'bar';
  barChartPlugins = [DataLabelsPlugin];

  totalMinutesData: ChartDataset[] = [];
  totalMinutesLabels: string[] = [];

  totalViewsData: ChartDataset[] = [];
  totalViewsLabels: string[] = [];

  userViewsData: ChartDataset[] = [];
  userViewsLabels: string[] = [];

  years: number[] = [];
  year: FormControl<number> = new FormControl<number>(0, { nonNullable: true });

  intervalNumbers: number[] = [20, 50, 100];
  intervalNumber: FormControl<number> = new FormControl(
    this.intervalNumbers[0],
    {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.min(this.intervalNumbers[0]),
        Validators.max(this.intervalNumbers[this.intervalNumbers.length - 1])
      ]
    }
  );

  id: number;
  title: string;
  duration: number;

  data: UserDTO[] = [];
  dataSrc = new MatTableDataSource<{
    user: UserDTO,
    views: { start: number, end: number, count: number }[],
    mostViewedSample: { start: number, end: number, count: number },
    avgViews: number,
    firstView: Date,
    lastView: Date
  }>();

  displayedColumns: string[] = ['name', 'maxViews', 'avgViews', 'mostViewedSample', 'firstView', 'lastView', 'actions'];

  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.dataSrc.sort = matSort;
  }

  @ViewChild(MatPaginator) set matPaginator(matPaginator: MatPaginator) {
    this.dataSrc.paginator = matPaginator;
  }

  showGraph: 'totalviews' | 'totalminutes' | 'users' = undefined;
  type: 'video' | 'audio' | 'pdf' = undefined;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private darkService: DarkThemeService
  ) { }

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id');
    
    if (this.route.snapshot.queryParamMap.has('title'))
      this.title = this.route.snapshot.queryParamMap.get('title');

    if (this.route.snapshot.queryParamMap.has('duration'))
      this.duration = Number(this.route.snapshot.queryParamMap.get('duration'));

    if (this.route.snapshot.queryParamMap.has('type'))
      this.type = this.route.snapshot.queryParamMap.get('type') as 'video' | 'audio' | 'pdf';
  }

  ngAfterViewInit() {
    this.courseService
      .getContentTrackerAll(this.id)
      .subscribe(res => {

        res = res.map(r => {
          r.created = new Date(r.created);

          r.courseContentTracker = r.courseContentTracker.map(cct => {

            cct.created = new Date(cct.created);

            let start = cct.stopPosition < cct.startPosition ? cct.stopPosition : cct.startPosition;
            let end = cct.startPosition > cct.stopPosition ? cct.startPosition : cct.stopPosition;

            cct.startPosition = start;
            cct.stopPosition = end;

            return cct;
          });

          return r;
        });

        this.data = res;

        this.setTableData();

        this.dataSrc.sortingDataAccessor = (element, property) => {
          switch (property) {
            case 'name':
              return `${element.user.name} ${element.user.surname}`;
            case 'maxViews':
              return element.mostViewedSample?.count ?? 0;
            case 'mostViewedSample':
              return element.mostViewedSample
                   ? (this.type === 'pdf' ? element.mostViewedSample.start : `${this.secondsToHMS(element.mostViewedSample.start)} - ${this.secondsToHMS(element.mostViewedSample.end)}`)
                   : undefined;
            default: 
              return element[property];
          }
        };

      });
  }

  updateViews(views?: { start: number, end: number, count: number }[]) {
    let data = this.getViews(views);

    this.totalViewsLabels = data.labels;
    this.totalViewsData = data.dataset;
  }

  updateUsers() {
    let index = this.dataSrc.data.length > 0
              ? this.dataSrc.data.findIndex(d => d.firstView != undefined)
              : -1;

    let data = this.getViews(index !== -1 ? this.dataSrc.data[index].views : undefined);

    this.userViewsLabels = data.labels;
    this.userViewsData = index === -1 ? [] : data.dataset;
  }

  updateYearMinutes() {
    let data = this.getTrackersStats(this.dataSrc.data.map(d => d.user))
      .filter(mt => this.year.value !== 0 ? mt.year === this.year.value : true);

    let duration = 0;

    if (this.duration > 0) {
      let m = Math.floor(this.duration / 60);
      let s = this.duration % 60;

      duration = m;

      if (s > 0)
        duration += s / 100;
    }

    this.totalMinutesLabels = data.map(mt => mt.year.toString());
    this.totalMinutesData = [
      {
        data: data.map(d => d.time),
        label: this.translate.instant('Minutes'),
        backgroundColor: '#5d9cec'
      },
      {
        data: data.map(d => Number(duration.toFixed(2))),
        label: this.translate.instant('Duration'),
        backgroundColor: '#ed5565'
      }
    ];
  }

  setTableData() {
    let trackers = this.type === 'pdf' ? this.getPdfTrackersVisual(this.data) : this.getMediaTrackersVisual(this.data);

    this.dataSrc.data = this.data.map(r => {

      let userTrackers = trackers.filter(t => t.user.id === r.id);
      let views: { start: number, end: number, count: number }[] = [];

      userTrackers
        .flatMap(vt => vt.views)
        .map(view => {
    
          let i = views.findIndex(v => v.start === view.start);
  
          if (i === -1)
            views.push(view);
          else
            views[i].count += view.count;
  
      });

      return {
        user: r,
        views: views,
        mostViewedSample: views.filter(v => v.count > 0).reduce((prev, current) => prev?.count > current?.count ? prev : current, undefined),
        avgViews: views.length > 0 ? (views.map(v => v.count).reduce((partialSum, v) => partialSum + v, 0) / views.length) : 0,
        firstView: r.courseContentTracker.reduce((prev, current) => prev?.created < current?.created ? prev : current, undefined)?.created,
        lastView: r.courseContentTracker.reduce((prev, current) => prev?.created > current?.created ? prev : current, undefined)?.created
      };

    });

    this.updateViews();
    this.updateUsers();
    this.updateYearMinutes();
  }

  private getViews(views?: { start: number, end: number, count: number }[]): { dataset: ChartDataset[], labels: string[] } {
    if (!views) {
      views = [];

      (this.type === 'pdf' ? this.getPdfTrackersVisual(this.dataSrc.data.map(d => d.user)) : this.getMediaTrackersVisual(this.dataSrc.data.map(d => d.user)))
        .flatMap(v => v.views)
        .map(view => {
  
          let i = views.findIndex(v => v.start === view.start);
    
          if (i === -1)
            views.push(view);
          else
            views[i].count += view.count;
    
        });
    }

    return {
      labels: views.map(v => this.type === 'pdf' ? String(v.start) : `${this.secondsToHMS(v.start)} - ${this.secondsToHMS(v.end)}`),
      dataset: [{
        data: views.map(v => v.count),
        label: this.translate.instant("Views"),
        backgroundColor: '#ac92ec'
      }]
    };
  }

  private getPdfTrackersVisual(trackers: UserDTO[]) {
    if (trackers.length === 0 || this.duration === 0)
      return [];

    let data: { user: UserDTO, views: { start: number, end: number, count: number }[] }[] = trackers.map(t => ({ user: t, views: [] }));

    for (let slide = 1; slide <= this.duration; slide++) {
  
      for (let i = 0; i < trackers.length; i++) {
  
        let views = trackers[i].courseContentTracker.filter(cct => slide >= cct.startPosition && slide <= cct.stopPosition)?.length ?? 0;
        let index = data[i].views.findIndex(v => v.start === slide);
  
        if (index === -1)
          data[i].views.push({ start: slide, end: slide, count: views });
        else
          data[i].views[index].count += views;
  
      }
  
    }

    return data;
  }

  private getMediaTrackersVisual(trackers: UserDTO[]) {
    if (trackers.length === 0 || this.duration === 0)
      return [];

    let sampleDuration = Math.round(this.duration / this.intervalNumber.value);

    if (sampleDuration < 1)
      sampleDuration = 1;

    let data: { user: UserDTO, views: { start: number, end: number, count: number }[] }[] = trackers.map(t => ({ user: t, views: [] }));

    for (let start = 0; start < this.duration; start += sampleDuration) {

      let end = start + sampleDuration;

      if (end > this.duration)
        end = this.duration;

      for (let i = 0; i < trackers.length; i++) {

        let views = trackers[i].courseContentTracker.filter(cct => cct.startPosition <= end && cct.stopPosition >= start)?.length ?? 0;
        let index = data[i].views.findIndex(v => v.start === start);

        if (index === -1)
          data[i].views.push({ start: start, end: end, count: views });
        else
          data[i].views[index].count += views;

      }

    }

    return data;
  }

  private getTrackersStats(trackers: UserDTO[]) {
    if (trackers.length === 0 || this.duration === 0)
      return [];

    this.years = [];

    let data: { year: number, time: number, views: number }[] = [];

    trackers
      .flatMap(dt => dt.courseContentTracker)
      .map(cct => {

        let year = cct.created.getFullYear();

        if (!this.years.includes(year))
          this.years.push(year);

        let minutes = differenceInMinutes(cct.stopDate, cct.startDate);

        let index = data.findIndex(d => d.year === year);

        if (index === -1) {
          data.push({ year: year, time: minutes, views: 1 });
        } else {
          data[index].time += minutes;
          data[index].views++;
        }

    });

    return data;
  }

  viewUserGraph(user: UserDTO, views: { start: number, end: number, count: number }[]) {
    let data = this.getViews(views);

    this.dialog.open(ViewGraphComponent, {
      data: <ViewGraphData>{
        title: this.translate.instant('Data of user', { user: `${user.name} ${user.surname}` }),
        width: '950px',
        height: '475px',
        dataset: data.dataset,
        labels: data.labels,
        plugins: this.barChartPlugins,
        options: this.viewsChartOptions,
        type: this.barChartType
      },
      width: '1000px'
    });
  }

  secondsToHMS(seconds: number) {
    if (!seconds)
      return '00:00';

    let h = Math.floor(seconds / 3600);

    seconds %= 3600;

    let m = Math.floor(seconds / 60);

    let s = seconds % 60;

    let res = [];

    if (h > 0)
      res.push(String(h).padStart(2, '0'));

    //if (m > 0)
    res.push(String(m).padStart(2, '0'));

    //if (s > 0)
    res.push(String(s).padStart(2, '0'));

    return res.length > 0 ? res.join(':') : '0';
  }

  doFilter(e: any) {
    this.dataSrc.filter = e.target.value.trim().toLowerCase();
  }

  goBack() {
    if (this.showGraph != undefined) {
      this.showGraph = undefined;

      this.intervalNumber.reset();
      this.year.reset();

      this.setTableData();

      return;
    }

    this.location.back();
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentService } from '../service/payment.service';

@Component({
  selector: 'app-reauthenticate',
  templateUrl: './reauthenticate.component.html',
  styleUrls: ['./reauthenticate.component.scss']
})
export class ReauthenticateComponent implements OnInit {

  constructor(private auth: AuthService,
              private paymentservice: PaymentService,
              private route: ActivatedRoute) { }
  currentUser : User;
  stripeID : string;
  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
        this.stripeID = params['acctid'];
    });
  }

  getstripeacctLink(){
    this.currentUser = this.auth.getCurrentUser();
    this.paymentservice.getStripeLink(this.stripeID).subscribe(result => {
      this.stripeID = result;
      console.log(this.stripeID);
    });

  }
}

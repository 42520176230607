<app-mobile-warning
  *ngIf="isMobile; else desktopLogin"
  [idCustomer]="idCustomer"
  [codeCustomer]="customerCode"
  [showUsername]="false"
  [logo]="logo"
  [background]="backgroundImageMobile">
</app-mobile-warning>

<ng-template #desktopLogin>
  <div class="sfondo" [style]="backgroundImageUrl">
    
    <mat-grid-list [cols]="colsNumber" [rowHeight]="rowSize + 'px'">

      <mat-grid-tile [colspan]="3" [rowspan]="1">

        <div fxLayout="column" class="container-logo-top">
          <img src="../../../../assets/img/lcr/licar_loghi_2.svg" loading="lazy" class="logo-loghi-2">
        </div>

      </mat-grid-tile>

      <mat-grid-tile [colspan]="2" [rowspan]="5" style="overflow: auto">
        
        <div fxLayout="column" fxLayoutAlign="start" style="width: 100%">

          <mat-card appearance="outlined" style="width: 400px" class="mat-elevation-z8">

            <mat-card-content fxLayout="column" fxLayoutGap="20px">

              <app-access-form
                *ngIf="isLoginMode()"
                [idCustomer]="idCustomer">
              </app-access-form>

              <app-register-form
                *ngIf="isSignUpMode()"
                (result)="changeMode('login')"
                [codeCustomer]="customerCode"
                [showUsername]="false">
              </app-register-form>

              <mat-divider></mat-divider>

              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

                <div *ngIf="isSignUpMode()">
                  <button mat-stroked-button (click)="changeMode('login')">
                    {{ 'Log In' | translate }}
                  </button>
                </div>

                <div *ngIf="isLoginMode()">
                  <button mat-stroked-button (click)="changeMode('signup')">
                    {{ 'Sign Up'| translate }}
                  </button>
                </div> 
      
              </div>

            </mat-card-content>

          </mat-card>

        </div>

      </mat-grid-tile>

      <mat-grid-tile [colspan]="3" [rowspan]="3">

        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" class="container-text-middle">
          <span class="title">Digital Academy</span>

          <div fxLayout="column">
            <span class="subtitle-1">Scopri, Impara, Migliora</span>
            <span class="subtitle-2">Discover, Learn, Improve</span>
          </div>
          
        </div>

      </mat-grid-tile>

      <mat-grid-tile [colspan]="3" [rowspan]="1">

        <div fxLayout="column" class="container-logo-bottom">
          <img src="../../../../assets/img/lcr/licar_logo_motto.svg" loading="lazy" class="logo-motto">
          <img src="../../../../assets/img/lcr/licar_loghi_5.svg" loading="lazy" class="logo-loghi-5">
        </div>
        
      </mat-grid-tile>

    </mat-grid-list>

  </div>
</ng-template>

export enum DataPointType {
    speed = "speed",
    torque = "torque",
    pedalPressed = "pedalPressed",
    waitSpOff = "waitSPOff",
    holder3 = "holder3",
    holder2 = "holder2",
    holder1 = "holder1",
    standbyMode = "standbyMode",
    directionSp = "directionSP",
    muxOut2 = "muxOut2",
    muxOut1 = "muxOut1",
    endoModes = "endoModes",
    light = "light",
    motorStopped = "motorStopped",
    motorJammed = "motorJammed",
    torqueLimited = "torqueLimited",
    direction = "direction",
    driveError = "driveError",
    motorType = "motorType",
    torqueSp = "torqueSP",
    speedSp = "speedSP",
    lightIntensity = "lightInt",
    driveMode = "driveMode",
    lightSp = "lightSP",
    softwareVersion = "softwareVersion",
    productionDate = "productionDate",
    serialRefNo = "serialRefNo",
    serialYear = "serialYear",
    serialMonth = "serialMonth",
    serialIncNumber = "serialIncNumber",
    serialNo = "serialNo",
    speedMax = "speedMax",
    speedMin = "speedMin",
    torqueMax = "torqueMax",
    torqueMin = "torqueMin",
    lightIntMax = "lightIntMax",
    lightIntMin = "lightIntMin",
    lightModes = "lightModes"
}

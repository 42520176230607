<div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px" fxFlex>

  <div *ngIf="!token">
    <button mat-stroked-button class="card-button" (click)="goBack()" style="margin-bottom: 20px;">
      <mat-icon matListIcon>arrow_back</mat-icon>
      {{ 'Back' | translate }}
    </button>
  </div>

  <div>

    <mat-card
      *ngIf="!token"
      [style.width]="isMobile ? '80vw' : '40vw'"
      appearance="outlined"
      fxLayout="column"
      fxLayoutGap="20px"
      class="mat-elevation-z8">

      <mat-card-header>
        <mat-card-title>{{ 'Password change request' | translate }}</mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <mat-form-field subscriptSizing="dynamic">
          <mat-label>{{ 'Enter username or email' | translate }}</mat-label>
          <input matInput [formControl]="usernameOrEmail">
          <mat-error *ngIf="usernameOrEmail.hasError('required')">
            {{ 'Username or email are required' | translate }}
          </mat-error>
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions>

        <button mat-raised-button color="accent" (click)="sendCredential()" [disabled]="!usernameOrEmail.valid || sending">
          <mat-icon>send</mat-icon>
          {{ 'Send' | translate }}
        </button>

      </mat-card-actions>

    </mat-card>

    <mat-card
      *ngIf="token"
      [style.width]="isMobile ? '80vw' : '40vw'"
      appearance="outlined"
      fxLayout="column"
      fxLayoutGap="20px"
      class="mat-elevation-z8">

      <mat-card-header>
        <mat-card-title>
          {{ 'Change password' | translate }}
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <div [formGroup]="newPasswordForm" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  
          <mat-form-field subscriptSizing="dynamic" fxFlex>
            <mat-label>{{ 'New password' | translate }}</mat-label>
            <input matInput type="password" formControlName="newPassword">
            <mat-error *ngIf="newPasswordForm.controls.newPassword.hasError('required')">
              {{ 'New password is required' | translate }}
            </mat-error>
            <mat-error *ngIf="newPasswordForm.controls.newPassword.hasError('pattern')">
              {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
            </mat-error>
          </mat-form-field>
  
          <mat-form-field subscriptSizing="dynamic" fxFlex>
            <mat-label>{{ 'Repeat new password' | translate }}</mat-label>
            <input matInput type="password" formControlName="newPasswordRepeat">
            <mat-error *ngIf="newPasswordForm.controls.newPasswordRepeat.hasError('required')">
              {{ 'New password is required' | translate }}
            </mat-error>
            <mat-error *ngIf="newPasswordForm.controls.newPasswordRepeat.hasError('different')">
              {{ 'New password and password confirmation are not the same' | translate }}
            </mat-error>
          </mat-form-field>
  
        </div>
  
      </mat-card-content>

      <mat-card-actions>

        <button mat-raised-button (click)="updatePassword()" color="accent" [disabled]="!newPasswordForm.valid || sending">
          <mat-icon>edit</mat-icon>
          {{ 'Update' | translate }}
        </button>
      </mat-card-actions>

    </mat-card>

  </div>

  <div *ngIf="sending" class="cssload-container">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
  </div>

</div>

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { SubscriptionFeeDTO } from 'src/app/models/dto/subscriptionFeeDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ConferenceService } from 'src/app/services/conference.service';
import { CourseService } from 'src/app/services/course.service';
import { MasterService } from 'src/app/services/master.service';
import { PackageService } from 'src/app/services/package.service';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.scss']
})
export class AddSubscriptionComponent implements OnInit {

  currentUser: User = null;
  
  isFree: UntypedFormControl = new UntypedFormControl(false, [Validators.required]);
  entityId: number = null;
  entityType: number = null;
  disableFree: boolean = false;

  subToEdit: SubscriptionDTO = null;
  euro: number;
  dollar: number;
  pound: number;

  constructor(public dialogRef: MatDialogRef<AddSubscriptionComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private masterService: MasterService,
              private courseService: CourseService,
              private packageService: PackageService,
              private conferenceService: ConferenceService,
              private subscriptionService: SubscriptionService,
              private snackbar: MatSnackBar,
              private auth: AuthService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    if (this.currentUser.isDemo)
      this.isFree.setValue(true);

    let data = <CrudSubscription>this.data;

    this.entityId = data.entityId;
    this.entityType = data.entityType;
    this.disableFree = data.disableFree;
    
    if (data.subscription) {
      this.subToEdit = data.subscription;

      this.isFree.setValue(this.subToEdit.isFree);

      if (!this.subToEdit.isFree) {
        this.euro = this.subToEdit.subscriptionFee.euro;
        this.dollar = this.subToEdit.subscriptionFee.dollar;
        this.pound = this.subToEdit.subscriptionFee.pound;
      }
    }
  }

  addSubscription() {
    if (!this.subToEdit) {
    
      let fee = new SubscriptionFeeDTO();
      fee.euro = this.euro;
      fee.dollar = this.dollar;
      fee.pound = this.pound;

      let sub = new SubscriptionDTO();
      sub.isFree = this.isFree.value;
      sub.subscriptionFee = fee;

      if (this.entityType === SubscriptionType.master) {
        this.masterService.addSubscription(this.entityId, sub)
        .subscribe({
          next: () => this.okAPI('Subscription added'),
          error: err => this.errAPI(err)
        });
      }

      if (this.entityType === SubscriptionType.course) {
        this.courseService.addSubscription(this.entityId, sub)
        .subscribe({
          next: () => this.okAPI('Subscription added'),
          error: err => this.errAPI(err)
        });
      }

      if (this.entityType === SubscriptionType.package) {
        this.packageService.addSubscription(this.entityId, sub)
        .subscribe({
          next: () => this.okAPI('Subscription added'),
          error: err => this.errAPI(err)
        });
      }

      if (this.entityType === SubscriptionType.level) {
        this.subscriptionService.addSubscriptionLevelSubscription(this.entityId, sub)
        .subscribe({
          next: () => this.okAPI('Subscription added'),
          error: err => this.errAPI(err)
        });
      }

      if (this.entityType === SubscriptionType.webinar) {
        this.conferenceService.addSubscription(this.entityId,sub)
        .subscribe({
          next: () => this.okAPI('Subscription added'),
          error: err => this.errAPI(err)
        })
      }

    } else {
     
      if (this.subToEdit.isFree && !this.isFree.value) {
        let fee = new SubscriptionFeeDTO();
        fee.euro = this.euro;
        fee.dollar = this.dollar;
        fee.pound = this.pound;

        this.subToEdit.subscriptionFee = fee;
      } else if (!this.subToEdit.isFree && !this.isFree.value) {
        this.subToEdit.subscriptionFee.euro = this.euro;
        this.subToEdit.subscriptionFee.pound = this.pound;
        this.subToEdit.subscriptionFee.dollar = this.dollar;
      }

      this.subToEdit.isFree = this.isFree.value;
    
      if (this.entityType === SubscriptionType.master) {
        this.masterService.putSubscription(this.entityId, this.subToEdit)
        .subscribe({
          next: () => this.okAPI('Subscription edited'),
          error: err => this.errAPI(err)
        });
      }

      if (this.entityType === SubscriptionType.course) {
        this.courseService.putSubscription(this.entityId, this.subToEdit)
        .subscribe({
          next: () => this.okAPI('Subscription edited'),
          error: err => this.errAPI(err)
        });
      }

      if (this.entityType === SubscriptionType.package) {
        this.packageService.putSubscription(this.entityId, this.subToEdit)
        .subscribe({
          next: () => this.okAPI('Subscription edited'),
          error: err => this.errAPI(err)
        });
      }

      if (this.entityType === SubscriptionType.level) {
        this.subscriptionService.editSubscriptionLevelSubscription(this.entityId, this.subToEdit)
        .subscribe({
          next: () => this.okAPI('Subscription edited'),
          error: err => this.errAPI(err)
        });
      }

      if (this.entityType === SubscriptionType.webinar) {
        this.conferenceService.putSubscription(this.entityId,this.subToEdit)
        .subscribe({
          next: () => this.okAPI('Subscription edited'),
          error: err => this.errAPI(err)
        })
      }

     
    }
  }

  okAPI(msg: string) {
    this.snackbar.open(msg, '', { duration: 3000 });
    this.dialogRef.close(true);
  }

  errAPI(err: any) {
    this.snackbar.open(err.error.Message, '', { duration: 3000 });
    this.onNoClick();
  }

  getPriceValid() {
    if(this.isFree.value === false)
      return this.euro > 0 || this.dollar > 0 || this.pound > 0;

    this.euro;
    this.dollar;
    this.pound;

    return true;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }
}

export class CrudSubscription {
  public entityId: number;
  public entityType: number;
  public subscription: SubscriptionDTO;
  public disableFree?: boolean = false;
}

export enum SubscriptionType {
  master,
  course,
  package,
  level,
  webinar
}

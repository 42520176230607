<h1 mat-dialog-title>{{ 'Add Customer' | translate }}</h1>
<div mat-dialog-content>
    
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput type="text" [formControl]="name" required>
            <mat-error *ngIf="name.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
                {{ 'Name should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <input matInput type="text" [formControl]="description" required>
            <mat-error *ngIf="description.hasError('required')">
                {{ 'Description is required' | translate }}
            </mat-error>
            <mat-error *ngIf="description.hasError('minlength')">
                {{ 'Description should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Storagepath' | translate }}</mat-label>
            <input matInput type="text" placeholder="Customer Name" [formControl]="storagePath" required max="20">
            <mat-error *ngIf="storagePath.hasError('required')">
                {{ 'Storagepath is required' | translate }}
            </mat-error>
            <mat-error *ngIf="storagePath.hasError('minlength')">
                {{ 'Storagepath should be at most 20 characters,no special characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!isLevel">
        <mat-form-field color="primary">
            <mat-label>{{ 'Demo' | translate }}</mat-label>
            <mat-select [formControl]="isDemo" required>
                <mat-option [value]="0">{{'No' | translate}}</mat-option>
                <mat-option [value]="1">{{'Yes' | translate}}</mat-option>
             </mat-select>
            <mat-error *ngIf="isDemo.hasError('required')">
                {{ 'Demo is required' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isDemo.value === 1">
        <mat-form-field color="primary">
            <mat-label>{{ 'Demo duration' | translate }} ({{ 'days' | translate }})</mat-label>
            <input matInput type="number" [formControl]="demoDuration" required>
            <mat-error *ngIf="demoDuration.hasError('min') || demoDuration.hasError('max')">
                {{ 'Demo duration must be between 30 and 365 days' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field *ngIf="isLevel">
            <input matInput placeholder="Email" [formControl]="email" type="email" required>
            <mat-error *ngIf="email.hasError('required')">
            {{ 'Email is required' | translate }}
            </mat-error>
            <mat-error *ngIf="email.hasError('email')">
            {{ 'This is not an email address' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="saveCustomer()" [disabled]="btnDisabled()" color="accent">Ok</button>
</div>

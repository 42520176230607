import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { slk } from 'survey-core';
import { appConfig } from './app/app.config';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localePt from '@angular/common/locales/pt';

if (environment.production) {
  enableProdMode();
}

slk(environment.surveyJsKey);

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localePt, 'pt');

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));

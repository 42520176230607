import { Component, OnInit } from '@angular/core';
import { ClassroomService } from 'src/app/services/classroom.service';
import { Classroom } from 'src/app/models/classroom';
import { Router, NavigationExtras } from '@angular/router';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { from } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AddClassroomComponent } from './add-classroom/add-classroom.component';

@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.scss']
})
export class ClassroomComponent implements OnInit {

  currentUser: User;

  customers: any[] = [];
  classrooms: Classroom[];
  currentCustomerId: number = null;
  currentCustomerName: string = null;
  
  constructor(private auth: AuthService,
              private classService: ClassroomService,
              private router: Router,
              private dialog: MatDialog
            ) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.getClassrooms();
  }

  getClassrooms() {
    this.classService.getClassrooms(
      undefined,
      undefined,
      this.currentUser.isCustomerAdmin ? this.currentUser.idCustomer : undefined,
      true)
      .subscribe(classrooms => {
        this.classrooms = classrooms as Classroom[];
        this.classrooms.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)

        this.groupByClients(this.classrooms);
      })
  }

  goToClassroom(classroom: Classroom) {
    let navigationExtras: NavigationExtras;

    if (classroom.author != null)
      navigationExtras = {
        queryParams: {
            title: classroom.name,
            calendar: classroom.googleCalendar,
            idAuthor: classroom.idAuthor,
            created: classroom.created,
            authorName: `${classroom.author.name} ${classroom.author.surname}`
        }
      }
    else
      navigationExtras = {
        queryParams: {
            title: classroom.name,
            calendar: classroom.googleCalendar,
            idAuthor: classroom.idAuthor,
            created: classroom.created
        }
      }

    this.router.navigate(['classroom/', classroom.id], navigationExtras);
  }

  addClassroom() {
    const dialogRef = this.dialog.open(AddClassroomComponent,
    {
      width: '600px',
      data: {
        customerName: this.currentUser.isAdmin ? this.currentCustomerName : undefined,
        customerId: this.currentUser.isAdmin ? this.currentCustomerId : undefined
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.getClassrooms();
    });
  }

  editClassroom(classroom: Classroom) {
    const dialogRef = this.dialog.open(AddClassroomComponent,
    {
      width: '600px',
      data: {
        customerName: this.currentUser.isAdmin ? this.currentCustomerName : undefined,
        customerId: this.currentUser.isAdmin ? this.currentCustomerId : undefined,
        classroom: classroom
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.getClassrooms();
    });
  }

  deleteClassroom(id: number) {
    const dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: 'Delete classroom',
        body: 'Are you sure you want to delete this class?'
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.classService.deleteClassroom(id)
        .subscribe(() => this.getClassrooms());
    });
  }

  groupByClients(data: Classroom[]) {
    this.customers = [];

    if (!data || data.length === 0) {
      this.setCustomer(undefined);
      return;
    }

    from(data)
      .pipe(
        groupBy(classroom => classroom.customerName),
        mergeMap(group => group.pipe(toArray()))
      )
      .subscribe(val => this.customers.push(val))
      .add(() => this.setCustomer(this.customers[0]));
  }

  setCustomer(classroom?: Classroom) {
    this.currentCustomerId = classroom?.idCustomer ?? this.currentUser.idCustomer;
    this.currentCustomerName = classroom?.customerName ?? this.currentUser.customerName;
  }

  openUserStatus(classroom: Classroom) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
          classroomName: classroom.name
      }
    };

    if (classroom.author != null)
      navigationExtras = {
        queryParams: {
            classroomName: classroom.name,
            created: classroom.created,
            authorName: `${classroom.author.name} ${classroom.author.surname}`
        }
      };

    this.router.navigate(['classroomstatus/', classroom.id], navigationExtras);
  }

}

import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helpers/helper';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { ConferencePresenterDTO } from 'src/app/models/dto/conferencePresenterDTO';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';

@Component({
  selector: 'app-webinar-table',
  templateUrl: './webinar-table.component.html',
  styleUrl: './webinar-table.component.scss'
})
export class WebinarTableComponent {

  private _webinars: ConferenceDTO[] = [];

  @Input()
  set webinars(value: ConferenceDTO[]) {
    if (!value)
      return;

    this.webinarAuthorsList = value
      .map(w => w.lessonSession.teacher)
      .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);

    this.webinarModeratorList = value
      .flatMap(w => w.conferencePresenter.filter(cp => cp.role === ConferencePresenterRole.Moderator))
      .map(m => m.presenter)
      .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);

    this.webinarSpeakersList = value
      .flatMap(w => w.conferencePresenter.filter(cp => cp.role === ConferencePresenterRole.Presenter))
      .map(m => m.presenter)
      .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);

    this._webinars = value;
    this.dataSource.data = value;
  }

  @Input()
  showAuthorSearch: boolean = false;

  @Input()
  showModeratorSearch: boolean = false;

  @Input()
  showSpeakerSearch: boolean = false;

  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    this.dataSource.paginator = matPaginator;
  }

  @ViewChild(MatSort) set sort(matSort: MatSort) {

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'author':
          return this.getWebinarAuthor(item.lessonSession);
        case 'moderator':
          return this.getWebinarModerator(item.conferencePresenter);
        case 'speakers':
          return this.getWebinarSpeakers(item.conferencePresenter);
        case 'price':
          return this.getPrice(item.subscription);
        case 'visibility':
          return this.getWebinarVisibility(item);
        case 'state':
          return this.getWebinarState(item.lessonSession, 'name');
        case 'plannedDate':
          return item.lessonSession.startPlanned;
        default:
          return item[property];
      }
    };

    this.dataSource.sort = matSort;

  }

  dataSource: MatTableDataSource<ConferenceDTO> = new MatTableDataSource();
  displayedColumns: string[] = ['plannedDate', 'author', 'moderator', 'speakers', 'name', 'description', 'visibility', 'price', 'state', 'view'];

  authorForm: FormControl = new FormControl(0);
  moderatorForm: FormControl = new FormControl(0);
  speakerForm: FormControl = new FormControl(0);
  stateForm: FormControl = new FormControl(0);
  searchWordForm: FormControl = new FormControl();

  searchForm: FormControl = new FormControl();

  states: { name: string, value: number }[] = [
    { name: 'All', value: 0 },
    { name: 'Planned', value: 1 },
    { name: 'Done', value: 2 },
    { name: 'Running', value: 3 }
  ];
  webinarAuthorsList: UserDTO[] = [];
  webinarModeratorList: UserDTO[] = [];
  webinarSpeakersList: UserDTO[] = [];

  constructor(private router: Router) { }

  applyFilter() {
    this.dataSource.filter = this.searchWordForm.value?.trim()?.toLowerCase();
  }

  filter() {
    let data = this._webinars.slice();

    if (this.authorForm.value !== 0)
      data = data.filter(w => w.lessonSession.teacherId === this.authorForm.value);
    
    if (this.moderatorForm.value !== 0)
      data = data.filter(w => w.conferencePresenter.some(presenter => presenter.idPresenter === this.moderatorForm.value && presenter.role === ConferencePresenterRole.Moderator));

    if (this.speakerForm.value !== 0)
      data = data.filter(w => w.conferencePresenter.some(presenter => presenter.idPresenter === this.speakerForm.value && presenter.role === ConferencePresenterRole.Presenter));

    if (this.stateForm.value !== 0)
      data = data.filter(w => this.getWebinarState(w.lessonSession, 'code') === this.stateForm.value);

    this.dataSource.data = data;
  }

  getWebinarModerator(presenters: ConferencePresenterDTO[]) {
    let moderator = presenters?.find(p => p.role === ConferencePresenterRole.Moderator);

    if (!moderator)
      return '';

    return `${moderator?.presenter?.name} ${moderator?.presenter?.surname}`;
  }

  getWebinarAuthor(lessonSession: LessonSessionDTO) {
    return lessonSession?.teacher ? `${lessonSession.teacher.name} ${lessonSession.teacher.surname}` : '';
  }

  getWebinarSpeakers(presenters: ConferencePresenterDTO[]) {
    if (!presenters)
      return [];

    return presenters
      .filter(r => r.role === ConferencePresenterRole.Presenter)
      .map(p => `${p.presenter.name} ${p.presenter.surname}`);
  }

  getWebinarState(lessonSession: LessonSessionDTO, type: 'name' | 'code' | 'color') {
    if (lessonSession?.state === 2)
      return type === 'name' ? 'Planned' : type === 'code' ? 1 : 'rgb(255, 103, 103)';

    if (lessonSession?.state === 1)
      return lessonSession.stopDate ? (type === 'name' ? 'Done' : type === 'code' ? 2 : 'lightgreen') : (type === 'name' ? 'Running' : type === 'code' ? 3 : 'orange');

    return undefined;
  }

  getWebinarVisibility(element: ConferenceDTO) {
    return element.visibility === 1 ? 'Public' : 'Private';
  }

  getPrice(subscription: SubscriptionDTO) {
    if (!subscription)
      return 'No price set';

    return subscription.isFree
         ? 'Free'
         : `${subscription.subscriptionFee.euro} €`;
  }

  getTruncateString(value: string, nullValue: string, maxLength: number): string {
    return Helper.getTruncateShowcase(value, nullValue, maxLength);
  }

  goToWebinarShowcase(conferenceId: number) {
    this.router.navigate(['/webinar-showcase', conferenceId]);
  }

}

import { Publisher, Subscriber } from "openvidu-browser";
import { ClientData } from "./clientData";

export class ClientStream extends ClientData {
	manager: Publisher | Subscriber;
	handRaised: boolean = false;
	priority: number = 0;
	highlight: boolean = false;
	position: number = 0;

	constructor(participant: Publisher | Subscriber, data: ClientData) {
		super(data.userId, data.name, data.picture, data.role, data.mode, data.type, data.color, data.hidden, data.index);
		this.manager = participant;
	}
}

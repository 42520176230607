<div class="component-main">

    <div fxLayout="row" fxLayoutAlign="center center">
        <h2>{{ 'Dashboard' | translate }}</h2>
    </div>

    <div  fxLayout="row" fxLayoutAlign="center center">
        <button mat-stroked-button (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
    </div>

    <div>
        <div id="surveyResult"></div>
    </div>
    
</div>

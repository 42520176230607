export class CourseBlogEdit {
    public IdContent: number;
    public IdAuthor: number;
    public IdParent: number;
    public Title: string;
    public PostContent: string;
    public MediaUrl1: string;
    public MediaUrl2: string;
    public MediaUrl3: string;
    public MediaUrl4: string;
    public MediaUrl5: string;
    public State: number;
}
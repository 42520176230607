<h1 mat-dialog-title>{{ (isEdit ? 'Edit educational plan' : 'Add educational plan') | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput type="text" [formControl]="name" required>
            <mat-error *ngIf="name.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
                {{ 'Name should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <input matInput type="text" [formControl]="description" required>
            <mat-error *ngIf="description.hasError('required')">
                {{ 'Description is required' | translate }}
            </mat-error>
            <mat-error *ngIf="description.hasError('minlength')">
                {{ 'Description should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Year' | translate }}</mat-label>
            <input matInput type="number" [formControl]="year" required>
            <mat-error *ngIf="year.hasError('required')">
                {{ 'Year is required' | translate }}
            </mat-error>
            <mat-error *ngIf="year.hasError('min')">
                {{ 'Year should be equal or greater than' | translate }} {{ currentYear }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Classification' | translate }}</mat-label>
            <mat-select [formControl]="classification">
                <mat-option [value]="0">{{ 'Create classification' | translate }}</mat-option>
                <mat-option *ngFor="let class of classifications" [value]="class.id">
                  {{ class.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="classification.value === 0">
        <mat-form-field appearance="fill" [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput type="text" [formControl]="className" required>
            <mat-error *ngIf="className.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="className.hasError('minlength')">
                {{ 'Name should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <input matInput type="text" [formControl]="classDesc" required>
            <mat-error *ngIf="classDesc.hasError('required')">
                {{ 'Description is required' | translate }}
            </mat-error>
            <mat-error *ngIf="classDesc.hasError('minlength')">
                {{ 'Description should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field *ngIf="currentUser.isAdmin && !isEdit" [color]="isDark() ? 'accent' : 'primary'">
            <mat-label>{{ 'Customer' | translate }}</mat-label>
            <mat-select [formControl]="customer">
                <mat-option [value]="0">{{ 'All Customer' | translate }}</mat-option>
                <mat-option *ngFor="let item of customers" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="savePlan()" [disabled]="btnDisabled()" color="accent">{{ 'Ok' | translate }}</button>
</div>


<div class="page-content">
    <div class="dev" *ngIf="isDev()">
        <p style="color:white">DEV Version</p>
    </div>
    <a [href]="playStoreUrl" *ngIf="isAndroid() || isUnknown()">
        <img src="../../../assets/img/Google_Play_Badge.png" alt='Get it on Google Play' loading="lazy" class="store-btn-android">
    </a>
    <a [href]="appStoreUrl" *ngIf="isIos() || isUnknown()">
        <img src="../../../assets/img/App_Store_Badge.svg" alt='Download on the App Store' loading="lazy" class="store-btn-ios">
    </a>
    <mat-card appearance="outlined">
        <mat-card-content style="text-align:left">
            {{ "Once the application has been installed, press the 'Open in app' button again in the previous page to access to the content" | translate }}
        </mat-card-content>
    </mat-card>
</div>
 
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { User } from 'src/app/models/user';
import { PurchasedService } from 'src/app/services/purchased.service';
import { environment } from 'src/environments/environment';
import { Helper } from 'src/app/helpers/helper';
import { SubscriptionLevelDTO } from 'src/app/models/dto/subscriptionLevelDTO';
import { TranslationService } from 'src/app/services/translation.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient,
              private purchased: PurchasedService,
              private translationService: TranslationService) { }

  bckEndUrlSubcription = `${environment.apiUrl}/api/v2/Subscription`;

  getById (id: number): Observable<SubscriptionDTO> {
    return this.http.get<SubscriptionDTO>(`${this.bckEndUrlSubcription}/getById/${id}`,
      { headers: Helper.getAuthHeader() });
  }

  checkSubscriptionPurchaseLimits(id: number) {
    return this.http.get(`${this.bckEndUrlSubcription}/check/${id}`, { headers: Helper.getAuthHeader() });
  }

  getCanPurchase(IDSubscription: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.bckEndUrlSubcription}/checkcanpurchase/${IDSubscription}`,
      { headers: Helper.getAuthHeader() });
  }

  getState(IDSubscription: number): Observable<number> {
    return this.http.get<number>(`${this.bckEndUrlSubcription}/checksubscriptionstate/${IDSubscription}`,
      { headers: Helper.getAuthHeader() });
  }

  getsubscriptionlimituser(IDSubscription: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.bckEndUrlSubcription}/checksubscriptionlimituser/${IDSubscription}`,
      { headers: Helper.getAuthHeader() });
  }

  getSubscriptionLevels(): Observable<SubscriptionLevelDTO[]> {
    return this.http.get<SubscriptionLevelDTO[]>(`${this.bckEndUrlSubcription}/subscriptionLevel/all/${this.translationService.currentLang.lang}`,
      { headers: Helper.getAuthHeader() });
  }

  getSubscriptionLevelById(levelId: number): Observable<SubscriptionLevelDTO> {
    return this.http.get<SubscriptionLevelDTO>(`${this.bckEndUrlSubcription}/subscriptionLevel/id/${levelId}/${this.translationService.currentLang.lang}`,
      { headers: Helper.getAuthHeader() });
  }

  getSubscriptionLevelByKey(nameKey: string): Observable<SubscriptionLevelDTO[]> {
    return this.http.get<SubscriptionLevelDTO[]>(`${this.bckEndUrlSubcription}/subscriptionLevel/key/${nameKey}/${this.translationService.currentLang.lang}`,
      { headers: Helper.getAuthHeader() });
  }

  addSubscriptionLevel(subscriptionLevel: SubscriptionLevelDTO) {
    return this.http.post(`${this.bckEndUrlSubcription}/subscriptionLevel`, subscriptionLevel,
      { headers: Helper.getAuthHeader() });
  }

  editSubscriptionLevel(levelId: number, subscriptionLevel: SubscriptionLevelDTO) {
    return this.http.put(`${this.bckEndUrlSubcription}/subscriptionLevel/${levelId}/${this.translationService.currentLang.lang}`, subscriptionLevel,
      { headers: Helper.getAuthHeader() });
  }

  addSubscriptionLevelSubscription(levelId: number, subscription: SubscriptionDTO) {
    return this.http.post(`${this.bckEndUrlSubcription}/subscriptionLevel/${levelId}`, subscription,
    { headers: Helper.getAuthHeader() });
  }

  editSubscriptionLevelSubscription(levelId: number, subscription: SubscriptionDTO) {
    return this.http.put(`${this.bckEndUrlSubcription}/subscriptionLevel/${levelId}`, subscription,
    { headers: Helper.getAuthHeader() });
  }

  getSubscriptionEntityPage(subId: number) {
    return this.http.get<{ page: string }>(`${this.bckEndUrlSubcription}/page/${subId}`, { headers: Helper.getAuthHeader() });
  }
  
  async hasUserAccessToSubscription (user: User, subscriptionId: number) {
    if (user.isTeacher) // they always have access
      return true;

    if (subscriptionId <= 0)  // it means this subscription is free content
      return true;

    var subscription = await firstValueFrom(this.getById(subscriptionId));
    if (this.subscriptionHasExpired(subscription))
      return false;

    return this.hasUserAccessToSubscriptionDto(user, subscription);
  }

  subscriptionHasExpired (subscription: SubscriptionDTO) {
    if (subscription.timeValidity == 0)
      return false;

    return new Date() > new Date(subscription.dateTo);
  }

  private async hasUserAccessToSubscriptionDto (user: User, subscription: SubscriptionDTO) {
    await firstValueFrom(this.purchased.getSinglePurchased(user.id, subscription.id));
  }

}

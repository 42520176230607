import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { differenceInSeconds } from 'date-fns';
import { Helper } from 'src/app/helpers/helper';
import { LessonAggregateData } from 'src/app/models/lessonAggregateData';
import { CalendarService } from 'src/app/services/calendar.service';
import { CsvExporterService } from 'src/app/services/csv-exporter.service';

@Component({
  selector: 'app-aggregate-data-table',
  templateUrl: './aggregate-data-table.component.html',
  styleUrls: ['./aggregate-data-table.component.scss']
})
export class AggregateDataTableComponent implements OnInit {

  @Input()
  lessonId: number = undefined;

  @Input()
  userType: 'all' | 'presenters' | 'participants' = 'all';

  @Input()
  set refresh(value: any) {
    this.ngOnInit();
  }

  @Output()
  lessonAggregateData: EventEmitter<LessonAggregateData[]> = new EventEmitter<LessonAggregateData[]>();

  @ViewChild('participantsAggSort') participantsAggSort: MatSort;

  participantsAggSource: MatTableDataSource<LessonAggregateData> = new MatTableDataSource<LessonAggregateData>();
  participantsAggColumns: string[] = [
    'name',
    'surname',
    'startDate',
    'stopDate',
    'totalTimeInLessons',
    'totalSessions',
    'downlinkMin',
    'downlinkAvg',
    'rttMin',
    'rttAvg',
    'effectiveTypeMin',
    'effectiveTypeAvg'
  ];

  constructor(
    private calendar: CalendarService,
    private exportService: CsvExporterService
  ) { }

  ngOnInit(): void {
    this.calendar.getAggregateDataOfLesson(this.lessonId, this.userType === 'presenters' ? 2 : this.userType === 'participants' ? 1 : 0)
      .subscribe({
        next: output => {

          this.participantsAggSource.data = output;

          this.participantsAggSource.sortingDataAccessor = (element, property) => {
            switch (property) {
              case 'name':
                return element.user.name;
              case 'surname':
                return element.user.surname;
              default:
                return element[property];
            }
          };

          this.participantsAggSource.sort = this.participantsAggSort;

          this.lessonAggregateData.emit(output);

        },
        error: err => {
          this.participantsAggSource.data = [];
          console.error(err);
        }
      });
  }

  getPercentage(startDate: Date, endDate: Date, time: string) {
    let lessonTime = differenceInSeconds(startDate, endDate);
    let studentTime = Helper.timeFromTimeSpan(time, 'seconds');

    if (lessonTime === 0 || studentTime === 0)
      return 0;

    return (studentTime * 100) / lessonTime;
  }

  saveToCsv() {
    let exportedData: ExportAggregateData[] = this.participantsAggSource
      .data
      .map(data => { console.warn()

        let start = new Date(data.startDate);
        let stop = new Date(data.stopDate);

        return <ExportAggregateData>{
          name: data.user.name,
          surname: data.user.surname,
          startDate: start.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' }),
          stopDate: stop.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' }),
          presenceTime: data.totalTimeInLessons.slice(0, 8),
          totalSession: data.totalSessions,
          downlinkMin: data.downlinkMin.toPrecision(2),
          downlinkAvg: data.downlinkAvg.toPrecision(2),
          rttMin: data.rttMin.toPrecision(2),
          rttAvg: data.rttAvg.toPrecision(2),
          classificationMin: data.effectiveTypeMin,
          classificationAvg: data.effectiveTypeAvg
        }
      });

    this.exportService.exportDataToCsv(
      exportedData,
      [
        "Name",
        "Surname",
        "Start",
        "End",
        "Presence Time (hh:mm)",
        "Sessions",
        "Downlink min (Mbps)",
        "Downlink avg (Mbps)",
        "Rtt min (ms)",
        "Rtt avg (ms)",
        "Classification min",
        "Classification avg"
      ],
      `${this.userType === 'presenters' ? 'Presenters' : this.userType === 'participants' ? 'Paticipants' : 'Users'} aggregate data`
    );
  }

}

class ExportAggregateData {
  name: string;
  surname: string;
  startDate: string;
  stopDate: string;
  presenceTime: string;
  totalSession: number;
  downlinkMin: string;
  downlinkAvg: string;
  rttMin: string;
  rttAvg: string;
  classificationMin: string;
  classificationAvg: string;
}

<mat-card appearance="outlined">
    
    <mat-card-content fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">

        <div *ngIf="localPublisher" fxFlex="70">
            <div class="device-preview">
                <app-user-video
                    [streamManager]="localPublisher"
                    [name]="username"
                    [color]="color"
                    [displayName]="false"
                    [displayAudio]="false">
                </app-user-video>
            </div>
        </div>

        <div fxLayout="column" [fxLayoutAlign]="localPublisher ? 'space-between center' : 'space-evenly center'" style="height: 550px" fxFlex>
            <h2 style="margin: 0px">
                {{ (videoDeviceEnabled && audioDeviceEnabled ? 'Choose your devices' : ('Join ' + entityName)) | translate }}
            </h2>
            
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" class="full-width">

                <div *ngIf="videoDeviceEnabled" fxLayout="row" fxLayoutAlign="center center"fxLayoutGap="20px" style="width: 100%" class="margin-bottom">

                    <mat-form-field color="accent" fxFlex>
                        <mat-label>{{ 'Video Device' | translate }}</mat-label>
                        <mat-select [formControl]="videoDevice">
                            <mat-option [value]="false">{{ 'No camera' }}</mat-option>
                            <mat-option *ngFor="let device of videoDevices" [value]="device.deviceId">
                                {{ device.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div fxLayoutAlign="center center">
                        <mat-slide-toggle [(ngModel)]="_mirrorDevice" (change)="toggleMirror()" [disabled]="videoDevice.value === false || formLocked">{{ 'Mirror' | translate }}</mat-slide-toggle>
                    </div>

                </div>
        
                <mat-form-field color="accent" *ngIf="audioDeviceEnabled" class="margin-bottom">
                    <mat-label>{{ 'Audio Device' | translate }}</mat-label>
                    <mat-select [formControl]="audioDevice">
                        <!--mat-option [value]="false">{{ 'No microhone' | translate }}</mat-option-->
                        <mat-option *ngFor="let device of audioDevices" [value]="device.deviceId">
                            {{ device.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        
                <div class="full-width margin-bottom" fxLayout="row">
        
                    <div *ngIf="videoDeviceEnabled" fxLayoutAlign="center center" fxFlex>
                        <mat-slide-toggle [(ngModel)]="_videoEnabled" (change)="toggleVideo()" [disabled]="videoDevice.value === false || formLocked">Video</mat-slide-toggle>
                    </div>
                        
                    <div *ngIf="audioDeviceEnabled" fxLayoutAlign="center center" fxFlex>
                        <mat-slide-toggle [(ngModel)]="_audioEnabled" (change)="toggleAudio()" [disabled]="audioDevice.value === false || formLocked">Audio</mat-slide-toggle>
                    </div>
    
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" [class.full-width]="localPublisher">

                    <div *ngIf="isValidBrowser; else browserIncompatible" fxLayout="column" fxLayoutAlign="center center">
                        <span *ngIf="checkNetwork(); else noConnection" class="full-width">
                            {{ 'Your network performance is' | translate }} <strong>{{ getNetworkClass() | translate }}</strong>
                        </span>

                        <ng-template #noConnection>
                            <strong class="full-width red" style="margin-top: 10px">
                                {{ 'No connection. Go to an area with a better connection' | translate }}
                            </strong>
                        </ng-template>

                    </div>

                    <ng-template #browserIncompatible>
                        <div fxLayout="column" fxLayoutAlign="center center">
                            <strong class="full-width red">
                                {{ 'The operation of this platform has been verified with Google Chrome browser v.> 80' | translate }}
                            </strong>
                        </div>
                    </ng-template>

                </div>

            </div>

            <div [ngClass]="localPublisher ? 'full-width' : 'mid-width'">
                <button mat-flat-button
                    color="accent"
                    class="full-width margin-bottom"
                    [disabled]="joinDisabled()"
                    (click)="ok()">
                    <mat-icon>login</mat-icon>
                    {{ ('Join ' + entityName) | translate }}
                </button>
                <button mat-flat-button
                    color="primary"
                    class="full-width"
                    (click)="cancel()">
                    <mat-icon>logout</mat-icon>
                    {{ 'Leave' | translate }}
                </button>
            </div>

        </div>

    </mat-card-content>
    
</mat-card>

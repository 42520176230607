import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-customer-confirm',
  templateUrl: './customer-confirm.component.html',
  styleUrls: ['./customer-confirm.component.scss']
})
export class CustomerConfirmComponent implements OnInit {

  sending: number = 0;
  msg: string = "Checking";

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private calendar: CalendarService,
              private navBar: NavBarService) { }

  ngOnInit(): void {
    let type = this.route.snapshot.paramMap.get('type');
    let levelId = Number(this.route.snapshot.paramMap.get('id'));
    let token = this.route.snapshot.paramMap.get('token');

    this.confirm(type as 'level' | 'demo', levelId, token);
  }

  confirm(type: 'level' | 'demo', levelId: number, token: string) {
    this.sending = 1;

    this.auth.confirmSubscriptionLevelUser(levelId, token)
      .then(async () => {

        if (type === 'level') {

          this.router.navigate(['/level', levelId], { queryParams: { stripe: true } });

        } else if (type === 'demo') {

          this.navBar.hide('all');

          // Post per la creazione del customer demo
          firstValueFrom(this.calendar.createDemoCustomer(levelId))
            .then(() => {

              this.sending = 2;
              this.msg = 'Demo customer created successfully, please check your email to access your demo';

              this.auth.logout(false);
              this.navBar.show('topbar');
              this.navBar.show('bottombar');

            })
            .catch(err => {
              console.error(err);

              this.msg = err.error.Message;
              this.sending = 0;
              
              this.navBar.show('topbar')
              this.navBar.show('bottombar');;
            });

        }
        
      })
      .catch(err => {
        this.msg = err.error.Message;
        this.sending = 0;
                    
        console.error(err);
      });
  }

}

<app-course
    [course]="course"
    *ngIf="choiceCourse === 'sidenav' && !receiving">
</app-course> 

<app-course-showcase 
    [course]="course"
    [currentUserIsParticipant]="currentUserIsParticipant"
    *ngIf="choiceCourse === 'showcase' && !receiving">
</app-course-showcase>

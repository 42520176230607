import { Pipe, PipeTransform } from "@angular/core";
import { EnvironmentHelper } from "../models/environmentVariables";

@Pipe({ name: 'role' })
export class RolePipe implements PipeTransform {
    transform(value: string): string {
        
        if (!value)
            return null;

        if (!EnvironmentHelper.isAixp())
            return value;

        return value
            .replace('student', 'user')
            .replace('students', 'users')
            .replace('Student', 'User')
            .replace('Students', 'Users');
    }
}

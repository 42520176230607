import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SurveyService } from 'src/app/services/survey.service';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';

@Component({
  selector: 'app-survey-analytics',
  templateUrl: './survey-analytics.component.html',
  styleUrl: './survey-analytics.component.scss'
})
export class SurveyAnalyticsComponent implements OnInit {

  private idSurvey: number;
  private idLesson: number;
  private idClassroom: number;

  private survey: SurveyDTO;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private surveyService: SurveyService
  ) { }

  ngOnInit(): void {
    this.idSurvey = this.route.snapshot.params['id'];
    this.idLesson = this.route.snapshot.queryParams['idLesson'];
    this.idClassroom = this.route.snapshot.queryParams['idClassroom'];

    this.surveyService.getSurvey(this.idSurvey)
      .subscribe(output => {
        this.survey = output;

        this.loadData();
      });
  }

  loadData() {
    let surveyResultNode = document.getElementById("surveyResult");
    surveyResultNode.innerHTML = "";

    this.surveyService.getSurveyAnswersClassroom(this.idClassroom, this.idLesson)
      .subscribe(res => {

        let validAnswers = res
          .filter(r => r.surveyAnswer.length > 0)
          .map(r => JSON.parse(r.surveyAnswer[0].surveyJSON));

          let surveyModel = new Model(this.survey.surveyJSON);

          let visPanel = new VisualizationPanel(
            surveyModel.getAllQuestions(),
            validAnswers,
            {
              labelTruncateLength: 27,
            }
          );

          visPanel.showToolbar = true;
          visPanel.render(surveyResultNode);
      });
  }

  goBack() {
    this.location.back();
  }

}

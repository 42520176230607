import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SubtitleEdit } from 'src/app/models/subtitleEdit';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-edit-subtitles',
  templateUrl: './edit-subtitles.component.html',
  styleUrls: ['./edit-subtitles.component.scss']
})
export class EditSubtitlesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  id: number;
  language: string;
  subtitles: MatTableDataSource<{ subtitle: SubtitleEdit, index: number }>;
  columns: string[] = ['start', 'end', 'lineValues', 'edit'];

  currentSubtitle: { subtitle: SubtitleEdit, index: number } = undefined;
  line1: FormControl = new FormControl(undefined, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]);
  line2: FormControl = new FormControl(undefined, [Validators.maxLength(100)]);

  sending: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
              private dialogRef: MatDialogRef<EditSubtitlesComponent>,
              private courseService: CourseService,
              private snackBar: MatSnackBar) {  }

  ngOnInit() {
    this.id = this.data.id;
    this.language = this.data.language;

    this.courseService.getCourseContentSubtitles(this.id, this.language)
      .subscribe(res => {

        let data = res.map((s, i) => <{ subtitle: SubtitleEdit, index: number }>{ subtitle: s, index: i });

        this.subtitles = new MatTableDataSource<{ subtitle: SubtitleEdit, index: number }>(data);
        this.subtitles.paginator = this.paginator;
        this.subtitles.sort = this.sort;

        this.subtitles.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'start':
              return item.subtitle.startTime;
            case 'end':
              return item.subtitle.endTime;
            case 'lineValues':
              return item.subtitle.lineValues ? item.subtitle.lineValues.join(' ') : undefined;
            default:
              return item[property];
          }
        };

        this.subtitles.filterPredicate = (data, filter) => {
          return data.subtitle.startTime.toLowerCase().includes(filter)
              || data.subtitle.endTime.toLowerCase().includes(filter)
              || data.subtitle.lineValues?.join(' ').toLowerCase().includes(filter);
        };

      });
  }

  edit(subtitle: { subtitle: SubtitleEdit, index: number }) {
    this.cancel();

    this.currentSubtitle = subtitle;

    if (!this.currentSubtitle.subtitle.lineValues)
      this.currentSubtitle.subtitle.lineValues = [];

    if (this.currentSubtitle.subtitle.lineValues.length > 0)
      this.line1.setValue(this.currentSubtitle.subtitle.lineValues[0]);

    if (this.currentSubtitle.subtitle.lineValues.length > 1)
      this.line2.setValue(this.currentSubtitle.subtitle.lineValues[1]);
  }

  cancel() {
    this.currentSubtitle = undefined;
    this.line1.reset();
    this.line2.reset();
  }

  save() {
    this.currentSubtitle.subtitle.lineValues = [];

    this.currentSubtitle.subtitle.lineValues.push(this.line1.value);
    this.currentSubtitle.subtitle.lineValues.push(this.line2.value);

    this.subtitles.data[this.currentSubtitle.index] = this.currentSubtitle;
    this.cancel();
  }

  applyFilter (input: HTMLInputElement) {
    this.subtitles.filter = input.value.trim().toLowerCase();
  }

  saveDisabled() {
    return !this.line1.valid || !this.line2.valid;
  }

  closeAndSave() {
    this.sending = true;

    this.courseService.putCourseContentSubtitles(this.id, this.language, this.subtitles.data.map(d => d.subtitle))
      .subscribe({
        next: () => {
          this.snackBar.open('subtitles saved successfully', '', { duration: 3000 });
          this.dialogRef.close(true);
        },
        error: err => {
          console.error(err);
          this.snackBar.open(err.message, '', { duration: 5000 });

          this.sending = false;
        }
      });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

<div fxLayout="row" style="width: 100%">
    <div fxLayoutAlign="start center" fxFlex="60%">
        {{ 'This website uses cookies to ensure you get the best experience. By clicking on Accept or continuing to browse otherwise, you will accept our terms and conditions' | translate }}
    </div>
    <div fxLayoutAlign="end center" fxFlex="15%">
        <a [href]="learnMore" target="_blank" style="padding: 10px; color: white">{{ 'Learn more' | translate }}</a>
    </div>
    <div fxLayoutAlign="start center" fxFlex="15%">
        <a [href]="cookiePolicy" target="_blank" style="padding: 10px; color: white">Cookie policy</a>
    </div>
    <div fxLayoutAlign="end center" fxFlex>
        <button mat-flat-button color="accent" style="color: black;" (click)="closeBanner()"
            fxLayoutAlign="center center">
            {{ 'Accept' | translate }}
        </button>
    </div>
</div>

export class NetworkInformation {

    public static bandwidth(): number {
        return (navigator as any)?.connection?.downlink;
    }

    public static maxBandwidth(): number {
        return (navigator as any)?.connection?.downlinkMax;
    }

    public static type(): string {
        return (navigator as any)?.connection?.type;
    }

    public static connectionType(): string {
        return (navigator as any)?.connection?.effectiveType;
    }

    public static rtt(): number {
        return (navigator as any)?.connection?.rtt;
    }

    public static reducedDataUsage(): boolean {
        return (navigator as any)?.connection?.saveData;
    }

}

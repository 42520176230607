<div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px;" fxFlex>
  <div *ngIf="!token">
    <button mat-stroked-button class="card-button" (click)="goBack()" style="margin-bottom: 20px;">
      <mat-icon matListIcon>arrow_back</mat-icon>
      {{ 'Back' | translate }}
    </button>
  </div>
  <mat-card appearance="outlined" *ngIf="!token" [style.width]="isMobile ? '90%' : '30%'" class="mat-elevation-z8">
    <mat-card-header>
      <mat-card-title>{{ 'Password change request' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field style="width: 100%;">
        <mat-label>{{ 'Enter username or email' | translate }}</mat-label>
        <input matInput [formControl]="usernameOrEmail">
        <mat-error *ngIf="usernameOrEmail.hasError('required')">
          {{ 'Username or email are required' | translate }}
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="accent" (click)="sendCredential()" [disabled]="!usernameOrEmail.valid">
        <mat-icon>send</mat-icon>
        {{ 'Send' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-card appearance="outlined" *ngIf="token"  [style.width]="isMobile ? '90%' : '40%'"  class="mat-elevation-z8">
    <mat-card-header>
      <mat-card-title>
        {{ 'Change password' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content [style.flex-direction]="isMobile ? 'column' : 'row'" style="display:flex;">
      <mat-form-field fxFlex>
        <mat-label>{{ 'New password' | translate }}</mat-label>
        <input matInput type="password" placeholder="" [formControl]="newPassword" required>
        <mat-error *ngIf="newPassword.hasError('required')">
          {{ 'New password is required' | translate }}
        </mat-error>
        <mat-error *ngIf="newPassword.hasError('pattern') && !isMobile">
          {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-error style="font-size:12px;margin-top:-15px;margin-bottom:10px" *ngIf="newPassword.hasError('pattern') && isMobile">
        {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
      </mat-error>
      <mat-form-field fxFlex>
        <mat-label>{{ 'Repeat new password' | translate }}</mat-label>
        <input matInput type="password" placeholder="" [formControl]="newPasswordRepeat" required>
        <mat-error *ngIf="newPasswordRepeat.hasError('required')">
          {{ 'New password is required' | translate }}
        </mat-error>
      </mat-form-field>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="updatePassword()" color="accent" [disabled]="!checkNewPassword()">
        <mat-icon>edit</mat-icon>
        {{ 'Update' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>

  <div *ngIf="sending" class="cssload-container">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
  </div>
</div>

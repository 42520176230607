import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { UserDTO } from 'src/app/models/dto/userDTO';

@Component({
  selector: 'app-author-info',
  templateUrl: './author-info.component.html',
  styleUrls: ['./author-info.component.scss']
})
export class AuthorInfoComponent implements OnInit {

  public margin: string = "10px";
  private _profile: UserDTO;
  private _compact: boolean = false;

  @Input()
  get profile(): UserDTO { return this._profile; }
  set profile(value: UserDTO) {
    this._profile = value ? value : new UserDTO();
  }

  @Input()
  get compact(): boolean { return this._compact; }
  set compact(value: boolean) {
    this._compact = value;
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToProfile(): void {
    if(this.profile.isAuthor)
      this.router.navigate(['/authorprofile', this.profile.id]);
    
    if(this.profile.isStudent) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
            name: this.profile.name,
            surname: this.profile.surname
        }
      };

      this.router.navigate(['/userstatus', this.profile.id], navigationExtras);
    }
  }

  getTooltip(): string {
    if(this.profile.isAuthor)
      return "Go to profile";
    
    return "User data";
  }
}

import { SignalRRequestType } from "./signalRRequestType";

export class SignalRMessage {
    public type: string;
    public data: string;
    public created: Date;

    constructor(type: SignalRRequestType,
                data: any) {

        this.type = type;
        this.data = typeof data === "string" ? data : JSON.stringify(data);
        this.created = new Date();
    }
}

function pack(message, id) {
  var result = {
    jsonrpc: '2.0'
  };
  if (message.method) {
    result.method = message.method;
    if (message.params) result.params = message.params;
    if (id != undefined) result.id = id;
  } else if (id != undefined) {
    if (message.error) {
      if (message.result !== undefined) throw new TypeError('Both result and error are defined');
      result.error = message.error;
    } else if (message.result !== undefined) result.result = message.result;else throw new TypeError('No result or error is defined');
    result.id = id;
  }
  return JSON.stringify(result);
}
function unpack(message) {
  var result = message;
  if (typeof message === 'string' || message instanceof String) {
    result = JSON.parse(message);
  }
  var version = result.jsonrpc;
  if (version !== '2.0') throw new TypeError("Invalid JsonRPC version '" + version + "': " + message);
  if (result.method == undefined) {
    if (result.id == undefined) throw new TypeError('Invalid message: ' + message);
    var result_defined = result.result !== undefined;
    var error_defined = result.error !== undefined;
    if (result_defined && error_defined) throw new TypeError('Both result and error are defined: ' + message);
    if (!result_defined && !error_defined) throw new TypeError('No result or error is defined: ' + message);
    result.ack = result.id;
    delete result.id;
  }
  return result;
}
exports.pack = pack;
exports.unpack = unpack;

<div class="component-main" fxLayoutAlign="center center" style="height: 100%">
    <div class="card mat-elevation-z8" style="text-align: center;">
        <div style="border-radius: 200px; height: 200px; width: 200px; background: #ffe2e2; margin: 0 auto;">
            <i>X</i>
        </div>
        <h1>{{ 'Payment failed' | translate }}</h1>
        <p>
            {{ "We couldn't process your payment" | translate }}
        </p>
        <br />
        <p>
            {{ 'Please try again later' | translate }}
        </p>
        <br />
        <button mat-raised-button color="primary" (click)="redirect()">
            <mat-icon>
                {{ isMobile ? 'close' : 'payment' }}
            </mat-icon>
            {{ (isMobile ? 'Close' : 'Go to your cart') | translate }}
        </button>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Helper } from 'src/app/helpers/helper';
import { CartItemDTO } from '../models/cart-item-dto';
import { DiscountCodeDTO } from 'src/app/models/dto/discountCodeDTO';
import { BillingData } from '../models/billingData';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BillingInfoComponent } from '../billing-info/billing-info.component';
import { MatDialog } from '@angular/material/dialog';

interface SessionIdDTO {
  id: string;
  stripeApiKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private http: HttpClient,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  // stripePromise = loadStripe(environment.stripeApi);

  bckEndUrlPayment = `${environment.apiUrl}/api/v2/payment`;

  updateSessionStatus (cartItems: CartItemDTO[]): Observable<SessionIdDTO> {
    // sessionStorage.removeItem("currentCart");
    this.http.post<SessionIdDTO>(`${this.bckEndUrlPayment}/checkoutsuccess`, cartItems,
      { headers: Helper.getAuthHeader() }).subscribe(async (session) => {
        //  sessionStorage.removeItem("currentCart");
      })
    return null;
  }

  getCheckoutSession (cartItems: CartItemDTO[]): Observable<SessionIdDTO> {
    let domain = window.location.protocol + "//" + window.location.hostname + ":443"; // :" + window.location.port;
    domain = btoa(domain);
    console.log(domain);
    return this.http.post<SessionIdDTO>(`${this.bckEndUrlPayment}/checkout/${domain}`, cartItems,
      { headers: Helper.getAuthHeader() });
  }

  getFreeSubscriptions (cartItems: CartItemDTO[]): Observable<SessionIdDTO> {
    let domain = window.location.protocol + "//" + window.location.hostname + ":443"; // :" + window.location.port;
    domain = btoa(domain);
    console.log(domain);
    return this.http.post<SessionIdDTO>(`${this.bckEndUrlPayment}/freesubs/${domain}`, cartItems,
      { headers: Helper.getAuthHeader() });
  }

  async startCheckout (cartItems: CartItemDTO[],
                       billingData?: { customer: number, elements: CartItemDTO[], price: number, currency: string, type: 'company' | 'person' }) {
    // Call your backend to create the Checkout session.
    sessionStorage.setItem('currentCarttobepurchased', JSON.stringify(cartItems));

    let billingDataToSave = null;

    if (billingData) {

      const dialogRef = this.dialog.open(BillingInfoComponent, {
        width: '500px',
        data: billingData,
        disableClose: true
      });

      billingDataToSave = await firstValueFrom(dialogRef.afterClosed());

      if (!billingDataToSave)
        return;

    }

    await firstValueFrom(this.getCheckoutSession(cartItems))
      .then(async (session) => {

        if (billingDataToSave) {

          billingDataToSave.session = session.id;

          await firstValueFrom(this.addBillingData(billingDataToSave));
          
        }

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await loadStripe(session.stripeApiKey);
        const { error } = await stripe.redirectToCheckout({ sessionId: session.id });

        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        if (error)
          console.log(error);

    })
    .catch(err => {
      console.error(err);
      this.snackBar.open(err.error.Message, null, { duration: 5000 });
    });

  }

  checkDiscountCode(code: string): Observable<DiscountCodeDTO> {
    return this.http.get<DiscountCodeDTO>(`${this.bckEndUrlPayment}/discountCode/${code}`, { headers: Helper.getAuthHeader() });
  }

  private addBillingData(data: BillingData) {
    return this.http.post(`${this.bckEndUrlPayment}/billingData`, data, { headers: Helper.getAuthHeader() });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Device, OpenVidu } from 'openvidu-browser';
import { StandardLessonConfig } from 'src/app/models/joinLessonConfig';
import { CalendarService } from 'src/app/services/calendar.service';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClassroomService } from 'src/app/services/classroom.service';

const LESSON_CONFIG_KEY: string = "lessonConfig";

const SCREEN_SHARE_DEVICE: Device = { kind: 'videoinput', deviceId: 'screen', label: 'Screen sharing' };
const NO_AUDIO_DEVICE: Device = { kind: 'audioinput', deviceId: 'noaudio', label: 'No audio device' };

@Component({
    selector: 'app-join-session-dialog',
    templateUrl: './join-session-dialog.component.html',
    styleUrls: ['join-session-dialog.component.scss']
})
export class JoinSessionDialogComponent implements OnInit {

    isEasyLesson: boolean = false;
    lesson: any = undefined;

    joinWithVideo = true;
    joinWithAudio = true;

    OV: OpenVidu = undefined;
    videoDeviceArr: Device[] = [];
    audioDeviceArr: Device[] = [];
    resolutions: { title: string, key: 'low' | 'medium' | 'high' | 'veryhigh', value: string }[] = [
        {
            title: 'VGA',
            key: 'low',
            value: '640x480'
        },
        {
            title: 'HD',
            key: 'medium',
            value: '1280x720'
        },
        {
            title: 'Full HD',
            key: 'high',
            value: '1920x1080'
        },
        {
            title: '4K',
            key: 'veryhigh',
            value: '3840x2160'
        }
    ];
    classrooms: ClassroomDTO[] = [];
    tabGlobalIndex: number = 0;
    tabScenarioIndex: number = 0;
    lessonOpenInClass: LessonSessionDTO = undefined;

    videoForm1: FormControl<string> = new FormControl(undefined, [Validators.required]);
    videoForm2: FormControl<string> = new FormControl(undefined, [Validators.required]);
    videoForm3: FormControl<string> = new FormControl(undefined, [Validators.required]);
    audioForm: FormControl<string> = new FormControl(undefined);
    qualityForm: FormControl<'low' | 'medium' | 'high' | 'veryhigh'> = new FormControl('medium', [Validators.required]);
    scenarioForm: FormControl<number> = new FormControl(2, [Validators.required]);
    classroomForm: FormControl<number> = new FormControl(undefined, [Validators.required]);

    constructor(
        @Inject(MAT_DIALOG_DATA) private data,
        private dialogRef: MatDialogRef<JoinSessionDialogComponent>,
        private auth: AuthService,
        private calendar: CalendarService,
        private classroomService: ClassroomService,
        private snackBar: MatSnackBar
    ) {
        this.dialogRef.updateSize('600px');
    }

    async ngOnInit(): Promise<void> {

        this.lesson = this.data.lesson;
        this.isEasyLesson = this.data.easylesson === true;

        if (this.isEasyLesson)
            this.getClassrooms();

        if (this.auth.getCurrentUser().lowRes === 1) {
            this.qualityForm.setValue('low');
            this.qualityForm.disable();
        }

        this.OV = new OpenVidu();

        try {
	
            let userMedia = await this.OV.getUserMedia({ audioSource: undefined, videoSource: undefined });

            userMedia.getTracks().forEach(t => t.stop());

            let devices = await this.OV.getDevices();

            this.videoDeviceArr = devices.filter(d => d.kind === 'videoinput');
            this.audioDeviceArr = devices.filter(d => d.kind === 'audioinput');

            this.videoForm1.setValue(this.videoDeviceArr[0].deviceId);
            this.audioForm.setValue(this.audioDeviceArr[0].deviceId);

        } catch (e) {
            console.error(e);

            this.snackBar.open('please connect a device and allow camera and microphone access to continue', 'Dismiss', {
                duration: 5000,
                verticalPosition: 'top'
            });
        }

        this.videoDeviceArr.push(SCREEN_SHARE_DEVICE);
        this.audioDeviceArr.push(NO_AUDIO_DEVICE);

        this.restoreConfig();
        this.OV = null;

    }

    joinSession() {
        if (this.tabGlobalIndex === 0)
            this.scenarioForm.setValue(1);

        let output: JoinSessionDialogData = {
            classroomId: this.classroomForm.value,
            scenario: this.scenarioForm.value,
            options: {
                quality: this.resolutions.find(r => r.key === this.qualityForm.value).value,
                videoEnabled: this.joinWithVideo,
                audioEnabled: this.joinWithAudio,
                audioDevice: this.audioForm.value === NO_AUDIO_DEVICE.deviceId ? undefined : this.audioForm.value,
                scenario: [
                    this.videoForm1.value
                ]
            }
        };

        if (this.scenarioForm.value > 1 && this.tabGlobalIndex === 1)
            output.options.scenario.push(this.videoForm2.value);

        if (this.scenarioForm.value > 2 && this.tabGlobalIndex === 1)
            output.options.scenario.push(this.videoForm3.value);

        this.setConfig();

        this.dialogRef.close(output);
    }

    getClassrooms() {
        this.classroomService.getClassrooms()
            .subscribe(output => { 
                this.classrooms = output as ClassroomDTO[];

                if (this.classrooms.length > 0) {
                    this.classroomForm.setValue(output[0].id);

                    if (this.isEasyLesson)
                        this.checkClassLesson();
                }
            });
    }

    tabChangePage(index: number) {
        this.tabGlobalIndex = index;
    }

    setConfig() {
        let config: StandardLessonConfig = {
            quality: this.resolutions.find(r => r.key === this.qualityForm.value)?.value,
            videoEnabled: this.joinWithVideo,
            audioEnabled: this.joinWithAudio,
            audioDevice: this.audioForm.value,
            scenario: [
                this.videoForm1.value
            ]
        };

        if (this.videoForm2.value != undefined)
            config.scenario.push(this.videoForm2.value);

        if (this.videoForm3.value != undefined)
            config.scenario.push(this.videoForm3.value);

        localStorage.setItem(LESSON_CONFIG_KEY, JSON.stringify(config));
    }

    restoreConfig() {
        let config: StandardLessonConfig = JSON.parse(localStorage.getItem(LESSON_CONFIG_KEY));

        if (!config)
            return;

        this.joinWithVideo = config.videoEnabled;
        this.joinWithAudio = config.audioEnabled;

        this.qualityForm.setValue(this.resolutions.find(r => r.value === config.quality)?.key);
        this.audioForm.setValue(config.audioDevice);

        if (config.scenario.length > 0)
            this.videoForm1.setValue(config.scenario[0]);

        if (config.scenario.length > 1)
            this.videoForm2.setValue(config.scenario[1]);

        if (config.scenario.length > 2)
            this.videoForm3.setValue(config.scenario[2]);
    }

    checkClassLesson() {
        this.lessonOpenInClass = null;

        this.calendar.getOpenLessonInClass(this.classroomForm.value)
            .subscribe(output => {
                if (!output)
                    return;

                this.lessonOpenInClass = output;
            });
    }

    isScreenShare() {
        return this.videoForm1.value === 'screen';
    }

    okBtnDisabled() {
        let check = !this.videoForm1.valid
                 || !this.qualityForm.valid;

        if (this.isEasyLesson)
            return check
                || !this.classroomForm.valid
                || this.lessonOpenInClass != undefined;

        if (this.tabGlobalIndex > 0 && this.scenarioForm.value === 2)
            check = check
                 || !this.scenarioForm.valid
                 || !this.videoForm2.valid;

        if (this.tabGlobalIndex > 0 && this.scenarioForm.value === 3)
            check = check
                 || !this.scenarioForm.valid
                 || !this.videoForm3.valid

        return check;

    }

    onNoClick(): void {
        this.OV = null;

        this.dialogRef.close();
    }
}

export class JoinSessionDialogData {
    classroomId: number;
    scenario: number;
    options: StandardLessonConfig;
}

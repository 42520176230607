<div>

    <div class="row-arrange">

        <mat-form-field color="primary">
            <mat-label>{{ 'Search' | translate }}</mat-label>
            <input matInput [formControl]="searchWordForm" (keyup)="applyFilter()">
            <button matSuffix mat-icon-button
                (click)="searchWordForm.setValue(undefined); applyFilter();"
                [disabled]="!searchWordForm.value"
                [matTooltip]="'Clear' | translate">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>

    </div>

    <mat-table matSort [dataSource]="dataSource" style="width: 100%">

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Participant' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

                    <app-profile-picture [url]="element.picture"></app-profile-picture>
                    <span>{{ element.name }}</span>

                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.role | translate }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="subscribed">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Joined' | translate }} ({{ 'Platform' | translate }})</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.subscribed | date: 'dd/MM/yyyy HH:mm' }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="joined">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Joined' | translate }} ({{ 'Conference' | translate }})</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ (element.joined | date: 'dd/MM/yyyy HH:mm') ?? '-' }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="classrooms">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Classrooms' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="showClassrooms(element.classrooms)">{{ showClassrooms(element.classrooms) }}</span>
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="totalOnlineTime">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Total online time' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ formatSeconds(element.totalOnlineTime) }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>{{ 'View' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-icon-button
                    (click)="goToUserDashboard(element.id)"
                    [matTooltip]="'Go to dashboard' | translate">
                    <mat-icon>assessment</mat-icon>
                </button>
            </mat-cell> 
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        <tr class="mat-row" style="height: 40px" *matNoDataRow>
            <td class="mat-cell" [colSpan]="displayedColumns.length" style="vertical-align: middle">
                <span style="margin: 10px">
                    {{ 'No data' | translate }}
                </span>
            </td>
        </tr>

    </mat-table>

    <mat-paginator #paginator
        [pageSize]="10"
        [pageSizeOptions]="[10, 25, 50]"
        [showFirstLastButtons]="true">
    </mat-paginator>

</div>

import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Helper } from 'src/app/helpers/helper';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-public-user-confirm',
  templateUrl: './public-user-confirm.component.html',
  styleUrls: ['./public-user-confirm.component.scss']
})
export class PublicUserConfirmComponent implements OnInit {

  token: string = null;
  type: string = null;
  sending: boolean = false;
  info: { title: string, body: string } = null;

  password: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.pattern(Helper.getValidPasswordRegex())]);
  passwordConfirm: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);

  constructor(private route: ActivatedRoute,
              private auth: AuthService) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.type = this.route.snapshot.paramMap.get('type');

    if (this.type !== 'invite')
      this.checkToken();
  }

  async checkToken() {
    this.sending = true;
    this.info = {
      title: 'Please wait',
      body: 'We are now confirming the user creation'
    };

    try {

      await this.auth.confirmUser(this.token, this.password.value);
      await this.auth.navigateToRequestedPath();

      this.info = null;

    } catch (e) {

      console.error(e);

      this.info = {
        title: 'Error',
        body: e.error.Message
      };

    }

    this.sending = false;
  }

  checkNewPassword() {
    return this.password.valid
        && this.passwordConfirm.valid
        && this.password.value === this.passwordConfirm.value;
  }
  
  checkNewPass(): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  
        this.checkNewPassword 
            ? null : {invalidPassword: control.value};
}

}

<h1 mat-dialog-title>{{ 'Send invitation to collaborate to' | translate }} {{ customerName }}</h1>
<div mat-dialog-content>

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field color="primary">
            <mat-label>{{ 'firstName' | translate }}</mat-label>
            <input matInput [formControl]="name" required>
            <mat-error>
                {{ 'The name is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
        
        <mat-form-field color="primary">
            <mat-label>{{ 'surname' | translate }}</mat-label>
            <input matInput [formControl]="surname" required>
            <mat-error>
                {{ 'The surname is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>     
        </mat-form-field>
        
    </div>

    <div fxLayout="row" fxLayoutGap="20px" class="field">
        <mat-form-field color="primary">
            <mat-label>{{ 'Email' | translate }}</mat-label>
            <input matInput [formControl]="email" required>
            <mat-error *ngIf="email.hasError('required')">
                {{ 'The email is' | translate }} <strong>{{ 'required' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="email.hasError('email')" >
                {{ 'Please enter a valid email address' | translate }} 
            </mat-error>
            <mat-error *ngIf="email.hasError('alreadyUsed')" >
                {{ 'Email already used' | translate }} 
            </mat-error>
        </mat-form-field>
        <mat-form-field color="primary">
            <mat-label>{{ 'Role' | translate }}</mat-label>
            <mat-select [formControl]="role" required>
                <mat-option *ngFor="let r of roles" [value]="r.role">
                    {{ r.label | role | translate }}
                </mat-option>
            </mat-select>
            <mat-error>
                {{ 'The role is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>

    </div>

    <div fxLayout="column" fxLayoutGap="20px" class="field">
        <mat-form-field color="primary" >
            <mat-label>{{ 'Text' | translate }}</mat-label>
            <textarea matInput [formControl]="text"></textarea>
        </mat-form-field>
    </div>

</div>

<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="inviteUser()" [disabled]="btnDisabled()" color="accent">{{ 'Send Invite' | translate }}</button>
</div>

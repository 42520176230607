import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { MasterDTO } from 'src/app/models/dto/masterDTO';
import { PackageDTO } from 'src/app/models/dto/packageDTO';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { PackageContentType } from 'src/app/models/packageState';
import { environment } from 'src/environments/environment';
import { CartItemDTO } from "../models/cart-item-dto"
import { SubscriptionService } from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
  private bckEndUrlCart = `${this.bckEndUrlBaseUrl}/cart`;
  
  private customers: CustomerDTO[] = [];
  private cartItems: CartItemDTO[] = [];

  constructor(private subscription: SubscriptionService) {

    if (sessionStorage.getItem('currentCart'))
      this.cartItems = JSON.parse(sessionStorage.getItem('currentCart'));

    if (sessionStorage.getItem('customersCart'))
      this.customers = JSON.parse(sessionStorage.getItem('customersCart'));

  }

  getCustomers() {
    return this.customers;
  }

  getItems() {
    return this.cartItems;
  }

  addMaster (master: MasterDTO) {
    return this.subscription.getById(master.idSubscription)
      .subscribe(subscription => this.addItem(subscription, master.imageUrl));
  }

  addCourse (course: CourseDTO) {
    return this.subscription.getById(course.idSubscription)
      .subscribe(subscription => this.addItem(subscription, course.imageUrl));
  }

  addPackage (packageEntity: PackageDTO) {
    if (packageEntity.packageContent == null || packageEntity.packageContent.length === 0)
      return;

    //Salvo gli id delle subscription dei package content appartenenti al package
    let packageSubscriptions: number[] = [];
    //let nestedSubscriptions: number[] = this.getCartNestedSubscriptions();

    for (let content of packageEntity.packageContent) {
      let subToCheck = 0;

      if (content.type === PackageContentType.course) subToCheck = content.course.idSubscription;
      if (content.type === PackageContentType.master) subToCheck = content.master.idSubscription;

      //Controllo che i contenuti del package non siano gia presenti nel carrello
      if (this.subscriptionIsPresentInCart(subToCheck)) {
        console.warn(`subscriptionId ${subToCheck} of packageId ${packageEntity.id} was found in cart`);
        return;
      }

      if (subToCheck != null && subToCheck != 0)
        packageSubscriptions.push(subToCheck);
    }

    //Controllo che siano impostati tutti gli elementi correttamente
    //Se qualche nuova entita non dovesse essere stata mappata (vedi da riga 75 a 78) allora impedisco l'aggiunta al carrello
    if (packageSubscriptions.length !== packageEntity.packageContent.length) {
      console.error("Set the entities check correctly in cart service!");
      return;
    }

    return this.subscription.getById(packageEntity.idSubscription)
      .subscribe(subscription => this.addItem(subscription, packageEntity.imageUrl, packageSubscriptions));
  }
  
  addConference(conference: ConferenceDTO) {
    return this.subscription.getById(conference.idSubscription)
      .subscribe(subscription => this.addItem(subscription, conference.imageUrl));
  }

  addItem (subscription: SubscriptionDTO, image: string, nestedSubscriptions: number[] = []) {

    //Controllo tutte le subscription contenute in altre subscription (package)
    if (this.subscriptionIsPresentInCart(subscription.id))
      return;

    let item = new CartItemDTO();
    item.id = this.cartItems.length > 0 ? Math.max(...this.cartItems.map(item => item.id)) + 1 :  1;
    item.subscriptionId = subscription.id;
    item.name = subscription.name;
    item.description = subscription.description;
    item.image = image;
    item.priceEuro = subscription.subscriptionFee?.euro ?? 0;
    item.priceUsd = subscription.subscriptionFee?.dollar ?? 0;
    item.priceGbp = subscription.subscriptionFee?.pound ?? 0;
    item.customer = subscription.customer;
    item.subscription = subscription;
    item.nestedSubscriptions = nestedSubscriptions;

    this.cartItems.push(item);

    if (this.customers.findIndex(c => c.id === subscription.idCustomer) === -1)
      this.customers.push(subscription.customer);

    sessionStorage.setItem('customersCart', JSON.stringify(this.customers));
    sessionStorage.setItem('currentCart', JSON.stringify(this.cartItems));
  }

  removeItems (cartItems: CartItemDTO[]) {
    cartItems.forEach(i => {
      this.cartItems = this.cartItems.filter(item => item.id !== i.id);

      if (this.cartItems.findIndex(item => item.subscription.idCustomer === i.subscription.idCustomer) === -1)
        this.customers = this.customers.filter(c => c.id !== i.subscription.idCustomer);
    });

    sessionStorage.setItem('customersCart', JSON.stringify(this.customers));
    sessionStorage.setItem('currentCart', JSON.stringify(this.cartItems));
  }

  async updateList () {
    if (this.cartItems == null || this.cartItems.length === 0)
      return;

    for (let r = 0; r < this.cartItems.length; r++) {
      let result = await firstValueFrom(this.subscription.getById(this.cartItems[r].subscriptionId));
      this.cartItems[r].priceEuro = result.subscriptionFee.euro;
      this.cartItems[r].priceUsd = result.subscriptionFee.dollar;
      this.cartItems[r].priceGbp = result.subscriptionFee.pound;
      this.cartItems[r].customer = result.customer;
      this.cartItems[r].subscription = result;
    }

    // sessionStorage.removeItem("currentCart");
    // sessionStorage.setItem('currentCart', JSON.stringify(this.cartItems_updated));
  }

  getList (currency: string, customer: CustomerDTO, reference: boolean = true) {
    return this.cartItems
      .filter(x => x.customer.id == customer.id)
      .map(item => {

        let price = 0;
        let cur = "eur";

        if (currency == "dollar" || currency == "usd") {
          price = item.priceUsd;
          cur = "usd";
        } else if (currency == "pound" || currency == "gbp") {
          price = item.priceGbp;
          cur = "gbp";
        } else {
          price = item.priceEuro;
          cur = "eur";
        }

        if (reference) {

          item.unitAmount = price;
          item.currency = cur;

          return item;

        }

        let newItem: CartItemDTO = {
          id: item.id,
          subscriptionId: item.subscriptionId,
          name: item.name,
          description: item.description,
          image: item.image,
          priceEuro: item.priceEuro,
          priceUsd: item.priceUsd,
          priceGbp: item.priceGbp,
          unitAmount: price,
          currency: cur,
          dollar: item.dollar,
          euro: item.euro,
          pound: item.pound,
          jsonData: item.jsonData,
          customer: item.customer,
          subscription: item.subscription,
          nestedSubscriptions: item.nestedSubscriptions
        };

        return newItem;

      });
  }

  private subscriptionIsPresentInCart(subscriptionId: number) {
    return this.cartItems.findIndex(i => i.subscriptionId == subscriptionId) !== -1 ||
           this.getCartNestedSubscriptions().findIndex(s => s == subscriptionId) !== -1;
  }

  private getCartNestedSubscriptions(): number[] {
    let nestedSubscriptionsInCart = [];
    for (let arr of this.cartItems.map(c => c.nestedSubscriptions)) {
      nestedSubscriptionsInCart = nestedSubscriptionsInCart.concat(arr);
    }

    return nestedSubscriptionsInCart;
  }
}

import { EduPlanClassDTO } from "./eduPlanClassDTO";
import { EduPlanContentDTO } from "./eduPlanContentDTO";
import { EduPlanStatusDTO } from "./eduPlanStatusDTO";
import { UserDTO } from "./userDTO";

export class EduPlanDTO {
    public id: number;
    public idCustomer: number;
    public name: string;
    public description: string;
    public idClassroom: number;
    public state: number;
    public idClassification: number;
    public idAuthor: number;
    public year: number;
    public created: Date;
    public author: UserDTO;
    public classification: EduPlanClassDTO;
    public educationalPlanContent: EduPlanContentDTO[];
    public educationalPlanStatus: EduPlanStatusDTO[];
}

import { UserDTO } from "./userDTO";

export class CheckListDTO {
    public id: number;
    public idCustomer: number;
    public name: string;
    public description: string;
    public created: Date;
}

export class CheckListQuestionDTO {
    public id: number;
    public idCheckList: number;
    public question: string;
    public yesNo: boolean;
    public created: Date;
}

export class CheckListAnswerDTO {
    public id: number;
    public idAirTime: number;
    public idStudent: number;
    public idQuestion: number;
    public response: string;
    public yesNo: boolean;
    public fixed?: Date;
    public created: Date;
    public checkListQuestion: CheckListQuestionDTO;
    public user: UserDTO;
}

import { Component, Input, OnInit } from '@angular/core';
import * as linkify from 'linkifyjs';
import { LinkPreviewService } from '../service/linkpreview.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { Helper } from 'src/app/helpers/helper';
import { MetaDataDTO } from 'src/app/models/dto/metaDataDTO';

@Component({
  selector: 'link-preview',
  exportAs: 'LinkPreview',
  templateUrl: './linkpreview.component.html',
  styleUrls: ['./linkpreview.component.scss']
})
export class LinkPreviewComponent implements OnInit {

  private _fromString: string;

  @Input()
  get fromString (): string { return this._fromString; }
  set fromString (value: string) {
    if (Helper.isNullOrEmpty(value))
      return;

    this._fromString = value;
    this.updatePreviews();
  }

  previews: MetaDataDTO[] = [];

  updatePreviews() {
    this.previews = [];

    let links: string[] = [];

    if (Helper.isNullOrEmpty(this.fromString))
      return;

    let foundLinks = linkify
      .find(this.fromString)
      .filter(link => link.type === "url");

    foundLinks.map(link => links.push(link.href.replace("http://", "https://").replace("</a>", "")));

    if (links.length > 0)
      links = this.uniq(links);

    links.map(async link => {
      let res = await this.linkPreviewService.resolvePreview(link);

      if (res && !this.previews.find(item => item.url === res.url))
        this.previews.push(res);
    });
  }

  constructor(private linkPreviewService: LinkPreviewService,
              private darkService: DarkThemeService) { }

  ngOnInit(): void { }

  private uniq(a: string[]) {
    return Array.from(new Set(a)) as string[];
  }

  public getSanitizedImageUrl(p: MetaDataDTO): string {
    if (p.image.startsWith('http') || p.image.startsWith('www'))
      return p.image;
    
    if (p.image.startsWith('//www'))
      return 'https://' + p.image.slice(2);
    
    if (p.image.startsWith('/yts/'))
      return 'https://' + this.extractHostname(p.url) + p.image;

    return null;
  }

  extractHostname(url: string) {
    let hostname: string = url.indexOf("//") > -1
                         ? url.split('/')[2]
                         : url.split('/')[0];

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  isDark() {
    return this.darkService.isSetDark;
  }

}

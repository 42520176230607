<div fxLayoutAlign="center center">
    <mat-card appearance="outlined" class="mat-elevation-z8" style="margin-bottom: 50px; width: 80%;" *ngIf="!compact; else compactCard">
        <button mat-mini-fab color="primary" class="buttonFloating" (click)="goToProfile()" [matTooltip]="getTooltip() | translate">
            <mat-icon>account_circle</mat-icon>
        </button>
        <div fxLayout="row" fxLayoutAlign="start start">
            <div fxLayoutAlign="center center" *ngIf="profile.profilePictureUrl">
                <img [src]="profile.profilePictureUrl" (click)="goToProfile()" class="userProfilePicture" loading="lazy">
            </div>
            <mat-card-content  fxLayout="column" fxLayoutAlign="start start">
                <mat-card-title>{{ profile.name + ' ' + profile.surname }}</mat-card-title>
                <div class="textBlock" [innerHTML]="profile.userSummary">
                    {{ (profile.userSummary != null && profile.userSummary.length > 0) ? profile.userSummary : ('Summary not present' | translate) }}
                </div>
            </mat-card-content>
        </div>
    </mat-card>
</div>

<ng-template #compactCard>
    <mat-card appearance="outlined" class="mat-elevation-z4" (click)="goToProfile()" [matTooltip]="getTooltip() | translate" style="padding: 5px; text-align-last: center; cursor: pointer; width: 100%;">
        <div fxLayoutAlign="center center" *ngIf="profile.profilePictureUrl; else noProfilePicture" style="margin-bottom: 5px;" loading="lazy">
            <img [src]="profile.profilePictureUrl" class="userProfilePictureSmall">
        </div>
        <mat-card-content>
            {{ (profile.name + ' ' + profile.surname).length > 25 ? (profile.name + ' ' + profile.surname | slice:0:22) + '...' : profile.name + ' ' + profile.surname }}
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #noProfilePicture>
    <mat-icon [inline]="true" style="font-size: 50px;">
        account_circle
    </mat-icon>
</ng-template>

import { CourseDTO } from './dto/courseDTO';
import { CourseContentDTO } from './dto/courseContentDTO';
import { CourseBlogDTO } from './dto/courseBlogDTO';

export class SearchInfo {
    public WordToSearch: string;
    public Language: string;
    public Mode: string; //null, All
}

export class SearchResult {
    public courses: CourseDTO[];
    public contents: CourseContentDTO[];
    public blogs: CourseBlogDTO[];
}

<div class="component-main center-item">
    <h2>{{ 'Registry' | translate }}</h2>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">

        <mat-form-field color="primary">
            <input matInput [matDatepicker]="pickerStart" [formControl]="startDate" (dateChange)="fetchEvents()" [placeholder]="'Start Date' | translate">
            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>

        <mat-form-field color="primary">
            <input matInput [matDatepicker]="pickerEnd" [formControl]="endDate" (dateChange)="fetchEvents()" [placeholder]="'End Date' | translate">
            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>

        <mat-form-field color="primary" *ngIf="!exportMode; else eventFilter">
            <mat-label>{{ 'Virtual Classroom' | translate }}</mat-label>
            <mat-select name="name" [formControl]="classroom" (selectionChange)="classroomChange()">
                <mat-option [value]="0">{{ 'All Classroom' | translate }}</mat-option>
                <mat-option *ngFor="let item of classrooms" [value]="item?.id">
                    {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <ng-template #eventFilter>
            <mat-form-field color="primary">
                <mat-label>{{ 'Events' | translate }}</mat-label>
                <mat-select name="name" [formControl]="classroom" (selectionChange)="classroomChange()">
                    <mat-option>
                        <ngx-mat-select-search
                            [formControl]="event"
                            [placeholderLabel]="'Search' | translate" 
                            [noEntriesFoundLabel]="'No data matching the word' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="0">{{ 'All events' | translate }}</mat-option>
                    <mat-option *ngFor="let item of events | search: event.value: ['eventId']" [value]="item.classId">
                        {{item.eventId}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>

        <mat-form-field color="primary">
            <mat-label>{{ 'Teacher' | translate }}</mat-label>
            <mat-select name="name" [formControl]="teacher" (selectionChange)="teacherChange()">
                <mat-option [value]="0">{{ 'All Teacher' | translate }}</mat-option>
                <mat-option *ngFor="let item of teachers" [value]="item?.id">
                    {{item?.surname}} {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field color="primary">
            <mat-label>{{ 'Teacher' | translate }}</mat-label>
            <mat-select name="name" [formControl]="teacher" (selectionChange)="teacherChange()">
                <mat-option [value]="0">{{ 'All Teacher' | translate }}</mat-option>
                <mat-option *ngFor="let item of teachers" [value]="item?.id">
                    {{item?.surname}} {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field color="primary">
            <mat-label>{{ 'Room' | translate }}</mat-label>
            <mat-select name="name" [formControl]="room" (selectionChange)="fetchEvents()">
                <mat-option [value]="0">{{ 'All Rooms' | translate }}</mat-option>
                <mat-option *ngFor="let item of rooms" [value]="item?.id">
                    {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-fab color="primary" (click)="lessonExportAllToCsv()" *ngIf="lessons.length !== 0" [matTooltip]="'Save csv of all lessons' | translate" matTooltipPosition="after">
            <mat-icon>save</mat-icon>
        </button>

    </div>
    <div class="center-item">
        <mat-accordion class="my-headers-align">
            <mat-expansion-panel *ngFor="let lesson of lessons" (opened)="lessonOpen(lesson)" style="margin: 10px;">
                <mat-expansion-panel-header>
                    <mat-panel-title style="width: 25%; text-align: start;">
                        <div fxLayout="row" fxLayoutAlign="start stretch" style="width: 100%;">
                            <div style="font-weight: bold; font-size: 20px; margin: 5px; width: 50%;" class="title strong" fxFlexAlign="center">
                                {{(lesson.name.length>15)? (lesson.name | slice:0:15)+'..':(lesson.name)}}
                            </div>
                            <div class="textFont" style="margin-top: 5px; width: 50%;" fxFlexAlign="center">
                                {{lesson.teacher.name}} {{lesson.teacher.surname}}
                            </div>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description style="text-align: start;">
                        <div *ngIf="lesson.stopDate && lesson.startDate" class="textFont" style="margin-top: 5px; width: 33%;" fxFlexAlign="center">
                            {{lesson.startDate | date: 'dd MMMM yyyy'}}
                            {{lesson.startDate | date: 'HH:mm'}} -
                            {{lesson.stopDate | date: 'HH:mm'}}
                        </div>
                        <div *ngIf="!lesson.stopDate && lesson.startDate" class="textFont" style="margin-top: 5px; width: 33%;" fxFlexAlign="center">
                            {{lesson.startDate | date: 'dd MMMM yyyy'}}
                            {{lesson.startDate | date: 'HH:mm'}} - ??:??
                        </div>
                        <div *ngIf="!lesson.stopDate && !lesson.startDate" class="textFont" style="margin-top: 5px; width: 33%;" fxFlexAlign="center">
                            {{lesson.startPlanned | date: 'dd MMMM yyyy'}}
                            {{lesson.startPlanned | date: 'HH:mm'}} - ??:??
                        </div>
                        <div class="textFont" style="width: 25%; margin-top: 5px;" fxFlexAlign="center">
                            {{lesson.classroom.name}}
                        </div>
                        <div *ngIf="lesson.room != null" class="textFont" style="width: 25%; margin-top: 5px;" fxFlexAlign="center">
                            {{lesson.room.name}}
                        </div>
                        <div *ngIf="lesson.room == null" class="textFont" style="width: 25%; margin-top: 5px; color: #f44336" fxFlexAlign="center">
                            Remote
                        </div>
                        <div>
                            <button mat-icon-button (click)="lessonExportToCsv($event, lesson)" [matTooltip]="'Save csv of this lesson' | translate" matTooltipPosition="before">
                                <mat-icon>save</mat-icon> 
                            </button>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <table mat-table [dataSource]="dataSource" *ngIf="dataSource && dataSource.length > 0" style="width: 100%; margin-left: auto; margin-right: auto;">
        
                        <ng-container matColumnDef="surname">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Surname' | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{ element.user.surname }} </td>
                        </ng-container>
        
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{ element.user.name }} </td>
                        </ng-container>
        
                        <ng-container matColumnDef="startDate">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Start' | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{ element.startDate | date: 'HH:mm'}} </td>
                        </ng-container>
        
                        <ng-container matColumnDef="endDate">
                            <th mat-header-cell *matHeaderCellDef>{{ 'End' | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{element.endDate | date: 'HH:mm'}} </td>
                        </ng-container>
        
                        <ng-container matColumnDef="timeInLessons">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Presence Time' | translate }} hh:mm:ss</th>
                            <td mat-cell *matCellDef="let element"> {{ element.timeInLessons | slice:0:8 }} 
                                <mat-progress-bar mode="determinate" [value]="getPercentage(lesson.startDate, lesson.stopDate, element.startDate, element.endDate)"></mat-progress-bar>
                            </td>
                        </ng-container>
        
                        <ng-container matColumnDef="room">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Room' | translate }}</th>
                            <td mat-cell *matCellDef="let element"> {{element.station?.room.name || 'Remote Connection' | translate }} </td>
                        </ng-container>
        
                        <ng-container matColumnDef="station">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Station' | translate }}</th>
                            <td mat-cell *matCellDef="let element">{{element.station?.name || 'Remote Connection' | translate}} </td>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                    </table>
                    <div *ngIf="dataSource && dataSource.length == 0">
                        {{!lesson.description ? ('No description' | translate) : lesson.description}}
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div *ngIf="lessons.length === 0">
        <app-error-message customClass="warning" fxFlex fxLayoutAlign="center center"
            [errorTitle]="'There arent any lessons' | translate"
            [errorContent]="'You can try to edit the filters to have some results' | translate">
        </app-error-message>
    </div>
</div>

"use strict";

/*
 * (C) Copyright 2017-2022 OpenVidu (https://openvidu.io)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subscriber = void 0;
var StreamManager_1 = require("./StreamManager");
var OpenViduLogger_1 = require("../OpenViduInternal/Logger/OpenViduLogger");
/**
 * @hidden
 */
var logger = OpenViduLogger_1.OpenViduLogger.getInstance();
/**
 * Packs remote media streams. Participants automatically receive them when others publish their streams. Initialized with {@link Session.subscribe} method
 *
 * See available event listeners at {@link StreamManagerEventMap}.
 */
var Subscriber = /** @class */function (_super) {
  __extends(Subscriber, _super);
  /**
   * @hidden
   */
  function Subscriber(stream, targEl, properties) {
    var _this = _super.call(this, stream, targEl) || this;
    _this.element = _this.targetElement;
    _this.stream = stream;
    _this.properties = properties;
    return _this;
  }
  /**
   * Subscribe or unsubscribe from the audio stream (if available). Calling this method twice in a row passing same value will have no effect
   * @param value `true` to subscribe to the audio stream, `false` to unsubscribe from it
   */
  Subscriber.prototype.subscribeToAudio = function (value) {
    this.stream.getMediaStream().getAudioTracks().forEach(function (track) {
      track.enabled = value;
    });
    this.stream.audioActive = value;
    logger.info("'Subscriber' has " + (value ? 'subscribed to' : 'unsubscribed from') + ' its audio stream');
    return this;
  };
  /**
   * Subscribe or unsubscribe from the video stream (if available). Calling this method twice in a row passing same value will have no effect
   * @param value `true` to subscribe to the video stream, `false` to unsubscribe from it
   */
  Subscriber.prototype.subscribeToVideo = function (value) {
    this.stream.getMediaStream().getVideoTracks().forEach(function (track) {
      track.enabled = value;
    });
    this.stream.videoActive = value;
    logger.info("'Subscriber' has " + (value ? 'subscribed to' : 'unsubscribed from') + ' its video stream');
    return this;
  };
  /* Hidden methods */
  /**
   * @hidden
   */
  Subscriber.prototype.replaceTrackInMediaStream = function (track, updateLastConstraints) {
    var mediaStream = this.stream.getMediaStream();
    var removedTrack;
    if (track.kind === 'video') {
      removedTrack = mediaStream.getVideoTracks()[0];
      if (updateLastConstraints) {
        this.stream.lastVideoTrackConstraints = track.getConstraints();
      }
    } else {
      removedTrack = mediaStream.getAudioTracks()[0];
    }
    mediaStream.removeTrack(removedTrack);
    removedTrack.stop();
    mediaStream.addTrack(track);
  };
  return Subscriber;
}(StreamManager_1.StreamManager);
exports.Subscriber = Subscriber;

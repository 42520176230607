import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../service/payment.service';

@Component({
  selector: 'app-returnstripe',
  templateUrl: './returnstripe.component.html',
  styleUrls: ['./returnstripe.component.scss']
})
export class ReturnstripeComponent implements OnInit {

  constructor(private paymentservice: PaymentService) { }
  acctid : string;

  ngOnInit(): void {
  }

  getacctinput(e){
    this.acctid = e.target.value;
  }

  getacct(){
     this.paymentservice.getStripeacct(this.acctid).subscribe(result => {
      
      console.log(result);
     
    });
  }
}

<app-mobile-warning 
*ngIf="isMobile; else desktopLogin"
[idCustomer]="idCustomer"
[codeCustomer]="customerCode"
[logo]="logo">
</app-mobile-warning>

<ng-template #desktopLogin>
  <div class="sfondo" [ngStyle]="{'background-image': 'url(' + background + ')'}">

    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      fxFlexOffset="40"
      fxFlex>
  
      <h1 style="width: 400px; text-align: center; color: white;">{{ name }}</h1>
  
      <mat-card
        appearance="outlined"
        style="width: 400px"
        class="mat-elevation-z8">
  
        <mat-card-content fxLayout="column" fxLayoutGap="20px">
  
          <app-access-form 
            *ngIf="isLoginMode()"
            [idCustomer]="idCustomer">
          </app-access-form>
          <app-register-form 
            *ngIf="isSignUpMode()" 
            (result)="changeMode('login')"  
            [codeCustomer]="customerCode">
        </app-register-form>

          <mat-divider></mat-divider>

          <div #signupBtns *ngIf="isSignUpMode()" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

            <button mat-stroked-button (click)="changeMode('login')">
              {{ 'Log In' | translate }}
            </button>

          </div>

          <div #loginBtns *ngIf="isLoginMode()" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

            <button mat-stroked-button (click)="changeMode('signup')">
              {{ 'Sign Up'| translate }}
            </button>

          </div>

        </mat-card-content>
  
      </mat-card>
  
      <h4 style="width: 400px; text-align: center; font-weight: normal; color: white;">
        {{ motto }}
      </h4>
  
    </div>
  </div>
</ng-template>

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsoleLogger = void 0;
var ConsoleLogger = /** @class */function () {
  function ConsoleLogger(console) {
    this.logger = console;
    this.log = console.log, this.info = console.info, this.debug = console.debug, this.warn = console.warn, this.error = console.error;
  }
  return ConsoleLogger;
}();
exports.ConsoleLogger = ConsoleLogger;

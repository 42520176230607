<div fxLayout="column" fxLayoutGap="20px" class="component-main">

    <h2 style="align-self: center">{{ 'Dashboard of name' | translate: { name: title } }}</h2>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="align-self: center">
        <button mat-stroked-button (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
        <mat-form-field color="primary" *ngIf="type !== 'pdf' && (showGraph === 'totalviews' || showGraph === 'users')">
            <mat-label>{{ 'Number of intervals' | translate }}</mat-label>
            <mat-select [formControl]="intervalNumber" (selectionChange)="setTableData()">
                <mat-option *ngFor="let interval of intervalNumbers" [value]="interval">{{ interval }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field color="primary" *ngIf="showGraph === 'totalminutes'">
            <mat-label>{{ 'Year' | translate }}</mat-label>
            <mat-select [formControl]="year" (selectionChange)="updateMinutes()">
                <mat-option [value]="0">{{ 'All years' | translate }}</mat-option>
                <mat-option *ngFor="let year of years" [value]="year">
                    {{ year }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="showGraph == undefined" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">

        <app-graph-preview
            [title]="'Total views' | translate"
            [datasets]="totalViewsData"
            [labels]="totalViewsLabels"
            [options]="previewViewsChartOptions"
            [type]="barChartType"
            [matTooltip]="'View' | translate"
            (onClick)="showGraph = 'totalviews'"
            width="400px"
            height="200px">
        </app-graph-preview>

        <app-graph-preview
            [title]="'Users views' | translate"
            [datasets]="userViewsData"
            [labels]="userViewsLabels"
            [options]="previewViewsChartOptions"
            [type]="barChartType"
            [matTooltip]="'View' | translate"
            (onClick)="showGraph = 'users'"
            width="400px"
            height="200px">
        </app-graph-preview>

        <app-graph-preview
            *ngIf="type !== 'pdf'"
            [title]="'Yearly Statistics' | translate"
            [datasets]="totalMinutesData"
            [labels]="totalMinutesLabels"
            [options]="previewMinutesChartOptions"
            [type]="barChartType"
            [matTooltip]="'View' | translate"
            (onClick)="showGraph = 'totalminutes'"
            width="400px"
            height="200px">
        </app-graph-preview>

    </div>

    <ng-container
        *ngIf="showGraph === 'totalviews'"
        [ngTemplateOutlet]="totalViews">
    </ng-container>

    <ng-container
        *ngIf="showGraph === 'totalminutes'"
        [ngTemplateOutlet]="totalMinutes">
    </ng-container>

    <ng-container
        *ngIf="showGraph === 'users'"
        [ngTemplateOutlet]="usersTable">
    </ng-container>

</div>

<ng-template #totalViews>
    <div fxLayout="column" fxLayoutAlign="center center">
        <div class="graph">
            <canvas baseChart
              [datasets]="totalViewsData"
              [labels]="totalViewsLabels"
              [options]="viewsChartOptions"
              [plugins]="barChartPlugins"
              [type]="barChartType">
            </canvas>
        </div>
    </div>
</ng-template>

<ng-template #totalMinutes>
    <div fxLayout="column" fxLayoutAlign="center center">

        <div class="graph">
            <canvas baseChart
              [datasets]="totalMinutesData"
              [labels]="totalMinutesLabels"
              [options]="minutesChartOptions"
              [plugins]="barChartPlugins"
              [type]="barChartType">
            </canvas>
        </div>

    </div>
</ng-template>

<ng-template #usersTable>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-card appearance="outlined" class="mat-elevation-z4" style="margin-top: 10px; width: 100%;">
            <mat-card-content>
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field style="width: 100%">
                        <mat-label>{{ 'Search' | translate }}</mat-label>
                        <input matInput type="text" (keyup)="doFilter($event)">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <table mat-table matSort [dataSource]="dataSrc">
    
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Student' | role | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <app-profile-picture [url]="row.user.profilePictureUrl"></app-profile-picture>
                                <span>{{ row.user.name + ' ' + row.user.surname }}</span>
                            </div>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="maxViews">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Max views' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ (row.mostViewedSample?.count ?? 0) | number: '1.0-2' }}</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="avgViews">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Average views' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ row.avgViews | number: '1.0-2' }}</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="mostViewedSample">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Most viewed' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>
                                {{
                                    row.mostViewedSample ?
                                    (type === 'pdf' ? row.mostViewedSample.start : (secondsToHMS(row.mostViewedSample.start) + ' - ' + secondsToHMS(row.mostViewedSample.end))) :
                                    ''
                                }}
                            </span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="firstView">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'First view' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ row.firstView | date: 'HH:mm - dd/MM/yyyy' }}</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="lastView">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Last view' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ row.lastView | date: 'HH:mm - dd/MM/yyyy' }}</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>{{ 'Data' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button
                                (click)="viewUserGraph(row.user, row.views)"
                                [disabled]="!row.firstView"
                                [matTooltip]="'View' | translate">
                                <mat-icon>analytics</mat-icon>
                            </button>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
    
                <mat-paginator #paginator
                    [length]="dataSrc?.data.length"
                    [pageSize]="10"
                    [pageSizeOptions]="[10, 25, 50]"
                    color="primary">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</ng-template>

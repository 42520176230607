import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { VirtualRoomDTO } from "../models/dto/virtualRoomDTO";
import { Helper } from "../helpers/helper";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RoomService {

    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlRooms = `${this.bckEndUrlBaseUrl}/rooms`;

    constructor(private http: HttpClient) { }

    public getVirtualRooms(): Observable<VirtualRoomDTO[]> {
        return this.http.get<VirtualRoomDTO[]>(`${this.bckEndUrlRooms}/virtual`, { headers: Helper.getAuthHeader() });
    }

    public createVirtualRoom(newVirtualRoom: VirtualRoomDTO) {
        return this.http.post(`${this.bckEndUrlRooms}/virtual`, newVirtualRoom, { headers: Helper.getAuthHeader() });
    }

    public deleteVirtualRoom(id: number) {
        return this.http.delete(`${this.bckEndUrlRooms}/virtual/${id}`, { headers: Helper.getAuthHeader() });
    }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { EduPlanClassDTO } from 'src/app/models/dto/eduPlanClassDTO';
import { EduPlanContentDTO } from 'src/app/models/dto/eduPlanContentDTO';
import { EduPlanDTO } from 'src/app/models/dto/eduPlanDTO';
import { User } from 'src/app/models/user';
import { AddEduplanComponent } from 'src/app/popup/add-eduplan/add-eduplan.component';
import { EditEduplancontentComponent } from 'src/app/popup/edit-eduplancontent/edit-eduplancontent.component';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { EduPlanService } from 'src/app/services/eduplan.service';
import { SelectionMode, SelectionService } from 'src/app/services/selection.service';

@Component({
  selector: 'app-eduplan-list',
  templateUrl: './eduplan-list.component.html',
  styleUrls: ['./eduplan-list.component.scss']
})
export class EduplanListComponent implements OnInit {
  currentUser: User = null;

  searchWord: string = null;
  pageSize: number = 10;
  pageIndex: number = 0;
  
  classification: number = 0;
  classifications: EduPlanClassDTO[] = [];

  backupPlans: EduPlanDTO[] = [];
  eduPlans: EduPlanDTO[] = [];
  planUsers: User[] = [];
  eduPlanContents: EduPlanContentDTO[] = [];

  sending: boolean = false;

  constructor(private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private auth: AuthService,
              private darkService: DarkThemeService,
              private eduPlanService: EduPlanService,
              private selectionService: SelectionService,
              private router: Router,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.eduPlanService.getEduPlansClassifications()
      .subscribe(res => this.classifications = res);

    this.getPlans();
  }

  getPlans() {
    this.eduPlanService.getEduPlansOfAuthor()
      .subscribe(res => {
        this.backupPlans = res;
        this.resetData();
      });
  }

  getPlanContents(planId: number) {
    this.eduPlanContents = [];

    this.eduPlanService.getEduPlanContents(planId)
      .subscribe(res => this.eduPlanContents = res.sort((a, b) => (a.sequenceIndex > b.sequenceIndex) ? 1 : ((b.sequenceIndex > a.sequenceIndex) ? -1 : 0)));
  }

  async togglePlan(plan: EduPlanDTO, $event: any) {
    let newState = plan.state === 1 ? 0 : 1;

    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Change educational plan status')),
        body: await firstValueFrom(this.translate.get('Are you sure to change educational plan {{name}} status to {{state}}?',
                                       { name: plan.name, state: newState ? 'online' : 'offline' }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          $event.source.checked = !newState;
          return;
        }

        this.sending = true;

        this.eduPlanService.changeEduPlanState(plan.id, newState)
          .subscribe({
            next: () => {
              this.snackBar.open('Educational plan status updated', 'Dismiss', { duration: 3000 } );
      
              let index = this.eduPlans.findIndex(p => p.id === plan.id);
              this.eduPlans[index].state = newState;
      
              this.sending = false;
            },
            error: err => {
              this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 } );
              this.sending = false;
            }
          });

      });
  }

  //Per mat-paginator
  changePage(event?: PageEvent) {
    let minIndex = 0; 
    let maxIndex = this.pageSize;
    
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;

      minIndex = event.pageIndex * event.pageSize;
      maxIndex = (event.pageIndex + 1) * event.pageSize;
    }
    
    this.eduPlans = this.getFilteredData(this.searchWord).filter((l, i) => i >= minIndex && i < maxIndex);
  }

  resetData(resetSearch: boolean = true) {
    if (resetSearch)
        this.searchWord = null;

    this.pageIndex = 0;
    this.pageSize = 10;

    this.changePage(null);
  }

  getFilteredData(text: string) {
    let plans = this.backupPlans;

    //if (this.selectedCustomer !== -1)
    //  plans = plans.filter(lesson => lesson.idCustomer === this.selectedCustomer);
    if (this.classification !== 0)
      plans = plans.filter(plan => plan.idClassification === this.classification);

    if (Helper.isNullOrEmpty(text))
      return plans;

    return plans.filter(plan => plan.name?.toLowerCase().includes(text) || plan.description?.toLowerCase().includes(text));
  }

  isDark() {
    return this.darkService.isSetDark;
  }

  startSelection(plan: EduPlanDTO) {
    this.selectionService.startSelection(plan.id, SelectionMode.eduPlan);
    this.selectionService.data = plan.idCustomer;
  }

  getContent(content: EduPlanContentDTO, property: string) {
    if (content.idCourse != null)
      return content.course[property];
    if (content.idMaster != null)
      return content.master[property];
  }

  getTruncateString(text: string, maxLength: number) {
    return Helper.getTruncateShowcase(text, 'No title', maxLength);
  }

  goToContent(content: EduPlanContentDTO) {
    if (content.idCourse != null)
      this.router.navigate(['/course', content.idCourse]);
    if (content.idMaster != null)
      this.router.navigate(['/master', content.idMaster]);
  }

  editContent(content: EduPlanContentDTO, plan: EduPlanDTO) {
    const dialogRef = this.dialog.open(EditEduplancontentComponent, {
      data: { content: content, year: plan.year }, width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result)
          this.getPlans();
    });
  }

  addUserToEduPlan(plan: EduPlanDTO) {
    this.router.navigate(['/classroom', plan.idClassroom], {
      queryParams: {
        title: plan.name,
        idEduPlan: plan.id
      }
    });
  }

  editEduPlan(plan: EduPlanDTO) {
    const dialogRef = this.dialog.open(AddEduplanComponent, {
      data: { eduPlan: plan }, width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (res)
          this.getPlans();
    });
  }

  addEduPlan() {
    const dialogRef = this.dialog.open(AddEduplanComponent, { width: '500px' });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (res)
          this.getPlans();
    });
  }

}

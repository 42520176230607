import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CalendarService } from 'src/app/services/calendar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from 'src/app/helpers/helper';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-lesson-video',
  templateUrl: './lesson-video.component.html',
  styleUrls: ['./lesson-video.component.scss']
})
export class LessonVideoComponent implements OnInit, OnDestroy {

  videoTime: number = 0;
  videoPause: boolean = false;
  lessonId: number;
  title: string;
  videos: { index: number, orderedRecordings: string[] }[] = [];
  selectedVideo: number = 0;
  visualType: string = 'contain';

  constructor(private route: ActivatedRoute,
              private location: Location,
              private calendar: CalendarService,
              private navBar: NavBarService,
              private snackBar: MatSnackBar) {

    let vt = localStorage.getItem('visualTypeConfig');
    this.visualType = vt ?? this.visualType;
  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.has('lessonId'))
      this.lessonId = Number(this.route.snapshot.queryParamMap.get('lessonId'));

    if (this.route.snapshot.queryParamMap.has('title'))
      this.title = this.route.snapshot.queryParamMap.get('title');

    this.getVideoOfLesson();
    this.navBar.hide('all');
  }

  ngOnDestroy(): void {
    this.navBar.show('topbar');
    this.navBar.show('bottombar');
  }

  getVideoOfLesson() {
    this.calendar.getLessonVideos(this.lessonId)
    .subscribe({
      next: async output => {

        if (output.length > 0) {
          this.videos = output;
          
          this.selectedVideo = 0;
        } else {
          this.noVideosSnackBar("No video found for this lesson");
          this.goBack();
        }

      },
      error: () => { 
        this.noVideosSnackBar("No video found for this lesson");
        this.goBack();
      }
    });
  }
 
  changeVideo(direction: boolean) {

    if (direction) {
      if (this.selectedVideo + 1 < this.videos.length) {
        this.selectedVideo++;
        this.videoTime = 0;
        this.videoPause = false;
      }
    } else {
      if (this.selectedVideo - 1 >= 0) {
        this.selectedVideo--;
        this.videoTime = 0;
        this.videoPause = false;
      }
    }
  }

  goBack() {
    this.location.back();
  }

  noVideosSnackBar(msg: string) {
    this.snackBar.open(msg, 'Dismiss', {
        duration: 3000,
        verticalPosition: 'bottom'
    });
  }

  getSize(bytes: number, decimals: number) {
    return Helper.formatBytes(bytes, decimals);
  }

  setVisualType(type: string) {
    this.visualType = type;
    localStorage.setItem('visualTypeConfig', this.visualType);
  }

  setCurrentTime($event: number) {
    this.videoTime = $event;
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserDTO } from 'src/app/models/dto/userDTO';

@Component({
  selector: 'app-showcase-card-author-info',
  templateUrl: './showcase-card-author-info.component.html',
  styleUrls: ['./showcase-card-author-info.component.scss']
})
export class ShowcaseCardAuthorInfoComponent {

  @Input()
  mode: 'author' | 'webinar' = 'author';

  @Input()
  author: UserDTO = undefined;

  @Input()
  users: UserDTO[] = [];

  @Input()
  padding: string = '';

  constructor(private router: Router) { }

  goToProfile(): void {
    this.router.navigate(['/authorprofile', this.author.id]);
  }

  getUsers() {
    return this.users?.map(u => `${u.name} ${u.surname}`).join('\n');
  }

}

<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>{{ title }}</h2>
    </div>

    <div class="mat-elevation-z8">
        <div fxLayout="row" fxLayoutGap="20px" style="padding: 10px 20px 0px 20px">
            <div fxLayoutGap="20px" fxFlex>
                <mat-form-field color="primary">
                    <mat-label>{{ 'Search' | translate }}</mat-label>
                    <input matInput (keyup)="applyFilter($event)">
                    <button matSuffix mat-icon-button [matTooltip]="'Search' | translate">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>
        
        <mat-table #table [dataSource]="resultsDataSource" multiTemplateDataRows matSort>

            <ng-container matColumnDef="RegistrationCompletion">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Completion' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.RegistrationCompletion }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="RegistrationSuccess">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Result' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element;">{{ element.RegistrationSuccess }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Score.Scaled">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Score' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.Score?.Scaled ?? '-' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="TotalSecondsTracked">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Seconds' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.TotalSecondsTracked }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="LastAccessDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Last access' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.LastAccessDate | date }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="CompletedDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Completed' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.CompletedDate | date }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Learner">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.Learner?.FirstName + ' ' + element.Learner?.LastName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef>{{ 'Action' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button
                        (click)="toggleRow(element, 'objectives')"
                        [matTooltip]="'Objectives' | translate">
                        <mat-icon>grading</mat-icon>
                    </button>
                    <button mat-icon-button
                        (click)="toggleRow(element, 'interactions')"
                        [matTooltip]="'Interactions' | translate">
                        <mat-icon>integration_instructions</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let element" [attr.colspan]="resultsColumns.length">
                    <div class="element-detail" [@detailExpand]="selectedResult?.Id === element.Id ? 'expanded' : 'collapsed'">

                        <mat-table #table1 [dataSource]="objectivesDataSource" *ngIf="selectedType === 'objectives'" style="padding: 20px 0 20px 0">

                            <ng-container matColumnDef="Id">
                                <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.Id }}</mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="Score.Scaled">
                                <mat-header-cell *matHeaderCellDef>{{ 'Score' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.Score?.Scaled ?? '-' }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="ScoreMin">
                                <mat-header-cell *matHeaderCellDef>{{ 'Min' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.ScoreMin }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="ScoreMax">
                                <mat-header-cell *matHeaderCellDef>{{ 'Max' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.ScoreMax }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="ProgressMeasure">
                                <mat-header-cell *matHeaderCellDef>{{ 'Progress' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.ProgressMeasure }}</mat-cell>
                            </ng-container>
                
                            <mat-header-row *matHeaderRowDef="objectivesColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: objectivesColumns;"></mat-row>
                        </mat-table>

                        <mat-table #table2 [dataSource]="interactionsDataSource" *ngIf="selectedType === 'interactions'" style="padding: 20px 0 20px 0">

                            <ng-container matColumnDef="Id">
                                <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.Id }}</mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="Objectives">
                                <mat-header-cell *matHeaderCellDef>{{ 'Objectives' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.Objectives}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Result">
                                <mat-header-cell *matHeaderCellDef>{{ 'Result' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.Result }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Description">
                                <mat-header-cell *matHeaderCellDef>{{ 'Description' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.Description }}</mat-cell>
                            </ng-container>
                
                            <mat-header-row *matHeaderRowDef="interactionsColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: interactionsColumns;"></mat-row>
                        </mat-table>

                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="resultsColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: resultsColumns;" (click)="collapseRow(row)"></mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></mat-row>
        </mat-table>

        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" color="primary">
        </mat-paginator>
    </div>
</div>

<h1 mat-dialog-title>{{ 'Self test' | translate }}</h1>
<div mat-dialog-content>

    <mat-accordion multi>

        <mat-expansion-panel>

            <mat-expansion-panel-header>

                <mat-panel-title>
                    <mat-icon class="expansion-title-icon">travel_explore</mat-icon>
                    {{ 'Browser' | translate }}
                </mat-panel-title>

                <mat-panel-description class="expansion-description-container">
                    <mat-icon
                        [class]="browserOk === true ? 'ok-color' : browserOk === false ? 'error-color' : 'loading-color'"
                        [matTooltip]="(browserOk === true ? 'Ok' : browserOk === false ? 'Error' : 'Checking') | translate">
                        {{
                            browserOk === true ?
                            'check_circle' :
                            browserOk === false ?
                            'cancel' :
                            'pending'
                        }}
                    </mat-icon>
                </mat-panel-description>

            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <span>
                    {{
                        (
                            browserOk === true ?
                            'Your current browser is compatible with the platform value' :
                            browserOk === false ?
                            'Your current browser is not compatible with the platform value' :
                            'Checking your browser'
                        )
                        | translate: { value: browser }
                    }}
                </span>
            </ng-template>

        </mat-expansion-panel>

        <mat-expansion-panel>

            <mat-expansion-panel-header>

                <mat-panel-title>
                    <mat-icon class="expansion-title-icon">network_check</mat-icon>
                    {{ 'Network speed test' | translate }}
                </mat-panel-title>

                <mat-panel-description class="expansion-description-container">
                    <mat-icon
                        [class]="speedOk === 'good' || speedOk === 'great' ? 'ok-color' : speedOk === 'ok' ? 'pending-color' : speedOk === 'no' ? 'error-color' : 'loading-color'"
                        [matTooltip]="(speedOk === 'ok' || speedOk === 'good' || speedOk === 'great' ? 'Ok' : speedOk === 'no' ? 'Error' : 'Checking') | translate">
                        {{
                            speed == undefined ?
                            'warning' :
                            speedOk === 'ok' || speedOk === 'good' || speedOk === 'great' ?
                            'check_circle' :
                            speedOk === 'no' ?
                            'cancel' :
                            'pending'
                        }}
                    </mat-icon>
                </mat-panel-description>

            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <span>
                    {{
                        (
                            speed == undefined ?
                            'Network not available' :
                            speedOk === 'ok' ?
                            'Your current network speed is sufficient value' :
                            speedOk === 'good' ?
                            'Your current network speed is good value' :
                            speedOk === 'great' ?
                            'Your current network speed is great value' :
                            speedOk === 'no' ?
                            'Your current network speed is insufficient value' :
                            'Checking your network speed'
                        )
                        | translate: { value: (speed ?? 0 | number: '1.0-2') + 'Mbps' }
                    }}
                </span>
            </ng-template>

        </mat-expansion-panel>

        <mat-expansion-panel>

            <mat-expansion-panel-header>

                <mat-panel-title>
                    <mat-icon class="expansion-title-icon">mic</mat-icon>
                    {{ 'Microphone permission' | translate }}
                </mat-panel-title>

                <mat-panel-description class="expansion-description-container">
                    <mat-icon
                        [class]="microphonePermissionOk === true ? 'ok-color' : microphonePermissionOk === false ? 'error-color' : 'loading-color'"
                        [matTooltip]="(microphonePermissionOk === true ? 'Ok' : microphonePermissionOk === false ? 'Error' : 'Checking') | translate">
                        {{
                            microphonePermissionOk === true ?
                            'check_circle' :
                            microphonePermissionOk === false ?
                            'cancel' :
                            'pending'
                        }}
                    </mat-icon>
                </mat-panel-description>

            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <span>
                    {{
                        (
                            microphonePermissionOk === true ?
                            'Microphone access is granted' :
                            microphonePermissionOk === false ?
                            'Microphone access is denied' :
                            'Checking your microphone permission'
                        ) | translate
                    }}
                </span>
            </ng-template>

        </mat-expansion-panel>

        <mat-expansion-panel>

            <mat-expansion-panel-header>

                <mat-panel-title>
                    <mat-icon class="expansion-title-icon">videocam</mat-icon>
                    {{ 'Camera permission' | translate }}
                </mat-panel-title>

                <mat-panel-description class="expansion-description-container">
                    <mat-icon
                        [class]="cameraPermissionOk === true ? 'ok-color' : cameraPermissionOk === false ? 'error-color' : 'loading-color'"
                        [matTooltip]="(cameraPermissionOk === true ? 'Ok' : cameraPermissionOk === false ? 'Error' : 'Checking') | translate">
                        {{
                            cameraPermissionOk === true ?
                            'check_circle' :
                            cameraPermissionOk === false ?
                            'cancel' :
                            'pending'
                        }}
                    </mat-icon>
                </mat-panel-description>

            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <span>
                    {{
                        (
                            cameraPermissionOk === true ?
                            'Camera access is granted' :
                            cameraPermissionOk === false ?
                            'Camera access is denied' :
                            'Checking your camera permission'
                        ) | translate
                    }}
                </span>
            </ng-template>

        </mat-expansion-panel>

    </mat-accordion>

</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Close' | translate }}</button>
</div>

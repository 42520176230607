import { Component, OnInit } from '@angular/core';
import { AzureStorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-uploads-viewer',
  templateUrl: './uploads-viewer.component.html',
  styleUrls: ['./uploads-viewer.component.scss']
})
export class UploadsViewerComponent implements OnInit {

  open: boolean = true;

  constructor(private azureService: AzureStorageService) { }

  ngOnInit() { }

  openList() {
    this.open = true;
  }

  closeList() {
    this.open = false;
  }

  getUploads() {
    return this.azureService.uploads;
  }

  clearList() {
    this.azureService.clearCurrentUploads();
  }

  stopCurrentUpload(index: number) {
    this.azureService.clearCurrentUpload(index);
  }

  spinnerValue(value: number) {
    return value + "%";
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Device, OpenVidu } from 'openvidu-browser';
import { StandardLessonConfig } from 'src/app/models/joinLessonConfig';

const NO_AUDIO_DEVICE: Device = { kind: 'audioinput', deviceId: 'noaudio', label: 'No audio device' };

@Component({
  selector: 'app-bidirectional-publisher-popup',
  templateUrl: './bidirectional-publisher-popup.component.html',
  styleUrl: './bidirectional-publisher-popup.component.scss'
})
export class BidirectionalPublisherPopupComponent implements OnInit {

  joinWithVideo = true;
  joinWithAudio = true;

  OV: OpenVidu = undefined;
  videoDeviceArr: Device[] = [];
  audioDeviceArr: Device[] = [];

  videoForm: FormControl<string> = new FormControl(undefined, [Validators.required]);
  audioForm: FormControl<string> = new FormControl(undefined, [Validators.required]);

  //userRequesting: string = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<BidirectionalPublisherPopupComponent>,
    private snackBar: MatSnackBar
  ) { }

  async ngOnInit(): Promise<void> {

    //this.userRequesting = this.data.user;

    this.OV = new OpenVidu();

    try {
	
      let userMedia = await this.OV.getUserMedia({ audioSource: undefined, videoSource: undefined });

      userMedia.getTracks().forEach(t => t.stop());

      let devices = await this.OV.getDevices();

      this.videoDeviceArr = devices.filter(d => d.kind === 'videoinput');
      this.audioDeviceArr = devices.filter(d => d.kind === 'audioinput');

      this.videoForm.setValue(this.videoDeviceArr[0].deviceId);
      this.audioForm.setValue(this.audioDeviceArr[0].deviceId);

    } catch (e) {
      console.error(e);

      this.snackBar.open('please connect a device and allow camera and microphone access to continue', 'Dismiss', {
        duration: 5000,
        verticalPosition: 'top'
      });
    }

    this.audioDeviceArr.push(NO_AUDIO_DEVICE);
    this.OV = null;

  }

  confirm() {
    this.dialogRef.close(<StandardLessonConfig>{
      quality: undefined,
      videoEnabled: this.joinWithVideo,
      audioEnabled: this.joinWithAudio,
      audioDevice: this.audioForm.value,
      scenario: [this.videoForm.value]
    });
  }

  okBtnDisabled() {
    return !this.videoForm.valid
        || !this.audioForm.valid;
  }

  onNoClick(): void {
    this.OV = null;

    this.dialogRef.close();
  }
}

<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>{{ 'Warnings' | translate }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px;">
        <button mat-fab color="primary" (click)="getWarnings()" [matTooltip]="'Refresh' | translate">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-accordion *ngIf="warnings && warnings.length > 0; else noWarnings" style="width: 80%;" class="my-headers-align">
            <mat-expansion-panel *ngFor="let warning of warnings" style="margin: 10px">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ warning.name }}
                    </mat-panel-title>
                    <mat-panel-description>
                        &nbsp;
                        <mat-icon>{{ getWarningIcon(warning.idType) }}</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayoutAlign="start center">
                            {{ warning.description }}
                        </div>
                        <mat-action-row *ngIf="acknowledgeEnabled(warning)">
                            <button mat-icon-button color="primary" (click)="acknowledgeWarning(warning.id)">
                                <mat-icon>done</mat-icon>
                            </button>
                        </mat-action-row>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>

        <ng-template #noWarnings>
            <app-error-message customClass="warning"
                [errorTitle]="'There are no warnings' | translate"
                [errorContent]="'Currently there are none to show' | translate">
        </app-error-message>
        </ng-template>
    </div>
</div>

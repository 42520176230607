import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentCustomerType, EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';

@Component({
  selector: 'app-ideandum-login',
  templateUrl: './ideandum-login.component.html',
  styleUrl: './ideandum-login.component.scss'
})
export class IdeandumLoginComponent {

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  completeLogo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.Ideandum, EnvironmentParameterType.Logo);
  logo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.Ideandum, this.themeService.isSetDark ? EnvironmentParameterType.LogoLight : EnvironmentParameterType.LogoDark);
  backgroundImageUrl: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.Ideandum, EnvironmentParameterType.BackgroundImage)})`;

  idCustomer: number = 6;
  customerCode: string = 'ideandum';

  constructor(
    private themeService: DarkThemeService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

}

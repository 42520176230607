<div class="component-main">
  <div fxLayout="row" fxLayoutAlign="center center">
    <h2>{{ 'Administrator Users' | translate }}</h2>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px;">
    <button mat-fab
      (click)="createUser()"
      [matTooltip]="'Add customer admin' | translate"
      [disabled]="this.customers == null || this.customers.length == 0"
      color="accent">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div style="width: 80%;" class="center-item">
    <mat-accordion class="mat-table" is-open="true">
      <mat-expansion-panel *ngFor="let customer of customers" style="margin: 10px"
        (click)="setCustomer(customer)" [disabled]="!customer.admins || customer.admins.length === 0">
        <mat-expansion-panel-header class="mat-row">
          <mat-panel-title>
            <div style="text-align: start; align-self: center;" fxFlex>
              {{ customer.name }}
            </div>
            <div style="text-align: end;" fxFlex="20">
              <mat-icon *ngIf="currentCustomer?.id === customer.id"
                [matTooltip]="'Selected' | translate"
                matTooltipPosition="before">
                done
              </mat-icon>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <table mat-table *ngIf="customer.admins && customer.admins.length > 0" [dataSource]="customer.admins" matSort>
    
            <ng-container matColumnDef="thumbnail">
              <th mat-header-cell *matHeaderCellDef>{{'Profile picture' | translate}}</th>
              <td mat-cell *matCellDef="let element"> <img *ngIf="element.profilePictureUrl != null"
                  style="height: 50px; max-width: 50px;" mat-card-avatar [src]="element.profilePictureUrl" loading="lazy">
              </td>
            </ng-container>
    
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'username' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{element.username}}</td>
            </ng-container>
    
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
    
            <ng-container matColumnDef="surname">
              <th mat-header-cell *matHeaderCellDef>{{'Surname' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.surname}}</td>
            </ng-container>
    
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>{{'Email' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>
    
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="goToUser(element.id)" [matTooltip]="'edit' | translate">
                  <mat-icon>
                    edit
                  </mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="deleteUser(element.id)" [matTooltip]="'Delete' | translate">
                  <mat-icon>
                    delete
                  </mat-icon>
                </button>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<mat-drawer-container hasBackdrop="false" id="session-container">

    <mat-drawer #globalChat
        mode="over"
        position="end"
        style="width: 400px"
        (openedChange)="globalChatOpened = !globalChatOpened">

        <app-directional-chat
            [session]="session"
            [addUsersOnConnection]="false"
            [aggregateChats]="false"
            [forwardActive]="false"
            [replyActive]="false"
            [hideActive]="false"
            [globalUsersChat]="true"
            [globalUsersChatEnabledRoles]="['publisher', 'presenter', 'participant']"
            [read]="globalChatOpened"
            (unread)="newChatMessages = $event"
            (onClose)="globalChat.close()"
            title="Messages">
        </app-directional-chat>

    </mat-drawer>

    <div *ngIf="initialSelection; else streamLayout" fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
        <app-source-selection
            [OV]="OV"
            [videoDevices]="getDevices('video', 'all')"
            [audioDevices]="getDevices('audio', 'all')"
            [audioEnabled]="isPublisher()"
            [username]="getUsername()"
            [entityName]="'conference'"
            [lockForm]="lockJoin"
            [acceptedNetwork]="validStreamingQualities"
            (result)="$event == null ? leave() : joinConference($event)"> <!--[audioDeviceEnabled]="isPresenter()"-->
        </app-source-selection>
    </div>

</mat-drawer-container>

<ng-template #streamLayout>

    <mat-card *ngIf="isRecording || newHandNotifications > 0 || publishingInfo || isSharing()" class="recording-container">

        <button mat-flat-button *ngIf="isRecording" disableRipple="true" color="warn">
            <mat-icon>fiber_manual_record</mat-icon>
            {{ 'Recording' | translate }}...
        </button>

        <button mat-flat-button *ngIf="publishingInfo" disableRipple="true" color="accent">
            <mat-icon>record_voice_over</mat-icon>
            {{ publishingInfo }}
        </button>

        <button mat-flat-button *ngIf="newHandNotifications > 0" disableRipple="true" color="accent">
            <mat-icon>back_hand</mat-icon>
            {{ 'x raised hands' | translate: { value: newHandNotifications } }}
        </button>

        <button mat-flat-button *ngIf="isSharing()" (click)="stopShare()" color="warn">
            <mat-icon>stop</mat-icon>
            {{ 'Stop share' | translate }}
        </button>

    </mat-card>

    <div *ngIf="conferenceRemainingMinutes() <= 10" class="messageBanner" style="top: 40px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'The conference will be closed in x minutes' | translate: { value: conferenceRemainingMinutes() },
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="lostConnection() || streamingQualityValues.length > 0" class="messageBanner" style="bottom: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'No connection. Go to an area with a better connection' | translate,
                                            icon: 'warning'
                                         }">
        </ng-container>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="layout-container">

        <ng-container *ngIf="publisherShare.share" [ngTemplateOutlet]="shareLayout"></ng-container>

        <app-session-participants
            [hidden]="participants.length === 0"
            [session]="session"
            [participants]="participants"
            [optionMenuBtn]="participantsOptionsBtn"
            [read]="!isPublisher() && !isPresenter()"
            [lowerHandEnabled]="isPublisher() || isPresenter()"
            [selectionEnabled]="selectedRoomData"
            [(selectedParticipants)]="selectedUsers"
            [selectedParticipantsDisabled]="connectedUsers"
            [updateParticipants]="updateParticipants"
            [maxColumns]="publisherShare.share ? 1 : undefined"
            [aspectRatio]="publisherShare.share ? 16 / 9 : undefined"
            [highlightUserId]="!isPublishing() ? (publisherShare.tempPublisher?.userId ?? publisherShare.share?.userId) : undefined"
            [visualType]="visualType"
            (onParticipantsChange)="orderParticipants()"
            (onLowerHand)="toggleHand($event, false)"
            (unread)="newHandNotifications = $event"
            (onCurrentUserHandRaised)="isHandRaised = $event"
            (onPriorityPosition)="priorityPosition = $event"
            mode="conference"
            [style.width]="publisherShare.share ? '20%' : '100%'"
            style="height: 100%">
        </app-session-participants>

    </div>

    <app-session-toolbar
        [localParticipant]="localParticipant?.manager"
        [subjectVideoDevices]="getDevices('video', 'subject')"
        [subjectAudioDevices]="getDevices('audio', 'subject')"
        [shareVideoDevices]="getDevices('video', 'share')"
        [subjectVideoDeviceId]="localParticipantVideoDeviceId"
        [subjectAudioDeviceId]="localParticipantAudioDeviceId"
        [shareVideoDeviceId]="localParticipantShareVideoDeviceId"
        [isSubjectMirrored]="localParticipantMirror"
        [isVideoOverride]="isVideoOverride()"
        [hasScreenShareCapabilities]="hasScreenShareCapabilities"
        [shareActive]="isPublisher() || isPresenter() || isPublishing()"
        [shareEnabled]="(isPublisher() && !publisherShare.publisher && !publisherShare.tempPublisher) || isPublishing()"
        [isSharing]="isSharing()"
        [(fullScreen)]="isFullScreen"
        [handRaiseActive]="!isPublisher() && !isPresenter() && !isPublishing()"
        [(handRaise)]="isHandRaised"
        [recordingActive]="isPublisher() && conference?.lessonSession?.recodingPlanned"
        [isRecording]="isRecording"
        [recordingEnabled]="!togglingRecording"
        [httpLink]="httpLink"
        (onChangeSource)="changeSource($event.deviceId, $event.mirror, $event.type)"
        (handRaiseChange)="toggleHand(currentUser.id, $event)"
        (onRecordingChange)="toggleRecording($event)"
        (onStartShare)="startShare($event)"
        (onStopShare)="stopShare()"
        (onVisualTypeChange)="visualType = $event"
        (onLeave)="leave(true)"
        class="bottom-container mat-elevation-z24"> <!--[toggleAudioActive]="isPublisher() || isPresenter() || isPublishing()"-->

        <div fxFlex leftBtns>

            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button
                    *ngIf="!virtualRoomId"
                    [matTooltip]="('Conference' | translate) + ' ' + conference?.name + ' ' + ('has started x minutes ago' | translate: { value: conferenceElapsedMinutes() })">
                    <mat-icon>info</mat-icon>
                </button>

                <button mat-icon-button
                    *ngIf="virtualRoomId"
                    [matTooltip]="('Room' | translate) + ' ' + room?.name">
                    <mat-icon>info</mat-icon>
                </button>

                <button mat-icon-button [matTooltip]="'Participants' | translate">
                    <mat-icon
                        [matBadge]="totalClients"
                        [matBadgeHidden]="totalClients === 0">
                        people
                    </mat-icon>
                </button>

            </div>
            
        </div>

        <div fxLayoutGap="10px" fxFlex centerBtns>

            <button mat-mini-fab
                (click)="globalChat.toggle()"
                [color]="globalChatOpened ? 'accent' : 'primary'"
                [matTooltip]="'Chat' | translate"
                [matBadge]="newChatMessages"
                [matBadgeHidden]="newChatMessages === 0"
                matBadgeColor="accent">
                <mat-icon>
                    chat
                </mat-icon>
            </button>

            <button mat-mini-fab 
                *ngIf="isPublisher() || isPresenter()"
                (click)="toggleRoomsToolbar()"
                [disabled]="virtualRooms.length === 0"
                [matTooltip]="'Virtual rooms' | translate"
                [color]="toolbarRoomsOpened ? 'accent' : 'primary'">
                <mat-icon>groups</mat-icon>
            </button>

            <button mat-mini-fab
                *ngIf="isPublisher() || isPresenter()"
                (click)="toggleHand(null, false)"
                [color]="newHandNotifications > 0 ? 'accent' : 'primary'"
                [matTooltip]="'Lower all hands' | translate"
                [matBadge]="newHandNotifications"
                [matBadgeHidden]="newHandNotifications === 0"
                color="primary">
                <mat-icon>
                    do_not_touch
                </mat-icon>
            </button>

            <button mat-mini-fab
                *ngIf="virtualRoomId"
                (click)="leave(true, ['/conference', this.id])"
                [matTooltip]="'Leave room' | translate"
                color="warn">
                <mat-icon>output</mat-icon>
            </button>
        
        </div>

    </app-session-toolbar>

    <app-rooms-toolbar
        [hidden]="!toolbarRoomsOpened"
        [rooms]="virtualRooms"
        [conferenceId]="id"
        [currentRoomId]="virtualRoomId"
        [currentUserId]="currentUser.id"
        [selectEnabled]="isPublisher()"
        (onSelect)="selectedRoomData = $event"
        (onJoin)="handleJoinEvent($event, currentUser.id)"
        (onClose)="toggleRoomsToolbar(false)"
        class="right-container">
    </app-rooms-toolbar>

    <ng-container *ngIf="selectedRoomData">
        <app-room-card
            [roomData]="selectedRoomData"
            [conferenceId]="id"
            [(selectedUsers)]="selectedUsers"
            (onData)="handleDataEvent($event)"
            (onConnectedUsers)="handleConnectedUsers($event)"
            (onClose)="clearSelectedRoom()"
            class="room-card-container">
        </app-room-card>
    </ng-container>
   
</ng-template>

<ng-template #shareLayout>
    <div class="presenter-stream">

        <app-user-video
            [streamManager]="publisherShare.share.manager"
            [name]="publisherShare.share.name"
            [color]="publisherShare.share.color"
            [displayBorder]="publisherShare.share.userId === currentUser.id"
            [displayHighlight]="!isPublishing() && isPublishing(publisherShare.share.userId)"
            [visualType]="visualType"
            [displayAudio]="publisherShare.share.type === 'screen'"
            [showScreenShare]="currentUser.id === publisherShare.share.userId && publisherShare.share.type === 'screen'">

            <ng-container *ngTemplateOutlet="
                participantsOptionsBtn;
                context: {
                    participant: publisherShare.publisher,
                    compactMode: false,
                    disableSetAs: false,
                    disableKickUser: publisherShare.share.userId === currentUser.id
                }" optionBtn>
            </ng-container>

        </app-user-video>

    </div>
</ng-template>

<ng-template #participantsOptionsBtn
    let-participant="participant"
    let-compactMode="compactMode"
    let-disableSetAs="disableSetAs"
    let-disableKickUser="disableKickUser">

    <div fxLayout="row" [fxLayoutGap]="compactMode ? '0px' : '5px'">

        <ng-template #compactKickUserBtn [ngIf]="isPublisher() &&
                                                 currentUser.id !== participant?.userId &&
                                                 !disableKickUser">
            <button mat-icon-button
                *ngIf="compactMode; else standardKickUserBtn"
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardKickUserBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #compactSetAsPublisherBtn [ngIf]="!disableSetAs &&
                                                       (
                                                        (isPresenter(participant) && isSharedPresenters()) ||
                                                        (!isPublisher(participant) && isExclusivePresenter())
                                                       ) &&
                                                       isPublisher() &&
                                                       !isPublisher(participant) &&
                                                       (isSharing(participant?.userId) || !publisherShare.share)">
            <button mat-icon-button
                *ngIf="compactMode; else standardSetAsPublisherBtn"
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false) : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Remove from publisher' : 'Set as publisher') | translate"
                color="accent">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardSetAsPublisherBtn>
            <button mat-mini-fab
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false) : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Remove from publisher' : 'Set as publisher') | translate"
                color="primary">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

    </div>

</ng-template>

<ng-template #messageBanner let-message="message" let-icon="icon">
    <mat-card>
        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
                <mat-icon *ngIf="icon" style="margin-right: 10px; vertical-align: middle;">{{ icon }}</mat-icon>
                {{ message }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content></mat-card-content>
    </mat-card>
</ng-template>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-carousel-next-button',
  templateUrl: './carousel-next-button.component.html',
  styleUrls: ['./carousel-next-button.component.scss']
})
export class CarouselNextButtonComponent {

  @Input()
  index: number = 0;

  @Input()
  items: any[] = [];

  @Input()
  pageSize: number = 0;

  @Input()
  marginRight: string = '24px';

  @Output()
  indexChange: EventEmitter<number> = new EventEmitter<number>();

  next() {

    if (this.index >= this.items.length - 1)
      return;

    this.index++;
    this.indexChange.emit(this.index);
  }

}

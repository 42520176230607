<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    <div style="display: block" [style.height]="height" [style.width]="width">
        <canvas baseChart
            [datasets]="dataset"
            [labels]="labels"
            [options]="options"
            [plugins]="plugins"
            [type]="type">
        </canvas>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">
        {{ "Close" | translate }}
    </button>
</div>

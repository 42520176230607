<h1 mat-dialog-title>{{ 'Set Stripe configuration of value' | translate: { value: customerName } }}</h1>
<div mat-dialog-content>

    <div>
        <mat-form-field color="primary">
            <mat-label>Stripe Key</mat-label>
            <input matInput [formControl]="keyForm" (keyup)="disableWebhook()">
        </mat-form-field>
        <p *ngIf="isKeyEmpty()" class="error-p">
            {{ 'Leaving the Stripe key blank will erase its value!' | translate }}
        </p>
    </div>

    <div>
        <mat-form-field color="primary">
            <mat-label>Stripe key secret</mat-label>
            <input matInput [formControl]="keySecretForm" (keyup)="disableWebhook()">
        </mat-form-field>
        <p *ngIf="isKeySecretEmpty()" class="error-p">
            {{ 'Leaving the Stripe key secret blank will erase its value!' | translate }}
        </p>
    </div>

    <div>
        <mat-checkbox [formControl]="webhookForm">
            {{ 'Create webhook' | translate }}
        </mat-checkbox>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button (click)="saveConfig()" [disabled]="btnDisabled()" color="accent">Ok</button>
</div>

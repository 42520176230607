import { Component, OnInit } from '@angular/core';
import { EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { AzureStorageService } from 'src/app/services/storage.service';
import { WarningService } from 'src/app/services/warning.service';

const STORAGE_WARN_THESHOLD: number = 90; //Percentuale di occupazione

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

  get currentUser(): User {
    return this.auth.getCurrentUser();
  }

  appName: string = EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Title);

  constructor(private auth: AuthService,
              public azureService: AzureStorageService,
              public warningService: WarningService) { }

  ngOnInit(): void { }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  showStorageWarning() {
    if (!this.azureService.customerStorage)
      return false;

    return (this.azureService.customerStorage.currentSize / this.azureService.customerStorage.maxSize) * 100 > STORAGE_WARN_THESHOLD;
  }

  remainingStorage() {
    if (!this.azureService.customerStorage)
      return 0;

    let remainingStorage = this.azureService.customerStorage.maxSize - this.azureService.customerStorage.currentSize;

    return remainingStorage < 0 ? 0 : remainingStorage;
  }

  color() {
    let color = 'gray';

    if (this.currentUser?.isAdmin)
      color = 'crimson';

    if (this.currentUser?.isCustomerAdmin)
      color = 'darkorange';

    if (this.currentUser?.isTutor)
      color = 'lightskyblue';

    if (this.currentUser?.isTeacher)
      color = 'yellowgreen';

    if (this.currentUser?.isSpeaker)
      color = 'orchid';

    return `4px solid ${color}`;
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  usernameOrEmail: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  token: string = null;
  sending: boolean = false;
  newPassword: UntypedFormControl = new UntypedFormControl("", [Validators.required, Validators.pattern(Helper.getValidPasswordRegex())]);
  newPasswordRepeat: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  isMobile: boolean = false;

  constructor(private user: UserService,
              private snackBar: MatSnackBar,
              private deviceService: DeviceDetectorService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private navBar: NavBarService) {
    this.isMobile = this.deviceService.isMobile();

    if (this.isMobile)
      this.navBar.hide('all');
  }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');

    if (this.token != null)
      this.checkToken();
  }

  ngOnDestroy(): void {
    this.navBar.show('topbar');
    this.navBar.show('bottombar');
  }

  sendCredential() {
    this.sending = true;

    let msg = "";
    let value = this.usernameOrEmail.value;

    this.usernameOrEmail.disable();
    this.usernameOrEmail.setValue('');

    firstValueFrom(this.user.forgotPassword(value))
    .then(res => {
        msg = "Request send";
        this.router.navigate(['/login']);
    }).catch(err => {
        msg = "Error sending request";
        console.error(err);
    }).finally(() => {
        this.sending = false;
        this.usernameOrEmail.enable();
        this.snackBar.open(msg, 'Dismiss', { duration: 3000 });
    });
  }

  checkToken() {
    firstValueFrom(this.user.forgotToken(this.token)).then(res => {
      //Token valido
    }).catch(err => {
      this.token = null;
      this.snackBar.open('The link provided is no more valid, please request another password change', 'Dismiss', { duration: 4000 });
    });
  }

  updatePassword() {
    firstValueFrom(this.user.forgotChangePassword(btoa(this.newPassword.value), this.token)).then(async res => {
      this.snackBar.open('Password changed successfully', 'Dismiss', {
         duration: 3000,
         verticalPosition: 'bottom'
       });
  
       this.router.navigate(['/login']);
     }).catch(err => {
       this.snackBar.open(err.error.Message, 'Dismiss', {
         duration: 3000,
         verticalPosition: 'bottom'
       });
     });
  }

  checkNewPassword() {
    if(this.newPassword.value == this.newPasswordRepeat.value && this.newPassword.valid && this.newPasswordRepeat.valid)
      return true;
    else
      return false;
  }

  goBack() {
    this.location.back();
  }
}

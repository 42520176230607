import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterState } from 'src/app/models/masterState';

@Component({
  selector: 'app-master-popup',
  templateUrl: './master-popup.component.html',
  styleUrls: ['./master-popup.component.scss']
})
export class MasterPopupComponent implements OnInit {

  masterName: string = "";
  newState: number = 0;

  constructor(public dialogRef: MatDialogRef<MasterPopupComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.newState = this.data.newState;
    this.masterName = this.data.name;
  }

  getMasterState() {
    return MasterState.getState(this.newState);
  }

  isOnline() {
    return this.newState === MasterState.online;
  }

  isPlanned() {
    return this.newState === MasterState.planned;
  }

  closePopUp() {
    this.dialogRef.close(true);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }
}

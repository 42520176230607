<div class="component-main">

    <div fxLayout="row" fxLayoutAlign="space-between center">

        <div>
            <button mat-stroked-button (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
                {{ 'Back' | translate }}
            </button>
        </div>

        <h2>
            {{ 'Dashboard of value' | translate: { value: (user?.name ?? '') + ' ' + (user?.surname ?? '') } }}
        </h2>

        <div>&nbsp;</div>

    </div>

    <div fxLayout="row" fxLayoutGap="20px">

        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px" fxFlex="25">

            <app-profile-picture [url]="user?.profilePictureUrl" size="150px"></app-profile-picture>

            <span class="user-name">{{ user?.name }} {{ user?.surname }}</span>

            <mat-card appearance="outlined" style="width: 100%">

                <mat-card-content fxLayout="column">

                    <mat-form-field>
                        <mat-label>{{ 'username' | translate }}</mat-label>
                        <input matInput [value]="user?.username" readonly>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ 'Email' | translate }}</mat-label>
                        <input matInput [value]="user?.email" readonly>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutGap="2%">

                        <mat-form-field fxFlex="49">
                            <mat-label>{{ 'Role' | translate }}</mat-label>
                            <input matInput [value]="getRole() | role | translate" readonly>
                        </mat-form-field>

                        <mat-form-field fxFlex="49">
                            <mat-label>{{ 'Timezone' | translate }}</mat-label>
                            <input matInput [value]="'GMT ' + (user?.timezone > 0 ? ('+' + user?.timezone) : user?.timezone)" readonly>
                        </mat-form-field>

                    </div>

                    <mat-form-field>
                        <mat-label>{{ 'Summary' | translate }}</mat-label>
                        <textarea matInput [value]="user?.userSummary" readonly></textarea>
                    </mat-form-field>

                    <mat-chip-set>
                        <mat-chip>

                            <span>{{ 'Subscribed on value' | translate: { value: user?.timeStamp | date: 'dd/MM/yyyy HH:mm' } }}</span>
    
                            <mat-icon matChipTrailingIcon>
                                person
                            </mat-icon>
    
                        </mat-chip>
    
                        <mat-chip>
    
                            <span>
                                {{
                                    !user?.lastSeen ?
                                    ('Offline' | translate) :
                                    !user?.lastSeen?.endDate ?
                                    ('Online' | translate) :
                                    ('Last seen on value' | translate: { value: user?.lastSeen.lastCheck | date: 'dd/MM/yyyy HH:mm' })
                                }}
                            </span>
    
                            <mat-icon matChipTrailingIcon
                                [style.color]="user?.lastSeen && !user?.lastSeen?.endDate ?
                                               '#a0d468' :
                                               '#ed5565'"
                                [matTooltip]="(user?.lastSeen && !user?.lastSeen?.endDate ?
                                              'Online' :
                                              'Offline')
                                            | translate">
                                fiber_manual_record
                            </mat-icon>
    
                        </mat-chip>
                    </mat-chip-set>

                </mat-card-content>

            </mat-card>

        </div>

        <mat-tab-group (selectedIndexChange)="tabChange($event)" fxFlex>

            <mat-tab [label]="'Dashboard' | translate">

                <ng-template matTabContent>

                    <div fxLayout="column" fxLayoutGap="20px" class="tab-element">

                        <mat-card appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>
                                    {{ 'Classrooms' | translate }}
                                </mat-card-title>
                            </mat-card-header>
        
                            <mat-card-content>
    
                                <span *ngIf="classrooms.length === 0; else classroomList">
                                    {{ 'User does not belong to any classroom' | translate }}
                                </span>
        
                            </mat-card-content>
                        </mat-card>

                        <mat-card appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>
                                    {{ 'Conferences' | translate }}
                                </mat-card-title>
                            </mat-card-header>
        
                            <mat-card-content>
    
                                <span *ngIf="conferences.length === 0; else conferenceList">
                                    {{ 'User does not belong to any conference' | translate }}
                                </span>
        
                            </mat-card-content>
                        </mat-card>

                        <mat-card appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>
                                    {{ 'Lessons' | translate }}
                                </mat-card-title>
                            </mat-card-header>
        
                            <mat-card-content>
    
                                <span *ngIf="lessons.length === 0; else lessonList">
                                    {{ 'User does not belong to any lesson' | translate }}
                                </span>
        
                            </mat-card-content>
                        </mat-card>
    
                    </div>

                </ng-template>

            </mat-tab>

            <mat-tab [label]="'Purchased' | translate">

                <ng-template matTabContent>

                    <div class="tab-element">

                        <app-purchased-table [purchased]="purchased"></app-purchased-table>

                    </div>

                </ng-template>

            </mat-tab>

            <mat-tab [label]="'Webinars' | translate">

                <ng-template matTabContent>

                    <div fxLayout="column" fxLayoutGap="20px" class="tab-element">

                        <div fxLayoutAlign="center center" fxLayoutGap="30px">
                            <button mat-fab color="primary"
                                (click)="getUserWebinars()"
                                [matTooltip]="'Refresh' | translate">
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <button mat-fab
                                (click)="webinarViewMode = (webinarViewMode === 'cards' ? 'table' : 'cards')"
                                [matTooltip]="'Change view' | translate" 
                                color="primary">
                                <mat-icon>view_list</mat-icon>
                            </button>
                        </div>

                        <app-error-message
                            *ngIf="webinars.length === 0"
                            [errorTitle]="'There are no webinars' | translate"
                            [errorContent]="'Currently there are none to show' | translate"
                            customClass="warning">
                        </app-error-message>

                        <ng-container *ngIf="webinars.length > 0">

                            <ng-container
                                *ngIf="webinarViewMode === 'cards'"
                                [ngTemplateOutlet]="webinarList">
                            </ng-container>

                            <app-webinar-table
                                *ngIf="webinarViewMode === 'table'"
                                [webinars]="webinars"
                                [showAuthorSearch]="true"
                                [showModeratorSearch]="true"
                                [showSpeakerSearch]="true">
                            </app-webinar-table>

                        </ng-container>

                    </div>

                </ng-template>

            </mat-tab>

            <mat-tab [label]="'Courses' | translate">

                <ng-template matTabContent>

                    <div fxLayout="column" fxLayoutGap="20px" class="tab-element">

                        <div fxLayoutAlign="center center" fxLayoutGap="30px">
                            <button mat-fab color="primary"
                                (click)="getUserCourses()"
                                [matTooltip]="'Refresh' | translate">
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <button mat-fab
                                (click)="courseViewMode = (courseViewMode === 'cards' ? 'table' : 'cards')"
                                [matTooltip]="'Change view' | translate" 
                                color="primary">
                                <mat-icon>view_list</mat-icon>
                            </button>
                        </div>

                        <app-error-message
                            *ngIf="courses.length === 0"
                            [errorTitle]="'There are no courses' | translate"
                            [errorContent]="'Currently there are none to show' | translate"
                            customClass="warning">
                        </app-error-message>

                        <ng-container *ngIf="courses.length > 0">

                            <ng-container
                                *ngIf="courseViewMode === 'cards'"
                                [ngTemplateOutlet]="courseList">
                            </ng-container>

                            <app-course-table
                                *ngIf="courseViewMode === 'table'"
                                [courses]="courses"
                                [userCourseViews]="userCourseViews">
                            </app-course-table>

                        </ng-container>

                    </div>

                </ng-template>

            </mat-tab>

            <mat-tab [label]="'Masters' | translate">

                <ng-template matTabContent>

                    <div fxLayout="column" fxLayoutGap="20px" class="tab-element">

                        <div fxLayoutAlign="center center" fxLayoutGap="30px">
                            <button mat-fab color="primary"
                                (click)="getUserMasters()"
                                [matTooltip]="'Refresh' | translate">
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <button mat-fab
                                (click)="masterViewMode = (masterViewMode === 'cards' ? 'table' : 'cards')"
                                [matTooltip]="'Change view' | translate" 
                                color="primary">
                                <mat-icon>view_list</mat-icon>
                            </button>
                        </div>

                        <app-error-message
                            *ngIf="masters.length === 0"
                            [errorTitle]="'There are no masters' | translate"
                            [errorContent]="'Currently there are none to show' | translate"
                            customClass="warning">
                        </app-error-message>

                        <ng-container *ngIf="masters.length > 0">

                            <ng-container
                                *ngIf="masterViewMode === 'cards'"
                                [ngTemplateOutlet]="masterList">
                            </ng-container>

                            <app-master-table
                                *ngIf="masterViewMode === 'table'"
                                [masters]="masters">
                            </app-master-table>

                        </ng-container>

                    </div>

                </ng-template>

            </mat-tab>

            <mat-tab [label]="'Statistics' | translate">

                <ng-template matTabContent>

                    <div class="tab-element">

                        <ng-container [ngTemplateOutlet]="analytics"></ng-container>

                    </div>

                </ng-template>

            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<ng-template #classroomList>
    <mat-chip-set class="card-content-container">
        <mat-chip *ngFor="let classroom of classrooms">{{ classroom.name }}</mat-chip>
    </mat-chip-set>
</ng-template>

<ng-template #conferenceList>
    <mat-table matSort #conferenceSort="matSort" [dataSource]="conferenceSource">

        <ng-container matColumnDef="isPublic">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Type' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                <mat-icon [matTooltip]="(element.isPublic ? 'Public conference' : 'Private conference') | translate">
                    {{ element.isPublic ? 'people' : 'person' }}
                </mat-icon>

            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Title' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                {{ element.name }}

            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="publisher">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Moderator' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                {{ getPublisher(element)?.name }} {{ getPublisher(element)?.surname }}

            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                {{ element.lessonSession.startPlanned | date: 'dd/MM/yyyy' }}
                {{ 'from' | translate }}
                {{ element.lessonSession.startPlanned | date: 'HH:mm' }}
                {{ 'to' | translate }}
                {{ element.lessonSession.endPlanned | date: 'HH:mm' }}

            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                <div *ngIf="element.lessonSession.startDate && element.lessonSession.stopDate; else conferenceStatus">
                    {{ 'Performed ' | translate }}{{ 'from' | translate }} {{ element.lessonSession.startDate | date: 'HH:mm' }}
                    {{ 'to' | translate }} {{ element.lessonSession.stopDate | date: 'HH:mm' }}
                </div>
    
                <ng-template #conferenceStatus>
                    <div class="conference-description">
                        {{ (getConferenceStatus(element) | translate) ?? '&nbsp;' }}
                    </div>
                </ng-template>

            </mat-cell> 
        </ng-container>

        <mat-header-row *matHeaderRowDef="conferenceCols"></mat-header-row>
        <mat-row *matRowDef="let row; columns: conferenceCols;"></mat-row>

    </mat-table>

    <mat-paginator #conferencePaginator
        [pageSize]="10"
        [pageSizeOptions]="[10, 25, 50]"
        [showFirstLastButtons]="true">
    </mat-paginator>
</ng-template>

<ng-template #lessonList>
    <mat-table matSort #lessonsSort="matSort" [dataSource]="lessonsSource">

        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Type' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                <mat-icon [matTooltip]="getLessonType(element) | translate">
                    {{ getLessonIcon(element) }}
                </mat-icon>

            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Title' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                {{ element.name }}

            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="teacher">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Teacher' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                {{ element.teacher.name }} {{ element.teacher.surname }}

            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                <span *ngIf="element.stopDate; else lessonStatus">
                    {{ (element.typeId === 4 ? element.startPlanned : element.startDate) | date: 'dd/MM/yyyy' }}
                    {{ 'from' | translate }}
                    {{ (element.typeId === 4 ? element.startPlanned : element.startDate) | date: 'HH:mm' }}
                    {{ 'to' | translate }}
                    {{ element.typeId === 4 ? (element.endPlanned | date: 'HH:mm') : (element.stopDate ? (element.stopDate | date: 'HH:mm') : '??:??') }}
                </span>
                
                <ng-template #lessonStatus>
                    {{ getLessonStatus(element) | translate }}
                </ng-template>

            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="classroom">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Classroom' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                {{ element.classroom.name }}

            </mat-cell> 
        </ng-container>

        <mat-header-row *matHeaderRowDef="lessonsCols"></mat-header-row>
        <mat-row *matRowDef="let row; columns: lessonsCols;"></mat-row>

    </mat-table>

    <mat-paginator #lessonsPaginator
        [pageSize]="10"
        [pageSizeOptions]="[10, 25, 50]"
        [showFirstLastButtons]="true">
    </mat-paginator>
</ng-template>

<ng-template #courseList>

    <div [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">

        <app-showcase-card *ngFor="let course of courses"
            [routeUrl]="'/course'"
            [routeId]="course.id"
            [queryParams]="{ page: 'showcase' }"
            [imgUrl]="course.imageUrl"
            [topLeft]="course.state === 0 ? 'Offline' : undefined"
            [title]="course.name"
            [subtitle_extra]="course.header"
            [subtitle]="course.created | date: 'dd/MM/yyyy'"
            [description]="course.description"
            [subscription]="course?.subscription"
            [compact]="true">

            <app-showcase-card-author-info bodyBottom
                [author]="course.author"
                mode="author"
                padding="0 16px">
            </app-showcase-card-author-info>

        </app-showcase-card>

    </div>

</ng-template>

<ng-template #masterList>
    <div [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">

        <app-showcase-card *ngFor="let master of masters"
            [routeUrl]="'/master'"
            [routeId]="master.id"
            [queryParams]="{ page: 'showcase' }"
            [imgUrl]="master.imageUrl"
            [topLeft]="getMasterState().getState(master.state)"
            [title]="master.name"
            [subtitle_extra]="master.header"
            [subtitle]="master.startDate && master.stopDate ? ((master.startDate | date: 'dd/MM/yyyy') +  ' - ' + (master.stopDate | date: 'dd/MM/yyyy')) : ''"
            [description]="master.description"
            [subscription]="master?.subscription"
            [compact]="true">

            <app-showcase-card-author-info bodyBottom
                [author]="master.author"
                mode="author"
                padding="0 16px">
            </app-showcase-card-author-info>

        </app-showcase-card>

    </div>

</ng-template>

<ng-template #webinarList>
    <div [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">

        <app-showcase-card *ngFor="let webinar of webinars"
            [routeUrl]="'/webinar-showcase'"
            [routeId]="webinar.id"
            [queryParams]="{ page: 'showcase' }"
            [imgUrl]="webinar.imageUrl"
            [title]="webinar.name"
            [subtitle]="webinar.lessonSession.startPlanned | date: 'dd/MM/yyyy HH:mm'"
            [title]="webinar.name"
            [description]="webinar.header"
            [subscription]="webinar.subscription"
            [compact]="true">

            <app-showcase-card-author-info bodyBottom
                [author]="getModerator(webinar)"
                [users]="getPresenters(webinar)"
                mode="webinar"
                padding="0 16px">
            </app-showcase-card-author-info>

        </app-showcase-card>

    </div>

</ng-template>

<ng-template #analytics>

    <div fxLayout="column" fxLayoutGap="20px">

        <div fxLayout="row" fxLayoutAlign="space-between">

            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" fxFlex="30"> 
    
                <mat-form-field>
                    <mat-label>{{ 'Year' | translate }}</mat-label>
                    <mat-select [formControl]="yearDate" (selectionChange)="filterData('year')">
                        <mat-option *ngFor="let year of years" [value]="year">
                            {{ year }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        
                <mat-form-field>
                    <mat-label>{{ 'Month' | translate }}</mat-label>
                    <mat-select [formControl]="monthDate" (selectionChange)="filterData('month')">
                        <mat-option *ngFor="let month of months" [value]="month.value">
                            {{ month.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        
            </div>
        
            <div fxLayoutAlign="center center" fxFlex>
                <button mat-fab
                    (click)="getUserData()"
                    [matTooltip]="'Refresh' | translate"
                    color="primary">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        
            <div fxLayoutAlign="end center" fxLayoutGap="20px" fxFlex="30">
                <div>
                    <p class="update-time">
                        {{ '(*) Data updated on' | translate }} 
                        {{ userResultsLastUpdate | date: 'dd/MM/yyyy' }} 
                        {{ 'at' | translate }} {{ userResultsLastUpdate | date: 'HH:mm' }}
                    </p>
                </div>
    
                <!--button mat-fab color="primary"
                    (click)="saveAllToCsv()"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button-->
            </div>
    
        </div>
    
        <div fxLayout="row wrap" fxLayoutAlign="center" style="gap: 20px">
    
            <mat-card appearance="outlined" class="matcard-row">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Total published stream time' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxFlex>
                    <div class="value-container">
                        <mat-icon class="card-icon">access_time</mat-icon>
                        <h1>
                            {{ publishedStreamingTimePerMonth / 3600 | number: '1.2-2' }} h
                        </h1>
                    </div>
                    <div class="card-footer">
                        {{ secondsToDhms(publishedStreamingTimePerMonth) }}
                    </div>
                </mat-card-content>
            </mat-card>
    
            <mat-card appearance="outlined" class="matcard-row">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Total streaming time' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxFlex>
                    <div class="value-container">
                        <mat-icon class="card-icon">access_time</mat-icon>
                        <h1>
                            {{ streamingTimePerMonth / 3600 | number: '1.2-2' }} h
                        </h1>
                    </div>
                    <div class="card-footer">
                        {{ secondsToDhms(streamingTimePerMonth) }}
                    </div>
                </mat-card-content>
            </mat-card>
    
            <mat-card appearance="outlined" class="matcard-row">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Total online time' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxFlex>
                    <div class="value-container">
                        <mat-icon class="card-icon">access_time</mat-icon>
                        <h1>
                            {{ onlineTimePerMonth / 3600 | number: '1.2-2' }} h
                        </h1>
                    </div>
                    <div class="card-footer">
                        {{ secondsToDhms(onlineTimePerMonth) }}
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="matcard-row">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Participated webinar' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxFlex>
                    <div class="value-container">
                        <mat-icon class="card-icon">interpreter_mode</mat-icon>
                        <h1>
                            {{ userWebinarPerformedPerMonth }}/{{ userWebinarStreamingTimePerMonth / 3600 | number : '1.2-2' }} h
                        </h1>
                    </div>
                    <div class="card-footer">
                        {{ secondsToDhms(userWebinarStreamingTimePerMonth) }}
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="matcard-row">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Participated conference' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxFlex>
                    <div class="value-container">
                        <mat-icon class="card-icon">interpreter_mode</mat-icon>
                        <h1>
                            {{ userConferencePerformedPerMonth }}/{{ userConferenceStreamingTimePerMonth / 3600 | number : '1.2-2' }} h
                        </h1>
                    </div>
                    <div class="card-footer">
                        {{ secondsToDhms(userConferenceStreamingTimePerMonth) }}
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="matcard-row">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Participated lessons' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxFlex>
                    <div class="value-container">
                        <mat-icon class="card-icon">school</mat-icon>
                        <h1>
                            {{ userLessonPerformedPerMonth }}/{{ userLessonStreamingTimePerMonth / 3600 | number : '1.2-2' }} h
                        </h1>
                    </div>
                    <div class="card-footer">
                        {{ secondsToDhms(userLessonStreamingTimePerMonth) }}
                    </div>
                </mat-card-content>
            </mat-card>

        </div>
    
        <div fxLayout="column" fxLayoutGap="20px">

            <div style="align-self: center">

                <mat-button-toggle-group [value]="currentChart" (change)="currentChart = $event.value">
                    <mat-button-toggle *ngFor="let chart of charts; let i = index" [value]="i">
                        {{ chart.title | translate }}
                    </mat-button-toggle>
                </mat-button-toggle-group>

            </div>

            <mat-card appearance="outlined">
    
                <mat-card-header>
                    <div fxFlex="20"></div>
                    <div fxFlex style="text-align: center">
                        <h2>{{ charts[currentChart].title | translate }}</h2>
                    </div>
                    <div fxFlex="20" fxLayoutAlign="end center">
                        <button mat-fab color="primary"
                            (click)="charts[currentChart].export()" 
                            [matTooltip]="'Save csv' | translate">
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                </mat-card-header>

                <mat-card-content>
                    <canvas baseChart
                        [data]="charts[currentChart].data"
                        [options]="charts[currentChart].options"
                        chartType="bar"
                        style="width: 100%">
                    </canvas>
                </mat-card-content> 
            
            </mat-card>
    
        </div>

    </div>

</ng-template>

<h1 mat-dialog-title>{{ (masterContentToEdit ? 'Edit content' : 'Add content') | translate }}</h1>
<div mat-dialog-content>
    <app-translations-form
        [label]="'Name' | translate"
        [disabled]="sending"
        [(translation)]="nameTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput type="text" [formControl]="name">
            <mat-error *ngIf="name.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
                {{ 'Name should be at least 4 characters' | translate }}
            </mat-error>
            <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                info
            </mat-icon>
        </mat-form-field>

    </app-translations-form>
    <app-translations-form
        [label]="'Description' | translate"
        [disabled]="sending"
        [mode]="'textarea'"
        [(translation)]="descriptionTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <textarea matInput [formControl]="description" style="height: 100px"></textarea>
            <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                info
            </mat-icon>
        </mat-form-field>

    </app-translations-form>
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 20px">
        <div fxFlex>
            <mat-checkbox [formControl]="download">{{ 'Downloadable' | translate }}</mat-checkbox>
        </div>
        <!--div *ngIf="currentType === 1" fxFlex> SCOMMENTARE PER ABILITAZIONE SOTTOTITOLI
            <mat-checkbox [(ngModel)]="subtitles">{{ 'Generate subtitles' | translate }}</mat-checkbox>
        </div-->
    </div>
    <div>
        <mat-label>{{ 'Media' | translate }}</mat-label>
        <app-file-input
            [control]="mediaSrc"
            [maxSize]="5000000"
            [acceptedTypes]="getAcceptedFiles()"
            [rejectedErrorMessage]="('The file type must be a video and not exceed' | translate) + ' 5GB'"
            [deletedErrorMessage]="'Please select a file' | translate"
            (acceptedFile)="mediaAccept()"
            (deletedFile)="mediaDelete()"
            style="width: 100%">
        </app-file-input>
        <mat-label style="margin-top: 20px">{{ 'Thumbnail' | translate }}</mat-label>
        <app-file-input
            [control]="thumbnailSrc"
            [maxSize]="2000"
            [acceptedTypes]="'image/*'"
            [aspectRatio]="8/5"
            [maxHeight]="2000"
            [maxWidth]="2000"
            [enableCrop]="true"
            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '8:5', height: '2000px', width: '2000px', size: '2MB' }"
            [deletedErrorMessage]="'Please select a file' | translate"
            style="width: 100%">
        </app-file-input>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" [disabled]="getBtnDisabled()" (click)="saveContent()">
        Ok
    </button>
</div>

<div *ngIf="sending" class="cssload-container" style="top: 50%">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>

<ngx-extended-pdf-viewer *ngIf="currentType === 2"
    [src]="this.mediaSrc.value"
    hidden="true">
</ngx-extended-pdf-viewer>

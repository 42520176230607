import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-price-card',
  templateUrl: './price-card.component.html',
  styleUrls: ['./price-card.component.scss']
})
export class PriceCardComponent implements OnInit {

  // ---- Solo se routeEnabled = true ---- //

  @Input()
  routeEnabled: boolean = false;

  @Input()
  routeUrl: string;

  @Input()
  routeId: number = null;

  @Input()
  queryParams: any = null;

  @Input()
  routeMessage: string;

  // ---- FINE ---- //

  @Input()
  subscription: SubscriptionDTO = null;

  @Input()
  subCrudShow: boolean = false;

  @Input()
  subCrudDisabled: boolean = false;

  @Input()
  buyShow: boolean = false;

  @Input()
  buyDisabled: boolean = true;

  @Input()
  renewShow: boolean = false;

  @Input()
  bookShow: boolean = false;

  @Input()
  bookDisabled: boolean = true;

  @Input()
  subtitle: string = null;

  @Input()
  logInToBuy: boolean = false;

  @Input()
  compact: boolean = false;
  
  @Output()
  buyFree: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  buyPay: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  book: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  subAdd: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  subEdit: EventEmitter<boolean> = new EventEmitter<boolean>();

  canpurchase: boolean = true;

  constructor(private router: Router,
              private auth: AuthService,
              private subscriptionService: SubscriptionService,
              private translate: TranslateService,
              private dialog: MatDialog) { }

  ngOnInit(): void { }

  buy(): void {
    this.subscriptionService.checkSubscriptionPurchaseLimits(this.subscription.id)
      .subscribe({
        next: () => {
          this.canpurchase = true;

          this.subscription.isFree ? this.buyFree.emit(true) : this.buyPay.emit(true);
        },
        error: async err => {
          console.error(err);

          this.canpurchase = false;

          this.dialog.open(GenericPopupComponent,
            {
              width: '400px',
              data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Warning')),
                body: err.error,
                hideCancelBtn: true
              }
            });
        }
      });
  }

  bookEntity(): void {
    return this.book.emit(true);
  }

  crudOperation(): void {
    return this.subscription ? this.subAdd.emit(true) : this.subEdit.emit(true);
  }

  goTo(): void {
    let route: any[] = [this.routeUrl];

    if (this.routeId)
      route.push(this.routeId);

    this.router.navigate(route, { queryParams: this.queryParams });
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  redirectToLogIn() {
    this.auth.setRequestedPath(window.location.pathname);
    this.router.navigate(['/login']);
  }
}

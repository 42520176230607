import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MasterContentDTO } from 'src/app/models/dto/masterContentDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';

@Component({
  selector: 'app-master-content-card',
  templateUrl: './master-content-card.component.html',
  styleUrl: './master-content-card.component.scss'
})
export class MasterContentCardComponent {

  @Input()
  content: MasterContentDTO;

  @Input()
  masterAuthorId: number;

  @Input()
  hideEdit: boolean = false;

  @Input()
  hideDelete: boolean = false;

  @Output()
  onView: EventEmitter<MasterContentDTO> = new EventEmitter<MasterContentDTO>();

  @Output()
  onEdit: EventEmitter<MasterContentDTO> = new EventEmitter<MasterContentDTO>();

  @Output()
  onDelete: EventEmitter<MasterContentDTO> = new EventEmitter<MasterContentDTO>();

  currentUser: User = this.auth.getCurrentUser();

  constructor(private auth: AuthService, private darkService: DarkThemeService) { }

  isDark() {
    return this.darkService.isSetDark;
  }

}

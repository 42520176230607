import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { User } from 'src/app/models/user';
import { SubscriptionLevelDTO } from 'src/app/models/dto/subscriptionLevelDTO';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { ActivatedRoute } from '@angular/router';
import { LanguageCodes, TranslationService } from 'src/app/services/translation.service';
import { AddSubscriptionComponent, CrudSubscription, SubscriptionType } from 'src/app/popup/add-subscription/add-subscription.component';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helpers/helper';
import { CustomerAddComponent } from 'src/app/popup/customer-add/customer-add.component';
import { AddUserComponent } from '../user-list/add-user/add-user.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { AddSubscriptionLevelComponent } from 'src/app/popup/add-subscription-level/add-subscription-level.component';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-subscription-level',
  templateUrl: './subscription-level.component.html',
  styleUrls: ['./subscription-level.component.scss']
})
export class SubscriptionLevelComponent implements OnInit {

  key: string;
  currentUser: User = null;
  level: SubscriptionLevelDTO = null;
  levels: MatTableDataSource<SubscriptionLevelDTO>;

  //Per controllo subscription
  canpurchased: boolean;
  currentstate: number;
  isstartget: boolean;

  displayedColumns: string[] = ['name', 'userNoMax', 'storageMax', 'price', 'select'];
  @ViewChild('levelsSort') levelsSort: MatSort;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private subscriptionService: SubscriptionService,
              private location: Location,
              private dialog: MatDialog,
              private translation: TranslationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.key = this.route.snapshot.paramMap.get('key');
    this.currentUser = this.auth.getCurrentUser();

    let goToStripe = false;

    if (this.route.snapshot.queryParamMap.has('stripe'))
      goToStripe = Boolean(this.route.snapshot.queryParamMap.get('stripe'));

    goToStripe ?
    this.getLevel(Number(this.key)) :
    this.getLevels();
  }

  getLevels() {
    this.subscriptionService.getSubscriptionLevelByKey(this.key)
    .subscribe(res => {
      this.addTableData(res);

      this.level = this.level
                 ? res.find(l => l.id === this.level.id)
                 : res[0];

      if (this.isAuthenticated())
        this.checkSubscription();
    });
  }

  getLevel(levelId: number) {
    this.subscriptionService.getSubscriptionLevelById(levelId)
      .subscribe(res => {
        this.level = res;
        this.addTableData([res]);

        this.key = res.nameKey;
        this.getLevels();

        this.pay();
      });
  }

  async pay() {
    this.dialog.open(CustomerAddComponent, { width: '500px', data: { level: this.level } });
  }

  editSubscriptionLevel() {
    const dialogRef = this.dialog.open(AddSubscriptionLevelComponent, {
      data: { subscriptionLevel: this.level }, width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getLevels();
      }
    });
  }

  addSubscription () {
    let data: CrudSubscription = {
      entityId: this.level.id,
      entityType: SubscriptionType.level,
      disableFree: true,
      subscription: this.level.subscription
    };
    
    const dialogRef = this.dialog.open(AddSubscriptionComponent, {
      data: data, width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getLevels();
      }
    });
  }

  getSubTitle() {
    return this.translation.currentLang.code === LanguageCodes.IT ? 'Iva inclusa' : null;
  }

  goBack() {
    this.location.back();
  }

  getHelper() {
    return Helper;
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  checkSubscription() {
    if (!this.level.idSubscription) {
      this.canpurchased = null;
      this.currentstate = null;
      this.isstartget = null;

      return;
    }

    this.subscriptionService.getCanPurchase(this.level.idSubscription)
      .subscribe(result => this.canpurchased = result);

    this.subscriptionService.getState(this.level.idSubscription)
      .subscribe(result => this.currentstate = result);

    this.subscriptionService.getsubscriptionlimituser(this.level.idSubscription)
      .subscribe(result => this.isstartget = result);
  }

  addSubscriptionUser() {
    const dialogRef = this.dialog.open(AddUserComponent,
    {
      width: '600px',
      data: {
        mode: 'level',
        levelId: this.level.id
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.dialog.open(GenericPopupComponent, {
        width: '500px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('User creation')),
          body: await firstValueFrom(this.translate.get('To confirm the user creation please click on the link provided to your email address')),
          hideCancelBtn: true
        }
      });
    });
  }

  getPrice(subscription: SubscriptionDTO) {
    if (!subscription)
      return '- €';

    return subscription.isFree
         ? 'Free'
         : `${subscription.subscriptionFee.euro} €`;
  }

  private addTableData(levels: SubscriptionLevelDTO[]) {
    this.levels = new MatTableDataSource(levels);
    this.levels.sort = this.levelsSort;

    this.levels.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'price':
          return this.getPrice(item.subscription);
        default:
          return item[property];
      };
    }
  }

}

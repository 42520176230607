<div class="component-main center-item">
    <div>
        <h2>Smily</h2>
        <mat-form-field>
            <mat-label>{{ 'Room' | translate }}</mat-label>
            <mat-select name="name" [formControl]="room" (selectionChange)="onRoomChange()">
                <mat-option [value]="0">{{ 'All Rooms' | translate }}</mat-option>
                <mat-option *ngFor="let item of rooms" [value]="item?.id">
                    {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="80%">
            <div *ngIf="stations && stations.length == 0">
                <app-error-message customClass="warning"
                    [errorTitle]="'There arent any Smily' | translate"
                    [errorContent]="'You can try to edit the filters to have some results' | translate"> 
                </app-error-message>
            </div>
            <mat-accordion class="my-headers-align" *ngIf="stations && stations.length != 0">
                <mat-expansion-panel *ngFor="let station of stations" (opened)="getAnswersOfStation(station.id)" style="margin: 10px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title style="width: 25%;">
                            <div style="margin-right: 20px;">
                                <span>{{ station.name }}</span>
                            </div>
                            <div [matTooltip]="'The station has problems' | translate">
                                <mat-icon fxFlex="4%" style="color: red;" *ngIf="!station.workingState">
                                    error
                                </mat-icon>
                            </div>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div>
                                {{ station.description }}
                            </div>
                            <div *ngIf="!station.workingState">
                                <button mat-icon-button color="primary" (click)="setStationWorkingState(station.id, station.name)" [matTooltip]="'Mark as working' | translate" [matTooltipPosition]="'before'">
                                    <mat-icon>
                                        done
                                    </mat-icon>
                                </button>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <table mat-table [dataSource]="answers" *ngIf="answers && answers.length > 0" style="width: 100%; margin-left: auto; margin-right: auto;">
        
                            <ng-container matColumnDef="surname">
                                <th mat-header-cell *matHeaderCellDef>{{ 'Surname' | translate }}</th>
                                <td mat-cell *matCellDef="let element"> {{ element.user.surname }} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</th>
                                <td mat-cell *matCellDef="let element"> {{ element.user.name }} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef>{{ 'Problem' | translate }}</th>
                                <td mat-cell *matCellDef="let element"> {{ element.response }} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef>{{ 'Date' | translate }}</th>
                                <td mat-cell *matCellDef="let element"> {{ element.created | date: 'HH:mm - dd/MM/yyyy' }} </td>
                            </ng-container>
        
                            
                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                        </table>
                        <div *ngIf="answers && answers.length == 0">
                            {{'This Smily has no issues' | translate}}
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { CourseEdit } from 'src/app/models/courseEdit';
import { CourseContentEdit } from 'src/app/models/courseContentEdit';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { CourseBlogDTO } from 'src/app/models/dto/courseBlogDTO';
import { CourseBlogEdit } from 'src/app/models/courseBlogEdit';
import { CourseLikesDTO } from 'src/app/models/dto/courseLikesDTO';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { SearchResult, SearchInfo } from 'src/app/models/search';
import { Helper } from 'src/app/helpers/helper';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { CourseContentTrackerDTO } from 'src/app/models/dto/courseContentTrackerDTO';
import { ContentTrackerInfo } from 'src/app/models/contentTrackerInfo';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { CourseViews, UserCourseViews } from 'src/app/models/courseView';
import { TranslationService } from './translation.service';
import { ScormLaunchLink } from '../models/scormLaunchLink';
import { ScormCloudRegistrationResultDTO } from '../models/dto/scormCloudRegistrationResultDTO';
import { SubtitleEdit } from '../models/subtitleEdit';

@Injectable({
    providedIn: 'root'
})
export class CourseService {
    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlCourse = `${this.bckEndUrlBaseUrl}/course`;
    private bckEndUrlSubscriptions = `${this.bckEndUrlBaseUrl}/subscription`;

    constructor(private http: HttpClient, private translationService: TranslationService) { }

    public getCustomersOfUser(): Observable<CustomerDTO[]> {
        return this.http.get<CustomerDTO[]>(`${this.bckEndUrlCourse}/customers`, { headers: Helper.getAuthHeader() });
    }

    public getCoursesOfClass(classId: number): Observable<CourseDTO[]> {
        return this.http.get<CourseDTO[]>(`${this.bckEndUrlCourse}/class/${classId}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getCoursesOfUser(userId?: number, classroomData?: boolean): Observable<CourseDTO[]> {
        let params = new HttpParams();

        if (userId != undefined)
            params = params.append('userId', userId);

        if (classroomData != undefined)
            params = params.append('classroomData', classroomData);

        return this.http.get<CourseDTO[]>(`${this.bckEndUrlCourse}/user/${this.translationService.currentLang.lang}`, { params: params, headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getCourses(userAuthenticated: boolean): Observable<CourseDTO[]> {
        return this.http.get<CourseDTO[]>(`${this.bckEndUrlCourse}/all/${this.translationService.currentLang.lang}`, { headers: userAuthenticated ? Helper.getAuthHeader() : null }).pipe(tap(console.log));
    }

    public getCourseContent(courseId: number, userAuthenticated: boolean): Observable<CourseDTO> {
        return this.http.get<CourseDTO>(`${this.bckEndUrlCourse}/course/${courseId}/${this.translationService.currentLang.lang}`, { headers: userAuthenticated ? Helper.getAuthHeader() : null }).pipe(tap(console.log));
    }

    public getContentById(contentId: number): Observable<CourseContentDTO> {
        return this.http.get<CourseContentDTO>(`${this.bckEndUrlCourse}/content/${contentId}/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getContentBlogs(contentId: number): Observable<CourseBlogDTO[]> {
        return this.http.get<CourseBlogDTO[]>(`${this.bckEndUrlCourse}/blogs/${contentId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getCourseClassrooms(courseId: number): Observable<ClassroomDTO[]> {
        return this.http.get<ClassroomDTO[]>(`${this.bckEndUrlCourse}/course/classrooms/${courseId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public getContentLikes(contentId: number): Observable<CourseLikesDTO[]> {
        return this.http.get<CourseLikesDTO[]>(`${this.bckEndUrlCourse}/likes/${contentId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public postCourse(course: CourseEdit) {
        course.Language = this.translationService.currentLang.lang;

        return this.http.post(`${this.bckEndUrlCourse}/course`, course, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public putCourse(id: number, course: CourseEdit) {
        course.Language = this.translationService.currentLang.lang;

        return this.http.put(`${this.bckEndUrlCourse}/course/${id}`, course, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public setCourseState(state: number, courseId: number) {
        return this.http.put(`${this.bckEndUrlCourse}/course/${courseId}/state/${state}`, {}, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public deleteCourse(id: number) {
        return this.http.delete(`${this.bckEndUrlCourse}/course/${id}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public postContent(content: CourseContentEdit) {
        content.Language = this.translationService.currentLang.lang;

        return this.http.post(`${this.bckEndUrlCourse}/content`, content, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public putContent(id: number, content: CourseContentEdit) {
        content.Language = this.translationService.currentLang.lang;

        return this.http.put(`${this.bckEndUrlCourse}/content/${id}`, content, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public deleteContent(id: number) {
        return this.http.delete(`${this.bckEndUrlCourse}/content/${id}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public postBlog(blog: CourseBlogEdit) {
        return this.http.post(`${this.bckEndUrlCourse}/blog`, blog, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public putBlog(id: number, blog: CourseBlogEdit) {
        return this.http.put(`${this.bckEndUrlCourse}/blog/${id}`, blog, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public deleteBlog(id: number) {
        return this.http.delete(`${this.bckEndUrlCourse}/blog/${id}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public postLike(contentId: number) {
        return this.http.post(`${this.bckEndUrlCourse}/like/${contentId}`, null, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public deleteLike(contentId: number) {
        return this.http.delete(`${this.bckEndUrlCourse}/like/${contentId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public searchString(word: string): Observable<SearchResult> {
        var data = new SearchInfo();
        data.WordToSearch = word;
        data.Language = this.translationService.currentLang.lang;
        data.Mode = null; //All

        return this.http.post<SearchResult>(`${this.bckEndUrlCourse}/search`, data, { headers: Helper.getAuthHeader() });
    }

    /*
    public deleteCoursePermanently(id: number) {
        return this.http.delete(`${this.bckEndUrlCourse}/permanent/${id}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }
    */
    
    public addSubscription(idCourse: number, subscription: SubscriptionDTO) {
        return this.http.post(`${this.bckEndUrlSubscriptions}/course/${idCourse}`, subscription, { headers: Helper.getAuthHeader() });
    }
        
    public putSubscription(idCourse: number, subscription: SubscriptionDTO) {
        return this.http.put(`${this.bckEndUrlSubscriptions}/course/${idCourse}`, subscription, { headers: Helper.getAuthHeader() });
    }

    public getHashtags(): Observable<string[]> {
        return this.http.get<string[]>(`${this.bckEndUrlCourse}/hashtags`, { headers: Helper.getAuthHeader() });
    }

    public postTracker(contentId: number, startDate: Date, startPosition: number, stopPosition: number) {
        return this.http.post(`${this.bckEndUrlCourse}/tracker/${contentId}`, <CourseContentTrackerDTO>{ startDate: startDate.toUTCString(), startPosition: startPosition, stopPosition: stopPosition }, { headers: Helper.getAuthHeader() });
    }

    public getContentTracker(contentId: number): Observable<ContentTrackerInfo> {
        return this.http.get<ContentTrackerInfo>(`${this.bckEndUrlCourse}/tracker/${contentId}`, { headers: Helper.getAuthHeader() });
    }  

    public getContentTrackerAll(contentId: number): Observable<UserDTO[]> {
        return this.http.get<UserDTO[]>(`${this.bckEndUrlCourse}/trackers/${contentId}`, { headers: Helper.getAuthHeader() });
    } 

    public buyCourse(subId: number, userId?: number) {
        let options: any = { headers: Helper.getAuthHeader() };

        if (userId != null)
            options.params = new HttpParams()
                                .set('targetUser', String(userId));

        return this.http.post(`${this.bckEndUrlSubscriptions}/paid/course/${subId}`, {}, options);
    }

    public postSurvey(courseId: number, orderIndex: number, lesson: LessonSessionDTO) {
        return this.http.post(`${this.bckEndUrlCourse}/lesson/${courseId}`, lesson, { headers: Helper.getAuthHeader(), params: new HttpParams().set('orderIndex', String(orderIndex)) });
    }

    public putSurvey(lessonId: number, lesson: LessonSessionDTO, orderIndex: number) {
        return this.http.put(`${this.bckEndUrlCourse}/lesson/${lessonId}`, lesson, { headers: Helper.getAuthHeader(), params: new HttpParams().set('orderIndex', String(orderIndex)) });
    }

    public getCourseView(courseId: number): Observable<CourseViews[]> {
        return this.http.get<CourseViews[]>(`${this.bckEndUrlCourse}/views/${courseId}`, { headers: Helper.getAuthHeader() });
    }

    public getCourseUserViews(courseId?: number, userId?: number): Observable<UserCourseViews[]> {
        let params = new HttpParams();

        if (courseId)
            params = params.append('courseId', courseId);

        if (userId)
            params = params.append('userId', userId);

        return this.http.get<UserCourseViews[]>(`${this.bckEndUrlCourse}/userViews`, { headers: Helper.getAuthHeader(), params: params });
    }

    public getCourseParticipants(courseId: number): Observable<UserDTO[]> {
        return this.http.get<UserDTO[]>(`${this.bckEndUrlCourse}/participants/${courseId} `, { headers: Helper.getAuthHeader() });
    }

    public addFavourite(contentId: number) {
        return this.http.post(`${this.bckEndUrlCourse}/favourite/${contentId}`, null, { headers: Helper.getAuthHeader() });
    }

    public removeFavourite(contentId: number) {
        return this.http.delete(`${this.bckEndUrlCourse}/favourite/${contentId}`, { headers: Helper.getAuthHeader() });
    }

    public getFavouriteContents(): Observable<CourseContentDTO[]> {
        return this.http.get<CourseContentDTO[]>(`${this.bckEndUrlCourse}/favourites/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
    }

    public checkFileIsPresent(hash: string) {
        let params = new HttpParams()
            .set('hash', hash);

        return this.http.get(`${this.bckEndUrlCourse}/hash`, { headers: Helper.getAuthHeader(), params: params });
    }

    public updateCourseVisibility(courseId: number, visibility: number) {
        return this.http.get(`${this.bckEndUrlCourse}/${courseId}/visibility/${visibility}`, { headers: Helper.getAuthHeader() });
    }
    
    public getScormUrl(contentId: number): Observable<ScormLaunchLink> {
        return this.http.get<ScormLaunchLink>(`${this.bckEndUrlCourse}/content/${contentId}/scorm`, { headers: Helper.getAuthHeader() });
    }

    public getScormPreviewUrl(contentId: number): Observable<ScormLaunchLink> {
        return this.http.get<ScormLaunchLink>(`${this.bckEndUrlCourse}/content/${contentId}/scorm/preview`, { headers: Helper.getAuthHeader() });
    }

    public getScormResults(contentId: number): Observable<ScormCloudRegistrationResultDTO[]> {
        return this.http.get<ScormCloudRegistrationResultDTO[]>(`${this.bckEndUrlCourse}/content/${contentId}/scorm/results`, { headers: Helper.getAuthHeader() });
    }

    public createContentFromVideoCut(contentId: number, startTime?: number, endTime?: number) {
        let params = new HttpParams();

        if (startTime)
            params = params.set('startTime', startTime);

        if (endTime)
            params = params.set('endTime', endTime);

        return this.http.get(`${this.bckEndUrlCourse}/content/${contentId}/cut`, { params: params, headers: Helper.getAuthHeader() });
    }

    public getCourseContentSubtitles(contentId: number, language: string) {
        return this.http.get<SubtitleEdit[]>(`${this.bckEndUrlCourse}/content/${contentId}/subtitles/${language}`, { headers: Helper.getAuthHeader() });
    }

    public putCourseContentSubtitles(contentId: number, language: string, subtitles: SubtitleEdit[]) {
        return this.http.put(`${this.bckEndUrlCourse}/content/${contentId}/subtitles/${language}`, subtitles, { headers: Helper.getAuthHeader() });
    }
    
    public reorderCourseContents(courseId: number, order: { index: number, courseContentId: number }[]) {
        return this.http.put(`${this.bckEndUrlCourse}/${courseId}/order`, order, { headers: Helper.getAuthHeader() });
    }

    public postFolder(folder: CourseContentEdit) {
        folder.Language = this.translationService.currentLang.lang;

        return this.http.post(`${this.bckEndUrlCourse}/content/folder`, folder, { headers: Helper.getAuthHeader() });
    }

    public addContentsToFolder(folderId: number, contentsIds: number[]) {
        return this.http.post(`${this.bckEndUrlCourse}/content/${folderId}/elements`, contentsIds, { headers: Helper.getAuthHeader() });
    }

    public getContentsOfFolder(folderId: number) {
        return this.http.get<CourseContentDTO[]>(`${this.bckEndUrlCourse}/content/${folderId}/elements/${this.translationService.currentLang.lang}`, { headers: Helper.getAuthHeader() });
    }

    public createCopyOfCourse(courseId: number) {
        return this.http.post(`${this.bckEndUrlCourse}/${courseId}/copy`, { }, { headers: Helper.getAuthHeader() });
    }
}

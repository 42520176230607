<div class="component-main">
    <div style="text-align:center">
        <h2>{{ 'Payments list' | translate }}</h2>
    </div>
    <div class="filters-container">
            <mat-form-field color="primary">
                <mat-label>{{ 'Search' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)" [disabled]="checked || selectedObject !== 0">
                <button matSuffix mat-icon-button [matTooltip]="'Search' | translate">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field *ngIf="isAdmin()" style="width:130px">
                <mat-label>{{ 'Mode' | translate }}</mat-label>
                <mat-select  [formControl]="mode" (ngModelChange)="filter()" [disabled]="checked || selectedObject !== 0" >
                    <mat-option *ngFor="let mode of modes"  [value]="mode.value">
                        {{ mode.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <mat-form-field style="width:130px" *ngIf="(isCustomerAdmin() && invoicePerMonth === 1) || (isAdmin() && mode.value === 0)">
                <mat-label>{{ 'Month' | translate }}</mat-label>
                <mat-select  [formControl]="monthDate" (ngModelChange)="filter()" [disabled]="checked || selectedObject !== 0" >
                    <mat-option *ngFor="let month of months" [value]="month.value">
                        {{ month.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <mat-form-field *ngIf="(isCustomerAdmin() && invoicePerMonth === 2) || (isAdmin() && mode.value === 1)">
                <mat-label>{{ 'Quarter' | translate }}</mat-label>
                <mat-select [formControl]="quarterDate" (ngModelChange)="filter()" [disabled]="checked || selectedObject !== 0" >
                    <mat-option *ngFor="let quarter of quarters" [value]="quarter.value">
                        {{ quarter.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <mat-form-field style="width:130px">
                <mat-label>{{ 'Year' | translate }}</mat-label>
                <mat-select  [formControl]="yearDate"  placeholder="year" (ngModelChange)="filter()" [disabled]="checked || selectedObject !== 0">
                    <mat-option *ngFor="let year of years" [value]="year">
                        {{year}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isAdmin()">
                <mat-label>{{ 'Customer' | translate }}</mat-label>  
                <mat-select  [(value)]="selectedCustomer" (selectionChange)="filter()" [disabled]="checked || selectedObject !== 0">
                    <mat-option>
                        <ngx-mat-select-search
                        [formControl]="searchForm"
                        [placeholderLabel]="'Search' | translate"
                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="0">{{ 'All Customers' | translate }}</mat-option>
                    <mat-option *ngFor="let customer of customers | search: searchForm.value: ['name']" [value]="customer.id">
                        {{customer.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width:130px">
                <mat-label>{{ 'Type' | translate }}</mat-label>
                <mat-select [formControl]="objectType"  (selectionChange)="filter()" [disabled]="checked"> 
                    <mat-option *ngFor="let object of objects" [value]="object.value">
                        {{ object.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <mat-form-field style="width:170px">
                <mat-label>{{ 'Payment type' | translate }}</mat-label>
                <mat-select [formControl]="paymentType" (selectionChange)="filter()" [disabled]="checked">
                    <mat-option *ngFor="let payment of payments" [value]="payment.value">
                        {{ payment.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <mat-form-field style="width:120px">
                <mat-label>{{ 'Amount' | translate }}</mat-label>
                <mat-select [formControl]="cost" (selectionChange)="filter()" [disabled]="checked">
                    <mat-option *ngFor="let cost of costs" [value]="cost.value">
                        {{ cost.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width:250px">
                <mat-label>{{ 'Filter by object' | translate }}</mat-label>  
                <mat-select [(value)]="selectedObject" [formControl]="objectFilter"  (selectionChange)="filter()" [disabled]="checked">
                    <mat-option>
                        <ngx-mat-select-search
                        [formControl]="searchForm"
                        [placeholderLabel]="'Search' | translate"
                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="0">{{ 'All' | translate }}</mat-option>
                    <mat-option *ngFor="let object of allFeeObjects | search: searchForm.value: ['name']" [value]="object">
                        {{object.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field> 
    
    </div>

    <mat-card class="main" [style.background-color]="darkService.isSetDark ? '' : '#eef5f9'">
        <div style="text-align:right;margin-bottom:10px;margin-right:1.5%;margin-top:1%;font-size: 1.5rem;">
            <button (click)="saveCsv()" class="btn-update" mat-fab color="primary"
            [matTooltip]="'Save csv' | translate" [disabled]="disableCsv()">
                <mat-icon>save</mat-icon>
            </button>
            <button (click)="getReportLink()" style="margin-left:1%;" class="btn-update" mat-fab color="primary"
            [matTooltip]="'Save pdf' | translate" [disabled]="disablePdf()">
                <mat-icon>picture_as_pdf</mat-icon>
            </button> 
        </div> 
        <div id="cards" style="display:flex;justify-content: space-around;">
            <mat-card class="matcard-row" [style.background-color]="darkService.isSetDark ? '' : 'white'">
                <mat-card-title>
                    {{'Paid webinar' | translate }}
                </mat-card-title>
                <mat-card-content>
                    <div class="price">
                        <mat-icon class="mat-card-icon">interpreter_mode</mat-icon>
                        <div class="stats">
                            <h1>{{formattedNumber(totalWebinarCost)}} €</h1>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <h3>{{totalWebinar}} {{ totalWebinar === 1 ? ( 'webinar sold ' | translate ) : ( 'webinar sold' | translate )}}</h3>
                </mat-card-footer>
            </mat-card>
            <mat-card class="matcard-row" [style.background-color]="darkService.isSetDark ? '' : 'white'">
                <mat-card-title>
                    {{'Paid courses' | translate }}
                </mat-card-title>
                <mat-card-content>
                    <div class="price">
                        <mat-icon class="mat-card-icon">book</mat-icon>
                        <div class="stats">
                            <h1>{{formattedNumber(totalCoursesCost)}} €</h1>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <h3>{{totalCourses}} {{ totalCourses === 1 ? ( 'course sold' | translate ) : ( 'courses sold' | translate ) }}</h3>
                </mat-card-footer>
            </mat-card>
            <mat-card class="matcard-row" [style.background-color]="darkService.isSetDark ? '' : 'white'">
                <mat-card-title>
                    {{ 'Paid packages' | translate }}
                </mat-card-title>
                <mat-card-content>
                    <div class="price">
                        <mat-icon class="mat-card-icon">inventory_2</mat-icon>
                        <div class="stats" >
                            <h1>{{formattedNumber(totalPackagesCost)}} €</h1>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <h3>{{totalPackages}} {{ totalPackages === 1 ? ( 'package sold' | translate ) : 'packages sold' | translate }}</h3>
                </mat-card-footer>
            </mat-card>
            <mat-card *ngIf="selectedObject === 0" class="matcard-row" [style.background-color]="darkService.isSetDark ? '#142634' : 'lightblue'">
                <mat-card-title>
                    {{'Fee streaming webinar' | translate }}
                </mat-card-title>
                <mat-card-content>
                    <div class="price">
                        <mat-icon class="mat-card-icon">record_voice_over</mat-icon>
                        <div class="stats">
                            <div *ngIf="isAdmin() && selectedCustomer === 0" class="stats">
                                <h1>{{ formattedNumber(webinarStreamingCost) }} €</h1>
                            </div>
                            <div class="stats" *ngIf="isCustomerAdmin() || (isAdmin() && selectedCustomer !== 0)">
                                <h1>{{ formattedNumber(webinarStreamingCost) }} €</h1>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <div *ngIf="isAdmin() && selectedCustomer === 0">
                        <h3>{{formattedNumber(totalAllMonthTime / 3600) ?? 0.0}} {{ totalAllMonthTime === 3600 ? ( 'hour spent' | translate) : ( 'hours spent' | translate )}}</h3>
                    </div>
                    <div *ngIf="isCustomerAdmin() || (isAdmin() && selectedCustomer !== 0)">
                        <h3>{{formattedNumber(totalMonthTime / 3600) ?? 0.0 }} {{ totalMonthTime === 3600 ? ( 'hour spent' | translate) :  ( 'hours spent' | translate )}}</h3>
                    </div>
                </mat-card-footer>
            </mat-card>
            <mat-card class="matcard-row" [style.background-color]="darkService.isSetDark ? 'LightSlateGrey' : '#AED6F1'">
                <mat-card-title>
                    {{'Total collected' | translate }}
                </mat-card-title>
                <mat-card-content>
                    <div style="display:flex;justify-content:center;" class="total">
                        <div class="stats">
                            <h1>{{formattedNumber(totalPrice)}} €</h1>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="matcard-row" *ngIf="selectedObject === 0" [style.background-color]="darkService.isSetDark ? '#142634' : 'lightblue'">
                <mat-card-title>
                    {{'Total fee' | translate }}
                </mat-card-title>
                <mat-card-content>
                    <div style="display:flex;justify-content:center;" class="total">
                        <div class="stats">
                            <h1>{{formattedNumber(totalFee)}} €</h1>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card>

    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" color="primary"></mat-paginator>

    <mat-table #table multiTemplateDataRows matSort [dataSource]="dataSource" >
        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Date' | translate }}</mat-header-cell>
             <mat-cell *matCellDef="let element;">{{ element.created | date: 'dd MMMM, yyyy'  }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ element.user.name + ' ' + element.user.surname }}</mat-cell> 
        </ng-container>

        <ng-container matColumnDef="product">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ getObjectType(element) | translate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="cartItemName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span>{{ element.cartItemName }}</span>
            </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Amount' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ formattedNumber(element.amount) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="currency">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Currency' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.currency }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="paymentType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Payment type' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ getPaymentType(element) | translate }}</mat-cell> 
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>


</div>

{
    "author": "OpenVidu",
    "dependencies": {
        "freeice": "2.2.2",
        "hark": "1.2.3",
        "jsnlog": "2.30.0",
        "mime": "3.0.0",
        "platform": "1.3.6",
        "semver": "7.3.8",
        "uuid": "9.0.0",
        "wolfy87-eventemitter": "5.2.9",
        "events": "3.3.0",
        "inherits": "2.0.4"
    },
    "description": "OpenVidu Browser",
    "devDependencies": {
        "@types/node": "18.11.9",
        "@types/platform": "1.3.4",
        "browserify": "17.0.0",
        "grunt": "1.5.3",
        "grunt-cli": "1.4.3",
        "grunt-contrib-copy": "1.0.0",
        "grunt-contrib-sass": "2.0.0",
        "grunt-contrib-uglify": "5.2.2",
        "grunt-contrib-watch": "1.1.0",
        "grunt-postcss": "0.9.0",
        "grunt-string-replace": "1.3.3",
        "grunt-ts": "6.0.0-beta.22",
        "terser": "5.15.1",
        "tsify": "5.0.4",
        "tslint": "6.1.3",
        "typedoc": "0.23.21",
        "typescript": "4.9.3"
    },
    "license": "Apache-2.0",
    "main": "lib/index.js",
    "name": "openvidu-browser",
    "repository": {
        "type": "git",
        "url": "git://github.com/OpenVidu/openvidu"
    },
    "scripts": {
        "browserify": "VERSION=${VERSION:-dev}; mkdir -p static/js/ && cd src && ../node_modules/browserify/bin/cmd.js Main.ts -p [ tsify ] --exclude kurento-browser-extensions --debug -o ../static/js/openvidu-browser-$VERSION.js -v",
        "browserify-prod": "VERSION=${VERSION:-dev}; mkdir -p static/js/ && cd src && ../node_modules/browserify/bin/cmd.js --debug Main.ts -p [ tsify ] --exclude kurento-browser-extensions | ../node_modules/terser/bin/terser --source-map content=inline --output ../static/js/openvidu-browser-$VERSION.min.js",
        "build": "cd src/OpenVidu && ./../../node_modules/typescript/bin/tsc && cd ../.. && ./node_modules/typescript/bin/tsc --declaration src/index.ts --outDir ./lib --sourceMap --target es5 --lib dom,es5,es2015.promise,scripthost && rm -rf ./ts4.4 && mkdir -p ./ts4.4/lib && cp -r ./lib ./ts4.4 && find ./ts4.4/lib -type f ! -iname '*.d.ts' -delete && ./config/replace_for_ts44.sh",
        "docs": "./generate-docs.sh"
    },
    "types": "lib/index.d.ts",
    "typesVersions": {
        "<4.4": {
            "*": [
                "ts4.4/*"
            ]
        }
    },
    "version": "2.27.0"
}

import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentService } from '../service/payment.service';

@Component({
  selector: 'app-start-onboarding',
  templateUrl: './start-onboarding.component.html',
  styleUrls: ['./start-onboarding.component.scss']
})
export class StartOnboardingComponent implements OnInit {

  constructor(private auth: AuthService, private paymentservice: PaymentService) { }
  currentUser : User;
  stripeID : any;
  stripeid : string;
  btndis : boolean = false;
  
  ngOnInit(): void {
    this.getcustomerstripeId();
  }

  createStripeacct(){
    this.paymentservice.getnewlycreateStripeLink().subscribe(result => {
      this.stripeID = result;
      console.log(this.stripeID);
      this.stripeid = result.id;
      this.btndis = true;
     
    });

    
  }

  getstripeacctLink(){
    this.currentUser = this.auth.getCurrentUser();
    this.paymentservice.getStripeLink(this.stripeid).subscribe(result => {
      // this.stripeID = result;
     window.location.href = result.url;
    });

  }

  getcustomerstripeId(){
    
    this.paymentservice.getStripeacct_customer().subscribe(result => {
      if(result.stripeAccountId != null){
          this.stripeid = result.stripeAccountId;
          console.log(result.stripeAccountId);
          this.btndis = true;
          
      }
    });
  }

}

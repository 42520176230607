import { Component, EventEmitter, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { StudentsVideoStatus } from 'src/app/models/studentsVideoStatus';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionEvent, Session, SignalEvent } from 'openvidu-browser';
import { SignalType } from 'src/app/models/conference-session/clientSignalType';
import { SignalRaiseHand } from 'src/app/models/conference-session/signalRaiseHand';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { ClientStream } from 'src/app/models/conference-session/clientStream';
import { BidirectionService } from 'src/app/services/bidirectional.service';

const NOTIFICATION_SOUND: string = "../../assets/sound/note.mp3";
const UPDATE_LIST_INTERVAL_TIME: number = 5000;

@Component({
  selector: 'app-user-list-video',
  templateUrl: './user-list-video.component.html',
  styleUrls: ['./user-list-video.component.scss']
})
export class UserListVideoComponent implements OnInit, OnDestroy {

  currentUser: User = undefined;

  userList: StudentsVideoStatus[] = [];

  private updateListInterval: any = undefined;

  private _session: Session;
  private _readNotifications: boolean = true;
  private _unreadNotifications: { userId: number, count: number }[] = [];
  private _notificationSound = new Audio(NOTIFICATION_SOUND);

  @Input()
  set session (value: Session) {

    if (!value)
      return;

    this._session?.off(SignalType.toggleHand);

    this._session = value;

    this._session.on(SignalType.toggleHand, (event: SignalEvent) => {

      let data: SignalRaiseHand = JSON.parse(event.data);
      let clientData: ClientData = JSON.parse(event.from.data);

      // Corrisponse al messaggio lowerAllHands
      if (data.userId == null) {

        if (clientData.role === 'publisher') {

          this.onCurrentUserHandRaised.emit(false);

          this.participants.forEach(p => p.handRaised = false);
          this.participantsChange.emit(this.participants);

          this._unreadNotifications = [];
          this.unread.emit(0);

        }

        return;
      }

      if (data.userId === this.currentUser.id)
        this.onCurrentUserHandRaised.emit(data.raise);

      this.participants.forEach(p => p.handRaised = p.userId === data.userId ? data.raise : p.handRaised);
      this.participantsChange.emit(this.participants);

      if (this._readNotifications)
        return;

      let nIndex = this._unreadNotifications.findIndex(n => n.userId === data.userId);

      if (data.raise) {

        nIndex === -1 ?
        this._unreadNotifications.push({ userId: data.userId, count: 1 }) :
        this._unreadNotifications[nIndex].count++;

        this.unread.emit(this._unreadNotifications.reduce((partialSum, n) => partialSum + n.count, 0));

        if (data.userId !== this.currentUser.id)
          this._notificationSound.play();

      } else if (this._unreadNotifications.length > 0) {

        if (nIndex === -1)
          return;

        this._unreadNotifications[nIndex].count--;

        this.unread.emit(this._unreadNotifications.reduce((partialSum, n) => partialSum + n.count, 0));
        
      }

    });

    this._session.on('connectionCreated', (event: ConnectionEvent) => {

      let data = <ClientData>JSON.parse(event.connection.data);

      if (data.mode === 'subject') {

        let index = this.userList.findIndex(u => u.user.id === data.userId);

        if (index !== -1) {
          this.userList[index].connection = event.connection;
          this.userList[index].actualState = 2;
        }

      }

      if (data.mode === 'share') {

        if (data.userId === this.currentUser.id)
          this.onCurrentUserHandRaised.emit(false);
  
        this.participants.forEach(p => p.handRaised = p.userId === data.userId ? false : p.handRaised);
        this.participantsChange.emit(this.participants);        
  
        let nIndex = this._unreadNotifications.findIndex(n => n.userId === data.userId);
  
        if (nIndex === -1)
          return;
  
        this._unreadNotifications.splice(nIndex, 1);
        this.unread.emit(this._unreadNotifications.reduce((partialSum, n) => partialSum + n.count, 0));

      }

    });

    this._session.on('connectionDestroyed', (event: ConnectionEvent) => {

      let clientData = <ClientData>JSON.parse(event.connection.data);

      if (clientData.mode !== 'subject')
        return;

      let index = this.userList.findIndex(u => u.connection?.connectionId === event.connection.connectionId);

      if (index !== -1) {
        this.userList[index].connection = undefined;
        this.userList[index].actualState = 1;
      }
      
      let nIndex = this._unreadNotifications.findIndex(n => n.userId === clientData.userId);

      if (nIndex !== -1) {
        this._unreadNotifications.splice(nIndex, 1);
        this.unread.emit(this._unreadNotifications.reduce((partialSum, n) => partialSum + n.count, 0));
      }

    });

  }

  @Input()
  set read (value: boolean) {
    
    this._readNotifications = value;

    if (this._readNotifications) {
      this._unreadNotifications = [];
      this.unread.emit(0);
    }
      
  }

  @Input()
  lowerAllHandsActive: boolean = false;

  @Input()
  lowerHandEnabled: boolean = false;

  @Input()
  participants: ClientStream[] = [];

  @Input()
  classId: number;

  @Input()
  lessonId: number;

  @Input()
  title: string = 'Participants';

  @Output()
  unread: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  participantsChange: EventEmitter<ClientStream[]> = new EventEmitter<ClientStream[]>();

  @Output()
  onCurrentUserHandRaised: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onLowerAllHands: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onLowerHand: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onAskToStartBidirectional: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onAskToStopBidirectional: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private calendar: CalendarService,
    private auth: AuthService,
    private bidirectionalService: BidirectionService
  ) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.fetchUsers();

    this.updateListInterval = setInterval(() => this.fetchUsers(), UPDATE_LIST_INTERVAL_TIME);
  }

  ngOnDestroy(): void {
    if (this.updateListInterval)
      clearInterval(this.updateListInterval);

    this._session?.off(SignalType.toggleHand);

    this.participants = [];
    this._readNotifications = true;
    this._unreadNotifications = [];
  }

  fetchUsers() {
    this.calendar.getStudentsVideoStatus(this.classId, this.lessonId)
      .subscribe(output => {
        
        let users = output.filter(u => u.user.id !== this.currentUser.id);

        if (this.userList.length === 0) {
          this.userList = users.slice();
          return;
        }

        users.map(u => {
          if (u.actualState >= 2)
            return;

          let i = this.userList.findIndex(ul => ul.user.id === u.user.id);

          if (i === -1) {
            this.userList.push(u);
            return;
          }

          if (this.userList[i].actualState === 2)
            return;

          this.userList[i].actualState = u.actualState;
        });

      });
  }

  getColor(actualState: number) {
    if (actualState === 1)
      return 'rgba(54, 162, 235, 0.5)'; // Blu
    if (actualState === 2)
      return 'rgba(255, 99, 132, 0.5)'; // Rosso

    return 'rgba(55, 59, 66, 0.5)'; // Grigio
  }

  isHandRaised(userId: number) {
    return this.participants.find(p => p.userId === userId)?.handRaised ?? false;
  }

  lowerHand(userId: number) {
    if (this.lowerHandEnabled)
      this.onLowerHand.emit(userId);
  }

  isBidirectionalConnected(userId: number) {
    return this.bidirectionalService.status === 'connected'
        && this.bidirectionalService.remoteClient?.userId === userId;
  }

  async askBidirectional(userId: number, type: 'start' | 'stop') {
    type === 'start' ?
    await this.bidirectionalService.askToStart(userId) :
    await this.bidirectionalService.askToStop(userId);
  }

}

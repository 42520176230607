<div class="btn-container">

  <button mat-fab extended
    (click)="onMainButtonClick.emit(true)"
    [disabled]="disabled"
    [color]="color"
    class="btn-left">

    <mat-icon *ngIf="icon">
      {{ icon }}
    </mat-icon>

    {{ text }}

  </button>

  <button mat-fab
    [matMenuTriggerFor]="dropdownMenu"
    [disabled]="disabled"
    [color]="color"
    class="btn-right">

    <mat-icon>
      arrow_drop_down
    </mat-icon>

  </button>

</div>

<mat-menu #dropdownMenu="matMenu">

  <button mat-menu-item
    *ngFor="let option of options"
    (click)="itemClick(option.value)"
    [disabled]="option.disabled">

    <mat-icon *ngIf="option.icon">
      {{ option.icon }}
    </mat-icon>

    {{ option.text }}

  </button>

</mat-menu>


<h1 mat-dialog-title>{{ popupSettings.title }}</h1>
<mat-dialog-content style="white-space: pre-line">
    {{ popupSettings.body }}

    <div *ngIf="popupSettings.showLoading" style="display: flex; justify-content: center;">
        <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
    </div>

</mat-dialog-content>
<mat-dialog-actions
    *ngIf="!popupSettings.hideCancelBtn || !popupSettings.hideOkBtn || popupSettings.showGoBtn"
    [fxLayoutAlign]="popupSettings.btnAlign">

    <button mat-stroked-button *ngIf="!popupSettings.hideCancelBtn" (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" *ngIf="!popupSettings.hideOkBtn" (click)="closePopUp()" cdkFocusInitial>Ok</button>
    <button mat-flat-button color="primary" *ngIf="popupSettings.showGoBtn" (click)="goToPage(popupSettings.page)">{{ 'Leave' | translate }}</button>

</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { ClassroomService } from 'src/app/services/classroom.service';
import { Classroom } from 'src/app/models/classroom';
import { Router, NavigationExtras } from '@angular/router';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { firstValueFrom, from } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AddClassroomComponent } from './add-classroom/add-classroom.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.scss']
})
export class ClassroomComponent implements OnInit {

  currentUser: User;

  customers: any[] = [];
  classrooms: Classroom[];
  currentCustomerId: number = null;
  currentCustomerName: string = null;

  constructor(
    private auth: AuthService,
    private classService: ClassroomService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.getClassrooms();
  }

  getClassrooms() {
    this.classService.getClassrooms(
      undefined,
      undefined,
      this.currentUser.isCustomerAdmin ? this.currentUser.idCustomer : undefined,
      true)
      .subscribe(classrooms => {
        this.classrooms = classrooms as Classroom[];
        this.classrooms.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)

        this.groupByClients(this.classrooms);
      })
  }

  goToClassroom(classroom: Classroom) {
    let navigationExtras: NavigationExtras;

    if (classroom.author != null)
      navigationExtras = {
        queryParams: {
          title: classroom.name,
          calendar: classroom.googleCalendar,
          idAuthor: classroom.idAuthor,
          created: classroom.created,
          authorName: `${classroom.author.name} ${classroom.author.surname}`
        }
      }
    else
      navigationExtras = {
        queryParams: {
          title: classroom.name,
          calendar: classroom.googleCalendar,
          idAuthor: classroom.idAuthor,
          created: classroom.created
        }
      }

    this.router.navigate(['/classroom', classroom.id], navigationExtras);
  }

  addClassroom() {
    const dialogRef = this.dialog.open(AddClassroomComponent,
    {
      width: '600px',
      data: {
        customerName: this.currentUser.isAdmin ? this.currentCustomerName : undefined,
        customerId: this.currentUser.isAdmin ? this.currentCustomerId : undefined
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.getClassrooms();
    });
  }

  editClassroom(classroom: Classroom) {
    const dialogRef = this.dialog.open(AddClassroomComponent,
    {
      width: '600px',
      data: {
        customerName: this.currentUser.isAdmin ? this.currentCustomerName : undefined,
        customerId: this.currentUser.isAdmin ? this.currentCustomerId : undefined,
        classroom: classroom
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.getClassrooms();
    });
  }

  async deleteClassroom(id: number) {
    let entities = await firstValueFrom(this.classService.getConnectedEntitiesOfClassroom(id));

    let body = await this.translate.instant('Are you sure you want to delete this class?');

    if (entities?.connectedEntities > 0) {

      body = `${await this.translate.instant('At the moment there are value entities connected to this classrooms, are you sure to delete it?', { value: entities.connectedEntities })}\n`;

      if (entities.lessons?.length > 0)
        body += `\n${await this.translate.instant('Lessons')}: ${entities.lessons.length}`;

      if (entities.courses?.length > 0)
        body += `\n${await this.translate.instant('Courses')}: ${entities.courses.length}`;

      if (entities.conferences?.length > 0)
        body += `\n${await this.translate.instant('Conferences')}: ${entities.conferences.length}`;

      if (entities.webinars?.length > 0)
        body += `\n${await this.translate.instant('Webinar')}: ${entities.webinars.length}`;

      if (entities.socialWalls?.length > 0)
        body += `\n${await this.translate.instant('Posts')}: ${entities.socialWalls.length}`;

    }

    let warningDialog = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: 'Warning',
          body: body
        }
      });

    warningDialog.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.classService.deleteClassroom(id)
        .subscribe(() => this.getClassrooms());
    });
  }

  groupByClients(data: Classroom[]) {
    this.customers = [];

    if (!data || data.length === 0) {
      this.setCustomer(undefined);
      return;
    }

    from(data)
      .pipe(
        groupBy(classroom => classroom.customerName),
        mergeMap(group => group.pipe(toArray()))
      )
      .subscribe(val => this.customers.push(val))
      .add(() => this.setCustomer(this.customers[0]));
  }

  setCustomer(classroom?: Classroom) {
    this.currentCustomerId = classroom?.idCustomer ?? this.currentUser.idCustomer;
    this.currentCustomerName = classroom?.customerName ?? this.currentUser.customerName;
  }

  openUserStatus(classroom: Classroom) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        classroomName: classroom.name
      }
    };

    if (classroom.author != null)
      navigationExtras = {
        queryParams: {
          classroomName: classroom.name,
          created: classroom.created,
          authorName: `${classroom.author.name} ${classroom.author.surname}`
        }
      };

    this.router.navigate(['/classroomstatus', classroom.id], navigationExtras);
  }

}

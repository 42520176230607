<app-mobile-warning
  *ngIf="isMobile; else desktopLogin"
  [idCustomer]="idCustomer"
  [codeCustomer]="customerCode"
  [showUsername]="false"
  [showDlgs]="true"
  [showMobileApps]="false"
  [registerBtnText]="registerBtnText"
  [registeredText]="registeredText"
  [playStoreUrl]="playStoreUrl"
  [appStoreUrl]="appStoreUrl"
  [logo]="logo"
  background="background-color: rgba(66, 66, 66, 255)">
</app-mobile-warning>

<ng-template #desktopLogin>
  <div class="sfondo">
    
    <mat-grid-list cols="5" rowHeight="100%">

      <mat-grid-tile [colspan]="3" [rowspan]="1" >
        <div fxFlex fxLayoutAlign="center center" >
          <img style="width:70%; max-width: 70%;" [src]="backgroundImage">
        </div>
      </mat-grid-tile>
      
      <mat-grid-tile [colspan]="2" [rowspan]="1" style="overflow:auto">
          
        <div fxLayout="column" fxLayoutAlign="start" style="width: 100%">

          <div style="width: 400px; text-align: center;">
            <h2 class="title">La formazione digitale selezionata per te da Dental Trey!</h2>
          </div>

          <mat-card
            appearance="outlined"
            style="width: 400px"
            class="mat-elevation-z8">

            <mat-card-content fxLayout="column" fxLayoutGap="20px">

              <app-access-form
                *ngIf="isLoginMode()"
                [idCustomer]="idCustomer">
              </app-access-form>

              <app-register-form
                *ngIf="isSignUpMode()"
                (result)="changeMode('login')"
                [codeCustomer]="customerCode"
                [showUsername]="false"
                [showDlgs]="true">
              </app-register-form>

              <mat-divider></mat-divider>

              <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

                <div *ngIf="isSignUpMode()">
                  <button mat-stroked-button (click)="changeMode('login')">
                    {{ 'Log In' | translate }}
                  </button>
                </div>

                <div *ngIf="isLoginMode()">
                  <button mat-stroked-button (click)="changeMode('signup')">
                    {{ 'Sign Up'| translate }}
                  </button>
                </div> 
      
              </div>
            </mat-card-content>

          </mat-card>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';
import { InvitedUser } from '../models/invitedUser';
import { CustomerDemoPost } from '../models/customerDemoPost';
import { ProvinceDTO } from '../models/dto/provinceDTO';
import { QualificationDTO } from '../models/dto/qualificationDTO';
import { UserDTO } from '../models/dto/userDTO';
import { UserRole } from '../models/userRole';
import { Observable } from 'rxjs';
import { ClassroomDTO } from '../models/dto/classroomDTO';
import { UserResultDTO } from '../models/dto/userResultDTO';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    bckEndUrlUsers = `${environment.apiUrl}/api/users`;

    constructor(private http: HttpClient) { }

    getAll(
        roles?: UserRole[],
        customerId?: number,
        state?: number,
        oldObj?: boolean
    ) {
        let params = new HttpParams();

        if (roles != undefined)
            params = params.append('roles', roles.join(','));

        if (customerId != undefined)
            params = params.append('customerId', customerId);

        if (state != undefined)
            params = params.append('state', state);

        if (oldObj != undefined)
            params = params.append('oldObj', oldObj);

        return this.http.get<UserDTO[] | User[]>(`${this.bckEndUrlUsers}/all`, { headers: Helper.getAuthHeader(), params: params });
    }

    get(id: number, last?: boolean, oldObj?: boolean): Observable<UserDTO | User> {
        let params = new HttpParams();

        if (last != undefined)
            params = params.append('last', last);

        if (oldObj != undefined)
            params = params.append('oldObj', oldObj);

        return this.http.get<UserDTO | User>(`${this.bckEndUrlUsers}/${id}`, { params: params, headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    updateUser(user: User, id: number) {
        return this.http.put(`${this.bckEndUrlUsers}/${id}`, user, { headers: Helper.getAuthHeader() });
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/api/classrooms/lessons/${id}`, { headers: Helper.getAuthHeader() });
    }

    deleteUser(id: number) {
        return this.http.delete(`${this.bckEndUrlUsers}/${id}`, { headers: Helper.getAuthHeader() });
    }

    enableUser(id: number) {
        return this.http.put(`${this.bckEndUrlUsers}/enable/${id}`, '', {headers: Helper.getAuthHeader() });
    }

    addUser(user: User) {
        return this.http.post(`${this.bckEndUrlUsers}`, user, { headers: Helper.getAuthHeader() });
    }

    addPublicUser(user) {
        return this.http.post(`${this.bckEndUrlUsers}/publicuser`, user);
    }

    updatePassword(currentPassword: string, newPassword: string) {
        return this.http.put(`${this.bckEndUrlUsers}/specs`,
            {
                currentPassword: currentPassword,
                password: newPassword,
                token: ""
            },
            { headers: Helper.getAuthHeader() });
    }

    forgotPassword(usernameEmail: string) {
        return this.http.post(`${this.bckEndUrlUsers}/forgotpassword`, { emailUsername: usernameEmail });
    }

    forgotChangePassword(password: string, token: string) {
        return this.http.put(`${this.bckEndUrlUsers}/forgotpassword`,
        {
            password: password,
            token: token
        });
    }

    forgotToken(token: string) {
        return this.http.get(`${this.bckEndUrlUsers}/forgottoken/${token}`);
    }

    hasSignalr(userId?: number) {
        return this.http.get(`${this.bckEndUrlUsers}/hasSignalR`,
                             { 
                                headers: Helper.getAuthHeader(),
                                params: userId ? new HttpParams().set('userId', userId) : null });
    }

    inactiveUsers() {
        return this.http.get<any>(`${this.bckEndUrlUsers}/disabled`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    addPublicUserLevel(idSubscriptionLevel: number, user: User) {
        return this.http.post(`${this.bckEndUrlUsers}/subscriptionLevel/${idSubscriptionLevel}`, user);
    }

    inviteUser(invitedUser: InvitedUser) {
        return this.http.post(`${this.bckEndUrlUsers}/invite`, invitedUser, { headers: Helper.getAuthHeader() });
    }

    requestDemo(customerDemoPost: CustomerDemoPost) {
        return this.http.post(`${this.bckEndUrlUsers}/subscriptionLevel/demo`, customerDemoPost);
    }
    
    getProvinces() {
        return this.http.get<ProvinceDTO[]>(`${this.bckEndUrlUsers}/provinces`);
    }

    getQualifications() {
        return this.http.get<QualificationDTO[]>(`${this.bckEndUrlUsers}/qualifications`);
    }

    getUserClassrooms(userId: number) {
        return this.http.get<ClassroomDTO[]>(`${this.bckEndUrlUsers}/${userId}/classrooms`, { headers: Helper.getAuthHeader() });
    }

    getUserResults(userId: number) {
        return this.http.get<UserResultDTO[]>(`${this.bckEndUrlUsers}/${userId}/results`, { headers: Helper.getAuthHeader() });
    }

}

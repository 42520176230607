<div fxLayoutAlign="center center">
    <img loading="lazy"
        *ngIf="src"
        [src]="src"
        [style.height]="height"
        [style.width]="width"
        [style.object-fit]="fit"
        [style.border-radius]="radius"
        [matTooltip]="tooltip"
        [matTooltipDisabled]="tooltip == null">
</div>

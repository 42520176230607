import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, NgZone, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith, take } from 'rxjs/operators';
import { SocialWallBlogDTO } from '../models/dto/SocialWallBlogDTO';
import { SocialWallContentDTO } from '../models/dto/SocialWallContentDTO';
import { SocialWallDTO } from '../models/dto/SocialWallDTO';
import { SocialWallLikeDTO } from '../models/dto/SocialWallLikeDTO';
import { AzureStorageService } from '../services/storage.service';
import { DialogdispuserlikeComponent } from './extensioncomponent/dialogdispuserlike.component';
import { WallService } from './service/wall.service';
import { User } from '../models/user';
import linkifyHtml from 'linkify-html';
import { ClassroomService } from '../services/classroom.service';
import { CustomerDTO } from '../models/dto/customerDTO';
import { SocialWallAdditionalCustomerDTO } from '../models/dto/SocialWallAdditionalCustomerDTO';
import { SocialWallClassroomDTO } from '../models/dto/SocialWallClasroomDTO';
import { DarkThemeService } from '../services/dark-theme.service';
import { AuthService } from '../services/auth.service';
import { SelectionMode, SelectionService } from '../services/selection.service';
import { firstValueFrom, Observable } from 'rxjs';
import { Helper } from '../helpers/helper';
import { GenericPopupComponent, GenericPopupData } from '../popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WallPopupComponent } from './wall-popup/wall-popup.component';
import { WallBlogPopupComponent } from './wall-blog-popup/wall-blog-popup.component';
import { ClassroomDTO } from '../models/dto/classroomDTO';
import { CourseService } from '../services/course.service';
import { ContentHelper } from '../helpers/contentHelper';

@Component({
  selector: "app-wall",
  templateUrl: "./wall.component.html",
  styleUrls: ["./wall.component.scss"],
})
export class WallComponent implements OnInit {

  @Input() classroomId: number = -1;

  currentUser: User;

  socialWallArr: SocialWallDTO[] = [];

  visibility: FormControl<number> = new FormControl<number>(0, { nonNullable: true });
  postTitle: FormControl<string> = new FormControl<string>('', [Validators.required, Validators.maxLength(256)]);
  postText: FormControl<string> = new FormControl<string>('', [Validators.required]);
  txtComment: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  selectCustomer: FormControl<number[]> = new FormControl<number[]>([], { nonNullable: true });
  selectClassroom: FormControl<number[]> = new FormControl<number[]>([], { nonNullable: true });
  hashTag: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  filePicture: any[] = [];
  fileVideo: any[] = [];

  attachLink: SocialWallContentDTO[] = [];
  attachEtcLink: SocialWallContentDTO[] = [];

  classrooms: ClassroomDTO[] = [];
  customersArr: CustomerDTO[] = [];
  hashTags: string[] = [];
  filteredHashTags: Observable<string[]>;

  lastSocialWallId: number = undefined;

  public get userInputLinks() {
    return `futurepost:${this.postTitle.value} : ${this.postText.value}`;
  }

  @ViewChild("autosize") autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  constructor(
    private _ngZone: NgZone,
    private auth: AuthService,
    private wallService: WallService,
    private azureService: AzureStorageService,
    private dialog: MatDialog,
    private selectionService: SelectionService,
    private classService: ClassroomService,
    private courseService: CourseService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private socialWall: WallService,
    private darkService: DarkThemeService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.getSocialWall();
    this.getClassRoom();
    this.getHashTags();

    if (this.route.snapshot.queryParamMap.has('selection') && Boolean(this.route.snapshot.queryParamMap.get('selection')) === true)
      this.getCourseLinks();

    if (this.currentUser.isAdmin || this.currentUser.isCustomerAdmin || this.currentUser.isAuthor)
      this.getCustomer();

    let prevTitle = sessionStorage.getItem('prevSocialWallTitle');
    let prevText = sessionStorage.getItem('prevSocialWallText');
    let prevVisibility = sessionStorage.getItem('prevSocialWallVisibility');

    if (prevTitle) {
      this.postTitle.setValue(prevTitle);
      sessionStorage.removeItem('prevSocialWallTitle');
    }

    if (prevText) {
      this.postText.setValue(prevText);
      sessionStorage.removeItem('prevSocialWallText');
    }

    if (prevVisibility) {
      this.visibility.setValue(Number(prevVisibility));
      sessionStorage.removeItem('prevSocialWallVisibility');
    }

    this.filteredHashTags = this.hashTag.valueChanges.pipe(
      startWith(''),
      map(value => this.hashTags.filter(hashTag => hashTag.toLowerCase().includes(value.toLowerCase())))
    );
  }

  getSocialWall() {
    this.wallService
      .getSocialWallAllChild(this.classroomId, this.lastSocialWallId)
      .subscribe((output) => {

        output.forEach(ele => {
          ele.isLikeUser = 0;
          ele.likeid = 0;

          ele.socialWallLikes = ele.socialWallLikes.map(like => {

            if (like.iduser === this.currentUser.id) {
              like.iduserNavigation.name = this.translate.instant("You");
              ele.isLikeUser = 1;
              ele.likeid = like.id;
            }

            return like;

          });

          ele.SocialWallBlogChild = ele.socialWallBlogs.filter(blog => blog.idparent !== 1);
          ele.socialWallBlogs = ele.socialWallBlogs.filter(blog => blog.idparent === 1);

          this.socialWallArr.push(ele);

          //set last socialwallid
          this.lastSocialWallId = ele.id;
        });

        if (this.socialWallArr.length < 20 && output.length > 0)
          this.getSocialWall();

      });
  }

  async postSocialWall() {
    let socialWallDTO = new SocialWallDTO();

    socialWallDTO.idauthor = this.currentUser.id;

    socialWallDTO.title = linkifyHtml(this.postTitle.value, {
      defaultProtocol: 'https',
      target: '_blank'
    });

    socialWallDTO.postText = linkifyHtml(this.replaceH3WithH4(this.postText.value), {
      defaultProtocol: 'https',
      target: '_blank'
    });

    socialWallDTO.visibility = this.visibility.value;
    socialWallDTO.socialWallAdditionalCustomers = this.selectCustomer.value.map(sc => <SocialWallAdditionalCustomerDTO>{ idCustomer: sc });
    socialWallDTO.socialWallClassrooms = this.selectClassroom.value.map(sc => <SocialWallClassroomDTO>{ idClassroom: sc });
    socialWallDTO.socialWallBlogs = null;
    socialWallDTO.socialWallLikes = null;

    this.wallService.postSocialWal(socialWallDTO).subscribe(async output => {
      this.socialWallArr.unshift(output);

      this.socialWallArr.forEach(async (ele) => {

        if (ele.id != output.id)
          return;

        ele.SocialWallBlogChild = [];
        ele.socialWallLikes = [];
        ele.socialWallBlogs = [];
        ele.likeid = 0;
        ele.isLikeUser = 0;

        let filesToUpload: { file: File, type: number }[] = [
          ...this.filePicture.map(fp => <{ file: File, type: number }>{ file: fp, type: 1 }),
          ...this.fileVideo.map(fv => <{ file: File, type: number }>{ file: fv, type: 2 })
        ];

        filesToUpload.forEach(async ftu => {

          let res = await this.azureService.uploadFile(ftu.file);

          let socialWallContent = new SocialWallContentDTO();
          socialWallContent.idsocialWall = ele.id;
          socialWallContent.contentLink = res;
          socialWallContent.type = ftu.type;

          this.wallService.postSocialWallContent(socialWallContent).subscribe(async (output) => {

            this.socialWallArr.forEach((ele) => {
              if (ele.id != output.idsocialWall)
                return;

              ele.socialWallContents.push(output);
            });
          });

        });

        let linksToUpload: { link: SocialWallContentDTO, type: number }[] = [
          ...this.attachLink.map(al => <{ link: SocialWallContentDTO, type: number }>{ link: al, type: 0 }),
          ...this.attachEtcLink.map(ael => <{ link: SocialWallContentDTO, type: number }>{ link: ael, type: 3 })
        ];

        linksToUpload.forEach(ltu => {

          let socialWallContent = new SocialWallContentDTO();
          socialWallContent.idsocialWall = ele.id;
          socialWallContent.contentLink = ltu.link.contentLink;
          socialWallContent.description = linkifyHtml(ltu.link.description, {
            defaultProtocol: 'https',
            target: '_blank'
          });
          socialWallContent.type = ltu.type;

          this.wallService.postSocialWallContent(socialWallContent).subscribe((output) => {

            this.socialWallArr.forEach((ele) => {
              if (ele.id != output.idsocialWall)
                return;

              ele.socialWallContents.push(output);
            });
          });

        });

        //clear attachments
        this.filePicture = [];
        this.fileVideo = [];
        this.attachLink = [];
        this.attachEtcLink = [];

        this.selectionService.clearBackupElements();
      });

      this.resetForm();
    });
  }

  resetForm(fromInput: boolean = false) {
    if (fromInput && this.postText.valid)
      return;

    this.postTitle.reset('');
    this.postText.reset('');
    this.visibility.reset();
    this.selectClassroom.reset();
    this.selectCustomer.reset();
  }

  writeBlog(socialWallId: number) {
    if (Helper.isNullOrEmpty(this.txtComment.value))
      return;

    let socialWallBlogDTO = new SocialWallBlogDTO();

    socialWallBlogDTO.idauthor = this.currentUser.id;
    socialWallBlogDTO.idsocialWall = socialWallId;
    socialWallBlogDTO.idparent = 1;
    socialWallBlogDTO.postContent = this.txtComment.value;
    socialWallBlogDTO.postContent = linkifyHtml(socialWallBlogDTO.postContent, {
      defaultProtocol: 'https',
      target: "_blank"
    });

    this.wallService
      .postBlog(socialWallBlogDTO)
      .subscribe(() => {

        this.txtComment.reset();

        let i = this.socialWallArr.findIndex(s => s.id === socialWallId);

        if (i === -1)
          return;

        this.wallService
          .getSocialWallBlogBySocialWallId(socialWallId)
          .subscribe(blogs => {

            this.socialWallArr[i].SocialWallBlogChild = blogs.filter(blog => blog.idparent !== 1);
            this.socialWallArr[i].socialWallBlogs = blogs.filter(blog => blog.idparent === 1);

          });

      });
  }

  replyBlog(blog: SocialWallBlogDTO) {
    let dialogRef = this.dialog.open(WallBlogPopupComponent, {
      width: '600px',
      disableClose: true,
      data: {
        mode: 'reply',
        blog: blog
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (!result)
        return;

      this.txtComment.reset();

      let i = this.socialWallArr.findIndex(s => s.id === blog.idsocialWall);

      if (i === -1)
        return;

      this.wallService
        .getSocialWallBlogBySocialWallId(blog.idsocialWall)
        .subscribe(blogs => {

          this.socialWallArr[i].SocialWallBlogChild = blogs.filter(blog => blog.idparent !== 1);
          this.socialWallArr[i].socialWallBlogs = blogs.filter(blog => blog.idparent === 1);

        });

    });
  }

  likePost(socialWallId: number) {
    let socialWallLikeDTO = new SocialWallLikeDTO();

    socialWallLikeDTO.idsocialWall = socialWallId;

    this.wallService
      .postSocialWallLike(socialWallLikeDTO)
      .subscribe(() => {

        let i = this.socialWallArr.findIndex(s => s.id === socialWallId);

        if (i === -1)
          return;

        this.wallService
          .getSocialWallLikeBySocialWallId(socialWallId)
          .subscribe(likes => {

            this.socialWallArr[i].isLikeUser = 0;
            this.socialWallArr[i].likeid = 0;

            this.socialWallArr[i].socialWallLikes = likes.map(like => {

              if (like.iduser === this.currentUser.id) {
                like.iduserNavigation.name = this.translate.instant("You");
                this.socialWallArr[i].isLikeUser = 1;
                this.socialWallArr[i].likeid = like.id;
              }

              return like;

            });

          });

    });
  }

  unlikePost(socialWallLikeId: number, socialWallId: number) {
    this.wallService
      .deleteSocialWallLike(socialWallLikeId)
      .subscribe(() => {

        let i = this.socialWallArr.findIndex(s => s.id === socialWallId);

        if (i === -1)
          return;

        this.wallService
          .getSocialWallLikeBySocialWallId(socialWallId)
          .subscribe(likes => {

            this.socialWallArr[i].isLikeUser = 0;
            this.socialWallArr[i].likeid = 0;

            this.socialWallArr[i].socialWallLikes = likes.map(like => {

              if (like.iduser === this.currentUser.id) {
                like.iduserNavigation.name = this.translate.instant("You");
                this.socialWallArr[i].isLikeUser = 1;
                this.socialWallArr[i].likeid = like.id;
              }

              return like;

            });

          });

      });
  }

  showWhoLike(socialWallId: number) {
    this.dialog.open(DialogdispuserlikeComponent, { data: { socialWallId: socialWallId } });
  }

  async onFileChange(event: any) {
    let file = event.target.files[0];

    //let fileUrl = await Helper.fileToBase64(file);
    //let fileName = file.name;

    if (file.type.match('image.*')) {
      this.filePicture.push(file);
    } else if (file.type.match('video.*')) {
      this.fileVideo.push(file);
    }
  }

  removeAttachment(item: any, type: 'picture' | 'video' | 'link' | 'etcLink') {
    let arr = type === 'picture'
            ? this.filePicture
            : type === 'video'
            ? this.fileVideo
            : type === 'link'
            ? this.attachLink
            : type === 'etcLink'
            ? this.attachEtcLink
            : [];

    let i = arr.indexOf(item);

    if (i !== -1)
      arr.splice(i, 1);
  }

  addCourseLink() {
    sessionStorage.setItem('prevSocialWallTitle', this.postTitle.value);
    sessionStorage.setItem('prevSocialWallText', this.postText.value);
    sessionStorage.setItem('prevSocialWallVisibility', String(this.visibility.value));
    sessionStorage.setItem('prevSocialWallClassrooms', JSON.stringify(this.selectClassroom.value));
    sessionStorage.setItem('prevSocialWallCustomers', JSON.stringify(this.selectCustomer.value));

    this.selectionService.startSelection(null, SelectionMode.socialWallContents);
  }

  getCourseLinks() {
    this.attachEtcLink = this.selectionService.backupElements.map(be => {

      let attachEtcLink = new SocialWallContentDTO();

      attachEtcLink.contentLink = String(be.originEntity.id);
      attachEtcLink.description = be.originEntity.name;
      attachEtcLink.type = 3;

      return attachEtcLink;

    });
  }

  needCarousel(item: SocialWallDTO) {
    return this.countCarousel(item) > 0;
  }

  countCarousel(item: SocialWallDTO) {
    return item.socialWallContents.filter(content => content.type === 1 || content.type === 2).length;
  }

  getCarouselSlides(item: SocialWallDTO, type: number) {
    return item.socialWallContents.filter(content => content.type === type);
  }

  getClassRoom() {
    this.classrooms = [];

    this.classService
      .getClassrooms()
      .subscribe(classrooms => {

        this.classrooms = classrooms as ClassroomDTO[];

        let prevClassrooms = sessionStorage.getItem('prevSocialWallClassrooms');
      
        if (prevClassrooms) {
          this.selectClassroom.setValue(JSON.parse(prevClassrooms));
          sessionStorage.removeItem('prevSocialWallClassrooms');
        }

      });
  }

  async goToEtcContent(url: string) {
    if (url.includes('/course-content/')) {

      let id = Number(url.split('/').pop());

      try {

        let content = await firstValueFrom(this.courseService.getContentById(id));

        let course = await firstValueFrom(this.courseService.getCourseContent(content.idCourse, this.auth.isAuthenticated()));

        let ch = new ContentHelper(
          this.router,
          this.dialog,
          this.courseService,
          this.currentUser,
          content,
          course.courseContent,
          course.idAuthor,
          course.mode
        );

        ch.goTo();

      } catch (e) {
        console.error(e);
      }

      return;

    }

    let goTo = url.includes('/course/')
             ? '/course'
             : url.includes('/master/')
             ? '/master'
             : undefined;

    if (!goTo)
      return;

    this.router.navigate([goTo, url.split('/').pop()]);
  }

  getCustomer() {
    this.wallService
      .getAllCustomer()
      .subscribe(customers => {

        this.customersArr = customers;

        let prevCustomers = sessionStorage.getItem('prevSocialWallCustomers');

        if (prevCustomers) {
          this.selectCustomer.setValue(JSON.parse(prevCustomers));
          sessionStorage.removeItem('prevSocialWallCustomers');

          return;
        }

        this.selectCustomer
          .setValue(
            this.customersArr
              .filter(item => item.id === this.currentUser.idCustomer)
              .map(item => item.id)
            );

      });
  }

  postBtnDisabled(): boolean {
    let check = !this.postText.valid
             || !this.postTitle.valid;

    if (this.visibility.value === 0)
      return check
          || this.selectClassroom.value.length === 0;

    if (this.visibility.value === 1)
      return check
          || this.selectCustomer.value.length === 0;

    return check;
  }

  getHashTags() {
    this.wallService.getHastags()
      .subscribe(output => this.hashTags = output);
  }

  getHtml(text: string) {
    if (Helper.isNullOrEmpty(text))
      return text;

    let header = text.substring(0, text.indexOf("</h3>"));
    let headerWithoutHtml = header.replace(/(<([^>]+)>)/ig, "");

    if (Helper.isNullOrEmpty(headerWithoutHtml))
      return headerWithoutHtml;

    return Helper.clean(headerWithoutHtml);
  }

  getDescription(text: string) {
    if (Helper.isNullOrEmpty(text))
      return text;

    let indexOfH3 = text.indexOf('</h3>');

    return Helper.clean(indexOfH3 !== -1 ? text.substring(indexOfH3 + 5) : text);
  }

  async editPost(socialWallPost: SocialWallDTO) {
    this.dialog.open(WallPopupComponent, {
      width: '600px',
      data: socialWallPost
    });
  }

  async deletePost(postId: number) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete Post')),
        body:  await firstValueFrom(this.translate.get(`Are you sure you want to delete this post?`))
      }
    });

    dialogRef
      .afterClosed()
      .subscribe(result => {

        if (!result)
          return;
        
        this.wallService
          .deleteSocialWallPost(postId)
          .subscribe({
            next: async () => {
              this.snackBar.open(await firstValueFrom(this.translate.get('Post deleted')), undefined, { duration: 3000 });
              this.socialWallArr = this.socialWallArr.filter(s => s.id !== postId);
              this.getSocialWall();
            },
            error: err => {
              this.snackBar.open('Error deleting post', undefined, {duration: 3000})
              console.log(err);
            }
          });
      });
  }

  async deleteBlog(blog: SocialWallBlogDTO) {
    const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Delete comment')),
          body: await firstValueFrom(this.translate.get('Are you sure you want to delete this comment?'))
        }
      });
  
      dialogRef.afterClosed().subscribe(async res => {
        if (!res)
          return;
  
        this.socialWall
          .deleteBlog(blog.id)
          .subscribe({
            next: async () => {
              this.snackBar.open(await firstValueFrom(this.translate.get('Comment deleted')), undefined, { duration: 3000 });

              let i = this.socialWallArr.findIndex(s => s.id === blog.idsocialWall);

              if (i === -1)
                return;

              this.wallService
                .getSocialWallBlogBySocialWallId(blog.idsocialWall)
                .subscribe(blogs => {

                  this.socialWallArr[i].SocialWallBlogChild = blogs.filter(blog => blog.idparent !== 1);
                  this.socialWallArr[i].socialWallBlogs = blogs.filter(blog => blog.idparent === 1);

                });
            },
            error: err => {
              this.snackBar.open(err.Message);
              console.log(err);
            }
          });
      });
  }

  editBlog(blog: SocialWallBlogDTO) {
    this.dialog.open(WallBlogPopupComponent, {
      width: '600px',
      data: {
        mode: 'edit',
        blog: blog
      }
    });
  }

  extractLinksFromText(inputText: string) {
    if (Helper.isNullOrEmpty(inputText))
      return null;

    let matches = inputText.match(/href="([^"]*)/g);

    if (!matches)
      return null;

    return matches.join(' ');
  }

  isDark() {
    return this.darkService.isSetDark;
  }

  // Utilizza una RegEx per cercare <h3>...</h3> all'inizio del testo
  replaceH3WithH4(text: string) {
    return text.replace(/^<h3>(.*?)<\/h3>/, (match, capturedText) => {
      if (match === `<h3>${capturedText}</h3>`)
        return `<h4>${capturedText}</h4>`;
      
      return match;
    });
  }

  getBlogChildren(blogId: number, blogs: SocialWallBlogDTO[]) {
    return blogs.filter(c => c.idparent === blogId) ?? [];
  }

  goToAuthorProfile(id: number) {
    this.router.navigate(['/authorprofile/', id]);
  }

}

@Pipe({ name: 'hashTagsFilter' })
export class HashTagsPipe implements PipeTransform {
  transform(items: any[], text: string): any[] {

    if (!items)
      return [];

    if (!text || text.length === 0)
      return items;

    let hashTag = text.toLowerCase();

    return items.filter(elem => {
      let res = elem.hashTags?.toLowerCase().includes(hashTag) ?? false;

      if (elem.socialWallContents)
        elem.socialWallContents.filter((content: SocialWallContentDTO) => {
          res = res || (content.hashTags?.toLowerCase().includes(hashTag) ?? false);
        });

      if (elem.socialWallBlogs)
        elem.socialWallBlogs.filter((blog: SocialWallBlogDTO) => {
          res = res || (blog.hashTags?.toLowerCase().includes(hashTag) ?? false);
        });

      return res;
    });
  }
}

import { UserDTO } from "./dto/userDTO";

export class Classroom {
    id: number;
    idCustomer: number;
    customerName: string;
    customerDesc: string;
    name: string;
    description: string;
    state: string;
    idAuthor?: number;
    googleCalendar: boolean;
    created: Date;

    countStudents: number;
    countTeachers: number;
    isTeacher: boolean;
    isStudent: boolean;

    author?: UserDTO;
}

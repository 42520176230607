<h1 mat-dialog-title>{{ (webinarToEdit ? 'Edit webinar' : 'Create a new webinar') | translate }}</h1>

<div mat-dialog-content>

    <mat-stepper #stepper (selectedIndexChange)="stepperIndex = $event">

        <mat-step>

            <div class="stepper-gap">
                <app-translations-form
                    [label]="'Title' | translate"
                    [disabled]="sending"
                    [(translation)]="nameTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Title' | translate }}</mat-label>
                        <input matInput autofocus [formControl]="nameForm" type="text">
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>
    
                <div [formGroup]="dateForm" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'Start' | translate }}</mat-label>
                        <input matInput
                            [type]="getShowDateOrTime() ? 'datetime-local' : 'time'"
                            [style.color]="dateForm.controls.startDateForm.hasError('futureDate') ? 'red' : '' "
                            formControlName="startDateForm">
                        <mat-error *ngIf="dateForm.controls.startDateForm.hasError('futureDate')">
                            {{ 'The date must be a future day' | translate }} 
                        </mat-error>
                        <mat-error *ngIf="dateForm.controls.startDateForm.hasError('required')">
                            {{ 'The date is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'End' | translate }}</mat-label>
                        <input matInput
                            type="time"
                            formControlName="endDateForm"
                            [style.color]="dateForm.controls.endDateForm.hasError('dateDifference') || dateForm.controls.endDateForm.hasError('durationLimit') ? 'red' : '' ">
                        <mat-error *ngIf="dateForm.controls.endDateForm.hasError('required')">
                            {{ 'The date is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="dateForm.controls.endDateForm.hasError('dateDifference')">
                            {{ 'The difference in dates must' | translate }} <strong> {{ 'not be negative' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="dateForm.controls.endDateForm.hasError('durationLimit')">
                            {{ 'The conference can last a maximum of value hours' | translate: { value: maxDurationHours() } }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <h3 fxFlex>{{ 'Moderator' | translate }} </h3>
                    <mat-form-field fxFlex>
                        <mat-label >{{ 'Choose an option' | translate }}</mat-label>
                        <mat-select [formControl]="moderatorForm" (selectionChange)="moderatorSelected()">
                            <mat-option>
                                <ngx-mat-select-search
                                    [formControl]="moderatorSearchForm"
                                    [placeholderLabel]="'Search' | translate"
                                    [noEntriesFoundLabel]="'No data matching the word' | translate">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let user of tutors | search: moderatorSearchForm.value: ['name', 'surname']"
                                [style.color]="darkService.isSetDark ? 'white' : ''" 
                                [value]="user.id"> 
                                {{ user.name }} {{ user.surname }} 
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="moderatorForm.hasError('required')">
                            {{ 'The choose is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field> 
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <h3 fxFlex>{{ 'Speakers list' | translate }}</h3>
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'Choose an option' | translate }}</mat-label>
                        <mat-select [formControl]="presenterListForm" multiple>
                            <mat-option>
                                <ngx-mat-select-search
                                    [formControl]="presenterListSearchForm"
                                    [placeholderLabel]="'Search' | translate"
                                    [noEntriesFoundLabel]="'No data matching the word' | translate">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let user of speakers | search: presenterListSearchForm.value: ['name', 'surname']"
                                [disabled]="presenterOptionDisabled(user.id)"
                                [value]="user.id"
                                [style.color]="darkService.isSetDark ? 'white' : ''">
                                {{ user.name }} {{ user.surname }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="presenterListForm.hasError('required')">
                            {{ 'The choose is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
    
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid">
                    <div *ngFor="let user of getSelectedUsers()" style="font-size: 14px">
                        {{ user.name }} {{ user.surname }}
                    </div>
                </div>
            </div>
            
        </mat-step>

        <mat-step>

            <div class="stepper-gap" style="gap: 0px">
                <app-translations-form
                    [label]="'Header' | translate"
                    [disabled]="sending"
                    [(translation)]="headerTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Header' | translate }}</mat-label>
                        <input matInput autofocus [formControl]="headerForm" type="text">
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>
                <app-translations-form
                    [label]="'Description' | translate"
                    [disabled]="sending"
                    [mode]="'textarea'"
                    [(translation)]="descriptionTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Description' | translate }}</mat-label>
                        <textarea matInput [formControl]="descriptionForm" style="height: 100px"></textarea>
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>
                <app-translations-form
                    [label]="'Footer' | translate"
                    [disabled]="sending"
                    [(translation)]="footerTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Footer' | translate }}</mat-label>
                        <input matInput autofocus [formControl]="footerForm" type="text">
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>
                <app-translations-form
                    [label]="'Showcase Summary' | translate"
                    [disabled]="sending"
                    [mode]="'textarea'"
                    [(translation)]="summaryTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Showcase Summary' | translate }}</mat-label>
                        <textarea matInput [formControl]="summaryForm" style="height: 100px"></textarea>
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>

                </app-translations-form>

            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">
                <div fxLayout="row">
                    <mat-checkbox matInput
                        [formControl]="recordForm"
                        fxFlex="50">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <mat-icon color="warn">fiber_manual_record</mat-icon>
                            <span style="font-size: larger;" [matTooltip]="'Enable recording for the session' | translate">{{ 'Record' | translate }}</span>
                        </div>
                    </mat-checkbox>
                    <mat-checkbox matInput
                        [formControl]="isPrivateForm"
                        fxFlex>
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <mat-icon>group</mat-icon>
                            <span style="font-size: larger;" [matTooltip]="'Change visibility' | translate">{{ 'Private' | translate }}</span>
                        </div>
                    </mat-checkbox>
                </div>
    
                <div fxLayout="column" *ngIf="isPrivateForm.value === true">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                        <h3 fxFlex>{{ 'Classrooms list' | translate }} </h3>
                        <mat-form-field fxFlex>
                            <mat-label>{{ 'Choose an option' | translate }}</mat-label>
                            <mat-select [formControl]="classroomsListForm" multiple>
                                <mat-option>
                                    <ngx-mat-select-search
                                        [formControl]="classroomListSearchForm"
                                        [placeholderLabel]="'Search' | translate"
                                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let class of classrooms | search: classroomListSearchForm.value: ['name', 'description']"
                                    [style.color]="darkService.isSetDark ? 'white' : ''"   
                                    [value]="class.id">
                                    {{ class.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="classroomsListForm.hasError('required')">
                                {{ 'The choose is' | translate }} <strong>{{ 'required' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid">
                        <div *ngFor="let classroom of getSelectedClassrooms()" style="font-size: 14px">
                            {{ classroom.name }}
                        </div>
                    </div> 
                </div>
            </div>
            
        </mat-step>

        <mat-step>

            <div class="stepper-gap">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div fxFlex>
                        <mat-label>{{ 'Picture' | translate }}</mat-label>
                        <app-file-input
                            [control]="imageSrc"
                            [maxSize]="2000"
                            [acceptedTypes]="'image/*'"
                            [aspectRatio]="8/5"
                            [maxHeight]="2000"
                            [maxWidth]="2000"
                            [enableCrop]="true"
                            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '8:5', height: '2000px', width: '2000px', size: '2MB' }"
                            [deletedErrorMessage]="'Please select a file' | translate"
                            (acceptedFile)="setPicturePreview()"
                            (deletedFile)="setPicturePreview()"
                            (croppingFile)="$event ? desktopPreview = undefined : setPicturePreview()"
                            style="width: 100%">
                        </app-file-input>
                    </div>
                    <app-picture-preview
                        *ngIf="desktopPreview"
                        [src]="desktopPreview"
                        [tooltip]="'Current picture' | translate"
                        mode="desktop"
                        fxFlex="50">
                    </app-picture-preview>
                </div>

            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div fxFlex>
                        <mat-label>{{ 'Mobile app picture' | translate }}</mat-label>
                        <app-file-input
                            [control]="mobileImageSrc"
                            [maxSize]="1000"
                            [acceptedTypes]="'image/*'"
                            [aspectRatio]="1/1"
                            [maxHeight]="800"
                            [maxWidth]="800"
                            [enableCrop]="true"
                            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '1:1', height: '800px', width: '800px', size: '1MB' }"
                            [deletedErrorMessage]="'Please select a file' | translate"
                            (acceptedFile)="setPicturePreview()"
                            (deletedFile)="setPicturePreview()"
                            (croppingFile)="$event ? mobilePreview = undefined : setPicturePreview()"
                            style="width: 100%">
                        </app-file-input>
                    </div>
                    <app-picture-preview
                        *ngIf="mobilePreview"
                        [src]="mobilePreview"
                        [tooltip]="'Current mobile app picture' | translate"
                        mode="mobile"
                        fxFlex="50">
                    </app-picture-preview>
                </div>

            </div>

        </mat-step>

    </mat-stepper>

</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>

    <div fxLayout="row">
        <button mat-stroked-button
            *ngIf="stepperIndex !== 0"
            (click)="stepper.previous()">
            {{ 'Back' | translate }}
        </button>
        <button mat-flat-button color="primary"
            *ngIf="stepperIndex !== 4"
            [disabled]="nextBtnDisabled()"
            (click)="stepper.next()">
            {{ 'Next' | translate }}
        </button>
        <button mat-flat-button color="accent"
            *ngIf="stepperIndex === 4"
            (click)="saveWebinar()"
            [disabled]="okBtnDisabled()">
            Ok
        </button>
    </div>
</div>

<h1 mat-dialog-title>Smily Check</h1>
<div mat-dialog-content>
    <mat-list role="list" *ngIf="questions">
        <mat-list-item *ngFor="let q of questions" role="listitem">
            <div style="margin-bottom: 10px;" mat-line>
                <mat-checkbox  [(ngModel)]="q.yesNo" (change)="checkAnswers()">{{q.question}}</mat-checkbox>
            </div>
            <div style="margin-left: 24px;" mat-line>
                <input matInput *ngIf="!q.yesNo" [(ngModel)]="q.response" (ngModelChange)="checkAnswers()" placeholder="{{ 'Describe the problem...' | translate }}">
            </div>
        </mat-list-item>
    </mat-list>
</div>
<div mat-dialog-actions>
    <!--button mat-button (click)="onNoClick()">{{ 'Cancel' | translate}}</button-->
    <button mat-flat-button color="accent" (click)="closePopUp()" [disabled]="!state">Ok</button>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialProviders, SocialShare } from 'src/app/helpers/socialshare';

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss']
})
export class SharePopupComponent implements OnInit {

  // ---- AGGIUNTA ICONE ---- //
  // Per visualizzare le icone di altri brand andare
  // nel costruttiore dell'AppModule ed inserire nella funzione
  // library.addIcons() le icone mancanti
  // Per la lista di icone fare riferimento a
  // FontAwesome free brand icons (https://fontawesome.com/)

  // ---- ATTENZIONE ---- //
  // Aggiungere SOLO le icone necessarie, aggiungere la libreria completa
  // aumenta di molto l'utilizzo delle risorse

  buttons: SocialButtons[] = [
    { provider: SocialProviders.facebook, icon: ['fab', 'facebook'] },
    { provider: SocialProviders.twitter, icon: ['fab', 'twitter'] },
    { provider: SocialProviders.linkedin, icon: ['fab', 'linkedin'] },
    { provider: SocialProviders.pinterest, icon: ['fab', 'pinterest'] },
    { provider: SocialProviders.whatsapp, icon: ['fab', 'whatsapp'] },
    { provider: SocialProviders.telegram, icon: ['fab', 'telegram'] },
    { provider: SocialProviders.skype, icon: ['fab', 'skype'] }
  ];

  title: string = null;

  constructor(private dialogRef: MatDialogRef<SharePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.title = this.data.title;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  share(provider: string) {
    SocialShare.share(<SocialProviders>provider, this.title ? `Checkout ${this.title}` : null);
    
    this.onNoClick();
  }
}

class SocialButtons {
  provider: SocialProviders;
  icon: string[] = [];
}

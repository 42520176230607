import { Component, OnInit } from '@angular/core';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ConferenceService } from 'src/app/services/conference.service';

@Component({
  selector: 'app-webinar-customer',
  templateUrl: './webinar-customer.component.html',
  styleUrls: ['./webinar-customer.component.scss']
})
export class WebinarCustomerComponent implements OnInit {

  currentUser: User;
  webinars: ConferenceDTO[] = [];

  constructor(
    private conferenceService: ConferenceService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.getWebinars();
  }

  getWebinars(): void {
    this.conferenceService
      .getWebinarCustomer(
        undefined,
        this.currentUser.isAdmin || this.currentUser.isCustomerAdmin ? this.currentUser.idCustomer : undefined)
      .subscribe({
        next: output => this.webinars = output,
        error: err => console.log(err)
      });
  }

  isCustomerAdmin() {
    return this.currentUser.isCustomerAdmin;
  }

}

export class SurveyAnswerDTO {
    id: number;
    idLesson: number;
    idUser: number;
    state: number;
    surveyJSON: string;
    created: Date;
    score: number;
    scoreA: number;
    scoreNote: string;
    scoreDate?: Date;
}

/* 
public class SurveyAnswerDTO
{
    public int Id { get; set; }
    public int IdLesson { get; set; }
    public int IdUser { get; set; }
    public int State { get; set; }
    public string SurveyJSON { get; set; }
    public DateTime Created { get; set; }
}
 */
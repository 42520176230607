import * as i0 from '@angular/core';
import { Component, Input, ViewChild, ElementRef, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/forms';
import { FormsModule } from '@angular/forms';
import * as i1 from 'survey-angular-ui';
import { BaseAngular, AngularComponentFactory, SurveyContentComponent, ButtonGroupQuestionComponent, EmbeddedViewContentComponent, QuestionAngular, ActionBarItemComponent, SurveyModule } from 'survey-angular-ui';
import { ResponsivityManager, VerticalResponsivityManager, DropdownListModel, RendererFactory } from 'survey-core';
import { ToolboxToolViewModel, PageAdorner, PageNavigatorViewModel, editorLocalization, SurveyResultsModel, QuestionAdornerViewModel, ItemValueWrapperViewModel, ImageItemValueWrapperViewModel, QuestionDropdownAdornerViewModel, QuestionImageAdornerViewModel, StringEditorViewModelBase, editableStringRendererName, initLogicOperator, MatrixCellWrapperViewModel, RowViewModel, QuestionRatingAdornerViewModel, LogoImageViewModel } from 'survey-creator-core';
const _c0 = a0 => ({
  model: a0
});
const _c1 = (a0, a1) => ({
  name: a0,
  data: a1
});
function TabbedMenuItemWrapperComponent_ng_template_0_ng_template_2_Template(rf, ctx) {}
function TabbedMenuItemWrapperComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 1)(1, "div", 2);
    i0.ɵɵtemplate(2, TabbedMenuItemWrapperComponent_ng_template_0_ng_template_2_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.model.css);
    i0.ɵɵclassProp("sv-action--hidden", !ctx_r0.model.isVisible);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(7, _c1, ctx_r0.model.component || "svc-tabbed-menu-item", i0.ɵɵpureFunction1(5, _c0, ctx_r0.model)));
  }
}
const _c2 = ["container"];
function TabbledMenuComponent_ng_template_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-tabbed-menu-item-wrapper", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const action_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", action_r1);
  }
}
function TabbledMenuComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2, 1);
    i0.ɵɵtemplate(2, TabbledMenuComponent_ng_template_0_ng_container_2_Template, 2, 1, "ng-container", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.model.renderedActions);
  }
}
function SidebarTabComponent_ng_template_0_ng_container_0_ng_template_1_Template(rf, ctx) {}
function SidebarTabComponent_ng_template_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, SidebarTabComponent_ng_template_0_ng_container_0_ng_template_1_Template, 0, 0, "ng-template", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(3, _c1, ctx_r0.model.componentName, i0.ɵɵpureFunction1(1, _c0, ctx_r0.model.model)));
  }
}
function SidebarTabComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SidebarTabComponent_ng_template_0_ng_container_0_Template, 2, 6, "ng-container", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r0.model.visible);
  }
}
function SidebarComponent_ng_template_0_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.model.headerText);
  }
}
function SidebarComponent_ng_template_0_ng_container_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-side-bar-tab", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const tab_r3 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", tab_r3);
  }
}
function SidebarComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "div", 3);
    i0.ɵɵlistener("click", function SidebarComponent_ng_template_0_Template_div_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.collapseSidebar());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "div", 4)(3, "div", 5, 1)(5, "div", 6)(6, "div", 7);
    i0.ɵɵelement(7, "sv-action-bar", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, SidebarComponent_ng_template_0_div_8_Template, 2, 1, "div", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "div", 10);
    i0.ɵɵtemplate(10, SidebarComponent_ng_template_0_ng_container_10_Template, 2, 1, "ng-container", 11);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("svc-flyout-side-bar", ctx_r1.model.flyoutPanelMode);
    i0.ɵɵproperty("visible", ctx_r1.model.hasVisibleTabs);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("visible", ctx_r1.model.visible);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("model", ctx_r1.model.toolbar);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !!ctx_r1.model.headerText);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.model.tabs);
  }
}
function CreatorComponent_ng_container_0_div_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17);
    i0.ɵɵelement(1, "sv-action-bar", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("visible", ctx_r0.creator.showToolbar);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.creator.toolbar);
  }
}
function CreatorComponent_ng_container_0_ng_container_15_div_1_ng_template_1_Template(rf, ctx) {}
function CreatorComponent_ng_container_0_ng_container_15_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵtemplate(1, CreatorComponent_ng_container_0_ng_container_15_div_1_ng_template_1_Template, 0, 0, "ng-template", 20);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const tab_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("svc-creator__toolbox--right", ctx_r0.creator.toolboxLocation == "right");
    i0.ɵɵattribute("id", "scrollableDiv-" + tab_r2.id);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(6, _c1, tab_r2.componentContent, i0.ɵɵpureFunction1(4, _c0, tab_r2.data.model)));
  }
}
function CreatorComponent_ng_container_0_ng_container_15_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CreatorComponent_ng_container_0_ng_container_15_div_1_Template, 2, 9, "div", 18);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const tab_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.creator.viewType == tab_r2.id && tab_r2.visible);
  }
}
function CreatorComponent_ng_container_0_div_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 21)(1, "div", 17);
    i0.ɵɵelement(2, "sv-action-bar", 8);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("visible", ctx_r0.creator.isMobileView);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.creator.footerToolbar);
  }
}
function CreatorComponent_ng_container_0_div_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "svc-side-bar", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("sv-mobile-side-bar", ctx_r0.creator.isMobileView);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.creator.sidebar);
  }
}
function CreatorComponent_ng_container_0_div_18_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 22);
    i0.ɵɵelement(1, "span", 23);
    i0.ɵɵpipe(2, "safeHtml");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind1(2, 1, ctx_r0.creator.licenseText), i0.ɵɵsanitizeHtml);
  }
}
function CreatorComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "sv-ng-modal-container");
    i0.ɵɵelementStart(2, "div", 2, 0)(4, "div");
    i0.ɵɵelement(5, "sv-svg-bundle");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 3)(7, "div", 4)(8, "div", 5)(9, "div", 6)(10, "div", 7);
    i0.ɵɵelement(11, "svc-tabbed-menu", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(12, CreatorComponent_ng_container_0_div_12_Template, 2, 2, "div", 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "div", 10)(14, "div", 11);
    i0.ɵɵtemplate(15, CreatorComponent_ng_container_0_ng_container_15_Template, 2, 1, "ng-container", 12);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(16, CreatorComponent_ng_container_0_div_16_Template, 3, 2, "div", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(17, CreatorComponent_ng_container_0_div_17_Template, 2, 3, "div", 14);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(18, CreatorComponent_ng_container_0_div_18_Template, 3, 3, "div", 15);
    i0.ɵɵelement(19, "sv-notifier", 16);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("svc-creator--mobile", ctx_r0.creator.isMobileView)("svc-creator--touch", ctx_r0.creator.isTouch);
    i0.ɵɵadvance(4);
    i0.ɵɵclassProp("svc-creator__area--with-banner", !ctx_r0.creator.haveCommercialLicense);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("svc-creator__side-bar--left", ctx_r0.creator.sidebarLocation == "left");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("visible", ctx_r0.creator.showTabs);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.creator.tabbedMenu);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.creator.showToolbar);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("svc-creator__content-wrapper--footer-toolbar", ctx_r0.creator.isMobileView);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r0.creator.tabs);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.creator.isMobileView);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.creator.sidebar);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.creator.haveCommercialLicense);
    i0.ɵɵadvance();
    i0.ɵɵproperty("notifier", ctx_r0.creator.notifier);
  }
}
const _c3 = (a0, a1, a2, a3) => ({
  model: a0,
  viewModel: a1,
  creator: a2,
  isCompact: a3
});
const _c4 = (a0, a1) => ({
  name: a0,
  default: "svc-toolbox-item",
  data: a1
});
function ToolboxToolComponent_ng_template_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 4);
  }
}
function ToolboxToolComponent_ng_template_0_ng_template_3_Template(rf, ctx) {}
function ToolboxToolComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ToolboxToolComponent_ng_template_0_div_1_Template, 1, 0, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵlistener("pointerdown", function ToolboxToolComponent_ng_template_0_Template_div_pointerdown_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.onPointerDown($event));
    })("mouseover", function ToolboxToolComponent_ng_template_0_Template_div_mouseover_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.onMouseOver(ctx_r1.item, $event));
    })("mouseleave", function ToolboxToolComponent_ng_template_0_Template_div_mouseleave_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.onMouseLeave(ctx_r1.item, $event));
    });
    i0.ɵɵtemplate(3, ToolboxToolComponent_ng_template_0_ng_template_3_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.itemCssClasses);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.item.needSeparator && !ctx_r1.creator.toolbox.showCategoryTitles);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(9, _c4, ctx_r1.model.itemComponent, i0.ɵɵpureFunction4(4, _c3, ctx_r1.item, ctx_r1.model, ctx_r1.creator, ctx_r1.isCompact)));
  }
}
function SearchComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "div", 3);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(3, "input", 5);
    i0.ɵɵtwoWayListener("ngModelChange", function SearchComponent_ng_template_0_div_0_Template_input_ngModelChange_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      i0.ɵɵtwoWayBindingSet(ctx_r1.model.filterString, $event) || (ctx_r1.model.filterString = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 6)(5, "div", 7);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(7, "sv-action-bar", 8);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("iconName", "icon-search")("size", "auto");
    i0.ɵɵadvance();
    i0.ɵɵtwoWayProperty("ngModel", ctx_r1.model.filterString);
    i0.ɵɵattribute("aria-label", ctx_r1.model.filterStringPlaceholder)("placeholder", ctx_r1.model.filterStringPlaceholder);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.model.matchCounterText);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model.searchActionBar);
  }
}
function SearchComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SearchComponent_ng_template_0_div_0_Template, 8, 7, "div", 1);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.model.isVisible);
  }
}
function ToolboxCategoryComponent_ng_template_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 7)(2, "svg", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-arrow-down")("size", 24)("visible", ctx_r1.category.collapsed);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-arrow-up")("size", 24)("visible", !ctx_r1.category.collapsed);
  }
}
function ToolboxCategoryComponent_ng_template_0_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-toolbox-tool", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("item", item_r3)("creator", ctx_r1.toolbox.creator)("parentModel", ctx_r1.toolbox)("isCompact", false);
  }
}
function ToolboxCategoryComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵlistener("click", function ToolboxCategoryComponent_ng_template_0_Template_div_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.category.toggleState());
    });
    i0.ɵɵelementStart(2, "span", 3);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, ToolboxCategoryComponent_ng_template_0_div_4_Template, 3, 6, "div", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, ToolboxCategoryComponent_ng_template_0_ng_container_5_Template, 2, 4, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("svc-toolbox__category--collapsed", ctx_r1.category.collapsed)("svc-toolbox__category--empty", ctx_r1.category.empty);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("svc-toolbox__category-header--collapsed", ctx_r1.toolbox.canCollapseCategories);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.category.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.toolbox.canCollapseCategories);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.category.items);
  }
}
function AdaptiveToolboxComponent_ng_template_0_div_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-toolbox-tool", 10)(2, "div", 11);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("creator", ctx_r1.creator)("item", ctx_r1.searchItem)("parentModel", ctx_r1.model)("isCompact", ctx_r1.model.isCompactRendered);
  }
}
function AdaptiveToolboxComponent_ng_template_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtemplate(1, AdaptiveToolboxComponent_ng_template_0_div_4_ng_container_1_Template, 3, 4, "ng-container", 7);
    i0.ɵɵelement(2, "svc-search", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.isCompactRendered);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model.searchManager);
  }
}
function AdaptiveToolboxComponent_ng_template_0_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.model.toolboxNoResultsFound);
  }
}
function AdaptiveToolboxComponent_ng_template_0_ng_container_7_svc_toolbox_category_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-toolbox-category", 14);
  }
  if (rf & 2) {
    const category_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("category", category_r3)("toolbox", ctx_r1.model);
  }
}
function AdaptiveToolboxComponent_ng_template_0_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, AdaptiveToolboxComponent_ng_template_0_ng_container_7_svc_toolbox_category_1_Template, 1, 2, "svc-toolbox-category", 13);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.model.categories);
  }
}
function AdaptiveToolboxComponent_ng_template_0_ng_container_8_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-toolbox-tool", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r4 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("creator", ctx_r1.creator)("item", item_r4)("parentModel", ctx_r1.model)("isCompact", ctx_r1.model.isCompactRendered);
  }
}
function AdaptiveToolboxComponent_ng_template_0_ng_container_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 15);
    i0.ɵɵtemplate(2, AdaptiveToolboxComponent_ng_template_0_ng_container_8_ng_container_2_Template, 2, 4, "ng-container", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.model.renderedActions);
  }
}
function AdaptiveToolboxComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", null, 1)(2, "div", 2);
    i0.ɵɵlistener("focusout", function AdaptiveToolboxComponent_ng_template_0_Template_div_focusout_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.focusOut($event));
    });
    i0.ɵɵelementStart(3, "div", 3);
    i0.ɵɵlistener("scroll", function AdaptiveToolboxComponent_ng_template_0_Template_div_scroll_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.onScroll(ctx_r1.model, $event));
    });
    i0.ɵɵtemplate(4, AdaptiveToolboxComponent_ng_template_0_div_4_Template, 3, 2, "div", 4)(5, AdaptiveToolboxComponent_ng_template_0_div_5_Template, 2, 1, "div", 5);
    i0.ɵɵelementStart(6, "div", 6);
    i0.ɵɵtemplate(7, AdaptiveToolboxComponent_ng_template_0_ng_container_7_Template, 2, 1, "ng-container", 7)(8, AdaptiveToolboxComponent_ng_template_0_ng_container_8_Template, 3, 1, "ng-container", 7);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.model.classNames);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", ctx_r1.model.showSearch);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.showPlaceholder);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.model.showInSingleCategory);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.showInSingleCategory);
  }
}
function PageDesignerComponent_div_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6)(1, "div", 7)(2, "div", 8);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.adorner.placeholderText);
  }
}
function PageDesignerComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("click", function PageDesignerComponent_div_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    })("dblclick", function PageDesignerComponent_div_0_Template_div_dblclick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.dblclick($event));
    })("mouseover", function PageDesignerComponent_div_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    })("mouseleave", function PageDesignerComponent_div_0_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.target));
    });
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelement(2, "sv-action-bar", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "page", 4);
    i0.ɵɵtemplate(4, PageDesignerComponent_div_0_div_4_Template, 4, 1, "div", 5);
    i0.ɵɵelement(5, "sv-action-bar", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.adorner.css);
    i0.ɵɵproperty("id", ctx_r1.adorner.page.id);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.actionContainer);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model)("survey", ctx_r1.survey);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.showPlaceholder);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.adorner.footerActionsBar);
  }
}
const _c5 = (a0, a1, a2) => ({
  model: a0,
  creator: a1,
  survey: a2
});
const _c6 = a0 => ({
  name: "svc-page",
  data: a0
});
function DesignerPagesComponent_ng_template_0_ng_template_1_Template(rf, ctx) {}
function DesignerPagesComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, DesignerPagesComponent_ng_template_0_ng_template_1_Template, 0, 0, "ng-template", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵattribute("data-sv-drop-target-survey-element", ctx_r0.model.displayPageDropTarget)("data-sv-drop-target-page", ctx_r0.model.pagesController.page2Display.name);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction1(7, _c6, i0.ɵɵpureFunction3(3, _c5, ctx_r0.model.pagesController.page2Display, ctx_r0.creator, ctx_r0.survey)));
  }
}
function PageNavigatorItemComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵlistener("click", function PageNavigatorItemComponent_ng_template_0_Template_div_click_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.click($event));
    });
    i0.ɵɵelement(2, "div", 3);
    i0.ɵɵelementStart(3, "div", 4)(4, "span", 5);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(6, "span", 3);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassProp("svc-page-navigator-item--selected", ctx_r1.model.active)("svc-page-navigator-item--disabled", ctx_r1.anyModel.disabled);
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", ctx_r1.model.title);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.model.title);
  }
}
function PageNavigatorComponent_ng_template_0_svc_page_navigator_item_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-page-navigator-item", 7);
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    i0.ɵɵproperty("model", item_r3);
  }
}
function PageNavigatorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2, 1)(2, "div", 3);
    i0.ɵɵlistener("click", function PageNavigatorComponent_ng_template_0_Template_div_click_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.togglePageSelector($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(3, "svg", 4);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelement(4, "sv-ng-popup", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div");
    i0.ɵɵtemplate(6, PageNavigatorComponent_ng_template_0_svc_page_navigator_item_6_Template, 1, 1, "svc-page-navigator-item", 6);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("visible", ctx_r1.model.visible);
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("svc-page-navigator__selector--opened", ctx_r1.model.isPopupOpened);
    i0.ɵɵattribute("title", ctx_r1.model.pageSelectorCaption);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", ctx_r1.model.icon)("size", 24);
    i0.ɵɵadvance();
    i0.ɵɵproperty("popupModel", ctx_r1.model.popupModel);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.model.visibleItems);
  }
}
const _c7 = a0 => ({
  page: a0,
  isGhost: false
});
const _c8 = a0 => ({
  page: a0,
  isGhost: true
});
const _c9 = (a0, a1, a2, a3) => ({
  model: a0,
  creator: a1,
  survey: a2,
  isGhost: a3
});
function DesignerSurveyComponent_ng_template_0_div_1_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r0.survey.css.header);
    i0.ɵɵproperty("survey", ctx_r0.survey);
  }
}
function DesignerSurveyComponent_ng_template_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, DesignerSurveyComponent_ng_template_0_div_1_div_1_Template, 1, 3, "div", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.survey.renderedHasHeader);
  }
}
function DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_1_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_1_ng_container_1_ng_container_1_Template, 1, 0, "ng-container", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const page_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵnextContext(3);
    const pageContainer_r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", pageContainer_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c7, page_r2));
  }
}
function DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_1_ng_container_1_Template, 2, 4, "ng-container", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const page_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.model.showNewPage || page_r2 !== ctx_r0.model.newPage);
  }
}
function DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_2_ng_container_1_Template, 1, 0, "ng-container", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    const pageContainer_r3 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", pageContainer_r3)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c8, ctx_r0.model.newPage));
  }
}
function DesignerSurveyComponent_ng_template_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_1_Template, 2, 1, "ng-container", 9)(2, DesignerSurveyComponent_ng_template_0_ng_container_2_ng_container_2_Template, 2, 4, "ng-container", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r0.survey.pages);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.model.showNewPage && ctx_r0.model.newPage);
  }
}
function DesignerSurveyComponent_ng_template_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-designer-pages", 11);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model);
  }
}
function DesignerSurveyComponent_ng_template_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵelement(1, "svc-page-navigator", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("pagesController", ctx_r0.model.pagesController)("pageEditMode", ctx_r0.creator.pageEditMode);
  }
}
function DesignerSurveyComponent_ng_template_0_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14);
    i0.ɵɵelement(1, "sv-action-bar", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.actionContainer);
  }
}
function DesignerSurveyComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, DesignerSurveyComponent_ng_template_0_div_1_Template, 2, 1, "div", 2)(2, DesignerSurveyComponent_ng_template_0_ng_container_2_Template, 3, 2, "ng-container", 3)(3, DesignerSurveyComponent_ng_template_0_ng_container_3_Template, 2, 1, "ng-container", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, DesignerSurveyComponent_ng_template_0_div_4_Template, 2, 2, "div", 4)(5, DesignerSurveyComponent_ng_template_0_div_5_Template, 2, 1, "div", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(ctx_r0.creator.designTabSurveyThemeVariables);
    i0.ɵɵclassMap(ctx_r0.model.designerCss);
    i0.ɵɵstyleProp("max-width", ctx_r0.survey.renderedWidth);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.creator.allowEditSurveyTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.creator.pageEditMode !== "bypage");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.model.pagesController.page2Display && ctx_r0.creator.pageEditMode === "bypage");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.creator.showPageNavigator);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.model.hasToolbar);
  }
}
function DesignerSurveyComponent_ng_template_2_ng_template_1_Template(rf, ctx) {}
function DesignerSurveyComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtemplate(1, DesignerSurveyComponent_ng_template_2_ng_template_1_Template, 0, 0, "ng-template", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const page_r4 = ctx.page;
    const isGhost_r5 = ctx.isGhost;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵattribute("data-sv-drop-target-survey-element", isGhost_r5 ? "newGhostPage" : page_r4.name)("data-sv-drop-target-page", page_r4.name);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction1(8, _c6, i0.ɵɵpureFunction4(3, _c9, page_r4, ctx_r0.creator, ctx_r0.survey, isGhost_r5)));
  }
}
function DesignerTabComponent_ng_template_0_svc_adaptive_toolbox_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-adaptive-toolbox", 6);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("creator", ctx_r1.creator);
  }
}
function DesignerTabComponent_ng_template_0_ng_container_4_div_1_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 13);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵclassMap(ctx_r1.survey.css.header);
    i0.ɵɵproperty("survey", ctx_r1.survey);
  }
}
function DesignerTabComponent_ng_template_0_ng_container_4_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵtemplate(1, DesignerTabComponent_ng_template_0_ng_container_4_div_1_div_1_Template, 1, 3, "div", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.survey.renderedHasHeader);
  }
}
function DesignerTabComponent_ng_template_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, DesignerTabComponent_ng_template_0_ng_container_4_div_1_Template, 2, 1, "div", 7);
    i0.ɵɵelementStart(2, "div", 8)(3, "span", 9);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "svc-page", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.creator.showHeaderInEmptySurvey && ctx_r1.creator.allowEditSurveyTitle);
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-sv-drop-target-survey-element", "newGhostPage");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.model.placeholderText, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model.newPage)("survey", ctx_r1.survey)("creator", ctx_r1.creator);
  }
}
function DesignerTabComponent_ng_template_0_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-designer-survey", 14);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model);
  }
}
function DesignerTabComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, DesignerTabComponent_ng_template_0_svc_adaptive_toolbox_1_Template, 1, 1, "svc-adaptive-toolbox", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "div", 3);
    i0.ɵɵlistener("click", function DesignerTabComponent_ng_template_0_Template_div_click_2_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.clickDesigner());
    });
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵtemplate(4, DesignerTabComponent_ng_template_0_ng_container_4_Template, 6, 6, "ng-container", 5)(5, DesignerTabComponent_ng_template_0_ng_container_5_Template, 2, 1, "ng-container", 5);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.isToolboxVisible);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.model.getRootCss());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.model.showPlaceholder);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.model.showPlaceholder);
  }
}
function TabbedMenuItemComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("click", function TabbedMenuItemComponent_ng_template_0_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.action());
    });
    i0.ɵɵelementStart(1, "span", 2);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("svc-tabbed-menu-item--selected", ctx_r1.model.active)("svc-tabbed-menu-item--disabled", ctx_r1.model.disabled);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("svc-text--bold", ctx_r1.model.active);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.model.title, " ");
  }
}
function ObjectSelectorComponent_ng_template_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "sv-ng-list", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.list);
  }
}
function ObjectSelectorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ObjectSelectorComponent_ng_template_0_ng_container_0_Template, 2, 1, "ng-container", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r0.model.isVisible);
  }
}
function PropertyGridComponent_ng_template_0_survey_content_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "survey-content", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("model", ctx_r0.model.survey);
  }
}
function PropertyGridComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelement(1, "svc-search", 2);
    i0.ɵɵtemplate(2, PropertyGridComponent_ng_template_0_survey_content_2_Template, 1, 1, "survey-content", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("spg-container_search", ctx_r0.model.searchEnabled);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.searchManager);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !!ctx_r0.model.survey);
  }
}
const _c10 = ["inputEl"];
function TextareaJsonEditorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "div", 3)(2, "textarea", 4, 1);
    i0.ɵɵtwoWayListener("ngModelChange", function TextareaJsonEditorComponent_ng_template_0_Template_textarea_ngModelChange_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      i0.ɵɵtwoWayBindingSet(ctx_r1.model.text, $event) || (ctx_r1.model.text = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵlistener("keydown", function TextareaJsonEditorComponent_ng_template_0_Template_textarea_keydown_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.checkKey(ctx_r1.model, $event));
    });
    i0.ɵɵtext(4, "      ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div", 5);
    i0.ɵɵelement(6, "sv-ng-list", 6);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("disabled", ctx_r1.model.readOnly);
    i0.ɵɵtwoWayProperty("ngModel", ctx_r1.model.text);
    i0.ɵɵattribute("aria-label", ctx_r1.model.ariaLabel);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("visible", ctx_r1.model.hasErrors);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model.errorList);
  }
}
function AceJsonEditorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2)(1, "div", 3);
    i0.ɵɵelement(2, "div", 4, 1);
    i0.ɵɵelementStart(4, "div", 5);
    i0.ɵɵelement(5, "sv-ng-list", 6);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("visible", ctx_r0.model.hasErrors);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.errorList);
  }
}
function LogicAddButtonComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("click", function LogicAddButtonComponent_ng_template_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClick($event));
    });
    i0.ɵɵelementStart(1, "span", 2);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("svc-logic-tab__content-action--disabled", ctx_r1.model.enabled !== undefined && !ctx_r1.model.enabled);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.model.title, " ");
  }
}
function LogicTabComponent_ng_template_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6)(1, "span", 7);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.model.emptyTabPlaceholder);
  }
}
function LogicTabComponent_ng_template_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-tab-logic-add-btn", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.addNewButton);
  }
}
function LogicTabComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵelement(2, "survey-content", 3);
    i0.ɵɵtemplate(3, LogicTabComponent_ng_template_0_div_3_Template, 3, 1, "div", 4)(4, LogicTabComponent_ng_template_0_ng_container_4_Template, 2, 1, "ng-container", 5);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassProp("svc-logic-tab__empty", !ctx_r0.model.hasItems);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.itemsSurvey);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.model.hasItems);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.model.readOnly);
  }
}
function ActionButtonComponent_ng_template_0_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r0.classes);
    i0.ɵɵattribute("title", ctx_r0.title);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.text);
  }
}
function ActionButtonComponent_ng_template_0_span_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵlistener("click", function ActionButtonComponent_ng_template_0_span_1_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r0.onClick($event));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r0.classes);
    i0.ɵɵclassProp("svc-action-button--selected", ctx_r0.selected);
    i0.ɵɵattribute("title", ctx_r0.title);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.text);
  }
}
function ActionButtonComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ActionButtonComponent_ng_template_0_span_0_Template, 2, 4, "span", 1)(1, ActionButtonComponent_ng_template_0_span_1_Template, 2, 6, "span", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r0.disabled);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.disabled);
  }
}
const _c11 = a0 => ({
  processEsc: false,
  disableTabStop: a0
});
function SwitcherComponent_ng_template_0_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.model.title);
  }
}
function SwitcherComponent_ng_template_0_ng_template_4_Template(rf, ctx) {}
function SwitcherComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 1);
    i0.ɵɵlistener("click", function SwitcherComponent_ng_template_0_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.action(ctx_r1.model, ctx_r1.model.getIsTrusted($event)));
    });
    i0.ɵɵelementStart(1, "div");
    i0.ɵɵelement(2, "div", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, SwitcherComponent_ng_template_0_span_3_Template, 2, 1, "span", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, SwitcherComponent_ng_template_0_ng_template_4_Template, 0, 0, "ng-template");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.model.getActionBarItemCss());
    i0.ɵɵproperty("key2click", i0.ɵɵpureFunction1(11, _c11, ctx_r1.model.disableTabStop))("disabled", ctx_r1.model.disabled);
    i0.ɵɵattribute("title", ctx_r1.model.tooltip || ctx_r1.model.title)("aria-checked", ctx_r1.model.ariaChecked)("aria-expanded", ctx_r1.model.ariaExpanded)("role", ctx_r1.model.ariaRole);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.model.getSwitcherIconCss());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.model.hasTitle);
  }
}
function LinkValueQuestionComponent_ng_template_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-action-button", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("text", ctx_r0.clearCaption)("click", ctx_r0.model.doClearClick.bind(ctx_r0.model))("selected", ctx_r0.model.isSelected)("disabled", false)("classes", ctx_r0.model.linkClearButtonCssClasses);
  }
}
function LinkValueQuestionComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-action-button", 1);
    i0.ɵɵtemplate(1, LinkValueQuestionComponent_ng_template_0_ng_container_1_Template, 2, 5, "ng-container", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("text", ctx_r0.model.linkValueText)("click", ctx_r0.model.doLinkClick.bind(ctx_r0.model))("selected", ctx_r0.model.isSelected)("disabled", !ctx_r0.model.isClickable)("classes", ctx_r0.model.linkSetButtonCssClasses)("title", ctx_r0.model.tooltip);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.model.isReadOnly && ctx_r0.model.showClear);
  }
}
function EmbeddedSurveyQuestionComponent_ng_template_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "page", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.survey.currentPage)("survey", ctx_r0.survey);
  }
}
function EmbeddedSurveyQuestionComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, EmbeddedSurveyQuestionComponent_ng_template_0_ng_container_0_Template, 2, 2, "ng-container", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", !!ctx_r0.model && !!ctx_r0.survey && ctx_r0.survey.currentPage);
  }
}
function TranslationTabComponent_ng_template_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.model.noStringsText);
  }
}
function TranslationTabComponent_ng_template_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6)(1, "div", 7);
    i0.ɵɵelement(2, "survey-content", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 9);
    i0.ɵɵelement(4, "survey-content", 8);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r0.model.stringsHeaderSurvey);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r0.model.stringsSurvey);
  }
}
function TranslationTabComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵtemplate(2, TranslationTabComponent_ng_template_0_div_2_Template, 3, 1, "div", 3)(3, TranslationTabComponent_ng_template_0_div_3_Template, 5, 2, "div", 4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.model.isEmpty);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.model.isEmpty);
  }
}
function TranslationSkeletonComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 1);
  }
}
const _c12 = a0 => ({
  name: "survey-widget",
  data: a0
});
function SimulatorComponent_ng_template_0_div_1_ng_template_3_Template(rf, ctx) {}
function SimulatorComponent_ng_template_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4)(1, "div", 5)(2, "div", 6);
    i0.ɵɵtemplate(3, SimulatorComponent_ng_template_0_div_1_ng_template_3_Template, 0, 0, "ng-template", 7);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵstyleProp("width", ctx_r1.simulatorFrame.frameWidth + "px")("height", ctx_r1.simulatorFrame.frameHeight + "px");
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("width", ctx_r1.simulatorFrame.deviceWidth + "px")("height", ctx_r1.simulatorFrame.deviceHeight + "px")("transform", "scale(" + ctx_r1.simulatorFrame.scale + ") translate(-50%, -50%)");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction1(13, _c12, i0.ɵɵpureFunction1(11, _c0, ctx_r1.model.survey)));
  }
}
function SimulatorComponent_ng_template_0_div_2_ng_template_1_Template(rf, ctx) {}
function SimulatorComponent_ng_template_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, SimulatorComponent_ng_template_0_div_2_ng_template_1_Template, 0, 0, "ng-template", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction1(3, _c12, i0.ɵɵpureFunction1(1, _c0, ctx_r1.model.survey)));
  }
}
function SimulatorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("keydown", function SimulatorComponent_ng_template_0_Template_div_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.tryToZoom($event, $event));
    })("mouseover", function SimulatorComponent_ng_template_0_Template_div_mouseover_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.activateZoom());
    })("mouseout", function SimulatorComponent_ng_template_0_Template_div_mouseout_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.deactivateZoom());
    });
    i0.ɵɵtemplate(1, SimulatorComponent_ng_template_0_div_1_Template, 4, 15, "div", 2)(2, SimulatorComponent_ng_template_0_div_2_Template, 2, 5, "div", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.model.getRootCss());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.hasFrame);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.model.hasFrame);
  }
}
function TestAgainActionComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("click", function TestAgainActionComponent_ng_template_0_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.action.action());
    });
    i0.ɵɵelementStart(1, "span", 2);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.action.title, " ");
  }
}
function SurveyResultsTableRowComponent_ng_template_0_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵstyleProp("left", ctx_r1.model.markerMargin);
    i0.ɵɵclassProp("svd-test-results__marker--expanded", !ctx_r1.model.collapsed);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-expand_16x16")("size", 16);
  }
}
function SurveyResultsTableRowComponent_ng_template_0_sv_ng_string_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "sv-ng-string", 8);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("model", ctx_r1.model.question.locTitle);
  }
}
function SurveyResultsTableRowComponent_ng_template_0_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.model.title);
  }
}
function SurveyResultsTableRowComponent_ng_template_0_ng_container_7_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "survey-results-table-row", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const row_r3 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", row_r3);
  }
}
function SurveyResultsTableRowComponent_ng_template_0_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, SurveyResultsTableRowComponent_ng_template_0_ng_container_7_ng_container_1_Template, 2, 1, "ng-container", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.model.data);
  }
}
function SurveyResultsTableRowComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tr", 1);
    i0.ɵɵlistener("click", function SurveyResultsTableRowComponent_ng_template_0_Template_tr_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.model.toggle());
    });
    i0.ɵɵelementStart(1, "td", 2);
    i0.ɵɵtemplate(2, SurveyResultsTableRowComponent_ng_template_0_span_2_Template, 2, 6, "span", 3)(3, SurveyResultsTableRowComponent_ng_template_0_sv_ng_string_3_Template, 1, 1, "sv-ng-string", 4)(4, SurveyResultsTableRowComponent_ng_template_0_span_4_Template, 2, 1, "span", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "td");
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(7, SurveyResultsTableRowComponent_ng_template_0_ng_container_7_Template, 2, 1, "ng-container", 5);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("padding-left", ctx_r1.model.textMargin);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.isNode);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.question);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.model.question);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("svd-test-results__node-value", ctx_r1.model.isNode)("svd-dark-border-color", !ctx_r1.model.isNode);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.model.getString(ctx_r1.model.displayValue), " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.isNode && !ctx_r1.model.collapsed);
  }
}
function SurveyResultsComponent_ng_template_0_ng_container_19_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "survey-results-table-row", 11);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const row_r1 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", row_r1);
  }
}
function SurveyResultsComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "div", 3);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 4);
    i0.ɵɵelement(5, "svc-action-button", 5)(6, "svc-action-button", 5);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "div", 6)(8, "div");
    i0.ɵɵtext(9);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(10, "div", 7)(11, "table")(12, "thead")(13, "tr", 8)(14, "th", 9);
    i0.ɵɵtext(15);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "th", 9);
    i0.ɵɵtext(17);
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(18, "tbody");
    i0.ɵɵtemplate(19, SurveyResultsComponent_ng_template_0_ng_container_19_Template, 2, 1, "ng-container", 10);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.model.surveyResultsText);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("text", ctx_r1.model.surveyResultsTableText)("click", ctx_r1.model.selectTableClick)("disabled", false)("selected", ctx_r1.model.isTableSelected);
    i0.ɵɵadvance();
    i0.ɵɵproperty("text", ctx_r1.model.surveyResultsJsonText)("click", ctx_r1.model.selectJsonClick)("disabled", false)("selected", ctx_r1.model.isJsonSelected);
    i0.ɵɵadvance();
    i0.ɵɵproperty("visible", ctx_r1.model.resultViewType === "text");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.model.resultText);
    i0.ɵɵadvance();
    i0.ɵɵproperty("visible", ctx_r1.model.resultViewType === "table");
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(ctx_r1.model.resultsTitle);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.model.resultsDisplayValue);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.model.resultData);
  }
}
function TestTabComponent_ng_template_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "survey-results", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("survey", ctx_r0.model.survey);
  }
}
function TestTabComponent_ng_template_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "sv-action-bar", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.pages);
  }
}
function TestTabComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵelement(2, "survey-simulator", 3);
    i0.ɵɵtemplate(3, TestTabComponent_ng_template_0_ng_container_3_Template, 2, 1, "ng-container", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, TestTabComponent_ng_template_0_div_4_Template, 2, 1, "div", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("svc-creator-tab__content--with-toolbar", ctx_r0.model.isPageToolbarVisible);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r0.model.simulator);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.model.showResults);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.model.isPageToolbarVisible);
  }
}
function ThemeTabComponent_ng_template_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "survey-results", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("survey", ctx_r0.model.survey);
  }
}
function ThemeTabComponent_ng_template_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "sv-action-bar", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.pages);
  }
}
function ThemeTabComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵelement(2, "survey-simulator", 3);
    i0.ɵɵtemplate(3, ThemeTabComponent_ng_template_0_ng_container_3_Template, 2, 1, "ng-container", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, ThemeTabComponent_ng_template_0_div_4_Template, 2, 1, "div", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("svc-creator-tab__content--with-toolbar", ctx_r0.model.isPageToolbarVisible);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r0.model.simulator);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.model.showResults);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.model.isPageToolbarVisible);
  }
}
function AddQuestionButtonComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵlistener("click", function AddQuestionButtonComponent_ng_template_0_div_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.addNewQuestion($event));
    })("mouseover", function AddQuestionButtonComponent_ng_template_0_div_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hoverStopper($event, $event.currentTarget));
    });
    i0.ɵɵelementStart(1, "span", 3);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 4);
    i0.ɵɵlistener("click", function AddQuestionButtonComponent_ng_template_0_div_0_Template_button_click_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.selectQuestionType($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(4, "svg", 5);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelement(5, "sv-ng-popup", 6);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.adorner.addNewQuestionText, " ");
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", ctx_r1.adorner.questionTypeSelectorModel.title)("aria-label", ctx_r1.adorner.questionTypeSelectorModel.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", ctx_r1.adorner.questionTypeSelectorModel.iconName)("size", 24);
    i0.ɵɵadvance();
    i0.ɵɵproperty("popupModel", ctx_r1.adorner.questionTypeSelectorModel.popupModel);
  }
}
function AddQuestionButtonComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, AddQuestionButtonComponent_ng_template_0_div_0_Template, 6, 6, "div", 1);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.showAddQuestionButton);
  }
}
function QuestionBannerComponent_ng_template_0_ng_template_6_Template(rf, ctx) {}
function QuestionBannerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "span");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "span", 3);
    i0.ɵɵelement(5, "svc-action-button", 4);
    i0.ɵɵelementEnd()()();
    i0.ɵɵtemplate(6, QuestionBannerComponent_ng_template_0_ng_template_6_Template, 0, 0, "ng-template");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1("", ctx_r0.model.text, " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("text", ctx_r0.model.actionText)("click", ctx_r0.model.onClick.bind(ctx_r0.model));
  }
}
const _c13 = () => ({
  disableTabStop: true
});
const _c14 = (a0, a1) => ({
  adorner: a0,
  question: a1
});
function QuestionDesignerComponent_ng_template_0_div_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵlistener("pointerdown", function QuestionDesignerComponent_ng_template_0_div_0_div_3_Template_div_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 14);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(2, "div", 15);
    i0.ɵɵelement(3, "sv-action-bar", 12);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-drag-area-indicator_24x16")("size", 24);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.topActionContainer)("handleClick", false);
  }
}
function QuestionDesignerComponent_ng_template_0_div_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "div");
    i0.ɵɵelement(2, "sv-ng-string", 16);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r1.adorner.cssCollapsedHiddenHeader);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.adorner.cssCollapsedHiddenTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model.locTitle);
  }
}
function QuestionDesignerComponent_ng_template_0_div_0_ng_template_5_Template(rf, ctx) {}
function QuestionDesignerComponent_ng_template_0_div_0_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17)(1, "div", 18)(2, "div", 19);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.adorner.placeholderText);
  }
}
function QuestionDesignerComponent_ng_template_0_div_0_7_ng_template_0_Template(rf, ctx) {}
function QuestionDesignerComponent_ng_template_0_div_0_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionDesignerComponent_ng_template_0_div_0_7_ng_template_0_Template, 0, 0, "ng-template", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(1, _c1, ctx_r1.placeholderComponent, ctx_r1.placeholderComponentData));
  }
}
function QuestionDesignerComponent_ng_template_0_div_0_8_ng_template_0_Template(rf, ctx) {}
function QuestionDesignerComponent_ng_template_0_div_0_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionDesignerComponent_ng_template_0_div_0_8_ng_template_0_Template, 0, 0, "ng-template", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(4, _c1, ctx_r1.adornerComponent, i0.ɵɵpureFunction2(1, _c14, ctx_r1.adorner, ctx_r1.model)));
  }
}
function QuestionDesignerComponent_ng_template_0_div_0_svc_question_banner_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-question-banner", 16);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("model", ctx_r1.adorner.createBannerParams());
  }
}
function QuestionDesignerComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3, 1);
    i0.ɵɵlistener("dblclick", function QuestionDesignerComponent_ng_template_0_div_0_Template_div_dblclick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.dblclick($event));
    })("mouseover", function QuestionDesignerComponent_ng_template_0_div_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    })("mouseleave", function QuestionDesignerComponent_ng_template_0_div_0_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    });
    i0.ɵɵelementStart(2, "div", 4);
    i0.ɵɵlistener("click", function QuestionDesignerComponent_ng_template_0_div_0_Template_div_click_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵtemplate(3, QuestionDesignerComponent_ng_template_0_div_0_div_3_Template, 4, 4, "div", 5)(4, QuestionDesignerComponent_ng_template_0_div_0_div_4_Template, 3, 5, "div", 6)(5, QuestionDesignerComponent_ng_template_0_div_0_ng_template_5_Template, 0, 0, "ng-template", 7)(6, QuestionDesignerComponent_ng_template_0_div_0_div_6_Template, 4, 1, "div", 8)(7, QuestionDesignerComponent_ng_template_0_div_0_7_Template, 1, 4, null, 9)(8, QuestionDesignerComponent_ng_template_0_div_0_8_Template, 1, 7, null, 9)(9, QuestionDesignerComponent_ng_template_0_div_0_svc_question_banner_9_Template, 1, 1, "svc-question-banner", 10);
    i0.ɵɵelementStart(10, "div", 11);
    i0.ɵɵlistener("focusin", function QuestionDesignerComponent_ng_template_0_div_0_Template_div_focusin_10_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵelement(11, "sv-action-bar", 12);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.rootCss());
    i0.ɵɵattribute("data-sv-drop-target-survey-element", ctx_r1.adorner.element.name || null);
    i0.ɵɵadvance(2);
    i0.ɵɵclassMap(ctx_r1.adorner.css());
    i0.ɵɵproperty("key2click", i0.ɵɵpureFunction0(15, _c13));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowDragging);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.model.hasTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(16, _c1, ctx_r1.componentName, ctx_r1.componentData));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement && !ctx_r1.showPlaceholderComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement && !!ctx_r1.showPlaceholderComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adornerComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isBannerShowing);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.actionContainer)("handleClick", false);
  }
}
function QuestionDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionDesignerComponent_ng_template_0_div_0_Template, 12, 19, "div", 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner);
  }
}
const _c15 = (a0, a1, a2, a3, a4) => ({
  "svc-item-value--new": a0,
  "svc-item-value--dragging": a1,
  "svc-item-value--ghost": a2,
  "svc-item-value--movedown": a3,
  "svc-item-value--moveup": a4
});
function ItemValueDesignerComponent_ng_template_0_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 9);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-drag-area-indicator")("size", 24);
    i0.ɵɵattribute("title", ctx_r1.adorner.dragTooltip);
  }
}
function ItemValueDesignerComponent_ng_template_0_span_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 11);
    i0.ɵɵlistener("click", function ItemValueDesignerComponent_ng_template_0_span_4_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.add(ctx_r1.adorner));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-add_16x16")("size", 16);
    i0.ɵɵattribute("title", undefined);
  }
}
function ItemValueDesignerComponent_ng_template_0_span_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵlistener("click", function ItemValueDesignerComponent_ng_template_0_span_5_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.remove(ctx_r1.adorner));
    })("blur", function ItemValueDesignerComponent_ng_template_0_span_5_Template_span_blur_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onBlur($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-remove_16x16")("size", 16);
    i0.ɵɵattribute("title", undefined);
  }
}
function ItemValueDesignerComponent_ng_template_0_ng_template_7_Template(rf, ctx) {}
function ItemValueDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("pointerdown", function ItemValueDesignerComponent_ng_template_0_Template_div_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵelement(1, "div", 2);
    i0.ɵɵelementStart(2, "div", 3);
    i0.ɵɵtemplate(3, ItemValueDesignerComponent_ng_template_0_span_3_Template, 2, 3, "span", 4)(4, ItemValueDesignerComponent_ng_template_0_span_4_Template, 2, 4, "span", 5)(5, ItemValueDesignerComponent_ng_template_0_span_5_Template, 2, 4, "span", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 7);
    i0.ɵɵlistener("click", function ItemValueDesignerComponent_ng_template_0_Template_div_click_6_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵtemplate(7, ItemValueDesignerComponent_ng_template_0_ng_template_7_Template, 0, 0, "ng-template", 8);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction5(6, _c15, ctx_r1.adorner.isNew, ctx_r1.adorner.isDragging, ctx_r1.adorner.isDragDropGhost, ctx_r1.adorner.isDragDropMoveDown, ctx_r1.adorner.isDragDropMoveUp));
    i0.ɵɵattribute("data-sv-drop-target-item-value", ctx_r1.adorner.isDraggable ? ctx_r1.item.value : null);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isDraggable);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowAdd);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowRemove);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(12, _c1, ctx_r1.componentName, ctx_r1.componentData));
  }
}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_1_ng_template_1_Template(rf, ctx) {}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵtemplate(1, ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_1_ng_template_1_Template, 0, 0, "ng-template", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(1, _c1, ctx_r1.componentName, ctx_r1.componentData));
  }
}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_span_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵlistener("pointerdown", function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_span_2_Template_span_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("title", undefined)("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-drag-area-indicator")("size", 24);
  }
}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "span", 15);
    i0.ɵɵlistener("click", function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_3_ng_container_1_Template_span_click_1_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.adorner.chooseFile(ctx_r1.adorner));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(3, "span", 17);
    i0.ɵɵlistener("click", function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_3_ng_container_1_Template_span_click_3_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.adorner.remove(ctx_r1.adorner));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(4, "svg", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", undefined)("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-file")("size", 24)("title", ctx_r1.adorner.selectFileTitle);
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", undefined)("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-delete")("size", 24)("title", ctx_r1.adorner.removeFileTitle);
  }
}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14);
    i0.ɵɵtemplate(1, ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_3_ng_container_1_Template, 5, 10, "ng-container", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowRemove && !ctx_r1.adorner.isUploading);
  }
}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_1_Template, 2, 4, "div", 7)(2, ImageItemValueDesignerComponent_ng_template_0_ng_container_5_span_2_Template, 2, 4, "span", 8)(3, ImageItemValueDesignerComponent_ng_template_0_ng_container_5_div_3_Template, 2, 1, "div", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.adorner.isNew);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isDraggable && ctx_r1.adorner.canRenderControls);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.canRenderControls);
  }
}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_6_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 25);
    i0.ɵɵelement(1, "sv-ng-loading-indicator");
    i0.ɵɵelementEnd();
  }
}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_6_span_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 26);
    i0.ɵɵlistener("click", function ImageItemValueDesignerComponent_ng_template_0_ng_container_6_span_7_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.chooseNewFile(ctx_r1.adorner));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 27);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵattribute("title", undefined)("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-add-lg")("size", 24)("title", ctx_r1.adorner.addFileTitle);
  }
}
function ImageItemValueDesignerComponent_ng_template_0_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 18)(2, "div", 19)(3, "label", 20)(4, "div", 21);
    i0.ɵɵtemplate(5, ImageItemValueDesignerComponent_ng_template_0_ng_container_6_div_5_Template, 2, 0, "div", 22);
    i0.ɵɵelementEnd()()()();
    i0.ɵɵelementStart(6, "div", 23);
    i0.ɵɵtemplate(7, ImageItemValueDesignerComponent_ng_template_0_ng_container_6_span_7_Template, 2, 5, "span", 24);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(4);
    i0.ɵɵstyleMap(ctx_r1.getNewItemStyle());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isUploading);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowAdd && !ctx_r1.adorner.isUploading);
  }
}
function ImageItemValueDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2, 1);
    i0.ɵɵlistener("pointerdown", function ImageItemValueDesignerComponent_ng_template_0_Template_div_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵelement(2, "div", 3);
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵelement(4, "input", 5);
    i0.ɵɵtemplate(5, ImageItemValueDesignerComponent_ng_template_0_ng_container_5_Template, 4, 3, "ng-container", 6)(6, ImageItemValueDesignerComponent_ng_template_0_ng_container_6_Template, 8, 4, "ng-container", 6);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.adorner.getRootCss());
    i0.ɵɵattribute("data-sv-drop-target-item-value", ctx_r1.adorner.isDraggable ? ctx_r1.item.value : null);
    i0.ɵɵadvance(2);
    i0.ɵɵstyleMap(ctx_r1.getNewItemStyle());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("accept", ctx_r1.adorner.acceptedTypes);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.adorner.isNew && !ctx_r1.adorner.isUploading);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isNew || ctx_r1.adorner.isUploading);
  }
}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵlistener("pointerdown", function QuestionDropdownDesignerComponent_ng_template_0_div_0_div_3_Template_div_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 14);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(2, "div", 15);
    i0.ɵɵelement(3, "sv-action-bar", 12);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-drag-area-indicator_24x16")("size", 24);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.topActionContainer)("handleClick", false);
  }
}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "div");
    i0.ɵɵelement(2, "sv-ng-string", 16);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r1.adorner.cssCollapsedHiddenHeader);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.adorner.cssCollapsedHiddenTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model.locTitle);
  }
}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_ng_template_5_Template(rf, ctx) {}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17)(1, "div", 18)(2, "div", 19);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.adorner.placeholderText);
  }
}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_7_ng_template_0_Template(rf, ctx) {}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionDropdownDesignerComponent_ng_template_0_div_0_7_ng_template_0_Template, 0, 0, "ng-template", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(1, _c1, ctx_r1.placeholderComponent, ctx_r1.placeholderComponentData));
  }
}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_8_ng_template_0_Template(rf, ctx) {}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionDropdownDesignerComponent_ng_template_0_div_0_8_ng_template_0_Template, 0, 0, "ng-template", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(4, _c1, ctx_r1.adornerComponent, i0.ɵɵpureFunction2(1, _c14, ctx_r1.adorner, ctx_r1.model)));
  }
}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_svc_question_banner_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-question-banner", 16);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("model", ctx_r1.adorner.createBannerParams());
  }
}
function QuestionDropdownDesignerComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3, 1);
    i0.ɵɵlistener("dblclick", function QuestionDropdownDesignerComponent_ng_template_0_div_0_Template_div_dblclick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.dblclick($event));
    })("mouseover", function QuestionDropdownDesignerComponent_ng_template_0_div_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    })("mouseleave", function QuestionDropdownDesignerComponent_ng_template_0_div_0_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    });
    i0.ɵɵelementStart(2, "div", 4);
    i0.ɵɵlistener("click", function QuestionDropdownDesignerComponent_ng_template_0_div_0_Template_div_click_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵtemplate(3, QuestionDropdownDesignerComponent_ng_template_0_div_0_div_3_Template, 4, 4, "div", 5)(4, QuestionDropdownDesignerComponent_ng_template_0_div_0_div_4_Template, 3, 5, "div", 6)(5, QuestionDropdownDesignerComponent_ng_template_0_div_0_ng_template_5_Template, 0, 0, "ng-template", 7)(6, QuestionDropdownDesignerComponent_ng_template_0_div_0_div_6_Template, 4, 1, "div", 8)(7, QuestionDropdownDesignerComponent_ng_template_0_div_0_7_Template, 1, 4, null, 9)(8, QuestionDropdownDesignerComponent_ng_template_0_div_0_8_Template, 1, 7, null, 9)(9, QuestionDropdownDesignerComponent_ng_template_0_div_0_svc_question_banner_9_Template, 1, 1, "svc-question-banner", 10);
    i0.ɵɵelementStart(10, "div", 11);
    i0.ɵɵlistener("focusin", function QuestionDropdownDesignerComponent_ng_template_0_div_0_Template_div_focusin_10_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵelement(11, "sv-action-bar", 12);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.rootCss());
    i0.ɵɵattribute("data-sv-drop-target-survey-element", ctx_r1.adorner.element.name || null);
    i0.ɵɵadvance(2);
    i0.ɵɵclassMap(ctx_r1.adorner.css());
    i0.ɵɵproperty("key2click", i0.ɵɵpureFunction0(15, _c13));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowDragging);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.model.hasTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(16, _c1, ctx_r1.componentName, ctx_r1.componentData));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement && !ctx_r1.showPlaceholderComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement && !!ctx_r1.showPlaceholderComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adornerComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isBannerShowing);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.actionContainer)("handleClick", false);
  }
}
function QuestionDropdownDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionDropdownDesignerComponent_ng_template_0_div_0_Template, 12, 19, "div", 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner);
  }
}
function QuestionDropdownAdornerDesignerComponent_ng_template_0_div_3_ng_template_1_Template(rf, ctx) {}
function QuestionDropdownAdornerDesignerComponent_ng_template_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, QuestionDropdownAdornerDesignerComponent_ng_template_0_div_3_ng_template_1_Template, 0, 0, "ng-template", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.getChoiceCss());
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(3, _c1, ctx_r1.getItemValueComponentName(item_r1), ctx_r1.getItemValueComponentData(item_r1)));
  }
}
function QuestionDropdownAdornerDesignerComponent_ng_template_0_svc_action_button_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-action-button", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("text", ctx_r1.adorner.getButtonText())("click", ctx_r1.adorner.switchCollapse.bind(ctx_r1.adorner))("allowBubble", true);
  }
}
function QuestionDropdownAdornerDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div")(2, "div", 2);
    i0.ɵɵtemplate(3, QuestionDropdownAdornerDesignerComponent_ng_template_0_div_3_Template, 2, 6, "div", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, QuestionDropdownAdornerDesignerComponent_ng_template_0_svc_action_button_4_Template, 1, 3, "svc-action-button", 4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r1.adorner.getRenderedItems());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.needToCollapse);
  }
}
function QuestionImageDesignerComponent_ng_template_0_div_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵlistener("pointerdown", function QuestionImageDesignerComponent_ng_template_0_div_0_div_3_Template_div_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 14);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(2, "div", 15);
    i0.ɵɵelement(3, "sv-action-bar", 12);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-drag-area-indicator_24x16")("size", 24);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.topActionContainer)("handleClick", false);
  }
}
function QuestionImageDesignerComponent_ng_template_0_div_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "div");
    i0.ɵɵelement(2, "sv-ng-string", 16);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r1.adorner.cssCollapsedHiddenHeader);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.adorner.cssCollapsedHiddenTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model.locTitle);
  }
}
function QuestionImageDesignerComponent_ng_template_0_div_0_ng_template_5_Template(rf, ctx) {}
function QuestionImageDesignerComponent_ng_template_0_div_0_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17)(1, "div", 18)(2, "div", 19);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.adorner.placeholderText);
  }
}
function QuestionImageDesignerComponent_ng_template_0_div_0_7_ng_template_0_Template(rf, ctx) {}
function QuestionImageDesignerComponent_ng_template_0_div_0_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionImageDesignerComponent_ng_template_0_div_0_7_ng_template_0_Template, 0, 0, "ng-template", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(1, _c1, ctx_r1.placeholderComponent, ctx_r1.placeholderComponentData));
  }
}
function QuestionImageDesignerComponent_ng_template_0_div_0_8_ng_template_0_Template(rf, ctx) {}
function QuestionImageDesignerComponent_ng_template_0_div_0_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionImageDesignerComponent_ng_template_0_div_0_8_ng_template_0_Template, 0, 0, "ng-template", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(4, _c1, ctx_r1.adornerComponent, i0.ɵɵpureFunction2(1, _c14, ctx_r1.adorner, ctx_r1.model)));
  }
}
function QuestionImageDesignerComponent_ng_template_0_div_0_svc_question_banner_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-question-banner", 16);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("model", ctx_r1.adorner.createBannerParams());
  }
}
function QuestionImageDesignerComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3, 1);
    i0.ɵɵlistener("dblclick", function QuestionImageDesignerComponent_ng_template_0_div_0_Template_div_dblclick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.dblclick($event));
    })("mouseover", function QuestionImageDesignerComponent_ng_template_0_div_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    })("mouseleave", function QuestionImageDesignerComponent_ng_template_0_div_0_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    });
    i0.ɵɵelementStart(2, "div", 4);
    i0.ɵɵlistener("click", function QuestionImageDesignerComponent_ng_template_0_div_0_Template_div_click_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵtemplate(3, QuestionImageDesignerComponent_ng_template_0_div_0_div_3_Template, 4, 4, "div", 5)(4, QuestionImageDesignerComponent_ng_template_0_div_0_div_4_Template, 3, 5, "div", 6)(5, QuestionImageDesignerComponent_ng_template_0_div_0_ng_template_5_Template, 0, 0, "ng-template", 7)(6, QuestionImageDesignerComponent_ng_template_0_div_0_div_6_Template, 4, 1, "div", 8)(7, QuestionImageDesignerComponent_ng_template_0_div_0_7_Template, 1, 4, null, 9)(8, QuestionImageDesignerComponent_ng_template_0_div_0_8_Template, 1, 7, null, 9)(9, QuestionImageDesignerComponent_ng_template_0_div_0_svc_question_banner_9_Template, 1, 1, "svc-question-banner", 10);
    i0.ɵɵelementStart(10, "div", 11);
    i0.ɵɵlistener("focusin", function QuestionImageDesignerComponent_ng_template_0_div_0_Template_div_focusin_10_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵelement(11, "sv-action-bar", 12);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.rootCss());
    i0.ɵɵattribute("data-sv-drop-target-survey-element", ctx_r1.adorner.element.name || null);
    i0.ɵɵadvance(2);
    i0.ɵɵclassMap(ctx_r1.adorner.css());
    i0.ɵɵproperty("key2click", i0.ɵɵpureFunction0(15, _c13));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowDragging);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.model.hasTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(16, _c1, ctx_r1.componentName, ctx_r1.componentData));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement && !ctx_r1.showPlaceholderComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement && !!ctx_r1.showPlaceholderComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adornerComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isBannerShowing);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.actionContainer)("handleClick", false);
  }
}
function QuestionImageDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionImageDesignerComponent_ng_template_0_div_0_Template, 12, 19, "div", 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner);
  }
}
function QuestionImageAdornerDesignerComponent_ng_template_0_div_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "input", 5);
    i0.ɵɵelementStart(2, "span", 6);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(3, "svg", 7);
    i0.ɵɵlistener("click", function QuestionImageAdornerDesignerComponent_ng_template_0_div_0_ng_container_1_Template_svg_click_3_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.chooseFile(ctx_r1.adorner));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("accept", ctx_r1.adorner.acceptedTypes);
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", undefined)("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-file")("size", 24);
  }
}
function QuestionImageAdornerDesignerComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtemplate(1, QuestionImageAdornerDesignerComponent_ng_template_0_div_0_ng_container_1_Template, 4, 5, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowEdit);
  }
}
function QuestionImageAdornerDesignerComponent_ng_template_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8)(1, "div", 9);
    i0.ɵɵelement(2, "sv-ng-loading-indicator");
    i0.ɵɵelementEnd()();
  }
}
function QuestionImageAdornerDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionImageAdornerDesignerComponent_ng_template_0_div_0_Template, 2, 1, "div", 1)(1, QuestionImageAdornerDesignerComponent_ng_template_0_div_1_Template, 3, 0, "div", 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", !ctx_r1.adorner.isUploading && !ctx_r1.adorner.isEmptyElement);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isUploading && !ctx_r1.adorner.isEmptyElement);
  }
}
function PanelDesignerComponent_ng_template_0_div_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵlistener("pointerdown", function PanelDesignerComponent_ng_template_0_div_0_div_3_Template_div_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 12);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(2, "div", 13);
    i0.ɵɵelement(3, "sv-action-bar", 14);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-drag-area-indicator_24x16")("size", 24);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.topActionContainer)("handleClick", false);
  }
}
function PanelDesignerComponent_ng_template_0_div_0_ng_template_4_Template(rf, ctx) {}
function PanelDesignerComponent_ng_template_0_div_0_div_5_div_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵlistener("click", function PanelDesignerComponent_ng_template_0_div_0_div_5_div_4_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.addNewQuestion($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 20);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(2, "span", 21);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-add_24x24")("size", 24);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.adorner.addNewQuestionText, " ");
  }
}
function PanelDesignerComponent_ng_template_0_div_0_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15)(1, "div", 16)(2, "div", 17);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, PanelDesignerComponent_ng_template_0_div_0_div_5_div_4_Template, 4, 3, "div", 18);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.adorner.placeholderText);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.showAddQuestionButton);
  }
}
function PanelDesignerComponent_ng_template_0_div_0_6_ng_template_0_Template(rf, ctx) {}
function PanelDesignerComponent_ng_template_0_div_0_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PanelDesignerComponent_ng_template_0_div_0_6_ng_template_0_Template, 0, 0, "ng-template", 6);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(4, _c1, ctx_r1.adornerComponent, i0.ɵɵpureFunction2(1, _c14, ctx_r1.adorner, ctx_r1.model)));
  }
}
function PanelDesignerComponent_ng_template_0_div_0_div_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 22)(1, "div", 23);
    i0.ɵɵelement(2, "sv-ng-popup", 24);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 25)(4, "div", 19);
    i0.ɵɵlistener("click", function PanelDesignerComponent_ng_template_0_div_0_div_7_Template_div_click_4_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.addNewQuestion($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(5, "svg", 20);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(6, "span", 21);
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "button", 26);
    i0.ɵɵlistener("click", function PanelDesignerComponent_ng_template_0_div_0_div_7_Template_button_click_8_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.selectQuestionType($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(9, "svg", 27);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("popupModel", ctx_r1.adorner.questionTypeSelectorModel.popupModel);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("iconName", "icon-add_24x24")("size", 24);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.adorner.addNewQuestionText, " ");
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", ctx_r1.adorner.addNewQuestionText);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", ctx_r1.adorner.questionTypeSelectorModel.iconName)("size", 24);
  }
}
function PanelDesignerComponent_ng_template_0_div_0_div_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 28);
    i0.ɵɵlistener("focusin", function PanelDesignerComponent_ng_template_0_div_0_div_8_Template_div_focusin_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵelement(1, "sv-action-bar", 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.adorner.actionContainer)("handleClick", false);
  }
}
function PanelDesignerComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3, 1);
    i0.ɵɵlistener("dblclick", function PanelDesignerComponent_ng_template_0_div_0_Template_div_dblclick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.dblclick($event));
    })("mouseover", function PanelDesignerComponent_ng_template_0_div_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    })("mouseleave", function PanelDesignerComponent_ng_template_0_div_0_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    });
    i0.ɵɵelementStart(2, "div", 4);
    i0.ɵɵlistener("click", function PanelDesignerComponent_ng_template_0_div_0_Template_div_click_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.element.isInteractiveDesignElement ? ctx_r1.adorner.select(ctx_r1.adorner, $event) : null);
    });
    i0.ɵɵtemplate(3, PanelDesignerComponent_ng_template_0_div_0_div_3_Template, 4, 4, "div", 5)(4, PanelDesignerComponent_ng_template_0_div_0_ng_template_4_Template, 0, 0, "ng-template", 6)(5, PanelDesignerComponent_ng_template_0_div_0_div_5_Template, 5, 2, "div", 7)(6, PanelDesignerComponent_ng_template_0_div_0_6_Template, 1, 7, null, 8)(7, PanelDesignerComponent_ng_template_0_div_0_div_7_Template, 10, 7, "div", 9)(8, PanelDesignerComponent_ng_template_0_div_0_div_8_Template, 2, 2, "div", 10);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.rootCss());
    i0.ɵɵattribute("data-sv-drop-target-survey-element", ctx_r1.adorner.element.name || null);
    i0.ɵɵadvance(2);
    i0.ɵɵclassMap(ctx_r1.adorner.css());
    i0.ɵɵproperty("key2click", i0.ɵɵpureFunction0(12, _c13));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowDragging && ctx_r1.adorner.element.isInteractiveDesignElement);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(13, _c1, ctx_r1.componentName, ctx_r1.componentData));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adornerComponent && ctx_r1.adorner.element.isInteractiveDesignElement);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.adorner.isEmptyElement && ctx_r1.adorner.showAddQuestionButton);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.element.isInteractiveDesignElement);
  }
}
function PanelDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PanelDesignerComponent_ng_template_0_div_0_Template, 9, 16, "div", 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner);
  }
}
function ToolboxItemComponent_ng_template_0__svg_svg_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 6);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("iconName", ctx_r1.item.iconName)("size", 24)("title", ctx_r1.item.tooltip);
  }
}
function ToolboxItemComponent_ng_template_0_span_3__svg_svg_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 10);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("iconName", ctx_r1.item.iconName)("size", 24)("title", ctx_r1.item.tooltip);
  }
}
function ToolboxItemComponent_ng_template_0_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 7);
    i0.ɵɵtemplate(1, ToolboxItemComponent_ng_template_0_span_3__svg_svg_1_Template, 1, 3, "svg", 8);
    i0.ɵɵelementStart(2, "span", 9);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.item.iconName);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.item.title);
  }
}
function ToolboxItemComponent_ng_template_0_span_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.item.title);
  }
}
function ToolboxItemComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("click", function ToolboxItemComponent_ng_template_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.viewModel.click($event));
    });
    i0.ɵɵelementStart(1, "span", 2);
    i0.ɵɵtemplate(2, ToolboxItemComponent_ng_template_0__svg_svg_2_Template, 1, 3, "svg", 3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, ToolboxItemComponent_ng_template_0_span_3_Template, 4, 2, "span", 4)(4, ToolboxItemComponent_ng_template_0_span_4_Template, 2, 1, "span", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.item.className);
    i0.ɵɵproperty("title", ctx_r1.item.tooltip);
    i0.ɵɵattribute("aria-label", ctx_r1.item.tooltip);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.item.iconName);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.isCompact);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.isCompact);
  }
}
function ToolboxItemGroupComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-toolbox-item", 1)(1, "sv-ng-popup", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("model", ctx_r0.item)("viewModel", ctx_r0.viewModel)("creator", ctx_r0.creator)("isCompact", ctx_r0.isCompact);
    i0.ɵɵadvance();
    i0.ɵɵproperty("popupModel", ctx_r0.model.popupModel)("getArea", ctx_r0.model.getArea);
  }
}
function ToolboxListComponent_ng_template_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "svc-toolbox-tool", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("item", item_r1)("creator", ctx_r1.creator)("parentModel", ctx_r1.model)("isCompact", false);
  }
}
function ToolboxListComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ToolboxListComponent_ng_template_0_ng_container_1_Template, 2, 4, "ng-container", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.model.cssClasses.root);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.model.renderedActions);
  }
}
function StringEditorComponent_ng_template_0_span_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 10, 1);
    i0.ɵɵlistener("focus", function StringEditorComponent_ng_template_0_span_5_Template_span_focus_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onFocus($event));
    })("paste", function StringEditorComponent_ng_template_0_span_5_Template_span_paste_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onPaste($event));
    })("blur", function StringEditorComponent_ng_template_0_span_5_Template_span_blur_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onBlur($event));
    })("input", function StringEditorComponent_ng_template_0_span_5_Template_span_input_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onInput($event));
    })("keydown", function StringEditorComponent_ng_template_0_span_5_Template_span_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onKeyDown($event));
    })("keyup", function StringEditorComponent_ng_template_0_span_5_Template_span_keyup_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onKeyUp($event));
    })("compositionstart", function StringEditorComponent_ng_template_0_span_5_Template_span_compositionstart_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onCompositionStart($event));
    })("compositionend", function StringEditorComponent_ng_template_0_span_5_Template_span_compositionend_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onCompositionEnd($event));
    })("mouseup", function StringEditorComponent_ng_template_0_span_5_Template_span_mouseup_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onMouseUp($event));
    })("click", function StringEditorComponent_ng_template_0_span_5_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.edit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("textContent", ctx_r1.locString.renderedHtml);
    i0.ɵɵattribute("aria-placeholder", ctx_r1.placeholder)("contenteditable", ctx_r1.contentEditable);
  }
}
function StringEditorComponent_ng_template_0_span_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 11, 1);
    i0.ɵɵpipe(2, "safeHtml");
    i0.ɵɵlistener("focus", function StringEditorComponent_ng_template_0_span_6_Template_span_focus_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onFocus($event));
    })("blur", function StringEditorComponent_ng_template_0_span_6_Template_span_blur_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onBlur($event));
    })("keydown", function StringEditorComponent_ng_template_0_span_6_Template_span_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onKeyDown($event));
    })("keyup", function StringEditorComponent_ng_template_0_span_6_Template_span_keyup_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onKeyUp($event));
    })("compositionstart", function StringEditorComponent_ng_template_0_span_6_Template_span_compositionstart_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onCompositionStart($event));
    })("compositionend", function StringEditorComponent_ng_template_0_span_6_Template_span_compositionend_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onCompositionEnd($event));
    })("mouseup", function StringEditorComponent_ng_template_0_span_6_Template_span_mouseup_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.baseModel.onMouseUp($event));
    })("click", function StringEditorComponent_ng_template_0_span_6_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.edit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("innerHtml", i0.ɵɵpipeBind1(2, 3, ctx_r1.locString.renderedHtml), i0.ɵɵsanitizeHtml);
    i0.ɵɵattribute("aria-placeholder", ctx_r1.placeholder)("contenteditable", ctx_r1.contentEditable);
  }
}
function StringEditorComponent_ng_template_0_sv_ng_character_counter_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "sv-ng-character-counter", 12);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("counter", ctx_r1.characterCounter)("remainingCharacterCounter", ctx_r1.getCharacterCounterClass);
  }
}
function StringEditorComponent_ng_template_0_span_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.errorText);
  }
}
function StringEditorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span")(1, "span", 2)(2, "div", 3);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(3, "svg", 4);
    i0.ɵɵlistener("click", function StringEditorComponent_ng_template_0_Template_svg_click_3_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.edit($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(4, "span", 5);
    i0.ɵɵtemplate(5, StringEditorComponent_ng_template_0_span_5_Template, 2, 3, "span", 6)(6, StringEditorComponent_ng_template_0_span_6_Template, 3, 5, "span", 7)(7, StringEditorComponent_ng_template_0_sv_ng_character_counter_7_Template, 1, 2, "sv-ng-character-counter", 8);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(8, StringEditorComponent_ng_template_0_span_8_Template, 2, 1, "span", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.className);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("iconName", "icon-arrow-up")("size", 24)("iconName", "icon-edit")("size", 16);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.locString.hasHtml);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.locString.hasHtml);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.showCharacterCounter);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.errorText);
  }
}
function LogicOperatorComponent_ng_template_0_ng_container_1_sv_ng_string_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "sv-ng-string", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("model", ctx_r1.model.selectedItemLocText);
  }
}
function LogicOperatorComponent_ng_template_0_ng_container_1_div_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵlistener("click", function LogicOperatorComponent_ng_template_0_ng_container_1_div_6_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.clear);
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r1.model.cssClasses.cleanButton);
    i0.ɵɵproperty("visible", !ctx_r1.model.isEmpty());
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.model.cssClasses.cleanButtonSvg);
    i0.ɵɵproperty("iconName", ctx_r1.model.cssClasses.cleanButtonIconId)("size", "auto")("title", ctx_r1.model.clearCaption)("size", 24);
  }
}
function LogicOperatorComponent_ng_template_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 3);
    i0.ɵɵlistener("click", function LogicOperatorComponent_ng_template_0_ng_container_1_Template_div_click_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.click($event));
    })("keyup", function LogicOperatorComponent_ng_template_0_ng_container_1_Template_div_keyup_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.keyup($event));
    });
    i0.ɵɵelementStart(2, "div");
    i0.ɵɵtemplate(3, LogicOperatorComponent_ng_template_0_ng_container_1_sv_ng_string_3_Template, 1, 1, "sv-ng-string", 4);
    i0.ɵɵelementStart(4, "div");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(6, LogicOperatorComponent_ng_template_0_ng_container_1_div_6_Template, 2, 9, "div", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(7, "sv-ng-popup", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.model.getControlClass());
    i0.ɵɵattribute("id", ctx_r1.model.inputId)("required", ctx_r1.model.isRequired)("tabindex", ctx_r1.model.isInputReadOnly ? undefined : 0)("disabled", ctx_r1.model.isInputReadOnly)("role", ctx_r1.model.ariaRole)("aria-required", ctx_r1.model.ariaRequired)("aria-label", ctx_r1.model.ariaLabel)("aria-invalid", ctx_r1.model.ariaInvalid)("aria-describedby", ctx_r1.model.ariaDescribedBy);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.model.cssClasses.controlValue);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.selectedItemLocText);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.model.readOnlyText);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.allowClear && ctx_r1.model.cssClasses.cleanButtonIconId);
    i0.ɵɵadvance();
    i0.ɵɵproperty("popupModel", ctx_r1.model.popupModel);
  }
}
function LogicOperatorComponent_ng_template_0_div_2_sv_ng_string_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "sv-ng-string", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("model", ctx_r1.model.selectedItemLocText);
  }
}
function LogicOperatorComponent_ng_template_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵtemplate(1, LogicOperatorComponent_ng_template_0_div_2_sv_ng_string_1_Template, 1, 1, "sv-ng-string", 4);
    i0.ɵɵelementStart(2, "div");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.model.getControlClass());
    i0.ɵɵattribute("id", ctx_r1.model.inputId);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.selectedItemLocText);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.model.readOnlyText);
  }
}
function LogicOperatorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, LogicOperatorComponent_ng_template_0_ng_container_1_Template, 8, 17, "ng-container", 1)(2, LogicOperatorComponent_ng_template_0_div_2_Template, 4, 5, "div", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.model.cssClasses.selectWrapper);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.model.isReadOnly);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.isReadOnly);
  }
}
function MatrixCellComponent_ng_template_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function MatrixCellComponent_ng_template_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5)(1, "span", 6);
    i0.ɵɵlistener("click", function MatrixCellComponent_ng_template_0_div_3_Template_span_click_1_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.editQuestion(ctx_r1.adorner, $event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 7);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("iconName", "icon-edit")("size", 24)("size", 24);
  }
}
function MatrixCellComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("click", function MatrixCellComponent_ng_template_0_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.selectContext($event));
    })("mouseover", function MatrixCellComponent_ng_template_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    })("mouseleave", function MatrixCellComponent_ng_template_0_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    });
    i0.ɵɵelement(1, "div", 2);
    i0.ɵɵtemplate(2, MatrixCellComponent_ng_template_0_ng_container_2_Template, 1, 0, "ng-container", 3)(3, MatrixCellComponent_ng_template_0_div_3_Template, 3, 3, "div", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassProp("svc-visible", ctx_r1.adorner.isSelected);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.contentTempl);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.isSupportCellEditor);
  }
}
function QuestionEditorComponent_ng_template_0_ng_template_0_Template(rf, ctx) {}
function QuestionEditorComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionEditorComponent_ng_template_0_ng_template_0_Template, 0, 0, "ng-template", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(1, _c1, ctx_r0.componentName, ctx_r0.componentData));
  }
}
function CellQuestionComponent_ng_template_0_ng_template_2_Template(rf, ctx) {}
function CellQuestionComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵtemplate(2, CellQuestionComponent_ng_template_0_ng_template_2_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(1, _c1, ctx_r0.componentName, ctx_r0.componentData));
  }
}
function CellQuestionDropdownComponent_ng_template_0_ng_template_2_Template(rf, ctx) {}
function CellQuestionDropdownComponent_ng_template_0_div_4_ng_template_1_Template(rf, ctx) {}
function CellQuestionDropdownComponent_ng_template_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, CellQuestionDropdownComponent_ng_template_0_div_4_ng_template_1_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(1, _c1, ctx_r1.getItemValueComponentName(item_r1), ctx_r1.getItemValueComponentData(item_r1)));
  }
}
function CellQuestionDropdownComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵtemplate(2, CellQuestionDropdownComponent_ng_template_0_ng_template_2_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵtemplate(4, CellQuestionDropdownComponent_ng_template_0_div_4_Template, 2, 4, "div", 5);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(2, _c1, ctx_r1.componentName, ctx_r1.componentData));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.model.visibleChoices);
  }
}
function CreatorRowComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "sv-ng-row", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.model.cssClasses);
    i0.ɵɵadvance();
    i0.ɵɵproperty("row", ctx_r0.row);
  }
}
function QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵlistener("pointerdown", function QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_div_1_Template_div_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-drag-area-indicator_24x16")("size", 24);
  }
}
function QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_ng_template_3_Template(rf, ctx) {}
function QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14);
    i0.ɵɵelement(1, "div", 15);
    i0.ɵɵelementEnd();
  }
}
function QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵlistener("click", function QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵtemplate(1, QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_div_1_Template, 2, 2, "div", 6);
    i0.ɵɵelementStart(2, "div", 7);
    i0.ɵɵtemplate(3, QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_ng_template_3_Template, 0, 0, "ng-template", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_div_4_Template, 2, 0, "div", 9);
    i0.ɵɵelementStart(5, "div", 10);
    i0.ɵɵelement(6, "sv-action-bar", 11);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r1.adorner.css());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowDragging);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(7, _c1, ctx_r1.componentName, ctx_r1.componentData));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.actionContainer)("handleClick", false);
  }
}
function QuestionWidgetDesignerComponent_ng_template_0_div_0_ng_container_2_ng_template_1_Template(rf, ctx) {}
function QuestionWidgetDesignerComponent_ng_template_0_div_0_ng_container_2_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14)(1, "div", 16);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.adorner.placeholderText);
  }
}
function QuestionWidgetDesignerComponent_ng_template_0_div_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, QuestionWidgetDesignerComponent_ng_template_0_div_0_ng_container_2_ng_template_1_Template, 0, 0, "ng-template", 8)(2, QuestionWidgetDesignerComponent_ng_template_0_div_0_ng_container_2_div_2_Template, 3, 1, "div", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(2, _c1, ctx_r1.componentName, ctx_r1.componentData));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement);
  }
}
function QuestionWidgetDesignerComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵlistener("mouseover", function QuestionWidgetDesignerComponent_ng_template_0_div_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    })("mouseleave", function QuestionWidgetDesignerComponent_ng_template_0_div_0_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    });
    i0.ɵɵtemplate(1, QuestionWidgetDesignerComponent_ng_template_0_div_0_div_1_Template, 7, 10, "div", 3)(2, QuestionWidgetDesignerComponent_ng_template_0_div_0_ng_container_2_Template, 3, 5, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.rootCss());
    i0.ɵɵattribute("data-sv-drop-target-survey-element", ctx_r1.adorner.element.name || null);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.element.isInteractiveDesignElement);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.adorner.element.isInteractiveDesignElement);
  }
}
function QuestionWidgetDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionWidgetDesignerComponent_ng_template_0_div_0_Template, 3, 5, "div", 1);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner);
  }
}
function ToolboxComponent_ng_template_0_ng_container_2_svc_toolbox_category_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-toolbox-category", 5);
  }
  if (rf & 2) {
    const category_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("category", category_r1)("toolbox", ctx_r1.toolbox);
  }
}
function ToolboxComponent_ng_template_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ToolboxComponent_ng_template_0_ng_container_2_svc_toolbox_category_1_Template, 1, 2, "svc-toolbox-category", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.toolbox.categories);
  }
}
function ToolboxComponent_ng_template_0_ng_container_3_svc_toolbox_tool_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-toolbox-tool", 8);
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("creator", ctx_r1.model)("item", item_r3)("parentModel", ctx_r1.toolbox)("isCompact", ctx_r1.toolbox.isCompact);
  }
}
function ToolboxComponent_ng_template_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵtemplate(2, ToolboxComponent_ng_template_0_ng_container_3_svc_toolbox_tool_2_Template, 1, 4, "svc-toolbox-tool", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.toolbox.visibleActions);
  }
}
function ToolboxComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵtemplate(2, ToolboxComponent_ng_template_0_ng_container_2_Template, 2, 1, "ng-container", 3)(3, ToolboxComponent_ng_template_0_ng_container_3_Template, 3, 1, "ng-container", 3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !(ctx_r1.toolbox.categories.length == 1 || !ctx_r1.toolbox.showCategoryTitles));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.toolbox.categories.length == 1 || !ctx_r1.toolbox.showCategoryTitles);
  }
}
function QuestionRatingDesignerComponent_ng_template_0_div_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵlistener("pointerdown", function QuestionRatingDesignerComponent_ng_template_0_div_0_div_3_Template_div_pointerdown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.adorner.onPointerDown($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 14);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(2, "div", 15);
    i0.ɵɵelement(3, "sv-action-bar", 12);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-drag-area-indicator_24x16")("size", 24);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.topActionContainer)("handleClick", false);
  }
}
function QuestionRatingDesignerComponent_ng_template_0_div_0_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "div");
    i0.ɵɵelement(2, "sv-ng-string", 16);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r1.adorner.cssCollapsedHiddenHeader);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.adorner.cssCollapsedHiddenTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r1.model.locTitle);
  }
}
function QuestionRatingDesignerComponent_ng_template_0_div_0_ng_template_5_Template(rf, ctx) {}
function QuestionRatingDesignerComponent_ng_template_0_div_0_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17)(1, "div", 18)(2, "div", 19);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.adorner.placeholderText);
  }
}
function QuestionRatingDesignerComponent_ng_template_0_div_0_7_ng_template_0_Template(rf, ctx) {}
function QuestionRatingDesignerComponent_ng_template_0_div_0_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionRatingDesignerComponent_ng_template_0_div_0_7_ng_template_0_Template, 0, 0, "ng-template", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(1, _c1, ctx_r1.placeholderComponent, ctx_r1.placeholderComponentData));
  }
}
function QuestionRatingDesignerComponent_ng_template_0_div_0_8_ng_template_0_Template(rf, ctx) {}
function QuestionRatingDesignerComponent_ng_template_0_div_0_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionRatingDesignerComponent_ng_template_0_div_0_8_ng_template_0_Template, 0, 0, "ng-template", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(4, _c1, ctx_r1.adornerComponent, i0.ɵɵpureFunction2(1, _c14, ctx_r1.adorner, ctx_r1.model)));
  }
}
function QuestionRatingDesignerComponent_ng_template_0_div_0_svc_question_banner_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "svc-question-banner", 16);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("model", ctx_r1.adorner.createBannerParams());
  }
}
function QuestionRatingDesignerComponent_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3, 1);
    i0.ɵɵlistener("dblclick", function QuestionRatingDesignerComponent_ng_template_0_div_0_Template_div_dblclick_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.dblclick($event));
    })("mouseover", function QuestionRatingDesignerComponent_ng_template_0_div_0_Template_div_mouseover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    })("mouseleave", function QuestionRatingDesignerComponent_ng_template_0_div_0_Template_div_mouseleave_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.hover($event, $event.currentTarget));
    });
    i0.ɵɵelementStart(2, "div", 4);
    i0.ɵɵlistener("click", function QuestionRatingDesignerComponent_ng_template_0_div_0_Template_div_click_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵtemplate(3, QuestionRatingDesignerComponent_ng_template_0_div_0_div_3_Template, 4, 4, "div", 5)(4, QuestionRatingDesignerComponent_ng_template_0_div_0_div_4_Template, 3, 5, "div", 6)(5, QuestionRatingDesignerComponent_ng_template_0_div_0_ng_template_5_Template, 0, 0, "ng-template", 7)(6, QuestionRatingDesignerComponent_ng_template_0_div_0_div_6_Template, 4, 1, "div", 8)(7, QuestionRatingDesignerComponent_ng_template_0_div_0_7_Template, 1, 4, null, 9)(8, QuestionRatingDesignerComponent_ng_template_0_div_0_8_Template, 1, 7, null, 9)(9, QuestionRatingDesignerComponent_ng_template_0_div_0_svc_question_banner_9_Template, 1, 1, "svc-question-banner", 10);
    i0.ɵɵelementStart(10, "div", 11);
    i0.ɵɵlistener("focusin", function QuestionRatingDesignerComponent_ng_template_0_div_0_Template_div_focusin_10_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.select(ctx_r1.adorner, $event));
    });
    i0.ɵɵelement(11, "sv-action-bar", 12);
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.rootCss());
    i0.ɵɵattribute("data-sv-drop-target-survey-element", ctx_r1.adorner.element.name || null);
    i0.ɵɵadvance(2);
    i0.ɵɵclassMap(ctx_r1.adorner.css());
    i0.ɵɵproperty("key2click", i0.ɵɵpureFunction0(15, _c13));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowDragging);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.model.hasTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("component", i0.ɵɵpureFunction2(16, _c1, ctx_r1.componentName, ctx_r1.componentData));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement && !ctx_r1.showPlaceholderComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isEmptyElement && !!ctx_r1.showPlaceholderComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adornerComponent);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.isBannerShowing);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("model", ctx_r1.adorner.actionContainer)("handleClick", false);
  }
}
function QuestionRatingDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, QuestionRatingDesignerComponent_ng_template_0_div_0_Template, 12, 19, "div", 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner);
  }
}
function QuestionRatingAdornerDesignerComponent_ng_template_0__svg_svg_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 4);
    i0.ɵɵlistener("click", function QuestionRatingAdornerDesignerComponent_ng_template_0__svg_svg_2_Template_svg_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.removeItem(ctx_r1.adorner));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.removeClassNames);
    i0.ɵɵproperty("iconName", "icon-remove_16x16")("size", 16);
    i0.ɵɵattribute("title", ctx_r1.adorner.removeTooltip)("aria-label", ctx_r1.adorner.removeTooltip);
  }
}
function QuestionRatingAdornerDesignerComponent_ng_template_0__svg_svg_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 4);
    i0.ɵɵlistener("click", function QuestionRatingAdornerDesignerComponent_ng_template_0__svg_svg_3_Template_svg_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.adorner.addItem(ctx_r1.adorner));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r1.adorner.addClassNames);
    i0.ɵɵproperty("iconName", "icon-add_16x16")("size", 16);
    i0.ɵɵattribute("title", ctx_r1.adorner.addTooltip)("aria-label", ctx_r1.adorner.addTooltip);
  }
}
function QuestionRatingAdornerDesignerComponent_ng_template_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function QuestionRatingAdornerDesignerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div");
    i0.ɵɵtemplate(2, QuestionRatingAdornerDesignerComponent_ng_template_0__svg_svg_2_Template, 1, 6, "svg", 2)(3, QuestionRatingAdornerDesignerComponent_ng_template_0__svg_svg_3_Template, 1, 6, "svg", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, QuestionRatingAdornerDesignerComponent_ng_template_0_ng_container_4_Template, 1, 0, "ng-container", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.adorner.controlsClassNames);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowRemove);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.adorner.allowAdd);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.contentTempl);
  }
}
function CreatorLogoImageComponent_ng_template_0_ng_container_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵlistener("click", function CreatorLogoImageComponent_ng_template_0_ng_container_3_ng_container_1_Template_div_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.model.chooseFile(ctx_r1.model));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(2, "svg");
    i0.ɵɵelement(3, "use", 7);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
}
function CreatorLogoImageComponent_ng_template_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CreatorLogoImageComponent_ng_template_0_ng_container_3_ng_container_1_Template, 4, 0, "ng-container", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.allowEdit && !ctx_r1.model.isUploading);
  }
}
function CreatorLogoImageComponent_ng_template_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div")(2, "div", 8)(3, "span", 9);
    i0.ɵɵlistener("click", function CreatorLogoImageComponent_ng_template_0_ng_container_4_Template_span_click_3_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.model.chooseFile(ctx_r1.model));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(4, "svg", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(5, "span", 11);
    i0.ɵɵlistener("click", function CreatorLogoImageComponent_ng_template_0_ng_container_4_Template_span_click_5_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.model.remove(ctx_r1.model));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(6, "svg", 10);
    i0.ɵɵelementEnd()();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelement(7, "sv-logo-image", 12);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.model.containerCss);
    i0.ɵɵadvance(2);
    i0.ɵɵattribute("title", undefined)("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-file")("size", 24);
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", undefined)("aria-label", undefined);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", "icon-clear")("size", 24);
    i0.ɵɵadvance();
    i0.ɵɵproperty("data", ctx_r1.survey);
  }
}
function CreatorLogoImageComponent_ng_template_0_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵelement(1, "sv-ng-loading-indicator");
    i0.ɵɵelementEnd();
  }
}
function CreatorLogoImageComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2, 1);
    i0.ɵɵelement(2, "input", 3);
    i0.ɵɵtemplate(3, CreatorLogoImageComponent_ng_template_0_ng_container_3_Template, 2, 1, "ng-container", 4)(4, CreatorLogoImageComponent_ng_template_0_ng_container_4_Template, 8, 11, "ng-container", 4)(5, CreatorLogoImageComponent_ng_template_0_div_5_Template, 2, 0, "div", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("accept", ctx_r1.model.acceptedTypes);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.survey.locLogo.renderedHtml);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.survey.locLogo.renderedHtml && !ctx_r1.model.isUploading);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.isUploading);
  }
}
function ColorItemComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1)(1, "sv-ng-string", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(ctx_r0.getStyle());
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.locTitle);
  }
}
function QuestionColorComponent_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "sv-action-bar-item", 5)(2, "sv-ng-popup", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model.dropdownAction);
    i0.ɵɵadvance();
    i0.ɵɵproperty("popupModel", ctx_r0.model.dropdownAction.popupModel);
  }
}
function QuestionTextWithResetComponent_ng_template_1_Template(rf, ctx) {}
function JsonErrorItemComponent_ng_template_0_button_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵlistener("click", function JsonErrorItemComponent_ng_template_0_button_4_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.fixError($event));
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(1, "svg", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("title", ctx_r1.model.data.fixButtonTitle)("aria-label", ctx_r1.model.data.fixButtonTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("iconName", ctx_r1.model.data.fixButtonIcon)("size", "auto");
  }
}
function JsonErrorItemComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 1);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(1, "div", 2)(2, "div", 3);
    i0.ɵɵelement(3, "sv-ng-string", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, JsonErrorItemComponent_ng_template_0_button_4_Template, 2, 4, "button", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("iconName", ctx_r1.model.iconName)("size", ctx_r1.model.iconSize)("css", "svc-json-error__icon");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("model", ctx_r1.model.locTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.model.data.showFixButton);
  }
}
function TranslateFromAction_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "sv-action-bar-item-dropdown", 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.model.data.containerCss);
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r0.model.data.additionalTitleCss);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.model.data.additionalTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("model", ctx_r0.model);
  }
}
class TabbedMenuItemWrapperComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
TabbedMenuItemWrapperComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTabbedMenuItemWrapperComponent_BaseFactory;
  return function TabbedMenuItemWrapperComponent_Factory(__ngFactoryType__) {
    return (ɵTabbedMenuItemWrapperComponent_BaseFactory || (ɵTabbedMenuItemWrapperComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TabbedMenuItemWrapperComponent)))(__ngFactoryType__ || TabbedMenuItemWrapperComponent);
  };
})();
TabbedMenuItemWrapperComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TabbedMenuItemWrapperComponent,
  selectors: [["svc-tabbed-menu-item-wrapper"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-tabbed-menu-item-container"], [1, "sv-action__content"], [3, "component"]],
  template: function TabbedMenuItemWrapperComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TabbedMenuItemWrapperComponent_ng_template_0_Template, 3, 10, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabbedMenuItemWrapperComponent, [{
    type: Component,
    args: [{
      selector: "svc-tabbed-menu-item-wrapper",
      templateUrl: "./tabbed-menu-item-wrapper.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
class TabbledMenuComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  ngAfterViewInit() {
    this.responsivityManager = new ResponsivityManager(this.container.nativeElement, this.model, ".svc-tabbed-menu-item-container:not(.sv-dots)>.sv-action__content");
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.responsivityManager.dispose();
  }
}
TabbledMenuComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTabbledMenuComponent_BaseFactory;
  return function TabbledMenuComponent_Factory(__ngFactoryType__) {
    return (ɵTabbledMenuComponent_BaseFactory || (ɵTabbledMenuComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TabbledMenuComponent)))(__ngFactoryType__ || TabbledMenuComponent);
  };
})();
TabbledMenuComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TabbledMenuComponent,
  selectors: [["svc-tabbed-menu"]],
  viewQuery: function TabbledMenuComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], [1, "svc-tabbed-menu"], [4, "ngFor", "ngForOf"], [3, "model"]],
  template: function TabbledMenuComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TabbledMenuComponent_ng_template_0_Template, 3, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [TabbedMenuItemWrapperComponent, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabbledMenuComponent, [{
    type: Component,
    args: [{
      selector: "svc-tabbed-menu",
      templateUrl: "./tabbed-menu.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ["container"]
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tabbed-menu", TabbledMenuComponent);
class SidebarTabComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
SidebarTabComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵSidebarTabComponent_BaseFactory;
  return function SidebarTabComponent_Factory(__ngFactoryType__) {
    return (ɵSidebarTabComponent_BaseFactory || (ɵSidebarTabComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SidebarTabComponent)))(__ngFactoryType__ || SidebarTabComponent);
  };
})();
SidebarTabComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SidebarTabComponent,
  selectors: [["svc-side-bar-tab"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [4, "ngIf"], [3, "component"]],
  template: function SidebarTabComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, SidebarTabComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i2.NgIf, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarTabComponent, [{
    type: Component,
    args: [{
      selector: "svc-side-bar-tab",
      templateUrl: "./side-bar-tab.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
class SidebarComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  ngOnDestroy() {
    this.model.resetResizeManager();
    super.ngOnDestroy();
  }
  ngAfterViewInit() {
    this.model.initResizeManager(this.container.nativeElement);
  }
}
SidebarComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵSidebarComponent_BaseFactory;
  return function SidebarComponent_Factory(__ngFactoryType__) {
    return (ɵSidebarComponent_BaseFactory || (ɵSidebarComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SidebarComponent)))(__ngFactoryType__ || SidebarComponent);
  };
})();
SidebarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SidebarComponent,
  selectors: [["svc-side-bar"]],
  viewQuery: function SidebarComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], [1, "svc-side-bar", 3, "visible"], [1, "svc-side-bar__shadow", 3, "click"], [1, "svc-flex-column", "svc-side-bar__wrapper"], [1, "svc-side-bar__container", 3, "visible"], [1, "svc-side-bar__container-header"], [1, "svc-side-bar__container-actions"], [3, "model"], ["class", "svc-side-bar__container-title", 4, "ngIf"], [1, "svc-side-bar__container-content"], [4, "ngFor", "ngForOf"], [1, "svc-side-bar__container-title"]],
  template: function SidebarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, SidebarComponent_ng_template_0_Template, 11, 7, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.ActionBarComponent, SidebarTabComponent, i1.VisibleDirective, i2.NgIf, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SidebarComponent, [{
    type: Component,
    args: [{
      selector: "svc-side-bar",
      templateUrl: "./side-bar.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ["container"]
    }]
  });
})();
class CreatorComponent extends BaseAngular {
  constructor(changeDetectorRef) {
    super(changeDetectorRef);
    changeDetectorRef.detach();
  }
  getModel() {
    return this.model;
  }
  get creator() {
    return this.model;
  }
  getShouldReattachChangeDetector() {
    return false;
  }
  onModelChanged() {
    this.changeDetectorRef.detectChanges();
    if (this.previousModel) {
      this.previousModel.unsubscribeRootElement();
    }
    if (this.creator && this.container.nativeElement) {
      this.creator.setRootElement(this.container.nativeElement);
    }
  }
  ngAfterViewInit() {
    this.creator.setRootElement(this.container.nativeElement);
    super.ngOnInit();
  }
  ngOnDestroy() {
    this.creator.unsubscribeRootElement();
    super.ngOnDestroy();
  }
}
CreatorComponent.ɵfac = function CreatorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CreatorComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
CreatorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CreatorComponent,
  selectors: [["survey-creator"]],
  viewQuery: function CreatorComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [["container", ""], [4, "ngIf"], [1, "svc-creator"], [1, "svc-full-container", "svc-creator__area", "svc-flex-column"], [1, "svc-flex-row", "svc-full-container"], [1, "svc-flex-column", "svc-flex-row__element", "svc-flex-row__element--growing"], [1, "svc-top-bar"], [1, "svc-tabbed-menu-wrapper", 3, "visible"], [3, "model"], ["class", "svc-toolbar-wrapper", 3, "visible", 4, "ngIf"], [1, "svc-creator__content-wrapper", "svc-flex-row"], [1, "svc-creator__content-holder", "svc-flex-column"], [4, "ngFor", "ngForOf"], ["class", "svc-footer-bar", 4, "ngIf"], [3, "sv-mobile-side-bar", 4, "ngIf"], ["class", "svc-creator__banner", 4, "ngIf"], [3, "notifier"], [1, "svc-toolbar-wrapper", 3, "visible"], ["class", "svc-creator-tab", 3, "svc-creator__toolbox--right", 4, "ngIf"], [1, "svc-creator-tab"], [3, "component"], [1, "svc-footer-bar"], [1, "svc-creator__banner"], [1, "svc-creator__non-commercial-text", 3, "innerHTML"]],
  template: function CreatorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, CreatorComponent_ng_container_0_Template, 20, 18, "ng-container", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.creator.isCreatorDisposed);
    }
  },
  dependencies: [i1.ModalComponent, i1.SvgBundleComponent, TabbledMenuComponent, i1.ActionBarComponent, SidebarComponent, i1.NotifierComponent, i2.NgIf, i1.VisibleDirective, i2.NgForOf, i1.DynamicComponentDirective, i1.SafeHtmlPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CreatorComponent, [{
    type: Component,
    args: [{
      selector: "survey-creator",
      templateUrl: "./creator.component.html"
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    model: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ["container", {
        read: ElementRef
      }]
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("survey-widget", SurveyContentComponent);
class CreatorModelComponent extends BaseAngular {
  createHash() {
    this.hash = {};
    this.getPropertiesToTrack().forEach(prop => {
      this.hash[prop] = this[prop];
    });
  }
  ngOnInit() {
    this.createModel();
    this.createHash();
    super.ngOnInit();
  }
  ngDoCheck() {
    if (this.needUpdateModel()) this.createModel();
    super.ngDoCheck();
  }
  needUpdateModel() {
    let res = false;
    Object.keys(this.hash).forEach(key => {
      if (this.hash[key] != this[key]) {
        this.hash[key] = this[key];
        res = true;
      }
    });
    return res;
  }
}
CreatorModelComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCreatorModelComponent_BaseFactory;
  return function CreatorModelComponent_Factory(__ngFactoryType__) {
    return (ɵCreatorModelComponent_BaseFactory || (ɵCreatorModelComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CreatorModelComponent)))(__ngFactoryType__ || CreatorModelComponent);
  };
})();
CreatorModelComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CreatorModelComponent,
  selectors: [["ng-component"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function CreatorModelComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CreatorModelComponent, [{
    type: Component,
    args: [{
      template: ""
    }]
  }], null, null);
})();
class ToolboxToolComponent extends CreatorModelComponent {
  constructor() {
    super(...arguments);
    this.isCompact = false;
  }
  createModel() {
    this.model = new ToolboxToolViewModel(this.item, this.creator, this.parentModel);
  }
  getPropertiesToTrack() {
    return ["creator", "item"];
  }
  getModel() {
    return this.item;
  }
  get itemCssClasses() {
    return this.item.css;
  }
  getPropertiesToUpdateSync() {
    return ["mode"];
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.model.dispose();
  }
}
ToolboxToolComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵToolboxToolComponent_BaseFactory;
  return function ToolboxToolComponent_Factory(__ngFactoryType__) {
    return (ɵToolboxToolComponent_BaseFactory || (ɵToolboxToolComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ToolboxToolComponent)))(__ngFactoryType__ || ToolboxToolComponent);
  };
})();
ToolboxToolComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToolboxToolComponent,
  selectors: [["svc-toolbox-tool"]],
  inputs: {
    creator: "creator",
    item: "item",
    parentModel: "parentModel",
    isCompact: "isCompact"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["class", "svc-toolbox__category-separator", 4, "ngIf"], [1, "sv-action__content", 3, "pointerdown", "mouseover", "mouseleave"], [3, "component"], [1, "svc-toolbox__category-separator"]],
  template: function ToolboxToolComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ToolboxToolComponent_ng_template_0_Template, 4, 12, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i2.NgIf, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolboxToolComponent, [{
    type: Component,
    args: [{
      selector: "svc-toolbox-tool",
      templateUrl: "./toolbox-tool.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    creator: [{
      type: Input
    }],
    item: [{
      type: Input
    }],
    parentModel: [{
      type: Input
    }],
    isCompact: [{
      type: Input
    }]
  });
})();
class SearchComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
SearchComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵSearchComponent_BaseFactory;
  return function SearchComponent_Factory(__ngFactoryType__) {
    return (ɵSearchComponent_BaseFactory || (ɵSearchComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SearchComponent)))(__ngFactoryType__ || SearchComponent);
  };
})();
SearchComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SearchComponent,
  selectors: [["svc-search"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["class", "spg-search-editor_container", 4, "ngIf"], [1, "spg-search-editor_container"], [1, "spg-search-editor_search-icon"], ["sv-ng-svg-icon", "", 3, "iconName", "size"], ["type", "text", 1, "spg-search-editor_input", 3, "ngModelChange", "ngModel"], [1, "spg-search-editor_toolbar"], [1, "spg-search-editor_toolbar-counter"], [3, "model"]],
  template: function SearchComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, SearchComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.ActionBarComponent, i2.NgIf, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SearchComponent, [{
    type: Component,
    args: [{
      selector: "svc-search",
      templateUrl: "./search.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-search", SearchComponent);
class ToolboxCategoryComponent extends BaseAngular {
  getModel() {
    return this.category;
  }
}
ToolboxCategoryComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵToolboxCategoryComponent_BaseFactory;
  return function ToolboxCategoryComponent_Factory(__ngFactoryType__) {
    return (ɵToolboxCategoryComponent_BaseFactory || (ɵToolboxCategoryComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ToolboxCategoryComponent)))(__ngFactoryType__ || ToolboxCategoryComponent);
  };
})();
ToolboxCategoryComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToolboxCategoryComponent,
  selectors: [["svc-toolbox-category"]],
  inputs: {
    category: "category",
    toolbox: "toolbox"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-toolbox__category"], [1, "svc-toolbox__category-header", 3, "click", "key2click"], [1, "svc-toolbox__category-title"], ["class", "svc-toolbox__category-header__controls", 4, "ngIf"], [4, "ngFor", "ngForOf"], [1, "svc-toolbox__category-header__controls"], ["sv-ng-svg-icon", "", 1, "svc-toolbox__category-header__button", "svc-string-editor__button--expand", 3, "iconName", "size", "visible"], ["sv-ng-svg-icon", "", 1, "svc-toolbox__category-header__button", "svc-string-editor__button--collapse", 3, "iconName", "size", "visible"], [3, "item", "creator", "parentModel", "isCompact"]],
  template: function ToolboxCategoryComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ToolboxCategoryComponent_ng_template_0_Template, 6, 9, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, ToolboxToolComponent, i1.Key2ClickDirective, i2.NgIf, i1.VisibleDirective, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolboxCategoryComponent, [{
    type: Component,
    args: [{
      selector: "svc-toolbox-category ",
      templateUrl: "./toolbox-category.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    category: [{
      type: Input
    }],
    toolbox: [{
      type: Input
    }]
  });
})();
class AdaptiveToolboxComponent extends BaseAngular {
  get model() {
    return this.creator.toolbox;
  }
  get searchItem() {
    return this.model.searchItem;
  }
  ngAfterViewInit() {
    this.model.setRootElement(this.container.nativeElement);
    this.responsivityManager = new VerticalResponsivityManager(this.model.containerElement, this.model, this.model.itemSelector);
  }
  getModel() {
    return this.model;
  }
  ngOnDestroy() {
    var _a;
    (_a = this.responsivityManager) === null || _a === void 0 ? void 0 : _a.dispose();
    super.ngOnDestroy();
  }
}
AdaptiveToolboxComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵAdaptiveToolboxComponent_BaseFactory;
  return function AdaptiveToolboxComponent_Factory(__ngFactoryType__) {
    return (ɵAdaptiveToolboxComponent_BaseFactory || (ɵAdaptiveToolboxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(AdaptiveToolboxComponent)))(__ngFactoryType__ || AdaptiveToolboxComponent);
  };
})();
AdaptiveToolboxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AdaptiveToolboxComponent,
  selectors: [["svc-adaptive-toolbox"]],
  viewQuery: function AdaptiveToolboxComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    creator: "creator"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], [1, "svc-toolbox__panel", 3, "focusout"], [1, "svc-toolbox__scroller", 3, "scroll"], ["class", "svc-toolbox__search-container", 4, "ngIf"], ["class", "svc-toolbox__placeholder", 4, "ngIf"], [1, "svc-toolbox__container"], [4, "ngIf"], [1, "svc-toolbox__search-container"], [3, "model"], [3, "creator", "item", "parentModel", "isCompact"], [1, "svc-toolbox__category-separator", "svc-toolbox__category-separator--search"], [1, "svc-toolbox__placeholder"], [3, "category", "toolbox", 4, "ngFor", "ngForOf"], [3, "category", "toolbox"], [1, "svc-toolbox__category"], [4, "ngFor", "ngForOf"]],
  template: function AdaptiveToolboxComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, AdaptiveToolboxComponent_ng_template_0_Template, 9, 6, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [ToolboxToolComponent, SearchComponent, ToolboxCategoryComponent, i2.NgIf, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdaptiveToolboxComponent, [{
    type: Component,
    args: [{
      selector: "svc-adaptive-toolbox",
      templateUrl: "./adaptive-toolbox.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    creator: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ["container"]
    }]
  });
})();
class PageDesignerComponent extends CreatorModelComponent {
  createModel() {
    if (this.model) {
      this.adorner = new PageAdorner(this.creator, this.model);
    }
  }
  getModel() {
    return this.adorner;
  }
  getPropertiesToTrack() {
    return ["creator", "model"];
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.adorner.dispose();
  }
}
PageDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵPageDesignerComponent_BaseFactory;
  return function PageDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵPageDesignerComponent_BaseFactory || (ɵPageDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PageDesignerComponent)))(__ngFactoryType__ || PageDesignerComponent);
  };
})();
PageDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PageDesignerComponent,
  selectors: [["svc-page"]],
  inputs: {
    model: "model",
    survey: "survey",
    creator: "creator",
    isGhost: "isGhost"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 1,
  consts: [["class", "svc-page__content", "data-bind", "clickBubble: false", 3, "class", "key2click", "id", "click", "dblclick", "mouseover", "mouseleave", 4, "ngIf"], ["data-bind", "clickBubble: false", 1, "svc-page__content", 3, "click", "dblclick", "mouseover", "mouseleave", "key2click", "id"], [1, "svc-page__content-actions"], [3, "model"], [3, "model", "survey"], ["class", "svc-page__placeholder_frame", 4, "ngIf"], [1, "svc-page__placeholder_frame"], [1, "svc-panel__placeholder_frame"], [1, "svc-panel__placeholder"]],
  template: function PageDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PageDesignerComponent_div_0_Template, 6, 8, "div", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.model && ctx.adorner && (ctx.isGhost === undefined || ctx.isGhost === ctx.adorner.isGhost));
    }
  },
  dependencies: [i1.ActionBarComponent, i1.PageComponent, i2.NgIf, i1.Key2ClickDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PageDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-page",
      templateUrl: "./page.component.html",
      styles: []
    }]
  }], null, {
    model: [{
      type: Input
    }],
    survey: [{
      type: Input
    }],
    creator: [{
      type: Input
    }],
    isGhost: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-page", PageDesignerComponent);
class DesignerPagesComponent extends BaseAngular {
  getModel() {
    return this.model.pagesController;
  }
  get creator() {
    return this.model.creator;
  }
  get survey() {
    return this.creator.survey;
  }
}
DesignerPagesComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵDesignerPagesComponent_BaseFactory;
  return function DesignerPagesComponent_Factory(__ngFactoryType__) {
    return (ɵDesignerPagesComponent_BaseFactory || (ɵDesignerPagesComponent_BaseFactory = i0.ɵɵgetInheritedFactory(DesignerPagesComponent)))(__ngFactoryType__ || DesignerPagesComponent);
  };
})();
DesignerPagesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DesignerPagesComponent,
  selectors: [["svc-designer-pages"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-page"], [3, "component"]],
  template: function DesignerPagesComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, DesignerPagesComponent_ng_template_0_Template, 2, 9, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DesignerPagesComponent, [{
    type: Component,
    args: [{
      selector: "svc-designer-pages",
      templateUrl: "./designer-pages.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
class PageNavigatorItemComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  get anyModel() {
    return this.model;
  }
  click(event) {
    this.getModel().action();
    event.stopPropagation();
    event.preventDefault();
  }
}
PageNavigatorItemComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵPageNavigatorItemComponent_BaseFactory;
  return function PageNavigatorItemComponent_Factory(__ngFactoryType__) {
    return (ɵPageNavigatorItemComponent_BaseFactory || (ɵPageNavigatorItemComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PageNavigatorItemComponent)))(__ngFactoryType__ || PageNavigatorItemComponent);
  };
})();
PageNavigatorItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PageNavigatorItemComponent,
  selectors: [["svc-page-navigator-item"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-page-navigator-item"], [1, "svc-page-navigator-item-content", 3, "click", "key2click"], [1, "svc-page-navigator-item__dot"], [1, "svc-page-navigator-item__banner"], [1, "svc-text", "svc-text--small", "svc-text--bold"]],
  template: function PageNavigatorItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PageNavigatorItemComponent_ng_template_0_Template, 7, 6, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PageNavigatorItemComponent, [{
    type: Component,
    args: [{
      selector: "svc-page-navigator-item",
      templateUrl: "./page-navigator-item.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
class PageNavigatorComponent extends CreatorModelComponent {
  createModel() {
    this.model = new PageNavigatorViewModel(this.pagesController, this.pageEditMode);
  }
  getModel() {
    return this.model;
  }
  getPropertiesToTrack() {
    return ["pagesController", "pageEditMode"];
  }
  ngAfterViewInit() {
    var _a, _b;
    if (this.pageEditMode !== "bypage") {
      const el = this.container.nativeElement;
      if (!!el && !!((_b = (_a = el.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement)) {
        const self = this;
        const scrollableViewPort = el.parentElement.parentElement.parentElement;
        scrollableViewPort.onscroll = function (ev) {
          return self.model.onContainerScroll(ev.currentTarget);
        };
        self.model.setScrollableContainer(scrollableViewPort);
        self.model.setItemsContainer(el.parentElement);
      }
    }
  }
  ngOnDestroy() {
    var _a, _b;
    super.ngOnDestroy();
    const el = this.container.nativeElement;
    if (!!el && !!((_b = (_a = el.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement)) {
      el.parentElement.parentElement.parentElement.onscroll = undefined;
    }
    this.model.stopItemsContainerHeightObserver();
    this.model.dispose();
  }
}
PageNavigatorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵPageNavigatorComponent_BaseFactory;
  return function PageNavigatorComponent_Factory(__ngFactoryType__) {
    return (ɵPageNavigatorComponent_BaseFactory || (ɵPageNavigatorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PageNavigatorComponent)))(__ngFactoryType__ || PageNavigatorComponent);
  };
})();
PageNavigatorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PageNavigatorComponent,
  selectors: [["svc-page-navigator"]],
  viewQuery: function PageNavigatorComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    pagesController: "pagesController",
    pageEditMode: "pageEditMode"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], [1, "svc-page-navigator", 3, "visible"], [1, "svc-page-navigator__selector", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 1, "svc-page-navigator__navigator-icon", 3, "iconName", "size"], [3, "popupModel"], [3, "model", 4, "ngFor", "ngForOf"], [3, "model"]],
  template: function PageNavigatorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PageNavigatorComponent_ng_template_0_Template, 7, 8, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.PopupComponent, PageNavigatorItemComponent, i1.VisibleDirective, i1.Key2ClickDirective, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PageNavigatorComponent, [{
    type: Component,
    args: [{
      selector: "svc-page-navigator",
      templateUrl: "./page-navigator.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    pagesController: [{
      type: Input
    }],
    pageEditMode: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ["container"]
    }]
  });
})();
class DesignerSurveyComponent extends BaseAngular {
  getModel() {
    return this.model.survey;
  }
  get creator() {
    return this.model.creator;
  }
  get survey() {
    return this.creator.survey;
  }
}
DesignerSurveyComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵDesignerSurveyComponent_BaseFactory;
  return function DesignerSurveyComponent_Factory(__ngFactoryType__) {
    return (ɵDesignerSurveyComponent_BaseFactory || (ɵDesignerSurveyComponent_BaseFactory = i0.ɵɵgetInheritedFactory(DesignerSurveyComponent)))(__ngFactoryType__ || DesignerSurveyComponent);
  };
})();
DesignerSurveyComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DesignerSurveyComponent,
  selectors: [["svc-designer-survey"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 0,
  consts: [["template", ""], ["pageContainer", ""], ["class", "svc-designer-header", 4, "ngIf"], [4, "ngIf"], ["class", "svc-tab-designer__page-navigator", 4, "ngIf"], ["class", "svc-tab-designer__toolbar", 4, "ngIf"], [1, "svc-designer-header"], ["sv-ng-survey-header", "", 3, "class", "survey", 4, "ngIf"], ["sv-ng-survey-header", "", 3, "survey"], [4, "ngFor", "ngForOf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "model"], [1, "svc-tab-designer__page-navigator"], [3, "pagesController", "pageEditMode"], [1, "svc-tab-designer__toolbar"], [1, "svc-page"], [3, "component"]],
  template: function DesignerSurveyComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, DesignerSurveyComponent_ng_template_0_Template, 6, 11, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(2, DesignerSurveyComponent_ng_template_2_Template, 2, 10, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SurveyHeaderComponent, DesignerPagesComponent, PageNavigatorComponent, i1.ActionBarComponent, i2.NgIf, i2.NgForOf, i2.NgTemplateOutlet, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DesignerSurveyComponent, [{
    type: Component,
    args: [{
      selector: "svc-designer-survey",
      templateUrl: "./designer-survey.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
class DesignerTabComponent extends BaseAngular {
  get survey() {
    return this.creator.survey;
  }
  get creator() {
    return this.model.creator;
  }
  getModel() {
    return this.model;
  }
}
DesignerTabComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵDesignerTabComponent_BaseFactory;
  return function DesignerTabComponent_Factory(__ngFactoryType__) {
    return (ɵDesignerTabComponent_BaseFactory || (ɵDesignerTabComponent_BaseFactory = i0.ɵɵgetInheritedFactory(DesignerTabComponent)))(__ngFactoryType__ || DesignerTabComponent);
  };
})();
DesignerTabComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DesignerTabComponent,
  selectors: [["svc-tab-designer"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-flex-column"], [3, "creator", 4, "ngIf"], [1, "svc-tab-designer", 3, "click"], [1, "svc-tab-designer_content"], [4, "ngIf"], [3, "creator"], ["class", "svc-designer-header", 4, "ngIf"], [1, "svc-designer__placeholder-container"], [1, "svc-designer-placeholder-text", "svc-text", "svc-text--normal"], [1, "svc-designer-placeholder-page", 3, "model", "survey", "creator"], [1, "svc-designer-header"], ["sv-ng-survey-header", "", 3, "class", "survey", 4, "ngIf"], ["sv-ng-survey-header", "", 3, "survey"], [3, "model"]],
  template: function DesignerTabComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, DesignerTabComponent_ng_template_0_Template, 6, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [AdaptiveToolboxComponent, i1.SurveyHeaderComponent, PageDesignerComponent, DesignerSurveyComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DesignerTabComponent, [{
    type: Component,
    args: [{
      selector: "svc-tab-designer",
      templateUrl: "./designer.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tab-designer", DesignerTabComponent);
class TabbedMenuItemComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
TabbedMenuItemComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTabbedMenuItemComponent_BaseFactory;
  return function TabbedMenuItemComponent_Factory(__ngFactoryType__) {
    return (ɵTabbedMenuItemComponent_BaseFactory || (ɵTabbedMenuItemComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TabbedMenuItemComponent)))(__ngFactoryType__ || TabbedMenuItemComponent);
  };
})();
TabbedMenuItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TabbedMenuItemComponent,
  selectors: [["svc-tabbed-menu-item"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-tabbed-menu-item", 3, "click", "key2click"], [1, "svc-text", "svc-text--normal", "svc-tabbed-menu-item__text"]],
  template: function TabbedMenuItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TabbedMenuItemComponent_ng_template_0_Template, 3, 7, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabbedMenuItemComponent, [{
    type: Component,
    args: [{
      selector: "svc-tabbed-menu-item",
      templateUrl: "./tabbed-menu-item.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tabbed-menu-item", TabbedMenuItemComponent);
class ObjectSelectorComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
ObjectSelectorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵObjectSelectorComponent_BaseFactory;
  return function ObjectSelectorComponent_Factory(__ngFactoryType__) {
    return (ɵObjectSelectorComponent_BaseFactory || (ɵObjectSelectorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ObjectSelectorComponent)))(__ngFactoryType__ || ObjectSelectorComponent);
  };
})();
ObjectSelectorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ObjectSelectorComponent,
  selectors: [["svc-object-selector"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [4, "ngIf"], [3, "model"]],
  template: function ObjectSelectorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ObjectSelectorComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.ListComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ObjectSelectorComponent, [{
    type: Component,
    args: [{
      selector: "svc-object-selector",
      templateUrl: "./object-selector.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-object-selector", ObjectSelectorComponent);
class PropertyGridComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  getPropertiesToUpdateSync() {
    return ["survey"];
  }
}
PropertyGridComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵPropertyGridComponent_BaseFactory;
  return function PropertyGridComponent_Factory(__ngFactoryType__) {
    return (ɵPropertyGridComponent_BaseFactory || (ɵPropertyGridComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PropertyGridComponent)))(__ngFactoryType__ || PropertyGridComponent);
  };
})();
PropertyGridComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PropertyGridComponent,
  selectors: [["svc-property-grid"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "spg-container"], [3, "model"], [3, "model", 4, "ngIf"]],
  template: function PropertyGridComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PropertyGridComponent_ng_template_0_Template, 3, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [SearchComponent, i1.SurveyContentComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PropertyGridComponent, [{
    type: Component,
    args: [{
      selector: "svc-property-grid",
      templateUrl: "./property-grid.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("buttongroup-question", ButtonGroupQuestionComponent);
AngularComponentFactory.Instance.registerComponent("svc-property-grid", PropertyGridComponent);
class TextareaJsonEditorComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  ngOnInit() {
    this.model.canShowErrors = false;
    super.ngOnInit();
  }
  ngAfterViewInit() {
    this.model.textElement = this.inputEl.nativeElement;
  }
}
TextareaJsonEditorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTextareaJsonEditorComponent_BaseFactory;
  return function TextareaJsonEditorComponent_Factory(__ngFactoryType__) {
    return (ɵTextareaJsonEditorComponent_BaseFactory || (ɵTextareaJsonEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TextareaJsonEditorComponent)))(__ngFactoryType__ || TextareaJsonEditorComponent);
  };
})();
TextareaJsonEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TextareaJsonEditorComponent,
  selectors: [["svc-tab-json-editor-textarea"]],
  viewQuery: function TextareaJsonEditorComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c10, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputEl = _t.first);
    }
  },
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["inputEl", ""], [1, "svc-creator-tab__content"], [1, "svc-json-editor-tab__content"], [1, "svc-json-editor-tab__content-area", 3, "ngModelChange", "keydown", "disabled", "ngModel"], [1, "svc-json-editor-tab__errros_list", 3, "visible"], [3, "model"]],
  template: function TextareaJsonEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TextareaJsonEditorComponent_ng_template_0_Template, 7, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.ListComponent, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel, i1.VisibleDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextareaJsonEditorComponent, [{
    type: Component,
    args: [{
      selector: "svc-tab-json-editor-textarea",
      templateUrl: "./json-editor-textarea.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }],
    inputEl: [{
      type: ViewChild,
      args: ["inputEl"]
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tab-json-editor-textarea", TextareaJsonEditorComponent);
class AceJsonEditorComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  ngAfterViewInit() {
    //todo fix ts 
    this.model.init(window.ace.edit(this.inputEl.nativeElement));
  }
}
AceJsonEditorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵAceJsonEditorComponent_BaseFactory;
  return function AceJsonEditorComponent_Factory(__ngFactoryType__) {
    return (ɵAceJsonEditorComponent_BaseFactory || (ɵAceJsonEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(AceJsonEditorComponent)))(__ngFactoryType__ || AceJsonEditorComponent);
  };
})();
AceJsonEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AceJsonEditorComponent,
  selectors: [["svc-tab-json-editor-ace"]],
  viewQuery: function AceJsonEditorComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c10, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputEl = _t.first);
    }
  },
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["inputEl", ""], [1, "svc-creator-tab__content"], [1, "svc-json-editor-tab__content"], [1, "svc-json-editor-tab__ace-editor"], [1, "svc-json-editor-tab__errros_list", 3, "visible"], [3, "model"]],
  template: function AceJsonEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, AceJsonEditorComponent_ng_template_0_Template, 6, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.ListComponent, i1.VisibleDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AceJsonEditorComponent, [{
    type: Component,
    args: [{
      selector: "svc-tab-json-editor-ace",
      templateUrl: "./json-editor-ace.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }],
    inputEl: [{
      type: ViewChild,
      args: ["inputEl"]
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tab-json-editor-ace", AceJsonEditorComponent);
class LogicAddButtonComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  onClick(event) {
    event.stopPropagation();
    this.model.action();
  }
}
LogicAddButtonComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵLogicAddButtonComponent_BaseFactory;
  return function LogicAddButtonComponent_Factory(__ngFactoryType__) {
    return (ɵLogicAddButtonComponent_BaseFactory || (ɵLogicAddButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LogicAddButtonComponent)))(__ngFactoryType__ || LogicAddButtonComponent);
  };
})();
LogicAddButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LogicAddButtonComponent,
  selectors: [["svc-tab-logic-add-btn"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["role", "button", 1, "svc-logic-tab__content-action", "svc-btn", 3, "click", "key2click"], [1, "svc-text", "svc-text--normal", "svc-text--bold"]],
  template: function LogicAddButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, LogicAddButtonComponent_ng_template_0_Template, 3, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LogicAddButtonComponent, [{
    type: Component,
    args: [{
      selector: "svc-tab-logic-add-btn",
      templateUrl: "./logic-add-btn.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
class LogicTabComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
LogicTabComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵLogicTabComponent_BaseFactory;
  return function LogicTabComponent_Factory(__ngFactoryType__) {
    return (ɵLogicTabComponent_BaseFactory || (ɵLogicTabComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LogicTabComponent)))(__ngFactoryType__ || LogicTabComponent);
  };
})();
LogicTabComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LogicTabComponent,
  selectors: [["svc-tab-logic"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-creator-tab__content"], [1, "svc-plugin-tab__content", "svc-logic-tab__content"], [3, "model"], ["class", "svc-logic-tab__content-empty", 4, "ngIf"], [4, "ngIf"], [1, "svc-logic-tab__content-empty"], [1, "svc-text"]],
  template: function LogicTabComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, LogicTabComponent_ng_template_0_Template, 5, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SurveyContentComponent, LogicAddButtonComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LogicTabComponent, [{
    type: Component,
    args: [{
      selector: "svc-tab-logic",
      templateUrl: "./logic.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tab-logic", LogicTabComponent);
class ActionButtonComponent extends EmbeddedViewContentComponent {
  constructor() {
    super(...arguments);
    this.classes = "";
    this.selected = false;
    this.disabled = false;
    this.text = "";
    this.title = "";
    this.allowBubble = false;
  }
  onClick(event) {
    this.click();
    if (!this.allowBubble) {
      event.stopPropagation();
    }
  }
}
ActionButtonComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵActionButtonComponent_BaseFactory;
  return function ActionButtonComponent_Factory(__ngFactoryType__) {
    return (ɵActionButtonComponent_BaseFactory || (ɵActionButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ActionButtonComponent)))(__ngFactoryType__ || ActionButtonComponent);
  };
})();
ActionButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ActionButtonComponent,
  selectors: [["svc-action-button"]],
  inputs: {
    classes: "classes",
    click: "click",
    selected: "selected",
    disabled: "disabled",
    text: "text",
    title: "title",
    allowBubble: "allowBubble"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["class", "svc-action-button svc-action-button--disabled", 3, "class", 4, "ngIf"], ["role", "button", "class", "svc-action-button", 3, "key2click", "svc-action-button--selected", "class", "click", 4, "ngIf"], [1, "svc-action-button", "svc-action-button--disabled"], ["role", "button", 1, "svc-action-button", 3, "click", "key2click"]],
  template: function ActionButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ActionButtonComponent_ng_template_0_Template, 2, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i2.NgIf, i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionButtonComponent, [{
    type: Component,
    args: [{
      selector: "svc-action-button",
      templateUrl: "./action-button.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    classes: [{
      type: Input
    }],
    click: [{
      type: Input
    }],
    selected: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    text: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    allowBubble: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-action-button", ActionButtonComponent);
class QuestionPgErrorComponent {}
QuestionPgErrorComponent.ɵfac = function QuestionPgErrorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || QuestionPgErrorComponent)();
};
QuestionPgErrorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionPgErrorComponent,
  selectors: [["svc-question-error"]],
  inputs: {
    error: "error",
    cssClasses: "cssClasses",
    element: "element"
  },
  decls: 3,
  vars: 7,
  consts: [["sv-ng-svg-icon", "", 3, "iconName", "size"], ["sv-ng-string", "", 3, "model"]],
  template: function QuestionPgErrorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div");
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(1, "svg", 0);
      i0.ɵɵnamespaceHTML();
      i0.ɵɵelement(2, "span", 1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.cssClasses.error.icon);
      i0.ɵɵproperty("iconName", "icon-alert_24x24")("size", 24);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.cssClasses ? ctx.cssClasses.error.item : "panel-error-item");
      i0.ɵɵproperty("model", ctx.error.locText);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.SurveyStringComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionPgErrorComponent, [{
    type: Component,
    args: [{
      selector: "svc-question-error",
      templateUrl: "./question-error.component.html"
    }]
  }], null, {
    error: [{
      type: Input
    }],
    cssClasses: [{
      type: Input
    }],
    element: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-question-error", QuestionPgErrorComponent);
class SwitcherComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
SwitcherComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵSwitcherComponent_BaseFactory;
  return function SwitcherComponent_Factory(__ngFactoryType__) {
    return (ɵSwitcherComponent_BaseFactory || (ɵSwitcherComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SwitcherComponent)))(__ngFactoryType__ || SwitcherComponent);
  };
})();
SwitcherComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SwitcherComponent,
  selectors: [["svc-switcher"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["type", "button", 3, "click", "key2click", "disabled"], [1, "svc-switcher__icon-thumb"], ["class", "svc-switcher__title", 4, "ngIf"], [1, "svc-switcher__title"]],
  template: function SwitcherComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, SwitcherComponent_ng_template_0_Template, 5, 13, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.Key2ClickDirective, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SwitcherComponent, [{
    type: Component,
    args: [{
      selector: "svc-switcher",
      templateUrl: "./switcher.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-switcher", SwitcherComponent);
class LinkValueQuestionComponent extends QuestionAngular {
  get clearCaption() {
    return editorLocalization.getString("pe.clear");
  }
}
LinkValueQuestionComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵLinkValueQuestionComponent_BaseFactory;
  return function LinkValueQuestionComponent_Factory(__ngFactoryType__) {
    return (ɵLinkValueQuestionComponent_BaseFactory || (ɵLinkValueQuestionComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinkValueQuestionComponent)))(__ngFactoryType__ || LinkValueQuestionComponent);
  };
})();
LinkValueQuestionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinkValueQuestionComponent,
  selectors: [["svc-link-value"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [3, "text", "click", "selected", "disabled", "classes", "title"], [4, "ngIf"], [3, "text", "click", "selected", "disabled", "classes"]],
  template: function LinkValueQuestionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, LinkValueQuestionComponent_ng_template_0_Template, 2, 7, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [ActionButtonComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinkValueQuestionComponent, [{
    type: Component,
    args: [{
      selector: "svc-link-value",
      templateUrl: "./question-link-value.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("linkvalue-question", LinkValueQuestionComponent);
class EmbeddedSurveyQuestionComponent extends QuestionAngular {
  get survey() {
    return this.model.embeddedSurvey;
  }
  onModelChanged() {
    super.onModelChanged();
    if (!!this.model) {
      this.model.onEmbeddedSurveyValueChanged = () => {
        this.detectChanges();
      };
    }
  }
}
EmbeddedSurveyQuestionComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵEmbeddedSurveyQuestionComponent_BaseFactory;
  return function EmbeddedSurveyQuestionComponent_Factory(__ngFactoryType__) {
    return (ɵEmbeddedSurveyQuestionComponent_BaseFactory || (ɵEmbeddedSurveyQuestionComponent_BaseFactory = i0.ɵɵgetInheritedFactory(EmbeddedSurveyQuestionComponent)))(__ngFactoryType__ || EmbeddedSurveyQuestionComponent);
  };
})();
EmbeddedSurveyQuestionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: EmbeddedSurveyQuestionComponent,
  selectors: [["svc-embeddedsurvey-question"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [4, "ngIf"], [3, "model", "survey"]],
  template: function EmbeddedSurveyQuestionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, EmbeddedSurveyQuestionComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.PageComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmbeddedSurveyQuestionComponent, [{
    type: Component,
    args: [{
      selector: "svc-embeddedsurvey-question",
      templateUrl: "./question-embedded-survey.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("embeddedsurvey-question", EmbeddedSurveyQuestionComponent);
class TranslationTabComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
TranslationTabComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTranslationTabComponent_BaseFactory;
  return function TranslationTabComponent_Factory(__ngFactoryType__) {
    return (ɵTranslationTabComponent_BaseFactory || (ɵTranslationTabComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TranslationTabComponent)))(__ngFactoryType__ || TranslationTabComponent);
  };
})();
TranslationTabComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TranslationTabComponent,
  selectors: [["svc-tab-translation"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-creator-tab__content", "svc-translation-tab"], [1, "st-content"], ["class", "st-no-strings", 4, "ngIf"], ["class", "svc-flex-column st-strings-wrapper", 4, "ngIf"], [1, "st-no-strings"], [1, "svc-flex-column", "st-strings-wrapper"], [1, "svc-flex-row", "st-strings-header"], [3, "model"], [1, "svc-flex-row", "svc-plugin-tab__content", "st-strings"]],
  template: function TranslationTabComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TranslationTabComponent_ng_template_0_Template, 4, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SurveyContentComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslationTabComponent, [{
    type: Component,
    args: [{
      selector: "svc-tab-translation",
      templateUrl: "./translation.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tab-translation", TranslationTabComponent);
class TranslationSkeletonComponent extends EmbeddedViewContentComponent {}
TranslationSkeletonComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTranslationSkeletonComponent_BaseFactory;
  return function TranslationSkeletonComponent_Factory(__ngFactoryType__) {
    return (ɵTranslationSkeletonComponent_BaseFactory || (ɵTranslationSkeletonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TranslationSkeletonComponent)))(__ngFactoryType__ || TranslationSkeletonComponent);
  };
})();
TranslationSkeletonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TranslationSkeletonComponent,
  selectors: [["sd-translation-line-skeleton"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "sd-translation-line-skeleton"]],
  template: function TranslationSkeletonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TranslationSkeletonComponent_ng_template_0_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslationSkeletonComponent, [{
    type: Component,
    args: [{
      selector: "sd-translation-line-skeleton",
      templateUrl: "./translation-line-skeleton.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("sd-translation-line-skeleton", TranslationSkeletonComponent);
class SimulatorComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  get simulatorFrame() {
    return this.model.simulatorFrame;
  }
  activateZoom() {
    if (this.model.device !== "desktop") {
      this.model.activateZoom();
    }
  }
  deactivateZoom() {
    if (this.model.device !== "desktop") {
      this.model.deactivateZoom();
    }
  }
}
SimulatorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵSimulatorComponent_BaseFactory;
  return function SimulatorComponent_Factory(__ngFactoryType__) {
    return (ɵSimulatorComponent_BaseFactory || (ɵSimulatorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SimulatorComponent)))(__ngFactoryType__ || SimulatorComponent);
  };
})();
SimulatorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SimulatorComponent,
  selectors: [["survey-simulator"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [3, "keydown", "mouseover", "mouseout"], ["class", "svd-simulator-wrapper", "id", "svd-simulator-wrapper", 3, "width", "height", 4, "ngIf"], ["class", "svd-simulator-content", 4, "ngIf"], ["id", "svd-simulator-wrapper", 1, "svd-simulator-wrapper"], [1, "svd-simulator"], [1, "svd-simulator-content"], [3, "component"]],
  template: function SimulatorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, SimulatorComponent_ng_template_0_Template, 3, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i2.NgIf, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SimulatorComponent, [{
    type: Component,
    args: [{
      selector: "survey-simulator",
      templateUrl: "./simulator.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
class TestAgainActionComponent extends BaseAngular {
  getModel() {
    return this.model.testAgainAction;
  }
  get action() {
    return this.getModel();
  }
}
TestAgainActionComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTestAgainActionComponent_BaseFactory;
  return function TestAgainActionComponent_Factory(__ngFactoryType__) {
    return (ɵTestAgainActionComponent_BaseFactory || (ɵTestAgainActionComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TestAgainActionComponent)))(__ngFactoryType__ || TestAgainActionComponent);
  };
})();
TestAgainActionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TestAgainActionComponent,
  selectors: [["survey-test-again"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["role", "button", 1, "svc-preview__test-again", "svc-btn", 3, "click", "key2click"], [1, "svc-text", "svc-text--normal", "svc-text--bold"]],
  template: function TestAgainActionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TestAgainActionComponent_ng_template_0_Template, 3, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TestAgainActionComponent, [{
    type: Component,
    args: [{
      selector: "survey-test-again",
      templateUrl: "./test-again.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-complete-page", TestAgainActionComponent);
class SurveyResultsTableRowComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
SurveyResultsTableRowComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵSurveyResultsTableRowComponent_BaseFactory;
  return function SurveyResultsTableRowComponent_Factory(__ngFactoryType__) {
    return (ɵSurveyResultsTableRowComponent_BaseFactory || (ɵSurveyResultsTableRowComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SurveyResultsTableRowComponent)))(__ngFactoryType__ || SurveyResultsTableRowComponent);
  };
})();
SurveyResultsTableRowComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SurveyResultsTableRowComponent,
  selectors: [["survey-results-table-row"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [3, "click", "key2click"], [1, "svd-dark-border-color"], ["class", "svd-test-results__marker", 3, "svd-test-results__marker--expanded", "left", 4, "ngIf"], [3, "model", 4, "ngIf"], [4, "ngIf"], [1, "svd-test-results__marker"], ["sv-ng-svg-icon", "", 3, "iconName", "size"], [3, "model"], [4, "ngFor", "ngForOf"]],
  template: function SurveyResultsTableRowComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, SurveyResultsTableRowComponent_ng_template_0_Template, 8, 11, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.SurveyStringComponent, SurveyResultsTableRowComponent, i1.Key2ClickDirective, i2.NgIf, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SurveyResultsTableRowComponent, [{
    type: Component,
    args: [{
      selector: "survey-results-table-row",
      templateUrl: "./survey-results-row.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
class SurveyResultsComponent extends CreatorModelComponent {
  createModel() {
    if (!!this.survey) {
      this.model = new SurveyResultsModel(this.survey);
    }
  }
  getModel() {
    return this.model;
  }
  getPropertiesToTrack() {
    return ["survey"];
  }
}
SurveyResultsComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵSurveyResultsComponent_BaseFactory;
  return function SurveyResultsComponent_Factory(__ngFactoryType__) {
    return (ɵSurveyResultsComponent_BaseFactory || (ɵSurveyResultsComponent_BaseFactory = i0.ɵɵgetInheritedFactory(SurveyResultsComponent)))(__ngFactoryType__ || SurveyResultsComponent);
  };
})();
SurveyResultsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SurveyResultsComponent,
  selectors: [["survey-results"]],
  inputs: {
    survey: "survey"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svd-test-results"], [1, "svd-test-results__header"], [1, "svd-test-results__header-text"], [1, "svd-test-results__header-types"], [3, "text", "click", "disabled", "selected"], [1, "svd-test-results__text", "svd-light-bg-color", 3, "visible"], [1, "svd-test-results__table", "svd-light-bg-color", 3, "visible"], [1, "svd-light-background-color"], [1, "svd-dark-border-color"], [4, "ngFor", "ngForOf"], [3, "model"]],
  template: function SurveyResultsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, SurveyResultsComponent_ng_template_0_Template, 20, 15, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [ActionButtonComponent, SurveyResultsTableRowComponent, i1.VisibleDirective, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SurveyResultsComponent, [{
    type: Component,
    args: [{
      selector: "survey-results",
      templateUrl: "./survey-results.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    survey: [{
      type: Input
    }]
  });
})();
class TestTabComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
TestTabComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTestTabComponent_BaseFactory;
  return function TestTabComponent_Factory(__ngFactoryType__) {
    return (ɵTestTabComponent_BaseFactory || (ɵTestTabComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TestTabComponent)))(__ngFactoryType__ || TestTabComponent);
  };
})();
TestTabComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TestTabComponent,
  selectors: [["svc-tab-test"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-creator-tab__content", "svc-test-tab__content"], [1, "svc-plugin-tab__content"], [3, "model"], [4, "ngIf"], ["class", "svc-plugin-tab__content-actions svc-test-tab__content-actions", 4, "ngIf"], [3, "survey"], [1, "svc-plugin-tab__content-actions", "svc-test-tab__content-actions"]],
  template: function TestTabComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TestTabComponent_ng_template_0_Template, 5, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [SimulatorComponent, SurveyResultsComponent, i1.ActionBarComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TestTabComponent, [{
    type: Component,
    args: [{
      selector: "svc-tab-test",
      templateUrl: "./test.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tab-test", TestTabComponent);
class ThemeTabComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
ThemeTabComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵThemeTabComponent_BaseFactory;
  return function ThemeTabComponent_Factory(__ngFactoryType__) {
    return (ɵThemeTabComponent_BaseFactory || (ɵThemeTabComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ThemeTabComponent)))(__ngFactoryType__ || ThemeTabComponent);
  };
})();
ThemeTabComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ThemeTabComponent,
  selectors: [["svc-tab-theme"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-creator-tab__content", "svc-test-tab__content"], [1, "svc-plugin-tab__content"], [3, "model"], [4, "ngIf"], ["class", "svc-plugin-tab__content-actions svc-test-tab__content-actions", 4, "ngIf"], [3, "survey"], [1, "svc-plugin-tab__content-actions", "svc-test-tab__content-actions"]],
  template: function ThemeTabComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ThemeTabComponent_ng_template_0_Template, 5, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [SimulatorComponent, SurveyResultsComponent, i1.ActionBarComponent, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeTabComponent, [{
    type: Component,
    args: [{
      selector: "svc-tab-theme",
      templateUrl: "./theme.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-tab-theme", ThemeTabComponent);
class AddQuestionButtonComponent extends ActionBarItemComponent {
  get adorner() {
    return this.model.data;
  }
  addNewQuestion(event) {
    event.stopPropagation();
    this.adorner.addNewQuestion(this.adorner, event);
  }
  selectQuestionType(event) {
    event.stopPropagation();
    this.adorner.questionTypeSelectorModel.action();
  }
}
AddQuestionButtonComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵAddQuestionButtonComponent_BaseFactory;
  return function AddQuestionButtonComponent_Factory(__ngFactoryType__) {
    return (ɵAddQuestionButtonComponent_BaseFactory || (ɵAddQuestionButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(AddQuestionButtonComponent)))(__ngFactoryType__ || AddQuestionButtonComponent);
  };
})();
AddQuestionButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AddQuestionButtonComponent,
  selectors: [["svc-add-new-question-btn"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["class", "svc-page__add-new-question svc-btn", "data-bind", "clickBubble: false", 3, "key2click", "click", "mouseover", 4, "ngIf"], ["data-bind", "clickBubble: false", 1, "svc-page__add-new-question", "svc-btn", 3, "click", "mouseover", "key2click"], [1, "svc-text", "svc-text--normal", "svc-text--bold"], ["type", "button", 1, "svc-page__question-type-selector", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 1, "svc-page__question-type-selector-icon", 3, "iconName", "size"], [3, "popupModel"]],
  template: function AddQuestionButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, AddQuestionButtonComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.PopupComponent, i2.NgIf, i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AddQuestionButtonComponent, [{
    type: Component,
    args: [{
      selector: "svc-add-new-question-btn",
      templateUrl: "./page-add-question.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("svc-add-new-question-btn", AddQuestionButtonComponent);
class QuestionBannerComponent extends EmbeddedViewContentComponent {}
QuestionBannerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionBannerComponent_BaseFactory;
  return function QuestionBannerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionBannerComponent_BaseFactory || (ɵQuestionBannerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionBannerComponent)))(__ngFactoryType__ || QuestionBannerComponent);
  };
})();
QuestionBannerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionBannerComponent,
  selectors: [["svc-question-banner"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-carry-forward-panel-wrapper"], [1, "svc-carry-forward-panel"], [1, "svc-carry-forward-panel__link"], [3, "text", "click"]],
  template: function QuestionBannerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionBannerComponent_ng_template_0_Template, 7, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [ActionButtonComponent],
  styles: ["[_nghost-%COMP%] { display: none }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionBannerComponent, [{
    type: Component,
    args: [{
      selector: "svc-question-banner",
      templateUrl: "./question-banner.component.html",
      styles: [":host { display: none }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-question-banner", QuestionBannerComponent);
class QuestionDesignerComponent extends CreatorModelComponent {
  constructor() {
    super(...arguments);
    this.adornerComponent = "";
  }
  get creator() {
    return this.componentData.data;
  }
  get model() {
    return this.componentData.model;
  }
  createModel() {
    if (this.componentData) {
      this.adorner = new QuestionAdornerViewModel(this.creator, this.model, null);
    }
  }
  getPropertiesToTrack() {
    return ["model", "creator"];
  }
  getModel() {
    return this.adorner;
  }
  get placeholderComponent() {
    return "";
  }
  get placeholderComponentData() {
    return null;
  }
  get showPlaceholderComponent() {
    return false;
  }
  selectQuestionType(event) {
    var _a;
    event.stopPropagation();
    (_a = this.adorner.questionTypeSelectorModel) === null || _a === void 0 ? void 0 : _a.action(this.adorner.questionTypeSelectorModel, event);
  }
  addNewQuestion(event) {
    event.stopPropagation();
    this.adorner.addNewQuestion();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.adorner.dispose();
  }
}
QuestionDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionDesignerComponent_BaseFactory;
  return function QuestionDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionDesignerComponent_BaseFactory || (ɵQuestionDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionDesignerComponent)))(__ngFactoryType__ || QuestionDesignerComponent);
  };
})();
QuestionDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionDesignerComponent,
  selectors: [["svc-question"]],
  inputs: {
    componentName: "componentName",
    componentData: "componentData"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], ["class", "svc-question__adorner", 3, "class", "dblclick", "mouseover", "mouseleave", 4, "ngIf"], [1, "svc-question__adorner", 3, "dblclick", "mouseover", "mouseleave"], [3, "click", "key2click"], ["class", "svc-question__drag-area", 3, "pointerdown", 4, "ngIf"], [3, "class", 4, "ngIf"], [3, "component"], ["class", "svc-panel__placeholder_frame-wrapper", 4, "ngIf"], [4, "ngIf"], [3, "model", 4, "ngIf"], [1, "svc-question__content-actions", 3, "focusin"], [3, "model", "handleClick"], [1, "svc-question__drag-area", 3, "pointerdown"], ["sv-ng-svg-icon", "", 1, "svc-question__drag-element", 3, "iconName", "size"], [1, "svc-question__top-actions"], [3, "model"], [1, "svc-panel__placeholder_frame-wrapper"], [1, "svc-panel__placeholder_frame"], [1, "svc-panel__placeholder"]],
  template: function QuestionDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionDesignerComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.ActionBarComponent, i1.SurveyStringComponent, QuestionBannerComponent, i2.NgIf, i1.Key2ClickDirective, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-question",
      templateUrl: "./question.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    componentName: [{
      type: Input
    }],
    componentData: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-question", QuestionDesignerComponent);
class ItemValueDesignerComponent extends CreatorModelComponent {
  get creator() {
    return this.componentData.data.creator;
  }
  get question() {
    return this.componentData.question;
  }
  get item() {
    return this.componentData.model;
  }
  onBlur(event) {
    this.adorner.onFocusOut(event);
  }
  createModel() {
    if (this.componentData) {
      this.adorner = new ItemValueWrapperViewModel(this.creator, this.question, this.item);
    }
  }
  getPropertiesToTrack() {
    return ["creator", "question", "item"];
  }
  getModel() {
    return this.adorner;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.adorner.dispose();
  }
}
ItemValueDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵItemValueDesignerComponent_BaseFactory;
  return function ItemValueDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵItemValueDesignerComponent_BaseFactory || (ɵItemValueDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ItemValueDesignerComponent)))(__ngFactoryType__ || ItemValueDesignerComponent);
  };
})();
ItemValueDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ItemValueDesignerComponent,
  selectors: [["svc-item-value"]],
  inputs: {
    componentName: "componentName",
    componentData: "componentData"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-item-value-wrapper", 3, "pointerdown", "ngClass"], [1, "svc-item-value__ghost"], [1, "svc-item-value-controls"], ["class", "svc-item-value-controls__button svc-item-value-controls__drag", 4, "ngIf"], ["class", "svc-item-value-controls__button svc-item-value-controls__add", 3, "key2click", "click", 4, "ngIf"], ["class", "svc-item-value-controls__button svc-item-value-controls__remove", 3, "key2click", "click", "blur", 4, "ngIf"], [1, "svc-item-value__item", 3, "click"], [3, "component"], [1, "svc-item-value-controls__button", "svc-item-value-controls__drag"], ["sv-ng-svg-icon", "", 1, "svc-item-value-controls__drag-icon", 3, "iconName", "size"], [1, "svc-item-value-controls__button", "svc-item-value-controls__add", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 3, "iconName", "size"], [1, "svc-item-value-controls__button", "svc-item-value-controls__remove", 3, "click", "blur", "key2click"]],
  template: function ItemValueDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ItemValueDesignerComponent_ng_template_0_Template, 8, 15, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i2.NgClass, i2.NgIf, i1.Key2ClickDirective, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ItemValueDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-item-value",
      templateUrl: "./item-value.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    componentName: [{
      type: Input
    }],
    componentData: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-item-value", ItemValueDesignerComponent);
class ImageItemValueDesignerComponent extends CreatorModelComponent {
  get creator() {
    return this.componentData.data.creator;
  }
  get question() {
    return this.componentData.question;
  }
  get item() {
    return this.componentData.model;
  }
  createModel() {
    if (this.componentData) {
      this.adorner = new ImageItemValueWrapperViewModel(this.creator, this.question, this.item, null, null);
    }
  }
  getPropertiesToTrack() {
    return ["creator", "question", "item"];
  }
  getModel() {
    return this.adorner;
  }
  get showDragDropGhostOnTop() {
    return this.adorner.ghostPosition === "top";
  }
  get showDragDropGhostOnBottom() {
    return this.adorner.ghostPosition === "bottom";
  }
  blockEvent(event) {
    event.stopPropagation();
  }
  getNewItemStyle() {
    const needStyle = !this.adorner.getIsNewItemSingle();
    return {
      width: needStyle ? this.question.renderedImageWidth + "px" : undefined,
      height: needStyle ? this.question.renderedImageHeight + "px" : undefined
    };
  }
  ngAfterViewInit() {
    this.adorner.itemsRoot = this.container.nativeElement;
  }
}
ImageItemValueDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵImageItemValueDesignerComponent_BaseFactory;
  return function ImageItemValueDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵImageItemValueDesignerComponent_BaseFactory || (ɵImageItemValueDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ImageItemValueDesignerComponent)))(__ngFactoryType__ || ImageItemValueDesignerComponent);
  };
})();
ImageItemValueDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ImageItemValueDesignerComponent,
  selectors: [["svc-image-item-value"]],
  viewQuery: function ImageItemValueDesignerComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    componentName: "componentName",
    componentData: "componentData"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], [3, "pointerdown"], [1, "svc-image-item-value-wrapper__ghost"], [1, "svc-image-item-value-wrapper__content"], ["type", "file", "aria-hidden", "true", "tabindex", "-1", 1, "svc-choose-file-input", 3, "accept"], [4, "ngIf"], ["class", "svc-image-item-value__item", 4, "ngIf"], ["class", "svc-context-button svc-image-item-value-controls__drag-area-indicator", 3, "pointerdown", 4, "ngIf"], ["class", "svc-context-container svc-image-item-value-controls", 4, "ngIf"], [1, "svc-image-item-value__item"], [3, "component"], [1, "svc-context-button", "svc-image-item-value-controls__drag-area-indicator", 3, "pointerdown"], ["sv-ng-svg-icon", "", 3, "iconName", "size"], [1, "svc-context-container", "svc-image-item-value-controls"], [1, "svc-context-button", 3, "click", "key2click"], ["role", "button", "sv-ng-svg-icon", "", 3, "iconName", "size", "title"], [1, "svc-context-button", "svc-context-button--danger", 3, "click", "key2click"], ["data-bind", "event: { dragover: dragover, drop: drop, dragleave: dragleave }", 1, "svc-image-item-value__item"], [1, "sd-imagepicker__item", "sd-imagepicker__item--inline"], [1, "sd-imagepicker__label"], [1, "sd-imagepicker__image"], ["class", "svc-image-item-value__loading", 4, "ngIf"], ["data-bind", "event: { pointerdown: blockEvent }", 1, "svc-image-item-value-controls"], ["class", "svc-context-button svc-image-item-value-controls__add", 3, "key2click", "click", 4, "ngIf"], [1, "svc-image-item-value__loading"], [1, "svc-context-button", "svc-image-item-value-controls__add", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 3, "iconName", "size", "title"]],
  template: function ImageItemValueDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ImageItemValueDesignerComponent_ng_template_0_Template, 7, 8, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.LoadingIndicatorComponent, i2.NgIf, i1.DynamicComponentDirective, i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageItemValueDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-image-item-value",
      templateUrl: "./image-item-value.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    componentName: [{
      type: Input
    }],
    componentData: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ["container", {
        read: ElementRef
      }]
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-image-item-value", ImageItemValueDesignerComponent);
class QuestionDropdownDesignerComponent extends QuestionDesignerComponent {
  constructor() {
    super(...arguments);
    this.adornerComponent = "svc-dropdown-question-adorner";
  }
  createModel() {
    if (this.componentData) {
      this.adorner = new QuestionDropdownAdornerViewModel(this.creator, this.model, null);
    }
  }
}
QuestionDropdownDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionDropdownDesignerComponent_BaseFactory;
  return function QuestionDropdownDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionDropdownDesignerComponent_BaseFactory || (ɵQuestionDropdownDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionDropdownDesignerComponent)))(__ngFactoryType__ || QuestionDropdownDesignerComponent);
  };
})();
QuestionDropdownDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionDropdownDesignerComponent,
  selectors: [["svc-dropdown-question"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], ["class", "svc-question__adorner", 3, "class", "dblclick", "mouseover", "mouseleave", 4, "ngIf"], [1, "svc-question__adorner", 3, "dblclick", "mouseover", "mouseleave"], [3, "click", "key2click"], ["class", "svc-question__drag-area", 3, "pointerdown", 4, "ngIf"], [3, "class", 4, "ngIf"], [3, "component"], ["class", "svc-panel__placeholder_frame-wrapper", 4, "ngIf"], [4, "ngIf"], [3, "model", 4, "ngIf"], [1, "svc-question__content-actions", 3, "focusin"], [3, "model", "handleClick"], [1, "svc-question__drag-area", 3, "pointerdown"], ["sv-ng-svg-icon", "", 1, "svc-question__drag-element", 3, "iconName", "size"], [1, "svc-question__top-actions"], [3, "model"], [1, "svc-panel__placeholder_frame-wrapper"], [1, "svc-panel__placeholder_frame"], [1, "svc-panel__placeholder"]],
  template: function QuestionDropdownDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionDropdownDesignerComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.ActionBarComponent, i1.SurveyStringComponent, QuestionBannerComponent, i2.NgIf, i1.Key2ClickDirective, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionDropdownDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-dropdown-question",
      templateUrl: "../question.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("svc-dropdown-question", QuestionDropdownDesignerComponent);
class QuestionDropdownAdornerDesignerComponent extends EmbeddedViewContentComponent {
  getItemValueComponentName(item) {
    return this.question.getItemValueWrapperComponentName(item) || "sv-ng-selectbase-item";
  }
  getItemValueComponentData(item) {
    return {
      componentName: "sv-ng-selectbase-item",
      componentData: {
        question: this.question,
        model: item,
        inputType: "radio",
        data: this.question.getItemValueWrapperComponentData(item)
      }
    };
  }
}
QuestionDropdownAdornerDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionDropdownAdornerDesignerComponent_BaseFactory;
  return function QuestionDropdownAdornerDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionDropdownAdornerDesignerComponent_BaseFactory || (ɵQuestionDropdownAdornerDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionDropdownAdornerDesignerComponent)))(__ngFactoryType__ || QuestionDropdownAdornerDesignerComponent);
  };
})();
QuestionDropdownAdornerDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionDropdownAdornerDesignerComponent,
  selectors: [["svc-dropdown-question-adorner"]],
  inputs: {
    adorner: "adorner",
    question: "question"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-question__dropdown-choices--wrapper"], [1, "svc-question__dropdown-choices"], ["data-bind", "css: $parent.getChoiceCss()", 3, "class", 4, "ngFor", "ngForOf"], [3, "text", "click", "allowBubble", 4, "ngIf"], ["data-bind", "css: $parent.getChoiceCss()"], [3, "component"], [3, "text", "click", "allowBubble"]],
  template: function QuestionDropdownAdornerDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionDropdownAdornerDesignerComponent_ng_template_0_Template, 5, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [ActionButtonComponent, i2.NgForOf, i1.DynamicComponentDirective, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionDropdownAdornerDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-dropdown-question-adorner",
      templateUrl: "./question-dropdown.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    adorner: [{
      type: Input
    }],
    question: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-dropdown-question-adorner", QuestionDropdownAdornerDesignerComponent);
class QuestionImageDesignerComponent extends QuestionDesignerComponent {
  constructor() {
    super(...arguments);
    this.adornerComponent = "svc-image-question-adorner";
  }
  createModel() {
    var _a;
    if (this.componentData) {
      this.adorner = new QuestionImageAdornerViewModel(this.creator, this.model, null, (_a = this.viewContainerRef) === null || _a === void 0 ? void 0 : _a.element.nativeElement.nextSibling);
    }
  }
  get placeholderComponent() {
    return "file-question";
  }
  get placeholderComponentData() {
    return {
      model: this.adorner.filePresentationModel
    };
  }
  get showPlaceholderComponent() {
    return !!this.placeholderComponentData;
  }
  ngAfterViewInit() {
    this.adorner.questionRoot = this.container.nativeElement;
  }
}
QuestionImageDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionImageDesignerComponent_BaseFactory;
  return function QuestionImageDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionImageDesignerComponent_BaseFactory || (ɵQuestionImageDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionImageDesignerComponent)))(__ngFactoryType__ || QuestionImageDesignerComponent);
  };
})();
QuestionImageDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionImageDesignerComponent,
  selectors: [["svc-image-question"]],
  viewQuery: function QuestionImageDesignerComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], ["class", "svc-question__adorner", 3, "class", "dblclick", "mouseover", "mouseleave", 4, "ngIf"], [1, "svc-question__adorner", 3, "dblclick", "mouseover", "mouseleave"], [3, "click", "key2click"], ["class", "svc-question__drag-area", 3, "pointerdown", 4, "ngIf"], [3, "class", 4, "ngIf"], [3, "component"], ["class", "svc-panel__placeholder_frame-wrapper", 4, "ngIf"], [4, "ngIf"], [3, "model", 4, "ngIf"], [1, "svc-question__content-actions", 3, "focusin"], [3, "model", "handleClick"], [1, "svc-question__drag-area", 3, "pointerdown"], ["sv-ng-svg-icon", "", 1, "svc-question__drag-element", 3, "iconName", "size"], [1, "svc-question__top-actions"], [3, "model"], [1, "svc-panel__placeholder_frame-wrapper"], [1, "svc-panel__placeholder_frame"], [1, "svc-panel__placeholder"]],
  template: function QuestionImageDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionImageDesignerComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.ActionBarComponent, i1.SurveyStringComponent, QuestionBannerComponent, i2.NgIf, i1.Key2ClickDirective, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionImageDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-image-question",
      templateUrl: "../question.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    container: [{
      type: ViewChild,
      args: ["container", {
        read: ElementRef
      }]
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-image-question", QuestionImageDesignerComponent);
class QuestionImageAdornerDesignerComponent extends EmbeddedViewContentComponent {}
QuestionImageAdornerDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionImageAdornerDesignerComponent_BaseFactory;
  return function QuestionImageAdornerDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionImageAdornerDesignerComponent_BaseFactory || (ɵQuestionImageAdornerDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionImageAdornerDesignerComponent)))(__ngFactoryType__ || QuestionImageAdornerDesignerComponent);
  };
})();
QuestionImageAdornerDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionImageAdornerDesignerComponent,
  selectors: [["svc-image-question-adorner"]],
  inputs: {
    adorner: "adorner",
    question: "question"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["class", "svc-image-question-controls", 4, "ngIf"], ["class", "svc-image-question__loading-placeholder", 4, "ngIf"], [1, "svc-image-question-controls"], [4, "ngIf"], ["type", "file", "aria-hidden", "true", "tabindex", "-1", 1, "svc-choose-file-input", 3, "accept"], [1, "svc-context-button", 3, "key2click"], ["sv-ng-svg-icon", "", 3, "click", "iconName", "size"], [1, "svc-image-question__loading-placeholder"], [1, "svc-image-question__loading"]],
  template: function QuestionImageAdornerDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionImageAdornerDesignerComponent_ng_template_0_Template, 2, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.LoadingIndicatorComponent, i2.NgIf, i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionImageAdornerDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-image-question-adorner",
      templateUrl: "./question-image.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    adorner: [{
      type: Input
    }],
    question: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-image-question-adorner", QuestionImageAdornerDesignerComponent);
class PanelDesignerComponent extends QuestionDesignerComponent {}
PanelDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵPanelDesignerComponent_BaseFactory;
  return function PanelDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵPanelDesignerComponent_BaseFactory || (ɵPanelDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PanelDesignerComponent)))(__ngFactoryType__ || PanelDesignerComponent);
  };
})();
PanelDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PanelDesignerComponent,
  selectors: [["svc-panel"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], ["class", "svc-question__adorner", 3, "class", "dblclick", "mouseover", "mouseleave", 4, "ngIf"], [1, "svc-question__adorner", 3, "dblclick", "mouseover", "mouseleave"], [3, "click", "key2click"], ["class", "svc-question__drag-area", 3, "pointerdown", 4, "ngIf"], [3, "component"], ["class", "svc-panel__placeholder_frame-wrapper", 4, "ngIf"], [4, "ngIf"], ["class", "svc-panel__add-new-question-container", 4, "ngIf"], ["class", "svc-question__content-actions", 3, "focusin", 4, "ngIf"], [1, "svc-question__drag-area", 3, "pointerdown"], ["sv-ng-svg-icon", "", 1, "svc-question__drag-element", 3, "iconName", "size"], [1, "svc-question__top-actions"], [3, "model", "handleClick"], [1, "svc-panel__placeholder_frame-wrapper"], [1, "svc-panel__placeholder_frame"], [1, "svc-panel__placeholder"], ["class", "svc-panel__add-new-question svc-action-button", 3, "key2click", "click", 4, "ngIf"], [1, "svc-panel__add-new-question", "svc-action-button", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 1, "svc-panel__add-new-question-icon", 3, "iconName", "size"], [1, "svc-text", "svc-text--normal", "svc-text--bold"], [1, "svc-panel__add-new-question-container"], [1, "svc-panel__question-type-selector-popup"], [3, "popupModel"], [1, "svc-panel__add-new-question-wrapper"], ["type", "button", 1, "svc-panel__question-type-selector", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 1, "svc-panel__question-type-selector-icon", 3, "iconName", "size"], [1, "svc-question__content-actions", 3, "focusin"]],
  template: function PanelDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PanelDesignerComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.ActionBarComponent, i1.PopupComponent, i2.NgIf, i1.Key2ClickDirective, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PanelDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-panel",
      templateUrl: "./panel.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("svc-panel", PanelDesignerComponent);
class ToolboxItemComponent extends BaseAngular {
  constructor() {
    super(...arguments);
    this.isCompact = false;
  }
  getModel() {
    return this.viewModel;
  }
  get item() {
    return this.model;
  }
}
ToolboxItemComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵToolboxItemComponent_BaseFactory;
  return function ToolboxItemComponent_Factory(__ngFactoryType__) {
    return (ɵToolboxItemComponent_BaseFactory || (ɵToolboxItemComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ToolboxItemComponent)))(__ngFactoryType__ || ToolboxItemComponent);
  };
})();
ToolboxItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToolboxItemComponent,
  selectors: [["svc-toolbox-item"]],
  inputs: {
    creator: "creator",
    model: "model",
    isCompact: "isCompact",
    viewModel: "viewModel"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["role", "button", 1, "svc-toolbox__item", 3, "click", "title", "key2click"], [1, "svc-toolbox__item-container"], ["sv-ng-svg-icon", "", 3, "iconName", "size", "title", 4, "ngIf"], ["class", "svc-toolbox__item-banner svc-item__banner", 4, "ngIf"], ["class", "svc-toolbox__item-title", 4, "ngIf"], ["sv-ng-svg-icon", "", 3, "iconName", "size", "title"], [1, "svc-toolbox__item-banner", "svc-item__banner"], ["class", "svc-toolbox__item-icon", "sv-ng-svg-icon", "", 3, "iconName", "size", "title", 4, "ngIf"], [1, "svc-toolbox__item-title"], ["sv-ng-svg-icon", "", 1, "svc-toolbox__item-icon", 3, "iconName", "size", "title"]],
  template: function ToolboxItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ToolboxItemComponent_ng_template_0_Template, 5, 7, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.Key2ClickDirective, i2.NgIf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolboxItemComponent, [{
    type: Component,
    args: [{
      selector: "svc-toolbox-item",
      templateUrl: "./toolbox-item.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    creator: [{
      type: Input
    }],
    model: [{
      type: Input
    }],
    isCompact: [{
      type: Input
    }],
    viewModel: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-toolbox-item", ToolboxItemComponent);
class ToolboxItemGroupComponent extends BaseAngular {
  constructor() {
    super(...arguments);
    this.isCompact = false;
  }
  getModel() {
    return this.viewModel;
  }
  get item() {
    return this.model;
  }
}
ToolboxItemGroupComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵToolboxItemGroupComponent_BaseFactory;
  return function ToolboxItemGroupComponent_Factory(__ngFactoryType__) {
    return (ɵToolboxItemGroupComponent_BaseFactory || (ɵToolboxItemGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ToolboxItemGroupComponent)))(__ngFactoryType__ || ToolboxItemGroupComponent);
  };
})();
ToolboxItemGroupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToolboxItemGroupComponent,
  selectors: [["svc-toolbox-item-group"]],
  inputs: {
    creator: "creator",
    model: "model",
    isCompact: "isCompact",
    viewModel: "viewModel"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [3, "model", "viewModel", "creator", "isCompact"], [3, "popupModel", "getArea"]],
  template: function ToolboxItemGroupComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ToolboxItemGroupComponent_ng_template_0_Template, 2, 6, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [ToolboxItemComponent, i1.PopupComponent],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolboxItemGroupComponent, [{
    type: Component,
    args: [{
      selector: "svc-toolbox-item-group",
      templateUrl: "./toolbox-item-group.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    creator: [{
      type: Input
    }],
    model: [{
      type: Input
    }],
    isCompact: [{
      type: Input
    }],
    viewModel: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-toolbox-item-group", ToolboxItemGroupComponent);
class ToolboxListComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
}
ToolboxListComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵToolboxListComponent_BaseFactory;
  return function ToolboxListComponent_Factory(__ngFactoryType__) {
    return (ɵToolboxListComponent_BaseFactory || (ɵToolboxListComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ToolboxListComponent)))(__ngFactoryType__ || ToolboxListComponent);
  };
})();
ToolboxListComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToolboxListComponent,
  selectors: [["svc-toolbox-list"]],
  inputs: {
    model: "model",
    creator: "creator"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [4, "ngFor", "ngForOf"], [3, "item", "creator", "parentModel", "isCompact"]],
  template: function ToolboxListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ToolboxListComponent_ng_template_0_Template, 2, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [ToolboxToolComponent, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolboxListComponent, [{
    type: Component,
    args: [{
      selector: "svc-toolbox-list ",
      templateUrl: "./toolbox-list.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }],
    creator: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-toolbox-list", ToolboxListComponent);
class StringEditorComponent extends CreatorModelComponent {
  constructor(cdr, vcr, ngZone) {
    super(cdr, vcr);
    this.ngZone = ngZone;
    this.justFocused = false;
    this.onChangeHandler = () => {
      this.detectChanges();
    };
  }
  createModel() {
    this.baseModel = new StringEditorViewModelBase(this.locString, this.creator);
    this.baseModel.setLocString(this.locString);
    this.baseModel.blurEditor = () => {
      this.container.nativeElement.blur();
      this.container.nativeElement.spellcheck = false;
    };
    this.baseModel.getEditorElement = () => this.container.nativeElement;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => this.baseModel.afterRender());
    });
  }
  get locString() {
    return this.model.locStr;
  }
  get creator() {
    return this.model.creator;
  }
  getModel() {
    return this.baseModel;
  }
  getPropertiesToTrack() {
    return ["creator", "locString"];
  }
  get placeholder() {
    return this.baseModel.placeholder;
  }
  get contentEditable() {
    return this.baseModel.contentEditable;
  }
  get characterCounter() {
    return this.baseModel.characterCounter;
  }
  get showCharacterCounter() {
    return this.baseModel.showCharacterCounter;
  }
  get getCharacterCounterClass() {
    return this.baseModel.getCharacterCounterClass;
  }
  get className() {
    return this.baseModel.className(this.locString.renderedHtml);
  }
  get errorText() {
    return this.baseModel.errorText;
  }
  onBlur(event) {
    this.container.nativeElement.spellcheck = false;
    this.locString.__isEditing = false;
    this.justFocused = false;
    this.baseModel.onBlur(event);
    return this.baseModel.errorText;
  }
  onFocus(event) {
    this.baseModel.onFocus(event);
    this.justFocused = true;
  }
  onPaste(event) {
    this.baseModel.onPaste(event);
  }
  done(event) {
    this.baseModel.done(event);
    this.locString.__isEditing = false;
  }
  edit(event) {
    this.container.nativeElement.focus();
    this.locString.__isEditing = true;
    this.baseModel.onClick(event);
  }
  ngOnInit() {
    var _a;
    super.ngOnInit();
    (_a = this.locString) === null || _a === void 0 ? void 0 : _a.onStringChanged.add(this.onChangeHandler);
  }
  ngAfterViewInit() {
    if (this.locString.__isEditing) {
      this.container.nativeElement.focus();
    }
  }
  ngOnDestroy() {
    var _a;
    this.baseModel.blurEditor = undefined;
    this.baseModel.getEditorElement = undefined;
    this.baseModel.dispose();
    (_a = this.locString) === null || _a === void 0 ? void 0 : _a.onStringChanged.remove(this.onChangeHandler);
    super.ngOnDestroy();
  }
}
StringEditorComponent.ɵfac = function StringEditorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StringEditorComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
StringEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: StringEditorComponent,
  selectors: [["svc-string-edtior"]],
  viewQuery: function StringEditorComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], [1, "svc-string-editor__content"], [1, "svc-string-editor__border"], ["sv-ng-svg-icon", "", 1, "svc-string-editor__button", "svc-string-editor__button--edit", 3, "click", "iconName", "size"], [1, "svc-string-editor__input"], ["role", "textbox", "class", "sv-string-editor", "spellcheck", "false", 3, "textContent", "focus", "paste", "blur", "input", "keydown", "keyup", "compositionstart", "compositionend", "mouseup", "click", 4, "ngIf"], ["role", "textbox", "class", "sv-string-editor sv-string-editor--html", "spellcheck", "false", 3, "innerHtml", "focus", "blur", "keydown", "keyup", "compositionstart", "compositionend", "mouseup", "click", 4, "ngIf"], [3, "counter", "remainingCharacterCounter", 4, "ngIf"], ["class", "svc-string-editor__error", 4, "ngIf"], ["role", "textbox", "spellcheck", "false", 1, "sv-string-editor", 3, "focus", "paste", "blur", "input", "keydown", "keyup", "compositionstart", "compositionend", "mouseup", "click", "textContent"], ["role", "textbox", "spellcheck", "false", 1, "sv-string-editor", "sv-string-editor--html", 3, "focus", "blur", "keydown", "keyup", "compositionstart", "compositionend", "mouseup", "click", "innerHtml"], [3, "counter", "remainingCharacterCounter"], [1, "svc-string-editor__error"]],
  template: function StringEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, StringEditorComponent_ng_template_0_Template, 9, 10, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.CharacterCounterComponent, i2.NgIf, i1.SafeHtmlPipe],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StringEditorComponent, [{
    type: Component,
    args: [{
      selector: "svc-string-edtior",
      templateUrl: "./string-editor.component.html",
      styles: [":host { display: none; }"]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.NgZone
    }];
  }, {
    model: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ["container"]
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent(editableStringRendererName, StringEditorComponent);
class LogicOperatorComponent extends QuestionAngular {
  get dropdownModel() {
    return this.dropdownListModel;
  }
  click(event) {
    var _a;
    (_a = this.dropdownListModel) === null || _a === void 0 ? void 0 : _a.onClick(event);
  }
  clear(event) {
    var _a;
    (_a = this.dropdownListModel) === null || _a === void 0 ? void 0 : _a.onClear(event);
  }
  keyup(event) {
    var _a;
    (_a = this.dropdownListModel) === null || _a === void 0 ? void 0 : _a.keyHandler(event);
  }
  ngOnInit() {
    super.ngOnInit();
    this.dropdownListModel = this.model.dropdownListModel || new DropdownListModel(this.model);
    initLogicOperator(this.model);
  }
}
LogicOperatorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵLogicOperatorComponent_BaseFactory;
  return function LogicOperatorComponent_Factory(__ngFactoryType__) {
    return (ɵLogicOperatorComponent_BaseFactory || (ɵLogicOperatorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LogicOperatorComponent)))(__ngFactoryType__ || LogicOperatorComponent);
  };
})();
LogicOperatorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LogicOperatorComponent,
  selectors: [["svc-logic-operator"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [4, "ngIf"], ["disabled", "", 3, "class", 4, "ngIf"], [3, "click", "keyup"], [3, "model", 4, "ngIf"], [3, "class", "visible", "click", 4, "ngIf"], [3, "popupModel"], [3, "model"], [3, "click", "visible"], ["sv-ng-svg-icon", "", 3, "iconName", "size", "title"], ["disabled", ""]],
  template: function LogicOperatorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, LogicOperatorComponent_ng_template_0_Template, 3, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SurveyStringComponent, i1.SvgIconComponent, i1.PopupComponent, i2.NgIf, i1.VisibleDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LogicOperatorComponent, [{
    type: Component,
    args: [{
      selector: "svc-logic-operator",
      templateUrl: "./logic-operator.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("sv-logic-operator", LogicOperatorComponent);
RendererFactory.Instance.registerRenderer("dropdown", "logicoperator", "sv-logic-operator");
class MatrixCellComponent extends CreatorModelComponent {
  get creator() {
    return this.componentData.creator;
  }
  get question() {
    return this.componentData.question;
  }
  get isSupportCellEditor() {
    return this.adorner.isSupportCellEditor;
  }
  get column() {
    return this.componentData.column;
  }
  get element() {
    return this.componentData.element;
  }
  selectContext(event) {
    this.adorner.selectContext(this.adorner, event);
  }
  get row() {
    return this.componentData.row;
  }
  createModel() {
    var _a;
    if (this.componentData) {
      this.adorner = new MatrixCellWrapperViewModel(this.creator, this.element, this.question, this.row, this.column || ((_a = this.element.cell) === null || _a === void 0 ? void 0 : _a.column));
    }
  }
  getPropertiesToTrack() {
    return ["creator", "row", "column", "question"];
  }
  getModel() {
    return this.adorner;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.adorner.dispose();
  }
}
MatrixCellComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵMatrixCellComponent_BaseFactory;
  return function MatrixCellComponent_Factory(__ngFactoryType__) {
    return (ɵMatrixCellComponent_BaseFactory || (ɵMatrixCellComponent_BaseFactory = i0.ɵɵgetInheritedFactory(MatrixCellComponent)))(__ngFactoryType__ || MatrixCellComponent);
  };
})();
MatrixCellComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MatrixCellComponent,
  selectors: [["svc-matrix-cell"]],
  inputs: {
    componentName: "componentName",
    componentData: "componentData",
    contentTempl: "contentTempl"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["tabindex", "-1", 1, "svc-matrix-cell", 3, "click", "mouseover", "mouseleave"], [1, "svc-matrix-cell--selected"], [4, "ngTemplateOutlet"], ["class", "svc-matrix-cell__question-controls", 4, "ngIf"], [1, "svc-matrix-cell__question-controls"], [1, "svc-matrix-cell__question-controls-button", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 3, "iconName", "size"]],
  template: function MatrixCellComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, MatrixCellComponent_ng_template_0_Template, 4, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i2.NgTemplateOutlet, i2.NgIf, i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatrixCellComponent, [{
    type: Component,
    args: [{
      selector: "svc-matrix-cell",
      templateUrl: "./matrix-cell.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    componentName: [{
      type: Input
    }],
    componentData: [{
      type: Input
    }],
    contentTempl: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-matrix-cell", MatrixCellComponent);
class QuestionEditorComponent extends EmbeddedViewContentComponent {
  get question() {
    return this.survey.getAllQuestions()[0];
  }
  get elementComponentName() {
    return this.question.isPanel ? "panel" : "question";
  }
  get componentName() {
    const survey = this.survey;
    if (!!survey) {
      const name = survey.getElementWrapperComponentName(this.question);
      if (!!name) {
        return name;
      }
    }
    return this.elementComponentName;
  }
  get componentData() {
    const survey = this.survey;
    let data;
    if (!!survey) {
      data = survey.getElementWrapperComponentData(this.question);
    }
    return {
      componentName: this.elementComponentName,
      componentData: {
        model: this.question,
        data: data
      }
    };
  }
}
QuestionEditorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionEditorComponent_BaseFactory;
  return function QuestionEditorComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionEditorComponent_BaseFactory || (ɵQuestionEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionEditorComponent)))(__ngFactoryType__ || QuestionEditorComponent);
  };
})();
QuestionEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionEditorComponent,
  selectors: [["svc-question-editor-content"]],
  inputs: {
    survey: "survey"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [3, "component"]],
  template: function QuestionEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionEditorComponent_ng_template_0_Template, 1, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.DynamicComponentDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionEditorComponent, [{
    type: Component,
    args: [{
      selector: "svc-question-editor-content",
      templateUrl: "./question-editor.component.html"
    }]
  }], null, {
    survey: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-question-editor-content", QuestionEditorComponent);
class CellQuestionComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  get model() {
    return this.componentData.model;
  }
}
CellQuestionComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCellQuestionComponent_BaseFactory;
  return function CellQuestionComponent_Factory(__ngFactoryType__) {
    return (ɵCellQuestionComponent_BaseFactory || (ɵCellQuestionComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CellQuestionComponent)))(__ngFactoryType__ || CellQuestionComponent);
  };
})();
CellQuestionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CellQuestionComponent,
  selectors: [["svc-cell-question"]],
  inputs: {
    componentName: "componentName",
    componentData: "componentData"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-question__adorner"], [1, "svc-question__content", "svc-question__content--in-popup"], [3, "component"]],
  template: function CellQuestionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, CellQuestionComponent_ng_template_0_Template, 3, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CellQuestionComponent, [{
    type: Component,
    args: [{
      selector: "svc-cell-question",
      templateUrl: "./cell-question.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    componentName: [{
      type: Input
    }],
    componentData: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-cell-question", CellQuestionComponent);
class CellQuestionDropdownComponent extends CellQuestionComponent {
  getItemValueComponentName(item) {
    return this.model.getItemValueWrapperComponentName(item) || "sv-ng-selectbase-item";
  }
  getItemValueComponentData(item) {
    return {
      componentName: "sv-ng-selectbase-item",
      componentData: {
        question: this.model,
        model: item,
        inputType: "radio",
        data: this.model.getItemValueWrapperComponentData(item)
      }
    };
  }
}
CellQuestionDropdownComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCellQuestionDropdownComponent_BaseFactory;
  return function CellQuestionDropdownComponent_Factory(__ngFactoryType__) {
    return (ɵCellQuestionDropdownComponent_BaseFactory || (ɵCellQuestionDropdownComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CellQuestionDropdownComponent)))(__ngFactoryType__ || CellQuestionDropdownComponent);
  };
})();
CellQuestionDropdownComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CellQuestionDropdownComponent,
  selectors: [["svc-cell-dropdown-question"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-question__adorner"], [1, "svc-question__content", "svc-question__content--in-popup"], [3, "component"], [1, "svc-question__dropdown-choices"], ["class", "svc-question__dropdown-choice", 4, "ngFor", "ngForOf"], [1, "svc-question__dropdown-choice"]],
  template: function CellQuestionDropdownComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, CellQuestionDropdownComponent_ng_template_0_Template, 5, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.DynamicComponentDirective, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CellQuestionDropdownComponent, [{
    type: Component,
    args: [{
      selector: "svc-cell-dropdown-question",
      templateUrl: "./cell-question-dropdown.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("svc-cell-dropdown-question", CellQuestionDropdownComponent);
class CreatorRowComponent extends CreatorModelComponent {
  get row() {
    return this.componentData.row;
  }
  get creator() {
    return this.componentData.creator;
  }
  getModel() {
    return this.model;
  }
  createModel() {
    this.model = new RowViewModel(this.creator, this.row, undefined);
  }
  getPropertiesToTrack() {
    return ["creator", "row"];
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.model.dispose();
  }
}
CreatorRowComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCreatorRowComponent_BaseFactory;
  return function CreatorRowComponent_Factory(__ngFactoryType__) {
    return (ɵCreatorRowComponent_BaseFactory || (ɵCreatorRowComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CreatorRowComponent)))(__ngFactoryType__ || CreatorRowComponent);
  };
})();
CreatorRowComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CreatorRowComponent,
  selectors: [["svc-row"]],
  inputs: {
    componentData: "componentData"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [3, "row"]],
  template: function CreatorRowComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, CreatorRowComponent_ng_template_0_Template, 2, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.RowComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CreatorRowComponent, [{
    type: Component,
    args: [{
      selector: "svc-row",
      templateUrl: "./row.component.html"
    }]
  }], null, {
    componentData: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-row", CreatorRowComponent);
class QuestionWidgetDesignerComponent extends QuestionDesignerComponent {
  createModel() {
    if (this.componentData) {
      this.adorner = new QuestionAdornerViewModel(this.creator, this.model, null);
    }
  }
}
QuestionWidgetDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionWidgetDesignerComponent_BaseFactory;
  return function QuestionWidgetDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionWidgetDesignerComponent_BaseFactory || (ɵQuestionWidgetDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionWidgetDesignerComponent)))(__ngFactoryType__ || QuestionWidgetDesignerComponent);
  };
})();
QuestionWidgetDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionWidgetDesignerComponent,
  selectors: [["svc-widget-question"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["class", "svc-question__adorner", 3, "class", "mouseover", "mouseleave", 4, "ngIf"], [1, "svc-question__adorner", 3, "mouseover", "mouseleave"], ["class", "svc-question__content", "data-bind", "clickBubble: false", 3, "class", "key2click", "click", 4, "ngIf"], [4, "ngIf"], ["data-bind", "clickBubble: false", 1, "svc-question__content", 3, "click", "key2click"], ["class", "svc-question__drag-area", 3, "pointerdown", 4, "ngIf"], [1, "svc-widget__content"], [3, "component"], ["class", "svc-panel__placeholder_frame", 4, "ngIf"], [1, "svc-question__content-actions"], [3, "model", "handleClick"], [1, "svc-question__drag-area", 3, "pointerdown"], ["sv-ng-svg-icon", "", 1, "svc-question__drag-element", 3, "iconName", "size"], [1, "svc-panel__placeholder_frame"], ["data-bind", "text: placeholderText", 1, "svc-panel__placeholder"], [1, "svc-panel__placeholder"]],
  template: function QuestionWidgetDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionWidgetDesignerComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.ActionBarComponent, i2.NgIf, i1.Key2ClickDirective, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionWidgetDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-widget-question",
      templateUrl: "./question-widget.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("svc-widget-question", QuestionWidgetDesignerComponent);
class ToolboxComponent extends BaseAngular {
  get toolbox() {
    return this.model.toolbox;
  }
  getModel() {
    return this.toolbox;
  }
}
ToolboxComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵToolboxComponent_BaseFactory;
  return function ToolboxComponent_Factory(__ngFactoryType__) {
    return (ɵToolboxComponent_BaseFactory || (ɵToolboxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ToolboxComponent)))(__ngFactoryType__ || ToolboxComponent);
  };
})();
ToolboxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToolboxComponent,
  selectors: [["svc-toolbox"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-toolbox"], [1, "svc-toolbox__container"], [4, "ngIf"], [3, "category", "toolbox", 4, "ngFor", "ngForOf"], [3, "category", "toolbox"], [1, "svc-toolbox__category"], [3, "creator", "item", "parentModel", "isCompact", 4, "ngFor", "ngForOf"], [3, "creator", "item", "parentModel", "isCompact"]],
  template: function ToolboxComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ToolboxComponent_ng_template_0_Template, 4, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [ToolboxCategoryComponent, ToolboxToolComponent, i2.NgIf, i2.NgForOf],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolboxComponent, [{
    type: Component,
    args: [{
      selector: "svc-toolbox",
      templateUrl: "./toolbox.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-toolbox", ToolboxComponent);
class QuestionRatingDesignerComponent extends QuestionDesignerComponent {
  constructor() {
    super(...arguments);
    this.adornerComponent = "";
  }
}
QuestionRatingDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionRatingDesignerComponent_BaseFactory;
  return function QuestionRatingDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionRatingDesignerComponent_BaseFactory || (ɵQuestionRatingDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionRatingDesignerComponent)))(__ngFactoryType__ || QuestionRatingDesignerComponent);
  };
})();
QuestionRatingDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionRatingDesignerComponent,
  selectors: [["svc-rating-question"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], ["class", "svc-question__adorner", 3, "class", "dblclick", "mouseover", "mouseleave", 4, "ngIf"], [1, "svc-question__adorner", 3, "dblclick", "mouseover", "mouseleave"], [3, "click", "key2click"], ["class", "svc-question__drag-area", 3, "pointerdown", 4, "ngIf"], [3, "class", 4, "ngIf"], [3, "component"], ["class", "svc-panel__placeholder_frame-wrapper", 4, "ngIf"], [4, "ngIf"], [3, "model", 4, "ngIf"], [1, "svc-question__content-actions", 3, "focusin"], [3, "model", "handleClick"], [1, "svc-question__drag-area", 3, "pointerdown"], ["sv-ng-svg-icon", "", 1, "svc-question__drag-element", 3, "iconName", "size"], [1, "svc-question__top-actions"], [3, "model"], [1, "svc-panel__placeholder_frame-wrapper"], [1, "svc-panel__placeholder_frame"], [1, "svc-panel__placeholder"]],
  template: function QuestionRatingDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionRatingDesignerComponent_ng_template_0_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.ActionBarComponent, i1.SurveyStringComponent, QuestionBannerComponent, i2.NgIf, i1.Key2ClickDirective, i1.DynamicComponentDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionRatingDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-rating-question",
      templateUrl: "../question.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("svc-rating-question", QuestionRatingDesignerComponent);
class QuestionRatingAdornerDesignerComponent extends CreatorModelComponent {
  createModel() {
    if (this.componentData) {
      this.adorner = new QuestionRatingAdornerViewModel(this.componentData.data, this.componentData.model, null);
    }
  }
  getPropertiesToTrack() {
    return ["model", "creator"];
  }
  getModel() {
    return this.adorner;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.adorner.dispose();
  }
}
QuestionRatingAdornerDesignerComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionRatingAdornerDesignerComponent_BaseFactory;
  return function QuestionRatingAdornerDesignerComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionRatingAdornerDesignerComponent_BaseFactory || (ɵQuestionRatingAdornerDesignerComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionRatingAdornerDesignerComponent)))(__ngFactoryType__ || QuestionRatingAdornerDesignerComponent);
  };
})();
QuestionRatingAdornerDesignerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionRatingAdornerDesignerComponent,
  selectors: [["svc-rating-question-content"]],
  inputs: {
    componentName: "componentName",
    componentData: "componentData",
    contentTempl: "contentTempl"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "svc-rating-question-content"], ["sv-ng-svg-icon", "", 3, "iconName", "size", "key2click", "class", "click", 4, "ngIf"], [4, "ngTemplateOutlet"], ["sv-ng-svg-icon", "", 3, "click", "iconName", "size", "key2click"]],
  template: function QuestionRatingAdornerDesignerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, QuestionRatingAdornerDesignerComponent_ng_template_0_Template, 5, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i2.NgIf, i1.Key2ClickDirective, i2.NgTemplateOutlet],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionRatingAdornerDesignerComponent, [{
    type: Component,
    args: [{
      selector: "svc-rating-question-content",
      templateUrl: "./question-rating.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    componentName: [{
      type: Input
    }],
    componentData: [{
      type: Input
    }],
    contentTempl: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-rating-question-content", QuestionRatingAdornerDesignerComponent);
class CreatorLogoImageComponent extends CreatorModelComponent {
  createModel() {
    this.model = new LogoImageViewModel(this.creator, null);
  }
  getModel() {
    return this.model;
  }
  getPropertiesToTrack() {
    return ["data"];
  }
  get creator() {
    return this.data;
  }
  get survey() {
    return this.creator.survey;
  }
  ngAfterViewInit() {
    this.model.root = this.container.nativeElement;
  }
}
CreatorLogoImageComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCreatorLogoImageComponent_BaseFactory;
  return function CreatorLogoImageComponent_Factory(__ngFactoryType__) {
    return (ɵCreatorLogoImageComponent_BaseFactory || (ɵCreatorLogoImageComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CreatorLogoImageComponent)))(__ngFactoryType__ || CreatorLogoImageComponent);
  };
})();
CreatorLogoImageComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CreatorLogoImageComponent,
  selectors: [["svc-logo-image"]],
  viewQuery: function CreatorLogoImageComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c2, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
    }
  },
  inputs: {
    data: "data"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["container", ""], [1, "svc-logo-image"], ["type", "file", "aria-hidden", "true", "tabindex", "-1", 1, "svc-choose-file-input", 3, "accept"], [4, "ngIf"], ["class", "svc-logo-image__loading", 4, "ngIf"], [1, "svc-logo-image-placeholder", 3, "click", "key2click"], [0, "xlink", "href", "#icon-logo"], [1, "svc-context-container", "svc-logo-image-controls"], [1, "svc-context-button", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 3, "iconName", "size"], [1, "svc-context-button", "svc-context-button--danger", 3, "click", "key2click"], [3, "data"], [1, "svc-logo-image__loading"]],
  template: function CreatorLogoImageComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, CreatorLogoImageComponent_ng_template_0_Template, 6, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.LogoImageComponent, i1.LoadingIndicatorComponent, i2.NgIf, i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CreatorLogoImageComponent, [{
    type: Component,
    args: [{
      selector: "svc-logo-image",
      templateUrl: "./logo-image.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    data: [{
      type: Input
    }],
    container: [{
      type: ViewChild,
      args: ["container", {
        read: ElementRef
      }]
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-logo-image", CreatorLogoImageComponent);
class QuestionSpinEditorComponent extends QuestionAngular {}
QuestionSpinEditorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionSpinEditorComponent_BaseFactory;
  return function QuestionSpinEditorComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionSpinEditorComponent_BaseFactory || (ɵQuestionSpinEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionSpinEditorComponent)))(__ngFactoryType__ || QuestionSpinEditorComponent);
  };
})();
QuestionSpinEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionSpinEditorComponent,
  selectors: [["ng-component"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 7,
  vars: 24,
  consts: [[3, "keydown"], ["autocomplete", "off", 3, "change", "keydown", "keyup", "blur", "focus", "beforeinput", "disabled", "value"], ["tabindex", "-1", 3, "mousedown", "mouseup", "mouseleave", "blur", "focus", "disabled"], ["sv-ng-svg-icon", "", 3, "iconName", "size"]],
  template: function QuestionSpinEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("keydown", function QuestionSpinEditorComponent_Template_div_keydown_0_listener($event) {
        return ctx.model.onKeyDown($event);
      });
      i0.ɵɵelementStart(1, "input", 1);
      i0.ɵɵlistener("change", function QuestionSpinEditorComponent_Template_input_change_1_listener($event) {
        return ctx.model.onChange($event);
      })("keydown", function QuestionSpinEditorComponent_Template_input_keydown_1_listener($event) {
        return ctx.model.onInputKeyDown($event);
      })("keyup", function QuestionSpinEditorComponent_Template_input_keyup_1_listener($event) {
        return ctx.model.onKeyUp($event);
      })("blur", function QuestionSpinEditorComponent_Template_input_blur_1_listener($event) {
        return ctx.model.onBlur($event);
      })("focus", function QuestionSpinEditorComponent_Template_input_focus_1_listener($event) {
        return ctx.model.onFocus($event);
      })("beforeinput", function QuestionSpinEditorComponent_Template_input_beforeinput_1_listener($event) {
        return ctx.model.onBeforeInput($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(2, "span")(3, "button", 2);
      i0.ɵɵlistener("mousedown", function QuestionSpinEditorComponent_Template_button_mousedown_3_listener() {
        return ctx.model.onDownButtonMouseDown();
      })("mouseup", function QuestionSpinEditorComponent_Template_button_mouseup_3_listener() {
        return ctx.model.onButtonMouseUp();
      })("mouseleave", function QuestionSpinEditorComponent_Template_button_mouseleave_3_listener() {
        return ctx.model.onButtonMouseLeave();
      })("blur", function QuestionSpinEditorComponent_Template_button_blur_3_listener($event) {
        return ctx.model.onBlur($event);
      })("focus", function QuestionSpinEditorComponent_Template_button_focus_3_listener($event) {
        return ctx.model.onFocus($event);
      });
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(4, "svg", 3);
      i0.ɵɵelementEnd();
      i0.ɵɵnamespaceHTML();
      i0.ɵɵelementStart(5, "button", 2);
      i0.ɵɵlistener("mousedown", function QuestionSpinEditorComponent_Template_button_mousedown_5_listener() {
        return ctx.model.onUpButtonMouseDown();
      })("mouseup", function QuestionSpinEditorComponent_Template_button_mouseup_5_listener() {
        return ctx.model.onButtonMouseUp();
      })("mouseleave", function QuestionSpinEditorComponent_Template_button_mouseleave_5_listener() {
        return ctx.model.onButtonMouseLeave();
      })("blur", function QuestionSpinEditorComponent_Template_button_blur_5_listener($event) {
        return ctx.model.onBlur($event);
      })("focus", function QuestionSpinEditorComponent_Template_button_focus_5_listener($event) {
        return ctx.model.onFocus($event);
      });
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(6, "svg", 3);
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.model.cssClasses.root);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.control);
      i0.ɵɵproperty("disabled", ctx.model.isInputReadOnly)("value", ctx.model.renderedValue);
      i0.ɵɵattribute("id", ctx.model.inputId)("placeholder", ctx.model.renderedPlaceholder)("aria-required", ctx.model.ariaRequired)("aria-label", ctx.model.ariaLabel)("aria-invalid", ctx.model.ariaInvalid)("aria-describedby", ctx.model.ariaDescribedBy);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.buttonsContainer);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.arrowButton);
      i0.ɵɵproperty("disabled", ctx.model.isInputReadOnly);
      i0.ɵɵadvance();
      i0.ɵɵproperty("iconName", ctx.model.cssClasses.decreaseButtonIcon)("size", "auto");
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.arrowButton);
      i0.ɵɵproperty("disabled", ctx.model.isInputReadOnly);
      i0.ɵɵadvance();
      i0.ɵɵproperty("iconName", ctx.model.cssClasses.increaseButtonIcon)("size", "auto");
    }
  },
  dependencies: [i1.SvgIconComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionSpinEditorComponent, [{
    type: Component,
    args: [{
      templateUrl: "./spin-editor.component.html"
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("spinedit-question", QuestionSpinEditorComponent);
class ColorItemComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  getStyle() {
    return {
      backgroundColor: this.model.value
    };
  }
}
ColorItemComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵColorItemComponent_BaseFactory;
  return function ColorItemComponent_Factory(__ngFactoryType__) {
    return (ɵColorItemComponent_BaseFactory || (ɵColorItemComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ColorItemComponent)))(__ngFactoryType__ || ColorItemComponent);
  };
})();
ColorItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ColorItemComponent,
  selectors: [["svc-color-item"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [1, "spg-color-editor__color-swatch"], [3, "model"]],
  template: function ColorItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ColorItemComponent_ng_template_0_Template, 2, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SurveyStringComponent],
  styles: ["[_nghost-%COMP%] { display: none }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorItemComponent, [{
    type: Component,
    args: [{
      selector: "svc-color-item",
      templateUrl: "./color-item.component.html",
      styles: [":host { display: none }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("color-item", ColorItemComponent);
class QuestionColorComponent extends QuestionAngular {}
QuestionColorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionColorComponent_BaseFactory;
  return function QuestionColorComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionColorComponent_BaseFactory || (ɵQuestionColorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionColorComponent)))(__ngFactoryType__ || QuestionColorComponent);
  };
})();
QuestionColorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionColorComponent,
  selectors: [["svc-color"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 6,
  vars: 23,
  consts: [[3, "keydown"], ["sv-ng-svg-icon", "", 3, "iconName", "size"], ["type", "color", "tabindex", "-1", 3, "change", "disabled", "value"], ["autocomplete", "off", 3, "change", "keyup", "blur", "beforeinput", "disabled", "placeholder", "value"], [4, "ngIf"], [3, "model"], [3, "popupModel"]],
  template: function QuestionColorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("keydown", function QuestionColorComponent_Template_div_keydown_0_listener($event) {
        return ctx.model.onKeyDown($event);
      });
      i0.ɵɵelementStart(1, "label");
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(2, "svg", 1);
      i0.ɵɵnamespaceHTML();
      i0.ɵɵelementStart(3, "input", 2);
      i0.ɵɵlistener("change", function QuestionColorComponent_Template_input_change_3_listener($event) {
        return ctx.model.onColorInputChange($event);
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(4, "input", 3);
      i0.ɵɵlistener("change", function QuestionColorComponent_Template_input_change_4_listener($event) {
        return ctx.model.onChange($event);
      })("keyup", function QuestionColorComponent_Template_input_keyup_4_listener($event) {
        return ctx.model.onKeyUp($event);
      })("blur", function QuestionColorComponent_Template_input_blur_4_listener($event) {
        return ctx.model.onBlur($event);
      })("beforeinput", function QuestionColorComponent_Template_input_beforeinput_4_listener($event) {
        return ctx.model.onBeforeInput($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(5, QuestionColorComponent_ng_container_5_Template, 3, 2, "ng-container", 4);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.model.cssClasses.root);
      i0.ɵɵadvance();
      i0.ɵɵstyleMap(ctx.model.getSwatchStyle());
      i0.ɵɵclassMap(ctx.model.getSwatchCss());
      i0.ɵɵadvance();
      i0.ɵɵproperty("iconName", ctx.model.cssClasses.swatchIcon)("size", "auto");
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.colorInput);
      i0.ɵɵproperty("disabled", ctx.model.isInputReadOnly)("value", ctx.model.renderedColorValue);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.control);
      i0.ɵɵproperty("disabled", ctx.model.isInputReadOnly)("placeholder", ctx.model.renderedPlaceholder)("value", ctx.model.renderedValue);
      i0.ɵɵattribute("id", ctx.model.inputId)("aria-required", ctx.model.ariaRequired)("aria-label", ctx.model.ariaLabel)("aria-invalid", ctx.model.ariaInvalid)("aria-describedby", ctx.model.ariaDescribedBy);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.model.showDropdownAction);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.ActionBarItemComponent, i1.PopupComponent, i2.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionColorComponent, [{
    type: Component,
    args: [{
      templateUrl: "color.component.html",
      selector: "svc-color"
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("color-question", QuestionColorComponent);
class QuestionFileEditorComponent extends QuestionAngular {}
QuestionFileEditorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionFileEditorComponent_BaseFactory;
  return function QuestionFileEditorComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionFileEditorComponent_BaseFactory || (ɵQuestionFileEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionFileEditorComponent)))(__ngFactoryType__ || QuestionFileEditorComponent);
  };
})();
QuestionFileEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionFileEditorComponent,
  selectors: [["svc-file-editor"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 9,
  vars: 31,
  consts: [["contentElement", ""], [3, "dragenter", "dragover", "drop", "dragleave", "keydown"], ["type", "text", 3, "change", "blur", "disabled", "value"], ["type", "file", "tabindex", "-1", 3, "change", "disabled", "title", "accept"], ["type", "button", 3, "click", "disabled", "key2click"], ["size", "'auto'", "sv-ng-svg-icon", "", 3, "iconName", "title"], ["role", "button", 3, "click", "key2click"]],
  template: function QuestionFileEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div", 1, 0);
      i0.ɵɵlistener("dragenter", function QuestionFileEditorComponent_Template_div_dragenter_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.onDragEnter($event));
      })("dragover", function QuestionFileEditorComponent_Template_div_dragover_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.onDragOver($event));
      })("drop", function QuestionFileEditorComponent_Template_div_drop_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.onDrop($event));
      })("dragleave", function QuestionFileEditorComponent_Template_div_dragleave_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.onDragLeave($event));
      })("keydown", function QuestionFileEditorComponent_Template_div_keydown_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.onKeyDown($event));
      });
      i0.ɵɵelementStart(2, "input", 2);
      i0.ɵɵlistener("change", function QuestionFileEditorComponent_Template_input_change_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.onInputChange($event));
      })("blur", function QuestionFileEditorComponent_Template_input_blur_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.onInputBlur($event));
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(3, "input", 3);
      i0.ɵɵlistener("change", function QuestionFileEditorComponent_Template_input_change_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.onFileInputChange($event));
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "div")(5, "button", 4);
      i0.ɵɵlistener("click", function QuestionFileEditorComponent_Template_button_click_5_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.doClean());
      });
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(6, "svg", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵnamespaceHTML();
      i0.ɵɵelementStart(7, "label", 6);
      i0.ɵɵlistener("click", function QuestionFileEditorComponent_Template_label_click_7_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.model.chooseFiles($event));
      });
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(8, "svg", 5);
      i0.ɵɵelementEnd()()();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.model.cssClasses.root);
      i0.ɵɵadvance(2);
      i0.ɵɵclassMap(ctx.model.cssClasses.control);
      i0.ɵɵproperty("disabled", ctx.model.isInputReadOnly)("value", ctx.model.renderedValue || "");
      i0.ɵɵattribute("placeholder", ctx.model.renderedPlaceholder);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.fileInput);
      i0.ɵɵproperty("disabled", ctx.model.isInputReadOnly)("title", ctx.model.inputTitle)("accept", ctx.model.acceptedTypes);
      i0.ɵɵattribute("id", ctx.model.inputId)("aria-required", ctx.model.ariaRequired)("aria-label", ctx.model.ariaLabel)("aria-invalid", ctx.model.ariaInvalid)("aria-describedby", ctx.model.ariaDescribedBy)("multiple", false);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.buttonsContainer);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.clearButton);
      i0.ɵɵproperty("disabled", ctx.model.getIsClearButtonDisabled());
      i0.ɵɵadvance();
      i0.ɵɵproperty("iconName", ctx.model.cssClasses.clearButtonIcon)("title", ctx.model.clearButtonCaption);
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.getChooseButtonCss());
      i0.ɵɵattribute("for", ctx.model.inputId)("aria-label", ctx.model.chooseButtonCaption);
      i0.ɵɵadvance();
      i0.ɵɵproperty("iconName", ctx.model.cssClasses.chooseButtonIcon)("title", ctx.model.chooseButtonCaption);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.Key2ClickDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionFileEditorComponent, [{
    type: Component,
    args: [{
      selector: "svc-file-editor",
      templateUrl: "./file.component.html"
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("fileedit-question", QuestionFileEditorComponent);
class QuestionTextWithResetComponent extends QuestionAngular {
  getComponentName() {
    return this.model.wrappedQuestionTemplate + "-question";
  }
}
QuestionTextWithResetComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵQuestionTextWithResetComponent_BaseFactory;
  return function QuestionTextWithResetComponent_Factory(__ngFactoryType__) {
    return (ɵQuestionTextWithResetComponent_BaseFactory || (ɵQuestionTextWithResetComponent_BaseFactory = i0.ɵɵgetInheritedFactory(QuestionTextWithResetComponent)))(__ngFactoryType__ || QuestionTextWithResetComponent);
  };
})();
QuestionTextWithResetComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QuestionTextWithResetComponent,
  selectors: [["svc-text-with-reset"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 13,
  consts: [[3, "component"], [3, "click", "disabled"], ["size", "'auto'", "sv-ng-svg-icon", "", 3, "iconName"]],
  template: function QuestionTextWithResetComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div");
      i0.ɵɵtemplate(1, QuestionTextWithResetComponent_ng_template_1_Template, 0, 0, "ng-template", 0);
      i0.ɵɵelementStart(2, "button", 1);
      i0.ɵɵlistener("click", function QuestionTextWithResetComponent_Template_button_click_2_listener() {
        return ctx.model.resetValueAdorner.resetValue();
      });
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(3, "svg", 2);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.model.getRootClass());
      i0.ɵɵadvance();
      i0.ɵɵproperty("component", i0.ɵɵpureFunction2(10, _c1, ctx.getComponentName(), i0.ɵɵpureFunction1(8, _c0, ctx.model)));
      i0.ɵɵadvance();
      i0.ɵɵclassMap(ctx.model.cssClasses.resetButton);
      i0.ɵɵproperty("disabled", ctx.model.resetValueAdorner.isDisabled);
      i0.ɵɵattribute("title", ctx.model.resetValueAdorner.caption);
      i0.ɵɵadvance();
      i0.ɵɵproperty("iconName", ctx.model.cssClasses.resetButtonIcon);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.DynamicComponentDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QuestionTextWithResetComponent, [{
    type: Component,
    args: [{
      templateUrl: "./text-with-reset.component.html",
      selector: "svc-text-with-reset"
    }]
  }], null, null);
})();
AngularComponentFactory.Instance.registerComponent("textwithreset-question", QuestionTextWithResetComponent);
AngularComponentFactory.Instance.registerComponent("commentwithreset-question", QuestionTextWithResetComponent);
class JsonErrorItemComponent extends BaseAngular {
  getModel() {
    return this.model;
  }
  fixError(event) {
    event.stopPropagation();
    this.model.data.fixError();
  }
}
JsonErrorItemComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵJsonErrorItemComponent_BaseFactory;
  return function JsonErrorItemComponent_Factory(__ngFactoryType__) {
    return (ɵJsonErrorItemComponent_BaseFactory || (ɵJsonErrorItemComponent_BaseFactory = i0.ɵɵgetInheritedFactory(JsonErrorItemComponent)))(__ngFactoryType__ || JsonErrorItemComponent);
  };
})();
JsonErrorItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: JsonErrorItemComponent,
  selectors: [["svc-json-error-item"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], ["sv-ng-svg-icon", "", 3, "iconName", "size", "css"], [1, "svc-json-error__container"], [1, "svc-json-error__title"], [3, "model"], ["type", "button", "class", "svc-json-error__fix-button", 3, "key2click", "click", 4, "ngIf"], ["type", "button", 1, "svc-json-error__fix-button", 3, "click", "key2click"], ["sv-ng-svg-icon", "", 3, "iconName", "size"]],
  template: function JsonErrorItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, JsonErrorItemComponent_ng_template_0_Template, 5, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.SvgIconComponent, i1.SurveyStringComponent, i2.NgIf, i1.Key2ClickDirective],
  styles: ["[_nghost-%COMP%] { display: none }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonErrorItemComponent, [{
    type: Component,
    args: [{
      selector: "svc-json-error-item",
      templateUrl: "./json-error-item.component.html",
      styles: [":host { display: none }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("json-error-item", JsonErrorItemComponent);
class TranslateFromAction extends BaseAngular {
  getModel() {
    return this.model;
  }
}
TranslateFromAction.ɵfac = /* @__PURE__ */(() => {
  let ɵTranslateFromAction_BaseFactory;
  return function TranslateFromAction_Factory(__ngFactoryType__) {
    return (ɵTranslateFromAction_BaseFactory || (ɵTranslateFromAction_BaseFactory = i0.ɵɵgetInheritedFactory(TranslateFromAction)))(__ngFactoryType__ || TranslateFromAction);
  };
})();
TranslateFromAction.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TranslateFromAction,
  selectors: [["svc-translate-from-action"]],
  inputs: {
    model: "model"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["template", ""], [3, "model"]],
  template: function TranslateFromAction_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TranslateFromAction_ng_template_0_Template, 4, 6, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.ActionBarItemDropdownComponent],
  styles: ["[_nghost-%COMP%] { display: none; }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslateFromAction, [{
    type: Component,
    args: [{
      selector: "svc-translate-from-action",
      templateUrl: "./translate-from-action.component.html",
      styles: [":host { display: none; }"]
    }]
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
AngularComponentFactory.Instance.registerComponent("svc-translate-from-action", TranslateFromAction);
class SurveyCreatorModule {}
SurveyCreatorModule.ɵfac = function SurveyCreatorModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SurveyCreatorModule)();
};
SurveyCreatorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SurveyCreatorModule
});
SurveyCreatorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, FormsModule, SurveyModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SurveyCreatorModule, [{
    type: NgModule,
    args: [{
      declarations: [CreatorComponent, DesignerTabComponent, PageDesignerComponent, QuestionDesignerComponent, PanelDesignerComponent, TabbledMenuComponent, TabbedMenuItemComponent, TabbedMenuItemWrapperComponent, SidebarComponent, SidebarTabComponent, ObjectSelectorComponent, PropertyGridComponent, SearchComponent, TextareaJsonEditorComponent, AceJsonEditorComponent, LogicTabComponent, LogicAddButtonComponent, ActionButtonComponent, QuestionPgErrorComponent, SwitcherComponent, LinkValueQuestionComponent, EmbeddedSurveyQuestionComponent, TranslationTabComponent, TranslationSkeletonComponent, SimulatorComponent, TestTabComponent, TestAgainActionComponent, SurveyResultsComponent, SurveyResultsTableRowComponent, ThemeTabComponent, AdaptiveToolboxComponent, ToolboxToolComponent, ToolboxItemComponent, ToolboxItemGroupComponent, ToolboxListComponent, ToolboxCategoryComponent, StringEditorComponent, PageNavigatorComponent, PageNavigatorItemComponent, QuestionDropdownDesignerComponent, QuestionDropdownAdornerDesignerComponent, QuestionImageDesignerComponent, QuestionImageAdornerDesignerComponent, ItemValueDesignerComponent, ImageItemValueDesignerComponent, LogicOperatorComponent, MatrixCellComponent, QuestionEditorComponent, CellQuestionDropdownComponent, CreatorRowComponent, DesignerPagesComponent, DesignerSurveyComponent, CellQuestionComponent, QuestionWidgetDesignerComponent, ToolboxComponent, CreatorLogoImageComponent, QuestionRatingAdornerDesignerComponent, QuestionRatingDesignerComponent, QuestionSpinEditorComponent, ColorItemComponent, QuestionColorComponent, QuestionFileEditorComponent, AddQuestionButtonComponent, QuestionBannerComponent, JsonErrorItemComponent, QuestionTextWithResetComponent, TranslateFromAction],
      imports: [CommonModule, FormsModule, SurveyModule],
      exports: [CreatorComponent, DesignerTabComponent, PageDesignerComponent, QuestionDesignerComponent, PanelDesignerComponent, TabbledMenuComponent, TabbedMenuItemComponent, TabbedMenuItemWrapperComponent, SidebarComponent, SidebarTabComponent, ObjectSelectorComponent, PropertyGridComponent, SearchComponent, TextareaJsonEditorComponent, AceJsonEditorComponent, LogicTabComponent, LogicAddButtonComponent, ActionButtonComponent, QuestionPgErrorComponent, SwitcherComponent, LinkValueQuestionComponent, EmbeddedSurveyQuestionComponent, TranslationTabComponent, TranslationSkeletonComponent, SimulatorComponent, TestTabComponent, TestAgainActionComponent, SurveyResultsComponent, SurveyResultsTableRowComponent, ThemeTabComponent, AdaptiveToolboxComponent, ToolboxToolComponent, ToolboxItemComponent, ToolboxItemGroupComponent, ToolboxListComponent, ToolboxCategoryComponent, StringEditorComponent, PageNavigatorComponent, PageNavigatorItemComponent, QuestionDropdownDesignerComponent, QuestionDropdownAdornerDesignerComponent, QuestionImageDesignerComponent, QuestionImageAdornerDesignerComponent, ItemValueDesignerComponent, ImageItemValueDesignerComponent, LogicOperatorComponent, MatrixCellComponent, QuestionEditorComponent, CellQuestionDropdownComponent, CreatorRowComponent, DesignerPagesComponent, DesignerSurveyComponent, CellQuestionComponent, QuestionWidgetDesignerComponent, ToolboxComponent, CreatorLogoImageComponent, QuestionRatingAdornerDesignerComponent, QuestionRatingDesignerComponent, QuestionSpinEditorComponent, ColorItemComponent, QuestionColorComponent, QuestionFileEditorComponent, AddQuestionButtonComponent, QuestionBannerComponent, JsonErrorItemComponent, QuestionTextWithResetComponent, TranslateFromAction],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AceJsonEditorComponent, ActionButtonComponent, AdaptiveToolboxComponent, AddQuestionButtonComponent, CellQuestionComponent, CellQuestionDropdownComponent, ColorItemComponent, CreatorComponent, CreatorLogoImageComponent, CreatorRowComponent, DesignerPagesComponent, DesignerSurveyComponent, DesignerTabComponent, EmbeddedSurveyQuestionComponent, ImageItemValueDesignerComponent, ItemValueDesignerComponent, JsonErrorItemComponent, LinkValueQuestionComponent, LogicAddButtonComponent, LogicOperatorComponent, LogicTabComponent, MatrixCellComponent, ObjectSelectorComponent, PageDesignerComponent, PageNavigatorComponent, PageNavigatorItemComponent, PanelDesignerComponent, PropertyGridComponent, QuestionBannerComponent, QuestionColorComponent, QuestionDesignerComponent, QuestionDropdownAdornerDesignerComponent, QuestionDropdownDesignerComponent, QuestionEditorComponent, QuestionFileEditorComponent, QuestionImageAdornerDesignerComponent, QuestionImageDesignerComponent, QuestionPgErrorComponent, QuestionRatingAdornerDesignerComponent, QuestionRatingDesignerComponent, QuestionSpinEditorComponent, QuestionTextWithResetComponent, QuestionWidgetDesignerComponent, SearchComponent, SidebarComponent, SidebarTabComponent, SimulatorComponent, StringEditorComponent, SurveyCreatorModule, SurveyResultsComponent, SurveyResultsTableRowComponent, SwitcherComponent, TabbedMenuItemComponent, TabbedMenuItemWrapperComponent, TabbledMenuComponent, TestAgainActionComponent, TestTabComponent, TextareaJsonEditorComponent, ThemeTabComponent, ToolboxCategoryComponent, ToolboxComponent, ToolboxItemComponent, ToolboxItemGroupComponent, ToolboxListComponent, ToolboxToolComponent, TranslateFromAction, TranslationSkeletonComponent, TranslationTabComponent };

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { SocialWallBlogDTO } from 'src/app/models/dto/SocialWallBlogDTO';
import { SocialWallContentDTO } from 'src/app/models/dto/SocialWallContentDTO';
import { SocialWallDTO } from 'src/app/models/dto/SocialWallDTO';
import { SocialWallLikeDTO } from 'src/app/models/dto/SocialWallLikeDTO';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';
import { Helper } from '../../helpers/helper';

@Injectable({
  providedIn: 'root'
})
export class WallService {
  bckEndUrlSocialWall = `${environment.apiUrl}/api/v2/SocialWall`;
  bckEndUrlSocialWallBlog = `${environment.apiUrl}/api/v2/SocialWallBlog`;
  bckEndUrlSocialWallLike = `${environment.apiUrl}/api/v2/SocialWallLike`;
  bckEndUrlSocialWallContent = `${environment.apiUrl}/api/v2/SocialWallContent`;

  constructor(private http: HttpClient, private translationService: TranslationService) { }

  getSocialWallAllChild (classroomId: number, id?: number): Observable<SocialWallDTO[]> {
    let params = new HttpParams();

    if (id != undefined)
      params = params.append('prevId', String(id));

    return this.http.get<SocialWallDTO[]>(`${this.bckEndUrlSocialWall}/getSocialWallAllChild/${classroomId}/${this.translationService.currentLang.lang}`, { params: params, headers: Helper.getAuthHeader() });
  }

  getSocialWallBlogBySocialWallId (socialWallId: number): Observable<SocialWallBlogDTO[]> {
    let lang = this.translationService.currentLang.lang;
    return this.http.get<SocialWallBlogDTO[]>(`${this.bckEndUrlSocialWallBlog}/BySocialWall/${lang}/${socialWallId}`,
      { headers: Helper.getAuthHeader() });
  }

  getSocialWallLikeBySocialWallId (socialWallId: number): Observable<SocialWallLikeDTO[]> {
    return this.http.get<SocialWallLikeDTO[]>(`${this.bckEndUrlSocialWallLike}/BySocialWall/${socialWallId}`,
      { headers: Helper.getAuthHeader() });
  }

  getAllCustomer () {
    return this.http.get<CustomerDTO[]>(`${this.bckEndUrlSocialWall}/getAllCustomer`,
      { headers: Helper.getAuthHeader() });
  }

  postSocialWal (body: SocialWallDTO) {
    let lang = this.translationService.currentLang.lang;
    return this.http.post(`${this.bckEndUrlSocialWall}/${lang}/`, body, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  postBlog (body: SocialWallBlogDTO) {
    return this.http.post(`${this.bckEndUrlSocialWallBlog}`, body, { headers: Helper.getAuthHeader() })
  }

  putBlog(id: number, blog: SocialWallBlogDTO) {
    return this.http.put(`${this.bckEndUrlSocialWallBlog}/blog/${id}`, blog, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  deleteBlog(id: number) {
    return this.http.delete(`${this.bckEndUrlSocialWallBlog}/blog/${id}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  postSocialWallLike (body: SocialWallLikeDTO) {
    return this.http.post(`${this.bckEndUrlSocialWallLike}`, body, { headers: Helper.getAuthHeader() })
  }

  postSocialWallContent (body: SocialWallContentDTO) {
    return this.http.post(`${this.bckEndUrlSocialWallContent}/${this.translationService.currentLang.lang}`, body, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  deleteSocialWallLike (socialWallLikeId: number) {
    return this.http.delete(`${this.bckEndUrlSocialWallLike}/${socialWallLikeId}`, { headers: Helper.getAuthHeader() });
  }

  deleteSocialWallContent (id: number) {
    return this.http.delete(`${this.bckEndUrlSocialWallContent}/${id}`, { headers: Helper.getAuthHeader() });
  }

  getHastags(): Observable<string[]> {
    return this.http.get<string[]>(`${this.bckEndUrlSocialWall}/hashTags`, { headers: Helper.getAuthHeader() });
  }

  deleteSocialWallPost(socialWallPostId: number) {
    return this.http.delete(`${this.bckEndUrlSocialWall}/${socialWallPostId}`, { headers: Helper.getAuthHeader() });
  }

  editSocialWallPost(id: number, socialWallPost: SocialWallDTO) {
    return this.http.put(`${this.bckEndUrlSocialWall}/${id}`, socialWallPost, {headers: Helper.getAuthHeader() });
  }
}

"use strict";

/*
 * (C) Copyright 2017-2022 OpenVidu (https://openvidu.io)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Filter = void 0;
var StreamPropertyChangedEvent_1 = require("../OpenViduInternal/Events/StreamPropertyChangedEvent");
var OpenViduError_1 = require("../OpenViduInternal/Enums/OpenViduError");
var OpenViduLogger_1 = require("../OpenViduInternal/Logger/OpenViduLogger");
/**
 * @hidden
 */
var logger = OpenViduLogger_1.OpenViduLogger.getInstance();
/**
 * **WARNING**: experimental option. This interface may change in the near future
 *
 * Video/audio filter applied to a Stream. See {@link Stream.applyFilter}
 */
var Filter = /** @class */function () {
  /**
   * @hidden
   */
  function Filter(type, options) {
    /**
     * @hidden
     */
    this.handlers = new Map();
    this.type = type;
    this.options = options;
  }
  /**
   * Executes a filter method. Available methods are specific for each filter
   *
   * @param method Name of the method
   * @param params Parameters of the method
   */
  Filter.prototype.execMethod = function (method, params) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      var _a;
      logger.info('Executing filter method to stream ' + _this.stream.streamId);
      var finalParams;
      var successExecMethod = function (triggerEvent) {
        logger.info('Filter method successfully executed on Stream ' + _this.stream.streamId);
        var oldValue = Object.assign({}, _this.stream.filter);
        _this.stream.filter.lastExecMethod = {
          method: method,
          params: finalParams
        };
        if (triggerEvent) {
          _this.stream.session.emitEvent('streamPropertyChanged', [new StreamPropertyChangedEvent_1.StreamPropertyChangedEvent(_this.stream.session, _this.stream, 'filter', _this.stream.filter, oldValue, 'execFilterMethod')]);
          _this.stream.streamManager.emitEvent('streamPropertyChanged', [new StreamPropertyChangedEvent_1.StreamPropertyChangedEvent(_this.stream.streamManager, _this.stream, 'filter', _this.stream.filter, oldValue, 'execFilterMethod')]);
        }
        return resolve();
      };
      if (_this.type.startsWith('VB:')) {
        if (typeof params === 'string') {
          try {
            params = JSON.parse(params);
          } catch (error) {
            return reject(new OpenViduError_1.OpenViduError(OpenViduError_1.OpenViduErrorName.VIRTUAL_BACKGROUND_ERROR, 'Wrong params syntax: ' + error));
          }
        }
        finalParams = params;
        if (method === 'update') {
          if (!((_a = _this.stream.virtualBackgroundSinkElements) === null || _a === void 0 ? void 0 : _a.VB)) {
            return reject(new OpenViduError_1.OpenViduError(OpenViduError_1.OpenViduErrorName.VIRTUAL_BACKGROUND_ERROR, 'There is no Virtual Background filter applied'));
          } else {
            _this.stream.virtualBackgroundSinkElements.VB.updateValues(params).then(function () {
              return successExecMethod(false);
            }).catch(function (error) {
              if (error.name === OpenViduError_1.OpenViduErrorName.VIRTUAL_BACKGROUND_ERROR) {
                return reject(new OpenViduError_1.OpenViduError(error.name, error.message));
              } else {
                return reject(new OpenViduError_1.OpenViduError(OpenViduError_1.OpenViduErrorName.VIRTUAL_BACKGROUND_ERROR, 'Error updating values on Virtual Background filter: ' + error));
              }
            });
          }
        } else {
          return reject(new OpenViduError_1.OpenViduError(OpenViduError_1.OpenViduErrorName.VIRTUAL_BACKGROUND_ERROR, "Unknown Virtual Background method \"".concat(method, "\"")));
        }
      } else {
        var stringParams = void 0;
        if (typeof params !== 'string') {
          try {
            stringParams = JSON.stringify(params);
          } catch (error) {
            var errorMsg = "'params' property must be a JSON formatted object";
            logger.error(errorMsg);
            return reject(errorMsg);
          }
        } else {
          stringParams = params;
        }
        finalParams = stringParams;
        _this.stream.session.openvidu.sendRequest('execFilterMethod', {
          streamId: _this.stream.streamId,
          method: method,
          params: stringParams
        }, function (error, response) {
          if (error) {
            logger.error('Error executing filter method for Stream ' + _this.stream.streamId, error);
            if (error.code === 401) {
              return reject(new OpenViduError_1.OpenViduError(OpenViduError_1.OpenViduErrorName.OPENVIDU_PERMISSION_DENIED, "You don't have permissions to execute a filter method"));
            } else {
              return reject(error);
            }
          } else {
            return successExecMethod(true);
          }
        });
      }
    });
  };
  /**
   * Subscribe to certain filter event. Available events are specific for each filter
   *
   * @param eventType Event to which subscribe to.
   * @param handler Function to execute upon event dispatched. It receives as parameter a {@link FilterEvent} object
   *
   * @returns A Promise (to which you can optionally subscribe to) that is resolved if the event listener was successfully attached to the filter and rejected with an Error object if not
   */
  Filter.prototype.addEventListener = function (eventType, handler) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      logger.info('Adding filter event listener to event ' + eventType + ' to stream ' + _this.stream.streamId);
      _this.stream.session.openvidu.sendRequest('addFilterEventListener', {
        streamId: _this.stream.streamId,
        eventType: eventType
      }, function (error, response) {
        if (error) {
          logger.error('Error adding filter event listener to event ' + eventType + 'for Stream ' + _this.stream.streamId, error);
          if (error.code === 401) {
            return reject(new OpenViduError_1.OpenViduError(OpenViduError_1.OpenViduErrorName.OPENVIDU_PERMISSION_DENIED, "You don't have permissions to add a filter event listener"));
          } else {
            return reject(error);
          }
        } else {
          _this.handlers.set(eventType, handler);
          logger.info('Filter event listener to event ' + eventType + ' successfully applied on Stream ' + _this.stream.streamId);
          return resolve();
        }
      });
    });
  };
  /**
   * Removes certain filter event listener previously set.
   *
   * @param eventType Event to unsubscribe from.
   *
   * @returns A Promise (to which you can optionally subscribe to) that is resolved if the event listener was successfully removed from the filter and rejected with an Error object in other case
   */
  Filter.prototype.removeEventListener = function (eventType) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      logger.info('Removing filter event listener to event ' + eventType + ' to stream ' + _this.stream.streamId);
      _this.stream.session.openvidu.sendRequest('removeFilterEventListener', {
        streamId: _this.stream.streamId,
        eventType: eventType
      }, function (error, response) {
        if (error) {
          logger.error('Error removing filter event listener to event ' + eventType + 'for Stream ' + _this.stream.streamId, error);
          if (error.code === 401) {
            return reject(new OpenViduError_1.OpenViduError(OpenViduError_1.OpenViduErrorName.OPENVIDU_PERMISSION_DENIED, "You don't have permissions to add a filter event listener"));
          } else {
            return reject(error);
          }
        } else {
          _this.handlers.delete(eventType);
          logger.info('Filter event listener to event ' + eventType + ' successfully removed on Stream ' + _this.stream.streamId);
          return resolve();
        }
      });
    });
  };
  return Filter;
}();
exports.Filter = Filter;

<div class="component-main">
    <div fxLayout="column">
        <div class="card" style="text-align: center;">
            <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
                <i class="">✓</i>
            </div>
            <h1>Success</h1>
            <mat-form-field style="margin-left: 30px; margin-top: 15px;">
                <mat-label>{{ '' | translate }}</mat-label>
                <input matInput placeholder="Account code" (keyup)="getacctinput($event)">
            </mat-form-field>

            <button mat-raised-button color="primary" style="margin-left: 5px;" (click)="getacct()">
                <mat-icon>
                    person_search
                </mat-icon>
                {{ 'Search Stripe' | translate }}
            </button>
        </div>
    </div>
</div>
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ImportExternalDataUser } from 'src/app/models/importExternalData';
import { RolePipe } from 'src/app/pipes/rolePipe';

@Component({
  selector: 'app-import-users-lessons-update',
  templateUrl: './import-users-lessons-update.component.html',
  styleUrl: './import-users-lessons-update.component.scss'
})
export class ImportUsersLessonsUpdateComponent {

  usersIndex: number = -1;
  users: ImportExternalDataUser[] = [];

  uniqueIdForm: FormControl<string> = new FormControl();
  nameForm: FormControl<string> = new FormControl();
  surnameForm: FormControl<string> = new FormControl();
  emailForm: FormControl<string> = new FormControl();
  roleForm: FormControl<string> = new FormControl();
  cFForm: FormControl<string> = new FormControl();

  currentUniqueIdForm: FormControl<string> = new FormControl();
  currentNameForm: FormControl<string> = new FormControl();
  currentSurnameForm: FormControl<string> = new FormControl();
  currentEmailForm: FormControl<string> = new FormControl();
  currentRoleForm: FormControl<string> = new FormControl();
  currentCFForm: FormControl<string> = new FormControl();

  usersSource: MatTableDataSource<ImportExternalDataUser> = new MatTableDataSource<ImportExternalDataUser>();
  usersColumns: string[] = ['uniqueId', 'name', 'surname', 'action'];

  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.usersSource.sort = matSort;

    this.usersSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'action':
          return item.update;
        default:
          return item[property];
      }
    };

    this.usersSource.filterPredicate = (data, filter) => {
      return `${data.name} ${data.surname}`.toLowerCase().includes(filter)
          || data.uniqueId.toLowerCase().includes(filter)
          || data.name.toLowerCase().includes(filter)
          || data.surname.toLowerCase().includes(filter);
    };
  }

  /*
  @ViewChild(MatPaginator) set matPaginator(matPaginator: MatPaginator) {
    this.usersSource.paginator = matPaginator;
  }
  */

  recap: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ImportUsersLessonsUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private translate: TranslateService,
    private rolePipe: RolePipe
  ) {

    if (this.data.users) {
      this.users = this.data.users;
      this.next();
    }

  }

  prev() {
    if (this.recap) {
      this.usersSource.data = [];
      this.recap = false;

      return;
    }

    //if (this.usersIndex >= 0)
    //  this.users[this.usersIndex].update = update;

    if (this.usersIndex > 0)
      this.usersIndex--;

    this.showUser();
  }

  next(update: boolean = false) {
    if (this.recap)
      return;

    if (this.usersIndex >= 0)
      this.users[this.usersIndex].update = update;

    if (this.usersIndex === this.users.length - 1) {
      this.usersSource.data = this.users;
      this.recap = true;

      return;
    }

    this.usersIndex++;
    this.showUser();
  }

  save() {
    this.dialogRef.close(this.users);
  }

  isDifferent(form1: FormControl, form2: FormControl) {
    return form1.value !== form2.value;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  private showUser() {
    this.currentUniqueIdForm.setValue(this.users[this.usersIndex].currentUser.uniqueId);
    this.currentNameForm.setValue(this.users[this.usersIndex].currentUser.name);
    this.currentSurnameForm.setValue(this.users[this.usersIndex].currentUser.surname);
    this.currentEmailForm.setValue(this.users[this.usersIndex].currentUser.email);
    this.currentRoleForm.setValue(this.translate.instant(this.rolePipe.transform(this.users[this.usersIndex].currentUser.isTeacher ? 'Teacher' : 'Student')));
    this.currentCFForm.setValue(this.users[this.usersIndex].currentUser.cF);

    this.uniqueIdForm.setValue(this.users[this.usersIndex].uniqueId);
    this.nameForm.setValue(this.users[this.usersIndex].name);
    this.surnameForm.setValue(this.users[this.usersIndex].surname);
    this.emailForm.setValue(this.users[this.usersIndex].email);
    this.roleForm.setValue(this.translate.instant(this.rolePipe.transform(this.users[this.usersIndex].isTeacher ? 'Teacher' : 'Student')));
    this.cFForm.setValue(this.users[this.usersIndex].cF);
  }

}

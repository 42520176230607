export class GrantAccessRequest {
    public name: string;
    public surname: string;
    public role: string;

    constructor(name: string,
                surname: string,
                role: string) {
        
        this.name = name;
        this.surname = surname;
        this.role = role;
    }
}

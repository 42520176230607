<h1 mat-dialog-title>{{ 'To continue, please change the initial password and add some information' | translate }}</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="20px">

    <!--div fxLayout="row" fxLayoutGap="20px" class="field">
        <mat-form-field color="primary">
            <mat-label>{{ 'firstName' | translate }}</mat-label>
            <input matInput [formControl]="name">
            <mat-error>
                {{ 'The name is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    
        <mat-form-field color="primary">
            <mat-label>{{ 'surname' | translate }}</mat-label>
            <input matInput [formControl]="surname">
            <mat-error>
                {{ 'The surname is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
        
    </div-->

    <div [formGroup]="newPasswordForm" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">

        <mat-form-field fxFlex>
          <mat-label>{{ 'Actual password' | translate }}</mat-label>
          <input matInput type="password" formControlName="oldPassword">
          <mat-error *ngIf="newPasswordForm.controls.oldPassword.hasError('required')">
            {{ 'Old password is required' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>{{ 'New password' | translate }}</mat-label>
          <input matInput type="password" formControlName="newPassword">
          <mat-error *ngIf="newPasswordForm.controls.newPassword.hasError('required')">
            {{ 'New password is required' | translate }}
          </mat-error>
          <mat-error *ngIf="newPasswordForm.controls.newPassword.hasError('pattern')">
            {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>{{ 'Repeat new password' | translate }}</mat-label>
          <input matInput type="password" formControlName="newPasswordRepeat">
          <mat-error *ngIf="newPasswordForm.controls.newPasswordRepeat.hasError('required')">
            {{ 'New password is required' | translate }}
          </mat-error>
          <mat-error *ngIf="newPasswordForm.controls.newPasswordRepeat.hasError('different')">
            {{ 'New password and password confirmation are not the same' | translate }}
          </mat-error>
        </mat-form-field>

    </div>

    <!--mat-form-field color="primary" style="width:49%" style="padding-top:25px">
        <mat-label>Timezone</mat-label>
        <mat-select [formControl]="timezone" required>
            <mat-option *ngFor="let zone of timezones" [value]="zone">GMT {{ (zone > 0 ? '+' : '') + zone }}</mat-option>
        </mat-select>
        <mat-error>
            {{ 'The timezone is' | translate }} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field-->

    <div class="field">
        <mat-label>{{ 'Profile picture' | translate }}</mat-label>
        <app-file-input
            [control]="profilePicture"
            [maxSize]="1000"
            [acceptedTypes]="'image/*'"
            [rejectedErrorMessage]="('The file type must be a picture and not exceed' | translate) + ' 1MB'"
            [deletedErrorMessage]="'Please select a file' | translate"
            style="width: 100%">
        </app-file-input>
    </div>

    <span>
        {{ 'The information entered can subsequently be changed at any time' | translate }}
    </span>

</div>

<div mat-dialog-actions>
    <button mat-flat-button (click)="save()" [disabled]="!newPasswordForm.valid" color="accent">Ok</button>
</div>

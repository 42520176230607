import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { CalendarService } from 'src/app/services/calendar.service';
import { GenericPopupComponent, GenericPopupData } from '../generic-popup/generic-popup.component';
import { ImportUsersLessonsUpdateComponent } from './import-users-lessons-update/import-users-lessons-update.component';
import { ImportExternalDataUser } from 'src/app/models/importExternalData';

@Component({
  selector: 'app-import-users-lessons',
  templateUrl: './import-users-lessons.component.html',
  styleUrls: ['./import-users-lessons.component.scss']
})
export class ImportUsersLessonsComponent implements OnInit {

  sending: boolean = false;
  mediaSrc: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);

  error: string = undefined;

  constructor(private dialogRef: MatDialogRef<ImportUsersLessonsComponent>,
              private dialog: MatDialog,
              private calendarService: CalendarService,
              //private snackBar: MatSnackBar,
              private translate: TranslateService) { }

  ngOnInit(): void { }

  async addEvents() {
    this.toggleDisable(true);

    this.error = undefined;

    try {

      let data = await firstValueFrom(this.calendarService.importExternalDataCheck(this.mediaSrc.value));

      let usersToEdit = data.users.filter(u => u.askToUpdate === true);

      if (usersToEdit.length > 0) {

        let dialogRef = this.dialog.open(ImportUsersLessonsUpdateComponent, {
          width: '800px',
          data: { users: usersToEdit }
        });

        let result = await firstValueFrom(dialogRef.afterClosed()) as ImportExternalDataUser[];

        if (result) {

          result.map(r => {

            let i = data.users.findIndex(u => u.uniqueId === r.uniqueId);

            if (i === -1)
              return r;

            data.users[i] = r;

            return r;

          });

        } else {

          this.dialogRef.close(false);
          return;

        }

      }

      let res: any = await firstValueFrom(this.calendarService.importExternalData(data));

      if (res.Message) {
        let formattedMsg = res.Message.split(',').join('\n');

        this.dialog.open(GenericPopupComponent, {
          width: '500px',
          data: <GenericPopupData>{
            title: await firstValueFrom(this.translate.get('Import log')),
            body: formattedMsg,
            hideCancelBtn: true
          }
        });
      }

      this.dialogRef.close(true);

    } catch (e) {
      console.error(e);

      this.error = e?.error?.Message ?? e?.error ?? 'Unknown error';

      //this.snackBar.open(e.error.Message, '', { duration: 3000 })
      this.toggleDisable(false);
    }
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if (toggle) {
      this.mediaSrc.disable();
    } else {
      this.mediaSrc.enable();
    }
  }

}

import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(url: string): any {
        
        if (!url)
            return null;

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

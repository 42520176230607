import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { ConferenceClassroomDTO } from 'src/app/models/dto/conferenceClassroomDTO';
import { ConferencePresenterDTO } from 'src/app/models/dto/conferencePresenterDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { UserOnlineTime } from 'src/app/models/userOnlineTime';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-conference-user-table',
  templateUrl: './conference-user-table.component.html',
  styleUrl: './conference-user-table.component.scss'
})
export class ConferenceUserTableComponent {

  private _presenterIds: number[] = [];
  private _moderatorIds: number[] = [];
  private _substituteIds: number[] = [];

  private _participants: UserDTO[] = [];

  private _firstSet: boolean = true;
  private _showClassrooms: boolean = false;
  private _conferenceClassrooms: ConferenceClassroomDTO[] = [];
  private _classrooms: ClassroomDTO[] = [];

  private _usersOnlineTime: UserOnlineTime[] = [];

  private _showDashboard: boolean = false;

  @Input()
  set presenters(value: ConferencePresenterDTO[]) {

    if (!value)
      value = [];

    this._presenterIds = value
      .filter(p => p.role === ConferencePresenterRole.Presenter)
      .map(p => p.idPresenter);

    this._moderatorIds = value
      .filter(m => m.role === ConferencePresenterRole.Moderator)
      .map(m => m.idPresenter);

    this._substituteIds = value
      .filter(s => s.role === ConferencePresenterRole.Substitute)
      .map(s => s.idPresenter);

    this.dataSource.data = this.dataSource.data
      .map(d => {

        d.role = this.getUserRole(d.id);

        return d;

      });

  }

  @Input()
  set conferenceClassroomId(value: number) {

    if (!value) {

      this._participants = [];
      this.dataSource.data = [];

      return;

    }

    this.calendarService
      .getClassroom(value)
      .subscribe({
        next: async classroom => {

          this._participants = classroom
            .teacherClassroom
            .map(tc => tc.user)
            .concat(
              classroom
                .classroomContent
                .map(cc => cc.user)
            );

          await firstValueFrom(this.calendarService.getUsersOnlineTime(this._participants.map(p => p.id)))
            .then(res => this._usersOnlineTime = res)
            .catch(err => console.error(err));

          this.dataSource.data = this._participants
            .map(p => {

              let joined = classroom
                .teacherClassroom
                .find(tc => tc.userId === p.id)?.startDate;

              if (joined == undefined)
                joined = classroom
                  .classroomContent
                  .find(cc => cc.userId === p.id)?.startDate;

              return <ConferenceParticipant>{
                id: p.id,
                picture: p.profilePictureUrl,
                name: `${p.name} ${p.surname}`,
                role: this.getUserRole(p.id),
                subscribed: p.created,
                joined: joined,
                classrooms: this.getUserClassrooms(p.id).map(c => c.name),
                totalOnlineTime: this._usersOnlineTime
                  .filter(ot => ot.id === p.id)
                  .reduce((partialSum, v) => partialSum + v.totalseconds, 0)
              }
            });

        },
        error: err => {
          console.error(err);

          this._participants = [];
          this.dataSource.data = [];
        }
      });

  }

  @Input()
  set conferenceClassroom(values: ConferenceClassroomDTO[]) {

    if (!values)
      values = [];

    this._conferenceClassrooms = values;

    if (!this._showClassrooms)
      return;

    if (this._firstSet || values.slice().pop()?.idConference !== this._conferenceClassrooms.slice().pop()?.idConference) {

      this._firstSet = false;

      new Promise(async (resolve) => {

        for (let i=0; i<values.length; i++) {

          try {
        
            let classroom = await firstValueFrom(this.calendarService.getClassroom(values[i].idClassroom));
    
            this._classrooms.push(classroom);
    
          } catch (e) {
            console.error(e);
          }
  
        }

        resolve(true);

      })
      .then(() => {

        this.dataSource.data = this.dataSource.data
          .map(d => {
    
            d.classrooms = this.getUserClassrooms(d.id).map(c => c.name);
    
            return d;
    
          });

      });

    }

  }

  @Input()
  set showClassroomsColumn(value: boolean) {
    this._showClassrooms = value;

    let index = this.displayedColumns.findIndex(c => c === 'classrooms');

    if (this._showClassrooms && index === -1)
      this.displayedColumns.splice(4, 0, 'classrooms');

    if (!this._showClassrooms && index !== -1)
      this.displayedColumns.splice(4, 1);

    this.conferenceClassroom = this._conferenceClassrooms;
  }

  @Input()
  set showDashboardColumn(value: boolean) {
    this._showDashboard = value;

    let index = this.displayedColumns.findIndex(c => c === 'actions');

    if (this._showDashboard && index === -1)
      this.displayedColumns.push('actions');

    if (!this._showDashboard && index !== -1)
      this.displayedColumns.splice(index, 1);
  }

  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    this.dataSource.paginator = matPaginator;
  }

  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.dataSource.sort = matSort;
  }

  dataSource: MatTableDataSource<ConferenceParticipant> = new MatTableDataSource();
  displayedColumns: string[] = ['name', 'role', 'subscribed', 'joined', 'classrooms', 'totalOnlineTime', 'actions'];

  searchWordForm: FormControl<string> = new FormControl();

  constructor(
    private router: Router,
    private calendarService: CalendarService
  ) { }

  applyFilter() {
    this.dataSource.filter = this.searchWordForm.value?.trim()?.toLowerCase();
  }

  showClassrooms(classrooms: string[]) {
    return classrooms.length > 0
         ? classrooms.join(', ')
         : '-';
  }

  formatSeconds(seconds: number) {
    if (!seconds)
      return '0m';

    seconds = Number(seconds);

    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor(seconds % (3600 * 24) / 3600);
    let m = Math.floor(seconds % 3600 / 60);

    return `${d > 0 ? d + 'd' : ''} ${h > 0 ? h + 'h' : ''} ${m > 0 ? m + 'm' : '0m'}`;
  }

  goToUserDashboard(userId: number) {
    this.router.navigate(['/user', userId]);
  }

  private getUserRole(userId: number) {
    return this._moderatorIds.some(m => m === userId)
         ? 'Moderator'
         : this._presenterIds.some(p => p === userId)
         ? 'Presenter'
         : this._substituteIds.some(s => s === userId)
         ? 'Substitute'
         : 'Participant';
  }

  private getUserClassrooms(userId: number) {
    return this._classrooms.filter(c => c.teacherClassroom.some(tc => tc.userId === userId) || c.classroomContent.some(cc => cc.userId === userId));
  }

}

class ConferenceParticipant {
  public id: number;
  public picture: string;
  public name: string;
  public role: string;
  public subscribed: Date;
  public joined: Date;
  public classrooms: string[];
  public totalOnlineTime: number;
}

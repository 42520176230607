import { Component, Input, OnInit, Output,EventEmitter  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { SelectionElement, SelectionMode, SelectionService } from 'src/app/services/selection.service';

@Component({
  selector: 'app-showcase-card',
  templateUrl: './showcase-card.component.html',
  styleUrls: ['./showcase-card.component.scss']
})
export class ShowcaseCardComponent implements OnInit {

  @Input()
  routeUrl: string;

  @Input()
  routeId: number = null;

  @Input()
  queryParams: any = null;

  @Input()
  routeMessage: string;

  @Input()
  profileId: number;

  @Input()
  moderatorId: number;

  @Input()
  imgUrl: string;

  @Input()
  topLeft: string = null;

  @Input()
  topRight: string = null;

  @Input()
  bottomLeft: string = null;

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  subtitle_extra: string;

  @Input()
  description: string;

  @Input()
  subscription: SubscriptionDTO;

  @Input()
  hasSubscription: boolean = true;

  @Input()
  isMobile: boolean = false;

  @Input()
  buyShow: boolean = false;

  @Input()
  buyDisabled: boolean = true;

  @Input()
  renewShow: boolean = false;

  @Input()
  compact: boolean = false;

  @Input()
  enableHighlight: boolean = false;

  @Input()
  isHighlighted: boolean = false;

  @Input()
  clickAsGoTo: boolean = true;

  @Input()
  style: string = undefined;

  //Inizio - selezione
  @Input()
  enableSelectionOn: SelectionMode[] = [];

  @Input()
  selectionEntity: SelectionElement = null;
  //Fine - selezione

  @Output()
  buyFree: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  buyPay: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  showInfoBtn: boolean = false;

  canpurchase: boolean = true;

  constructor(private router: Router,
              private selectionService: SelectionService,
              private darkService: DarkThemeService,
              private subscriptionService: SubscriptionService,
              private translate: TranslateService,
              private dialog: MatDialog) { }

  ngOnInit(): void { }

  goTo(): void {
    if (this.isMobile)
      return;

    let route: any[] = [this.routeUrl];

    if (this.routeId)
      route.push(this.routeId);

    this.router.navigate(route, { queryParams: this.queryParams });
  }

  buy(): void {
    this.subscriptionService.checkSubscriptionPurchaseLimits(this.subscription.id)
      .subscribe({
        next: () => {
          this.canpurchase = true;

          this.subscription.isFree ? this.buyFree.emit(true) : this.buyPay.emit(true);
        },
        error: async err => {
          console.error(err);

          this.canpurchase = false;

          this.dialog.open(GenericPopupComponent,
            {
              width: '400px',
              data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Warning')),
                body: err.error,
                hideCancelBtn: true
              }
            });
        }
      });
  }

  //Per selezione entita
  isSelectionEnabled() {
    return this.selectionService.showSelection &&
           this.enableSelectionOn.includes(this.selectionService.mode) &&
           this.selectionEntity != null;
  }

  selectionToggle(event: any) {
    if(event.checked && !this.selectionMaxLength())
      this.selectionService.addElement(this.selectionEntity);
    else
      this.selectionService.removeElement(this.selectionEntity.id, this.selectionEntity.type);
  }

  checkSelectedEntity() {
    return this.selectionService.elementExist(this.selectionEntity.id, this.selectionEntity.type);
  }

  selectionMaxLength() {
    return this.selectionService.isFull();
  }

  isDark() {
    return this.darkService.isSetDark;
  }

}

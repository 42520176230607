<h1 mat-dialog-title>{{ title | translate }}</h1>

<div mat-dialog-content>

    <mat-stepper #stepper (selectedIndexChange)="stepperIndex = $event">

        <mat-step>

            <div class="stepper-gap">
                <app-translations-form
                    [label]="'Title' | translate"
                    [disabled]="sending"
                    [(translation)]="nameTranslation"
                    [style.margin-top]="isPublicForm.value &&
                                        (dateForm.controls.endDateForm.hasError('durationLimit') ||
                                         dateForm.controls.endDateForm.hasError('dateDifference') ||
                                         dateForm.controls.startDateForm.hasError('futureDate'))
                                        ? '20px'
                                        : ''">
            
                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Title' | translate }}</mat-label>
                        <input matInput autofocus [formControl]="nameForm" type="text">
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>
            
                </app-translations-form>

                <div [formGroup]="dateForm" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'Start' | translate }}</mat-label>
                        <input matInput
                            [type]="getShowDateOrTime() ? 'datetime-local' : 'time'"
                            [style.color]="dateForm.controls.startDateForm.hasError('futureDate') ? 'red' : '' "
                            formControlName="startDateForm">
                        <mat-error *ngIf="dateForm.controls.startDateForm.hasError('futureDate')">
                            {{ 'The date must be a future day' | translate }} 
                        </mat-error>
                        <mat-error *ngIf="dateForm.controls.startDateForm.hasError('required')">
                            {{ 'The date is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
            
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'End' | translate }}</mat-label>
                        <input matInput
                            type="time"
                            formControlName="endDateForm"
                            [style.color]="dateForm.controls.endDateForm.hasError('dateDifference') || dateForm.controls.endDateForm.hasError('durationLimit') ? 'red' : '' ">
                        <mat-error *ngIf="dateForm.controls.endDateForm.hasError('required')">
                            {{ 'The date is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="dateForm.controls.endDateForm.hasError('dateDifference')">
                            {{ 'The difference in dates must' | translate }} <strong> {{ 'not be negative' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="dateForm.controls.endDateForm.hasError('durationLimit')">
                            {{ 'The conference can last a maximum of value hours' | translate: { value: maxDurationHours() } }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="!isPublicForm.value">
                    <mat-button-toggle-group [formControl]="idModeForm" fxFlex>
                        <mat-button-toggle *ngFor="let idMode of idModes" [value]="idMode.value" fxFlex>
                            {{ idMode.name | translate }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>

                <div *ngIf="currentUser.isTutor || currentUser.isCustomerAdmin || currentUser.isAdmin">
                    <mat-form-field>
                        <mat-label>{{ 'Moderator' | translate }}</mat-label>
                        <mat-select [formControl]="publisherForm" (selectionChange)="userSelected()">
                            <mat-option>
                                <ngx-mat-select-search
                                    [formControl]="publisherListSearchForm"
                                    [placeholderLabel]="'Search' | translate"
                                    [noEntriesFoundLabel]="'No data matching the word' | translate">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let user of publishers | search: publisherListSearchForm.value: ['name', 'surname']"
                                [value]="user.id"
                                [style.color]="darkService.isSetDark ? 'white' : ''">
                                {{ user.name }} {{ user.surname }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="column" *ngIf="idModeForm.value === 2">

                    <div fxFlex="row" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                        <h3 fxFlex="50">{{ 'Speakers list' | translate }}</h3>
                        <mat-form-field fxFlex>
                            <mat-label>{{ 'Choose an option' | translate }}</mat-label>
                            <mat-select [formControl]="presenterListForm" (selectionChange)="userSelected()" multiple>
                                <mat-option>
                                    <ngx-mat-select-search
                                        [formControl]="presenterListSearchForm"
                                        [placeholderLabel]="'Search' | translate"
                                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let user of presenters | search: presenterListSearchForm.value: ['name', 'surname']"
                                    [disabled]="presenterOptionDisabled(user.id)"
                                    [value]="user.id"
                                    [style.color]="darkService.isSetDark ? 'white' : ''">
                                    {{ user.name }} {{ user.surname }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="presenterListForm.hasError('required')">
                                {{ 'The choose is' | translate }} <strong>{{ 'required' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
            
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid">
                        <div *ngFor="let user of getSelectedUsers()" style="font-size: 14px;">
                            {{ user.name }} {{ user.surname }}
                        </div>
                    </div>
            
                </div>
            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">
                <div fxLayout="column" *ngIf="!isPublicForm.value && !isMasterPage()">

                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                        <h3 fxFlex="50">{{ 'Classrooms list' | translate }}</h3>
                        <mat-form-field fxFlex>
                            <mat-label>{{ 'Choose an option' | translate }}</mat-label>
                            <mat-select [formControl]="classroomsListForm" multiple>
                                <mat-option>
                                    <ngx-mat-select-search
                                        [formControl]="classroomListSearchForm"
                                        [placeholderLabel]="'Search' | translate"
                                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let class of classrooms | search: classroomListSearchForm.value: ['name', 'description']" 
                                    [style.color]="darkService.isSetDark ? 'white' : ''"   
                                    [value]="class.id">
                                    {{ class.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="classroomsListForm.hasError('required')">
                                {{ 'The choose is' | translate }} <strong>{{ 'required' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
            
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid">
                        <div *ngFor="let classroom of getSelectedClassrooms()" style="font-size: 14px;">
                            {{ classroom.name }}
                        </div>
                    </div>
             
                </div>
            
                <div fxLayout="column">
            
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="50">
                            <h3>{{ 'Virtual rooms list' | translate }}</h3>
                            <button mat-icon-button (click)="createVirtualRoom = !createVirtualRoom">
                                <mat-icon>{{ createVirtualRoom ? 'remove' : 'add' }}</mat-icon>
                            </button>
                        </div>
                        <mat-form-field fxFlex>
                            <mat-label>{{ 'Choose an option' | translate }}</mat-label>
                            <mat-select [formControl]="virtualRoomsListForm" multiple>
                                <mat-option>
                                    <ngx-mat-select-search
                                        [formControl]="virtualroomListSearchForm"
                                        [placeholderLabel]="'Search' | translate"
                                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let virtualRoom of virtualRooms | search: virtualroomListSearchForm.value: ['name', 'description']"
                                    [disabled]="virtualRoomOptionDisabled(virtualRoom.id)"
                                    [value]="virtualRoom.id"
                                    [style.color]="darkService.isSetDark ? 'white' : ''">
                                    {{ virtualRoom.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
            
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid">
                        <div *ngFor="let virtualRoom of getSelectedVirtualRooms()"
                            style="display: flex; justify-content: space-between; align-items: center; font-size: 14px;">
                            {{ virtualRoom.name }}
                            <button mat-icon-button (click)="deleteVirtualRoom(virtualRoom.id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
            
                </div>
            
                <div fxLayout="column" *ngIf="createVirtualRoom" style="padding: 0px 20px 20px 20px">
            
                    <mat-form-field>
                        <mat-label>{{ 'Name' | translate }}</mat-label>
                        <input matInput type="text" [formControl]="nameRoomForm">
                        <mat-error *ngIf="nameRoomForm.hasError('required')">
                            {{ 'Name is required' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <!--mat-form-field>
                        <mat-label>{{ 'Description' | translate }}</mat-label>
                        <input matInput type="text" [formControl]="descriptionRoomForm">
                    </mat-form-field-->
            
                    <button mat-flat-button color="accent"
                        (click)="addVirtualRoom()"
                        [disabled]="!nameRoomForm.valid"><!--[disabled]="!nameRoomForm.valid || !descriptionRoomForm.valid"-->
                        {{ 'Create room' | translate }}
                    </button>
            
                </div>
            
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <h3 fxFlex="50">{{ 'Substitute' | translate }} </h3>
                    <mat-form-field fxFlex>
                        <mat-label >{{ 'Choose an option' | translate }}</mat-label>
                        <mat-select [formControl]="substituteForm" (selectionChange)="userSelected()">
                            <mat-option>
                                <ngx-mat-select-search
                                    [formControl]="substituteListSearchForm"
                                    [placeholderLabel]="'Search' | translate"
                                    [noEntriesFoundLabel]="'No data matching the word' | translate">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let user of substitutes | search: substituteListSearchForm.value: ['name', 'surname']"
                                [style.color]="darkService.isSetDark ? 'white' : ''" 
                                [value]="user.id"> 
                                {{ user.name }} {{ user.surname }} 
                            </mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>
            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">
                <div fxLayout="row">
                    <mat-checkbox matInput
                        [formControl]="recordForm"
                        fxFlex="50">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <mat-icon color="warn">fiber_manual_record</mat-icon>
                            <span style="font-size: larger;"
                                [matTooltip]="'Enable recording for the session' | translate">
                                {{ 'Record' | translate }}
                            </span>
                        </div>
                    </mat-checkbox>
                    <mat-checkbox matInput
                        *ngIf="recordForm.value"
                        [formControl]="privateForm"
                        fxFlex>
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <mat-icon>lock</mat-icon>
                            <span style="font-size: larger;" [matTooltip]="'This setting will make the recording accessible only to you' | translate">{{ 'Private recording' | translate }}</span>
                        </div>
                    </mat-checkbox>
                </div>
            </div>

        </mat-step>

    </mat-stepper>

    <div>
        <h3>{{ (isPublicForm.value ? 'Public conference' : 'Private conference') | translate }}</h3>
        <mat-label>{{ (isPublicForm.value ? 'Anyone with the link can join the conference' : 'Only members of the selected classes can join the conference') | translate }}</mat-label>
    </div>

</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>

    <div fxLayout="row">
        <button mat-stroked-button
            *ngIf="stepperIndex !== 0"
            (click)="stepper.previous()">
            {{ 'Back' | translate }}
        </button>
        <button mat-flat-button color="primary"
            *ngIf="stepperIndex !== 2"
            [disabled]="nextBtnDisabled()"
            (click)="stepper.next()">
            {{ 'Next' | translate }}
        </button>
        <button mat-flat-button color="accent"
            *ngIf="stepperIndex === 2"
            (click)="saveConference()"
            [disabled]="okBtnDisabled()">
            Ok
        </button>
    </div>
</div>

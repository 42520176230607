import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { Classroom } from 'src/app/models/classroom';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ClassroomService } from 'src/app/services/classroom.service';

@Component({
  selector: 'app-add-classroom',
  templateUrl: './add-classroom.component.html',
  styleUrl: './add-classroom.component.scss'
})
export class AddClassroomComponent implements OnInit {

  currentUser: User = null;
  sendingClassroom: boolean = false;

  classId: number = null;
  customerName: string = null;
  customerId: number = null;

  name: FormControl<string> = new FormControl('', [Validators.required]);
  description: FormControl<string> = new FormControl('', [Validators.required]);
  googleCalendar: boolean = false;

  constructor(private dialogRef: MatDialogRef<AddClassroomComponent>,
              @Inject(MAT_DIALOG_DATA) private data,
              private auth: AuthService,
              private classroomService: ClassroomService,
              private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.customerId = this.data?.customerId;
    this.customerName = this.data?.customerName;

    if (this.data?.classroom != undefined) {

      this.classId = this.data.classroom.id;

      this.name.setValue(this.data.classroom.name);
      this.description.setValue(this.data.classroom.description);

    }
  }

  async save() {
    this.lockEdit(true);

    let classroom = new Classroom();

    classroom.name = this.name.value;
    classroom.description = this.description.value;

    let operation = this.classId != undefined
                  ? this.classroomService.updateClassroom(classroom, this.classId)
                  : this.classroomService.createClassroom(classroom, this.customerId, this.googleCalendar);

    await firstValueFrom(operation)
      .then(() => this.dialogRef.close(true))
      .catch(err => {
        this.snackbar.open(err.error.Message, 'Dismiss', { duration: 4000 });
        console.error(err);

        this.lockEdit(false);
      });
  }

  btnDisabled() {
    return !this.name.valid
        || !this.description.valid;
  }

  lockEdit(lock: boolean) {
    if(lock) {
      this.name.disable();
      this.description.disable();
    } else {
      this.name.enable();
      this.description.enable();
    }

    this.sendingClassroom = lock;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

export class RouteReusePath implements RouteReuseStrategy {

    handlers: { [key: string]: DetachedRouteHandle } = { };
    
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return (route.data.shouldReuse ?? false);
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        if ((route.data.shouldReuse ?? false))
            this.handlers[route.routeConfig.path] = handle;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        if (!route.routeConfig)
            return null;
        
        return this.handlers[route.routeConfig.path];
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return (future.data.shouldReuse ?? false);
    }
    
}

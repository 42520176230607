import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { SubscriptionSessionDTO } from 'src/app/models/dto/subscriptionSessionDTO';

@Component({
  selector: 'app-purchased-table',
  templateUrl: './purchased-table.component.html',
  styleUrl: './purchased-table.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class PurchasedTableComponent {

  private _purchased: SubscriptionSessionDTO[] = [];

  @Input()
  get purchased(): SubscriptionSessionDTO[] { return this._purchased; }
  set purchased(value: SubscriptionSessionDTO[]) {
    if (!value)
      return;

    this.types = value
      .map(o => o.paymentType)
      .filter((value, index, self) => self.indexOf(value) === index);

    this.sources = value
      .map(o => o.paymentSource)
      .filter((value, index, self) => self.indexOf(value) === index);

    this._purchased = value;
    this.dataSource.data = value;
  }

  @Input()
  showGoTo: boolean = false;

  dataSource: MatTableDataSource<SubscriptionSessionDTO> = new MatTableDataSource();  
  displayedColumns: string[] = ['picture', 'created', 'cartItemName', 'amount', 'currency', 'paymentType', 'paymentSource', 'action'];
  selectedSubSession: SubscriptionSessionDTO = null;

  subDisplayedColumns: string[] = ['picture', 'cartItemName', 'cartItemDescription'];

  types: string[] = [];
  sources: string[] = [];

  selectedType: string = "all";
  selectedSource: string = "all";

  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.dataSource.sort = matSort;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'amount':
          return `${item.currency} ${item.amount}`;
        default:
          return item[property];
      }
    };

    this.dataSource.filterPredicate = (data, filter) => {
      return data.cartItemName.toLowerCase().includes(filter)
          || String(data.amount).includes(filter)
          || data.currency.includes(filter)
          || data.paymentType.toLowerCase().includes(filter)
          || data.paymentSource.toLowerCase().includes(filter);
    };
  }

  @ViewChild(MatPaginator) set matPaginator(matPaginator: MatPaginator) {
    this.dataSource.paginator = matPaginator;
  }

  constructor(
    private subscriptionService: SubscriptionService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  toggleRow(subSession: SubscriptionSessionDTO) {
    this.selectedSubSession = subSession.subscriptionSessions.length === 0
                            ? null
                            : this.selectedSubSession === subSession
                            ? null
                            : subSession;
  }

  collapseRow(subSession: SubscriptionSessionDTO) {
    if (subSession !== this.selectedSubSession)
      this.selectedSubSession = null;
  }

  applyFilter (event: Event) {
    let value = (event.target as HTMLInputElement).value;

    this.dataSource.filter = value.trim().toLowerCase();
  }

  getPurchases() {
    let purchases = this.purchased;

    if (this.selectedType !== "all")
      purchases = purchases.filter(o => o.paymentType === this.selectedType);

    if (this.selectedSource !== "all")
      purchases = purchases.filter(o => o.paymentSource === this.selectedSource);

    this.dataSource.data = purchases;
  }

  goToEntity(subId: number) {
    this.subscriptionService.getSubscriptionEntityPage(subId)
      .subscribe({
        next: res => this.router.navigate([res.page], { queryParams: { page: 'sidenav' } }),
        error: err => {
          console.error(err);
          this.snackBar.open('entity not found', '', { duration: 5000 });
        }
      });
  }

}

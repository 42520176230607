import { Component, HostListener, OnInit } from '@angular/core';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { WebinarState } from 'src/app/models/webinarState';
import { AuthService } from 'src/app/services/auth.service';
import { ConferenceService } from 'src/app/services/conference.service';
import { Location } from '@angular/common';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { User } from 'src/app/models/user';
import { Helper } from 'src/app/helpers/helper';
import { UntypedFormControl } from '@angular/forms';
import { CARDS_MARGIN, COMPACT_CARD_WIDTH, STANDARD_CARD_WIDTH } from '../../showcase/showcase.component';
import { differenceInMinutes } from 'date-fns';

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 40; //px

@Component({
  selector: 'app-webinar-list',
  templateUrl: './webinar-list.component.html',
  styleUrls: ['./webinar-list.component.scss']
})
export class WebinarListComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.rowSize = Math.floor((window.innerWidth - (PAGE_PADDING * 2)) / this.cardSize);
  }

  currentUser: User;
  conferences: ConferenceDTO[] = [];
  backupConferences: ConferenceDTO[] = [];
  
  search: string;
  hashTag: UntypedFormControl = new UntypedFormControl(0);
  hashTags: string[] = [];
  searchForm: UntypedFormControl = new UntypedFormControl();

  compactMode: boolean = false;
  cardSize: number = 0;
  rowSize: number = 0;

  constructor(private conferenceService: ConferenceService,
              private location: Location,
              private auth: AuthService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.compactMode = this.currentUser.frontEndType === 1;
    this.cardSize = CARDS_GAP + CARDS_MARGIN + (this.compactMode ? COMPACT_CARD_WIDTH : STANDARD_CARD_WIDTH);
    this.onResize(undefined);

    if (this.isAuthenticated()) {
      this.getWebinars();
      this.fetchHashtag();
    }
  }

  getWebinars() {
    this.toggleHashtag(true);
    this.conferenceService.getConferencesShowcase()
      .subscribe(output => {
        if (output.length > 0) {
          this.conferences = output;
          this.backupConferences = this.conferences.slice();
        }
    });

    this.searchForm.setValue('');
  }

  getModerator(conference: ConferenceDTO) {
    return conference.conferencePresenter.find(r => r.role === ConferencePresenterRole.Moderator)?.presenter;
  }

  getPresenters(conference: ConferenceDTO) {
    return conference.conferencePresenter.filter(r => r.role === ConferencePresenterRole.Presenter).map(p => p.presenter);
  }

  border(conference: ConferenceDTO) {
    if (conference?.lessonSession?.stopDate)
      return null;

    let border = 'outline: solid thick ';

    let participant = conference?.lessonSession?.classroom?.classroomContent.find(p => p.userId === this.currentUser.id);
    let presenter = conference?.conferencePresenter?.find(p => p.idPresenter === this.currentUser.id);

    if ((participant || presenter) && conference?.lessonSession?.startDate && !conference?.lessonSession?.stopDate)
      return border += 'green';

    let diff = differenceInMinutes(new Date(conference?.lessonSession?.startPlanned), new Date());

    if (diff < 0)
      return null;

    if (diff <= 120)
      return border += 'red';
    
    if (diff <= 1440)
      return border += 'orange';
    
    return null;
  }

  checkIfPaidWebinarIsOnline(conference) {
    let result = conference.lessonSession.classroom.classroomContent.filter(p => p.userId === this.currentUser.id);
    if(result.length > 0 && conference.lessonSession.startDate != null && conference.lessonSession.stopDate === null)
      return true
    else
      return false
  }

  getWebinarState() {
    return WebinarState;
  }
  
  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  goBack() {
    this.location.back();
  }

  fetchHashtag() {
    this.conferenceService.getHashtags()
      .subscribe(output => {
        this.hashTags = output;
    });
   }

  onHashTagsChange() {
    this.conferences = this.backupConferences;
    this.conferences = this.hashTag.value !== 0
                 ? this.conferences.filter(c => c.hashTag != null && c.hashTag.includes(this.hashTag.value))
                 : this.backupConferences.slice();
  }

  toggleHashtag(toggle: boolean) {
    if (toggle) {
      this.hashTag.enable();
      this.hashTag.setValue(0);
    } else {
      this.hashTag.disable();
      this.hashTag.setValue(0);
    }
  }

  searchWord() {
    this.conferences = this.backupConferences.filter(
      a =>(a.lessonSession.teacher.name.toLowerCase() + ' ' +  a.lessonSession.teacher.surname.toLowerCase()).includes(this.searchForm.value.toLowerCase()) ||
           a.conferencePresenter.find(a => (a.presenter.name.toLowerCase() + ' ' + a.presenter.surname.toLowerCase()).includes(this.searchForm.value.toLowerCase()))|| 
           a.name.toLowerCase().includes(this.searchForm.value.toLowerCase()) || a?.description?.toLowerCase().includes(this.searchForm.value.toLowerCase()) || 
           a?.footer?.toLowerCase().includes(this.searchForm.value.toLowerCase()) || a?.header?.toLowerCase().includes(this.searchForm.value.toLowerCase()) || 
           a?.showcaseSummary?.toLowerCase().includes(this.searchForm.value.toLowerCase()))
          
    if (Helper.isNullOrEmpty(this.searchForm.value))
      this.conferences = this.backupConferences.slice();
  }

  gridTemplateColumns() {
    let total = this.rowSize;

    if (this.conferences.length < total)
      total = this.conferences.length;

    let columns = '';

    for (let i=0; i<total; i++)
      columns += 'auto ';

    return columns;
  }

}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Helper } from "src/app/helpers/helper";

@Injectable({
    providedIn: 'root'
})
export class GoogleService {
    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlGoogle = `${this.bckEndUrlBaseUrl}/google`;
    
    constructor(private http: HttpClient) { }

    public grantAccess(authorizationCode: string, email: string) {
        return this.http.post(`${this.bckEndUrlGoogle}/access`, { authorizationCode, email }, { headers: Helper.getAuthHeader() });
    }

    public revokeAccess() {
        return this.http.delete(`${this.bckEndUrlGoogle}/access`, { headers: Helper.getAuthHeader() });
    }

    public addEmail(email: string) {
        var params = new HttpParams()
            .set('email', email);

        return this.http.post(`${this.bckEndUrlGoogle}/email`, null, { headers: Helper.getAuthHeader(), params: params });
    }

    public removeEmail() {
        return this.http.delete(`${this.bckEndUrlGoogle}/email`, { headers: Helper.getAuthHeader() });
    }

    /*
    public getCalendars(): Observable<GoogleCalendarList> {
        return this.http.get<GoogleCalendarList>(`${this.bckEndUrlGoogle}/calendars`, { headers: Helper.getAuthHeader() });
    }

    public addCalendar(title: string, description: string) {
        return firstValueFrom(this.http.post<any>(`${this.bckEndUrlGoogle}/calendar`, { title, description }, { headers: Helper.getAuthHeader() }));
    }
    */
}

<h1 mat-dialog-title>{{ 'Please open the camera' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-form-field style="width: 100%" color="accent">
                <mat-label>{{ 'Video Device' | translate }}</mat-label>
                <mat-select [formControl]="videoForm" required>
                    <mat-option *ngFor="let dev of videoDeviceArr" [value]="dev.deviceId">
                        {{ dev.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-form-field style="width: 100%" color="accent">
                <mat-label>{{ 'Audio Device' | translate }}</mat-label>
                <mat-select [formControl]="audioForm" required>
                    <mat-option *ngFor="let dev of audioDeviceArr" [value]="dev.deviceId">
                        {{ dev.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close type="button" (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" [disabled]="okBtnDisabled()" (click)="confirm()">Ok</button>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { Helper } from 'src/app/helpers/helper';
import { MasterContentDTO } from 'src/app/models/dto/masterContentDTO';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { MasterContentEdit } from 'src/app/models/masterContentEdit';
import { MasterContentType } from 'src/app/models/masterContentType';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { AzureStorageService } from 'src/app/services/storage.service';

const VIDEO_ACCEPT = 'video/*,.mkv';
const PDF_ACCEPT = '.pdf';
const AUDIO_ACCEPT = 'audio/*';

@Component({
  selector: 'app-master-add-preview',
  templateUrl: './master-add-preview.component.html',
  styleUrls: ['./master-add-preview.component.scss']
})
export class MasterAddPreviewComponent implements OnInit {

  currentUser: User;
  currentType: number = 0;
  sending: boolean = false;

  masterId: number;
  masterContentToEdit: MasterContentDTO;

  mediaSrc: FormControl<File> = new FormControl<File>(undefined, [Validators.required]);
  thumbnailSrc: FormControl<File> = new FormControl<File>(undefined);
  name: FormControl<string> = new FormControl<string>(undefined, [Validators.required, Validators.minLength(4)]);
  description: FormControl<string> = new FormControl<string>(undefined);
  download: FormControl<boolean> = new FormControl<boolean>(false);

  nameTranslation: TranslationDTO;
  descriptionTranslation: TranslationDTO;

  constructor(public dialogRef: MatDialogRef<MasterAddPreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private snackBar: MatSnackBar,
              private auth: AuthService,
              private azureService: AzureStorageService,
              private pdfViewerService: NgxExtendedPdfViewerService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.masterId = this.data.masterId;

    if (this.data.masterContent != null) {
      this.masterContentToEdit = this.data.masterContent;
      this.currentType = this.masterContentToEdit.type;

      this.download.setValue(this.masterContentToEdit.downloadable == 1 ? true : false);

      if (this.masterContentToEdit.nameTranslation)
        this.name.setValue(this.masterContentToEdit.nameTranslation[this.currentUser.defaultLanguage]);

      if (this.masterContentToEdit.descriptionTranslation)
        this.description.setValue(this.masterContentToEdit.descriptionTranslation[this.currentUser.defaultLanguage]);

      this.nameTranslation = this.masterContentToEdit.nameTranslation;
      this.descriptionTranslation = this.masterContentToEdit.descriptionTranslation;
    }
  }

  async saveContent() {
    this.toggleDisable(true);

    let content = new MasterContentEdit();

    content.name = this.name.value;
    content.description = this.description.value;
    content.type = this.masterContentToEdit ? this.masterContentToEdit.type : 0;
    content.downloadable = this.download.value ? 1 : 0;
    content.orderIndex = this.masterContentToEdit?.orderIndex ?? 0;
    content.nameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    content.descriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);

    if (!this.masterContentToEdit) {

      content.type = Helper.getFileExtensionCode(this.mediaSrc.value, 'mastercontent');
      content.duration = await this.getFileDuration(this.mediaSrc.value, content.type);

      this.azureService.postMasterContent(
        this.masterId,
        content,
        this.mediaSrc.value,
        await this.getThumbnail(content.type));

      this.onNoClick();

      this.snackBar.open('Adding content...', 'Dismiss', { duration: 3000 });

    } else {

      if (this.mediaSrc.value) {

        content.type = Helper.getFileExtensionCode(this.mediaSrc.value, 'mastercontent');
        content.duration = await this.getFileDuration(this.mediaSrc.value, content.type);

        this.azureService.putMasterContent(
          this.masterId,
          this.masterContentToEdit.id,
          content,
          this.mediaSrc.value,
          await this.getThumbnail(content.type));

        this.onNoClick();

      } else {

        await this.azureService.putMasterContent(
          this.masterId,
          this.masterContentToEdit.id,
          content,
          undefined,
          await this.getThumbnail(content.type));

        this.dialogRef.close(true);

      }

      if (this.mediaSrc.value) {
        content.type = Helper.getFileExtensionCode(this.mediaSrc.value, 'mastercontent');
        content.duration = await this.getFileDuration(this.mediaSrc.value, content.type);
      }

      this.snackBar.open('Editing content...', 'Dismiss', { duration: 3000 });

    }

    this.toggleDisable(false);
  }

  async getThumbnail(type: number): Promise<any> {
    if (this.thumbnailSrc.value)
      return this.thumbnailSrc.value;
    
    if (type === 2 && this.mediaSrc.value) {
      let base64 = await this.pdfViewerService.getPageAsImage(1, { width: 800 });
      return await fetch(base64).then(async r => r.blob());
    }

    return null;
  }

  async getFileDuration(file: File, type: number): Promise<number> {
    if (!file)
      return 0;

    try {

      if (type === MasterContentType.video)
        return await Helper.getVideoDuration(file);
  
      if (type === MasterContentType.pdf)
        return this.pdfViewerService.numberOfPages();

    } catch (e) {
      console.error(e);

      return 0;
    }
  }

  getBtnDisabled() {
    let check = !this.name.valid ||
                this.sending;

    if (!this.masterContentToEdit)
      check = check ||
              !this.mediaSrc.valid;

    return check;
  }

  getAcceptedFiles(): string {
    if (this.masterContentToEdit) {

      if (this.masterContentToEdit.type === MasterContentType.video)
        return VIDEO_ACCEPT;
      
      if (this.masterContentToEdit.type === MasterContentType.pdf)
        return PDF_ACCEPT;

      if (this.masterContentToEdit.type === MasterContentType.audio)
        return AUDIO_ACCEPT;
        
    }

    return `${VIDEO_ACCEPT},${PDF_ACCEPT},${AUDIO_ACCEPT}`;
  }

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if(toggle) {
      this.name.disable();
      this.description.disable();
      this.mediaSrc.disable();
      this.thumbnailSrc.disable();
      this.download.disable();
    } else {
      this.name.enable();
      this.description.enable();
      this.mediaSrc.enable();
      this.thumbnailSrc.enable();
      this.download.enable();
    }
  }

  mediaAccept() {
    this.currentType = Helper.getFileExtensionCode(this.mediaSrc.value, 'mastercontent');
  }

  mediaDelete() {
    this.currentType = 0;
    this.thumbnailSrc.reset();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }
}

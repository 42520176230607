import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from 'src/app/helpers/helper';
import { CustomerUpdateStripe } from 'src/app/models/customerUpdateStripe';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-customer-stripe-popup',
  templateUrl: './customer-stripe-popup.component.html',
  styleUrl: './customer-stripe-popup.component.scss'
})
export class CustomerStripePopupComponent implements OnInit {

  customerId: number = undefined;
  customerName: string = undefined;

  keyForm: FormControl<string> = new FormControl(undefined);
  keySecretForm: FormControl<string> = new FormControl(undefined);
  webhookForm: FormControl<boolean> = new FormControl({ value: false, disabled: true });

  constructor(
    private dialogRef: MatDialogRef<CustomerStripePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private calendar: CalendarService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.customerId = this.data.customerId;
    this.customerName = this.data.customerName;
  }

  saveConfig() {
    if (this.webhookForm.disabled)
      this.webhookForm.setValue(false);

    let config: CustomerUpdateStripe = {
      apiKey: this.keyForm.value,
      apiKeySecret: this.keySecretForm.value,
      createWebhookSecret: this.webhookForm.value
    };

    this.calendar
      .putCustomerStripe(config, this.customerId)
      .subscribe({
        next: () => {
          this.snackBar.open('Stripe configuration saved', '', { duration: 5000 });
          this.dialogRef.close(true);
        },
        error: err => {
          console.error(err);
          this.snackBar.open(err.error, '', { duration: 10000 });
        }
      });
  }

  disableWebhook() {
    if (!this.isKeyEmpty() && !this.isKeySecretEmpty()) {

      if (this.webhookForm.disabled)
        this.webhookForm.enable();

      return;

    }

    if (!this.webhookForm.disabled)
      this.webhookForm.disable();
      
  }

  btnDisabled() {
    return !this.keyForm.valid
        || !this.keySecretForm.valid
        || this.customerId == undefined;
  }

  isKeyEmpty() {
    return Helper.isNullOrEmpty(this.keyForm.value);
  }

  isKeySecretEmpty() {
    return Helper.isNullOrEmpty(this.keySecretForm.value);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

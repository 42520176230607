<mat-drawer-container hasBackdrop="false" id="session-container">

    <mat-drawer #directionalChat
        mode="over"
        position="end"
        style="width: 400px"
        (openedChange)="closeChats($event)">

        <app-directional-chat
            *ngIf="isModerator()"
            [hidden]="!mpresentersChatOpened"
            [session]="session"
            [globalUsersChat]="true"
            [globalUsersChatEnabledRoles]="['moderator', 'presenter']"
            [forwardedMessage]="forwardedMessage"
            [hideActive]="true"
            [read]="mpresentersChatOpened"
            (unread)="newMPresentersMessages = $event"
            (onChatsChange)="presentersChats = $event"
            (onClose)="directionalChat.close()"
            enabledRole="presenter"
            title="Speakers">
        </app-directional-chat>

        <app-directional-chat
            *ngIf="isModerator()"
            [hidden]="!mparticipantsChatOpened"
            [session]="session"
            [aggregateChats]="true"
            [forwardActive]="true"
            [forwardChats]="presentersChats"
            [replyActive]="true"
            [hideActive]="true"
            [read]="mparticipantsChatOpened"
            (unread)="newMParticipantsMessages = $event"
            (onForwardedMessage)="forwardedMessage = $event"
            (onClose)="directionalChat.close()"
            enabledRole="participant"
            title="Participants">
        </app-directional-chat>

        <app-directional-chat
            *ngIf="!isModerator()"
            [hidden]="!ppChatOpened"
            [session]="session"
            [globalUsersChat]="isPresenter()"
            [globalUsersChatEnabledRoles]="['moderator', 'presenter']"
            [read]="ppChatOpened"
            (unread)="newPPMesagges = $event"
            (onClose)="directionalChat.close()"
            enabledRole="moderator"
            title="Moderator">
        </app-directional-chat>

    </mat-drawer>

    <div *ngIf="initialSelection; else streamLayout" fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
        <app-source-selection
            [OV]="OV"
            [videoDevices]="getDevices('video', 'all')"
            [audioDevices]="getDevices('audio', 'all')"
            [videoDeviceEnabled]="isPresenter() || isModerator()"
            [audioDeviceEnabled]="isPresenter() || isModerator()"
            [username]="getUsername()"
            [entityName]="'webinar'"
            [lockForm]="lockJoin"
            [acceptedNetwork]="isPresenter() || isModerator() ? validStreamingQualities : undefined"
            (result)="$event == null ? leave() : joinConference($event)">
        </app-source-selection>
    </div>

    <app-conference-participants
        *ngIf="participantsListOpened"
        [conference]="conference"
        [connectedUserIds]="getConnectedParticipantsIds()"
        (onClose)="participantsListOpened = !participantsListOpened"
        dragBoundary="#session-container"
        class="participants-list">
    </app-conference-participants>

</mat-drawer-container>

<ng-template #streamLayout>

    <mat-card *ngIf="isRecording || publishingInfo || isSharing()" class="recording-container">

        <button mat-flat-button *ngIf="isRecording" disableRipple="true" color="warn">
            <mat-icon>fiber_manual_record</mat-icon>
            {{ 'Recording' | translate }}...
        </button>

        <button mat-flat-button *ngIf="publishingInfo" disableRipple="true" color="accent">
            <mat-icon>record_voice_over</mat-icon>
            {{ publishingInfo }}
        </button>

        <button mat-flat-button *ngIf="isSharing()" (click)="stopShare()" color="warn">
            <mat-icon>stop</mat-icon>
            {{ 'Stop share' | translate }}
        </button>

    </mat-card>

    <div *ngIf="conferenceRemainingMinutes() <= 10" class="messageBanner" style="top: 40px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'The conference will be closed in x minutes' | translate: { value: conferenceRemainingMinutes() },
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="highlightPresenters.length === 0 && presenters.length === 0 && participants.length > 0" class="messageBanner" style="top: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: publisherName ? (('Conference paused by' | translate) + ' ' + publisherName) : ('Conference paused' | translate),
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="lostConnection() || streamingQualityValues.length > 0" class="messageBanner" style="bottom: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'No connection. Go to an area with a better connection' | translate,
                                            icon: 'warning'
                                         }">
        </ng-container>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="layout-container">

        <app-session-participants
            [hidden]="presenters.length === 0"
            [session]="session"
            [participants]="presenters"
            [optionMenuBtn]="participantsOptionsBtn"
            [read]="true"
            [lowerAllHandsActive]="false"
            [lowerHandEnabled]="isModerator()"
            [overlay]="false"
            [visualType]="visualType"
            (onLowerHand)="toggleHand($event, false)"
            (unread)="newHandNotifications = $event"
            (onCurrentUserHandRaised)="isHandRaised = $event"
            (onLowerAllHands)="toggleHand(null, false)"
            (onPriorityPosition)="priorityPosition = $event"
            mode="webinar"
            style="height: 100%">
        </app-session-participants>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex style="height: 100%">

            <div *ngIf="publisherShare" class="presenter-stream">
                <ng-container *ngTemplateOutlet="shareLayout"></ng-container>
            </div>

            <div *ngFor="let highlight of highlightPresenters" class="presenter-stream" [style.width]="100 / highlightPresenters.length">
                <app-user-video
                    [streamManager]="highlight.manager"
                    [name]="highlight.name"
                    [color]="highlight.color"
                    [displayBorder]="highlight.userId === currentUser.id"
                    [handRaised]="highlight.handRaised"
                    [lowerHandEnabled]="isModerator()"
                    [visualType]="visualType"
                    (onLowerHand)="toggleHand(highlight.userId, false)">

                    <ng-container *ngTemplateOutlet="
                        participantsOptionsBtn;
                        context: {
                            participant: highlight,
                            compactMode: false,
                            disableSetAs: false,
                            disableKickUser: false
                        }" optionBtn>
                    </ng-container>

                </app-user-video>
            </div>

        </div>

    </div>

    <app-session-toolbar
        [localParticipant]="localParticipant?.manager"
        [subjectVideoDevices]="getDevices('video', 'subject')"
        [subjectAudioDevices]="getDevices('audio', 'subject')"
        [shareVideoDevices]="getDevices('video', 'share')"
        [subjectVideoDeviceId]="localParticipantVideoDeviceId"
        [subjectAudioDeviceId]="localParticipantAudioDeviceId"
        [shareVideoDeviceId]="localParticipantShareVideoDeviceId"
        [isSubjectMirrored]="localParticipantMirror"
        [isVideoOverride]="isVideoOverride()"
        [hasScreenShareCapabilities]="hasScreenShareCapabilities"
        [shareActive]="isPublishing() || isSharing() || isHighlight()"
        [shareEnabled]="isPublishing() || isSharing() || (isHighlight() && !publisherShare)"
        [isSharing]="isSharing()"
        [(fullScreen)]="isFullScreen"
        [handRaiseActive]="false"
        [(handRaise)]="isHandRaised"
        [recordingActive]="isPublisher() && conference?.lessonSession?.recodingPlanned"
        [isRecording]="isRecording"
        [recordingEnabled]="!togglingRecording"
        [httpLink]="httpLink"
        [closeActive]="isModerator()"
        (onChangeSource)="changeSource($event.deviceId, $event.mirror, $event.type)"
        (handRaiseChange)="toggleHand(currentUser.id, $event)"
        (onRecordingChange)="toggleRecording($event)"
        (onStartShare)="startShare($event)"
        (onStopShare)="stopShare()"
        (onVisualTypeChange)="visualType = $event"
        (onLeave)="leave(true)"
        (onClose)="close()"
        class="bottom-container mat-elevation-z24"> <!--[toggleAudioActive]="isPresenter() || isPublisher() || isPublishing()"-->

        <div fxFlex leftBtns>

            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button
                    [matTooltip]="('Conference' | translate) + ' ' + conference?.name + ' ' + ('has started x minutes ago' | translate: { value: conferenceElapsedMinutes() })">
                    <mat-icon>info</mat-icon>
                </button>

                <button mat-icon-button (click)="openParticipantList()" [matTooltip]="'Participants' | translate">
                    <mat-icon
                        [matBadge]="totalClients"
                        [matBadgeHidden]="totalClients === 0">
                        people
                    </mat-icon>
                </button>

            </div>

        </div>

        <div fxLayoutGap="10px" fxFlex centerBtns>
           
            <button mat-mini-fab
                *ngIf="isModerator()"
                (click)="toggleChats('participants')"
                [color]="mparticipantsChatOpened ? 'accent' : 'primary'"
                [matTooltip]="'Participants' | translate"
                [matBadge]="newMParticipantsMessages"
                [matBadgeHidden]="newMParticipantsMessages === 0"
                matBadgeColor="accent">
                <mat-icon>
                    chat
                </mat-icon>
            </button>

            <button mat-mini-fab
                *ngIf="isModerator()"
                (click)="toggleChats('presenters')"
                [color]="mpresentersChatOpened ? 'accent' : 'primary'"
                [matTooltip]="'Speakers' | translate"
                [matBadge]="newMPresentersMessages"
                [matBadgeHidden]="newMPresentersMessages === 0"
                matBadgeColor="accent">
                <mat-icon>
                    chat
                </mat-icon>
            </button>

            <button mat-mini-fab
                *ngIf="!isModerator()"
                (click)="toggleChats('moderators')"
                [color]="ppChatOpened ? 'accent' : 'primary'"
                [matTooltip]="'Moderator' | translate"
                [matBadge]="newPPMesagges"
                [matBadgeHidden]="newPPMesagges === 0"
                matBadgeColor="accent">
                <mat-icon>
                    chat
                </mat-icon>
            </button>

            <!--button mat-mini-fab
                (click)="globalChat.toggle()"
                [color]="globalChatOpened ? 'accent' : 'primary'"
                [matTooltip]="'Chat' | translate"
                [matBadge]="newChatMessages"
                [matBadgeHidden]="newChatMessages === 0"
                matBadgeColor="accent">
                <mat-icon>
                    chat
                </mat-icon>
            </button-->

        </div>

    </app-session-toolbar>

</ng-template>

<ng-template #shareLayout>
    <div class="presenter-stream">
        <app-user-video
            [streamManager]="publisherShare.manager"
            [name]="publisherShare.name"
            [color]="publisherShare.color"
            [displayBorder]="publisherShare.userId === currentUser.id"
            [visualType]="visualType"
            [displayName]="true"
            [displayAudio]="publisherShare.type === 'screen'"
            [showScreenShare]="currentUser.id === publisherShare.userId && publisherShare.type === 'screen'">

            <ng-container *ngTemplateOutlet="
                participantsOptionsBtn;
                context: {
                    participant: publisherShare,
                    compactMode: false,
                    disableSetAs: true,
                    disableKickUser: true
                }" optionBtn>
            </ng-container>

        </app-user-video>
    </div>
</ng-template>

<ng-template #participantsOptionsBtn
    let-participant="participant"
    let-compactMode="compactMode"
    let-disableSetAs="disableSetAs"
    let-disableKickUser="disableKickUser">

    <div fxLayout="row" [fxLayoutGap]="compactMode ? '0px' : '5px'">

        <ng-template #compactKickUserBtn [ngIf]="isModerator() &&
                                                currentUser.id !== participant?.userId &&
                                                !disableKickUser">
            <button mat-icon-button
                *ngIf="compactMode; else standardKickUserBtn"
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardKickUserBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #compactSetAsPublisherBtn [ngIf]="isModerator() &&
                                                       !isModerator(participant?.userId) &&
                                                       !disableSetAs &&
                                                       !publisherShare">
            <button mat-icon-button
                *ngIf="compactMode; else standardSetAsPublisherBtn"
                (click)="isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? setUserAsPresenter(participant?.userId, false, 'multiple') : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? 'Withdraw the word' : 'Give the word') | translate"
                color="accent">
                <mat-icon>{{ isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardSetAsPublisherBtn>
            <button mat-mini-fab
                (click)="isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? setUserAsPresenter(participant?.userId, false, 'multiple') : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? 'Withdraw the word' : 'Give the word') | translate"
                color="primary">
                <mat-icon>{{ isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

    </div>

</ng-template>

<ng-template #messageBanner let-message="message" let-icon="icon">
    <mat-card>
        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
                <mat-icon *ngIf="icon" style="margin-right: 10px; vertical-align: middle;">{{ icon }}</mat-icon>
                {{ message }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content></mat-card-content>
    </mat-card>
</ng-template>

<h1 mat-dialog-title *ngIf="dialogTitle">{{ dialogTitle | translate }}</h1>
<div mat-dialog-content fxLayout="column">

    <mat-form-field color="primary">
        <mat-label>{{'Survey' | translate}}</mat-label>
        <mat-select [(value)]="selectedSurvey">
          <mat-option [value]="0">{{ 'New Survey' | translate }}</mat-option>
          <mat-option *ngFor="let survey of surveys" [value]="survey.id">
            {{survey.name}}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex *ngIf="selectedSurvey === 0" color="primary">
        <mat-label>{{ 'Title' | translate }}</mat-label>
        <input type="text" matInput [formControl]="titleForm">
        <mat-error *ngIf="titleForm.hasError('required')">
            {{ 'The title is' | translate}} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" *ngIf="!isCourseSurvey()">
        <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'Start' | translate }}</mat-label>
            <input matInput [type]="showDateOrTime ? 'datetime-local' : 'time'" [formControl]="startDateForm">
            <mat-error *ngIf="startDateForm.hasError('required')">
                {{ 'The date is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'End' | translate }}</mat-label>
            <input type="time" matInput [formControl]="endDateForm" (input)="negativeSpan()">
            <mat-error *ngIf="endDateForm.hasError('required')">
                {{ 'The date is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="endDateForm.hasError('negativeSpan')">
                {{ 'The difference in dates must' | translate }} <strong>{{ 'not be negative' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex *ngIf="eventData?.classrooms">
        <mat-form-field color="primary">
            <mat-label>{{ 'Virtual Classroom' | translate }}</mat-label>
            <mat-select matInput [formControl]="classroomForm">
                <mat-option *ngFor="let item of eventData.classrooms" [value]="item?.id">
                    {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    
    <mat-form-field fxFlex *ngIf="selectedSurvey === 0" color="primary" >
        <mat-label>{{ 'Description' | translate }}</mat-label>
        <input type="text" matInput [formControl]="descriptionForm">
        <mat-error *ngIf="descriptionForm.hasError('required')">
            {{ 'The description is' | translate}} <strong>{{ 'required' | translate }}</strong>
        </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isCourseSurvey()">
        <mat-form-field *ngIf="eventData?.mode === 1" color="primary" fxFlex>
            <mat-label>{{ 'Sequence' | translate }}</mat-label>
            <input matInput type="number" [formControl]="orderForm" [min]="1">
            <mat-error *ngIf="orderForm.hasError('required')">
                {{ 'It' | translate }} <strong>{{ 'is mandatory' | translate }}</strong> {{ 'to select a value' | translate }}
            </mat-error>
        </mat-form-field>
        <!--mat-form-field *ngIf="eventData?.mode === 0" color="primary" fxFlex>
            <mat-label>{{ 'Priority' | translate }}</mat-label>
            <mat-select matInput [formControl]="orderForm">
                <mat-option [value]="1">{{ 'Very High' | translate }}</mat-option>
                <mat-option [value]="2">{{ 'High' | translate }}</mat-option>
                <mat-option [value]="3">{{ 'Medium' | translate }}</mat-option>
                <mat-option [value]="4">{{ 'Low' | translate }}</mat-option>
                <mat-option [value]="5">{{ 'Very low' | translate }}</mat-option>
            </mat-select>
            <mat-error *ngIf="orderForm.hasError('required')">
                {{ 'It' | translate }} <strong>{{ 'is mandatory' | translate }}</strong> {{ 'to select a value' | translate }}
            </mat-error>
        </mat-form-field-->
    </div>

<!-- 
    <mat-form-field appearance="legacy" fxFlex>
        <mat-label>{{ 'Teacher' | translate }}</mat-label>
        <mat-select matInput [formControl]="teacherForm">
            <mat-option *ngFor="let item of eventData.teachers" [value]="item?.id">
                {{item?.surname + ' ' + item?.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="teacherForm.hasError('required')">
            {{ 'The author is' | translate }} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field> 

 -->

</div>

<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" 
    [disabled]="okBtnDisabled()"
    (click)="getResult()">Ok</button>
</div>

<h1 mat-dialog-title>{{ 'Share' | translate }}</h1>
<mat-dialog-content fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid">
    <div *ngFor="let button of buttons">
        <button mat-raised-button (click)="share(button.provider)" color="primary">
            <fa-icon [icon]="button.icon" size="lg"></fa-icon>
        </button>
    </div>
    <div>
        <button mat-raised-button (click)="share('ml')" color="primary">
            <mat-icon>mail</mat-icon>
        </button>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Close' | translate }}</button>
</mat-dialog-actions>

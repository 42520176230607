import { UserDTO } from "./userDTO"

export class SocialWallBlogDTO {
    id: number;
    idsocialWall: number;
    idauthor: number;
    idparent: number;
    title: string;
    postContent: string;
    mediaUrl1: string;
    mediaUrl2: string;
    mediaUrl3: string;
    mediaUrl4: string;
    mediaUrl5: string;
    state: number;
    hashTags: string;
    created: Date;

    idauthorNavigation: UserDTO = new UserDTO();
}

import { Connection, ConnectionEvent, OpenVidu, Session, SignalEvent, StreamEvent } from "openvidu-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { VideoSessionService } from "src/app/services/video-session.service";
import { Helper } from "src/app/helpers/helper";
import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { ClientStream } from "../models/conference-session/clientStream";
import { ClientData } from "../models/conference-session/clientData";
import { StandardLessonConfig } from "../models/joinLessonConfig";
import { JoinSession } from "../models/joinSession";
import { BidirectionalPublisherPopupComponent } from "../popup/bidirectional-publisher-popup/bidirectional-publisher-popup.component";

const TIMEOUT_SELECT_CAMERA_OPERATION: number = 20000; //ms
const TIMEOUT_WAIT_SELECT_CAMERA_OPERATION: number = 25000; //ms
const SNACKBAR_DURATION: number = 5000; //ms
const SNACKBAR_POSITION: 'top' | 'bottom' = 'top'; //ms

const APPROVE_PARAM: string = 'approve';
const DISCARD_PARAM: string = 'discard';

@Injectable({
    providedIn: 'root'
})
export class BidirectionService {

    private currentUser: User = this.auth.getCurrentUser();

    private _lessonId: number = undefined;

    private _OV: OpenVidu = undefined;
    private _session: Session = undefined;
    private _remoteConnections: { connection: Connection, clientData: ClientData }[] = [];

    private _stateTimeout: any = undefined;

    public get stateTimeout(): any { return this._stateTimeout; }
    public set stateTimeout(value: any) {
        if (this._stateTimeout != undefined) {
            clearTimeout(this._stateTimeout);
            this._stateTimeout = undefined;
        }

        this._stateTimeout = value;
    }

    private _localClient: ClientStream = undefined;
    public get localClient() { return this._localClient; }

    private _remoteClient: ClientStream = undefined;
    public get remoteClient() { return this._remoteClient; }

    private _mode: 'publisher' | 'subscriber' = undefined;
    public get mode() { return this._mode; }

    private _status: 'stopped' | 'ready' | 'waitingAuth' | 'connected';
    public get status() { return this._status; }

    constructor(
        private auth: AuthService,
        private videoSessionService: VideoSessionService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) { }

    async init(lessonId: number, mode: 'publisher' | 'subscriber') {
        await this.dispose();

        this._lessonId = lessonId;
        this._mode = mode;

        this._OV = new OpenVidu();
        this._session = this._OV.initSession();

        if (this.mode === 'publisher') { // Student

            this._session.on(DirectionalStreamSignal.askStartStream, async (event: SignalEvent) => {
                
                if (this.status !== 'ready')
                    return;

                let clientData: ClientData = JSON.parse(event.from.data);

                this._status = 'waitingAuth';
            
                let dialogRef = this.dialog.open(BidirectionalPublisherPopupComponent, { width: '500px' });

                let timeout = setTimeout(() => {

                    dialogRef.close();
                    timeout = undefined;

                }, TIMEOUT_SELECT_CAMERA_OPERATION);

                let res: StandardLessonConfig = await firstValueFrom(dialogRef.afterClosed());

                if (timeout)
                    clearTimeout(timeout);

                if (!res) {

                    await this.sendData(DirectionalStreamSignal.ackStartStream, DISCARD_PARAM, this._remoteConnections.find(c => c.connection.connectionId === event.from.connectionId)?.connection);
                    await this.dispose(`Request from ${clientData.name} denied`, false);

                    return;
                }

                await this.sendData(DirectionalStreamSignal.ackStartStream, APPROVE_PARAM, this._remoteConnections.find(c => c.connection.connectionId === event.from.connectionId)?.connection);
                this._remoteClient = new ClientStream(undefined, clientData);

                let localPublisher = this._OV.initPublisher(
                    undefined,
                    {
                        videoSource: res.scenario[0],
                        audioSource: res.audioDevice,
                        publishVideo: res.videoEnabled,
                        publishAudio: res.audioEnabled,
                        resolution: '640x480',
                        frameRate: 25,
                        mirror: false
                    }
                );

                this._localClient.manager = localPublisher;

                await this._session.publish(localPublisher);

                this._status = 'connected';

                await this.joinStream(this._session.connection.connectionId, this._session.connection.stream.streamId);

            });

            this._session.on(DirectionalStreamSignal.askStopStream, async (event: SignalEvent) => {

                //if (this.status !== 'connected')
                //    return;

                let clientData: ClientData = JSON.parse(event.from.data);

                if (clientData.userId !== this.remoteClient?.userId)
                    return;

                await this.dispose(`${this.remoteClient.name} asked to close the camera`, false);

            });   

        }

        if (this.mode === 'subscriber') { // Teacher

            this._session.on(DirectionalStreamSignal.ackStartStream, async (event: SignalEvent) => {

                if (this.status !== 'waitingAuth')
                    return;

                let clientData: ClientData = JSON.parse(event.from.data);

                if (clientData.userId !== this.remoteClient?.userId)
                    return;

                this.stateTimeout = undefined;
    
                if (event.data === APPROVE_PARAM) {

                    this.snackBar.open(`${clientData.name} accepted to open the camera`, '', { duration: SNACKBAR_DURATION, verticalPosition: SNACKBAR_POSITION });

                    this._status = 'connected';

                    await this.joinStream(this._session.connection.connectionId);

                } else {

                    await this.dispose(`${clientData.name} denied to open the camera`, false);

                }
    
            });

        }

        this._session.on('connectionCreated', (event: ConnectionEvent) => {

            if (event.connection.connectionId === this._session.connection.connectionId)
                return;

            if (this._remoteConnections.some(rc => rc.connection.connectionId === event.connection.connectionId))
                return;

            let clientData: ClientData = JSON.parse(event.connection.data);

            if (clientData.mode !== 'bidirectional')
                return;

            this._remoteConnections.push({ connection: event.connection, clientData: clientData });

        });

        this._session.on('connectionDestroyed', async (event: ConnectionEvent) => {

            if (event.connection.connectionId === this._session.connection.connectionId)
                return;

            let index = this._remoteConnections.findIndex(c => c.connection.connectionId === event.connection.connectionId);

            if (index === -1)
                return;

            this._remoteConnections.splice(index, 1);

            let clientData: ClientData = JSON.parse(event.connection.data);

            if (clientData.userId === this.remoteClient?.userId)
                await this.dispose(`${clientData.name} disconnected`, false);

        });

        this._session.on('streamCreated', (event: StreamEvent) => {

            let clientData: ClientData = JSON.parse(event.stream.connection.data);

            if (clientData.mode !== 'bidirectional')
                return;

            if (clientData.userId !== this.remoteClient?.userId)
                return;

            this._remoteClient.manager = this._session.subscribe(event.stream, undefined);

        });

        this._session.on('streamDestroyed', async (event: StreamEvent) => {

            if (!this.remoteClient)
                return;

            let clientData: ClientData = JSON.parse(event.stream.connection.data);

            if (clientData.mode !== 'bidirectional')
                return;

            if (clientData.userId !== this.remoteClient?.userId)
                return;

            await this.dispose(`${clientData.name} closed the camera`, false);

        });

        let token = (await this.getToken())?.token;

        let clientData = new ClientData(
            this.currentUser.id,
            `${this.currentUser.name} ${this.currentUser.surname}`,
            this.currentUser.profilePictureUrl,
            this.mode === 'publisher' ? 'publisher' : 'participant',
            'bidirectional',
            'camera'
        );

        this._localClient = new ClientStream(undefined, clientData);

        await this._session.connect(token, clientData);

        this._status = 'ready';
    }

    async dispose(message?: string, disconnect: boolean = true) {
        if (!Helper.isNullOrEmpty(message))
            this.snackBar.open(message, '', { duration: SNACKBAR_DURATION, verticalPosition: SNACKBAR_POSITION });

        this.stateTimeout = undefined;

        if (this.status === 'connected')
            await this.leaveStream(this._session.connection.connectionId);

        this._status = 'ready';
        this._remoteClient = undefined;

        if (disconnect) {
            this._status = 'stopped';

            this._lessonId = undefined;
            this._mode = undefined;
            this._localClient = undefined;
            this._remoteConnections = [];

            this._session?.disconnect();

            this._session = undefined;
            this._OV = undefined;
        }
    }

    async askToStart(userId: number) {

        let remote = this._remoteConnections.find(c => c.clientData.userId === userId);

        if (!remote)
            return;

        this._status = 'waitingAuth';
        this._remoteClient = new ClientStream(undefined, remote.clientData);

        this.stateTimeout = setTimeout(async () => await this.dispose(`Response from ${remote.clientData.name} not received`, false), TIMEOUT_WAIT_SELECT_CAMERA_OPERATION);

        await this.sendData(DirectionalStreamSignal.askStartStream, '', remote.connection);

        this.snackBar.open(`Request sent to ${remote.clientData.name}`, '', { duration: SNACKBAR_DURATION, verticalPosition: SNACKBAR_POSITION });

    }

    async askToStop(userId: number) {

        let remote = this._remoteConnections.find(c => c.clientData.userId === userId);

        if (!remote)
            return;

        await this.sendData(DirectionalStreamSignal.askStopStream, '', remote.connection);

        this.snackBar.open(`Request sent to ${remote.clientData.name}`, '', { duration: SNACKBAR_DURATION, verticalPosition: SNACKBAR_POSITION });

    }

    private async sendData(type: DirectionalStreamSignal, data: any, connection: Connection) {

        if (this.status === 'stopped')
            return;

        try {

            await this._session.signal({
                data: data,
                to: [connection],
                type: type
            });

        } catch (e) {
            console.error(e);
        }

    }

    private async getToken() {
        try {
    
          return await firstValueFrom(this.videoSessionService.generateToken(this._lessonId, this.mode === 'publisher'));
    
        } catch (e) {
    
          console.error(e);
    
        }
    }

    private async joinStream(connectionId: string, streamId?: string) {
        try {
          await this.videoSessionService.joinLesson(
            this._lessonId,
            <JoinSession>{
              role: streamId != undefined ? 'PUBLISHER' : 'SUBSCRIBER',
              connectionId: connectionId,
              streamId: streamId,
              position: 4,
              bidirectional: 1,
              prevStreamId: undefined
            });
        } catch (e) {
          console.error(e);
        }
      }
    
    private async leaveStream(connectionId?: string) {
        try {
    
          await this.videoSessionService.leaveLesson(this._lessonId, connectionId);
    
        } catch (e) {
          console.error(e);
        }
    }
}

enum DirectionalStreamSignal {
    askStartStream = 'signal:ask-start-video',
    ackStartStream = 'signal:ack-start-video',
    askStopStream = 'signal:ask-stop-video',
    ackStopStream = 'signal:ack-stop-video'
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';
import { SubscriptionSessionList } from 'src/app/models/dto/subscriptionSessionDTO';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchasedService {
  bckEndUrlPurchased = `${environment.apiUrl}/api/v2/purchased`;

  constructor(private http: HttpClient) { }

  getAll(userId?: number): Observable<SubscriptionSessionList> {
    let params: HttpParams = new HttpParams()
      .set("pageLimit", '10000')
      .set("pageIndex", '1');

    if (userId != undefined)
      params = params.append('userId', userId);

    return this.http.get<SubscriptionSessionList>(`${this.bckEndUrlPurchased}`, { headers: Helper.getAuthHeader(), params: params }).pipe(tap(console.log));
  }

  getSinglePurchased(userId: number, subscriptionId: number) {
    return this.http.get<boolean>(`${this.bckEndUrlPurchased}/getSinglePurchased/${userId}/${subscriptionId}`,
      { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }
}

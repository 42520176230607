import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MasterContentDTO } from 'src/app/models/dto/masterContentDTO';
import { MasterContentType } from 'src/app/models/masterContentType';
import { DarkThemeService } from 'src/app/services/dark-theme.service';

const CONTENT_WIDTH: number = 1100; //px
const CONTENT_HEIGHT: number = 620; //px
const CARD_PADDING: number = 24; //px

@Component({
  selector: 'app-master-content-view',
  templateUrl: './master-content-view.component.html',
  styleUrl: './master-content-view.component.scss'
})
export class MasterContentViewComponent implements OnInit {

  masterContent: MasterContentDTO = undefined;

  isVideo: boolean = false;
  isAudio: boolean = false;
  isPdf: boolean = false;

  download: string = "";

  constructor(
    public dialogRef: MatDialogRef<MasterContentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private darkService: DarkThemeService
  ) {
    this.dialogRef.updateSize(`${CONTENT_WIDTH + (CARD_PADDING * 2)}px`, '760px');
  }

  ngOnInit(): void {
    this.masterContent = this.data.masterContent;

    this.isVideo = this.masterContent.type === MasterContentType.video;
    this.isAudio = this.masterContent.type === MasterContentType.audio;
    this.isPdf = this.masterContent.type === MasterContentType.pdf;

    if (!this.masterContent.downloadable)
      this.download = "nodownload";
  }

  width() {
    return `${CONTENT_WIDTH}px`;
  }

  height(pixelToSubtract?: number) {
    let height = CONTENT_HEIGHT;

    if (pixelToSubtract)
      height -= pixelToSubtract;

    return `${height}px`;
  }

  isDark() {
    return this.darkService.isSetDark;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

import { ConferenceDTO } from "./conferenceDTO";
import { CourseDTO } from "./courseDTO";
import { CustomerDTO } from "./customerDTO";
import { PackageDTO } from "./packageDTO";
import { SubscriptionFeeDTO } from "./subscriptionFeeDTO";
import { SubscriptionUserDTO } from "./subscriptionUserDTO";

export class SubscriptionDTO {
    public id: number;
    public idOrigin?: number;
    public name: string;
    public description: string;
    public idCustomer: number;
    public type: number;
    public idFee: number;
    public isFree: boolean;
    public state: number;
    public isPublic: number;
    public idTargetCustomer: number;
    public timeValidity: number;
    public durationDays: number;
    public dateFrom?: Date;
    public dateTo?: Date;
    public created: Date;
    public subscriptionFee: SubscriptionFeeDTO;
    public subscriptionUser: SubscriptionUserDTO[];
    public customer : CustomerDTO;
    public course: CourseDTO;
    public conference: ConferenceDTO;
    public package: PackageDTO;
}

function Mapper() {
  var sources = {};
  this.forEach = function (callback) {
    for (var key in sources) {
      var source = sources[key];
      for (var key2 in source) callback(source[key2]);
    }
  };
  this.get = function (id, source) {
    var ids = sources[source];
    if (ids == undefined) return undefined;
    return ids[id];
  };
  this.remove = function (id, source) {
    var ids = sources[source];
    if (ids == undefined) return;
    delete ids[id];
    for (var i in ids) {
      return false;
    }
    delete sources[source];
  };
  this.set = function (value, id, source) {
    if (value == undefined) return this.remove(id, source);
    var ids = sources[source];
    if (ids == undefined) sources[source] = ids = {};
    ids[id] = value;
  };
}
Mapper.prototype.pop = function (id, source) {
  var value = this.get(id, source);
  if (value == undefined) return undefined;
  this.remove(id, source);
  return value;
};
module.exports = Mapper;

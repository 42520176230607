import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CourseAddComponent } from './course-add/course-add.component';
import { CoursePopupComponent } from './course-popup/course-popup.component';
import { ContentAddComponent } from './content-add/content-add.component';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { User } from 'src/app/models/user';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { AddSurveyPopUpComponent } from 'src/app/survey/add-survey-pop-up/add-survey-pop-up.component';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { ResDTO } from 'src/app/models/dto/resDTO';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/helpers/helper';
import { AddSubscriptionComponent, CrudSubscription, SubscriptionType } from 'src/app/popup/add-subscription/add-subscription.component';
import { ContentHelper } from 'src/app/helpers/contentHelper';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SplitButtonOption } from 'src/app/components/split-button/split-button.component';

export const CARD_WIDTH: number = 325; //px

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 40; //px

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.rowSize = Math.floor((window.innerWidth - (PAGE_PADDING * 2)) / this.cardSize);
  }

  contents: CourseContentDTO[] = [];
  backupContents: CourseContentDTO[] = [];

  //Utilizzato solo per aggiunta di un nuovo content con corso in modalia module index
  lastOrderIndex: number = 0;

  private _course: CourseDTO;

  @Input()
  get course(): CourseDTO { return this._course; }
  set course(value: CourseDTO) {
    this._course = value;

    if (this._course != null) {
      this.contents = value.courseContent.map((content, i, contents) => {
        content.contentHelper = new ContentHelper(this.router,
                                                  this.dialog,
                                                  this.courseService,
                                                  this.auth.getCurrentUser(),
                                                  content,
                                                  contents,
                                                  this.course.idAuthor,
                                                  this.course.mode);
        return content;
      });
  
      if (this.contents.length > 0)
        this.lastOrderIndex = this.contents.slice().sort((a, b) => b.orderIndex - a.orderIndex)[0].orderIndex;

      this.backupContents = this.contents.slice();
      this.sortContent();
    }
  }

  id: number;
  textColor: string = 'white';
  currentUser: User;
  changingState: boolean = false;
  filter: number = 0;
  href: string = "";

  cardSize: number = 0;
  rowSize: number = 0;
  rowGridColumns: string = undefined;

  contentsMode: 'cards' | 'table' = 'cards';
  sending: boolean = false;

  addContentOptions: SplitButtonOption[] = [
    {
      icon: 'add',
      text: 'Pdf',
      value: 'pdf'
    },
    {
      icon: 'add',
      text: 'Audio',
      value: 'audio'
    },
    {
      icon: 'add',
      text: 'Scorm',
      value: 'scorm',
      disabled: true
    },
    {
      icon: 'add',
      text: 'File',
      value: 'generic'
    }
  ];

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private courseService: CourseService,
              private location: Location,
              private router: Router,
              private translate: TranslateService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,            
              public darkService: DarkThemeService) { }

  ngOnInit() {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();
    this.href = Helper.getUrl();

    this.cardSize = CARDS_GAP + CARD_WIDTH;
    this.onResize(undefined);
  }

  getContent() {
    this.course = null;
    this.contents = [];
    this.backupContents = [];

    this.courseService.getCourseContent(this.id, this.auth.isAuthenticated())
      .subscribe((output) => {
        this.course = output;
      });
  }

  updateCourse() {
    const dialogRef = this.dialog.open(CourseAddComponent, {
      data: { course: this.course }, width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getContent();
      }
    });
  }

  deleteCourse() {
    const dialogRef = this.dialog.open(CoursePopupComponent, {
      data: { name: this.course.name, type: 'deleteCourse' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.courseService.deleteCourse(this.id)
          .subscribe(output => {
            if(output.Message == "")
              this.snackBar.open('Course deleted', 'Dismiss', { duration: 3000 });
            else
              this.snackBar.open('Error deleting course', 'Dismiss', { duration: 3000 });

            this.router.navigate(['/courses']);
          });
      }
    });
  }

  /*
  deleteCoursePermanent() {
    const dialogRef = this.dialog.open(CoursePopupComponent, {
      data: {name: this.course.name, type: 'deleteCoursePermanent'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.courseService.deleteCoursePermanently(this.id)
          .subscribe(output => {
            if(output.Message == "")
              this.snackBar.open('Course deleted permanently', 'Dismiss', { duration: 3000 });
            else
              this.snackBar.open('Error deleting course', 'Dismiss', { duration: 3000 });
            
            this.router.navigate(['/courses/']);
          });
      }
    });
  }
  */

  toggleCourse() {
    const dialogRef = this.dialog.open(CoursePopupComponent, {
      data: { name: this.course.name, type: 'statusCourse' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.changingState = true;

        this.courseService.setCourseState(this.course.state ? 0 : 1, this.course.id)
          .subscribe({
            next: () => {
              this.snackBar.open('Course state updated', 'Dismiss', { duration: 3000 } );
              this.changingState = false;

              this.getContent();
            },
            error: err => {
              this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 } );
              this.changingState = false;

              this.getContent();
          }
        });
      } else {
        this.getContent();
      }
    });
  }

  addContent(type?: 'scorm' | 'folder') {
    const dialogRef = this.dialog.open(ContentAddComponent, {
      data: { idCourse: this.course.id, mode: this.course.mode, lastIndex: this.lastOrderIndex, type: type },
      width: '600px'
    });

    dialogRef.afterClosed()
      .subscribe(res => {

        if (!res)
          return;

        this.courseService.getContentById(res)
          .subscribe(res => {

            let ch = new ContentHelper(this.router,
                                       this.dialog,
                                       this.courseService,
                                       this.currentUser,
                                       res,
                                       this.contents,
                                       this.course.idAuthor,
                                       this.course.mode);

            ch.goTo();

          });

      });
  }

  goBack() {
    this.location.back();
  }

  analyticsCourse(id: number){
    this.router.navigate(['course-analytics', id]);
  }

  sortContent() { //0 -> Default (Priority), 1 -> Name
    this.contents = this.backupContents.slice().filter(c => !c.idParent);

    if (this.filter === 1)
      this.contents.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
  }

  addSubscription () {
    let data: CrudSubscription = new CrudSubscription();
    data.entityId = this.course.id;
    data.entityType = SubscriptionType.course;
    data.subscription = this.course.subscription;

    const dialogRef = this.dialog.open(AddSubscriptionComponent, {
      data: data, width: '600px'  
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getContent();
      }
    });
  }

  getCoursePrice() {
    if (this.course.idSubscription) {
      return this.course.subscription.isFree ? 'Free' : this.course.subscription.subscriptionFee.euro + '€';
    } else {
      return '- €';
    }
  }

  addSurvey(): void {
    let eventData: EventDialogData = {
      dialogTitle : 'Add new survey',
      classId: this.course.idInstanceClassroom,
      teacherId: this.course.idAuthor,
      type: 5,
      orderIndex: this.lastOrderIndex,
      mode: this.course.mode,
      isPlanned: true
    };

    const dialogRef = this.dialog.open(AddSurveyPopUpComponent, {
      width: '500px',
      data: { eventData, page: "course" }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let surveyLesson: LessonSessionDTO = new LessonSessionDTO();

        if (!result.survey) {
          surveyLesson.teacherId = result.teacherId;
          surveyLesson.startPlanned = new Date(); //Date dummy perche settate dal backend in questo caso
          surveyLesson.endPlanned = new Date(); //Date dummy perche settate dal backend in questo caso
          surveyLesson.name = result.title;
          surveyLesson.description = result.description;
          surveyLesson.classId = this.course.idInstanceClassroom;
          surveyLesson.survey = new SurveyDTO();
          surveyLesson.survey.surveyJSON = '';
        } else {
          surveyLesson.teacherId = result.teacherId;
          surveyLesson.startPlanned = new Date(); //Date dummy perche settate dal backend in questo caso
          surveyLesson.endPlanned = new Date(); //Date dummy perche settate dal backend in questo caso
          surveyLesson.name = result.survey.name;
          surveyLesson.description = result.survey.description;
          surveyLesson.classId = this.course.idInstanceClassroom;
          surveyLesson.survey = new SurveyDTO();
          surveyLesson.survey.surveyJSON = result.survey.surveyJSON;
        }
        
        this.courseService.postSurvey(this.course.id, result.orderIndex, surveyLesson)
            .subscribe({
              next: (res:ResDTO) => {
                this.snackBar.open('Survey added!', '', { duration: 3000 });

                if (res.Message)
                  this.router.navigate(['survey-creator', res.Message]);

              },
              error: err => {
                  this.snackBar.open(err.error.Message, '', { duration: 3000 });
              }
            });   
      }
    }); 
  }

  async copyCourse() {
    let dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Copy name', { name: this.course.name })),
          body: await firstValueFrom(this.translate.get('Are you sure to copy name?', { name: this.course.name }))
        }
      });

    dialogRef.afterClosed()
      .subscribe(async res => {
        if (!res)
          return;

        let loadingDialog = this.dialog.open(GenericPopupComponent,
          {
            width: '400px',
            disableClose: true,
            data: <GenericPopupData>{
              title: await firstValueFrom(this.translate.get('Loading')),
              showLoading: true,
              hideCancelBtn: true,
              hideOkBtn: true
            }
          });

        this.courseService.createCopyOfCourse(this.course.id)
          .subscribe({
            next: res => {
              loadingDialog.close();

              this.router.navigate(['/course', Number((res as any).Message)], { queryParams: { page: 'sidenav' } });
            },
            error: err => {
              console.error(err);

              loadingDialog.close();

              this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
            }
          });
      });
  }

  getHashTags(hashTags: string[]) {
    return hashTags?.join(' ');
  }

  gridTemplateColumns() {
    let total = this.rowSize;

    if (this.contents.length < total)
      total = this.contents.length;

    let columns = '';

    for (let i=0; i<total; i++)
      columns += 'auto ';

    return columns;
  }

}

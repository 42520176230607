<button mat-button>
    <div *ngIf="isAuthenticated(); else signUp"
         fxLayout="row"
         fxLayoutGap="10px"
         fxLayoutAlign="center center">
        <div fxLayout="column">
            <mat-icon *ngIf="currentUser?.firstPassword"
                style="vertical-align: middle" color="warn"
                [matTooltip]="'You are still using the default password, please consider changing it' | translate">
                error
            </mat-icon>
            <mat-icon *ngIf="showStorageWarning()"
                color="warn" [matTooltip]="('The customer' | translate) + ' ' +
                    azureService.customerStorage?.customer?.name + ' ' +
                    ('has at his disposal' | translate) + ' ' +
                    (remainingStorage() | number: '1.0-2') + 'GB ' +
                    ('of the total' | translate) + ' ' + azureService.customerStorage?.maxSize + 'GB'"
                style="vertical-align: middle">
                disc_full
            </mat-icon>
        </div>
        <div fxLayout="column">
            <span>{{ currentUser?.name }} {{ currentUser?.surname }}</span>
            <span style="margin-top: -15px; color: grey;">{{ currentUser?.customerName}}</span>
        </div>
        <app-profile-picture
            [url]="currentUser?.profilePictureUrl"
            size="45px"
            [border]="color()"
            [matBadge]="warningService.warnings.length"
            [matBadgeHidden]="warningService.warnings.length === 0"
            [matBadgeOverlap]="true"
            matBadgeSize="small"
            matBadgeColor="warn">
        </app-profile-picture>
    </div>
</button>

<ng-template #signUp>
    <div fxLayout="row"
         fxLayoutGap="10px"
         fxLayoutAlign="center center">
         <div fxLayout="column">
            <span>{{ 'Sign Up' | translate }}/{{ 'Log In' | translate }}</span>
            <span style="margin-top: -15px; color: grey;">{{ appName }}</span>
        </div>
        <app-profile-picture size="45px"></app-profile-picture>
    </div>
</ng-template>

<div fxLayout="column" class="component-main">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="30"></div>
        <h2 fxLayoutAlign="center center" fxFlex>
            Smily
        </h2>
        <h3 fxLayoutAlign="end center" fxFlex="30"
            style="font-weight: bold;"
            [style.color]="signalRStatus() === 'Connected' ? 'green' : signalRStatus() === 'Disconnected' ? 'red' : 'yellow'">
            {{ signalRStatus() }}
        </h3>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-fab color="primary" (click)="refresh()" [matTooltip]="'Refresh' | translate">
            <mat-icon>refresh</mat-icon>
        </button>
        <button mat-fab extended color="accent"
            *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin"
            (click)="addRoom()">
            <mat-icon>add</mat-icon>
            {{ "Room" | translate }}
        </button>
        <button mat-fab extended color="accent"
            *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin"
            (click)="addStation()"
            [disabled]="rooms == null || rooms.length === 0">
            <mat-icon>add</mat-icon>
            Smily
        </button>
        <mat-form-field appearance="fill" color="primary" *ngIf="isAdmin()" fxFlex="20">
            <mat-label>{{ 'Customer' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedCustomer" (selectionChange)="refresh()">
              <mat-option [value]="c.id" *ngFor="let c of customers">{{ c.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
<!--
    <div fxLayout="row" style="margin-top: 20px;">
        <mat-list fxFlex="15" style="padding-top: 0px">
            <mat-list-item class="titolo-lista" fxLayout="row" fxLayoutAlign="space-between center" >
                {{ 'Rooms' | translate }}
            </mat-list-item>
            <mat-list-item *ngFor="let item of rooms let i = index" fxLayout="row"
                [ngStyle]="{'background-color': item === roomSelected ? '#606160' : 'white' }"
                (click)="getPostazioni(item)">
                <div class="titolo" matLine>{{ item.name }}</div>  
                <div class="descrizione" matLine [ngStyle]="{'color':item === roomSelected ? 'white' : '#606160' }">
                    {{item.description}}
                </div>  
                <div matLine>pos:  <strong>{{ roomsStationsInfo[i].pos }}</strong> 
                                onLine: <strong>{{ roomsStationsInfo[i].onLine }}</strong> 
                                onError: <strong [style.color]="roomsStationsInfo[i].error>0 ? 'red': '' ">{{ roomsStationsInfo[i].error }}</strong> 
                </div>
            </mat-list-item>
        </mat-list>
    </div>
 -->
    <div style="margin-top: 20px;">
        <nav mat-tab-nav-bar mat-align-tabs="center">
            <a mat-tab-link *ngFor="let item of rooms; let i = index"
                (click)="getPostazioni(item)"
                [active]="roomSelected == item">
                
                <div fxLayout="column" fxFlex="100%">
                
                    <div class="titolo" style="padding-bottom: 7px;">{{item.name }}</div>
                    <div fxLayout="row"  fxLayoutAlign="center center" style="padding-bottom: 3px;">
                    <div class="sedia-icon"><mat-icon matTooltip="postazioni" [matBadge]="roomsStationsInfo[i].pos" matBadgeSize="small">event_seat</mat-icon> </div>
                    <div class="sedia-icon"><mat-icon matTooltip="on line" [matBadge]="roomsStationsInfo[i].onLine" matBadgeSize="small" style="color: green;" >event_seat</mat-icon></div>
                    <div class="sedia-icon"><mat-icon matTooltip="on error" [matBadge]="roomsStationsInfo[i].error" matBadgeSize="small" style="color: red;" >event_seat</mat-icon> </div>
                        
                    <!-- lens -->
                    <!-- error -->
                    </div>  
                </div>
            </a>
        </nav>
    </div>
    
    <div fxFlex="85" class="aula" fxLayout="column">
        <div *ngIf="roomSelected" fxLayout="row">
            <div fxFlex="10"></div>
            <div class="descrizione" fxLayoutAlign="center center" fxFlex>{{ roomSelected.description }}</div>
            <div fxFlex="10" fxLayoutAlign="end center">
                <button mat-icon-button *ngIf="currentUser.isCustomerAdmin || currentUser.isAdmin"
                    (click)="editRoom(roomSelected)"
                    color="primary">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>

        <div fxLayout="row" *ngFor="let riga of isole">
            <div fxLayout="row" *ngFor="let colonna of riga">
                <app-isola [isola]="colonna" (refresh)="refresh()"></app-isola>
            </div>
        </div>
    </div>
</div>

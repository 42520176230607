import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';

@Injectable({
    providedIn: 'root'
})
export class EmergencyService {
    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlEmergency = `${this.bckEndUrlBaseUrl}/emergency`;
    private bckEndUrlEmergencyClassroomAdmin = `${this.bckEndUrlEmergency}/classroom`;
    private bckEndUrlEmergencyTeacherAdmin = `${this.bckEndUrlEmergency}/teacher`;


    constructor(private http: HttpClient) { }

    /**
     * Ritona la lista delle lesson che protrebbero essere bloccate.
     * @param classroomId
     */
    public GetEmergencyList(classroomId: number): Observable<LessonSessionDTO> {
        return this.http.get<LessonSessionDTO[]>(`${this.bckEndUrlEmergencyClassroomAdmin}/${classroomId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    /**
     * Sblocca eventuali lezioni bloccate per un teacher.
     * Il metodo pu� esserre usato da un teacher (usando il proprio id o un numero a caso) per bloccare le proprie lezioni rimaste aperte.
     * Oppure da un CustumerAdmin per sloccare le lezioni di un proprio teacher.
     * 
     * Ricordati che questo metodo chiude tutte le lezioni aperte e killa tutti gli streaming
     * @param teacherId
     */
    public UnlockLessosOnTeacher(teacherId: number): Observable<any> {
        return this.http.delete(`${this.bckEndUrlEmergencyTeacherAdmin}/${teacherId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    /**
     * Sblocca eventuale lezione bloccata.
     * Il Methodo pu� essere usato solo da un CustomerAdmin e Admin e chiude un eventuale lezione rimasta aperta.
     * @param lessonId
     */
    public UnlockLessons(lessonId: number): Observable<any> {
        return this.http.delete(`${this.bckEndUrlEmergency}/${lessonId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }
}

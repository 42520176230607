<mat-drawer-container hasBackdrop="false" id="session-container">

    <mat-drawer #directionalChat
        mode="over"
        position="end"
        style="width: 400px"
        (openedChange)="closeChats($event)">

        <app-directional-chat
            *ngIf="isPublisher()"
            [hidden]="!participantsChatOpened"
            [session]="session"
            [read]="participantsChatOpened"
            (unread)="newParticipantsMessages = $event"
            (onChatsChange)="currentChats = ($event?.length ?? 0)"
            (onClose)="directionalChat.close()"
            enabledRole="participant"
            title="Participants">
        </app-directional-chat>

        <app-directional-chat
            *ngIf="!isPublisher()"
            [hidden]="!publisherChatOpened"
            [session]="session"
            [read]="publisherChatOpened"
            (unread)="newPublisherMesagges = $event"
            (onChatsChange)="currentChats = ($event?.length ?? 0)"
            (onClose)="directionalChat.close()"
            enabledRole="publisher"
            title="Teacher">
        </app-directional-chat>

    </mat-drawer>

    <ng-container [ngTemplateOutlet]="streamLayout"></ng-container>

    <div class="bidirectional-stream">
        <app-user-video
            *ngIf="bidirectionalClient()"
            [streamManager]="bidirectionalClient().manager"
            [name]="bidirectionalClient().name"
            [color]="bidirectionalClient().color"
            [visualType]="visualType">

        <button mat-mini-fab color="warn"
            (click)="stopBidirectional()"
            [matTooltip]="'Close Video' | translate"
            optionBtn>
            <mat-icon>
                videocam_off
            </mat-icon>
        </button>

        </app-user-video>
    </div>

</mat-drawer-container>

<ng-template #streamLayout>

    <mat-card *ngIf="isRecording || newHandNotifications" class="recording-container"> <!-- isSharing() -->

        <button mat-flat-button *ngIf="isRecording" disableRipple="true" color="warn">
            <mat-icon>fiber_manual_record</mat-icon>
            {{ 'Recording' | translate }}...
        </button>

        <button mat-flat-button
            *ngIf="newHandNotifications > 0"
            (click)="isPublisher() ? participantsListOpened = !participantsListOpened : false"
            disableRipple="true"
            color="accent">
            <mat-icon>back_hand</mat-icon>
            {{ 'x raised hands' | translate: { value: newHandNotifications } }}
        </button>

        <!--button mat-flat-button *ngIf="publishingInfo" disableRipple="true" color="accent">
            <mat-icon>record_voice_over</mat-icon>
            {{ publishingInfo }}
        </button-->

        <!--ng-template [ngIf]="isSharing()">
            <button mat-flat-button
                *ngFor="let share of publisherShare; let i = index"
                (click)="stopShare(undefined, index)"
                color="warn">
                <mat-icon>stop</mat-icon>
                {{ 'Stop share' | translate }} {{ i + 1 }}
            </button>
        </ng-template-->

    </mat-card>

    <div *ngIf="teachers.length === 0 && participants.length > 0" class="messageBanner" style="top: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: publisherName ? (('Lesson paused by' | translate) + ' ' + publisherName) : ('Lesson paused' | translate),
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="lostConnection() || streamingQualityValues.length > 0" class="messageBanner" style="bottom: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'No connection. Go to an area with a better connection' | translate,
                                            icon: 'warning'
                                         }">
        </ng-container>
    </div>

    <app-user-list-video
        *ngIf="lesson"
        [hidden]="!participantsListOpened"
        [classId]="lesson?.classId"
        [lessonId]="lesson?.id"
        [session]="session"
        [(participants)]="participants"
        [read]="!isPublisher()"
        [lowerAllHandsActive]="isPublisher()"
        [lowerHandEnabled]="isPublisher()"
        (onClose)="participantsListOpened = false"
        (onLowerHand)="toggleHand($event, false)"
        (unread)="newHandNotifications = $event"
        (onCurrentUserHandRaised)="isHandRaised = $event"
        (onLowerAllHands)="toggleHand(null, false)">
    </app-user-list-video>

    <div *ngIf="!currentContent; else contentsContainer" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="layout-container">

        <div *ngFor="let teacher of teachers" class="presenter-stream" [style.width]="100 / (teachers.length + shareStreams().length)">
            <app-user-video
                [streamManager]="teacher.manager"
                [name]="teacher.name"
                [color]="teacher.color"
                [displayBorder]="teacher.userId === currentUser.id"
                [visualType]="visualType"
                [handRaised]="teacher.handRaised"
                [showScreenShare]="currentUser.id === teacher.userId && teacher.manager?.stream?.typeOfVideo === 'SCREEN'"
                [lowerHandEnabled]="isPublisher()"
                (onLowerHand)="toggleHand(teacher.userId, false)">

                <ng-container *ngTemplateOutlet="
                    participantsOptionsBtn;
                    context: {
                        participant: teacher,
                        compactMode: false,
                        disableSetAs: false,
                        disableKickUser: false
                    }" optionBtn>
                </ng-container>

            </app-user-video>
        </div>

        <div *ngFor="let share of shareStreams()" class="presenter-stream" [style.width]="100 / (teachers.length + shareStreams().length)">
            <app-user-video *ngIf="share"
                [streamManager]="share.manager"
                [name]="share.name"
                [color]="share.color"
                [displayBorder]="share.userId === currentUser.id"
                [visualType]="visualType"
                [displayAudio]="share.manager?.stream?.typeOfVideo === 'SCREEN'"
                [showScreenShare]="currentUser.id === share.userId && share.manager?.stream?.typeOfVideo === 'SCREEN'">
    
                <ng-container *ngTemplateOutlet="
                    participantsOptionsBtn;
                    context: {
                        participant: share,
                        compactMode: false,
                        disableSetAs: true,
                        disableKickUser: true
                    }" optionBtn>
                </ng-container>
    
            </app-user-video>
        </div>

    </div>

    <app-session-toolbar
        [localParticipant]="localParticipant?.manager"
        [shareActive]="false"
        [(fullScreen)]="isFullScreen"
        [handRaiseActive]="!isPublisher()"
        [(handRaise)]="isHandRaised"
        [isRecording]="isRecording"
        (onChangeSource)="changeSource($event.deviceId, $event.mirror, $event.type)"
        (handRaiseChange)="toggleHand(currentUser.id, $event)"
        (onVisualTypeChange)="visualType = $event"
        (onToggleVideo)="toggleShareVideo($event, 0); toggleShareVideo($event, 1)"
        (onToggleAudio)="toggleShareAudio($event, 0); toggleShareAudio($event, 1)"
        (onLeave)="leave(true)"
        (onClose)="close()"
        class="bottom-container mat-elevation-z24">

        <div fxFlex leftBtns>

            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button
                    [matTooltip]="('Lesson' | translate) + ' ' + lesson?.name + ' ' + ('has started x minutes ago' | translate: { value: lessonElapsedMinutes() })">
                    <mat-icon>info</mat-icon>
                </button>

                <button mat-icon-button
                    (click)="isPublisher() ? participantsListOpened = !participantsListOpened : false"
                    [matTooltip]="'Participants' | translate"
                    matBadgeColor="accent">
                    <mat-icon
                        [matBadge]="totalClients"
                        [matBadgeHidden]="totalClients === 0">
                        people
                    </mat-icon>
                </button>

            </div>

        </div>

        <div fxLayoutGap="10px" fxFlex centerBtns>

            <button mat-mini-fab
                *ngIf="isPublisher()"
                [matMenuTriggerFor]="streamOptionMenu"
                color="accent">
                <mat-icon>
                    video_settings
                </mat-icon>
            </button>

            <button mat-mini-fab
                *ngIf="isPublisher() && contents.length > 0"
                [matMenuTriggerFor]="lessonContentMenu"
                [matTooltip]="'Choose content to display' | translate"
                [color]="currentContent ? 'accent' : 'primary'">
                <mat-icon>
                    add_to_queue
                </mat-icon>
            </button>

            <button mat-mini-fab
                *ngIf="isPublisher()"
                (click)="toggleChats('participants')"
                [disabled]="currentChats === 0"
                [color]="participantsChatOpened ? 'accent' : 'primary'"
                [matTooltip]="'Participants' | translate"
                [matBadge]="newParticipantsMessages"
                [matBadgeHidden]="newParticipantsMessages === 0"
                matBadgeColor="accent">
                <mat-icon>
                    chat
                </mat-icon>
            </button>

            <button mat-mini-fab
                *ngIf="!isPublisher()"
                (click)="toggleChats('publisher')"
                [disabled]="currentChats === 0"
                [color]="publisherChatOpened ? 'accent' : 'primary'"
                [matTooltip]="'Teacher' | translate"
                [matBadge]="newPublisherMesagges"
                [matBadgeHidden]="newPublisherMesagges === 0"
                matBadgeColor="accent">
                <mat-icon>
                    chat
                </mat-icon>
            </button>

        </div>

    </app-session-toolbar>

</ng-template>

<mat-menu #streamOptionMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent>

        <button mat-menu-item [matMenuTriggerFor]="firstDeviceMenu">
            <mat-icon>present_to_all</mat-icon>
            {{ 'Sharing' | translate }} 1
        </button>

        <button mat-menu-item
            *ngIf="isSharing(undefined, 0)"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('video', 'share'), type: 'video', i: 0, currentDevice: localParticipantShareVideoDeviceId[0] }">
            <mat-icon>present_to_all</mat-icon>
            {{ 'Sharing' | translate }} 2
        </button>

        <button mat-menu-item
            *ngIf="isSharing(undefined, 1)"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('video', 'share'), type: 'video', i: 1, currentDevice: localParticipantShareVideoDeviceId[1] }">
            <mat-icon>present_to_all</mat-icon>
            {{ 'Sharing' | translate }} 3
        </button>

    </ng-template>
</mat-menu>

<mat-menu #firstDeviceMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item
            *ngIf="localParticipantVideoDeviceId !== false"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('video', 'subject'), type: 'video', currentDevice: localParticipantVideoDeviceId }">
            <mat-icon>videocam</mat-icon>
            {{ 'Video Device' | translate }}
        </button>
        <button mat-menu-item
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('audio', 'subject'), type: 'audio', currentDevice: localParticipantAudioDeviceId }">
            <mat-icon>mic</mat-icon>
            {{ 'Audio Device' | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #devicesMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-devices="devices" let-type="type" let-i="i" let-currentDevice="currentDevice">
        <button mat-menu-item
            *ngFor="let device of devices"
            (click)="i == undefined ? changeSource(device.deviceId, localParticipantMirror, type) : startShare(device.deviceId, i)"
            [class.mat-menu-highlight]="currentDevice === device.deviceId">
            <mat-icon>
                {{
                   device.deviceId === 'screen' ?
                   'screen_share' :
                   type === 'video' ?
                   'videocam' :
                   type === 'audio' ?
                   'mic' :
                   'question_mark'
                }}
            </mat-icon>
            {{ device.label | translate }}
        </button>
        <!--button mat-menu-item
            *ngIf="type === 'video'"
            (click)="changeSource(false, 'video')"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === false">
            {{ 'No camera' | translate }}
        </button-->
    </ng-template>
</mat-menu>

<ng-template #participantsOptionsBtn
    let-participant="participant"
    let-compactMode="compactMode"
    let-disableSetAs="disableSetAs"
    let-disableKickUser="disableKickUser">

    <div fxLayout="row" [fxLayoutGap]="compactMode ? '0px' : '5px'">

        <!--ng-template #compactKickUserBtn [ngIf]="isPublisher() &&
                                                 currentUser.id !== participant?.userId &&
                                                 !disableKickUser">
            <button mat-icon-button
                *ngIf="compactMode; else standardKickUserBtn"
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardKickUserBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template-->

        <!--ng-template #compactSetAsPublisherBtn [ngIf]="isPublisher() &&
                                                       !isPublisher(participant?.userId) &&
                                                       !disableSetAs &&
                                                       !shareStreams()">
            <button mat-icon-button
                *ngIf="compactMode; else standardSetAsPublisherBtn"
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false, 'multiple') : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Withdraw the word' : 'Give the word') | translate"
                color="accent">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardSetAsPublisherBtn>
            <button mat-mini-fab
                (click)="isPublishing(participant?.userId) ? setUserAsPresenter(participant?.userId, false, 'multiple') : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) ? 'Withdraw the word' : 'Give the word') | translate"
                color="primary">
                <mat-icon>{{ isPublishing(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template-->

    </div>

</ng-template>

<ng-template #messageBanner let-message="message" let-icon="icon">
    <mat-card>
        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
                <mat-icon *ngIf="icon" style="margin-right: 10px; vertical-align: middle;">{{ icon }}</mat-icon>
                {{ message }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content></mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #lessonContentMenu="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item *ngFor="let content of contents" (click)="resetContent(); setContent(content.id, true);">
            <!--app-profile-picture [url]="content.headerImageUrl" size="40px"></app-profile-picture-->
            <mat-icon>
                {{
                    isVideo(content.type) ?
                    'smart_display' : 
                    isPdf(content.type) ?
                    'picture_as_pdf' :
                    'insert_drive_file'
                }}
            </mat-icon>
            <span class="trucate-text" style="max-width: 60px">{{ content.name }}</span>
        </button>
        <button mat-menu-item *ngIf="currentContent" (click)="setContent(currentContent.id, false)">
            <mat-icon>remove_from_queue</mat-icon>
            <span>{{ 'Remove the content displayed' | translate }}</span>
        </button>
    </ng-template>
</mat-menu>

<ng-template #contentsContainer>
    <app-player-events *ngIf="isVideo(currentContent.type) && isPublisher()"
        [media]="currentContent.mediaUrl"
        [enableControls]="true"
        (time)="currentContentTime = $event"
        (paused)="currentContentPause = true; updateContent($event);"
        (played)="currentContentPause = false; updateContent($event);"
        (seek)="updateContent(currentContentTime)"
        controls="nofullscreen nodownload noremoteplayback noplaybackrate"
        height="100%"
        class="content-container">
    </app-player-events>
    <app-player-events *ngIf="isVideo(currentContent.type) && !isPublisher()"
        [media]="currentContent.mediaUrl"
        [enableControls]="false"
        [pause]="currentContentPause"
        [disableSeekCheck]="true"
        [startTime]="currentContentMarker"
        (time)="currentContentTime = $event"
        height="100%"
        class="content-container">
    </app-player-events>
    <ngx-extended-pdf-viewer *ngIf="isPdf(currentContent.type)"
        [useBrowserLocale]="true"
        [src]="currentContent.mediaUrl"
        [textLayer]="true"
        [showBorders]="false"
        [showOpenFileButton]="false"
        [showBookmarkButton]="false"
        [showPresentationModeButton]="true"
        [filenameForDownload]="currentContent.name"
        [enablePrint]="false"
        [showDownloadButton]="false"
        [contextMenuAllowed]="false"
        (pageChange)="isPublisher() ? updateContent($event) : undefined"
        [(page)]="currentContentMarker"
        [theme]="isDark() ? 'dark' : 'light'"
        class="content-container">
    </ngx-extended-pdf-viewer>
</ng-template>

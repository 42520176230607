import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { SocialWallDTO } from 'src/app/models/dto/SocialWallDTO';
import { WallService } from '../service/wall.service';
import { Helper } from 'src/app/helpers/helper';
import linkifyHtml from 'linkify-html';
import { SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { SocialWallContentDTO } from 'src/app/models/dto/SocialWallContentDTO';
import { AzureStorageService } from 'src/app/services/storage.service';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';

@Component({
  selector: 'app-wall-popup',
  templateUrl: './wall-popup.component.html',
  styleUrls: ['./wall-popup.component.scss']
})
export class WallPopupComponent implements OnInit {

  title: FormControl<string> = new FormControl<string>('', [Validators.required, Validators.minLength(4)]);
  description: FormControl<string> = new FormControl<string>(undefined, [Validators.required]);
  header: FormControl<string> = new FormControl<string>(undefined);
  socialWallPostEdited: SocialWallDTO = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public wallService: WallService,
    private azureService: AzureStorageService,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<WallPopupComponent>,
    private dialog: MatDialog,
    private selectionService: SelectionService,
  ) { }

  ngOnInit(): void {
    this.socialWallPostEdited = this.data;

    this.title.setValue(this.stripHtmlTags(this.socialWallPostEdited.title));
    this.description.setValue(this.stripHtmlTags(this.getDescription(this.socialWallPostEdited.postText)));
    this.header.setValue(this.getHtml(this.socialWallPostEdited.postText));
  }  

  getDescription(text: string) {
    if (Helper.isNullOrEmpty(text))
      return text;

    let indexOfH3 = text.indexOf('</h3>');

    return Helper.clean(indexOfH3 !== -1 ? text.substring(indexOfH3 + 5) : text);
  }

  stripHtmlTags(html: string): string {
    let div = document.createElement('div');
    div.innerHTML = html;

    return div.textContent || div.innerText || '';
  }

  getHtml(text: string) {
    return text.substring(0, text.indexOf('</h3>')).replace(/(<([^>]+)>)/ig, '');
  }

  hasErrors() {
    return !this.title.valid || !this.description.valid;
  }

  saveEdit() {
    this.socialWallPostEdited.title = linkifyHtml(this.title.value, {
      defaultProtocol: 'https',
      target: '_blank'
    });
    
    let linkifyText = linkifyHtml(this.description.value, {
      defaultProtocol: 'https',
      target: '_blank'
    });

    this.socialWallPostEdited.postText = `<h3>${this.header.value}</h3>${linkifyText}`;

    this.wallService
      .editSocialWallPost(this.data.id, this.socialWallPostEdited)
      .subscribe({
        next: async () => {
          this.dialogRef.close();
          this.snackBar.open(await firstValueFrom(this.translate.get('Post edited')), undefined, { duration: 3000 });
        },
        error: err => {
          console.log(err);
          this.snackBar.open('Error editing post', undefined, { duration: 3000 });
        }
      });
  }

  /*
  addCourseLink() {
    this.selectionService.startSelection(this.socialWallPostEdited.id, SelectionMode.socialWallContents);
  }
  */

  async addFile(event: any, type: number) {
    let socialWallContent = new SocialWallContentDTO();
    socialWallContent.idsocialWall = this.socialWallPostEdited.id;
    socialWallContent.contentLink = await this.azureService.uploadFile(event.target.files[0]);
    socialWallContent.type = type;

    this.wallService
      .postSocialWallContent(socialWallContent)
      .subscribe({
        next: async output => {
          this.socialWallPostEdited.socialWallContents.push(output);
          this.snackBar.open(await firstValueFrom(this.translate.get('Content added')), undefined, { duration: 3000 });
        },
        error: async err => {
          console.error(err);
          this.snackBar.open(await firstValueFrom(this.translate.get('Error adding content')), undefined, { duration: 3000 });
        }
      });
  }

  deleteContent(content: SocialWallContentDTO) {
    let dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: 'Delete content',
          body: 'Are you sure you want to delete this content?'
        }
      });
  
      dialogRef.afterClosed().subscribe(async res => {
        if (!res)
          return;

        this.wallService.deleteSocialWallContent(content.id)
          .subscribe({
            next: async () => {
              let i = this.socialWallPostEdited.socialWallContents.findIndex(c => c.id === content.id);

              if (i !== -1)
                this.socialWallPostEdited.socialWallContents.splice(i, 1);

              this.snackBar.open(await firstValueFrom(this.translate.get('Content deleted')), undefined, { duration: 3000 });
            },
            error: async err => {
              console.error(err);
              this.snackBar.open(await firstValueFrom(this.translate.get('Error deleting content')), undefined, { duration: 3000 });
            }
          });
      });
  }

  getFileName(content: SocialWallContentDTO) {
    if (content.type === 1 || content.type === 2) {

      if (Helper.isNullOrEmpty(content.contentLink))
        return undefined;
  
      return content.contentLink
        .split('?')
        .shift()
        .split('/')
        .pop();

    }

    if (content.type === 4 || content.type === 5) {

      if (Helper.isNullOrEmpty(content.contentLink))
        return undefined;

      return content.contentLink
        .split('@@')
        .pop()
        .split('/')
        .join(' ');

    }

    return content.description;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

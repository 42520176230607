import { Injectable } from "@angular/core";
import { addDays } from "date-fns";
import { ClassroomDTO } from "src/app/models/dto/classroomDTO";
import { CourseContentDTO } from "src/app/models/dto/courseContentDTO";
import { CourseDTO } from "src/app/models/dto/courseDTO";
import { LessonSessionDTO } from "src/app/models/dto/lessonSessionDTO";
import { MasterDTO, MasterType } from "src/app/models/dto/masterDTO";
import { PackageDTO } from "src/app/models/dto/packageDTO";
import { RoomDTO } from "src/app/models/dto/roomDTO";
import { User } from "src/app/models/user";
import { ConferenceDTO } from "../models/dto/conferenceDTO";
import { CalendarService } from "./calendar.service";
import { ConferenceService } from "./conference.service";
import { CourseService } from "./course.service";
import { MasterService } from "./master.service";
import { PackageService } from "./package.service";
import { ClassroomService } from "./classroom.service";

@Injectable({
    providedIn: 'root'
})
export class SideNavService {

    private currentUser: User;
    
    public lessons: LessonSessionDTO[] = [];
    public courses: CourseDTO[] = [];
    public masters: MasterDTO[] = [];
    public oneToOneMasters: MasterDTO[] = [];
    public favourites: CourseContentDTO[] = [];
    public classrooms: ClassroomDTO[] = [];
    public rooms: RoomDTO[] = [];
    public packages: PackageDTO[] = [];
    public webinars: ConferenceDTO[] = [];
    
    constructor(private calendar: CalendarService,
                private classroomService: ClassroomService,
                private courseService: CourseService,
                private masterService: MasterService,
                private conferenceService: ConferenceService,
                private packageService: PackageService) { }

    /**
     * Impostare al login
     */
    public setCurrentUser(user: User): void { 
        this.currentUser = user;

        //this.getCourses();
        //this.getMasters();
        //this.getFavourites();
        //this.getRooms();
    }

    /**
     * Impostare al logout
     */
    public unsetCurrentUser(): void {
        this.currentUser = null;
    }

    /**
     * Funzioni da richiamare all'apertura della sidenav
     */
    public navbarOpened(): void {
        this.getClassrooms();
        this.getScheduledLessons();
        this.getMasters();
        this.getCourses();
        this.getFavourites();
        this.getRooms();
        //this.getWebinars();

        if (this.currentUser.isAuthor) {
            this.getPackages();
        }
    }

    private getPackages(): void {
        this.packageService.getAuthorPackages()
            .subscribe({
                next: output => {
                    this.packages = output;
                },
                error: err => {
                    console.log(err);
                    this.packages = [];
                }
            });
    }

    private getMasters(): void {
        this.masterService.getUserMaster()
            .subscribe({
                next: output => {
                    this.masters = output.filter(m => m.type === MasterType.standard);
                    this.oneToOneMasters = output.filter(m => m.type === (this.currentUser.isStudent ? MasterType.oneToOne : MasterType.template));
                },
                error: err => {
                    console.log(err);
                    this.masters = [];
                    this.oneToOneMasters = [];
                }
            });
    }

    private getCourses(): void {
        this.courseService.getCoursesOfUser()
            .subscribe({
                next: output => {
                    this.courses = output;
                },
                error: err => {
                    console.log(err);
                    this.courses = [];
                }
            });
    }

    private getFavourites(): void {
        this.courseService.getFavouriteContents()
            .subscribe({
                next: output => {
                    this.favourites = output;
                },
                error: err => {
                    console.log(err);
                    this.favourites = [];
                }
            });
    }

    private getRooms(): void {
        this.calendar.getRoomsForAll()
            .subscribe({
                next: output => {
                    this.rooms = output;
                },
                error: err => {
                    console.error(err);
                    this.rooms = [];
                }
            });
    }

    private getClassrooms(): void {
        this.classroomService.getClassrooms()
            .subscribe({
                next: output => { 
                    this.classrooms = output as ClassroomDTO[];
                },
                error: err => {
                    console.error(err);
                    this.classrooms = [];
                }
            });
    }

    private getWebinars(): void {
        this.conferenceService.getConferences([3], true)
            .subscribe({
                next: output => {
                    this.webinars = output;
                },
                error: err => {
                    console.log(err);
                    this.webinars = [];
                }
            });
    }

    private getScheduledLessons(): void {
        let today: Date = new Date();
        today.setHours(0, 0, 0, 0);
    
        let upperLimit: Date = new Date(today);
        upperLimit = addDays(upperLimit, 3);

        if (this.currentUser.isTeacher) //this.currentUser.isTutor || this.currentUser.isTeacher
            this.calendar.getLessons(
                today,
                upperLimit,
                undefined,
                undefined,
                undefined,
                2,
                this.currentUser.isCustomerAdmin || this.currentUser.isTeacher || this.currentUser.isTutor ? this.currentUser.idCustomer : undefined
            ).subscribe({
                next: lessons => this.lessons = lessons,
                error: err => {
                    console.log(err);
                    this.lessons = [];
                }
            });
    }

    /*
    groupMasters(masters: MasterDTO[]): Promise<SidenavMaster[]> {
        return new Promise((resolve) => {
            var res: SidenavMaster[] = [];
        
            var grouping = from(masters).pipe(
                groupBy(m => m.idOrigin),
                mergeMap(group => zip(of(group.key), group.pipe(toArray())))
            );
        
            grouping.subscribe(val => res.push(<SidenavMaster>{ idOrigin: val[0], masters: val[1] }));
            lastValueFrom(grouping)
                .then(() => resolve(res))
                .catch(() => resolve([<SidenavMaster>{ masters: masters }]));
        });
    }
    */
}

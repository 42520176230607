import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { addHours, addMinutes, isBefore } from 'date-fns';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { DatePipe } from '@angular/common';
import { AzureStorageService } from 'src/app/services/storage.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/helpers/helper';
import { ClassroomService } from 'src/app/services/classroom.service';
import { UserDTO } from 'src/app/models/dto/userDTO';

@Component({
    selector: 'app-app-lesson-pop-up',
    templateUrl: './app-lesson-pop-up.component.html',
    styleUrls: ['./app-lesson-pop-up.component.scss']
})
export class AppLessonPopUpComponent implements OnInit {

    currentUser: User;
    sending: boolean = false;

    eventData: EventDialogData;
    lessonOpenInClass: LessonSessionDTO = null;
    publishers: UserDTO[] = [];

    isPlanned: boolean = false;

    //Mostra l'ora da cambiare oppure il calendario (input type 'time' oppure 'date')
    static showDateOrTime: boolean = true; //default 'time' (false)
    static startDateBackup: Date = undefined;
    dateForm: FormGroup = new FormGroup(
        {
            startDateForm: new FormControl(null, [Validators.required]),
            endDateForm: new FormControl(null, [Validators.required])
        },
        this.dateDifferenceValidator
    );

    nameForm: FormControl<string> = new FormControl('', [Validators.required]);
    teacherForm: FormControl<number> = new FormControl(undefined, [Validators.required]);
    roomForm: FormControl<number> = new FormControl({ value: 0, disabled: false });

    descriptionForm: FormControl<string> = new FormControl('');
    recordForm: FormControl<boolean> = new FormControl(false);
    privateForm: FormControl<boolean> = new FormControl(true);
    smilyRecForm: FormControl<boolean> = new FormControl(false);

    classroomForm: FormControl<number> = new FormControl(undefined);

    constructor(private auth: AuthService,
                private dialogRef: MatDialogRef<AppLessonPopUpComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private calendar: CalendarService,
                private classroomService: ClassroomService,
                private datePipe: DatePipe,
                private azureService: AzureStorageService) { }

    ngOnInit() { //Da collegare record alla chiamata di salvataggio della lezione
        this.currentUser = this.auth.getCurrentUser();
        this.teacherForm.setValue(this.currentUser.id);

        AppLessonPopUpComponent.showDateOrTime = this.data?.dateOrTime ?? true;

        if (!this.currentUser.smilyEnabled)
            this.smilyRecForm.disable();

        this.eventData = this.data.eventData;
        this.isPlanned = this.eventData.isPlanned;

        let startDate = this.eventData.startData ? new Date(this.eventData.startData) : addMinutes(new Date(), 5);
        let endDate = this.eventData.endData ? new Date(this.eventData.endData) : addHours(startDate, 2);

        if (endDate < startDate)
            endDate = addHours(startDate, 2);

        AppLessonPopUpComponent.startDateBackup = startDate;

        this.dateForm.controls.startDateForm.setValue(this.datePipe.transform(startDate, AppLessonPopUpComponent.showDateOrTime ? 'yyyy-MM-ddTHH:mm' : 'HH:mm'));
        this.dateForm.controls.endDateForm.setValue(this.datePipe.transform(endDate, 'HH:mm'));

        if (this.eventData.classId != null)
            this.classroomForm.setValue(this.eventData.classId, { emitEvent: true });

        if (this.eventData.title != null)
            this.nameForm.setValue(this.eventData.title);

        if (this.eventData.description != null)
            this.descriptionForm.setValue(this.eventData.description);

        if (this.eventData.recordPlan != null)
            this.recordForm.setValue(this.eventData.recordPlan);

        if (this.eventData.private != null)
            this.privateForm.setValue(this.eventData.private);

        if (this.eventData.smilyRec != null)
            this.smilyRecForm.setValue(this.eventData.smilyRec);

        if (this.eventData.roomId != null)
            this.roomForm.setValue(this.eventData.roomId, { emitEvent: true });

        if (this.eventData.teacherId != null)
            this.teacherForm.setValue(this.eventData.teacherId, { emitEvent: true });

        this.azureService.updateCustomerStorage()
            .then(storage => {
                if (storage && ((storage.currentSize / storage.maxSize) * 100) >= 100) {
                    this.recordForm.setValue(false);
                    this.recordForm.disable();
                }
            });

        if (!this.isMasterLesson()) {
            this.getPublisherOfClassroom();

            if (!this.isPlanned)
                this.checkClassLesson();
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    async getResult() {
        this.sending = true;

        this.eventData.startData = AppLessonPopUpComponent.getStartDate(this.dateForm).toUTCString();
        this.eventData.endData = AppLessonPopUpComponent.getEndDate(this.dateForm).toUTCString();
        this.eventData.title = this.nameForm.value;
        this.eventData.description = this.descriptionForm.value;
        this.eventData.recordPlan = this.recordForm.value;
        this.eventData.private = this.privateForm.value;
        this.eventData.smilyRec = this.smilyRecForm.value;
        this.eventData.classId = this.classroomForm.value;
        this.eventData.roomId = this.roomForm.value === 0 ? null : this.roomForm.value;
        this.eventData.teacherId = this.teacherForm.value;

        this.dialogRef.close(this.eventData);
    }

    checkClassLesson() {
        this.lessonOpenInClass = null;

        this.calendar.getOpenLessonInClass(this.classroomForm.value)
            .subscribe(output => {
                if (!output)
                    return;

                this.lessonOpenInClass = output;
            });
    }

    getPublisherOfClassroom() {
        this.classroomService
            .getUsersOfClassroom(this.classroomForm.value, 1, true)
            .subscribe(res => {

                this.publishers = res.slice();

                if (!res.some(p => p.id === this.currentUser.id))
                    this.publishers.unshift(<any>{ id: this.currentUser.id, name: this.currentUser.name, surname: this.currentUser.surname });

            });
    }

    isStandardLesson() {
        return this.eventData.type === 1;
    }

    isMasterLesson() {
        return this.eventData.type === 3;
    }

    okBtnDisabled() {
        let check = !this.dateForm.valid ||
                    !this.nameForm.valid ||
                    !this.teacherForm.valid ||
                    this.lessonOpenInClass ||
                    this.sending;

        return check;
    }

    getShowDateOrTime() {
        return AppLessonPopUpComponent.showDateOrTime;
    }

    dateDifferenceValidator(group: FormGroup): ValidatorFn {
        if (!group)
          return null;
    
        let startDateForm = group.controls.startDateForm;
        let endDateForm = group.controls.endDateForm;
    
        let startDate = AppLessonPopUpComponent.getStartDate(group);
        let endDate = AppLessonPopUpComponent.getEndDate(group);
    
        if (isBefore(startDate, new Date()))
          startDateForm.setErrors({ futureDate: true });
        else
          startDateForm.setErrors(null);
    
        if (endDate <= startDate)
          endDateForm.setErrors({ dateDifference: true });
        //else if (differenceInMinutes(endDate, startDate) > LESSON_DURATION_MINUTES)
        //  endDateForm.setErrors({ durationLimit: true });
        else
          endDateForm.setErrors(null);
    
        return null;
    }
    
    private static getStartDate(group: FormGroup) {
        if (!group?.controls?.startDateForm?.value)
          return null;
    
        if (AppLessonPopUpComponent.showDateOrTime)
          return new Date(group.controls.startDateForm.value);
    
        let startDate = new Date(AppLessonPopUpComponent.startDateBackup);
        let startTime = Helper.convertTimeToHMS(group.controls.startDateForm.value);
    
        startDate.setHours(startTime[0]);
        startDate.setMinutes(startTime[1]);
        startDate.setSeconds(startTime[2]);
    
        return startDate;
    }
    
    private static getEndDate(group: FormGroup) {
        if (!group?.controls?.endDateForm?.value)
          return null;
    
        let endTime = Helper.convertTimeToHMS(group.controls.endDateForm.value);
        let endDate = this.getStartDate(group);
    
        endDate.setHours(endTime[0]);
        endDate.setMinutes(endTime[1]);
        endDate.setSeconds(endTime[2]);
    
        return endDate;
    }

}

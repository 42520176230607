<mat-card appearance="outlined" fxLayout="column" class="standard-card mat-elevation-z8"
    *ngIf="!compact; else compactCard"
    [style]="style">

    <img mat-card-image [src]="imgUrl" (click)="goTo()" loading="lazy">
    
    <div class="topLeft mat-elevation-z3" *ngIf="topLeft">
        <h5 style="margin: 0%">{{ (topLeft | translate).toUpperCase() }}</h5>
    </div>

    <div class="topRight mat-elevation-z3" *ngIf="topRight">
        <h5 style="margin: 0%">{{ (topRight | translate).toUpperCase() }}</h5>
    </div>

    <div class="bottomLeft mat-elevation-z3" *ngIf="bottomLeft">
        <h5 style="margin: 0%">{{ (bottomLeft | translate).toUpperCase() }}</h5>
    </div>

    <mat-card-header>
        <mat-card-title [matTooltip]="title" class="truncate-text">
            {{ title }}
        </mat-card-title>

        <mat-card-subtitle fxLayout="row">
            <div class="truncate-text" style="width: 146px">
                {{ subtitle_extra }}
            </div>
            <div class="truncate-text" [style.width.px]="subtitle_extra ? '146' : '293'" style="text-align: end;">
                {{ subtitle }}
            </div>
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content
        [innerHtml]="description ?? '&nbsp;'"
        [style.color]="isDark() ? 'white' : 'rgba(0, 0, 0, 0.54)'"
        class="truncate-text"
        style="white-space: pre-wrap; width: 293px;">
    </mat-card-content>

    <div fxFlex></div>

    <div style="margin-top: 5px">

        <ng-content select="[bodyBottom]"></ng-content>

    </div>

    <mat-card-actions>
        <div fxLayour="row" fxLayoutAlign="center center" fxFlex style="margin-right: 8px">

            <div fxLayout="column" fxLayoutAlign="center start" fxFlex>
                <button mat-raised-button color="accent" (click)="goTo()" *ngIf="!isMobile">
                    <mat-icon>launch</mat-icon>
                    <div class="truncate-text" style="max-width: 130px">{{ routeMessage | translate }}</div>
                </button>

                <button mat-raised-button color="primary" (click)="buy()" [disabled]="buyDisabled" *ngIf="isMobile && subscription && buyShow">
                    <mat-icon>{{ subscription.isFree ? 'shopping_bag' : 'shopping_cart' }}</mat-icon>
                    {{ (renewShow ? 'Renew' : subscription.isFree ? 'Subscribe' : 'Add to cart') | translate }}
                </button>
            </div>

            <div fxLayout="column" fxLayoutAlign="end end" *ngIf="isSelectionEnabled(); else showSubscription" fxFlex>
                <ng-container *ngTemplateOutlet="selectionCheckbox"></ng-container>
            </div>

        </div>
    </mat-card-actions>
</mat-card>

<ng-template #compactCard>
    <mat-card appearance="outlined" class="compact-card mat-elevation-z8"
        [class.highlight]="isHighlighted || showInfoBtn"
        [style]="style"
        (mouseenter)="showInfoBtn = enableHighlight"
        (mouseleave)="showInfoBtn = false">

        <img mat-card-image [src]="imgUrl" (click)="clickAsGoTo ? goTo() : onClick.emit(true)" loading="lazy">

        <div *ngIf="showInfoBtn" (click)="clickAsGoTo ? goTo() : onClick.emit(true)" class="topRight" style="background-color: rgba(0, 0, 0, 0);">
            <button mat-mini-fab class="info-btn">
                <mat-icon>info</mat-icon>
            </button>
        </div>

        <mat-card-header (click)="clickAsGoTo ? goTo() : onClick.emit(true)">
            <mat-card-title [matTooltip]="title" class="truncate-text">
                {{ title }}
            </mat-card-title>
    
            <mat-card-subtitle class="truncate-text">
                {{ subtitle }}
            </mat-card-subtitle>
        </mat-card-header>

        <div fxFlex></div>

        <mat-card-content>
            <ng-container *ngTemplateOutlet="isSelectionEnabled() ? selectionCheckbox : showSubscription"></ng-container>
        </mat-card-content>

    </mat-card>
</ng-template>

<ng-template #selectionCheckbox>
    <mat-checkbox color="accent" (change)="selectionToggle($event)"
        [checked]="checkSelectedEntity()"
        [disabled]="selectionMaxLength()">
        {{ 'Select' | translate }}
    </mat-checkbox>
</ng-template>

<ng-template #showSubscription>
    <span *ngIf="hasSubscription" class="priceTag" [style.color]="isDark() ? 'white !important' : 'black !important'">
        {{ subscription ? (subscription.isFree ? ('Free' | translate) : subscription.subscriptionFee.euro + '€') : '- €' }}
    </span>
</ng-template>

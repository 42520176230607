<mat-card appearance="outlined" *ngIf="open" class="mat-elevation-z8" style="width: 400px;">
  <mat-card-header style="justify-content: space-between; align-items: center;">
    <mat-card-title>
      <mat-icon style="margin-right: 10px; vertical-align: middle;">cloud_upload</mat-icon>
      {{ 'Uploads' | translate }} ({{ getUploads().length }})
    </mat-card-title>
    <div>
      <button mat-icon-button (click)="closeList()" [disabled]="sending" [matTooltip]="'Hide' | translate">
        <mat-icon>remove</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-list role="list" style="max-height: 50vh; overflow: auto;">
      <mat-list-item *ngFor="let upload of getUploads(); let i = index">
        <app-spinner-container matListItemAvatar
          *ngIf="upload.percentage !== 100 && upload.percentage !== -1; else uploadMarker"
          [diameter]="50"
          [strokeWidth]="4"
          [value]="upload.percentage"
          [displayWith]="spinnerValue"
          color="primary">
        </app-spinner-container>
        <ng-template #uploadMarker>
          <mat-icon matListItemIcon [style.color]="upload.percentage === 100 ? 'green' : 'red'">
            {{ upload.percentage === 100 ? 'check_circle' : 'remove_circle' }}
          </mat-icon>
        </ng-template>
        <span matListItemTitle>{{ upload.name }}</span>
        <button matListItemMeta mat-icon-button
          *ngIf="upload.percentage !== 100 && upload.percentage !== -1"
          (click)="stopCurrentUpload(i)"
          [matTooltip]="'Cancel' | translate">
          <mat-icon>
            clear
          </mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="clearList()">{{ 'Clear' | translate }}</button>
  </mat-card-actions>
</mat-card>

<button mat-fab color="primary" (click)="openList()" *ngIf="!open" [matTooltip]="'Uploads' | translate" matTooltipPosition="left">
  <mat-icon>cloud_upload</mat-icon>
</button>

<div class="component-main" fxLayout="column" fxLayoutGap="20px">

    <div fxLayoutAlign="center center">
        <span fxFlex="30"></span>
        <h2 fxLayoutAlign="center center" fxFlex>{{ 'Dashboard' | translate }}{{ stationName != null ? (' ' + stationName) : '' }}</h2>
        <h3 fxLayoutAlign="end center" fxFlex="30" style="font-weight: bold; white-space: pre;">
            {{ 'Remote user' | translate }}: <span style="color: rgb(0, 172, 193)">
                {{ connUser && connUser.name && connUser.surname ? (connUser.name + ' ' + connUser.surname) : ('No user connected' | translate) }}
            </span>
        </h3>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-stroked-button (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
          {{ 'Back' | translate }}
        </button>
    </div>

    <!-- DataPoint KPI -->
    <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex>
            <app-data-widget
                title="Speed"
                icon="speed"
                [value]="speedSp"
                [unit]="speedSpUnit"
                [nextValue]="speedSpSetTime != null ? speedSpSet : null">  
            </app-data-widget>
        </div>

        <div fxFlex>
            <app-data-widget
                title="Torque"
                icon="settings"
                [value]="torqueSp"
                [unit]="torqueSpUnit"
                [nextValue]="torqueSpSetTime != null ? torqueSpSet : null">  
            </app-data-widget>
        </div>

        <div fxFlex>
            <app-data-widget
                title="Rotation direction"
                icon="sync"
                [value]="getRotationDirString(rotationDir) | translate"
                [nextValue]="rotationDirSetTime != null ? (getRotationDirString(rotationDirSet) | translate) : null">  
            </app-data-widget>
        </div>

        <div fxFlex>
            <app-data-widget
                title="Light"
                icon="bolt"
                [value]="lightSp"
                [nextValue]="lightSpSetTime != null ? lightSpSet : null">  
            </app-data-widget>
        </div>
    </div>

    <!-- DataPoint set value -->
    <div fxLayout="row" fxLayoutGap="20px">
        <div fxLayoutAlign="center center" fxLayout="row" fxFlex>
            <div fxLayoutAlign="center center" fxFlex>{{ speedMin }}</div>
            <mat-slider
                [min]="speedMin"
                [max]="speedMax"
                step="500"
                thumbLabel="true"
                [(ngModel)]="speedSpSet"
                fxFlex="60" #ngSlider><input matSliderThumb (change)="setDataPoint(getSps().speed, {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" />
            </mat-slider>
            <div fxLayoutAlign="center center" fxFlex>{{ speedMax }}</div>
        </div>

        <div fxLayoutAlign="center center" fxLayout="row" fxFlex>
            <div fxLayoutAlign="center center" fxFlex>{{ torqueMin }}</div>
            <mat-slider
                [min]="torqueMin"
                [max]="torqueMax"
                step="10"
                thumbLabel="true"
                [(ngModel)]="torqueSpSet"
                fxFlex="60" #ngSlider><input matSliderThumb (change)="setDataPoint(getSps().torque, {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" />
            </mat-slider>
            <div fxLayoutAlign="center center" fxFlex>{{ torqueMax }}</div>
        </div>

        <div fxLayoutAlign="center center" fxFlex>
            <mat-button-toggle-group [(ngModel)]="rotationDirSet" (change)="setDataPoint(getSps().direction, $event)">
                <mat-button-toggle value="CW">{{ 'Clockwise' | translate }}</mat-button-toggle>
                <mat-button-toggle value="CCW">{{ 'Counter clockwise' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div fxLayoutAlign="center center" fxLayout="row" fxFlex>
            
            <div fxFlex="50">
                <mat-form-field color="accent">
                    <mat-label>{{ 'Mode' | translate }}</mat-label>
                    <mat-select [(ngModel)]="lightSpSet" (selectionChange)="setDataPoint(getSps().light, $event)">
                        <mat-option *ngFor="let mode of lightModes" [value]="mode">{{ mode | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayoutAlign="center center" fxLayout="row" fxFlex>
                <div fxLayoutAlign="center center" fxFlex>{{ lightIntMin }}</div>
                <mat-slider
                    [min]="lightIntMin"
                    [max]="lightIntMax"
                    step="1"
                    thumbLabel="true"
                    [disabled]="lightSp === 'Off'"
                    [(ngModel)]="lightIntSet"
                    fxFlex="60" #ngSlider><input matSliderThumb (change)="setDataPoint(getSps().lightIntensity, {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" />
                </mat-slider>
                <div fxLayoutAlign="center center" fxFlex>{{ lightIntMax }}</div>
            </div>
            
        </div>
    </div>

</div>

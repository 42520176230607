<div fxLayout="column" class="overlay-container">

  <div class="title" fxLayoutAlign="space-between center">
    <h3>{{ title | translate }}</h3>

    <div>

      <button mat-icon-button *ngIf="lowerAllHandsActive" (click)="onLowerAllHands.emit(true)" [matTooltip]="'Lower all hands' | translate" class="buttons">
        <mat-icon>do_not_touch</mat-icon>
      </button>

      <button mat-icon-button (click)="onClose.emit(true)" [matTooltip]="'Close' | translate">
          <mat-icon>close</mat-icon>
      </button>

    </div>
  </div>

  <mat-list class="user-list-container">

    <mat-list-item *ngFor="let element of userList" [style.backgroundColor]="getColor(element.actualState)">
  
      <app-profile-picture [url]="element.user.profilePictureUrl" size="40px" matListItemAvatar></app-profile-picture>
  
      <span matListItemTitle>{{ element.user.name }} {{ element.user.surname }}</span>
  
      <div matListItemMeta style="color: inherit">

        <button mat-icon-button *ngIf="isHandRaised(element.user.id)"
          (click)="lowerHand()"
          [matTooltip]="'Lower hand' | translate"
          [matTooltipDisabled]="!lowerHandEnabled"
          color="accent">
          <mat-icon>
            back_hand
          </mat-icon>
        </button>
  
        <button mat-icon-button *ngIf="element.actualState === 2 && !isBidirectionalConnected(element.user.id)"
          (click)="askBidirectional(element.user.id, 'start')"
          [matTooltip]="'Start Video' | translate"
          color="accent">
          <mat-icon>
            videocam
          </mat-icon>
        </button>
  
        <button mat-icon-button *ngIf="isBidirectionalConnected(element.user.id)"
          (click)="askBidirectional(element.user.id, 'stop')"
          [matTooltip]="'Close Video' | translate"
          color="accent">
          <mat-icon>
            videocam_off
          </mat-icon>
        </button>
  
        <button mat-icon-button *ngIf="element.station"
          [matTooltip]="'Smily: ' + element.station?.name + ' ' + ('Room' | translate) + ': ' + element.station.room?.name">
          <mat-icon>
            desktop_windows
          </mat-icon>
        </button>
  
        <button mat-icon-button *ngIf="!element.station"
          [matTooltip]="'Remote Connection' | translate">
          <mat-icon>
            settings_ethernet
          </mat-icon>
        </button>
  
        <button mat-icon-button *ngIf="!(element.station?.workingState ?? true)"
          [matTooltip]="('Problems on Smily' | translate) + ' ' + element.station?.name"
          color="warn">
          <mat-icon>
            error
          </mat-icon>
        </button>
  
      </div>
  
    </mat-list-item>
  
  </mat-list>

</div>

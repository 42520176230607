import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentCustomerType, EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { LanguageCodes, TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-viqirobot-login',
  templateUrl: './viqirobot-login.component.html',
  styleUrls: ['./viqirobot-login.component.scss']
})
export class ViqirobotLoginComponent {

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  logo: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.ViqiRobot, this.themeService.isSetDark ? EnvironmentParameterType.LogoLight : EnvironmentParameterType.LogoDark);
  backgroundImage: string = EnvironmentHelper.getConfig(EnvironmentCustomerType.ViqiRobot, EnvironmentParameterType.BackgroundImage);
  backgroundImageMobile: string = `background-image: url(${EnvironmentHelper.getConfig(EnvironmentCustomerType.ViqiRobot, EnvironmentParameterType.BackgroundImageMobile)})`;
  motto: string = environment.applicationMotto;

  idCustomer: number = 79;
  customerCode: string = 'SSC2023';

  constructor(
    private themeService: DarkThemeService,
    private translationService: TranslationService,
    private navBar: NavBarService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.translationService.setLang(LanguageCodes.IT);
    this.themeService.onLogOut();
    this.navBar.hide('topbar');
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

}

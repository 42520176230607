import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { AirTimeDTO } from 'src/app/models/dto/airTimeDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CsvExporterService } from 'src/app/services/csv-exporter.service';
import { ClassroomService } from 'src/app/services/classroom.service';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { Helper } from 'src/app/helpers/helper';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-calendar-user-list',
    templateUrl: './calendar-user-list.component.html',
    styleUrls: ['./calendar-user-list.component.scss']
})
export class CalendarUserListComponent implements OnInit {

    lessonId: number = 0;
    lesson: LessonSessionDTO;
    lessonDuration: string;
    lessonPlannedDuration: string;
    students: number = 0;

    dataSource: MatTableDataSource<AirTimeDTO> = new MatTableDataSource();
    columnsToDisplay = ['name', 'surname', 'startDate', 'endDate', 'timeInLessons', 'room', 'station'];

    @ViewChild(MatSort) set matSort(matSort: MatSort) {
        this.dataSource.sort = matSort;

        this.dataSource.sortingDataAccessor = (element, property:string) => {
            switch (property) {
             case 'name':
               return `${element.user.name}`;
             case 'surname':
               return `${element.user.surname}`;
             case 'station':
               return `${element.station?.name}`;
             case 'room':
               return `${element.station?.room.name}`;
             default: 
                return element[property];
            }
        };
    }
    
    @ViewChild(MatPaginator) set matPaginator(matPaginator: MatPaginator) {
        this.dataSource.paginator = matPaginator;
    }

    constructor(
        private calendar: CalendarService,
        private exporter: CsvExporterService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private snackBar: MatSnackBar,
        private classroomService: ClassroomService) { }

    ngOnInit() {
        this.lessonId = Number(this.route.snapshot.paramMap.get('id'));

        if (this.lessonId == undefined) 
            this.router.navigate(['/calendar']);

        this.getLesson();
        this.updatePage(); 
    }

    getLesson() {
        this.calendar.getLesson(this.lessonId)
            .subscribe(output => {
                this.lesson = output;
                this.lessonDuration = Helper.convertToHMS(this.getTime(this.lesson.startDate, this.lesson.stopDate));
                this.lessonPlannedDuration = Helper.convertToHMS(this.getTime(this.lesson.startPlanned, this.lesson.endPlanned));

                this.getUsersByClassroom();
        });
    }

    updatePage() {
        this.calendar.getAirTimeOfLesson(this.lessonId)
            .subscribe({
                next: output => {
                    this.dataSource.data = output;
                   
                    if (this.dataSource.data.length === 0)
                        this.openSnackBar('No users were connected to the lesson');

                }, error: err => {
                    this.dataSource.data = [];
                    console.error(err);
                }
            });
    }

    openSnackBar(message: string) {
        this.snackBar
            .open(message, '', { duration: 2000,})
            .afterDismissed()
            .subscribe(() => this.goBack());
    };

    goBack() {
        this.location.back();
    }

    getPercentage(startDateL: string | Date, endDateL: string | Date, startDateS: string | Date, endDateS: string | Date) {
        var startL = new Date(startDateL);
        var endL = new Date(endDateL);
        var startS = new Date(startDateS);
        var endS = new Date(endDateS);

        var StudentTime = endS.getTime() - startS.getTime();
        var LessonTime = endL.getTime() - startL.getTime();

        if (LessonTime < 1000)
            return 0;

        return (StudentTime * 100) / LessonTime;
    }

    getTime(startDate: Date | string, endDate: Date | string) {
        var start = new Date(startDate);
        var end = new Date(endDate);

        if(end.getTime() - start.getTime() < 1000)
            return 0;

        return end.getTime() - start.getTime();
    }

    getAvgPercentage() {
        var totalTime = 0;

        this.dataSource?.data.forEach(data => {
            totalTime += this.getTime(data.startDate, data.endDate);
        });

        return Math.round(((totalTime / this.students) / this.getTime(this.lesson.startDate, this.lesson.stopDate)) * 10000) / 100;
    }

    getUsersByClassroom() {
        this.students = null;

        this.classroomService.getUsersOfClassroom(this.lesson.classId, 2)
            .subscribe(res => this.students = res.length);
    }

    lessonExportToCsv() {
        this.calendar.getLesson(this.lessonId)
            .subscribe(lesson => {

                if (!lesson)
                    return;

                this.exporter.exportAirTimesToCsv([{ data: this.dataSource.data, lesson: lesson }]);
            });
    }

    spinnerValue(value: number) {
        return `${value}%`;
    }
}

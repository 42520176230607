<mat-card appearance="outlined" fxLayout="row" style="height: 72px">

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex style="margin-left: 16px">
        <ng-content select="[leftBtns]"></ng-content>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" style="margin-left: 40px; margin-right: 40px;">
        <button mat-mini-fab [color]="isVideoActive() ? 'primary' : 'warn'"
            *ngIf="isToggleVideoActive() && !videoOverride; else videoOverrideBtn"
            (click)="toggleVideo()"
            [matTooltip]="'Toggle video' | translate">
            <mat-icon>
                {{ isVideoActive() ? 'videocam' : 'videocam_off' }}
            </mat-icon>
        </button>
        <button mat-mini-fab [color]="isAudioActive() ? 'primary' : 'warn'"
            *ngIf="isToggleAudioActive()"
            (click)="toggleAudio()"
            [matTooltip]="'Toggle audio' | translate">
            <mat-icon>
                {{ isAudioActive() ? 'mic' : 'mic_off' }}
            </mat-icon>
        </button>
        <button mat-mini-fab [color]="handRaise ? 'accent' : 'primary'"
            *ngIf="handRaiseActive"
            (click)="toggleHandRaise()"
            [matTooltip]="'Toggle hand' | translate">
            <mat-icon>back_hand</mat-icon>
        </button>
        <button mat-mini-fab [color]="isSharing ? 'accent' : 'primary'"
            *ngIf="shareActive"
            [matMenuTriggerFor]="shareSwitchMenu"
            [disabled]="!shareEnabled"
            [matTooltip]="'Share' | translate">
            <mat-icon>
                present_to_all
            </mat-icon>
        </button>
        <button mat-mini-fab
            *ngIf="recordingActive"
            (click)="onRecordingChange.emit(!isRecording)"
            [disabled]="!recordingEnabled"
            [matTooltip]="(isRecording ? 'Stop recording' : 'Start recording') | translate"
            [color]="isRecording ? 'warn' : 'primary'">
            <mat-icon>
                {{ isRecording ? 'stop' : 'fiber_manual_record' }}
            </mat-icon>
        </button>

        <ng-content select="[centerBtns]"></ng-content>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex style="margin-right: 16px">
        <ng-content select="[rightBtns]"></ng-content>

        <button mat-mini-fab
            [matTooltip]="'Settings' | translate"
            [matMenuTriggerFor]="optionMenu"
            color="primary">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <button mat-raised-button
            *ngIf="closeActive"
            (click)="toggleFullScreen(false); onClose.emit(true)"
            color="warn">
            <mat-icon>close</mat-icon>
            {{ 'Close' | translate }}
        </button>
        <button mat-raised-button
            (click)="toggleFullScreen(false); onLeave.emit(true)"
            color="warn">
            <mat-icon>logout</mat-icon>
            {{ 'Leave' | translate }}
        </button>
    </div>

</mat-card>

<mat-menu #shareSwitchMenu="matMenu" xPosition="before" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item
            *ngIf="hasScreenShareCapabilities"
            (click)="onStartShare.emit('screen')"
            [class.mat-menu-highlight]="shareVideoDeviceId === 'screen'">
            <mat-icon>screen_share</mat-icon>
            {{ 'Screen sharing' | translate }}
        </button>
        <button mat-menu-item
            *ngFor="let device of shareVideoDevices"
            (click)="onStartShare.emit(device.deviceId)"
            [class.mat-menu-highlight]="shareVideoDeviceId === device.deviceId">
            <mat-icon>videocam</mat-icon>
            {{ device.label }}
        </button>
        <button mat-menu-item
            *ngIf="isSharing"
            (click)="onStopShare.emit(true)">
            <mat-icon>stop</mat-icon>
            {{ 'Stop share' | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #optionMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item
            *ngIf="subjectVideoDeviceId !== false && subjectVideoDeviceId != undefined"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: subjectVideoDevices, type: 'video' }">
            <mat-icon>videocam</mat-icon>
            {{ 'Video Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="subjectAudioDeviceId !== false && subjectAudioDeviceId != undefined"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: subjectAudioDevices, type: 'audio' }">
            <mat-icon>mic</mat-icon>
            {{ 'Audio Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="visualTypeActive"
            [matMenuTriggerFor]="visualTypeMenu">
            <mat-icon>settings_overscan</mat-icon>
            {{ 'Change video appearance' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="fullScreenActive"
            (click)="toggleFullScreen()">
            <mat-icon>{{ fullScreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
            {{ (fullScreen ? 'Disable fullscreen' : 'Enable fullscreen') | translate }}
        </button>
        <button mat-menu-item
            *ngIf="httpLink"
            (click)="copyUrl()"
            [cdkCopyToClipboard]="httpLink">
            <mat-icon>link</mat-icon>
            {{ 'Copy link' | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #devicesMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-devices="devices" let-type="type">
        <button mat-menu-item
            *ngFor="let device of devices"
            (click)="onChangeSource.emit({ deviceId: device.deviceId, mirror: isSubjectMirrored, type: type })"
            [class.mat-menu-highlight]="subjectVideoDeviceId === device.deviceId || subjectAudioDeviceId === device.deviceId">
            <mat-icon>
                {{
                   device.deviceId === 'screen' ?
                   'screen_share' :
                   type === 'video' ?
                   'videocam' :
                   type === 'audio' ?
                   'mic' :
                   'question_mark'
                }}
            </mat-icon>
            {{ device.label }}
        </button>
        <button mat-menu-item
            *ngIf="subjectVideoDeviceId !== false && type === 'video'"
            (click)="onChangeSource.emit({ deviceId: subjectVideoDeviceId, mirror: !isSubjectMirrored, type: 'video' })"
            [class.mat-menu-highlight]="isSubjectMirrored">
            <mat-icon>
                switch_video
            </mat-icon>
            {{ 'Mirror' | translate }}
        </button>
        <!--button mat-menu-item
            *ngIf="type === 'video'"
            (click)="changeSource(false, 'video')"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === false">
            {{ 'No camera' | translate }}
        </button-->
    </ng-template>
</mat-menu>

<mat-menu #visualTypeMenu="matMenu" xPosition="before" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="setVisualType('fill')" [class.mat-menu-highlight]="visualType === 'fill'">{{ 'Fill' | translate }}</button>
        <button mat-menu-item (click)="setVisualType('cover')" [class.mat-menu-highlight]="visualType === 'cover'">{{ 'Cover' | translate }}</button>
        <button mat-menu-item (click)="setVisualType('contain')" [class.mat-menu-highlight]="visualType === 'contain'">{{ 'Contain' | translate }}</button>
    </ng-template>
</mat-menu>

<ng-template #videoOverrideBtn>
    <button mat-mini-fab [color]="videoOverrideActive ? 'primary' : 'warn'"
        *ngIf="isToggleVideoActive()"
        (click)="videoOverrideActive = !videoOverrideActive"
        [matTooltip]="'Toggle video' | translate">
        <mat-icon>
            {{ videoOverrideActive ? 'videocam' : 'videocam_off' }}
        </mat-icon>
    </button>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { BannerDTO } from 'src/app/models/dto/bannerDTO';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  banner: BannerDTO;

  constructor(private calendar: CalendarService) { }

  ngOnInit(): void {
    this.getBanner();
  }

  getBanner(): void {
    this.calendar.getBanner()
      .subscribe({
        next: output => this.banner = output,
        error: err => {
          console.log('No banner');
          console.log(err);
          
          this.banner = null;
        }
      });
  }

  openLink() {
    if (!this.banner?.httpLink)
      return;

    window.open(this.banner.httpLink, "_blank");
  }

}

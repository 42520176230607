import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Helper } from 'src/app/helpers/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { ConferenceService } from 'src/app/services/conference.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { LanguageCodes, TranslationService } from 'src/app/services/translation.service';
import { AddSubscriptionComponent, CrudSubscription, SubscriptionType } from 'src/app/popup/add-subscription/add-subscription.component';
import { MatDialog } from '@angular/material/dialog';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConferenceWebinarPopupComponent } from '../conference-webinar-popup/conference-webinar-popup.component';
import { CartService } from 'src/app/ecommerce/service/cart.service';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CsvExporterService, HASHTAGS_HEADERS, Subscriber } from 'src/app/services/csv-exporter.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { LocationStrategy } from '@angular/common';
import { AddUserListPopupComponent } from 'src/app/popup/add-user-list-popup/add-user-list-popup.component';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { SubscriptionUserDTO } from 'src/app/models/dto/subscriptionUserDTO';
import { differenceInMinutes, differenceInSeconds, isToday } from 'date-fns';
import { UserRole } from 'src/app/models/userRole';

@Component({
  selector: 'app-webinar-showcase',
  templateUrl: './webinar-showcase.component.html',
  styleUrls: ['./webinar-showcase.component.scss']
})
export class WebinarShowcaseComponent implements OnInit {

  @ViewChild('subscribersSort') subscribersSort: MatSort;

  id: number;
  currentUser: User;
  href: string = "";
  
  webinar: ConferenceDTO;
  moderator: UserDTO;
  presenters: UserDTO[] = [];
  
  isWebinarAuthor: boolean = false;
  currentUserIsPresenter: boolean = false;
  currentUserIsParticipant: boolean = false;

  changingState: boolean = false;
  courseCreation: boolean = false;
  
  isMobile: boolean = false;
  showDeepLinkButton: boolean = false;
  previousUrl: string;
  
  canpurchased: boolean;
  currentstate: number;
  isstartget: boolean;
  
  subscribersSource: MatTableDataSource<UserDTO & SubscriptionUserDTO> = new MatTableDataSource();
  subscribersColumns: string[] = ['name', 'surname', 'email', 'role', 'purchased data'];

  newLayout: boolean = false;
  
  constructor(private auth:AuthService,
              private route: ActivatedRoute,
              private conferenceService: ConferenceService,
              private router: Router,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private subscription: SubscriptionService,
              private cart: CartService,
              private translation: TranslationService,
              private navBar: NavBarService,
              private translate: TranslateService,
              private exporter: CsvExporterService,
              private deviceService: DeviceDetectorService,
              private location: LocationStrategy,
              private rolePipe: RolePipe) {
    this.previousUrl = this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.isMobile = this.deviceService.isMobile();

    this.currentUser = this.auth.getCurrentUser();
    this.newLayout = this.currentUser.frontEndType === 1;

    this.href = Helper.getUrl();

    if (this.auth.isDT())
      this.subscribersColumns = this.subscribersColumns.concat(['city', 'qualification', 'telephone']);
    
    if (this.isMobile) {

      this.navBar.hide('all');

      history.pushState(null, null, window.location.href);
      // check if back or forward button is pressed.
      this.location.onPopState(() => {
          history.pushState(null, null, window.location.href);
      });
    }

    this.getWebinar(this.id);
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  goBack() {
    if(this.previousUrl === '/webinar-customer' || this.previousUrl === '/showcase')
      this.location.back()
    else
      this.router.navigate(['/webinars']);
  }

  payWebinarFree() {
    this.conferenceService.buyConference(this.webinar.idSubscription)
    .subscribe({
      next: async () =>  {

        if (this.isMobile)
          this.snackBar.open(await firstValueFrom(this.translate.get('Subscribed to the webinar')), '', { duration: 5000 });

        this.getWebinar(this.id);
        
      },
      error: err =>{
       this.snackBar.open(err.error.Message, '', { duration: 3000 });
       console.log(err);
      }
    });
  }

  private getParticipants() {

    this.conferenceService.getConferenceParticipants(this.id)
      .subscribe(res => {

        let subscribers = res.map(t1 => ({...t1, ...this.webinar.subscription?.subscriptionUser.find(t2 => t2.idUser === t1.id)}));

        this.subscribersSource.data = subscribers;

        this.subscribersSource.sortingDataAccessor = (element, property) => {
          switch (property) {
            case 'role':
              return this.getRole(element);
            case 'city':
              return element?.idprovinceNavigation?.name;
            case 'qualification':
              return element?.idqualificationNavigation?.name;
            case 'purchased data':
              return element.stateLastChangedDate;
            default:
              return element[property];
          }
        };

        this.subscribersSource.sort = this.subscribersSort;

      });
  }

  getWebinar(id: number) {
    this.conferenceService.getConferenceShowcase(id).subscribe({
      next: res => {
        this.webinar = res;
        
        if (this.webinar.lessonSession.teacherId === this.currentUser.id)
          this.isWebinarAuthor = true;

        this.moderator = this.webinar.conferencePresenter.find(p => p.role === ConferencePresenterRole.Moderator)?.presenter;

        this.presenters = this.webinar.conferencePresenter.filter(r => r.role === ConferencePresenterRole.Presenter).map(p => p.presenter);

        this.currentUserIsPresenter = this.webinar.conferencePresenter.findIndex(p => p.idPresenter === this.currentUser.id) !== -1;

        this.currentUserIsParticipant = this.webinar.lessonSession.classroom.classroomContent.findIndex(u => u.userId === this.currentUser.id) !== -1;

        if (this.isMobile && (this.currentUserIsParticipant || this.currentUserIsPresenter))
          this.showDeepLinkButton = true;
       
        if (this.webinar.subscription && this.isAuthenticated())
          this.checkSubscription();

        if (this.isWebinarAuthor ||
            this.currentUserIsPresenter ||
            this.currentUser.isAdmin ||
            (this.currentUser.isCustomerAdmin && this.currentUser.idCustomer === this.webinar?.lessonSession?.classroom?.customerId))
          this.getParticipants();
    },
      error: err => {
        console.log(err);
      }
    });
  }

  getTimeDiff(conference: ConferenceDTO) {
    return differenceInMinutes(new Date(), new Date(conference.lessonSession.startDate));
  }

  border(conference: ConferenceDTO) {
    if (conference?.lessonSession?.stopDate)
      return null;

    let border = 'outline: solid thick ';

    let participant = conference?.lessonSession?.classroom?.classroomContent.find(p => p.userId === this.currentUser.id);
    let presenter = conference?.conferencePresenter?.find(p => p.idPresenter === this.currentUser.id);

    if ((participant || presenter) && conference?.lessonSession?.startDate && !conference?.lessonSession?.stopDate)
      return border += 'green';

    let diff = differenceInMinutes(new Date(conference?.lessonSession?.startPlanned), new Date());

    if (diff < 0)
      return null;

    if (diff <= 120)
      return border += 'red';
    
    if (diff <= 1440)
      return border += 'orange';
    
    return null;
  }

  getPercentage(startDate: Date, endDate: Date, time: string) {
    let lessonTime = differenceInSeconds(new Date(startDate), new Date(endDate));
    let studentTime = Helper.timeFromTimeSpan(time, 'seconds');

    if (lessonTime === 0 || studentTime === 0)
      return 0;

    return (studentTime * 100) / lessonTime;
  }

  getSubTitle() {
    return this.translation.currentLang.code === LanguageCodes.IT ? 'Iva inclusa' : null;
  }

  getWebinarState() {
    return this.webinar.state === 1 ? 'Online' : 'Offline';
  }

  addSubscription () {
    let data: CrudSubscription = new CrudSubscription();

    data.entityId = this.webinar.id;
    data.entityType = SubscriptionType.webinar;
    data.subscription = this.webinar.subscription;

    const dialogRef = this.dialog.open(AddSubscriptionComponent, {
      data: data, width: '600px'  
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.getWebinar(this.id);
    });
  }

  async toggleState(event: MatSlideToggleChange) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      data: <GenericPopupData> { 
        title: await firstValueFrom(this.translate.get('Change webinar status')), 
        body: await firstValueFrom(this.translate.get('Are you sure to change the status of')) + ' ' + this.webinar.name + '?' }
    });

    dialogRef.afterClosed()
      .subscribe(async result => {
        if (result) {
          this.changingState = true;

          await firstValueFrom(this.conferenceService.changeConferenceState(this.webinar.id, this.webinar.state === 1 ? 2 : 1))
            .then(async () => {
              this.snackBar.open(await firstValueFrom(this.translate.get('Webinar state updated')), 'Dismiss', { duration: 3000 } );
              this.changingState = false;
            })
            .catch(err => {
              this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 } );
              this.changingState = false;
            });
        }

        this.getWebinar(this.id);
        event.source.checked = this.webinar.state === 1;
     });
  }

  editWebinar() {
    const dialogRef = this.dialog.open(ConferenceWebinarPopupComponent, {
      data: { webinar: this.webinar },
      width: '600px',
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.getWebinar(this.id);
    });
  }

  async deleteWebinar(webinar:ConferenceDTO) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete conference')),
        body:  await firstValueFrom(this.translate.get(`Are you sure you want to delete webinar?`, { name: webinar.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result)
          return;

        this.conferenceService.deleteConference(webinar.id)
          .subscribe({
            next: async () => {
              this.snackBar.open(await firstValueFrom(this.translate.get('Webinar deleted')), undefined, { duration: 3000 });
              this.router.navigate(['/showcase']);
            },
            error: err => {
              this.snackBar.open('Error deleting conference', undefined, { duration: 3000 });
              console.log(err);
            }
          });
      });
  }

  addToCartWebinar () {
    this.cart.addConference(this.webinar);
    this.router.navigateByUrl('/cart');
  }

  checkSubscription() {
    this.subscription.getCanPurchase(this.webinar.idSubscription)
      .subscribe(result => this.canpurchased = result);

    this.subscription.getState(this.webinar.idSubscription)
      .subscribe(result => this.currentstate = result);

    this.subscription.getsubscriptionlimituser(this.webinar.idSubscription)
      .subscribe(result => this.isstartget = result);
  }

  isToday(date: Date) {
    return isToday(new Date(date));
  }

  getRole(user: User | UserDTO) {
    return this.rolePipe.transform(UserRole.role(user));
  }

  saveToCsvSubscribers() {
    let data: Subscriber[] = [];

    this.subscribersSource.data.forEach(element => {

      data.push({
        name: element.name,
        surname: element.surname,
        email: element.email,
        role: this.getRole(element),
        province: element?.idprovinceNavigation?.name,
        qualification: element?.idqualificationNavigation?.name,
        telephone: element.telephone,
        purchasedData: new Date(element.stateLastChangedDate).toLocaleString(),
        hashtag1: this.webinar.hashTag[0],
        hashtag2: this.webinar.hashTag[1],
        hashtag3: this.webinar.hashTag[2],
        hashtag4: this.webinar.hashTag[3],
        hashtag5: this.webinar.hashTag[4],
        hashtag6: this.webinar.hashTag[5],
        hashtag7: this.webinar.hashTag[6],
        hashtag8: this.webinar.hashTag[7],
        hashtag9: this.webinar.hashTag[8],
        hashtag10: this.webinar.hashTag[9],
      });

    });

    this.exporter.exportDataToCsv(
      data,
      ["Name", "Surname", "Email", "Role", "Province", "Qualification", "Telephone", "Purchased Data", ...HASHTAGS_HEADERS],
      `Subscribers list of ${this.webinar.name}`
    );
  }

  notDone(conference: ConferenceDTO) {
    return differenceInMinutes(new Date(conference?.lessonSession?.endPlanned), new Date()) < -60
        && !conference.lessonSession.startDate;
  }

  notDoneYet(conference: ConferenceDTO) {
    return differenceInMinutes(new Date(conference?.lessonSession?.startPlanned), new Date()) < 0
        && differenceInMinutes(new Date(conference?.lessonSession?.endPlanned), new Date()) > -60
        && !conference.lessonSession.startDate
        && !conference.lessonSession.stopDate;
  }

  createCourse() {
    let dialogRef = this.dialog.open(GenericPopupComponent, {
      data: <GenericPopupData>{
        title: this.translate.instant('Create course'),
        body: this.translate.instant('Are you sure to create a course from this webinar?')
      }
    });

    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res)
          return;

        this.courseCreation = true;

        this.conferenceService.createCourse(this.webinar.id)
          .subscribe({
            next: success => {
              this.snackBar.open('Course created', undefined, { duration: 3000 });

              this.courseCreation = false;

              this.router.navigate(['/course', (success as any).Message], { queryParams: { page: 'sidenav' } });
            },
            error: err => {
              console.error(err);
              this.snackBar.open(err.error.Message, undefined, { duration: 5000 });

              this.courseCreation = false;
            }
          });

      });
  }

  addPaidUsers() {
    let users = [];

    if (this.webinar != null && this.webinar.subscription !== null)
      users = this.webinar.subscription.subscriptionUser.map(u => u.idUser);

    let data: any = {
      type: "webinar",
      subscriptionId: this.webinar.idSubscription,
      alreadyPaidUsers: users
    };

    let dialogRef = this.dialog.open(AddUserListPopupComponent, { data: data });

    dialogRef.afterClosed()
      .subscribe(() => this.getParticipants());
  }

  createDeepLink(webinarId: number) {
    window.location.href =`${EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Url)}/mobile-app-link/webinar/${webinarId}`;
  }

  isDT() {
    return this.auth.isDT();
  }

  isAixp() {
    return EnvironmentHelper.isAixp();
  }

  isDev() {
    return EnvironmentHelper.isDev();
  }
}

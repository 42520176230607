import { EnvironmentCustomerType, EnvironmentVariables } from 'src/app/models/environmentVariables';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentVariables = {
    production: false,
    mode: 'aixp',
    appVersion: '1.12.014',
    appDate: '13/11/2024',
    //apiUrl: 'http://localhost:4500',
    apiUrl: 'https://dev.s-teach.com',
    supportsBidirectional: true,
    useNativePip4Student: false,
    loginBackgroundPicture: '../../assets/img/aixp/aixp_background.jpg',
    applicationName: 'Aixp.io',
    applicationNameShort: 'AIXP',
    applicationMotto: 'The new learning experience',
    applicationFavicon: '../assets/img/aixp/aixp_icon.png',
    applicationCompanyName: 'Aixp.io',
    applicationCompanyUrl: 'https://aixp.io',
    applicationCompanyPhone: '+39 0434 1831007',
    applicationCompanyLogo: '../assets/img/aixp/aixp_logo_dark.svg',
    applicationCompanyLogoWhite: '../assets/img/aixp/aixp_logo_light.svg',
    applicationPrivacyPolicy: 'https://aixp.io/docs/Privacy_Policy_AIXP.html',
    applicationCookiePolicy: 'https://aixp.io/docs/Cookie_Policy_AIXP.html',
    applicationPlayStoreUrl: 'https://play.google.com/store/apps/details?id=io.aixp.mobile',
    applicationAppStoreUrl: 'https://apps.apple.com/us/app/aixp/id1639121952',
    mainPage: 'login',
    surveyJsKey: 'ODRhZTI2OWMtNjE5NC00MGMwLThjMjUtOThkYjgzODVhMDVlJmRvbWFpbnM6YWl4cC5pbyxkZW50YWx0cmV5ZWxlYXJuaW5nLmFpeHAuaW8sZGV2LnMtdGVhY2guY29tLGRldjIucy10ZWFjaC5jb20sZWFzeXRlYWNoLmNsb3VkLGZpdHhwLmlvLGdhbW1hZG9ubmEuYWl4cC5pbyxocm9jb25zdWx0aW5nLmFpeHAuaW8sc2FyYXRvZ2Eucy10ZWFjaC5jb20sc21hcnRjYWJpbmV0LmFpeHAuaW8sc21hcnRjYWJpbmV0MzYwLmFpeHAuaW8sc3VubGlnaHRhY2FkZW15LmFpeHAuaW8sdmlxaXJvYm90LmFpeHAuaW87MT0yMDI1LTA0LTI0LDI9MjAyNS0wNC0yNCw0PTIwMjUtMDQtMjQ=',
    facebookAppId: "305247964610196",
    googleClientId: "",
    configs: [
        {
            id: EnvironmentCustomerType.GammaDonna,
            logo: '../assets/img/gd/gammadonna_logo_dark.png',
            logoDark: '../assets/img/gd/gammadonna_logo_dark.png',
            logoLight: '../assets/img/gd/gammadonna_logo_light.png'
        },
        {
            id: EnvironmentCustomerType.DentalTrey,
            title: 'Dental Trey E-Learning',
            name: 'AIXP',
            url: 'https://dentaltreyelearning.aixp.io',
            phone: '+39 0434 1831007',
            logo: '../assets/img/dt/dentaltrey_logo.png',
            logoDark: '../assets/img/dt/dentaltrey_logo.png',
            logoLight: '../assets/img/dt/dentaltrey_logo.png',
            backgroundImage: '../assets/img/dt/dentaltrey_background.jpg',
            privacyPolicy: 'https://aixp.io/docs/Privacy_Policy_AIXP.html',
            cookiePolicy: 'https://aixp.io/docs/Cookie_Policy_AIXP.html',
            playStore: 'https://play.google.com/store/apps/details?id=io.aixp',
            appStore: 'https://apps.apple.com/us/app/aixp-dental-trey/id6448850960'
        },
        {
            id: EnvironmentCustomerType.ViqiRobot,
            logoDark: '../assets/img/vr/viqirobot_logo_dark.png',
            logoLight: '../assets/img/vr/viqirobot_logo_light.png',
            backgroundImage: '../assets/img/vr/viqirobot_background.png',
            backgroundImageMobile: '../assets/img/vr/viqirobot_background_mobile.png',
        },
        {
            id: EnvironmentCustomerType.Sunlight,
            title: 'Sun Light Academy',
            icon: '../assets/img/sl/sunlight_academy_icon.png',
            logo: '../assets/img/sl/sunlight_academy_logo.png',
            logoDark: '../assets/img/sl/sunlight_academy_logo_dark.png',
            logoLight: '../assets/img/sl/sunlight_academy_logo_light.png',
            backgroundImage: '../assets/img/sl/sunlight_academy_background.jpg'
        },
        {
            id: EnvironmentCustomerType.SmartCabinet,
            backgroundImage: '../assets/img/sc360/smartcabinet360_background.jpg'
        },
        {
            id: EnvironmentCustomerType.Fitxp,
            title: 'Fitxp.io',
            name: 'Fitxp.io',
            icon: '../assets/img/fitxp/fitxp_icon.png',
            logoDark: '../assets/img/fitxp/fitxp_logo_dark.png',
            logoLight: '../assets/img/fitxp/fitxp_logo_light.png',
            backgroundImage: '../assets/img/fitxp/fitxp_background.jpg',
        },
        {
            id: EnvironmentCustomerType.Hro,
            title: 'HR&O Academy',
            icon: '../assets/img/hro/hro_icon.png',
            logo: '../assets/img/hro/hro_logo.png',
            backgroundImage: '../assets/img/hro/hro_background.jpg',
            backgroundImageMobile: '../assets/img/hro/hro_background.jpg'
        },
        {
            id: EnvironmentCustomerType.HuFriedy,
            title: 'HuFriedyGroup',
            icon: '../assets/img/hufriedy/hufriedy_icon.png',
            logo: '../assets/img/hufriedy/hufriedy_logo.png',
            backgroundImage: '../assets/img/hufriedy/hufriedy_background.png'
        },
        {
            id: EnvironmentCustomerType.AixpAcademy,
            title: 'AIXP Academy',
            backgroundImage: '../assets/img/aa/aixp_academy_background.png'
        },
        {
            id: EnvironmentCustomerType.Licar,
            title: 'LICAR | Academy',
            icon: '../assets/img/lcr/licar_icon.png',
            logo: '../assets/img/lcr/licar_logo.png',
            logoDark: '../assets/img/lcr/licar_logo.png',
            logoLight: '../assets/img/lcr/licar_logo.png',
            backgroundImage: '../assets/img/lcr/licar_background.png'
        },
        {
            id: EnvironmentCustomerType.Ideandum,
            title: 'Ideandum',
            icon: '../assets/img/idn/ideandum_icon.png',
            logo: '../assets/img/idn/ideandum_logo.png',
            logoDark: '../assets/img/idn/ideandum_logo_dark.png',
            logoLight: '../assets/img/idn/ideandum_logo_dark.png',
            backgroundImage: '../assets/img/idn/ideandum_background.png'
        },
        {
            id: EnvironmentCustomerType.AixpCommunity,
            title: 'AIXP Community',
            backgroundImage: '../assets/img/cmt/community_background.png'
        }
    ]
}

// dev.s-teach.com
/*
    production: false,
    mode: 'saratoga',
    appVersion: '4.07.001',
    appDate: '09/10/2024',
    apiUrl: 'https://dev.s-teach.com',
    supportsBidirectional: true,
    useNativePip4Student: false,
    loginBackgroundPicture: '../../assets/img/saratoga/saratoga_background.jpg',
    applicationName: 'EasyTeach Cloud',
    applicationNameShort: 'ETC',
    applicationMotto: 'An e-learning platform for dental schools with 4K video streaming',
    applicationFavicon: '../assets/img/saratoga/saratoga_icon.png',
    applicationCompanyName: 'Saratoga S.r.l.',
    applicationCompanyUrl: 'https://www.saratogadental.it/',
    applicationCompanyPhone: null,
    applicationCompanyLogo: '../assets/img/saratoga/saratoga_logo_dark.svg',
    applicationCompanyLogoWhite: '../assets/img/saratoga/saratoga_logo_light.svg',
    applicationPrivacyPolicy: 'https://www.saratogadental.it/en/privacy',
    applicationCookiePolicy: 'https://www.saratogadental.it/en/privacy#cookie',
    applicationPlayStoreUrl: null,
    applicationAppStoreUrl: null,
    mainPage: 'login',
    surveyJsKey: 'ODRhZTI2OWMtNjE5NC00MGMwLThjMjUtOThkYjgzODVhMDVlJmRvbWFpbnM6YWl4cC5pbyxkZW50YWx0cmV5ZWxlYXJuaW5nLmFpeHAuaW8sZGV2LnMtdGVhY2guY29tLGRldjIucy10ZWFjaC5jb20sZWFzeXRlYWNoLmNsb3VkLGZpdHhwLmlvLGdhbW1hZG9ubmEuYWl4cC5pbyxocm9jb25zdWx0aW5nLmFpeHAuaW8sc2FyYXRvZ2Eucy10ZWFjaC5jb20sc21hcnRjYWJpbmV0LmFpeHAuaW8sc21hcnRjYWJpbmV0MzYwLmFpeHAuaW8sc3VubGlnaHRhY2FkZW15LmFpeHAuaW8sdmlxaXJvYm90LmFpeHAuaW87MT0yMDI1LTA0LTI0LDI9MjAyNS0wNC0yNCw0PTIwMjUtMDQtMjQ=',
    facebookAppId: "265756391918692",
    googleClientId: "808102167921-3o59q8j567n3cjq1v9vqcdianrflg5n0.apps.googleusercontent.com",
}

// saratoga.s-teach.com
/*
    production: false,
    mode: 'saratoga',
    appVersion: '4.07.001',
    appDate: '09/10/2024',
    apiUrl: 'https://saratoga.s-teach.com',
    supportsBidirectional: true,
    useNativePip4Student: false,
    loginBackgroundPicture: '../../assets/img/saratoga/saratoga_background.jpg',
    applicationName: 'EasyTeach Cloud',
    applicationNameShort: 'ETC',
    applicationMotto: 'An e-learning platform for dental schools with 4K video streaming',
    applicationFavicon: '../assets/img/saratoga/saratoga_icon.png',
    applicationCompanyName: 'Saratoga S.r.l.',
    applicationCompanyUrl: 'https://www.saratogadental.it/',
    applicationCompanyPhone: null,
    applicationCompanyLogo: '../assets/img/saratoga/saratoga_logo_dark.svg',
    applicationCompanyLogoWhite: '../assets/img/saratoga/saratoga_logo_light.svg',
    applicationPrivacyPolicy: 'https://www.saratogadental.it/en/privacy',
    applicationCookiePolicy: 'https://www.saratogadental.it/en/privacy#cookie',
    applicationPlayStoreUrl: null,
    applicationAppStoreUrl: null,
    mainPage: 'login',
    surveyJsKey: 'ODRhZTI2OWMtNjE5NC00MGMwLThjMjUtOThkYjgzODVhMDVlJmRvbWFpbnM6YWl4cC5pbyxkZW50YWx0cmV5ZWxlYXJuaW5nLmFpeHAuaW8sZGV2LnMtdGVhY2guY29tLGRldjIucy10ZWFjaC5jb20sZWFzeXRlYWNoLmNsb3VkLGZpdHhwLmlvLGdhbW1hZG9ubmEuYWl4cC5pbyxocm9jb25zdWx0aW5nLmFpeHAuaW8sc2FyYXRvZ2Eucy10ZWFjaC5jb20sc21hcnRjYWJpbmV0LmFpeHAuaW8sc21hcnRjYWJpbmV0MzYwLmFpeHAuaW8sc3VubGlnaHRhY2FkZW15LmFpeHAuaW8sdmlxaXJvYm90LmFpeHAuaW87MT0yMDI1LTA0LTI0LDI9MjAyNS0wNC0yNCw0PTIwMjUtMDQtMjQ=',
    facebookAppId: "",
    googleClientId: "",
*/

// aixp.io
/*
    production: false,
    mode: 'aixp',
    appVersion: '1.11.004',
    appDate: '12/11/2024',
    apiUrl: 'https://aixp.io',
    supportsBidirectional: true,
    useNativePip4Student: false,
    loginBackgroundPicture: '../../assets/img/aixp/aixp_background.jpg',
    applicationName: 'Aixp.io',
    applicationNameShort: 'AIXP',
    applicationMotto: 'The new learning experience',
    applicationFavicon: '../assets/img/aixp/aixp_icon.png',
    applicationCompanyName: 'Aixp.io',
    applicationCompanyUrl: 'https://aixp.io',
    applicationCompanyPhone: '+39 0434 1831007',
    applicationCompanyLogo: '../assets/img/aixp/aixp_logo_dark.svg',
    applicationCompanyLogoWhite: '../assets/img/aixp/aixp_logo_light.svg',
    applicationPrivacyPolicy: 'https://aixp.io/docs/Privacy_Policy_AIXP.html',
    applicationCookiePolicy: 'https://aixp.io/docs/Cookie_Policy_AIXP.html',
    applicationPlayStoreUrl: 'https://play.google.com/store/apps/details?id=io.aixp.mobile',
    applicationAppStoreUrl: 'https://apps.apple.com/us/app/aixp/id1639121952',
    mainPage: 'login',
    surveyJsKey: 'ODRhZTI2OWMtNjE5NC00MGMwLThjMjUtOThkYjgzODVhMDVlJmRvbWFpbnM6YWl4cC5pbyxkZW50YWx0cmV5ZWxlYXJuaW5nLmFpeHAuaW8sZGV2LnMtdGVhY2guY29tLGRldjIucy10ZWFjaC5jb20sZWFzeXRlYWNoLmNsb3VkLGZpdHhwLmlvLGdhbW1hZG9ubmEuYWl4cC5pbyxocm9jb25zdWx0aW5nLmFpeHAuaW8sc2FyYXRvZ2Eucy10ZWFjaC5jb20sc21hcnRjYWJpbmV0LmFpeHAuaW8sc21hcnRjYWJpbmV0MzYwLmFpeHAuaW8sc3VubGlnaHRhY2FkZW15LmFpeHAuaW8sdmlxaXJvYm90LmFpeHAuaW87MT0yMDI1LTA0LTI0LDI9MjAyNS0wNC0yNCw0PTIwMjUtMDQtMjQ=',
    facebookAppId: "",
    googleClientId: "",
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

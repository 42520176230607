import { Component, OnInit } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { ClassroomService } from 'src/app/services/classroom.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { ChartDataset, ChartOptions } from 'chart.js';
import { AttendanceDTO } from 'src/app/models/dto/attendanceDTO';
import { firstBy } from 'thenby';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Helper } from 'src/app/helpers/helper';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { StudentsStatus } from 'src/app/models/studentsStatus';

@Component({
  selector: 'app-classroom-status',
  templateUrl: './classroom-status.component.html',
  styleUrls: ['./classroom-status.component.scss']
})
export class ClassroomStatusComponent implements OnInit {

  currentUser: User;

  classroomId: number;
  classroomName: string;
  authorName: string;
  created: Date;

  classUsers: UserDTO[] = [];
  dataSource: StudentsStatus[] = [];

  lessonAverageDuration: string = '0';
  lessonAveragePlannedDuration: string = '0';
  lessonAverageAttendance: number = 0.00;
  lessonsAttendance: AttendanceDTO[] = [];
  durationSpinnerValue: number = 0;
  
  columnsToDisplay = ['name', 'surname', 'time', 'date', 'status', 'info'];

  lineChartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        fill: 'origin',
        tension: 0.4
      },
      point:{
        radius: 0,
        hitRadius: 0
      }
    },
    scales:{
      x: {
          display: false
      },
      y: {
        display: true,
        ticks: {
          stepSize: 50
        },
          max : 100,
          min: 0
        }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };
  public lineChartType = 'line';
  public lineChartData: ChartDataset[] = [{ data: [0], label: '%' }];
  public lineChartLabels: string[] = [];

  constructor(private auth: AuthService,
              private calendar: CalendarService,
              private classroomService: ClassroomService,
              private route: ActivatedRoute,
              private location: Location,
              private router: Router,
              public darkService: DarkThemeService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();

    this.classroomId = Number(this.route.snapshot.paramMap.get('id'));

    if (this.route.snapshot.queryParamMap.has('classroomName'))
      this.classroomName = this.route.snapshot.queryParamMap.get('classroomName');

    if (this.route.snapshot.queryParamMap.has('authorName'))
      this.authorName = this.route.snapshot.queryParamMap.get('authorName');

    if (this.route.snapshot.queryParamMap.has('created'))
      this.created = new Date(this.route.snapshot.queryParamMap.get('created'));

    this.getUsersByClassroom();
    this.getAttendanceClassroom();
  }

  getUsersByClassroom() {
    this.classroomService.getUsersOfClassroom(this.classroomId, 2)
      .subscribe(res => {

        this.classUsers = res;
        this.fetchOnlineStudents();

      });
  }

  setChartData() {
    let data: { value: number, date: Date }[] = [];

    this.lessonsAttendance.map(la => {

      if (la.actualDurationPercentage < 10)
        return;

      data.push({ value: la.attendancePercentage, date: new Date(la.startDate) });

    });

    this.lineChartLabels = data.map(d => `${d.date.getMonth() + 1}/${d.date.getFullYear()}`);
    this.lineChartData = [{ data: data.map(d => d.value), label: "%", borderColor: 'rgba(103, 58, 183, 1)', backgroundColor: 'rgba(103, 58, 183, 0.5)' }];
  }

  fetchOnlineStudents() {
    this.calendar.getStatusStudents(this.classroomId)
      .subscribe({
        next: output => {

          this.dataSource = this.classUsers.map(u => {

            let data = output.find(o => o.userId === u.id);

            if (data)
              return data;

            return <StudentsStatus>{
              id: 0,
              userId: u.id,
              startDate: undefined,
              endDate: undefined,
              airTimeConnection: undefined,
              lastCheck: undefined,
              physicalToken: undefined,
              stationId: undefined,
              timeInSeconds: 0,
              name: u.name,
              surname: u.surname,
              created: undefined
            };

          });

        },
        error: err => {
          this.dataSource = [];
          console.log(err);
        }
      });
  }

  convertToTime(seconds: number) {
    return Helper.convertToHMS(seconds * 1000);
  }

  goBack() {
    this.location.back();
  }

  getPercentage(actualTime: number, plannedTime: number) {
    if (actualTime < 1000)
        return 0;

    return (actualTime * 100) / plannedTime;
  }

  getAvgPercentage(attendaceTime: number, lessonTime: number) {
    return ((attendaceTime / this.dataSource.length) / lessonTime) * 100;
  }

  spinnerValue(value: number) {
    return `${value}%`;
  }

  userStats(id: number, name: string, surname: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
          name: name,
          surname: surname
      }
    };

    this.router.navigate(['/userstatus/', id], navigationExtras);
  }

  getTime(startDate: Date, endDate: Date) {
    var start = new Date(startDate);
    var end = new Date(endDate);

    if(end.getTime() - start.getTime() < 1000)
        return 0;

    return end.getTime() - start.getTime();
  }

  getAttendanceClassroom() {
    this.calendar.getClassroomAttendance(this.classroomId)
      .subscribe(output => {
        var attendancePercentage = 0;
        var actualDurationPercentage = 0;
        var plannedLessonTime = 0;
        var actualLessonTime = 0;

        this.lessonsAttendance = output;
        this.lessonsAttendance.sort(
          firstBy((a: AttendanceDTO) => new Date(a.startDate).getFullYear(), 1)
          .thenBy((a: AttendanceDTO) => new Date(a.startDate).getMonth(), 1));

        output.forEach(l => {
          attendancePercentage += l.attendancePercentage;
          actualDurationPercentage += l.actualDurationPercentage;
          plannedLessonTime += this.getTime(l.startPlanned, l.endPlanned);
          actualLessonTime += this.getTime(l.startDate, l.stopDate);
        });

        this.lessonAverageAttendance = Number((attendancePercentage / output.length).toFixed(2));
        if(!this.lessonAverageAttendance)
          this.lessonAverageAttendance = 0;

        this.durationSpinnerValue = Math.round((actualLessonTime / plannedLessonTime) * 10000) / 100;
        this.lessonAverageDuration = Helper.convertToHMS(actualLessonTime / output.length);
        this.lessonAveragePlannedDuration = Helper.convertToHMS(plannedLessonTime / output.length);

        this.setChartData();
      });
  }
}

<h1 mat-dialog-title>{{ 'Add Subscription' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>NameKey</mat-label>
            <input matInput type="text" [formControl]="nameKey" required>
            <mat-error *ngIf="nameKey.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="nameKey.hasError('minlength')">
                {{ 'Name should be at least 2 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput type="text" [formControl]="name" required>
            <mat-error *ngIf="name.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
                {{ 'Name should be at least 2 characters' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <textarea matInput [formControl]="description" style="height: 100px;"></textarea>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Summary' | translate }}</mat-label>
            <textarea matInput [formControl]="summary" style="height: 100px;"></textarea>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Header' | translate }}</mat-label>
            <input matInput type="text" [formControl]="header">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Footer' | translate }}</mat-label>
            <input matInput type="text" [formControl]="footer">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Users' | translate }}</mat-label>
            <input matInput type="number" [formControl]="maxUsers">
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Storage size' | translate }}</mat-label>
            <input matInput type="number" [formControl]="maxStorage">
        </mat-form-field>
    </div>
    <div>
        <mat-label>{{ 'Media' | translate }}</mat-label>
        <app-file-input
            [control]="imageSrc"
            [maxSize]="2000"
            [acceptedTypes]="'image/*'"
            [aspectRatio]="'8:5'"
            [maxHeight]="2000"
            [maxWidth]="2000"
            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '8:5', height: '2000px', width: '2000px', size: '2MB' }"
            [deletedErrorMessage]="'Please select a file' | translate"
            style="width: 100%">
        </app-file-input>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()" [disabled]="sending">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent"
        (click)="saveSubscriptionLevel()"
        [disabled]="disabledOkBtn()">
        Ok
    </button>
</div>
<div *ngIf="sending" class="cssload-container" style="top: 50%">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>

<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="start start">
        <h1>{{ 'Welcome to' | translate }} {{ appName }}</h1>
    </div>

    <app-showcase-header *ngIf="!isAuthenticated(); else entitiesShowcase"></app-showcase-header>
</div>

<ng-template #entitiesShowcase>

    <div fxLayout="column" fxLayoutGap="40px">
        <!--div *ngIf="shownConferences?.length > 0">
            <div fxLayout="row" fxLayoutAlign="center">
                <h2>{{ (isDT() ? 'Incoming live courses' : 'Incoming webinar') | translate }}</h2>
                <div fxFlex></div>
                <div fxLayoutAlign="center center">
                    <button mat-flat-button color="primary" [routerLink]="['/webinars']" >
                        {{ 'See all' | translate }}
                    </button>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid" style="height:480px;overflow:hidden;padding-left:5px;">
                <app-showcase-card *ngFor="let conference of shownConferences"
                    [routeUrl]="'/webinar-showcase'"
                    [routeId]="conference.id"
                    [queryParams]="{ page: 'showcase' }"
                    [routeMessage]="checkIfPaidWebinarIsOnline(conference) ? ('Join webinar' | translate) : ('Go to webinar' | translate)"
                    [style]="border(conference)"
                    [profileId]="getFirstPresenterId(conference)"
                    [moderatorId]="getModeratorId(conference)"
                    [presenters]="getPresenters(conference)"
                    [subtitle]="conference.lessonSession.startPlanned | date: 'dd/MM/yyyy HH:mm'"
                    [imgUrl]="conference.imageUrl"
                    [title]="conference.name"
                    [description]="conference.header"
                    [author]="conference.conferencePresenter[0].presenter.name + ' ' + conference.conferencePresenter[0].presenter.surname"
                    [multiplePresenters]="conference.conferencePresenter.length > 2"
                    [moderator]="getModerator(conference)"
                    [subscription]="conference.subscription"
                    style="margin-top: 10px; margin-right: 10px;">
                </app-showcase-card>
            </div>
        </div-->

        <div *ngIf="shownConferences?.length > 0">
            <div fxLayout="row" fxLayoutAlign="center">
                <h2>{{ (isDT() ? 'Incoming live courses' : 'Incoming webinar') | translate }}</h2>
                <div fxFlex></div>
                <div fxLayoutAlign="center center">
                    <button mat-flat-button color="primary" [routerLink]="['/webinars']" >
                        {{ 'See all' | translate }}
                    </button>
                </div>
            </div>

            <app-showcase-card-info *ngIf="selectedConferenceIndex != undefined"
                [routeUrl]="'/webinar-showcase'"
                [routeId]="shownConferences[selectedConferenceIndex].id"
                [queryParams]="{ page: 'showcase' }"
                [routeMessage]="checkIfPaidWebinarIsOnline(shownConferences[selectedConferenceIndex]) ? 'Join webinar' : 'See more'"
                [title]="shownConferences[selectedConferenceIndex].name"
                [subtitle]="shownConferences[selectedConferenceIndex].startPlanned | date: 'dd/MM/yyyy HH:mm'"
                [description]="shownConferences[selectedConferenceIndex].description"
                [imageUrl]="shownConferences[selectedConferenceIndex].imageUrl"
                [isMobile]="isMobile"
                (onClose)="selectedConferenceIndex = undefined"
                id="selectedConferenceInfo">

                <app-showcase-card-author-info leftBottom
                    [author]="getModerator(shownConferences[selectedConferenceIndex])"
                    [users]="getPresenters(shownConferences[selectedConferenceIndex])"
                    mode="webinar">
                </app-showcase-card-author-info>

            </app-showcase-card-info>

            <div class="carousel">
                <app-carousel-prev-button
                    [(index)]="shownConferencesIndex"
                    (indexChange)="positionCard($event, 'conferencesCarousel')"
                    marginLeft="19px">
                </app-carousel-prev-button>
                <div id="conferencesCarousel" class="carousel-inner">
                    <app-showcase-card *ngFor="let conference of shownConferences; let i = index"
                        [routeUrl]="'/webinar-showcase'"
                        [routeId]="conference.id"
                        [queryParams]="{ page: 'showcase' }"
                        [routeMessage]="checkIfPaidWebinarIsOnline(conference) ? 'Join webinar' : 'Go to webinar'"
                        [style]="border(conference)"
                        [subtitle]="conference.lessonSession.startPlanned | date: 'dd/MM/yyyy HH:mm'"
                        [imgUrl]="conference.imageUrl"
                        [title]="conference.name"
                        [description]="conference.header"
                        [subscription]="conference.subscription"
                        [compact]="compactMode"
                        [enableHighlight]="compactMode"
                        [isHighlighted]="selectedConferenceIndex === i"
                        [clickAsGoTo]="!compactMode"
                        (onClick)="selectElement(i, 'conference')"
                        [class.carousel-hide]="i >= (shownConferencesIndex + pageSize)"
                        style="margin-top: 10px; margin-right: 10px;">

                        <app-showcase-card-author-info bodyBottom
                            [author]="getModerator(conference)"
                            [users]="getPresenters(conference)"
                            mode="webinar"
                            padding="0 16px">
                        </app-showcase-card-author-info>

                    </app-showcase-card>
                </div>
                <app-carousel-next-button
                    [(index)]="shownConferencesIndex"
                    [items]="shownConferences"
                    [pageSize]="pageSize"
                    (indexChange)="positionCard($event, 'conferencesCarousel')"
                    marginRight="19px">
                </app-carousel-next-button>
            </div>
        </div>

        <!--div *ngIf="shownPackages?.length > 0">
            <div fxLayout="row" fxLayoutAlign="center">
                <h2>{{ 'Available packages' | translate }}</h2>
                <div fxFlex></div>
                <div fxLayoutAlign="center center">
                    <button mat-flat-button color="primary" [routerLink]="['/packages']" [disabled]="true">
                        {{ 'See all' | translate }}
                    </button>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid" style="height: 480px; overflow: hidden;padding-left:5px;">
                <app-showcase-card *ngFor="let package of shownPackages"
                    [routeUrl]="'/package'"
                    [routeId]="package.id"
                    [queryParams]="{ page: 'showcase' }"
                    [routeMessage]="'Go to package'"
                    [profileId]="package.idAuthor"
                    [imgUrl]="package.imageUrl"
                    [title]="package.name"
                    [subtitle]="package.hashTags"
                    [description]="package.description"
                    [author]="package.author.name + ' ' + package.author.surname"
                    [subscription]="package.subscription"
                    style="margin-top: 10px; margin-right: 10px;">
                </app-showcase-card>
        
                <app-error-message customClass="warning"
                    *ngIf="!shownPackages || shownPackages.length === 0"
                    [errorTitle]="'There are no packages' | translate"
                    [errorContent]="'Currently there are none to show' | translate">
                </app-error-message>
            </div>
        </div-->

        <div *ngIf="shownPackages?.length > 0">
            <div fxLayout="row" fxLayoutAlign="center">
                <h2>{{ 'Available packages' | translate }}</h2>
                <div fxFlex></div>
                <!--div fxLayoutAlign="center center">
                    <button mat-flat-button color="primary" [routerLink]="['/packages']" [disabled]="true">
                        {{ 'See all' | translate }}
                    </button>
                </div-->
            </div>

            <app-showcase-card-info *ngIf="selectedPackageIndex != undefined"
                [routeUrl]="'/package'"
                [routeId]="shownPackages[selectedPackageIndex].id"
                [queryParams]="{ page: 'showcase' }"
                [routeMessage]="'See more'"
                [title]="shownPackages[selectedPackageIndex].name"
                [description]="shownPackages[selectedPackageIndex].description"
                [imageUrl]="shownPackages[selectedPackageIndex].imageUrl"
                [isMobile]="isMobile"
                (onClose)="selectedPackageIndex = undefined"
                id="selectedPackageInfo">

                <app-showcase-card-author-info leftBottom
                    [author]="shownPackages[selectedPackageIndex].author"
                    mode="author">
                </app-showcase-card-author-info>

            </app-showcase-card-info>

            <div class="carousel">
                <app-carousel-prev-button
                    [(index)]="shownPackagesIndex"
                    (indexChange)="positionCard($event, 'packagesCarousel')"
                    marginLeft="19px">
                </app-carousel-prev-button>
                <div id="packagesCarousel" class="carousel-inner">
                    <app-showcase-card *ngFor="let package of shownPackages; let i = index"
                        [routeUrl]="'/package'"
                        [routeId]="package.id"
                        [queryParams]="{ page: 'showcase' }"
                        [routeMessage]="'Go to package'"
                        [imgUrl]="package.imageUrl"
                        [title]="package.name"
                        [subtitle]="package.hashTags"
                        [description]="package.description"
                        [subscription]="package.subscription"
                        [compact]="compactMode"
                        [enableHighlight]="compactMode"
                        [isHighlighted]="selectedPackageIndex === i"
                        [clickAsGoTo]="!compactMode"
                        (onClick)="selectElement(i, 'package')"
                        [class.carousel-hide]="i >= (shownPackagesIndex + pageSize)"
                        style="margin-top: 10px; margin-right: 10px;">

                        <app-showcase-card-author-info bodyBottom
                            [author]="package.author"
                            mode="author"
                            padding="0 16px">
                        </app-showcase-card-author-info>

                    </app-showcase-card>
            
                    <app-error-message customClass="warning"
                        *ngIf="!shownPackages || shownPackages.length === 0"
                        [errorTitle]="'There are no packages' | translate"
                        [errorContent]="'Currently there are none to show' | translate">
                    </app-error-message>
                </div>
                <app-carousel-next-button
                    [(index)]="shownPackagesIndex"
                    [items]="shownPackages"
                    [pageSize]="pageSize"
                    (indexChange)="positionCard($event, 'packagesCarousel')"
                    marginRight="19px">
                </app-carousel-next-button>
            </div>
        </div>
        
        <!--div>
            <div fxLayout="row" fxLayoutAlign="center">
                <h2>{{ 'Available courses' | translate }}</h2>
                <div fxFlex></div>
                <div fxLayoutAlign="center center">
                    <button mat-flat-button color="primary" [routerLink]="['/courses']">
                        {{ 'See all' | translate }}
                    </button>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid" style="height: 480px; overflow: hidden;padding-left:5px;">
                <app-showcase-card *ngFor="let course of shownCourses"
                    [routeUrl]="'/course'"
                    [routeId]="course.id"
                    [queryParams]="{ page: 'showcase' }"
                    [routeMessage]="'Go to course'"
                    [profileId]="course.idAuthor"
                    [imgUrl]="course.imageUrl"
                    [title]="course.name"
                    [speaker]="course.header"
                    [subtitle]="course.created | date: 'dd/MM/yyyy'"
                    [description]="course.description"
                    [author]="course.author.name + ' ' + course.author.surname"
                    [subscription]="course.subscription"
                    [enableSelectionOn]="getSelectionEnabledMode()"
                    [selectionEntity]="getSelectionEntity(course, getPackageContentType().course)"
                    style="margin-top: 10px; margin-right: 10px;">
                </app-showcase-card>
        
                <app-error-message customClass="warning"
                    *ngIf="!shownCourses || shownCourses.length === 0"
                    [errorTitle]="'There are no courses' | translate"
                    [errorContent]="'Currently there are none to show' | translate">
                </app-error-message>
            </div>
        </div-->

        <div>
            <div fxLayout="row" fxLayoutAlign="center">
                <h2>{{ 'Available courses' | translate }}</h2>
                <div fxFlex></div>
                <div fxLayoutAlign="center center">
                    <button mat-flat-button color="primary" [routerLink]="['/courses']">
                        {{ 'See all' | translate }}
                    </button>
                </div>
            </div>

            <app-showcase-card-info *ngIf="selectedCourseIndex != undefined"
                [routeUrl]="'/course'"
                [routeId]="shownCourses[selectedCourseIndex].id"
                [queryParams]="{ page: 'showcase' }"
                [routeMessage]="'See more'"
                [title]="shownCourses[selectedCourseIndex].name"
                [description]="shownCourses[selectedCourseIndex].description"
                [imageUrl]="shownCourses[selectedCourseIndex].imageUrl"
                [isMobile]="isMobile"
                (onClose)="selectedCourseIndex = undefined"
                id="selectedCourseInfo">

                <app-showcase-card-author-info leftBottom
                    [author]="shownCourses[selectedCourseIndex].author"
                    mode="author">
                </app-showcase-card-author-info>

            </app-showcase-card-info>

            <div class="carousel">
                <app-carousel-prev-button
                    [(index)]="shownCoursesIndex"
                    (indexChange)="positionCard($event, 'coursesCarousel')"
                    marginLeft="19px">
                </app-carousel-prev-button>
                <div id="coursesCarousel" class="carousel-inner">
                    <app-showcase-card *ngFor="let course of shownCourses; let i = index"
                        [routeUrl]="'/course'"
                        [routeId]="course.id"
                        [queryParams]="{ page: 'showcase' }"
                        [routeMessage]="'Go to course'"
                        [imgUrl]="course.imageUrl"
                        [title]="course.name"
                        [subtitle_extra]="course.header"
                        [subtitle]="course.created | date: 'dd/MM/yyyy'"
                        [description]="course.description"
                        [subscription]="course.subscription"
                        [enableSelectionOn]="getSelectionEnabledMode()"
                        [selectionEntity]="getSelectionEntity(course, getPackageContentType().course)"
                        [compact]="compactMode"
                        [enableHighlight]="compactMode"
                        [isHighlighted]="selectedCourseIndex === i"
                        [clickAsGoTo]="!compactMode"
                        (onClick)="selectElement(i, 'course')"
                        [class.carousel-hide]="i >= (shownCoursesIndex + pageSize)"
                        style="margin-top: 10px; margin-right: 10px;">

                        <app-showcase-card-author-info bodyBottom
                            [author]="course.author"
                            mode="author"
                            padding="0 16px">
                        </app-showcase-card-author-info>

                    </app-showcase-card>
            
                    <app-error-message customClass="warning"
                        *ngIf="!shownCourses || shownCourses.length === 0"
                        [errorTitle]="'There are no courses' | translate"
                        [errorContent]="'Currently there are none to show' | translate">
                    </app-error-message>
                </div>
                <app-carousel-next-button
                    [(index)]="shownCoursesIndex"
                    [items]="shownCourses"
                    [pageSize]="pageSize"
                    (indexChange)="positionCard($event, 'coursesCarousel')"
                    marginRight="19px">
                </app-carousel-next-button>
            </div>
        </div>

        <!--div *ngIf="shownMasters.length > 0">
            <div fxLayout="row" fxLayoutAlign="center">
                <h2>{{ 'Available masters' | translate }}</h2>
                <div fxFlex></div>
                <div fxLayoutAlign="center center">
                    <button mat-flat-button color="primary" [routerLink]="['/masters']">
                        {{ 'See all' | translate }}
                    </button>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid" style="height: 480px; overflow: hidden;padding-left:5px;">
                <app-showcase-card *ngFor="let master of shownMasters"
                    [routeUrl]="'/master'"
                    [routeId]="master.id"
                    [queryParams]="{ page: 'showcase' }"
                    [routeMessage]="'Go to master'"
                    [profileId]="master.idAuthor"
                    [imgUrl]="master.imageUrl"
                    [topLeft]="getMasterState().getState(master.state)"
                    [title]="master.name"
                    [speaker]="master.header"
                    [subtitle]="master.startDate && master.stopDate ? ((master.startDate | date: 'dd/MM/yyyy') + ' - ' + (master.stopDate | date: 'dd/MM/yyyy')) : ''"
                    [description]="master.description"
                    [author]="master.author.name + ' ' + master.author.surname"
                    [subscription]="master.subscription"
                    [enableSelectionOn]="getSelectionEnabledMode()"
                    [selectionEntity]="getSelectionEntity(master, getPackageContentType().master)"
                    style="margin-top: 10px; margin-right: 10px;">
                </app-showcase-card>
        
                <app-error-message customClass="warning"
                    *ngIf="!shownMasters || shownMasters.length === 0"
                    [errorTitle]="'There are no masters' | translate"
                    [errorContent]="'Currently there are none to show' | translate">
                </app-error-message>
            </div>
        </div-->

        <div *ngIf="shownMasters.length > 0">
            <div fxLayout="row" fxLayoutAlign="center">
                <h2>{{ 'Available masters' | translate }}</h2>
                <div fxFlex></div>
                <div fxLayoutAlign="center center">
                    <button mat-flat-button color="primary" [routerLink]="['/masters']">
                        {{ 'See all' | translate }}
                    </button>
                </div>
            </div>

            <app-showcase-card-info *ngIf="selectedMasterIndex != undefined"
                [routeUrl]="'/master'"
                [routeId]="shownMasters[selectedMasterIndex].id"
                [queryParams]="{ page: 'showcase' }"
                [routeMessage]="'See more'"
                [title]="shownMasters[selectedMasterIndex].name"
                [description]="shownMasters[selectedMasterIndex].description"
                [imageUrl]="shownMasters[selectedMasterIndex].imageUrl"
                [isMobile]="isMobile"
                (onClose)="selectedMasterIndex = undefined"
                id="selectedMasterInfo">

                <app-showcase-card-author-info leftBottom
                    [author]="shownMasters[selectedMasterIndex].author"
                    mode="author">
                </app-showcase-card-author-info>

            </app-showcase-card-info>

            <div class="carousel">
                <app-carousel-prev-button
                    [(index)]="shownMastersIndex"
                    (indexChange)="positionCard($event, 'mastersCarousel')"
                    marginLeft="19px">
                </app-carousel-prev-button>
                <div id="mastersCarousel" class="carousel-inner">
                    <app-showcase-card *ngFor="let master of shownMasters; let i = index"
                        [routeUrl]="'/master'"
                        [routeId]="master.id"
                        [queryParams]="{ page: 'showcase' }"
                        [routeMessage]="'Go to master'"
                        [imgUrl]="master.imageUrl"
                        [topLeft]="getMasterState().getState(master.state)"
                        [title]="master.name"
                        [subtitle_extra]="master.header"
                        [subtitle]="master.startDate && master.stopDate ? ((master.startDate | date: 'dd/MM/yyyy') + ' - ' + (master.stopDate | date: 'dd/MM/yyyy')) : ''"
                        [description]="master.description"
                        [subscription]="master.subscription"
                        [enableSelectionOn]="getSelectionEnabledMode()"
                        [selectionEntity]="getSelectionEntity(master, getPackageContentType().master)"
                        [compact]="compactMode"
                        [enableHighlight]="compactMode"
                        [isHighlighted]="selectedMasterIndex === i"
                        [clickAsGoTo]="!compactMode"
                        (onClick)="selectElement(i, 'master')"
                        [class.carousel-hide]="i >= (shownMastersIndex + pageSize)"
                        style="margin-top: 10px; margin-right: 10px;">

                        <app-showcase-card-author-info bodyBottom
                            [author]="master.author"
                            mode="author"
                            padding="0 16px">
                        </app-showcase-card-author-info>
                        
                    </app-showcase-card>
            
                    <app-error-message customClass="warning"
                        *ngIf="!shownMasters || shownMasters.length === 0"
                        [errorTitle]="'There are no masters' | translate"
                        [errorContent]="'Currently there are none to show' | translate">
                    </app-error-message>
                </div>
                <app-carousel-next-button
                    [(index)]="shownMastersIndex"
                    [items]="shownMasters"
                    [pageSize]="pageSize"
                    (indexChange)="positionCard($event, 'mastersCarousel')"
                    marginRight="19px">
                </app-carousel-next-button>
            </div>
        </div>
    </div>

</ng-template>

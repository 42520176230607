<div class="component-main" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start start">
    <h1>{{ 'Welcome to' | translate }} {{ appName }}</h1>
  </div>
  <div fxLayout="column" fxLayoutGap="40px">
    <ng-container *ngFor="let plan of eduPlans"
      [ngTemplateOutlet]="planData"
      [ngTemplateOutletContext]="{ plan: plan }">
    </ng-container>
  </div>
</div>

<ng-template #planData let-currentPlan="plan">
  <div fxLayout="row" fxLayoutGap="20px">
    <mat-card appearance="outlined" class="mat-elevation-z8" fxFlex="60">
      <mat-card-header>
        <mat-card-title>{{ currentPlan.name }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="currentPlan.eduContents" style="width: 100%;">
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>{{ 'Title' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ getTitle(element) }}</td>
          </ng-container>
      
          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>{{ 'Status' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon [style.color]="getStatus(element)" style="font-size: xx-large">
                fiber_manual_record
              </mat-icon>
            </td>
          </ng-container>
      
          <ng-container matColumnDef="expirationDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'Expiration' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.expirationDate | date: 'dd/MM/yyyy' }}</td>
          </ng-container>
      
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button (click)="goTo(element)" color="accent">
                <mat-icon style="margin-right: 5px;">launch</mat-icon>
                {{ getActionBtn(element) | translate }}
              </button>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
    
    <div fxLayout="column" fxLayoutGap="20px" fxFlex="40">
      <mat-card appearance="outlined" class="mat-elevation-z8" fxFlex="70">
        <mat-card-header>
          <mat-card-title>{{ 'You have completed % of the educational plan' | translate: {value: currentPlan.donePerc} }}</mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayoutAlign="center center">
          <div style="height: 300px; width: 375px; display: block;">
            <canvas baseChart style="margin-top: -50px"
              [options]="chartOptions"
              [type]="chartType"
              [datasets]="currentPlan.chartData"
              [labels]="currentPlan.chartLabels">
            </canvas>
          </div>
        </mat-card-content>
      </mat-card>
      <div fxLayout="row" fxLayoutGap="20px" fxFlex>
        <!--mat-card class="mat-elevation-z8" fxFlex>
          <mat-card-header>
            <mat-card-title>{{ 'Request assistance' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content fxLayoutAlign="start end">
            <button mat-flat-button color="accent">
              <mat-icon style="margin-right: 5px;">help_outline</mat-icon>
              {{ 'Request assistance' | translate }}
            </button>
          </mat-card-content>
        </mat-card-->
        <mat-card appearance="outlined" class="mat-elevation-z8" fxFlex>
          <mat-card-header>
            <mat-card-title>{{ 'See more' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content fxLayoutAlign="start end" fxLayoutGap="10px">
            <button mat-flat-button color="accent" [routerLink]="['/courses']">
              <mat-icon style="margin-right: 5px;">launch</mat-icon>
              {{ 'Training Courses' | translate }}
            </button>
            <button mat-flat-button color="accent" [routerLink]="['/masters']">
              <mat-icon style="margin-right: 5px;">launch</mat-icon>
              {{ 'Live Digital Academy' | translate }}
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</ng-template>

<mat-form-field
        (mouseenter)="onEditOver()"
        (mouseleave)="showEdit = false"
        [matTooltip]="'Click to edit' | translate"
        color="primary"
        class="full-width">
        <mat-label>{{ label | translate }}</mat-label>
        <input matInput 
                [(ngModel)]="newValue"
                [readonly]="!editing"
                (click)="onClick($event)"
                (keyup.enter)="save()"/>
        <button mat-icon-button matSuffix color="accent" *ngIf="showEdit">
                <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matSuffix color="accent" *ngIf="editing" (click)="save()">
                <mat-icon>save</mat-icon>
        </button>
</mat-form-field>

export abstract class MasterState {
    public static deleted: number = 0;
    public static offline: number = 1;
    public static online: number = 2;
    public static planned: number = 3;
    public static running: number = 4;
    public static finished: number = 5;

    /**
     * @param state Stato del master
     * @returns Restituisce lo stato del master in stringa, utile per le traduzioni
     */
    public static getState(state: number): string {
        if(state === this.deleted)
            return 'Deleted';
        else if(state === this.offline)
            return 'Offline';
        else if(state === this.online)
            return 'Available for booking';
        else if(state === this.planned)
            return 'On sale';
        else if(state === this.running)
            return 'Started';
        else if(state === this.finished)
            return 'Finished';
    }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-course-popup',
  templateUrl: './course-popup.component.html',
  styleUrls: ['./course-popup.component.scss']
})
export class CoursePopupComponent implements OnInit {

  courseName: string = "";
  type: string = "";

  constructor(public dialogRef: MatDialogRef<CoursePopupComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.type = this.data.type;

    this.courseName = this.data.name;
  }

  closePopUp() {
    this.dialogRef.close(true);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }
}

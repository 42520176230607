import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { differenceInSeconds } from 'date-fns';
import { Helper } from 'src/app/helpers/helper';
import { JoinedLessonDTO } from 'src/app/models/dto/joinedLessonDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { CsvExporterService } from 'src/app/services/csv-exporter.service';

@Component({
  selector: 'app-joinedlesson-table',
  templateUrl: './joinedlesson-table.component.html',
  styleUrls: ['./joinedlesson-table.component.scss']
})
export class JoinedlessonTableComponent implements OnInit {

  @Input()
  lessonId: number = undefined;

  @Input()
  hashTagsToExport: string[] = [];

  @Input()
  set refresh(value: any) {
    this.ngOnInit();
  }

  @Output()
  joinedLessons: EventEmitter<JoinedLessonDTO[]> = new EventEmitter<JoinedLessonDTO[]>();

  @ViewChild('sessionsSort') sessionsSort: MatSort;

  sessionsSource: MatTableDataSource<JoinedLessonDTO> = new MatTableDataSource<JoinedLessonDTO>();

  sessionsColumns: string[] = ['name', 'surname', 'email', 'startDate', 'stopDate', 'timeInLessons', 'room', 'station'];

  constructor(private calendar: CalendarService,
              private exporter: CsvExporterService) { }

  ngOnInit(): void {
    this.calendar.getJoinedLessons(this.lessonId, true)
      .subscribe({
        next: async output => {

          this.sessionsSource.data = output;

          this.sessionsSource.sortingDataAccessor = (element, property) => {
            switch (property) {
              case 'name':
                return element.user.name;
              case 'surname':
                return element.user.surname;
              case 'email':
                return element.user.email;
              case 'station':
                return element.station?.name;
              case 'room':
                return element.station?.room.name;
              default: 
                return element[property];
            }
          };

          this.sessionsSource.sort = this.sessionsSort;

          this.joinedLessons.emit(output);

        }, error: err => {
          this.sessionsSource.data = [];
          console.error(err);
        }
    });
  }

  getSessionsParticipants() {
    let unique = this.sessionsSource.data
      .map(p => p.userId)
      .filter((value, index, self) => self.indexOf(value) === index);

    return unique.length;
  }

  getPercentage(startDate: Date, endDate: Date, time: string) {
    let lessonTime = differenceInSeconds(startDate, endDate);
    let studentTime = Helper.timeFromTimeSpan(time, 'seconds');

    if (lessonTime === 0 || studentTime === 0)
      return 0;

    return (studentTime * 100) / lessonTime;
  }

  saveToCsv() {
    this.calendar.getLesson(this.lessonId)
      .subscribe(lesson => {
          if (!lesson)
            return;
            
          this.exporter.exportJoinedLessonsToCsv(
          [
            {
              data: this.sessionsSource.data,
              title: lesson.name,
              author: `${lesson.teacher.name} ${lesson.teacher.surname}`,
              hashTags: this.hashTagsToExport
            }
          ]);
      });
  }

}

<h1 mat-dialog-title>{{ title | translate }} {{ type !== 'folder' && !contentToEdit ? ('(' + (typeTitle | translate) + ')') : '' }}</h1>
<div mat-dialog-content>

    <mat-stepper #stepper (selectedIndexChange)="stepperIndex = $event">

        <mat-step>

            <div class="stepper-gap">

                <app-translations-form *ngIf="type !== 'generic'"
                    [label]="'Name' | translate"
                    [disabled]="sending"
                    [(translation)]="nameTranslation">
            
                    <mat-form-field color="primary" currentLanguageForm>
                        <mat-label>{{ 'Name' | translate }}</mat-label>
                        <input matInput type="text" [formControl]="name">
                        <mat-error *ngIf="name.hasError('required')">
                            {{ 'Name is required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="name.hasError('minlength')">
                            {{ 'Name should be at least 4 characters' | translate }}
                        </mat-error>
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>
            
                </app-translations-form>

                <app-translations-form
                    [label]="'Description' | translate"
                    [disabled]="sending"
                    [mode]="'textarea'"
                    [(translation)]="descriptionTranslation">
            
                    <mat-form-field color="primary" currentLanguageForm>
                        <mat-label>{{ 'Description' | translate }}</mat-label>
                        <textarea matInput [formControl]="description" style="height: 100px"></textarea>
                        <mat-icon [matTooltip]="'You are currently editing the text of the default language' | translate: { value: currentUser.defaultLanguageDescription }" matSuffix>
                            info
                        </mat-icon>
                    </mat-form-field>
            
                </app-translations-form>

                <mat-form-field *ngIf="data.mode === 1" color="primary">
                    <mat-label>{{ 'Sequence' | translate }}</mat-label>
                    <input matInput type="number" [formControl]="order" [min]="1">
                    <mat-error *ngIf="order.hasError('required')">
                        {{ 'It' | translate }} <strong>{{ 'is mandatory' | translate }}</strong> {{ 'to select a value' | translate }}
                    </mat-error>
                </mat-form-field>

                <!--mat-form-field *ngIf="data.mode === 0" color="primary">
                    <mat-label>{{ 'Priority' | translate }}</mat-label>
                    <mat-select [formControl]="order">
                        <mat-option [value]="contentToEdit.orderIndex" *ngIf="customOrder">{{ 'Custom priority' | translate }}</mat-option>
                        <mat-option [value]="1">{{ 'Very High' | translate }}</mat-option>
                        <mat-option [value]="2">{{ 'High' | translate }}</mat-option>
                        <mat-option [value]="3">{{ 'Medium' | translate }}</mat-option>
                        <mat-option [value]="4">{{ 'Low' | translate }}</mat-option>
                        <mat-option [value]="5">{{ 'Very low' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="order.hasError('required')">
                        {{ 'It' | translate }} <strong>{{ 'is mandatory' | translate }}</strong> {{ 'to select a value' | translate }}
                    </mat-error>
                </mat-form-field-->

                <div *ngIf="type !== 'folder' && type !== 'generic'" fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 20px">
                    <div fxFlex>
                        <mat-checkbox [formControl]="download">{{ 'Downloadable' | translate }}</mat-checkbox>
                    </div>
                    <!--div *ngIf="currentType === 1" fxFlex> SCOMMENTARE PER ABILITAZIONE SOTTOTITOLI
                        <mat-checkbox [(ngModel)]="subtitles">{{ 'Generate subtitles' | translate }}</mat-checkbox>
                    </div-->
                </div>

                <div *ngIf="subtitles" fxLayout="row" fxLayoutAlign="center center">
                    <mat-form-field color="primary">
                        <mat-label>{{ 'Media language' | translate }}</mat-label>
                        <mat-select [formControl]="mediaLanguage">
                            <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!--mat-form-field color="primary">
                        <mat-label>{{ 'Available media subtitles' | translate }}</mat-label>
                        <mat-select [formControl]="subtitlesLanguages">
                            <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.label | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field-->
                </div>

            </div>

        </mat-step>

        <mat-step *ngIf="type !== 'folder'">

            <div class="stepper-gap">

                <div style="margin-bottom: 20px">
                    <mat-label>{{ typeTitle | translate }}</mat-label>
                    <app-file-input
                        [control]="mediaSrc"
                        [maxSize]="5000000"
                        [acceptedTypes]="getAcceptedFiles()"
                        [rejectedErrorMessage]="('The file type must be a value and not exceed' | translate: { value: type }) + ' 5GB'"
                        [deletedErrorMessage]="'Please select a file' | translate"
                        (acceptedFile)="mediaAccept(); setPicturePreview()"
                        (deletedFile)="mediaDelete(); setPicturePreview()"
                        style="width: 100%">
                    </app-file-input>
                </div>

            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div fxFlex>
                        <mat-label>{{ 'Thumbnail' | translate }}</mat-label>
                        <app-file-input
                            [control]="thumbnailSrc"
                            [maxSize]="2000"
                            [acceptedTypes]="'image/*'"
                            [aspectRatio]="8/5"
                            [maxHeight]="2000"
                            [maxWidth]="2000"
                            [enableCrop]="true"
                            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '8:5', height: '2000px', width: '2000px', size: '2MB' }"
                            [deletedErrorMessage]="'Please select a file' | translate"
                            (acceptedFile)="setPicturePreview()"
                            (deletedFile)="setPicturePreview()"
                            (croppingFile)="$event ? desktopPreview = undefined : setPicturePreview()"
                            style="width: 100%">
                        </app-file-input>
                    </div>
                    <app-picture-preview
                        *ngIf="desktopPreview"
                        [src]="desktopPreview"
                        [tooltip]="'Current picture' | translate"
                        mode="desktop"
                        fxFlex="50">
                    </app-picture-preview>
                </div>

            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div fxFlex>
                        <mat-label>{{ 'Mobile app picture' | translate }}</mat-label>
                        <app-file-input
                            [control]="mobileImageSrc"
                            [maxSize]="1000"
                            [acceptedTypes]="'image/*'"
                            [aspectRatio]="1/1"
                            [maxHeight]="800"
                            [maxWidth]="800"
                            [enableCrop]="true"
                            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '1:1', height: '800px', width: '800px', size: '1MB' }"
                            [deletedErrorMessage]="'Please select a file' | translate"
                            (acceptedFile)="setPicturePreview()"
                            (deletedFile)="setPicturePreview()"
                            (croppingFile)="$event ? mobilePreview = undefined : setPicturePreview()"
                            style="width: 100%">
                        </app-file-input>
                    </div>
                    <app-picture-preview
                        *ngIf="mobilePreview"
                        [src]="mobilePreview"
                        [tooltip]="'Current mobile app picture' | translate"
                        mode="mobile"
                        fxFlex="50">
                    </app-picture-preview>
                </div>

            </div>

        </mat-step>

    </mat-stepper>

</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
    <button mat-stroked-button (click)="onNoClick()" [disabled]="sending">{{ 'Cancel' | translate }}</button>

    <div fxLayout="row">
        <button mat-stroked-button
            *ngIf="stepperIndex !== 0"
            (click)="stepper.previous()">
            {{ 'Back' | translate }}
        </button>
        <button mat-flat-button color="primary"
            *ngIf="stepperIndex !== (this.type === 'folder' ? 2 : 3)"
            [disabled]="nextBtnDisabled()"
            (click)="stepper.next()">
            {{ 'Next' | translate }}
        </button>
        <button mat-flat-button color="accent"
            *ngIf="stepperIndex === (this.type === 'folder' ? 2 : 3)"
            (click)="saveContent()"
            [disabled]="okBtnDisabled()">
            Ok
        </button>
    </div>
</div>

<div *ngIf="sending" class="cssload-container" style="top: 50%">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>

<ngx-extended-pdf-viewer *ngIf="type === 'pdf'"
    [src]="this.mediaSrc.value"
    (pdfLoaded)="setPicturePreview()"
    hidden="true">
</ngx-extended-pdf-viewer>

<mat-card *ngIf="!compact; else compactCard" appearance="outlined" style="height: 100%">
    <mat-card-header>
        <mat-card-title>
            {{ 'Price' | translate }}
        </mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">
            {{ subtitle | translate }}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center" fxFlex>
        <span [style.font-size]="subscription ? '80px' : '16px'" style="font-weight: bold">
            {{
                !subscription ?
                ('No price set' | translate) :
                subscription.isFree ? ('Free' | translate) :
                subscription.subscriptionFee.euro + '€'
            }}
        </span>
    </mat-card-content>
    <mat-card-actions fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
        <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
    </mat-card-actions>
</mat-card>

<ng-template #compactCard>
    <div class="column-arrange" style="height: 100%; align-items: center;">
        <mat-card appearance="outlined" style="height: 100%">
            <mat-card-header style="justify-content: center; align-items: center; margin-bottom: 16px; height: 100%;">
                <mat-card-title [style.font-size]="subscription ? '26px' : '16px'" style="font-weight: bold">
                    {{ !subscription ? ('No price set' | translate) : subscription.isFree ? ('Free' | translate) : subscription.subscriptionFee.euro + '€' }}
                </mat-card-title>
                <div class="top-right">
                    <button mat-mini-fab color="primary"
                        (click)="crudOperation()"
                        [disabled]="subCrudDisabled"
                        [matTooltip]="(subscription ? 'Edit price' : 'Set price') | translate"
                        *ngIf="subCrudShow && compact">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <mat-card-content *ngIf="bookShow || (buyShow && subscription) || (logInToBuy && !isAuthenticated())" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
                <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
            </mat-card-content>
        </mat-card>

        <button mat-fab color="primary"
            *ngIf="routeEnabled && compact"
            (click)="goTo()"
            [matTooltip]="routeMessage | translate">
            <mat-icon>play_circle</mat-icon> 
        </button>
    </div>
</ng-template>

<ng-template #actionButtons>
    <button mat-raised-button color="primary"
        (click)="crudOperation()"
        [disabled]="subCrudDisabled"
        *ngIf="subCrudShow && !compact"
        class="column-button">
        <mat-icon>
            monetization_on
        </mat-icon>
        {{ (subscription ? 'Edit price' : 'Set price') | translate }}
    </button>
    <button mat-raised-button color="primary"
        (click)="bookEntity()"
        [disabled]="bookDisabled"
        *ngIf="bookShow"
        class="column-button">
        <mat-icon>
            bookmark
        </mat-icon>
        {{ 'Book' | translate }}
    </button>
    <button mat-raised-button color="primary"
        (click)="buy()"
        [disabled]="buyDisabled"
        *ngIf="buyShow && subscription"
        class="column-button">
        <mat-icon>
            {{ subscription.isFree ? 'shopping_bag' : 'shopping_cart' }}
        </mat-icon>
        {{ (renewShow ? 'Renew' : subscription.isFree ? 'Subscribe' : 'Purchase') | translate }}
    </button>
    <button mat-raised-button color="primary"
        *ngIf="routeEnabled && !compact"
        (click)="goTo()"
        class="column-button">
        <mat-icon>launch</mat-icon> 
        {{ routeMessage | translate }}
    </button>
    <button mat-raised-button color="primary"
        *ngIf="logInToBuy && !isAuthenticated()"
        [disabled]="!subscription"
        (click)="redirectToLogIn()"
        class="column-button">
        <mat-icon>
            login
        </mat-icon>
        {{ (subscription?.isFree ? 'Log in to subscribe' : 'Log in to purchase') | translate }}
    </button>
    <ng-content select="[additionalButton]"></ng-content>
</ng-template>

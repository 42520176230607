<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>Smily</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom: 20px;">
        <mat-form-field color="primary">
            <mat-label>{{ 'User' | translate }}</mat-label>
            <mat-select matInput [formControl]="userForm" (selectionChange)="getRecOfUser($event.value)">
                <mat-option [value]="0">{{ 'No user' | translate }}</mat-option>
                <mat-option *ngFor="let user of users" [value]="user.id">
                    {{ user.surname + ' ' + user.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field color="primary" style="width: 230px;">
            <mat-label>{{ 'Recording' | translate }}</mat-label>
            <mat-select matInput [formControl]="recForm" (selectionChange)="updateGraph($event.value)">
                <mat-option *ngFor="let rec of recordings" [value]="rec.id">
                    {{ ('From' | translate) +
                        ' ' +
                       (rec.startDate | date: 'HH:mm:ss') +
                       ' ' +
                       ('to' | translate) +
                       ' ' +
                       (rec.stopDate | date: 'HH:mm:ss') }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field color="primary">
            <mat-label>{{ 'Data point' | translate }}</mat-label>
            <mat-select matInput [formControl]="dpForm" (selectionChange)="dpChange($event.value)">
                <mat-option value="all">{{ 'All' | translate }}</mat-option>
                <mat-option *ngFor="let dp of items" [value]="dp.tag">{{ dp.tag }}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-icon-button (click)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
        </button>

        <button mat-icon-button (click)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
        </button>
    </div>
    <div style="width: 95vw; overflow-x: scroll;">
        <div style="height: 600px;" [style.width]="zoomLevel">
            <canvas #lineChartCanvas>
            </canvas>
        </div>
    </div>
</div>

import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Device, OpenVidu, Publisher } from 'openvidu-browser';
import { Helper } from 'src/app/helpers/helper';
import { NetworkInformation } from 'src/app/helpers/networkInformation';
import { AuthService } from 'src/app/services/auth.service';

const CONFERENCE_SOURCE_KEY: string = "conferenceSourceConfig";
const SOURCE_SELECTION_TIMEOUT: number = 2000; //ms
const SOURCE_SELECTION_SHOW_INTERVAL: number = 500; //ms

@Component({
  selector: 'app-source-selection',
  templateUrl: './source-selection.component.html',
  styleUrls: ['./source-selection.component.scss']
})
export class SourceSelectionComponent implements OnInit, OnDestroy {

  private _videoDevices: Device[] = [];
  private _audioDevices: Device[] = [];
  private _videoEnabled: boolean = true;
  private _audioEnabled: boolean = true;
  private _mirrorDevice: boolean = true;
  private _showingDevice: boolean = false;

  @Input()
  OV: OpenVidu;

  @Input()
  get videoDevices(): Device[] { return this._videoDevices; }
  set videoDevices(value: Device[]) {
    if (!value || value?.length === 0)
      return;

    this._videoDevices = value;

    if (this.setInitialVideo) {
      this.setInitialVideo = false;

      this.setConfigValues();

      if (this.videoDevice.value === false)
        setTimeout(() => this.videoDevice.setValue(this.videoDevices[0].deviceId));
    }
  }

  @Input()
  get audioDevices(): Device[] { return this._audioDevices; }
  set audioDevices(value: Device[]) {
    if (!value || value?.length === 0)
      return;

    this._audioDevices = value;

    if (this.setInitialAudio) {
      this.setInitialAudio = false;

      this.setConfigValues();

      if (this.audioDevice.value == undefined)
        setTimeout(() => this.audioDevice.setValue(this.audioDevices[0].deviceId));
    }
  }

  @Input()
  username: string;

  @Input()
  videoDeviceEnabled: boolean = true;

  @Input()
  audioDeviceEnabled: boolean = true;

  @Input()
  set videoEnabled(value: boolean) {
    if (this.setInitialVideo)
      this._videoEnabled = value;
  }

  @Input()
  set audioEnabled(value: boolean) {
    if (this.setInitialAudio)
      this._audioEnabled = value;
  }

  @Input()
  set mirrorDevice(value: boolean) {
    if (this.setInitialVideo)
      this._mirrorDevice = value;
  }

  @Input()
  set lockForm(value: boolean) {
    this.toggleFormLock(value);
  }

  @Input()
  entityName: string = 'streaming';

  @Input()
  acceptedNetwork: ('2g' | '3g' | '4g')[] = undefined;

  @Output()
  result: EventEmitter<SourceSelection> = new EventEmitter<SourceSelection>();

  videoDevice: FormControl<false | string> = new FormControl<false | string>(false, [Validators.required]);
  audioDevice: FormControl<false | string> = new FormControl<false | string>(undefined, [Validators.required]);

  localPublisher: Publisher;
  setInitialVideo: boolean = true;
  setInitialAudio: boolean = true;
  color: string = Helper.getRandomColor();

  isValidBrowser: boolean = this.platform.BLINK && (this.deviceService.browser?.toLowerCase()?.includes('chrome') ?? false);
  browser: string = `${this.deviceService.browser} ${this.deviceService.browser_version}`;

  formLocked: boolean = false;

  showInterval: any = undefined;
  showQueue: number = 0;

  constructor(private auth: AuthService,
              private platform: Platform,
              private deviceService: DeviceDetectorService) {

    this.videoDevice.valueChanges.subscribe(() => this.showQueue++);
    this.audioDevice.valueChanges.subscribe(() => this.showQueue++);

    this.disposeInterval();
    this.showInterval = setInterval(() => this.showSelectedDevices(), SOURCE_SELECTION_SHOW_INTERVAL);
    
  }

  ngOnInit(): void {
    let lum = Helper.getColorBrightness(this.color);

		while (!this.color || lum > 190 || lum < 65) {
			
			this.color = Helper.getRandomColor();
			lum = Helper.getColorBrightness(this.color);

		}
  }

  async ngOnDestroy(): Promise<void> {
    this.disposeInterval();
    await this.disposePublisher();

    delete this.localPublisher;
    delete this.OV;
  }

  ok() {
    this.toggleFormLock(true);

    let res: SourceSelection = {
      videoDeviceId: this.videoDevice.value === false ? false : this.videoDevice.value,
      audioDeviceId: this.audioDevice.value,
      videoEnabled: this.videoDevice.value === false ? false : this._videoEnabled,
      audioEnabled: this.audioDevice.value === false ? false : this._audioEnabled,
      mirror: this._mirrorDevice
    };

    this.saveConfigValues(res);

    this.result.emit(res);
  }

  cancel() {
    this.result.emit(null);
  }

  toggleVideo() {
    if (this.localPublisher?.stream.hasVideo && this.localPublisher?.stream.getMediaStream() != undefined) {
      this.localPublisher?.publishVideo(!this.localPublisher?.stream.videoActive);
      this.showQueue++;
    }
  }

  toggleAudio() {
    if (this.localPublisher?.stream.hasAudio && this.localPublisher?.stream.getMediaStream() != undefined) {
      this.localPublisher?.publishAudio(!this.localPublisher?.stream.audioActive);
      this.showQueue++;
    }
  }

  toggleMirror() {
    this.showQueue++;
  }

  joinDisabled() {
    let check = this.formLocked || !this.checkNetwork();

    if (this.videoDeviceEnabled)
      check = check || !this.videoDevice.valid;

    if (this.audioDeviceEnabled)
      check = check || !this.audioDevice.valid;

    return check;
  }

  getNetworkBandwidth() {
    return NetworkInformation.bandwidth();
  }

  getNetworkLatency() {
    return NetworkInformation.rtt();
  }

  getNetworkClass() {
    let connType = NetworkInformation.connectionType();

    if (connType === '4g')
      return 'Fast connection';

    if (connType === '3g')
      return 'Medium connection';

    if (connType === '2g')
      return 'Slow connection';

    if (connType === 'slow-2g')
      return 'Very slow connection';

    return 'Unknown connection';
  }

  checkNetwork() {
    //if (!this.isValidBrowser)
    //  return false;

    if (!this.acceptedNetwork || !this.platform.BLINK)
      return true;

    let connType = NetworkInformation.connectionType();
    let check = false;

    if (this.acceptedNetwork.includes('4g'))
      check = check || connType === '4g';

    if (this.acceptedNetwork.includes('3g'))
      check = check || connType === '3g';

    if (this.acceptedNetwork.includes('2g'))
      check = check || connType === '2g';

    return check;
  }

  private async showSelectedDevices() {
    if (!this.OV || this._showingDevice || this.showQueue <= 0)
      return;

    this.showQueue = 0;

    this._showingDevice = true;

    await this.disposePublisher();

    try {

      let publishVideo: boolean = this.videoDevice.value === false ? false : this._videoEnabled;
      let publishAudio: boolean = false;

      if (this.videoDevice.value !== false)
        this.localPublisher = this.OV.initPublisher(undefined, {
          audioSource: this.audioDevice.value, // The source of audio. If undefined default microphone
          videoSource: this.videoDevice.value, // The source of video. If undefined default webcam
          publishAudio: publishAudio, // Whether you want to start publishing with your audio unmuted or not
          publishVideo: publishVideo, // Whether you want to start publishing with your video enabled or not
          resolution: '640x480', // The resolution of your video
          frameRate: 25, // The frame rate of your video
          mirror: this._mirrorDevice 
        });

    } catch (e) {
      console.error(e);
    }

    setTimeout(() => this._showingDevice = false, SOURCE_SELECTION_TIMEOUT);
  }

  private async disposePublisher() {
    if (this.localPublisher?.stream.getMediaStream() != undefined) {

      if (this.localPublisher.stream.hasVideo && this.localPublisher.stream.videoActive)
        await this.localPublisher.publishVideo(false, true);

      this.localPublisher.stream.getMediaStream()?.getTracks().forEach(t => t.stop());

    }

    this.localPublisher = null;
  }

  private disposeInterval() {
    if (this.showInterval)
      clearInterval(this.showInterval);

    this.showInterval = undefined;
  }

  private setConfigValues() {
    let configs = <SourceConfig[]>JSON.parse(localStorage.getItem(CONFERENCE_SOURCE_KEY)) ?? [];
    if (!configs || configs.length === 0)
      return;

    let userConf = configs.find(c => c.userId === this.auth.getCurrentUser().id);
    if (userConf == undefined)
      return;

    this._videoEnabled = userConf.config.videoEnabled;
    this._audioEnabled = userConf.config.audioEnabled;
    this._mirrorDevice = userConf.config.mirror ?? false;

    if (userConf.config.videoDeviceId !== false) {

      let videoDevice = this.videoDevices.find(d => d.deviceId === userConf.config.videoDeviceId);
      if (videoDevice != undefined)
        this.videoDevice.setValue(videoDevice.deviceId);

    }

    if (userConf.config.audioDeviceId !== false) {

      let audioDevice = this.audioDevices.find(d => d.deviceId === userConf.config.audioDeviceId);
      if (audioDevice != undefined)
        this.audioDevice.setValue(audioDevice.deviceId);

    }
    
  }

  private saveConfigValues(config: SourceSelection) {
    let newConfig: SourceConfig = {
      userId: this.auth.getCurrentUser().id,
      config: config
    };

    let configs = <SourceConfig[]>JSON.parse(localStorage.getItem(CONFERENCE_SOURCE_KEY)) ?? [];
    let userConfIndex = configs.findIndex(c => c.userId === newConfig.userId);

    userConfIndex === -1 ? configs.push(newConfig) : configs[userConfIndex] = newConfig;

    localStorage.setItem(CONFERENCE_SOURCE_KEY, JSON.stringify(configs));
  }

  private toggleFormLock(value: boolean) {
    this.formLocked = value;

    if (value) {

      this.videoDevice.disable();
      this.audioDevice.disable();

    } else {

      this.videoDevice.enable();
      this.audioDevice.enable();

    }
  }

  /*
  getNetworkType() {
    return NetworkInformation.type();
  }

  getNetworkTypeIcon() {
    let type = this.getNetworkType();

    if (type === 'bluetooth')
      return 'bluetooth';

    if (type === 'cellular')
      return 'signal_cellular_alt';

    if (type === 'ethernet')
      return 'settings_ethernet';

    if (type === 'wifi')
      return 'wifi';

    if (type === 'wimax')
      return 'leak_add';

    if (type === 'other')
      return 'public';

    if (type === 'none')
      return 'public_off';

    return 'question_mark';
  }
  */

}

export class SourceSelection {
  public videoDeviceId: string | false;
  public audioDeviceId: string | false;
  public videoEnabled: boolean;
  public audioEnabled: boolean;
  public mirror: boolean;
}

class SourceConfig {
  public userId: number;
  public config: SourceSelection;
}

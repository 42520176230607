<div class="component-main">
  <div style="margin-bottom: 20px">

    <div fxLayout="row" fxLayoutAlign="center center">
      <h2>{{ 'Users' | translate }}</h2>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom: 20px">
      <button mat-fab color="accent" *ngIf="isCustomerAdmin() && isAixpMode() && !isDT()" (click)="inviteUser()"
        [matTooltip]="'Invite user' | translate">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-fab color="accent" *ngIf="(isAdmin() || isCustomerAdmin() || isTeacher()) && (isSaratogaMode() || isDT())" (click)="addUser()"
        [matTooltip]="'Add user' | role | translate">
        <mat-icon>add</mat-icon>
      </button>
      <mat-checkbox *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin"
        [formControl]="state"
        (change)="getUsers($event.checked)">
        {{ 'Inactive users' | translate }}
      </mat-checkbox>
    </div>

  </div>

  <app-user-table *ngIf="!currentUser.isAdmin; else adminList"
    [users]="currentCustomerUsers"
    (updateUsers)="getUsers($event === 'enable')">
  </app-user-table>
</div>

<ng-template #adminList>
  <div class="center-item" style="width: 100%">

    <mat-form-field color="primary" style="width: 100%">
      <mat-label>{{ 'Search' | translate }}...</mat-label>
      <input matInput [(ngModel)]="value" (ngModelChange)="resetCustomers(false)">
      <button matSuffix mat-icon-button [disabled]="!value" [matTooltip]="'Clear' | translate" (click)="resetCustomers(true)">
        <mat-icon>clear</mat-icon>
      </button>  
    </mat-form-field>

    <mat-accordion>

      <mat-expansion-panel
        *ngFor="let customer of customers | slice:pageStart:pageEnd"
        (opened)="setCustomer(customer)"
        style="margin-bottom: 10px">

        <mat-expansion-panel-header>
          <mat-panel-title>
            <div style="text-align: start; align-self: center;" fxFlex>
              {{ customer[0].customerName }}
            </div>
            <div style="text-align: end;" fxFlex="20">
              <mat-icon *ngIf="currentCustomerId === customer[0].idCustomer"
                [matTooltip]="'Selected' | translate"
                [matTooltipPosition]="'before'">
                done
              </mat-icon>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <app-user-table
            [users]="currentCustomerUsers"
            (updateUsers)="getUsers($event === 'enable')">
          </app-user-table>
        </ng-template>

      </mat-expansion-panel>

    </mat-accordion>

    <mat-paginator
      [showFirstLastButtons]="true"
      [length]="customers.length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 25, 50, 100]"
      (page)="changePage($event)"
      color="primary"
      class="mat-elevation-z3">
    </mat-paginator>
  </div>
</ng-template>

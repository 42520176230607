import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CourseEdit } from 'src/app/models/courseEdit';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ClassroomService } from 'src/app/services/classroom.service';
import { CourseService } from 'src/app/services/course.service';
import { AzureStorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-course-add',
  templateUrl: './course-add.component.html',
  styleUrls: ['./course-add.component.scss']
})
export class CourseAddComponent implements OnInit {

  currentUser: User = null;
  classrooms: ClassroomDTO[] = [];
  sending: boolean = false;
  courseToEdit: CourseDTO = null;
 
  selectedClassrooms: number[] = [];
  imageSrc: FormControl = new FormControl(undefined, [Validators.required]);
  name: FormControl = new FormControl('', [Validators.required, Validators.minLength(4)]);
  description: FormControl = new FormControl(null);
  header: FormControl = new FormControl(null);
  footer: FormControl = new FormControl(null);
  public: FormControl = new FormControl(false);
  everyone: FormControl = new FormControl(false); 
  modalita: FormControl = new FormControl(0, [Validators.required]);
  summary: FormControl = new FormControl('');
  notifications: FormControl = new FormControl(true);

  nameTranslation: TranslationDTO = undefined;
  descriptionTranslation: TranslationDTO = undefined;
  headerTranslation: TranslationDTO = undefined;
  footerTranslation: TranslationDTO = undefined;
  summaryTranslation: TranslationDTO = undefined;

  constructor(public dialogRef: MatDialogRef<CourseAddComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private auth: AuthService,
              private courseService: CourseService,
              private classroomService: ClassroomService,
              private snackBar: MatSnackBar,
              private azureService: AzureStorageService,
              private router: Router) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();

    if(this.data != null) {
      this.courseToEdit = this.data.course;

      this.public.setValue(this.courseToEdit.visibility == 1 ? true : false);
      this.everyone.setValue(this.courseToEdit.visibility == 2 ? true : false);
      this.modalita.setValue(this.courseToEdit.mode ? this.courseToEdit.mode : 0);
      this.notifications.setValue(this.courseToEdit.sendNotification === 1);

      if (this.courseToEdit.nameTranslation)
        this.name.setValue(this.courseToEdit.nameTranslation[this.currentUser.defaultLanguage]);

      if (this.courseToEdit.descriptionTranslation)
        this.description.setValue(this.courseToEdit.descriptionTranslation[this.currentUser.defaultLanguage]);

      if (this.courseToEdit.headerTranslation)
        this.header.setValue(this.courseToEdit.headerTranslation[this.currentUser.defaultLanguage]);

      if (this.courseToEdit.footerTranslation)
        this.footer.setValue(this.courseToEdit.footerTranslation[this.currentUser.defaultLanguage]);

      if (this.courseToEdit.summaryTranslation)
        this.summary.setValue(this.courseToEdit.summaryTranslation[this.currentUser.defaultLanguage]);

      this.nameTranslation = this.courseToEdit.nameTranslation;
      this.descriptionTranslation = this.courseToEdit.descriptionTranslation;
      this.headerTranslation = this.courseToEdit.headerTranslation;
      this.footerTranslation = this.courseToEdit.footerTranslation;
      this.summaryTranslation = this.courseToEdit.summaryTranslation;

      this.fetchCourseClassrooms();

      //In edit non e possibile modificare la modalita di un corso
      this.modalita.disable();
    }

    this.fetchClassRoom();
  }

  async saveCourse() {
    this.toggleDisable(true);

    let course = new CourseEdit();
    
    course.Name = this.name.value;
    course.Description = this.description.value;
    course.Header = this.header.value;
    course.Footer = this.footer.value;
    course.IdAuthor = this.currentUser.id;
    course.Visibility = this.public.value ? 1 : this.everyone.value ? 2 : 0;
    course.ClassIds = this.selectedClassrooms;
    course.ImageUrl = await this.azureService.uploadFile(this.imageSrc.value);
    course.mode = this.modalita.value;
    course.Summary = this.summary.value;
    course.SendNotification = this.notifications.value ? 1 : 0;
    course.NameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    course.DescriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);
    course.HeaderTranslation = TranslationEdit.fromDTO(this.headerTranslation);
    course.FooterTranslation = TranslationEdit.fromDTO(this.footerTranslation);
    course.SummaryTranslation = TranslationEdit.fromDTO(this.summaryTranslation);

    if (!this.courseToEdit) {
      firstValueFrom(this.courseService.postCourse(course))
      .then(success => {
        this.snackBar.open('Course added successfully', 'Dismiss', { duration: 3000 });

        this.toggleDisable(false);
        this.dialogRef.close(true);

        this.router.navigate(['/course', (success as any).Message], { queryParams: { page: 'sidenav' } });
      }).catch(err => { 
        console.log(err);
        this.snackBar.open('Error adding course', 'Dismiss', { duration: 3000 });

        this.toggleDisable(false);
        this.onNoClick();
      });
    } else {
      course.State = this.courseToEdit.state;

      firstValueFrom(this.courseService.putCourse(this.courseToEdit.id, course))
        .then(success => {
          this.snackBar.open('Course edited successfully', 'Dismiss', { duration: 3000 });
          this.toggleDisable(false);
          this.dialogRef.close(true);
        }).catch(err => {
          console.log(err);
          this.snackBar.open('Error editing course', 'Dismiss', { duration: 3000 });
          this.toggleDisable(false);
          this.onNoClick();
        });
    }
  }

  publicChanged(everyone: boolean) {
    this.selectedClassrooms = [];

    if(!everyone)
      this.everyone.setValue(false);
    else
      this.public.setValue(false);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  fetchClassRoom() {
    this.classroomService.getClassrooms()
      .subscribe(output => this.classrooms = output as ClassroomDTO[]);
  }

  fetchCourseClassrooms() {
    this.selectedClassrooms = [];

    this.courseService.getCourseClassrooms(this.courseToEdit.id)
      .subscribe(output => {
        var classes = [];
        output.forEach(c => {
          classes.push(c.id);
        });

        this.selectedClassrooms = classes;
      });
  }

  /*
  getBlob(b64Data: string, contentType: string) {
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    var blob: any = new Blob(byteArrays, {type: contentType});
    
    //blob.lastModifiedDate = new Date(); Incaso si voglia fare un file e non un Blob, aggiungere anche alla fine return <File>blob
    //blob.name = name;

    return blob;
  }*/

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if(toggle) {
      this.name.disable();
      this.description.disable();
      this.header.disable();
      this.footer.disable();
      this.public.disable();
      this.everyone.disable();
      this.imageSrc.disable();
      this.summary.disable();
      this.notifications.disable();
    } else {
      this.name.enable();
      this.description.enable();
      this.header.enable();
      this.footer.enable();
      this.public.enable();
      this.everyone.enable();
      this.summary.enable();
      this.imageSrc.enable();
      this.notifications.enable();
    }
  }
}

<h1 mat-dialog-title>{{ "Set price" | translate }}</h1>
<mat-dialog-content fxLayout="column">
  <mat-radio-group [formControl]="isFree" style="margin-top: 10px; margin-bottom: 20px">
    <mat-radio-button [value]="true" [disabled]="disableFree">{{ "Free" | translate }}</mat-radio-button>
    <mat-radio-button [value]="false" [disabled]="currentUser.isDemo">{{ "Price" | translate }}</mat-radio-button>
  </mat-radio-group>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="5px"
    *ngIf="isFree.value === false">

    <mat-form-field fxFlex color="primary">
      <mat-label>{{ "Euro" | translate }}</mat-label>
      <input matInput type="number" [(ngModel)]="euro" />
    </mat-form-field>
    <mat-form-field fxFlex color="primary">
      <mat-label>{{ "Dollar" | translate }}</mat-label>
      <input matInput type="number" [(ngModel)]="dollar" />
    </mat-form-field>
    <mat-form-field fxFlex color="primary">
      <mat-label>{{ "Pound" | translate }}</mat-label>
      <input matInput type="number" [(ngModel)]="pound" />
    </mat-form-field>

  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()">
    {{ "Cancel" | translate }}
  </button>
  <button
    mat-flat-button
    color="accent"
    (click)="addSubscription()"
    [disabled]="!isFree.valid || !getPriceValid()">
    {{ "Set price" | translate }}
  </button>
</mat-dialog-actions>

import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CourseContentEdit } from 'src/app/models/courseContentEdit';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AzureStorageService } from 'src/app/services/storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { SubtitlesLanguages } from 'src/app/models/subtitlesLanguages';
import { Helper } from 'src/app/helpers/helper';
import { CourseContentType } from 'src/app/models/courseContentType';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

const VIDEO_ACCEPT = 'video/*,.mkv';
const PDF_ACCEPT = '.pdf';
const ZIP_ACCEPT = '.zip';
const AUDIO_ACCEPT = 'audio/*';

@Component({
  selector: 'app-content-add',
  templateUrl: './content-add.component.html',
  styleUrls: ['./content-add.component.scss']
})
export class ContentAddComponent implements OnInit {

  currentUser: User = null;
  sending: boolean = false;
  contentToEdit: CourseContentDTO = null;
  type: string = 'video';
  subtitles: boolean = false;
  languages: { code: string, label: string }[] = [];
  //customOrder: boolean = false;
  title: string = undefined;
  typeTitle: string = undefined;

  idCourse: number = null;
  mediaSrc: FormControl = new FormControl(undefined, [Validators.required]);
  mobileImageSrc: FormControl = new FormControl(undefined);
  mediaLanguage: FormControl<string> = new FormControl(undefined);
  thumbnailSrc: FormControl = new FormControl(undefined);
  name: FormControl<string> = new FormControl('', [Validators.required, Validators.minLength(4)]);
  description: FormControl<string> = new FormControl(null);
  order: FormControl<number> = new FormControl(1, [Validators.required]);
  download: FormControl<boolean> = new FormControl(false);
  subtitlesLanguages: FormControl<string[]> = new FormControl([]);

  nameTranslation: TranslationDTO = undefined;
  descriptionTranslation: TranslationDTO = undefined;

  desktopPreview: string = undefined;
  mobilePreview: string = undefined;

  stepperIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ContentAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private azureService: AzureStorageService,
    private pdfViewerService: NgxExtendedPdfViewerService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.idCourse = this.data.idCourse;

    Object
      .entries(SubtitlesLanguages)
      .forEach(value => {

        this.languages.push({
          code: value[1],
          label: value[0]
        });

    });
    
    this.mediaLanguage.setValue(this.languages[0].code);
    this.order.setValue(this.data.mode === 1 ? 1 : 3);

    if (this.data.type)
      this.type = this.data.type;

    if (this.type === 'generic')
      this.download.setValue(true);

    //if (this.data.customOrder)
    //  this.customOrder = this.data.customOrder;

    if (this.data.lastIndex && this.data.mode === 1)
      this.order.setValue(this.data.lastIndex + 1);

    if (this.data.content != null) {
      this.contentToEdit = this.data.content;

      this.type = this.getCodeFromNumber(this.contentToEdit.type);

      this.order.setValue(this.contentToEdit.orderIndex);
      this.download.setValue(this.contentToEdit.downloadable == 1 ? true : false);

      if (this.contentToEdit.nameTranslation)
        this.name.setValue(this.contentToEdit.nameTranslation[this.currentUser.defaultLanguage]);

      if (this.contentToEdit.descriptionTranslation)
        this.description.setValue(this.contentToEdit.descriptionTranslation[this.currentUser.defaultLanguage]);

      this.nameTranslation = this.contentToEdit.nameTranslation;
      this.descriptionTranslation = this.contentToEdit.descriptionTranslation;
      
      this.subtitles = !Helper.isNullOrEmpty(this.contentToEdit.mediaLanguage);

      if (this.subtitles) {
        this.mediaLanguage.setValue(this.contentToEdit.mediaLanguage);
        this.subtitlesLanguages.setValue(this.contentToEdit.mediaSubtitlesLanguages?.split(' ') ?? []);
      }

    }

    this.setPicturePreview();
    this.setTitle();
  }

  async saveContent() {
    this.toggleDisable(true);

    let content = new CourseContentEdit();

    content.Name = this.name.value;
    content.Description = this.description.value;
    content.IdAuthor = this.currentUser.id;
    content.IdCourse = this.idCourse;
    content.Type = this.contentToEdit ? this.contentToEdit.type : this.getTypeFromCode(this.type);
    content.OrderIndex = this.order.value;
    content.Downloadable = this.download.value ? 1 : 0;
    content.NameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    content.DescriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);

    if (this.subtitles) {
      content.MediaLanguage = this.mediaLanguage.value;
      content.SubtitlesLanguages = this.subtitlesLanguages.value;
    }

    if (this.type === 'folder') {

      content.Duration = 0;

    } else if (this.mediaSrc.value && (this.type === 'video' || this.type === 'pdf' || this.type === 'audio')) {

      content.Duration = await this.getFileDuration(this.mediaSrc.value, content.Type);

    }

    if (!this.contentToEdit) {

      if (this.mediaSrc.value) {

        this.azureService.postContent(content, this.mediaSrc.value, await this.getThumbnail(content.Type), this.mobileImageSrc.value);
        this.onNoClick();

      } else {

        let id = this.type === 'folder'
               ? await this.azureService.postContentFolder(content, await this.getThumbnail(content.Type), this.mobileImageSrc.value)
               : await this.azureService.postContent(content, null, await this.getThumbnail(content.Type), this.mobileImageSrc.value);

        this.dialogRef.close(id);

      }

      this.snackBar.open('Adding content...', 'Dismiss', { duration: 3000 });

    } else {

      if (this.mediaSrc.value) {

        let warningDialog = this.dialog.open(GenericPopupComponent,
          {
            width: '400px',
            data: <GenericPopupData>{
              title: await firstValueFrom(this.translate.get('Warning')),
              body: await firstValueFrom(this.translate.get('Changing the file will delete all the analytics data associated with this content, do you want to continue?'))
            }
          });

        if (!(await firstValueFrom(warningDialog.afterClosed()))) {
          this.toggleDisable(false);
          return;
        }

        this.azureService.putContent(this.contentToEdit.id, content, this.mediaSrc.value, await this.getThumbnail(content.Type), this.mobileImageSrc.value);
        this.onNoClick();

      } else {

        await this.azureService.putContent(this.contentToEdit.id, content, null, await this.getThumbnail(content.Type), this.mobileImageSrc.value);
        this.dialogRef.close(true);

      }

      this.snackBar.open('Editing content...', 'Dismiss', { duration: 3000 });

    }

    this.toggleDisable(false);
  }

  async getThumbnail(type?: number) {
    if (this.thumbnailSrc.value)
      return this.thumbnailSrc.value;
    
    if (type === CourseContentType.Pdf && this.mediaSrc.value) {
      let base64 = await this.pdfViewerService.getPageAsImage(1, { width: 800 });
      return await fetch(base64).then(r => r.blob());
    }

    return null;
  }

  mediaAccept() {

    if (this.type === 'generic') {

      let filename = this.mediaSrc.value?.name
               ? this.mediaSrc.value?.name.replace(/[/\\?%*:|"<>\s]/g, '_')
               : Math.floor(Math.random() * 100000000).toString();

      this.name.setValue(filename);

    }

  }

  mediaDelete() {
    this.thumbnailSrc.reset();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if(toggle) {
      this.name.disable();
      this.description.disable();
      this.order.disable();
      this.mediaSrc.disable();
      this.thumbnailSrc.disable();
      this.download.disable();
    } else {
      this.name.enable();
      this.description.enable();
      this.order.enable();
      this.mediaSrc.enable();
      this.thumbnailSrc.enable();
      this.download.enable();
    }
  }

  async getFileDuration(file: File, type: number): Promise<number> {
    if (!file)
      return 0;

    try {

      if (type === CourseContentType.Video)
        return await Helper.getVideoDuration(file);
  
      if (type === CourseContentType.Pdf)
        return this.pdfViewerService.numberOfPages();
  
      if (type === CourseContentType.Audio)
        return await Helper.getAudioDuration(file);

    } catch (e) {
      console.error(e);

      return 0;
    }
  }

  getAcceptedFiles(): string {

    if (this.type === 'video')
      return VIDEO_ACCEPT;

    if (this.type === 'pdf')
      return PDF_ACCEPT;

    if (this.type === 'scorm')
      return `${VIDEO_ACCEPT},${PDF_ACCEPT},${ZIP_ACCEPT}`;

    if (this.type === 'audio')
      return AUDIO_ACCEPT;

    if (this.type === 'generic')
      return '*';

    return undefined;

  }

  okBtnDisabled() {
    let check = !this.order.valid ||
                this.sending;

    if (this.type !== 'generic')
      check = check
           || !this.name.valid;

    if (!this.contentToEdit && this.type !== 'folder')
      check = check ||
              !this.mediaSrc.valid;

    return check;
  }

  nextBtnDisabled() {

    if (this.stepperIndex === 0)
      return (this.type !== 'generic' && !this.name.valid)
          || !this.order.valid;

    if (this.stepperIndex === 1)
      return !this.contentToEdit
          && this.type !== 'folder'
          && !this.mediaSrc.valid;

    if (this.stepperIndex === 2)
      return false;

    return true;

  }

  async setPicturePreview() {

    try {

      this.desktopPreview = this.thumbnailSrc.value
                          ? URL.createObjectURL(this.thumbnailSrc.value)
                          : this.mediaSrc.value && this.type === 'pdf'
                          ? await this.pdfViewerService.getPageAsImage(1, { width: 800 })
                          : this.mediaSrc.value
                          ? URL.createObjectURL(await Helper.getThumbnailBlob(this.mediaSrc.value, undefined, { type: this.getTypeFromCode(this.type), entity: 'coursecontent' }))
                          : this.contentToEdit?.headerImageUrl
                          ? this.contentToEdit.headerImageUrl
                          : URL.createObjectURL(await Helper.getThumbnailBlob(undefined, undefined, { type: this.getTypeFromCode(this.type), entity: 'coursecontent' }));

    } catch (e) { }

    this.mobilePreview = this.mobileImageSrc.value
                       ? URL.createObjectURL(this.mobileImageSrc.value)
                       : this.contentToEdit?.mobileHeaderImageUrl
                       ? this.contentToEdit.mobileHeaderImageUrl
                       : this.desktopPreview
                       ? this.desktopPreview
                       : undefined;

  }

  getCodeFromNumber(type: number) {

    if (type === CourseContentType.Video)
      return 'video';

    if (type === CourseContentType.Pdf)
      return 'pdf';

    if (type === CourseContentType.Survey)
      return 'survey';

    if (type === CourseContentType.Scorm)
      return 'scorm';

    if (type === CourseContentType.Audio)
      return 'audio';

    if (type === CourseContentType.Generic)
      return 'generic';

    if (type === CourseContentType.Folder)
      return 'folder';

    return 'unknown';

  }

  getTypeFromCode(code: string) {

    if (code === 'video')
      return CourseContentType.Video;

    if (code === 'pdf')
      return CourseContentType.Pdf;

    if (code === 'survey')
      return CourseContentType.Survey;

    if (code === 'scorm')
      return CourseContentType.Scorm;

    if (code === 'audio')
      return CourseContentType.Audio;

    if (code === 'generic')
      return CourseContentType.Generic;

    if (code === 'folder')
      return CourseContentType.Folder;

    return undefined;

  }

  private setTitle() {

    this.typeTitle = this.type === 'video'
                   ? 'Video'
                   : this.type === 'pdf'
                   ? 'Pdf'
                   : this.type === 'survey'
                   ? 'Survey'
                   : this.type === 'scorm'
                   ? 'Scorm'
                   : this.type === 'audio'
                   ? 'Audio'
                   : this.type === 'generic'
                   ? 'File'
                   : this.type === 'folder'
                   ? 'Folder'
                   : 'Unknown';

    if (this.contentToEdit) {

      this.title = this.type === 'folder'
                 ? 'Edit Training Course Folder'
                 : 'Edit Training Course Content';

      return;

    }

    this.title = this.type === 'folder'
               ? 'Add Training Course Folder'
               : 'Add Training Course Content';

  }

}

<div class="component-main">

    <div style="text-align: center">
        <h2>{{ 'My masters' | translate }}</h2>
    </div>

    <app-master-table
        [masters]="masters">
    </app-master-table>

</div>

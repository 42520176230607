import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

  private _url: string = '../../assets/img/user.png';
  private _size: string = '50px';
  private _border: string = null;
  private _text: string = null;
  private _hover: boolean = false;

  @Input()
  get url(): string { return this._url; }
  set url(value: string) {
    this._url = value ? value : '../../assets/img/user.png';
  }

  @Input()
  get size(): string { return this._size; }
  set size(value: string) {
    this._size = value ? value : '50px';
  }

  @Input()
  get text(): string { return this._text; }
  set text(value: string) {
    this._text = value;
  }

  @Input()
  get hover(): boolean { return this._hover; }
  set hover(value: boolean) {
    this._hover = value;
  }

  @Input()
  get border(): string { return this._border; }
  set border(value: string) {
    this._border = value;
  }

  constructor() { }

  ngOnInit(): void { }

}

import { UserDTO } from "./dto/userDTO";
import { User } from "./user";

export class UserRole {
    public static Admin: number = 1;
    public static Teacher: number = 2;
    public static Student: number = 3;
    public static CustomerAdmin: number = 4;
    public static Tutor: number = 5;
    public static Speaker: number = 6;

    public static role(user: User | UserDTO): string {
        if (!user)
            return null;

        if (user.isAdmin)
            return 'Admin';

        if (user.isCustomerAdmin)
            return 'Customer admin';

        if (user.isTutor)
            return 'Tutor';

        if (user.isTeacher)
            return 'Teacher';

        if (user.isSpeaker)
            return 'Speaker';

        if (user.isStudent)
            return 'Student';

        return null;
    }

    public static code(user: User | UserDTO): number {
        if (user.isAdmin)
            return UserRole.Admin;

        if (user.isCustomerAdmin)
            return UserRole.CustomerAdmin;

        if (user.isTutor)
            return UserRole.Tutor;

        if (user.isTeacher)
            return UserRole.Teacher;

        if (user.isSpeaker)
            return UserRole.Speaker;

        if (user.isStudent)
            return UserRole.Student;

        return null;
    }

    public static codeAsString(user: User | UserDTO): string {
        if (user.isAdmin)
            return 'admin';

        if (user.isCustomerAdmin)
            return 'adminCustomer';

        if (user.isTutor)
            return 'tutor';

        if (user.isTeacher)
            return 'teacher';

        if (user.isSpeaker)
            return 'speaker';

        if (user.isStudent)
            return 'student';

        return null;
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/ecommerce/service/cart.service';
import { Helper } from 'src/app/helpers/helper';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { User } from 'src/app/models/user';
import { CourseState } from 'src/app/models/courseState';
import { CourseService } from 'src/app/services/course.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location, LocationStrategy } from '@angular/common';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharePopupComponent } from 'src/app/popup/share-popup/share-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageCodes, TranslationService } from 'src/app/services/translation.service';
import { AddUserListPopupComponent } from 'src/app/popup/add-user-list-popup/add-user-list-popup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { CourseContentType } from 'src/app/models/courseContentType';

const VIDEO_PREVIEW_DURATION: number = 60;

@Component({
  selector: 'app-course-showcase',
  templateUrl: './course-showcase.component.html',
  styleUrls: ['./course-showcase.component.scss']
})
export class CourseShowcaseComponent implements OnInit {
  
  private os: 'ios' | 'android' | 'unknown' = 'unknown';
  private _course: CourseDTO;

  @Input()
  get course(): CourseDTO { return this._course; }
  set course (value: CourseDTO) {
    this._course = value;

    let videos = this.course.courseContent.filter(cc => cc.type === CourseContentType.Video);
    this.totDuration = videos.map(cc => cc.duration).reduce((a, b) => a + b, 0);
    this.totVideo = videos.length;

    videos = videos.filter(v => v.duration >= VIDEO_PREVIEW_DURATION);
    this.video1 = videos.shift()?.mediaUrl;
    this.video2 = videos.shift()?.mediaUrl;

    this.totFile = this.course.courseContent.filter(cc => cc.type === CourseContentType.Pdf).length;
    this.totSurvey = this.course.courseContent.filter(cc => cc.type === CourseContentType.Survey).length;

    if (this.course.subscription && this.isAuthenticated())
      this.checkSubscription();
  }

  @Input()
  currentUserIsParticipant: boolean = false;

  id: number;
  currentUser: User;
  video1: string = "";
  video2: string = "";
  videoPause1: boolean = false;
  videoMarker1: number = 0;
  videoPause2: boolean = false;
  videoMarker2: number = 0;
  totVideo: number = 0;
  totDuration: number = 0;
  totFile: number = 0;
  totSurvey: number = 0;
  href: string = "";
  isMobile: boolean = false;

  //Per controllo subscription
  canpurchased : boolean;
  currentstate : number;
  isstartget : boolean;

  fromPackage: boolean = false;
  showDeepLinkButton: boolean = false;
  playStoreUrl: string = EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.PlayStore);
  appStoreUrl: string = EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.AppStore);
  
  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router ,
              private snackBar: MatSnackBar,
              private courseService: CourseService,
              private cart: CartService,
              private location: Location,
              private dialog: MatDialog,
              private subscription : SubscriptionService,
              private navBar: NavBarService,
              private deviceService: DeviceDetectorService,
              private translate: TranslateService,
              private locationStrategy: LocationStrategy,
              private device: DeviceDetectorService,
              private translation: TranslationService) { }
              
  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();
    this.href = Helper.getUrl();
    this.isMobile = this.deviceService.isMobile();

    if (this.isMobile) {

      this.navBar.hide('all');

      this.os = this.device.os?.toLocaleLowerCase().includes('android')
            ? 'android'
            : this.device.os?.toLocaleLowerCase().includes('ios')
            ? 'ios'
            : 'unknown';

      history.pushState(null, null, window.location.href);
      // check if back or forward button is pressed.
      this.locationStrategy.onPopState(() => {
          history.pushState(null, null, window.location.href);
      });

      if (this.currentUserIsParticipant || this.currentUser?.id === this.course.idAuthor)
        this.showDeepLinkButton = true;
      
    }

    if (this.route.snapshot.queryParamMap.has('from'))
      this.fromPackage = this.route.snapshot.queryParamMap.get('from') === 'package';
  }

  hideFiles() { 
    return !this.course.courseContent;
  }

  updateVideo($event) {
    if ($event < VIDEO_PREVIEW_DURATION)
      return;

    this.videoMarker1 = VIDEO_PREVIEW_DURATION; 
    this.videoPause1 = true;

    setTimeout(() => {
      this.videoMarker1 = 0;
      this.videoPause1 = false;
    }, 500);
  }
  
  updateVideo2($event) {
    if ($event < VIDEO_PREVIEW_DURATION)
      return;

    this.videoMarker2 = VIDEO_PREVIEW_DURATION; 
    this.videoPause2 = true;

    setTimeout(() => {
      this.videoMarker2 = 0;
      this.videoPause2 = false;
    }, 500);
        
  }

  goBack() {
    this.location.back();
  }

  getCourseState() {
    return CourseState;
  }

  payCourseFree() {
    this.courseService.buyCourse(this.course.idSubscription)
      .subscribe({
        next: async () => {
          
          if (this.isMobile) {
            this.snackBar.open(await firstValueFrom(this.translate.get('Course added to your entities')), '', { duration: 3000 });
            this.currentUserIsParticipant = true;
            if (this.currentUserIsParticipant || this.currentUser?.id === this.course.idAuthor)
              this.showDeepLinkButton = true;

            return;
          }

          this.router.navigate(['/course', this.course.id], { queryParams: { page: 'sidenav' } });
        },
        error: err => this.snackBar.open(err.error.message, '', { duration: 3000 })
      });
  }

  getHelper() {
    return Helper;
  }

  goToAuthorProfile(id: number) {
    this.router.navigate(['/authorprofile', id]);
  }

  getDuration() {
    return Helper.convertToHMS(this.totDuration * 1000);
  }

  addToCartCourse () {
    this.cart.addCourse(this.course);
    this.router.navigateByUrl('/cart');
  }

  share() {
    this.dialog.open(SharePopupComponent, {
      width: '400px', data: { title: this.course.name }
    });
  }

  checkSubscription() {
    this.subscription.getCanPurchase(this.course.idSubscription)
      .subscribe(result => this.canpurchased = result);

    this.subscription.getState(this.course.idSubscription)
      .subscribe(result => this.currentstate = result);

    this.subscription.getsubscriptionlimituser(this.course.idSubscription)
      .subscribe(result => this.isstartget = result);
  }

  getSubTitle() {
    return this.translation.currentLang.code === LanguageCodes.IT ? 'Iva inclusa' : null;
  }
  
  addPaidUsers() {
    let users = [];

    if (this.course != null && this.course.subscription != null)
      users = this.course.subscription.subscriptionUser.map(u => u.idUser);

    let data: any = {
      type: "course",
      subscriptionId: this.course.idSubscription,
      alreadyPaidUsers: users
    };

    const dialogRef = this.dialog.open(AddUserListPopupComponent, { data: data });

    dialogRef.afterClosed()
      .subscribe(() => {
        this.courseService.getCourseContent(this.id, this.auth.isAuthenticated())
          .subscribe((output) => this.course = output);
      });
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  changeVisibility($event: any) {
    this.courseService.updateCourseVisibility(this.course.id, $event.value)
      .subscribe({
        next: () => this.snackBar.open('Visibility changed', '', { duration: 3000 }),
        error: err => this.snackBar.open(err.error.message, '', { duration: 3000 })
      });
  }

  createDeepLink(courseId: number) {
    window.location.href =`${EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Url)}/mobile-app-link/course/${courseId}`
  }

  isAndroid() {
    return this.os === 'android';
  }

  isIos() {
    return this.os === 'ios';
  }

  isUnknown() {
    return this.os === 'unknown';
  }

  isDT() {
    return this.auth.isDT();
  }

  isDev() {
    return EnvironmentHelper.isDev();
  }

  isAixp() {
    return EnvironmentHelper.isAixp();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-showcase-card-info',
  templateUrl: './showcase-card-info.component.html',
  styleUrls: ['./showcase-card-info.component.scss']
})
export class ShowcaseCardInfoComponent {

  @Input()
  routeUrl: string;

  @Input()
  routeId: number = null;

  @Input()
  queryParams: any = null;

  @Input()
  routeMessage: string;

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  description: string;

  @Input()
  imageUrl: string;

  @Input()
  isMobile: boolean = false;

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router) { }

  goTo(): void {
    if (this.isMobile)
      return;

    let route: any[] = [this.routeUrl];

    if (this.routeId)
      route.push(this.routeId);

    this.router.navigate(route, { queryParams: this.queryParams });
  }

}

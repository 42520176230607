import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { differenceInDays } from 'date-fns';
import { Helper } from 'src/app/helpers/helper';
import { MasterDTO } from 'src/app/models/dto/masterDTO';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { MasterState } from 'src/app/models/masterState';

@Component({
  selector: 'app-master-table',
  templateUrl: './master-table.component.html',
  styleUrl: './master-table.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class MasterTableComponent {

  private _masters: MasterDTO[] = [];

  @Input()
  set masters(value: MasterDTO[]) {
    if (!value)
      return;

    this.masterAuthors = value
      .map(m => m.author)
      .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);

    this.types = value
      .map(m => m.type)
      .filter((value, index, self) => self.findIndex(v => v === value) === index)
      .map(t => {
        return {
          name: this.getType(t),
          value: t
        }
      });

    this._masters = value;
    this.dataSource.data = value;
  }

  dataSource: MatTableDataSource<MasterDTO> = new MatTableDataSource();
  derivedDataSource: MatTableDataSource<MasterDTO> = new MatTableDataSource();

  displayedColumns: string[] = ['plannedDate', 'author', 'name', 'description', 'price', 'type', 'state', 'view'];
  derivedDisplayedColumns: string[] = ['dates', 'user', 'derivedType', 'derivedState', 'view'];

  selectedMaster: MasterDTO = undefined;
  
  masterAuthors: UserDTO[] = [];
  types: { name: string, value: number }[] = [];
  states: { name: string, value: number }[] = [
    {
      name: MasterState.getState(MasterState.offline),
      value: MasterState.offline
    },
    {
      name: MasterState.getState(MasterState.online),
      value: MasterState.online
    },
    {
      name: MasterState.getState(MasterState.planned),
      value: MasterState.planned
    },
    {
      name: MasterState.getState(MasterState.running),
      value: MasterState.running
    },
    {
      name: MasterState.getState(MasterState.finished),
      value: MasterState.finished
    }
  ];

  searchWordForm: FormControl<string> = new FormControl();
  authorForm: FormControl<number> = new FormControl(-1);
  typeForm: FormControl<number> = new FormControl(-1);
  stateForm: FormControl<number> = new FormControl(-1);

  searchForm: FormControl = new FormControl();

  @ViewChild('mastersPaginator') set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  @ViewChild('mastersSort') set sort(value: MatSort) {

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'plannedDate':
          return item.startDate;
        case 'author':
          return `${item.author.name} ${item.author.surname}`;
        case 'price':
          return this.getPrice(item.subscription);
        default:
          return item[property];
      }
    };

    this.dataSource.sort = value;

  }

  constructor(private router: Router) { }

  getTruncateString(value: string, nullValue: string, maxLength: number): string {
    return Helper.getTruncateShowcase(value, nullValue, maxLength);
  }

  getType(type: number) {
    return type === 1
         ? 'One to One'
         : type === 2
         ? 'Personal'
         : 'Standard';
  }

  getState(state: number, type: 'name' | 'color') {
    if (type === 'name')
      return MasterState.getState(state);

    if (state === MasterState.deleted)
      return 'rgb(140, 140, 140)';

    if (state === MasterState.offline)
      return 'rgb(255, 103, 103)';

    if (state === MasterState.online)
      return 'rgb(0, 150, 255)';

    if (state === MasterState.planned)
      return 'rgb(255, 165, 0)';

    if (state ===  MasterState.running)
      return 'rgb(60, 179, 113)';

    if (state === MasterState.finished)
      return 'rgb(200, 200, 200)';

    return undefined;
  }

  getPrice(subscription: SubscriptionDTO) {
    if (!subscription)
      return 'No price set';

    return subscription.isFree
         ? 'Free'
         : `${subscription.subscriptionFee.euro} €`;
  }

  applyFilter() {
    this.dataSource.filter = this.searchWordForm.value?.trim()?.toLowerCase();
  }

  filter() {
    let data = this._masters.slice();

    if (this.authorForm.value !== -1)
      data = data.filter(m => m.idAuthor === this.authorForm.value);

    if (this.typeForm.value !== -1)
      data = data.filter(m => m.type === this.typeForm.value);

    if (this.stateForm.value !== -1)
      data = data.filter(m => m.state === this.stateForm.value);

    this.dataSource.data = data;
  }

  goToMaster(masterId: number) {
    this.router.navigate(['/master', masterId], { queryParams: { page: 'sidenav' } });
  }

  showDerivedMasters(master: MasterDTO) {
    if (master.id === this.selectedMaster?.id) {
      this.selectedMaster = undefined;
      this.derivedDataSource.data = undefined;

      return;
    }

    master.derivedMaster.sort((a, b) => differenceInDays(b.startDate, a.startDate));

    this.selectedMaster = master;
    this.derivedDataSource.data = master.derivedMaster;
  }

}

<div fxLayout="column" class="component-main">
  
    <div  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div xFlex="10">
            <button mat-stroked-button (click)="goBack()">
                <mat-icon matListIcon>arrow_back</mat-icon>
                {{ 'Back' | translate }}
            </button>
        </div>
    
    <!--
      <mat-form-field appearance="legacy" fxFlex="30">
        <mat-label>{{ 'Title' | translate }}</mat-label>
        <input type="text" matInput  [(ngModel)]="surveyDto.name" [errorStateMatcher]="matcher">
        <mat-error *ngIf="nameForm.hasError('required')">
            {{ 'The title is' | translate}} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field>  
    -->
    <app-smart-edit 
        fxFlex="40"
        [label]="'Title' | translate" 
        [valore]="surveyDto.name"
        (onSave)="saveName($event)"
    ></app-smart-edit>
    
    <app-smart-edit fxFlex="50"
        [label]="'Description' | translate" 
        [valore]="surveyDto.description"
        (onSave)="saveDescription($event)"
    ></app-smart-edit>
    
    <!-- 
    <mat-form-field appearance="legacy" fxFlex="40">
        <mat-label>{{ 'Description' | translate }}</mat-label>
        <input type="text" matInput  [(ngModel)]="surveyDto.description" [errorStateMatcher]="matcher">
        <mat-error *ngIf="descriptionForm.hasError('required')">
            {{ 'The description is' | translate}} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field>  
     -->
    
    <!-- 
    <mat-form-field appearance="legacy" fxFlex="30" >
        <mat-label>{{ 'Teacher' | translate }}</mat-label>
        <mat-select matInput [formControl]="teacherForm" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let item of surveyData.teachers" [value]="item?.id">
                {{item?.surname + ' ' + item?.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="teacherForm.hasError('required')">
            {{ 'The author is' | translate }} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field>
     -->
</div>
     
<app-survey-creator *ngIf="json" [json]="json" (surveySaved)="onSurveySaved($event)"></app-survey-creator>

export class ConnStatusReply {
    public boardConnStatus: string;
    public boardIsConnected: boolean;

    constructor(boardConnStatus: string,
                boardIsConnected: boolean) {

        this.boardConnStatus = boardConnStatus;
        this.boardIsConnected = boardIsConnected;
    }
}

export class StationConnStatus extends ConnStatusReply {
    public stationId: number;

    constructor(connStatus: ConnStatusReply, stationId: number) {
        super(connStatus.boardConnStatus, connStatus.boardIsConnected);
        this.stationId = stationId;
    }
}

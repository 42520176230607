import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { differenceInSeconds } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { MasterContentDTO } from 'src/app/models/dto/masterContentDTO';
import { MasterDTO, MasterType } from 'src/app/models/dto/masterDTO';
import { MasterContentType } from 'src/app/models/masterContentType';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-master-profile',
  templateUrl: './master-profile.component.html',
  styleUrls: ['./master-profile.component.scss']
})
export class MasterProfileComponent implements OnInit {

  id: number;
  currentUser: User;
  master: MasterDTO;
  media: MasterContentDTO[] = [];
  receiving: boolean = true;
  classroom: ClassroomDTO;
  currentUserIsParticipant: boolean = false;
  currentUserIsTeacher: boolean = false;
  currentUserHavePaid: boolean = false;
  availablePlaces: number = 0;
  plannedDuration: number[] = [0, 0];
  choiceMaster: string = "showcase";

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private masterService: MasterService) { }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();

    if (this.route.snapshot.queryParamMap.has('page'))
      this.choiceMaster = this.route.snapshot.queryParamMap.get('page');

    this.getMaster();
  }

  getMaster() {
    this.master = null;

    this.masterService.getMaster(this.id, this.auth.isAuthenticated())
      .subscribe(async output => {

        if (output.type === MasterType.template) {
          
          try {

            let derived = await firstValueFrom(this.masterService.getLatestMasterOfTemplate(this.id));

            if (derived != undefined) {
              this.router.navigate(['/master', derived.id], { queryParams: { page: 'sidenav' } });

              return;
            }

          } catch (e) {
            console.error(e);
          }

        }

        this.master = output;

        this.media = this.master
          .masterContent
          .filter(c => c.type === MasterContentType.video
                    || c.type === MasterContentType.pdf
                    || c.type === MasterContentType.audio);

        let duration = this.master
          .masterContent
          .filter(c => c.type === MasterContentType.lesson)
          .map(l => differenceInSeconds(l.lesson.endPlanned, l.lesson.startPlanned))
          .reduce((partialSum, a) => partialSum += a, 0);

        this.plannedDuration = Helper.convertToTime(duration);

        if (this.master.subscription && this.master.subscription.subscriptionUser && this.auth.isAuthenticated())
          this.currentUserHavePaid = Helper.getLatestSubscriptionUser(this.master.subscription.subscriptionUser, this.currentUser.id) != null;
          //this.currentUserHavePaid = this.master.subscription.subscriptionUser.findIndex(u => u.idUser === this.currentUser.id
          //                                                                                 && u.dateFrom < this.master.stopDate
          //                                                                                 && u.dateTo > this.master.startDate) !== -1;
        
        this.getParticipants();
      });
  }

  getParticipants() {
    this.masterService.getParticipantsOfMaster(this.id)
      .subscribe({
        next: output => {
          this.classroom = output;

          if (this.master.subscription != null && this.master.subscription.subscriptionUser != null) {

          let paidUsers = this.classroom.classroomContent.filter(cc => this.master.subscription.subscriptionUser.findIndex(su => su.idUser === cc.userId) !== -1);
          this.availablePlaces = this.master.maxParticipants - paidUsers.length;
          
          //Subscriptions valide per il master corrente, metodo non corrento per il calcolo dei posti rimanenti
          //dato che un utente puo avere piu SubscriptioUser con validita differente nel tempo
          //let validSubs = this.master.subscription.subscriptionUser.filter(s => s.dateFrom < this.master.stopDate && s.dateTo > this.master.startDate);
          //let clearedSubs = [];

            //from(validSubs)
            //  .pipe(distinct(s => s.idUser))
            //  .subscribe(s => clearedSubs.push(s))
            //  .add(() => this.availablePlaces = this.master.maxParticipants - clearedSubs.length);
          } else {
            this.availablePlaces = this.master.maxParticipants;
          }

          this.currentUserIsTeacher = output.teacherClassroom.findIndex(t => t.userId === this.currentUser?.id) !== -1;
          this.currentUserIsParticipant = output.classroomContent.findIndex(s => s.userId === this.currentUser?.id) !== -1;

          this.receiving = false;
        },
        error: err => {
          console.log(err);
          this.receiving = false;
        }
      });
  }

}

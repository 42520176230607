<div class="component-main">
  <div style="margin-bottom: 20px;">

    <div fxLayout="row" fxLayoutAlign="center center">
      <h2>
        {{
          (
            (currentUser.isAdmin || currentUser.isCustomerAdmin || currentUser.isTutor || currentUser.isTeacher) ?
            'Virtual Classrooms' :
            'You belong to the following classrooms'
          )
          | translate
        }}
      </h2>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px;">
      <button mat-fab color="accent"
        *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin || currentUser.isTutor || currentUser.isTeacher"
        (click)="addClassroom()"
        [matTooltip]="'Add class' | translate">
        <mat-icon>add</mat-icon>
      </button>
    </div>

  </div>

  <div fxLayout="column" fxLayoutAlign="start center">
    <div style="width: 80%">
      <ng-container [ngTemplateOutlet]="currentUser.isAdmin ? adminList : classroomsList" [ngTemplateOutletContext]="{ classroomList: classrooms }"></ng-container>
    </div>
  </div>

</div>

<ng-template #adminList>
  <mat-accordion class="mat-table">
    <div *ngFor="let customer of customers" style="margin: 10px">
      <mat-expansion-panel (afterExpand)="setCustomer(customer[0])">
        <mat-expansion-panel-header class="mat-row">
          <mat-panel-title>
            <div style="text-align: start; align-self: center;" fxFlex>
              {{ customer[0].customerName }}
            </div>
            <div style="text-align: end;" fxFlex="20">
              <mat-icon *ngIf="currentCustomerId === customer[0].idCustomer"
                [matTooltip]="'Selected' | translate"
                [matTooltipPosition]="'before'">
                done
              </mat-icon>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <ng-container *ngTemplateOutlet="classroomsList; context: { classroomList: customer }"></ng-container>
        </ng-template>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</ng-template>

<ng-template #classroomsList let-classroomList="classroomList">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-card *ngFor="let classroom of classroomList"
      appearance="outlined"
      class="card-list"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px">

      <span fxFlex="40" style="font-weight: bold; padding-left: 12px;" class="title strong">
        {{ classroom.name }}
      </span>

      <span fxFlex="60">
        {{ classroom.description }}
      </span>

      <div fxLayout="row" fxLayoutAlign="start">
        <button mat-icon-button color="warn"
          *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin || classroom.isTeacher"
          (click)="deleteClassroom(classroom.id)"
          [disabled]="!currentUser.isAdmin && !currentUser.isCustomerAdmin && currentUser.id !== classroom.idAuthor"
          [matTooltip]="'Delete'| translate">
          <mat-icon>
            delete
          </mat-icon>
        </button>
        <button mat-icon-button
          *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin || classroom.isTeacher"
          (click)="editClassroom(classroom)"
          [matTooltip]="'edit'| translate">
          <mat-icon>
            edit
          </mat-icon>
        </button>
        <button mat-icon-button
          *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin || classroom.isTeacher"
          (click)="goToClassroom(classroom)"
          [matTooltip]="'Go to class detail' | translate">
          <mat-icon>
            people_alt
          </mat-icon>
        </button>
        <button mat-icon-button
          *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin || classroom.isTeacher"
          (click)="openUserStatus(classroom)"
          [matTooltip]="'Class data' | translate">
          <mat-icon>
            info
          </mat-icon>
        </button>
      </div>

    </mat-card>
  </div>
</ng-template>

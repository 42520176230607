import { Component, OnInit } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { StationDTO } from 'src/app/models/dto/stationDTO';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { IsolaDTO } from 'src/app/models/dto/isolaDTO';
import { AirTimeDTO } from 'src/app/models/dto/airTimeDTO';
import { firstBy } from 'thenby';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { SignalRService } from 'src/app/services/signalr.service';
import { MatDialog } from '@angular/material/dialog';
import { AddRoomComponent } from 'src/app/popup/add-room/add-room.component';
import { AddStationComponent } from 'src/app/popup/add-station/add-station.component';

export interface StationsInfo {
  pos: number;
  onLine: number;
  error: number;
}

@Component({
  selector: 'app-stanza',
  templateUrl: './stanza.component.html',
  styleUrls: ['./stanza.component.scss']
})
export class StanzaComponent implements OnInit {

  row = 0;
  column = 0;
  isola: IsolaDTO;
  isole: IsolaDTO[][] = [];
  currentUser: User;
  allStations: StationDTO[] = [];
  stations: StationDTO[] = [];
  rooms: RoomDTO[] = [];
  roomSelected: RoomDTO;
  idxRoomSelected = -1;
  roomsStationsInfo: StationsInfo[];
  airtimes: AirTimeDTO[] = [];
  users: any[] = [];
  customers: CustomerDTO[] = [];
  selectedCustomer: number = 0;
   
  constructor(private auth: AuthService,
              private calendar: CalendarService,
              private signalR: SignalRService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.signalR.init(this.currentUser.signalRToken, this.currentUser);

    this.getCustomers();
    this.refresh();
  }

  isAdmin () {
    return this.auth.isAdmin();
  }

  getCustomers() {
    this.customers = null;
    this.calendar.getCustomerForAdmin()
      .subscribe(output => {
        if(output.length > 0) {
          this.customers = output;
          this.selectedCustomer = this.currentUser.idCustomer;
        } else {
          this.customers = [];
        }
      });
  }
  
  refresh() {
    let idxRoom = this.roomSelected == null || this.roomSelected.customerId != this.selectedCustomer
                ? -1
                : this.rooms.findIndex(room => room.id === this.roomSelected.id);

    let idxCustomer = this.selectedCustomer != null ? this.selectedCustomer : null;
    this.airtimes = [];

    this.calendar.getOnlineUsersAirTime()
      .subscribe({
        next: output => {
          this.airtimes = output.sort(firstBy((c: AirTimeDTO) => c.user.customerId).thenBy((u: AirTimeDTO) => u.user.id));
          this.calendar.getRoomsForAll(idxCustomer)
            .subscribe( output => {
              this.rooms = output;
              this.getErrorRooms();

              if (idxRoom === -1)
                idxRoom = 0;
              
              this.getPostazioni(this.rooms[idxRoom]);
            });
        },
        error: () => {
          console.log('Cannot get online users')
          // nel caso di errore carico in ogni caso le rooms 
          this.calendar.getRoomsForAll(idxCustomer)
            .subscribe( output => {
              this.rooms = output;
              this.getErrorRooms();
              
              if (idxRoom === -1)
                idxRoom = 0;

              this.getPostazioni(this.rooms[idxRoom]);
            });
        }
      });      
  }

  getErrorRooms() {
    this.roomsStationsInfo = [];

    this.rooms.forEach(r => {
      this.roomsStationsInfo.push({
        pos: r.stations.length,
        onLine: r.stations.filter(s => s.onlineState === 1).length,
        error: r.stations.filter(s => s.workingState === 0).length
      });
    });
  }

  getPostazioni(item: RoomDTO) {
    this.roomSelected = item;
    this.stations = [];
    this.isole = [];
    this.row = 0;
    this.column = 0;

    // si parte dalla posizione 1,1 in alto a sinistra
    /*
    public id: number;
    public roomId: number;
    public name: string;
    public description: string;
    public macAddress: string;
    public state: number; // abilitata non abilitata ma se non abilitata non le vedo
    public workingState: number; rotta = 0 o funzionante = 1
    public mapRow?: number;
    public mapColumn?: number;
    public mapPosition?: number;
    public onlineState?: number; accesa spenta
    public lastCheck: Date;
    public created: Date;
    public room: RoomDTO;
    */
    if (item == null || item.stations == null || item.stations.length == 0)
      return;

    const maxRowItem = item.stations.reduce((prev, current) => {
      return (prev.mapRow > current.mapRow) ? prev : current;
    }); // returns object
    this.row = maxRowItem.mapRow;

    const maxColumnItem = item.stations.reduce((prev, current) => {
      return (prev.mapColumn > current.mapColumn) ? prev : current;
    }); // returns object
    this.column = maxColumnItem.mapColumn;

    for (let r = 0; r < this.row; r++) {
      this.isole[r] = [];
      for (let c = 0; c < this.column; c++) {
        // const isola: IsolaDTO = {pos1: null, pos2: null, pos3: null, pos4: null};
        const isola: IsolaDTO = {
                                  pos1: { station: null, user1: null, user2: null, boardOnline: false },
                                  pos2: { station: null, user1: null, user2: null, boardOnline: false },
                                  pos3: { station: null, user1: null, user2: null, boardOnline: false },
                                  pos4: { station: null, user1: null, user2: null, boardOnline: false },
                                };
        this.isole[r][c] = isola;
      }
    }

    for (let postazione of item.stations) {
        this.stations.push(postazione);

        let col = postazione.mapColumn - 1;
        let row = postazione.mapRow - 1;
        let nPos = postazione.mapPosition;

        this.isole[row][col]['pos' + nPos].station = JSON.parse(JSON.stringify(postazione));

        const airtimes = this.airtimes.filter(air => air.stationId === postazione.id);

        if (airtimes[0])
            this.isole[row][col]['pos' + nPos].user1 = JSON.parse(JSON.stringify(airtimes[0].user));

        if (airtimes[1])
            this.isole[row][col]['pos' + nPos].user2 = JSON.parse(JSON.stringify(airtimes[1].user));
    }
    
    console.log(this.isole);
  }

  signalRStatus() {
    return this.signalR.connectionStatus;
  }

  addRoom() {
    const dialogRef = this.dialog.open(AddRoomComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this.refresh();
    });
  }

  editRoom(room: RoomDTO) {
    const dialogRef = this.dialog.open(AddRoomComponent, {
      width: '500px', data: { room: room }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this.refresh();
    });
  }

  addStation() {
    const dialogRef = this.dialog.open(AddStationComponent, {
      width: '800px',
      data: { rooms: this.rooms, selectedRoom: this.roomSelected.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result)
        this.refresh();
    });
  }

  // non usato
  getStations() {
    this.allStations = null;
    this.stations = null;

    this.calendar.getStationsOfCustomer(this.currentUser.idCustomer)
      .subscribe(output => {
        this.allStations = [];
        this.stations = [];

        if (output.length > 0) {
          this.allStations = output;
          this.stations = this.allStations;
        }
      });
  }

}

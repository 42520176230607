import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Helper } from 'src/app/helpers/helper';
import { LessonSessionDTO } from '../models/dto/lessonSessionDTO';
import { firstValueFrom, Observable } from 'rxjs';
import { LessonPost } from '../models/lessonPost';

@Injectable({
    providedIn: 'root'
})
export class LessonService {

    bckEndUrlSessions: string = `${environment.apiUrl}/api/classrooms`;

    constructor(private http: HttpClient) { }

    createLesson(classId: number, lesson: LessonPost) {
        return this.http.post<LessonSessionDTO>(`${this.bckEndUrlSessions}/${classId}/lessons`, lesson,{ headers: Helper.getAuthHeader() });
    }

    getQuickLessons() {
        return this.http.get<LessonSessionDTO[]>(`${this.bckEndUrlSessions}/lessons/quick`, { headers: Helper.getAuthHeader() });
    }

    deleteLesson(id: number) {
        return firstValueFrom(this.http.delete(`${this.bckEndUrlSessions}/lessons/${id}/delete`, { headers: Helper.getAuthHeader() }));
    }

    async addLessonNote(lessonId: number, note: string) {
        return firstValueFrom(this.http.put(`${this.bckEndUrlSessions}/lessons/${lessonId}/note`, { note }, { headers: Helper.getAuthHeader() }));
    }

    getActiveStreamingLessons(): Observable<LessonSessionDTO[]> {
        return this.http.get<LessonSessionDTO[]>( `${this.bckEndUrlSessions}/lessons/activeStreaming`,{ headers: Helper.getAuthHeader() });
    }

}

import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helpers/helper';
import { UserCourseViews } from 'src/app/models/courseView';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { ViewGraphComponent, ViewGraphData } from 'src/app/popup/view-graph/view-graph.component';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { TranslateService } from '@ngx-translate/core';

const USER_VIEWS_COLUMNS: string [] = ['totalViews', 'firstView', 'lastView'];

@Component({
  selector: 'app-course-table',
  templateUrl: './course-table.component.html',
  styleUrl: './course-table.component.scss'
})
export class CourseTableComponent {

  private _courses: CourseDTO[] = [];
  private _userCourseViews: UserCourseViews[] = [];

  @Input()
  set courses(value: CourseDTO[]) {
    if (!value)
      return;

    this.courseAuthorsList = value
      .map(m => m.author)
      .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);

    this._courses = value;
    this.setData(value);
  }

  @Input()
  set userCourseViews(value: UserCourseViews[]) {
    if (!value)
      return;

    this._userCourseViews = value;
    this.setUserViews(value);

    if (!this.displayedColumns.some(c => USER_VIEWS_COLUMNS.includes(c)))
      this.displayedColumns.splice(5, 0, ...USER_VIEWS_COLUMNS);
  }

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  @ViewChild(MatSort) set sort(value: MatSort) {

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'firstView':
          return [item.firstView?.year, item.firstView?.month];
        case 'lastView':
          return [item.lastView?.month, item.lastView?.year];
        default:
          return item[property];
      }
    };

    this.dataSource.sort = value;

  }

  dataSource: MatTableDataSource<CourseData> = new MatTableDataSource();
  displayedColumns: string[] = ['joinDate', 'author', 'name', 'mode', 'price', 'state', 'view'];

  authorForm: FormControl = new FormControl(0);
  stateForm: FormControl = new FormControl(0);
  searchWordForm: FormControl = new FormControl();

  searchForm: FormControl = new FormControl();

  states: { name: string, value: number }[] = [
    { name: 'Offline', value: 0 },
    { name: 'Online', value: 1 }
  ];
  courseAuthorsList: UserDTO[] = [];

  months: { name: string, value: number }[] = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private darkService: DarkThemeService,
    private translate: TranslateService
  ) { }

  applyFilter() {
    this.dataSource.filter = this.searchWordForm.value?.trim()?.toLowerCase();
  }

  filter() {
    let data = this._courses.slice();

    if (this.authorForm.value !== 0)
      data = data.filter(c => c.idAuthor === this.authorForm.value);

    if (this.stateForm.value !== 0)
      data = data.filter(c => c.state === this.stateForm.value);

    this.setData(data);
  }

  getCourseMode(mode: number, type: 'name' | 'code' | 'color') {
    if (mode === 0)
      return type === 'name'
           ? 'Priority'
           : type === 'code'
           ? 0
           : 'rgb(0, 150, 255)';

    if (mode === 1)
      return type === 'name'
           ? 'Sequence'
           : type === 'code'
           ? 1
           : 'rgb(60, 179, 113)';

    return undefined;
  }

  getCourseState(state: number, type: 'name' | 'code' | 'color') {
    if (state === 0)
      return type === 'name'
           ? 'Offline'
           : type === 'code'
           ? 0
           : 'rgb(255, 103, 103)';

    if (state === 1)
      return type === 'name'
           ? 'Online'
           : type === 'code'
           ? 1
           : 'rgb(60, 179, 113)';

    return undefined;
  }

  getPrice(subscription: SubscriptionDTO) {
    if (!subscription)
      return 'No price set';

    return subscription.isFree
         ? 'Free'
         : `${subscription.subscriptionFee.euro} €`;
  }

  getMonthName(month: number) {
    return this.months.find(m => m.value === month)?.name;
  }

  goToCourse(courseId: number) {
    this.router.navigate(['/course', courseId]);
  }

  viewUserGraph(data: CourseData) {

    this.dialog.open(ViewGraphComponent, {
      data: <ViewGraphData>{
        title: this.translate.instant('Views of value', { value: data.name }) + ` (${data.totalViews})`,
        width: '950px',
        height: '475px',
        dataset: [{
          data: data.userViews.map(ucv => ucv.totalViews),
          label: this.translate.instant('Views'),
          backgroundColor: '#ac92ec'
        }],
        labels: data.userViews.map(cv => `${this.translate.instant(this.getMonthName(cv.month)) ?? '-'} ${cv.year}`),
        plugins: [DataLabelsPlugin],
        options: {
          responsive: true,
          scales: {
            x: {
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              },
              grid: {
                display: false
              }
            },
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
                color: this.darkService.isSetDark ? 'white' : ''
              },
              grid: {
                display: true,
                color: this.darkService.isSetDark ? 'grey' : ''
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            },
            datalabels: {
              color: this.darkService.isSetDark ? 'white' : ''
            }
          }
        },
        type: 'bar'
      },
      width: '1000px'
    });

  }

  private setData(values: CourseDTO[]) {
    this.dataSource.data = values.map(v => <CourseData>{
      id: v.id,
      joinDate: v.classroom?.classroomContent?.length > 0
              ? v.classroom.classroomContent[0].created
              : v.classroom.teacherClassroom?.length > 0
              ? v.classroom.teacherClassroom[0].created
              : undefined,
      author: `${v.author.name} ${v.author.surname}`,
      name: v.name,
      description: Helper.getTruncateShowcase(v.description, '', 100),
      mode: this.getCourseMode(v.mode, 'name'),
      price: this.getPrice(v.subscription),
      state: this.getCourseState(v.state, 'name'),
      course: v
    });

    if (this._userCourseViews.length > 0)
      this.setUserViews(this._userCourseViews);
  }

  private setUserViews(values: UserCourseViews[]) {
    this.dataSource.data = this.dataSource.data
      .slice()
      .map(d => {

        d.userViews = values.filter(v => v.idCourse === d.id);

        d.totalViews = d.userViews
          .map(uv => uv.totalViews)
          .reduce((partialSum, v) => partialSum + v, 0);

        d.firstView = d.userViews.length > 0
                    ? d.userViews[0]
                    : undefined;

        d.lastView = d.userViews.length > 0
                   ? d.userViews[d.userViews.length - 1]
                   : undefined;

        return d;

      }
    );
  }

}

class CourseData {
  id: number;
  joinDate: Date;
  author: string;
  name: string;
  description: string;
  mode: string;
  price: string;
  state: string;
  totalViews: number = 0;
  firstView: UserCourseViews;
  lastView: UserCourseViews;
  userViews: UserCourseViews[] = [];
  course: CourseDTO;
}

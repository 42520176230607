<div class="component-main">

    <div style="text-align: center">
        <h2>{{ 'My webinar' | translate }}</h2>
    </div>

    <app-webinar-table
        [webinars]="webinars"
        [showAuthorSearch]="isCustomerAdmin()"
        [showModeratorSearch]="isCustomerAdmin()"
        [showSpeakerSearch]="isCustomerAdmin()">
    </app-webinar-table>

</div>

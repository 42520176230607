<h1 mat-dialog-title>{{ 'Data import' | translate }}</h1>
<div mat-dialog-content>

    <div *ngIf="!recap" fxLayout="row" fxLayoutAlign="space-between">

        <div fxFlex="48">

            <h3>
                {{ 'Current data' | translate }}
            </h3>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'Unique Id' | translate }}</mat-label>
                <input matInput [formControl]="currentUniqueIdForm" type="text" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input matInput [formControl]="currentNameForm" type="text" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'Surname' | translate }}</mat-label>
                <input matInput [formControl]="currentSurnameForm" type="text" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'Email' | translate }}</mat-label>
                <input matInput [formControl]="currentEmailForm" type="text" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'Role' | translate }}</mat-label>
                <input matInput [formControl]="currentRoleForm" type="text" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>CF</mat-label>
                <input matInput [formControl]="currentCFForm" type="text" readonly>
            </mat-form-field>

        </div>

        <div fxFlex="48">

            <h3>
                {{ 'New data' | translate }}
            </h3>

            <mat-form-field appearance="outline" [class.different-value-form]="isDifferent(currentUniqueIdForm, uniqueIdForm)">
                <mat-label>{{ 'Unique Id' | translate }}</mat-label>
                <input matInput [formControl]="uniqueIdForm" type="text" readonly>
                <mat-icon matSuffix *ngIf="isDifferent(currentUniqueIdForm, uniqueIdForm)" class="different-value">fiber_new</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" [class.different-value-form]="isDifferent(currentNameForm, nameForm)">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input matInput [formControl]="nameForm" type="text" readonly>
                <mat-icon matSuffix *ngIf="isDifferent(currentNameForm, nameForm)" class="different-value">fiber_new</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" [class.different-value-form]="isDifferent(currentSurnameForm, surnameForm)">
                <mat-label>{{ 'Surname' | translate }}</mat-label>
                <input matInput [formControl]="surnameForm" type="text" readonly>
                <mat-icon matSuffix *ngIf="isDifferent(currentSurnameForm, surnameForm)" class="different-value">fiber_new</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" [class.different-value-form]="isDifferent(currentEmailForm, emailForm)">
                <mat-label>{{ 'Email' | translate }}</mat-label>
                <input matInput [formControl]="emailForm" type="text" readonly>
                <mat-icon matSuffix *ngIf="isDifferent(currentEmailForm, emailForm)" class="different-value">fiber_new</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" [class.different-value-form]="isDifferent(currentRoleForm, roleForm)">
                <mat-label>{{ 'Role' | translate }}</mat-label>
                <input matInput [formControl]="roleForm" type="text" readonly>
                <mat-icon matSuffix *ngIf="isDifferent(currentRoleForm, roleForm)" class="different-value">fiber_new</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" [class.different-value-form]="isDifferent(currentCFForm, cFForm)">
                <mat-label>CF</mat-label>
                <input matInput [formControl]="cFForm" type="text" readonly>
                <mat-icon matSuffix *ngIf="isDifferent(currentCFForm, cFForm)" class="different-value">fiber_new</mat-icon>
            </mat-form-field>

        </div>

    </div>

    <div *ngIf="recap">

        <h3>
            {{ 'Recap' | translate }}
        </h3>

        <div class="users-table">
            <table mat-table [dataSource]="usersSource" matSort #matSort="matSort">
                <ng-container matColumnDef="uniqueId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.uniqueId }} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="surname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Action' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon
                            [matTooltip]="(element.update ? 'Update' : 'Keep') | translate"
                            [class.different-value]="element.update">
                            {{ element.update ? 'file_upload' : 'file_upload_off' }}
                        </mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="usersColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: usersColumns"></tr>
            </table>
    
            <!--mat-paginator #paginator
                [length]="usersSource?.data.length"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25]"
                color="primary">
            </mat-paginator-->
        </div>

    </div>

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>

    <div fxLayout="row">
        <button mat-stroked-button
            *ngIf="usersIndex > 0 || recap"
            (click)="prev()">
            {{ 'Back' | translate }}
        </button>
        <button mat-flat-button
            *ngIf="!recap"
            (click)="next(false)"
            color="primary">
            {{ 'Skip' | translate }}
        </button>
        <button mat-flat-button
            *ngIf="!recap"
            (click)="next(true)"
            color="accent">
            {{ 'Update' | translate }}
        </button>
        <button mat-flat-button
            *ngIf="recap"
            (click)="save()"
            color="accent">
            {{ 'Save' | translate }}
        </button>
    </div>
</div>

<div class="bar-container"
    [class.mat-elevation-z24]="overlay"
    [class.standard-height]="!overlay">
    <div class="overlay-container">
        <div class="toolbar-header">
            <h2 style="margin-left: 16px;">{{ 'Virtual rooms' | translate }}</h2>
            <button mat-icon-button
                (click)="onClose.emit(true)"
                [matTooltip]="'Close' | translate">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="rooms-container">
            <mat-card class="room-card" *ngFor="let room of rooms" [class.outline]="room.id === currentRoomId">
                <mat-card-header class="header">
                    <mat-card-title>{{ room.name }}</mat-card-title>
                    <button mat-mini-fab color="accent"
                        (click)="joinRoom(room.id)"
                        [disabled]="currentRoomId === room.id"
                        [matTooltip]="'Join room' | translate">
                        <mat-icon>input</mat-icon>
                    </button>
                    <button mat-mini-fab *ngIf="selectEnabled"
                        (click)="onSelect.emit(room)"
                        [matTooltip]="'Select room' | translate"
                        color="primary">
                        <mat-icon>trending_flat</mat-icon>
                    </button>
                </mat-card-header>
            </mat-card>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { AirTimeDTO } from 'src/app/models/dto/airTimeDTO';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ConferenceDTO } from '../models/dto/conferenceDTO';
import { JoinedLessonDTO } from '../models/dto/joinedLessonDTO';
import { Helper } from '../helpers/helper';

export const HASHTAGS_HEADERS: string[] = ["Hashtag1", "Hashtag2", "Hashtag3", "Hashtag4", "Hashtag5", "Hashtag6", "Hashtag7", "Hashtag8", "Hashtag9", "Hashtag10"];

const OPTIONS: {
    fieldSeparator: string,
    quoteStrings: string,
    decimalseparator: string,
    showLabels: boolean,
    showTitle: boolean,
    title: string,
    useBom: boolean,
    noDownload: boolean,
    nullToEmptyString: boolean,
    headers: string[]
} = {
    fieldSeparator: ';', // Defines the field separator character
    quoteStrings: '"', // If provided, will use this characters to "escape" fields, otherwise will use double quotes as deafult
    decimalseparator: 'locale', // Defines the decimal separator character (default is .). If set to "locale", it uses the language sensitive representation of the number.
    showLabels: true, // If provided, would use this attribute to create a header row
    showTitle: true,
    title: undefined,
    useBom: true, // If true, adds a BOM character at the start of the CSV
    noDownload: false, // If true, disables automatic download and returns only formatted CSV
    nullToEmptyString: true, // If true, all null values will be changed to empty strings,
    headers: []
};

@Injectable({
    providedIn: 'root'
})
export class CsvExporterService {

    public exportDataToCsv(data: any, headers: string[], title: string, filename?: string) {
        if (!data || data.length === 0)
            return;

        let options = OPTIONS;

        options.headers = headers;
        options.title = title;

        if (Helper.isNullOrEmpty(filename))
            filename = title;

        filename = filename.toLowerCase().replace(' ', '_');

        new AngularCsv(data, filename, options);
    }

    public exportAirTimesToCsv(values: { data: AirTimeDTO[], lesson: LessonSessionDTO, webinar?: ConferenceDTO }[], title?: string, filename?: string) {
        let options = OPTIONS;
        let exportableData = [];

        if (!Helper.isNullOrEmpty(filename))
            filename = Helper.clean(filename);

        values.forEach(v => {

            let data = [];
    
            data = this.exportAirTimes(v.data, v.lesson, v.webinar);
            options.headers = [
                "Teacher",
                "Lesson Name",
                "Email",
                "LessonId",
                "Lesson Start",
                "Lesson Stop",
                "First Name",
                "Last Name",
                "Presence Start",
                "Presence End",
                "Presence Time",
                "inRoom",
                "RoomName",
                "StationName",
                "EventId",
                "ParticipantId"
            ];
    
            if (v.webinar)
                options.headers = [...options.headers, ...HASHTAGS_HEADERS];

            exportableData = exportableData.concat(data);

        });

        if (exportableData.length === 0)
            return;

        if (values.length === 1) {
            let startDate = new Date(values[0].lesson.startPlanned).toLocaleString();

            options.title = `${values[0].lesson.name} - ${startDate}`;
            filename = `${values[0].lesson.id}-${values[0].lesson.name}-${startDate}`;
        }

        if (!Helper.isNullOrEmpty(title))
            options.title = title;

        new AngularCsv(exportableData, filename ?? 'lesson_export', options);
    }

    public exportJoinedLessonsToCsv(values: { data: JoinedLessonDTO[], title: string, author: string, hashTags: string[] }[], title?: string, filename?: string) {
        let options = OPTIONS;
        let exportableData = [];

        if (!Helper.isNullOrEmpty(filename))
            filename = Helper.clean(filename);

        values.forEach(v => {

            let data = [];
    
            data = this.exportJoinedLessons(v.data, v.title, v.author, v.hashTags);
            options.headers = [
                "Teacher",
                "Lesson Name",
                "LessonId",
                "Lesson Start",
                "Lesson Stop",
                "First Name",
                "Last Name",
                "Email",
                "Presence Start",
                "Presence End",
                "Presence Time",
                "RoomName",
                "StationName"
            ];

            options.title = v.title;
    
            if (v.hashTags)
                options.headers = [...options.headers, ...HASHTAGS_HEADERS];

            exportableData = exportableData.concat(data);

        });

        if (exportableData.length === 0)
            return;

        if (!Helper.isNullOrEmpty(title))
            options.title = title;

        new AngularCsv(exportableData, filename ?? 'lesson_export', options);
    }

    private exportAirTimes(airTimes: AirTimeDTO[], lesson: LessonSessionDTO, webinar?: ConferenceDTO): ExportableAirTimeCsv[] {
        if (!airTimes)
            return [];

        return airTimes.map(airTime => <ExportableAirTimeCsv>{
            teacher: `${lesson.teacher.surname} ${lesson.teacher.name}`,
            lessons: lesson.name,
            email: airTime.user.email,
            lessonsId: lesson.id.toString(),
            lessonStart: new Date(lesson.startPlanned).toLocaleString(),
            lessonEnd: new Date(lesson.endPlanned).toLocaleString(),
            fistname: airTime.user.name,
            lastname: airTime.user.surname,
            presenceStart: new Date(airTime.startDate).toLocaleString(),
            presenceEnd: new Date(airTime.endDate).toLocaleString(),
            presenceTime: airTime.timeInLessons.slice(0, 8),
            inRoom: airTime.physicalToken,
            roomName: airTime.station?.room?.name,
            stationName: airTime.station?.name,
            eventId: lesson.classroom?.eventId,
            participantId: airTime.user?.uniqueId,
            hashtag1: webinar?.hashTag[0],
            hashtag2: webinar?.hashTag[1],
            hashtag3: webinar?.hashTag[2],
            hashtag4: webinar?.hashTag[3],
            hashtag5: webinar?.hashTag[4],
            hashtag6: webinar?.hashTag[5],
            hashtag7: webinar?.hashTag[6],
            hashtag8: webinar?.hashTag[7],
            hashtag9: webinar?.hashTag[8],
            hashtag10: webinar?.hashTag[9]
        });
    }

    private exportJoinedLessons(joinedLessons: JoinedLessonDTO[], title: string, author: string, hashTags: string[] = []): ExportableJoinedLessonCsv[] {
        if (!joinedLessons)
            return [];

        return joinedLessons.map(joinedLesson => <ExportableJoinedLessonCsv>{
            teacherName: author,
            lessonName: title,
            lessonId: String(joinedLesson.lessonsId),
            lessonStart: new Date(joinedLesson.lessonsRange.min).toLocaleString(),
            lessonEnd: new Date(joinedLesson.lessonsRange.max).toLocaleString(),
            fistname: joinedLesson.user.name,
            lastname: joinedLesson.user.surname,
            email: joinedLesson.user.email,
            presenceStart: new Date(joinedLesson.startDate).toLocaleString(),
            presenceEnd: new Date(joinedLesson.stopDate).toLocaleString(),
            presenceTime: joinedLesson.timeInLessons.slice(0, 8),
            roomName: joinedLesson.station?.room?.name,
            stationName: joinedLesson.station?.name,
            hashtag1: this.getHashtag(hashTags, 0),
            hashtag2: this.getHashtag(hashTags, 1),
            hashtag3: this.getHashtag(hashTags, 2),
            hashtag4: this.getHashtag(hashTags, 3),
            hashtag5: this.getHashtag(hashTags, 4),
            hashtag6: this.getHashtag(hashTags, 5),
            hashtag7: this.getHashtag(hashTags, 6),
            hashtag8: this.getHashtag(hashTags, 7),
            hashtag9: this.getHashtag(hashTags, 8),
            hashtag10: this.getHashtag(hashTags, 9),
        });
    }

    private getHashtag(values: string[], index: number) {
        if (!values || !index)
            return undefined;

        if (index >= values.length)
            return undefined;

        return values[index];
    }

}

interface ExportableAirTimeCsv extends hashtags {
    teacher: string;
    lessons: string;
    email: string;
    lessonsId: string;
    lessonStart: string;
    lessonEnd: string;
    fistname: string;
    lastname: string;
    presenceStart: string;
    presenceEnd?: string;
    presenceTime: any;
    inRoom: boolean;
    roomName: string;
    stationName: string;
    eventId: string;
    participantId: string;
}

interface ExportableJoinedLessonCsv extends hashtags {
    teacherName: string;
    lessonName: string;
    lessonId: string;
    lessonStart: string;
    lessonEnd: string;
    fistname: string;
    lastname: string;
    email: string;
    presenceStart: string;
    presenceEnd?: string;
    presenceTime: any;
    roomName: string;
    stationName: string;
}

export interface ExportUserCsv {
    username: string;
    surname: string;
    name: string;
    email: string;
    role: string;
    date: string;
}

export interface StreamingTime {
    year: number;
    month: number;
    hours?: number | string;
    minutes?: number;
    seconds?: number;
}

export interface ActiveUsers {
    activeUsers: number;
    registeredUsers: number;
    month: number;
    year: number;
}

export interface ExportDtUserCsv {
    email: string;
    name: string;
    surname: string;
    province: string;
    phoneNumber: string;
    qualification: string;
}

export interface Payment extends hashtags {
    date: string;
    time: string;
    name: string;
    surname: string;
    email: string;
    province: string;
    qualification: string;
    telephone: string;
    product: string;
    amount: number;
    currency: string;
    type: string;
    source: string;
}

export interface hashtags {
    hashtag1?: string;
    hashtag2?: string;
    hashtag3?: string;
    hashtag4?: string;
    hashtag5?: string;
    hashtag6?: string;
    hashtag7?: string;
    hashtag8?: string;
    hashtag9?: string;
    hashtag10?: string;
}

export interface Subscriber extends hashtags {
    name: string;
    surname: string;
    email: string;
    role: string;
    province: string;
    telephone: string;
    qualification: string;
    purchasedData: string;
}

export interface CourseView extends hashtags {
    name: string;
    author: string;
    description: string;
    header: string;
    footer: string;
    summary: string;
    month: number;
    year: number;
    totalViews: number;
}

export interface StudentView extends hashtags {
    name: string;
    surname: string;
    email: string;
    role: string;
    city: string;
    qualification: string;
    telephone: string;
    views: number;
    courseName: string;
    courseAuthor: string;
    courseDescription: string;
    courseHeader: string;
    courseFooter: string;
    courseSummary: string;
}

export interface WebinarTotalData {
    webinarPerformed: number;
    streamingTime: string;
    participants: number;
    month: number;
    year: number;
}

export interface PaymentReport {
    month?: number;
    quarter?: number;
    year: number;
    webinarPurchased: number;
    webinarAmount: string;
    coursesPurchased: number;
    coursesAmount: string;
    packagesPurchased: number;
    packagesAmount: string;
    totalPayments: number;
    totalAmount: string;
}

export interface PaymentsTable {
    month: number;
    year: number;
    day: number;
    name: string;
    surname: string;
    product: string;
    productName: string;
    amount: string;
    currency: string;
    paymentType: string;
}

<div class="column-arrange">
    <div class="row-arrange max-width">
        <ng-content select="[currentLanguageForm]"></ng-content>
        <button mat-icon-button
            (click)="toggleForm()"
            [disabled]="disabled"
            [matTooltip]="(showLanguageForm ? 'Close' : 'Change translation for other languages') | translate">
            <mat-icon>{{ showLanguageForm ? 'close' : 'language' }}</mat-icon>
        </button>
    </div>
    <div *ngIf="showLanguageForm" class="row-arrange max-width">
        <mat-form-field style="flex: 1">
            <mat-label>{{ label }}</mat-label>
            <input matInput
                *ngIf="mode === 'input'"
                [formControl]="selectedForm"
                (keyup)="sendData()"
                type="text">
            <textarea matInput
                *ngIf="mode === 'textarea'"
                [formControl]="selectedForm"
                (keyup)="sendData()">
            </textarea>
        </mat-form-field>
        <mat-form-field style="flex: 0.5">
            <mat-label>{{ 'Language' | translate }}</mat-label>
            <mat-select [formControl]="selectedLanguage" (selectionChange)="setForm()">
                <mat-option *ngFor="let language of languages" [value]="language.code">
                    {{ language.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>    
</div>

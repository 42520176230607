<mat-card appearance="outlined" class="mat-elevation-z8" fxLayout="column">

    <img mat-card-image [src]="thumbnail()" (click)="goTo()" loading="lazy">
    <div class="videoText mat-elevation-z3">
        <h5 style="margin: 0%;">{{ typeTitle() }}</h5>
    </div>

    <mat-card-header>
        <mat-card-title [matTooltip]="content.name" class="webkitLineTitle">
            {{ content.name }}
        </mat-card-title>
        <mat-card-subtitle class="truncate-text">
            {{ content.hashTag }}
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content
        [innerHtml]="content.description ?? '&nbsp;'"
        [matTooltip]="content.description"
        [style.color]="darkService.isSetDark ? 'white' : 'rgba(0, 0, 0, 0.54)'"
        [class]="content.hashTag.length > 0 ? 'webkitLineReduced' : 'webkitLine'">
    </mat-card-content>

    <div fxFlex></div>

    <div fxLayout="row" fxLayoutAlign="space-between" style="margin-top: 5px; margin-right:10px; margin-left:-5px">
        <span (click)="goToProfile()" style="color: #5092CF; white-space: pre; cursor: pointer; padding-left: 16px;">
            {{ author.length > 23 ? (author | slice:0:20) + '...' : author }}
        </span>
        <span style="white-space: pre;">
            {{ (content.created | date: 'dd/MM/yyyy') }}
        </span>
    </div>

    <mat-card-actions fxLayoutGap="10px">

        <app-content-action-buttons
            [authorId]="courseAuthorId"
            [content]="content"
            [contentHelper]="contentHelper"
            (onDelete)="onDelete.emit(true)"
            fxFlex="85">
        </app-content-action-buttons>

        <ng-container *ngIf="!this.selectionEnabled()">
            <div *ngIf="!isSurveyContent(); else authorSurveyActions" fxLayout="row" fxLayoutAlign="end end" fxFlex>
                <button mat-button disabled style="font-weight: bold; color: grey; font-size: 20px;">
                    <mat-icon>thumb_up</mat-icon>
                    {{ content.courseLikes.length }}
                </button>
            </div>
    
            <ng-template #authorSurveyActions>
                <div *ngIf="currentUser.id === courseAuthorId" fxLayout="row" fxLayoutAlign="end end" fxFlex>
                    <button mat-icon-button [matTooltip]="'View survey' | translate"
                        (click)="goToSurvey()">
                        <mat-icon>preview</mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="'View analytics' | translate"
                        (click)="goToSurveyAnalytics()">
                        <mat-icon>analytics</mat-icon>
                    </button>
                    <button mat-icon-button [matTooltip]="'Show survey answer' | translate" 
                        (click)="goToSurveyResults()"
                        [disabled]="courseState !== 1">
                        <mat-icon [matBadgeHidden]="content.lessonSession.surveyAnswer.length === 0" 
                            [matBadge]="content.lessonSession.surveyAnswer.length">
                            people
                        </mat-icon> 
                    </button>
                </div>
            </ng-template>
        </ng-container>

        <div fxLayout="column" fxLayoutAlign="end end" *ngIf="selectionShow()" fxFlex>
            <mat-checkbox color="accent"
                (change)="selectionToggle($event)"
                [checked]="selectionChecked()"
                [disabled]="selectionDisabled()">
                {{ 'Select' | translate }}
            </mat-checkbox>
        </div>
    </mat-card-actions>

</mat-card>

import { SubscriptionDTO } from "./subscriptionDTO";

export class SubscriptionLevelDTO {
    public id: number;
    public idSubscription: number;
    public nameKey: string;
    public name: string;
    public idStringName: number;
    public description: string;
    public idStringDescription?: number;
    public summary: string;
    public idStringSummary?: number;
    public header: string;
    public idStringHeader?: number;
    public footer: string;
    public idStringFooter?: number;
    public imageUrl: string;
    public userNoMax: number;
    public storageMax: number;
    public authoring: number;
    public created: Date;
    public subscription: SubscriptionDTO;
}

<div class="component-main">

  <h1 style="text-align: center">{{ 'System status' | translate }}</h1>

  <div fxLayout="row">

    <div fxLayout="column" fxLayoutGap="20px" fxFlex="48">

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">

        <h2>{{ 'Active streaming' | translate }}</h2>

        <button mat-fab color="primary" (click)="getSessions()" [matTooltip]="'Refresh' | translate">
          <mat-icon>refresh</mat-icon>
        </button>

      </div>

      <mat-accordion class="my-headers-align" [hidden]="sessions.length === 0">

        <mat-expansion-panel *ngFor="let session of sessions" (opened)="setCurrentSession(session)" (closed)="setCurrentSession()" style="margin-bottom: 10px">

          <mat-expansion-panel-header>

            <mat-panel-title fxFlex="20" class="session-title">
              {{ session.lesson.name }}
            </mat-panel-title>

            <mat-panel-description fxLayout="row" fxLayoutGap="10px" fxFlex>

              <div>{{ session.customer.name }}</div>
              <div>{{ session.id }}</div>
              <div>{{ getLessonType(session.lesson.typeId) }}</div>
              <div>{{ ('Users' | translate) + ' ' + session.connections.numberOfElements }}</div>
              <div>{{ session.recording ? ('Recording' | translate) : '' }}</div>
              <div>
                {{ 'From' | translate }} {{ session.createdAt | date: 'HH:mm' }} {{ 'to' | translate }} {{ session.lesson.endPlanned ? (session.lesson.endPlanned | date: 'HH:mm ') : '??:??' }}
              </div>
              <!--div>
                <button mat-icon-button color="warn" (click)="closeSession(session.id)"
                  [matTooltip]="'Admin force close' | translate">
                  <mat-icon>videocam_off</mat-icon>
                </button>
              </div-->
            </mat-panel-description>

          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>

            <div style="max-height: 50vh; overflow: auto;">

              <table mat-table [dataSource]="selectedSession">

                <ng-container matColumnDef="usr">
                  <th mat-header-cell *matHeaderCellDef>{{ 'User' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container *ngTemplateOutlet="userCol; context: { user: element.user }"></ng-container>
                  </td>
                </ng-container>

                <ng-container matColumnDef="urole">
                  <th mat-header-cell *matHeaderCellDef>{{ 'Role' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.user ? (getUserRole(element.user) | role | translate) : '-' }}</td>
                </ng-container>

                <ng-container matColumnDef="connId">
                  <th mat-header-cell *matHeaderCellDef>Connection ID</th>
                  <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container>

                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef>{{ 'Role' | translate }} (Streaming)</th>
                  <td mat-cell *matCellDef="let element">{{ element.role }}</td>
                </ng-container>

                <ng-container matColumnDef="platform">
                  <th mat-header-cell *matHeaderCellDef>{{ 'Platform' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.platform }}</td>
                </ng-container>

                <ng-container matColumnDef="ids">
                  <th mat-header-cell *matHeaderCellDef>Stream IDs</th>
                  <td mat-cell *matCellDef="let element" style="white-space: pre-wrap">
                    {{ getStreamIds(element).join('') }}</td>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>{{ 'Start' | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'HH:mm' }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="streamingColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: streamingColumns" 
                  [class.publisher]="row.role == 'PUBLISHER'"
                  [class.bidirectional]="getLessonType(session.lesson.typeId) !== 6 && (row.role == 'PUBLISHER' && (row.user?.isStudent ?? false)) || (row.role == 'SUBSCRIBER' && (row.user?.isTeacher ?? false))">
                </tr>

              </table>

            </div>

          </ng-template>

        </mat-expansion-panel>

      </mat-accordion>

      <app-error-message
        *ngIf="sessions.length === 0"
        [errorTitle]="'There are no active streaming at the moment' | translate"
        [errorContent]="'There arent any active session' | translate"
        customClass="warning">
      </app-error-message>

    </div>

    <div fxLayoutAlign="center center" fxFlex>

      <mat-divider [vertical]="true" class="divider"></mat-divider>

    </div>

    <div fxLayout="column" fxLayoutGap="20px" fxFlex="48">

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">

        <h2>{{ 'Connected users' | translate }} ({{ airtimes?.data?.length ?? 0 }})</h2>

        <button mat-fab color="primary" (click)="getAirTimes()" [matTooltip]="'Refresh' | translate">
          <mat-icon>refresh</mat-icon>
        </button>

      </div>

      <div [hidden]="!airtimes" class="mat-elevation-z8">

        <table mat-table matSort #airtimesSort="matSort" [dataSource]="airtimes">

          <ng-container matColumnDef="cname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Customer' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.user.customer.name }}</td>
          </ng-container>

          <ng-container matColumnDef="usr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngTemplateOutlet="userCol; context: { user: element.user }"></ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ getUserRole(element.user) | role | translate }}</td>
          </ng-container>

          <ng-container matColumnDef="fdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Start' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.startDate | date: 'HH:mm - dd/MM/yyyy' }}</td>
          </ng-container>

          <ng-container matColumnDef="station">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Station' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.station ? element.station.name : ('Remote Connection' | translate) }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="usersColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: usersColumns;" [class.publisher]="row.user.id === currentUser.id"></tr>
        </table>

        <mat-paginator
          [pageSizeOptions]="paginatorPages"
          [pageSize]="paginatorDefault"
          [showFirstLastButtons]="true"
          #airtimesPaginator>
        </mat-paginator>

      </div>

      <app-error-message *ngIf="!airtimes"
        [errorTitle]="'There arent any users' | translate"
        [errorContent]="'There arent any user connected to the platform' | translate"
        customClass="warning">
      </app-error-message>

    </div>

  </div>

</div>

<ng-template #userCol let-user="user">

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 5px">

    <app-profile-picture [url]="user?.profilePictureUrl"></app-profile-picture>

    <div fxLayoutAlign="center start">{{ user ? (user.name + ' ' + user.surname) : '-' }}</div>

  </div>

</ng-template>

<div fxLayout="column" fxLayoutAlign="center center" class="component-main">
  <h2>Account</h2>
  <div fxLayout="column" fxLayoutGap="40px" style="width: 80%;">

    <mat-card appearance="outlined" class="mat-elevation-z8" *ngIf="teacherAvgAttendance && currentUser.isTeacher">
      <mat-card-header>
        <mat-card-title>{{ 'User data' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="33">
          <div fxLayout="row">
            {{'The average attendance to the lessons is' | translate}} {{ teacherAvgAttendance | number: '1.0-2' }}%
          </div>
          <div fxLayout="row" style="margin-top: 10px;">
            <app-spinner-container
              color="primary"
              [diameter]="75"
              [strokeWidth]="5"
              [value]="teacherAvgAttendance"
              [displayWith]="spinnerValue">
            </app-spinner-container>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="33">
          <div fxLayout="row">
            {{'The average lesson lasted' | translate}} {{lessonAvgDuration | date: 'HH:mm:ss'}} {{'of the' | translate}} {{lessonAvgPlannedDuration | date: 'HH:mm:ss'}} {{'planned' | translate}}
          </div>
          <div fxLayout="row" style="margin-top: 10px;">
            <app-spinner-container
              color="primary"
              [diameter]="75"
              [strokeWidth]="5"
              [value]="durationSpinnerValue"
              [displayWith]="spinnerValue">
            </app-spinner-container>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="33">
          <div fxLayout="row">
            {{ 'Average attendance trend from' | translate}} {{ lineChartLabels[0] }} {{ 'toD' | translate }} {{ lineChartLabels[lineChartLabels.length-1] }}
          </div>
          <div fxLayout="row" style="margin-top: 10px;">
            <div class="graph">
              <canvas baseChart width="400px" height="110px"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [type]="lineChartType">
              </canvas>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="mat-elevation-z8">
      <mat-card-header>
        <mat-card-title>{{ 'User profile' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'firstName' | translate }}</mat-label>
            <input matInput [formControl]="nameUser">
            <mat-error *ngIf="nameUser.hasError('required')">
              {{ 'Name is required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'surname' | translate }}</mat-label>
            <input matInput [formControl]="surnameUser">
            <mat-error *ngIf="surnameUser.hasError('required')">
              {{ 'Last name is required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'username' | translate }}</mat-label>
            <input matInput placeholder="" [(ngModel)]="currentUser.username" disabled>
          </mat-form-field>
          <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'Role' | translate }}</mat-label>
            <input matInput [value]="getRole() | role | translate" disabled>
          </mat-form-field>
        </div>
        <div>
          <div fxFlex>
            <div fxFlex>
              <mat-label>{{ 'Profile picture' | translate }}</mat-label>
              <app-file-input
                [control]="userProfilePicture"
                [maxSize]="1000"
                [acceptedTypes]="'image/*'"
                [rejectedErrorMessage]="('The file type must be a picture and not exceed' | translate) + ' 1MB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
              </app-file-input>
            </div>
            <div *ngIf="profilePicture" style="align-self: center;" fxFlex>
              <img [src]="profilePicture" [matTooltip]="'Current profile picture' | translate" loading="lazy">
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div style="padding-right: 10px;">
              <div fxFlex="80" style="padding-right: 15px;">
                <mat-form-field style="width: 100%;" color="primary">
                  <mat-label>Email</mat-label>
                  <input matInput [formControl]="emailUser">
                  <mat-error *ngIf="emailUser.hasError('required') || emailUser.hasError('email')">
                    {{ 'Email is required' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field style="width: 100%;" color="primary">
                  <mat-label>Timezone</mat-label>
                  <mat-select [formControl]="timezone">
                    <mat-option *ngFor="let zone of timeZone" [value]="zone">GMT {{zone > 0 ? '+' : '' }}{{zone}}</mat-option>
                  </mat-select>
                  <mat-error>
                    {{ 'The timezone is' | translate }} <strong>{{ 'Required' | translate }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>  
            </div>
            <mat-form-field color="primary" >
              <mat-label >{{ 'Summary' | translate }}</mat-label>
              <textarea  [placeholder]="'HTML ' + ('is supported' | translate)" matInput [formControl]="summaryUser"></textarea>
            </mat-form-field>
          </div>
        </div>

      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex="80">
          <button mat-raised-button (click)="update()" color="accent" [disabled]="!nameUser.valid || !surnameUser.valid || !emailUser.valid || sendingUserProfile">
            <mat-icon>edit</mat-icon>
            {{ 'Update' | translate }}
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="end end" fxFlex>
          <button mat-icon-button (click)="toggleUserProfileEdits()" [matTooltip]="(enableUserProfile ? 'Enable edits' : 'Cancel') | translate">
            <mat-icon>
              {{ enableUserProfile ? 'lock' : 'lock_open' }}
            </mat-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>

    <mat-card appearance="outlined" class="mat-elevation-z8">
      <mat-card-header>
        <mat-card-title>
          {{ 'Change password' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'Actual password' | translate }}</mat-label>
            <input matInput type="password" placeholder="" [formControl]="oldPassword" required>
            <mat-error *ngIf="oldPassword.hasError('required')">
              {{ 'Old password is required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'New password' | translate }}</mat-label>
            <input matInput type="password" placeholder="" [formControl]="newPassword" required>
            <mat-error *ngIf="newPassword.hasError('required')">
              {{ 'New password is required' | translate }}
            </mat-error>
            <mat-error *ngIf="newPassword.hasError('pattern')">
              {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'Repeat new password' | translate }}</mat-label>
            <input matInput type="password" placeholder="" [formControl]="newPasswordRepeat" required>
            <mat-error *ngIf="newPasswordRepeat.hasError('required')">
              {{ 'New password is required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-error style="font-size:11px" *ngIf="!checkNewPassword() &&
                            !enableChangePassword">
            {{ 'New password and password confirmation are not the same' | translate }}
          </mat-error>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="column" fxLayoutAlign="end start" fxFlex="80">
          <button mat-raised-button (click)="updatePassword()" color="accent"
            [disabled]="!checkNewPassword() ||
                        !oldPassword.valid ||
                        !oldPasswordServer.valid ||
                        sendingChangePassword">
            <mat-icon>edit</mat-icon>
            {{ 'Update' | translate }}
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="end end" fxFlex>
          <button mat-icon-button (click)="toggleChangePasswordEdits()" [matTooltip]="(enableChangePassword ? 'Enable edits' : 'Cancel') | translate">
            <mat-icon>
              {{ enableChangePassword ? 'lock' : 'lock_open' }}
            </mat-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="!currentUser.isAdmin && !currentUser.isCustomerAdmin">
      <mat-card-header>
        <mat-card-title>
          Google
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="row" fxLayoutGap="20px">
        <div *ngIf="currentUser.isTeacher || currentUser.isTutor" fxLayoutAlign="start center" fxFlex>
          <button mat-raised-button
            [color]="currentUser.googleEnabled ? 'warn' : 'accent'"
            (click)="currentUser.googleEnabled ? revokeGoogleAccess() : grantGoogleAccess()"
            [disabled]="googleAccessDisabled()">
            <mat-icon>event</mat-icon>
            {{ (currentUser.googleEnabled ? 'Revoke caledar access' : 'Grant calendar access') | translate }}
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
          <mat-form-field color="primary" [fxFlex]="currentUser.isTeacher || currentUser.isTutor ? 50 : 25">
            <mat-label>Google {{ 'account email' | translate }}</mat-label>
            <input matInput type="email" [formControl]="googleEmail">
            <mat-error *ngIf="googleEmail.hasError('required') || googleEmail.hasError('email')">
              {{ 'Email is required' | translate }}
            </mat-error>
          </mat-form-field>
          <div>
            <button mat-raised-button color="accent"
              *ngIf="!currentUser.isTeacher && !currentUser.isTutor && currentUser.provider !== 'GOOGLE'"
              [disabled]="googleEmailDisabled()"
              (click)="currentUser.googleEmail == null ? addGoogleEmail() : removeGoogleEmail()">
              {{ (currentUser.googleEmail == null ? 'Add' : 'Remove') | translate }}
            </button>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="column" fxLayoutAlign="end end">
        <button mat-icon-button (click)="toggleUserGoogleEdits()"
          [matTooltip]="(enableGoogleIntegration ? 'Enable edits' : 'Cancel') | translate">
          <mat-icon>
            {{ enableGoogleIntegration ? 'lock' : 'lock_open' }}
          </mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="currentUser.isAuthor || authorProfile" appearance="outlined" class="mat-elevation-z8">
      <mat-card-header>
        <mat-card-title>
          {{ 'Author profile' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div>
            <mat-form-field fxFlex color="primary">
              <mat-label>{{ 'Name' | translate }}</mat-label>
              <input matInput [formControl]="profileName">
              <mat-error *ngIf="profileName.hasError('required')">
                {{ 'Name is required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field fxFlex color="primary">
              <mat-label>{{ 'Summary' | translate }}</mat-label>
              <textarea matInput [formControl]="profileSummary" [placeholder]="'HTML ' + ('is supported' | translate)"></textarea>
              <mat-error *ngIf="profileSummary.hasError('required')">
                {{ 'Summary is required' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex color="primary">
              <mat-label>Curriculum Vitae</mat-label>
              <textarea matInput [formControl]="profileCV" [placeholder]="'HTML ' + ('is supported' | translate)"></textarea>
              <mat-error *ngIf="profileCV.hasError('required')">
                {{ 'Curriculum vitae is required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <div fxFlex>
              <mat-label>{{ 'Profile picture' | translate }} *</mat-label>
              <app-file-input
                [control]="profilePictureSrc"
                [maxSize]="1000"
                [acceptedTypes]="'image/*'"
                [rejectedErrorMessage]="('The file type must be a picture and not exceed' | translate) + ' 1MB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
              </app-file-input>
            </div>
            <div fxFlex>
              <mat-label>{{ 'Background picture' | translate }} *</mat-label>
              <app-file-input
                [control]="profileBackgroundSrc"
                [maxSize]="2000"
                [acceptedTypes]="'image/*'"
                [rejectedErrorMessage]="('The file type must be a picture and not exceed' | translate) + ' 2MB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
              </app-file-input>
            </div>
          </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="80">
          <button mat-raised-button [disabled]="!authorProfile" color="primary" (click)="goToAuthorProfile()">
            <mat-icon>
              account_circle
            </mat-icon>
            {{ 'Go to profile' | translate }}
          </button>
          <button mat-raised-button color="accent" (click)="saveAuthorProfile()" *ngIf="!authorProfile" [disabled]="!profileName.valid || !profileSummary.valid || !profileCV.valid || !profilePictureSrc.valid || !profileBackgroundSrc.valid  || sendingAuthorProfile">
            <mat-icon>edit</mat-icon>
            {{ 'Create' | translate }}
          </button>
          <button mat-raised-button color="accent" (click)="saveAuthorProfile()" *ngIf="authorProfile" [disabled]="!profileName.valid || !profileSummary.valid || !profileCV.valid || sendingAuthorProfile">
            <mat-icon>edit</mat-icon>
            {{ 'Update' | translate }}
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="end end" fxFlex>
          <button mat-icon-button (click)="toggleAuthorProfileEdits()" [matTooltip]="(enableAuthorProfile ? 'Enable edits' : 'Cancel') | translate">
            <mat-icon>
              {{ enableAuthorProfile ? 'lock' : 'lock_open' }}
            </mat-icon>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>

    <mat-card appearance="outlined" class="mat-elevation-z8" *ngIf="(currentUser.isAuthor || currentUser.isCustomerAdmin) && azureService.customerStorage">
      <mat-card-header>
        <mat-card-title>{{ 'Storage' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="row">
        <div fxLayoutAlign="center center" fxFlex="25">
          {{ ('There are' | translate) + ' ' + azureService.customerStorage.blobCount + ' files ' + ('available' | translate) }}
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="25">
          <div fxLayout="row" style="margin-bottom: 10px;">
            {{ 'Space used' | translate }}
          </div>
          <app-spinner-container
            color="primary"
            [diameter]="75"
            [strokeWidth]="5"
            [value]="storageStatus"
            [displayWith]="spinnerValue">
          </app-spinner-container>
        </div>
        <div fxLayoutAlign="center center" fxFlex>
          {{ ('The customer' | translate) + ' ' + azureService.customerStorage.customer.name + ' '
            + ('has at his disposal' | translate) + ' ' + (availableStorage) + 'GB '
            + ('of the total' | translate) + ' ' + azureService.customerStorage.maxSize + 'GB' }}
        </div>
      </mat-card-content>
    </mat-card>

  </div>
</div>

<mat-card appearance="outlined" class="mat-elevation-z8" fxLayout="column">

    <img mat-card-image [src]="content.headerImageUrl" (click)="onView.emit(content)" loading="lazy">

    <mat-card-header>
        <mat-card-title [matTooltip]="content.name" class="webkitLineTitle">
            {{ content.name }}
        </mat-card-title>
        <mat-card-subtitle class="truncate-text">
            {{ content.created | date: 'dd/MM/yyyy' }}
        </mat-card-subtitle>
    </mat-card-header>

    <!--mat-card-content
        [matTooltip]="content.description"
        [style.color]="isDark() ? 'white' : 'rgba(0, 0, 0, 0.54)'"
        class="truncate-text"
        style="white-space: normal"
        fxFlex>
        <span>
            {{ content.description }}
        </span>
    </mat-card-content-->

    <mat-card-actions fxLayout="row" fxLayoutAlign="space-between">

        <button mat-icon-button
            (click)="onView.emit(content)"
            [matTooltip]="'Go to content' | translate">
            <mat-icon>launch</mat-icon>
        </button>

        <div fxLayout="row" *ngIf="currentUser.id === masterAuthorId && content.provenance === 0">
            <button mat-icon-button
                *ngIf="!hideEdit"
                (click)="onEdit.emit(content)"
                [matTooltip]="'Edit' | translate">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn"
                *ngIf="!hideDelete"
                (click)="onDelete.emit(content)"
                [matTooltip]="'Delete' | translate">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

    </mat-card-actions>

</mat-card>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserDeletionDTO } from 'src/app/models/dto/userDeletionDTO';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-data-deletion',
  templateUrl: './data-deletion.component.html',
  styleUrls: ['./data-deletion.component.scss']
})
export class DataDeletionComponent implements OnInit {
  userDeletion: UserDeletionDTO;
  tokenRequest: string;

  constructor(private route: ActivatedRoute,
              private auth: AuthService) { }

  ngOnInit(): void {

    this.tokenRequest = this.route.snapshot.paramMap.get('id');

    this.auth.getDeletionRequest(this.tokenRequest)
      .subscribe(data => this.userDeletion = data);
  }

}

import { TranslationEdit } from "./dto/translationDTO";

export class PackageEdit {
    public Name: string;
    public Description: string;
    public Header: string;
    public Footer: string;
    public ShowcaseSummary: string;
    public ImageUrl: string;
    public MobileImageUrl: string;
    public Language: string;
    public Visibility: number;
    public DurationDays: number;
    public NameTranslation: TranslationEdit;
    public DescriptionTranslation: TranslationEdit;
    public HeaderTranslation: TranslationEdit;
    public FooterTranslation: TranslationEdit;
    public ShowcaseTranslation: TranslationEdit;
}

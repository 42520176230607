import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-smart-edit',
  templateUrl: './smart-edit.component.html',
  styleUrls: ['./smart-edit.component.scss']
})
export class SmartEditComponent implements OnInit, OnChanges {

  @Input()
  label: string = 'label';

  @Input()
  valore: string;

  @Output()
  onSave: EventEmitter<string> = new EventEmitter<string>();
  
  editing: boolean = false;
  showEdit: boolean = false;
  oldValue: string;
  newValue: string;

  constructor() { }

  ngOnInit(): void {
   this.loadValue(); 
  }

  ngOnChanges(): void {
    this.loadValue();
  }

  onEditOver() {
    if (!this.editing)
      this.showEdit = true;
  }

  loadValue() {
    this.oldValue = this.valore;
    this.newValue = this.valore;
    this.editing = false;
    this.showEdit = false;
  }

  onClick(event: MouseEvent) {
    event.stopPropagation();

    this.editing = true;
    this.showEdit = false;
  }

  save() {
   this.editing = false;

   if (this.oldValue != this.newValue)
    this.onSave.emit(this.newValue);
  }

}

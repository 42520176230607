<div fxLayoutAlign="center center" class="component-main">
    <mat-card appearance="outlined" style="width: 600px; margin-top: 40px;" class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>{{ 'Confirm user creation' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayoout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
          <mat-icon [style.color]="sending === 0 ? 'red' : sending === 1 ? 'orange' : 'green'">
            {{ sending === 0 ? 'error' : sending === 1 ? 'autorenew' : 'check_circle' }}
          </mat-icon>
          <span>
            {{ msg }}
          </span>
        </mat-card-content>
    </mat-card>
</div>

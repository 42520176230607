import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MasterDTO } from 'src/app/models/dto/masterDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-master-add-teacher',
  templateUrl: './master-add-teacher.component.html',
  styleUrls: ['./master-add-teacher.component.scss']
})
export class MasterAddTeacherComponent implements OnInit {

  master: MasterDTO;
  teachers: UserDTO[] = [];
  selectedTeachers: number[] = [];

  constructor(public dialogRef: MatDialogRef<MasterAddTeacherComponent>, @Inject(MAT_DIALOG_DATA) public data,
              private masterService: MasterService,
              private snackbar: MatSnackBar) {
    if(data) {
      this.master = this.data.master;
      this.teachers = this.data.teachers;
    }
  }

  ngOnInit(): void {
  }

  addTeachers() {
    this.masterService.addTeachers(this.master.id, this.selectedTeachers)
      .subscribe({
        next: () => {
          this.snackbar.open('Teachers added successfully', '', { duration: 3000 });
          this.dialogRef.close(true);
        },
        error: err => {
          this.snackbar.open(err.error.Message, '', { duration: 3000 });
          this.dialogRef.close(false);
        }
      });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

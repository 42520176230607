<div fxLayout="row" fxLayoutAlign="space-evenly center">
    <table>
        <tr>
            <td>
                <div class="btn-group" fxLayout="row" fxLayoutAlign="start center">
                    <button mat-button mat-button color="primary"
                            mwlCalendarPreviousView
                            [view]="view"
                            [(viewDate)]="viewDate"
                            (viewDateChange)="viewDateChange.next(viewDate)">
                        {{ 'Previous' | translate }}
                    </button>
                    <button mat-button mat-button color="primary"
                            mwlCalendarToday
                            [(viewDate)]="viewDate"
                            (viewDateChange)="viewDateChange.next(viewDate)">
                        {{ 'Today' | translate }}
                    </button>
                    <button mat-button mat-button color="primary"
                            mwlCalendarNextView
                            [view]="view"
                            [(viewDate)]="viewDate"
                            (viewDateChange)="viewDateChange.next(viewDate)">
                        {{ 'Next' | translate }}
                    </button>
                </div>
            </td>
            <td>
                <h4>{{ viewDate | calendarDate: view + 'ViewTitle': locale() }}</h4>
            </td>
            <td>
                <div class="btn-group" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-button mat-button color="primary"
                            (click)="viewChange.emit('month')"
                            [class.active]="view === 'month'">
                        {{ 'Month' | translate }}
                    </button>
                    <button mat-button mat-button color="primary"
                            (click)="viewChange.emit('week')"
                            [class.active]="view === 'week'">
                        {{ 'Week' | translate }}
                    </button>
                </div>
            </td>
        </tr>
    </table>
</div>

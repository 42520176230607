<h1 mat-dialog-title>{{ 'Notes' | translate }}</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%" color="primary">
        <mat-label>{{ 'Notes of the lesson' | translate }}</mat-label>
        <textarea matInput [formControl]="noteForm" type="text"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'No notes' | translate }}</button>
    <button mat-flat-button color="accent" (click)="closePopUp()" [disabled]="!noteForm.valid">{{ 'Save' | translate }}</button>
</div>

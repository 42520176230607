import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { InvitedUser } from 'src/app/models/invitedUser';
import { User } from 'src/app/models/user';
import { UserRole } from 'src/app/models/userRole';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {

  roles: { role: number, label: string }[] = [];

  currentUser: User = null;
  customerName: string = this.data?.customerName;
  defaultMessage: string = 'You have been invited to contribute to aixp.io by x';
  maxTextChars: number = 800;

  name: FormControl<string> = new FormControl('', [Validators.required]);
  surname: FormControl<string> = new FormControl('', [Validators.required]);
  email: FormControl<string> = new FormControl('', [Validators.required, Validators.email]);
  role: FormControl<number> = new FormControl(UserRole.Student, [Validators.required]);
  text: FormControl<string> = new FormControl(this.translate.instant(this.defaultMessage, { name: this.customerName }), [Validators.maxLength(this.maxTextChars)]);

  constructor(
    private dialogRef: MatDialogRef<InviteUserComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.roles = [{ role: UserRole.Student, label: 'Student' }];

    if (EnvironmentHelper.isAixp() || this.auth.isDT(this.customerName)) {
      this.roles.push({ role: UserRole.Tutor, label: 'Tutor' });
      this.roles.push({ role: UserRole.Speaker, label: 'Speaker' });
    }

    if (EnvironmentHelper.isSaratoga())
      this.roles.push({ role: UserRole.Teacher, label: 'Teacher' });

    this.roles.sort((a, b) => a.role - b.role);
  }

  inviteUser() {

    let userInvite: InvitedUser = {
      name: this.name.value,
      surname: this.surname.value,
      email: this.email.value,
      invite: this.text.value,
      isTutor: this.role.value === UserRole.Tutor,
      isTeacher: this.role.value === UserRole.Teacher,
      isSpeaker: this.role.value === UserRole.Speaker,
      isStudent: this.role.value === UserRole.Student
    };

    this.userService.inviteUser(userInvite).subscribe({
      next: async () => {
        this.snackBar.open(await firstValueFrom(this.translate.get('Invite sent!')), undefined, { duration: 3000 });
        this.dialogRef.close();
      },
      error: err => {
        console.error(err);
        this.snackBar.open(err.error.Message, undefined, { duration: 5000 });
        
        if (err.status === 406)
          this.email.setErrors({ alreadyUsed: true });
      }
    });

  }

  btnDisabled() {
    let check = !this.name.valid
             || !this.surname.valid
             || !this.email.valid
             || !this.role.valid
             || !this.text.valid;

    return check;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from 'src/app/helpers/helper';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { AzureStorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-user-info',
  templateUrl: './change-user-info.component.html',
  styleUrls: ['./change-user-info.component.scss']
})
export class ChangeUserInfoComponent implements OnInit {

  currentUser: User = null;
  //timezones: number[] = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  editedUser: User = null;
  
  newPasswordForm: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl<string>(null, [Validators.required]),
      newPassword: new FormControl<string>(null, [Validators.required, Validators.pattern(Helper.getValidPasswordRegex())]),
      newPasswordRepeat: new FormControl<string>(null, [Validators.required])
    },
    this.samePasswordValidator
  );
  
  profilePicture: UntypedFormControl = new UntypedFormControl(undefined);
  //timezone: UntypedFormControl = new UntypedFormControl(1, [Validators.required]);

  constructor(private dialogRef: MatDialogRef<ChangeUserInfoComponent>,
              private auth: AuthService,
              private snackbar: MatSnackBar,
              private azureService: AzureStorageService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.editedUser = this.currentUser;
  }

  async save() {
    //this.editedUser.timezone = this.timezone.value;
    this.editedUser.profilePictureUrl = await this.azureService.uploadFile(this.profilePicture.value);

    this.userService.updatePassword(this.newPasswordForm.controls.oldPassword.value, this.newPasswordForm.controls.newPassword.value).subscribe({
      next: () => {
        console.log('Password updated');

        this.userService.updateUser(this.editedUser, this.currentUser.id).subscribe({
          next: () => {
            console.log('User updated ');

            this.snackbar.open('Credentials saved!', undefined, { duration: 3000 });
            this.dialogRef.close();
          },
          error: err => {
            console.error(err);

            this.snackbar.open(err.error.Message, undefined, { duration: 5000 });
            this.dialogRef.close();
          }
        });
      },
      error: err => {
        console.error(err);
        this.snackbar.open('Error: ' + err.error.Message, undefined, { duration: 5000 });
      }
    });
  }

  samePasswordValidator(group: FormGroup) {
    if (!group)
      return null;

    let password = group.controls.newPassword;
    let passwordRepeat = group.controls.newPasswordRepeat;

    if (password.value !== passwordRepeat.value)
      passwordRepeat.setErrors({ different: true });
    else
      passwordRepeat.setErrors(null);

    return null;
  }

}

<h1 mat-dialog-title>{{ ('Contents of' | translate) + ' ' + lesson.name }}</h1>
<div mat-dialog-content>
    <mat-list role="list" *ngIf="contents.length > 0; else noContents">
      <div *ngFor="let content of contents">
        <mat-list-item style="cursor: pointer; margin: 10px" (click)="goToContent(content)">
          <div fxLayout="column" fxLayoutAlign="center start" fxFlex="20">
            <img [src]="content.headerImageUrl" loading="lazy">
          </div>
          <div fxLayout="column" fxLayoutAlign="center start" fxFlex>
            {{ getHelper().getTruncateShowcase(content.name, 'No title', 45) }}
          </div>
        </mat-list-item>
        <mat-divider *ngIf="contents.length > 1"></mat-divider>
      </div>
    </mat-list>
    <ng-template #noContents>
      <p>
        {{ 'There are no contents for this lesson' | translate }}
      </p>
    </ng-template>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Close' | translate }}</button>
</div>

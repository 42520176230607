import { TranslationEdit } from "./dto/translationDTO";

export class MasterContentEdit {
    public name: string;
    public description: string;
    public mediaUrl: string;
    public headerImageUrl: string;
    public type: number;
    public downloadable: number;
    public orderIndex: number;
    public duration: number;
    public language: string;
    public nameTranslation: TranslationEdit;
    public descriptionTranslation: TranslationEdit;
}

import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SpeedTestService } from 'ng-speed-test';
import { DeviceDetectorService } from 'ngx-device-detector';
import { firstValueFrom } from 'rxjs';

const SPEED_TEST_ITERATIONS: number = 5;
const SPEED_TEST_RETRY_DELAY: number = 500;

@Component({
  selector: 'app-network-check',
  templateUrl: './network-check.component.html',
  styleUrl: './network-check.component.scss'
})
export class NetworkCheckComponent implements OnInit {

  browser: string = undefined;
  browserOk: boolean = undefined;

  speed: number = 0;
  speedOk: 'no' | 'ok' | 'good' | 'great' = undefined;

  microphonePermission: string = undefined;
  microphonePermissionOk: boolean = undefined;

  cameraPermission: string = undefined;
  cameraPermissionOk: boolean = undefined;

  constructor(
    private dialogRef: MatDialogRef<NetworkCheckComponent>,
    private speedTestService: SpeedTestService,
    private platform: Platform,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.checkSpeed();
    this.checkMicrophone();
    this.checkCamera();
    this.checkBrowser();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  private async checkSpeed() {
    let isOnline = await firstValueFrom(this.speedTestService.isOnline());

    if (!isOnline) {
      this.speed = undefined;
      this.speedOk = 'no';

      return;
    }

    let recSpeed: number[] = [];

    this.speedTestService
      .getMbps({
        iterations: SPEED_TEST_ITERATIONS,
        retryDelay: SPEED_TEST_RETRY_DELAY
      })
      .subscribe({
        next: speed => {

          recSpeed.push(speed);

          this.speed = recSpeed.reduce((partialSum, s) => partialSum += s, 0) / recSpeed.length;
          this.speedOk = speed <= 5
                       ? 'no'
                       : speed <= 10
                       ? 'ok'
                       : speed <= 100
                       ? 'good'
                       : 'great';

        },
        error: err => console.error(err)
      });
  }

  private async checkMicrophone() {
    let res = await navigator.permissions.query({ name: 'microphone' as any });

    this.microphonePermission = res.state;
    this.microphonePermissionOk = res.state === 'granted';
  }

  private async checkCamera() {
    let res = await navigator.permissions.query({ name: 'camera' as any });

    this.cameraPermission = res.state;
    this.cameraPermissionOk = res.state === 'granted';
  }

  private async checkBrowser() {
    this.browser = `${this.deviceService.browser} ${this.deviceService.browser_version}`;
    this.browserOk = this.platform.BLINK && this.browser?.toLowerCase()?.includes('chrome');
  }

}

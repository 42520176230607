<app-mobile-warning *ngIf="isMobile; else desktopLogin"></app-mobile-warning>

<ng-template #desktopLogin>
  <div *ngIf="!lockLogin" class="sfondo" [ngStyle]="{'background-image': 'url(' + background + ')'}">

    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      fxFlexOffset="40"
      fxFlex>
  
      <h1 style="width: 400px; text-align: center; color: white;">{{ name }}</h1>
  
      <mat-card
        appearance="outlined"
        style="width: 400px"
        class="mat-elevation-z8">
  
        <mat-card-content fxLayout="column" fxLayoutGap="20px">
  
          <app-access-form *ngIf="isLoginMode()"></app-access-form>
          <app-register-form *ngIf="isSignUpMode()" (result)="changeMode('login')"></app-register-form>

          <mat-divider></mat-divider>

          <div #signupBtns *ngIf="isSignUpMode()" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

            <button mat-stroked-button (click)="changeMode('login')">
              {{ 'Log In' | translate }}
            </button>

          </div>

          <div #loginBtns *ngIf="isLoginMode()" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

            <button mat-stroked-button (click)="changeMode('signup')">
              {{ 'Sign Up existing organization'| translate }}
            </button>

            <button mat-raised-button color="primary" (click)="openRequireDemoPopup()">
              {{ 'Require a demo' | translate }}
            </button>

          </div>
  
          <!--div *ngIf="!registerView">
            <button mat-flat-button (click)="setRegisterView(1); myForm.reset()" color="primary">
              {{ ('Signup Community' | translate:appName) + ' ' + ('for free' | translate) }}!
            </button>
          </div-->

        </mat-card-content>
  
      </mat-card>
  
      <h4 style="width: 400px; text-align: center; font-weight: normal; color: white;">
        {{ motto }}
      </h4>
  
    </div>
  </div>
</ng-template>

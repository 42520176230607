<mat-card matRipple (click)="onClick.emit(true)" appearance="outlined" style="cursor: pointer">
    <mat-card-header style="margin-bottom: 16px">
        <mat-card-title>
            {{ title }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div style="display: block" [style.height]="height" [style.width]="width">
            <canvas baseChart
                [datasets]="datasets"
                [labels]="labels"
                [options]="options"
                [plugins]="plugins"
                [type]="type">
            </canvas>
        </div>
    </mat-card-content>
</mat-card>

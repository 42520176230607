<h1 mat-dialog-title *ngIf="type == 'deleteCourse' || type == 'deleteCoursePermanent'">{{ 'Delete course' | translate }} {{ courseName }}</h1>
<h1 mat-dialog-title *ngIf="type == 'statusCourse'">{{ 'Change course status' | translate }} {{ courseName }}</h1>
<h1 mat-dialog-title *ngIf="type == 'deleteContent'">{{ 'Delete content of' | translate }} {{ courseName }}</h1>
<div mat-dialog-content  *ngIf="type == 'deleteCourse' || type == 'deleteCoursePermanent'">
    {{ 'Are you sure to delete' | translate }} {{ courseName }}{{ type == 'deleteCourse' ? '' :  ' ' + ('Permanently' | translate ) }}?
</div>
<div mat-dialog-content  *ngIf="type == 'statusCourse'">
    {{ 'Are you sure to change te status of' | translate }} {{ courseName }}?
</div>
<div mat-dialog-content  *ngIf="type == 'deleteContent'">
    {{ 'Are you sure to delete this content of' | translate }} {{ courseName }}?
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" (click)="closePopUp()" cdkFocusInitial>Ok</button>
</div>

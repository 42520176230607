import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/services/auth.service';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss']
})
export class FailedComponent implements OnInit, OnDestroy {

  isMobile: boolean = false;

  constructor(private router: Router,
              private deviceService: DeviceDetectorService,
              private navBar: NavBarService,
              private auth: AuthService) { }
    
  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();

    if (this.isMobile)
      this.navBar.hide('all');
  }

  ngOnDestroy(): void {
    this.navBar.show('topbar');
    this.navBar.show('bottombar');
  }

  redirect() {
    this.isMobile ?
    this.auth.logout() :
    this.router.navigate(['/cart']);
  }

}

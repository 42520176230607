import { Component, Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/helpers/helper';

@Component({
  selector: 'app-image-header',
  templateUrl: './image-header.component.html',
  styleUrls: ['./image-header.component.scss']
})
export class ImageHeaderComponent implements OnInit {

  private _hashTags: string[];
  private _creationDate: Date;
  private _imageUrl: string;
  private _header: string;
  private _title: string;
  private _footer: string;
  private _description: string;
  private _price: string;

  @Input()
  get hashTags(): string[] { return this._hashTags; }
  set hashTags(value: string[]) {
    this._hashTags = value;
  }

  @Input()
  get creationDate(): Date { return this._creationDate; }
  set creationDate(value: Date) {
    this._creationDate = value;
  }

  @Input()
  get imageUrl(): string { return this._imageUrl }
  set imageUrl(value: string) {
    this._imageUrl = Helper.clean(value);
  }

  @Input()
  get header(): string { return this._header; }
  set header(value: string) {
    this._header = value;
  }

  @Input()
  get title(): string { return this._title; }
  set title(value: string) {
    this._title = Helper.isNullOrEmpty(value) ? 'No title' : value;
  }

  @Input()
  get footer(): string { return this._footer; }
  set footer(value: string) {
    this._footer = value;
  }

  @Input()
  get description(): string { return this._description; }
  set description(value: string) {
    this._description = value;
  }

  @Input()
  get price(): string { return this._price; }
  set price(value: string) {
    this._price = value;
  }

  public textColor: string = 'white';

  constructor() { }

  ngOnInit(): void { }

  setImageBrightness($event, elementName: string): void {
    this.textColor = Helper.getImageBrightness($event, elementName);
  }

  getHashTags() {
    return this.hashTags?.join(' ');
  }
}

<h1 mat-dialog-title>{{ masterContent?.name }}</h1>
<div mat-dialog-content>

    <app-player-events
        *ngIf="isVideo"
        [media]="masterContent.mediaUrl"
        [controls]="download"
        [enableControls]="true"
        [disableSeekCheck]="true"
        [height]="height()">
    </app-player-events>

    <ngx-extended-pdf-viewer
        *ngIf="isPdf"
        [src]="masterContent.mediaUrl"
        [useBrowserLocale]="true"
        [textLayer]="true"
        [showBorders]="false"
        [showOpenFileButton]="false"
        [showBookmarkButton]="false"
        [showPresentationModeButton]="true"
        [filenameForDownload]="masterContent.name"
        [enablePrint]="masterContent.downloadable == 1"
        [showDownloadButton]="masterContent.downloadable == 1"
        [contextMenuAllowed]="masterContent.downloadable == 1"
        [theme]="isDark() ? 'dark' : 'light'"
        [height]="height()">
    </ngx-extended-pdf-viewer>

    <div *ngIf="isAudio" fxLayout="column" fxLayoutGap="10px">
        <app-picture-preview
            [src]="masterContent.headerImageUrl"
            [height]="height(70)"
            [width]="width()"
            fit="cover"
            style="background-color: black">
        </app-picture-preview>
        <app-audio-player-events 
            [shadow]="true"
            [media]="masterContent.mediaUrl"
            [controls]="download"
            [enableControls]="true"
            [disableSeekCheck]="true">
        </app-audio-player-events>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">
        {{ 'Close' | translate }}
    </button>
</div>

<div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <mat-form-field color="primary" style="width: 100%">
          <mat-label>{{ 'Search' | translate }}</mat-label>
          <input matInput (keyup)="searchWord($event)">
        </mat-form-field>

        <div *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin">
            <button mat-fab color="primary"
                (click)="isDT() ? exportDtUserToCsv() : exportToCsv()" [matTooltip]="'Save csv of users' | translate">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </div>

    <table mat-table [dataSource]="datasource" #usersSort="matSort" style="width: 100%" matSort>

      <ng-container matColumnDef="thumbnail">
        <th mat-header-cell *matHeaderCellDef class="imgProfile">{{ 'Profile picture' | translate }}</th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <app-profile-picture [url]="element.profilePictureUrl"></app-profile-picture>
        </td>
      </ng-container>

      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.surname }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'username' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.username }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ getRole(element) | translate }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User creation' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.timeStamp | date: 'dd/MM/yyyy HH:mm' }}</td>
      </ng-container>

      <ng-container matColumnDef="actions" >
        <th mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</th>
        <td mat-cell *matCellDef="let element">

          <button mat-icon-button
            *ngIf="(currentUser.isAdmin || currentUser.isCustomerAdmin) && element.state === 1"
            (click)="goToUserDashboard(element.id)"
            [matTooltip]="'Go to dashboard' | translate">
            <mat-icon>assessment</mat-icon>
          </button>

          <button mat-icon-button
            *ngIf="isSaratogaMode() || isDT()"
            (click)="editUser(element)"
            [matTooltip]="'edit' | translate">
            <mat-icon>
              edit
            </mat-icon>
          </button>

          <button mat-icon-button *ngIf="(currentUser.isAdmin || currentUser.isCustomerAdmin) && element.state === 1" color="warn"
            (click)="deleteUser(element.id)"
            [matTooltip]="'Disable' | translate">
            <mat-icon>
              not_interested
            </mat-icon>
          </button>

          <button mat-icon-button *ngIf="(currentUser.isAdmin || currentUser.isCustomerAdmin) && element.state === 0" style="color: green"
            (click)="enableUser(element.id)"
            [matTooltip]="'Enable user' | translate">
            <mat-icon>
              done
            </mat-icon>
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    
    <mat-paginator
      [pageSizeOptions]="paginatorPages"
      [pageSize]="paginatorDefault"
      [showFirstLastButtons]="true"
      #usersPaginator>
    </mat-paginator>
</div>

<h1 mat-dialog-title>Smily {{data.name + ' ' + ('issue list' | translate)}} </h1>
<div class="description">{{ ('Found' | translate) + ' ' +  getOpenIssue() + ' ' + ('open issues' | translate)}}</div>
<mat-dialog-content style="margin-top: 20px; margin-bottom: 20px;">
    <table mat-table [dataSource]="data.answers"  *ngIf="data.answers && data.answers.length > 0"  style="width: 100%; margin-left: auto; margin-right: auto;">
    
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef>{{ 'User' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{ element.user.surname + ' ' + element.user.name }} </td>
        </ng-container>

        <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef>{{ 'Question' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{ element.checkListQuestion.question }} </td>
        </ng-container>

        <ng-container matColumnDef="answer">
            <th mat-header-cell *matHeaderCellDef>{{ 'Problem' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{ element.response }} </td>
        </ng-container>

        <ng-container matColumnDef="opendate">
            <th mat-header-cell *matHeaderCellDef>{{ 'Open date' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{ element.created | date: 'HH:mm  dd/MM/yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="closedate">
            <th mat-header-cell *matHeaderCellDef>{{ 'Close date' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{ element.fixed ? (element.fixed | date: 'HH:mm  dd/MM/yyyy') : '-' }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay" [class.openIssue]="row.fixed == null"></tr>
        
    </table>
    <div *ngIf="data.answers && data.answers.length === 0" style="margin-top: 20px">
        {{ 'This Smily has no issues' | translate }}
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row">
    <div fxLayoutAlign="start center" fxFlex>
        <button mat-stroked-button [mat-dialog-close] cdkFocusInitial>{{ 'Close' | translate }}</button>
    </div>
    <div fxLayoutAlign="end center" fxFlex>
        <button mat-flat-button color="accent" *ngIf="data.workingState === 0"  (click)="MarkAsWorking()">{{ 'Mark as working' | translate }}</button>
    </div>
</mat-dialog-actions>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContentHelper } from 'src/app/helpers/contentHelper';
import { CourseContentType } from 'src/app/models/courseContentType';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { User } from 'src/app/models/user';
import { CoursePopupComponent } from 'src/app/pages/course/course-popup/course-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-content-action-buttons',
  templateUrl: './content-action-buttons.component.html',
  styleUrls: ['./content-action-buttons.component.scss']
})
export class ContentActionButtonsComponent {

  private _content: CourseContentDTO = undefined;

  @Input()
  authorId: number = undefined;

  @Input()
  get content(): CourseContentDTO { return this._content; }
  set content(value: CourseContentDTO) {
    this._content = value;

    this.isSurvey = this.content.type === CourseContentType.Survey
                 && this.content.lessonSession.surveyAnswer
                 && this.content.lessonSession.surveyAnswer.length > 0;
  }

  @Input()
  compact: boolean = false;

  @Input()
  contentHelper: ContentHelper = undefined;

  @Output()
  onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentUser: User = undefined;
  isSurvey: boolean = false;

  constructor(private router: Router,
              private dialog: MatDialog,
              private auth: AuthService,
              private courseService: CourseService,
              private snackBar: MatSnackBar) {

    this.currentUser = this.auth.getCurrentUser();
  }

  goTo() {
    this.contentHelper.goTo();
  }

  editSurvey() {
    this.router.navigate(['survey-creator', this.content.lessonSession.idSurvey]);
  }

  deleteContent() {
    const dialogRef = this.dialog.open(CoursePopupComponent, {
      data: { name: this.content.name, type: 'deleteContent' }
    });

    dialogRef.afterClosed()
      .subscribe(result => {

        if (!result)
          return;

        this.courseService.deleteContent(this.content.id)
          .subscribe({
            next: () => {
              this.snackBar.open('Content deleted', 'Dismiss', { duration: 3000 });
              this.onDelete.emit(true);
            },
            error: err => {
              this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
            }
          });

      });
  }

  isSurveyContent() {
    return this.content.type === CourseContentType.Survey;
  }

}

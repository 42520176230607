<div class="component-main" fxLayout="column">
    <h2 style="align-self: center;">
        {{ 'Student data' | role | translate }} {{ surname + ' ' + name }}
    </h2>
    <div class="btn-group">
        <button mat-stroked-button class="card-button" (click)="goBack()" style="margin-right: 30px;">
            <mat-icon matListIcon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
        <mat-form-field color="primary">
            <mat-label>{{ 'Year' | translate }}</mat-label>
            <mat-select name="name" [formControl]="year" (selectionChange)="yearChange()">
                <mat-option [value]="0">{{ 'All years' | translate }}</mat-option>
                <mat-option *ngFor="let year of years" [value]="year">
                    {{year}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="graph">
        <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [type]="barChartType">
        </canvas>
    </div>
</div>

import { Injectable } from '@angular/core';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { environment } from 'src/environments/environment';
import { SurveyAnswerDTO } from 'src/app/models/dto/surveyAnswerDTO';
import { SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
  private bckEndUrlSurvey = `${this.bckEndUrlBaseUrl }/survey`;

  constructor(private http: HttpClient) { }

  public getSurveys(): Observable<SurveyDTO[]> {
    return this.http.get<SurveyDTO[]>(`${this.bckEndUrlSurvey}/all`, { headers: Helper.getAuthHeader() });
  }

  public getSurveysTeacher(userId: number): Observable<SurveyDTO[]> {
    let param = new HttpParams()
      .set('idAuthor', String(userId));

    return this.http.get<SurveyDTO[]>(`${this.bckEndUrlSurvey}/all`, { headers: Helper.getAuthHeader(),params: param });
  }

  public getSurvey(surveyId: number): Observable<SurveyDTO> {
    return this.http.get<SurveyDTO>(`${this.bckEndUrlSurvey}/${surveyId}`, { headers: Helper.getAuthHeader() });
  }

  public postSurvey(survey: SurveyDTO) {
    return this.http.post(`${this.bckEndUrlSurvey}`, survey, { headers: Helper.getAuthHeader() });
  }

  public putSurvey(surveyId: number, survey: SurveyDTO) {
    return this.http.put(`${this.bckEndUrlSurvey}/${surveyId}`, survey, { headers: Helper.getAuthHeader() });
  }

  public deleteSurvey(surveyId: number) {
    return this.http.delete(`${this.bckEndUrlSurvey}/${surveyId}`, { headers: Helper.getAuthHeader() });
  }

  public postSurveyAnswer(lessonId: number, surveyAnswerDTO: SurveyAnswerDTO ) {
    // POST {backendURl}/api/v2/survey/answer/{lessonId} => Passare un oggetto SurveyAnswerDTO 
    //  con lo stato (completato 1, non completato 0), e il surveyJSON di risposta
    return this.http.post(`${this.bckEndUrlSurvey}/answer/${lessonId}`, surveyAnswerDTO, { headers: Helper.getAuthHeader() } )
  }

 public getSurveyAnswersClassroom(classroomId: number, lessonId: number) {
    // Ho dovuto eseguire un cambiamento ad un API esistente, quella che ti dava le risposte di un survey.
    // Invece di essere GET answers/master/{idMaster}/{idLesson} è GET answers/{idClassroom}/{idLesson}
    // mi da tutti gli studenti e tutte le risposte
    return this.http.get<UserDTO[]>(`${this.bckEndUrlSurvey}/answers/${classroomId}/${lessonId}`, { headers: Helper.getAuthHeader()} );
  }

  public putSurveyScore(answerId: number, myScore: number, myScoreNote: string ) {
    // PUT {backendURl}/api/v2/survey/answer/{idAnswer} 
    // => Richiede solo lo Score(int) e ScoreNote(string). Bisogna essere teacher della lezione per poter fare la put
    return this.http.put(`${this.bckEndUrlSurvey}/answer/${answerId}`, { score: myScore, scoreNote: myScoreNote } , { headers: Helper.getAuthHeader() })
  }

  public getSurveyScore(lessonId: number) {
    // GET {backendURl}/api/v2/survey/answer/{idLesson} 
    // => Questa viene eseguita dall'utente interessato(da lì ricavo il suo idUser) e gli restituisce la risposta che ha dato per quella lezione
    return this.http.get<any>(`${this.bckEndUrlSurvey}/answer/${lessonId}`, { headers: Helper.getAuthHeader()} );
  }

  public getStudentSurvey(lessonId: number) {
    // Ok allora con questa (GET {backendURl}/api/v2/survey/answer/{idLesson}
    // => Questa viene eseguita dall'utente interessato(da lì ricavo il suo idUser)) ti do anche l'oggetto survey dentro
    return this.http.get<any>(`${this.bckEndUrlSurvey}/answer/${lessonId}`, { headers: Helper.getAuthHeader()} );
  }

  public AddSurveyToLesson(lessonSurvey: SurveyLessonDTO) {
    /* 
    Ho fatto l'API per aggiungere una lezione survey al momento:
    POST {backendUrl}/api/v2/lessons/teacher/now => passare un oggetto LessonSessionDTO con almeno name, classId, teacherId, startPlanned, endPlanned e il surveyDTO
    (startPlanned e endPlanned vengono rimpiazzate dal backend, servono solo per evitare errori del backend)
    */
    return this.http.post(`${this.bckEndUrlBaseUrl}/lessons/teacher`, lessonSurvey, { headers: Helper.getAuthHeader() } )
  }

}

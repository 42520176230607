import { StationDTO } from './stationDTO';

export class RoomDTO {
    public id: number;
    public customerId: number;
    public name: string;
    public description: string;
    public state: number;
    public created: Date;
    public stations: StationDTO[];
}

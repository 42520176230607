import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../service/cart.service';
import { CartItemDTO } from '../models/cart-item-dto';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, OnDestroy {

  isMobile: boolean = false;

  cartItems: CartItemDTO[] = [];
  cartItemstoberemove: CartItemDTO[] = [];
  lastItem: CartItemDTO = null;
  subscription: SubscriptionDTO;

  constructor(private cartService: CartService,
              private router: Router,
              private deviceService: DeviceDetectorService,
              private navBar: NavBarService,
              private auth: AuthService) { }

  ngOnInit (): void {
    this.isMobile = this.deviceService.isMobile();

    if (this.isMobile)
      this.navBar.hide('all');

    this.cartItemstoberemove = JSON.parse(sessionStorage.getItem('currentCarttobepurchased'));
    let lastObject = this.cartItemstoberemove.slice(-1)[0];
    this.lastItem = {...lastObject};
    this.subscription = this.lastItem.subscription;
    this.cartService.removeItems(this.cartItemstoberemove);
    //  sessionStorage.setItem('currentCart', JSON.stringify(this.cartItems));
    console.log(sessionStorage.getItem("currentCart"));
  }

  ngOnDestroy(): void {
    this.navBar.show('topbar');
    this.navBar.show('bottombar');
  }

  redirect() {
    if(this.isMobile) {
      switch (this.subscription.type) {
        case 1:
          this.router.navigate(['/course',this.subscription.course.id]);
          break;
        case 2:
          this.router.navigate(['/package',this.subscription.package.id]);
          break;
        case 4:
          this.router.navigate(['/webinar-showcase',this.subscription.conference.id]);
          break;
        default:
          this.auth.logout();
          break;
      }
    }
    else
      this.router.navigate(['/purchased']);
  }

}

import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Helper } from '../../helpers/helper';
import { MetaDataDTO } from 'src/app/models/dto/metaDataDTO';

@Injectable({
  providedIn: 'root'
})
export class LinkPreviewService {

  private bckEndUrlSocialWall = `${environment.apiUrl}/api/v2/SocialWall`;

  constructor(private http: HttpClient) { }

  private cache: MetaDataDTO[] = [];

  async resolvePreview(url: string): Promise<MetaDataDTO> {
    let index = this.cache.findIndex(c => c.url === url);
    
    if (index !== -1)
      return this.cache[index];

    try {

      let res = await firstValueFrom(this.http.post<MetaDataDTO>(`${this.bckEndUrlSocialWall}/getMetaData`, { url: url }, { headers: Helper.getAuthHeader() }));

      if (res && this.cache.findIndex(c => c.url === url) === -1)
        this.cache.push(res);

      return res;

    } catch (e) { }

    return undefined;
  }

}

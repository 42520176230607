import { Component, OnInit } from '@angular/core';
import { MasterDTO } from 'src/app/models/dto/masterDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-master-list-user',
  templateUrl: './master-list-user.component.html',
  styleUrl: './master-list-user.component.scss'
})
export class MasterListUserComponent implements OnInit {

  currentUser: User;
  masters: MasterDTO[] = [];

  constructor(
    private auth: AuthService,
    private masterService: MasterService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.getMasters();
  }

  getMasters() {
    this.masterService.getUserMaster()
      .subscribe({
        next: output => this.masters = output.slice(),
        error: err => console.error(err)
      });
  }

}

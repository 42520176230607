import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {

  private sidenav: MatSidenav = undefined;
  private _sidenavStatus: 'visible' | 'hidden' = 'hidden';
  private _topbarStatus: 'visible' | 'hidden' = 'visible';
  private _bottombarStatus: 'visible' | 'hidden' = 'visible';

  public get sidenavStatus(): 'visible' | 'hidden' { return this._sidenavStatus; }
  public get topbarStatus(): 'visible' | 'hidden' { return this._topbarStatus; }
  public get bottombarStatus(): 'visible' | 'hidden' { return this._bottombarStatus; }

  public setSidenav(sidenav: MatSidenav): void { this.sidenav = sidenav; }

  public hide(bar: 'sidenav' | 'topbar' | 'bottombar' | 'all'): void {

    if (bar === 'sidenav' || bar === 'all') {
      this._sidenavStatus = 'hidden';
      this.sidenav?.close();
    }

    if (bar === 'topbar' || bar === 'all')
      this._topbarStatus = 'hidden';

    if (bar === 'bottombar' || bar === 'all')
      this._bottombarStatus = 'hidden';

  }

  public show(bar: 'sidenav' | 'topbar' | 'bottombar' | 'all'): void {

    if (bar === 'sidenav' || bar === 'all') {
      this._sidenavStatus = 'visible';
      this.sidenav?.open();
    }

    if (bar === 'topbar' || bar === 'all')
      this._topbarStatus = 'visible';

    if (bar === 'bottombar' || bar === 'all')
      this._bottombarStatus = 'visible';

  }

  public toggle(bar: 'sidenav' | 'topbar' | 'bottombar'): void {

    let status = bar === 'sidenav'
               ? this.sidenavStatus
               : bar === 'topbar'
               ? this.topbarStatus
               : bar === 'bottombar'
               ? this.bottombarStatus
               : undefined;

    if (!status)
      return;

    status === 'visible' ? this.hide(bar) : this.show(bar);

  }

}

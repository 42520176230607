import { Component, OnInit } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { StationDTO } from 'src/app/models/dto/stationDTO';
import { CheckListAnswerDTO } from 'src/app/models/dto/CheckListDTO';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { MatDialog } from '@angular/material/dialog';
import { StationListPopupComponent } from './station-list-popup/station-list-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.scss']
})
export class StationListComponent implements OnInit {

  currentUser: User;
  allStations: StationDTO[] = [];
  stations: StationDTO[] = [];
  answers: CheckListAnswerDTO[] = [];
  rooms: RoomDTO[] = [];
  room: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);

  columnsToDisplay = ['surname', 'name', 'description', 'date'];
  constructor(private calendar: CalendarService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              private auth: AuthService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();

    this.getRooms();
    this.getStations();
  }

  getStations() {
    this.allStations = null;
    this.stations = null;
    
    this.calendar.getStationsOfCustomer(this.currentUser.idCustomer)
      .subscribe(output => {
        this.allStations = [];
        this.stations = [];

        if(output.length > 0) {
          this.allStations = output;
          this.stations = this.allStations;
        }
      });
  }
  // vedo cosa ha di rotto la macchina ETTORE
  getAnswersOfStation(stationId: number) {
    this.answers = null;

    this.calendar.getStationAnswers(stationId)
      .subscribe(output => {
        this.answers = [];
        if(output.length > 0)
          this.answers = output;
      });
  }

  getRooms() {
    this.calendar.getRoomsForAll()
      .subscribe(output => {
        this.rooms = output;
      });
  }

  onRoomChange() {
    this.stations = [];

    if(this.room.value != 0) {
      this.allStations.forEach(s => {
        if(s.roomId == this.room.value)
          this.stations.push(s);
      });
    } else {
      this.stations = this.allStations;
    }
  }
   // è aggiustata ETTORE
   setStationWorkingState(stationId: number, stationName: string) {

    const dialogRef = this.dialog.open(StationListPopupComponent, {
      data: {name: stationName}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.calendar.setStationWorkingState(stationId)
          .subscribe(error => {
            if(error.Message == "") {
              this.snackBar.open(`Smily marked as fixed`, 'Dismiss', {
                duration: 2000,
                verticalPosition: 'top'
            });
            } else {
              this.snackBar.open(`Error, Smily not marked as fixed`, 'Dismiss', {
                duration: 2000,
                verticalPosition: 'top'
            });
            }

            this.getStations();
          }
        );
      }
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ContentHelper } from 'src/app/helpers/contentHelper';
import { Helper } from 'src/app/helpers/helper';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-content-visualizer',
  templateUrl: './content-visualizer.component.html',
  styleUrls: ['./content-visualizer.component.scss']
})
export class ContentVisualizerComponent implements OnInit {

  lesson: LessonSessionDTO;
  contents: CourseContentDTO[] = [];

  constructor(public dialogRef: MatDialogRef<ContentVisualizerComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private auth: AuthService,
              private calendar: CalendarService,
              private courseService: CourseService,
              private router: Router,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.lesson = this.data.lesson;

    this.calendar.getLessonContents(this.lesson.id)
                .subscribe(output => this.contents = output);
  }

  async goToContent(content: CourseContentDTO) {
    try {

      let course = await firstValueFrom(this.courseService.getCourseContent(content.idCourse, this.auth.isAuthenticated()));

      let ch = new ContentHelper(
        this.router,
        this.dialog,
        this.courseService,
        this.auth.getCurrentUser(),
        content,
        course.courseContent,
        course.idAuthor,
        course.mode
      );

      ch.goTo();

    } catch (e) {
      console.error(e);
    }

    this.onNoClick();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  getHelper() {
    return Helper;
  }

}

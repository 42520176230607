import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
    selector: 'calendar-header',
    templateUrl: './calendar-header.component.html',
    styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent implements OnInit {
    @Input() view: CalendarView | 'month' | 'week' | 'day';
    @Input() viewDate: Date;
    @Output() viewChange: EventEmitter<string> = new EventEmitter();
    @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();

    constructor(private translation: TranslationService) { }

    ngOnInit(): void { }

    locale() {
        return this.translation.currentLang.code;
    }
}

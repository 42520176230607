import { Component, OnInit } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AddUserComponent } from '../user-list/add-user/add-user.component';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

  currentUser: User;
  customers: CustomerDTO[] = [];
  currentCustomer: CustomerDTO = null;
  displayedColumns: string[] = ['thumbnail', 'username', 'name', 'surname', 'email', 'actions'];

  constructor(private auth: AuthService,
              private calendar: CalendarService,
              private userService: UserService,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.getCustomers();
  }

  getCustomers() {
    this.customers = [];
    this.calendar.getCustomerForAdmin()
      .subscribe(output => {

        if (!output || output.length === 0)
          return;

        this.customers = output.filter(c => c.global === 0);
        this.setCustomer(this.customers[0]);

      });
  }

  createUser() {
    const dialogRef = this.dialog.open(AddUserComponent,
    {
      width: '600px',
      data: {
        customerName: this.currentCustomer.name,
        customerId: this.currentCustomer.id,
        mode: 'customerAdmin'
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.getCustomers();
    });
  }

  setCustomer(customer: CustomerDTO) {
    this.currentCustomer = customer;
  }

  goToUser(id: number) {
    const dialogRef = this.dialog.open(AddUserComponent,
    {
      width: '600px',
      data: {
        userId: id,
        customerName: this.currentCustomer.name,
        customerId: this.currentCustomer.id,
        mode: 'customerAdmin'
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.getCustomers();
    });
  }

  deleteUser(id: number) {
    const dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: 'Delete user',
        body: 'Are you sure you want to delete this user?'
      }
    });

    dialogRef.afterClosed().subscribe(async res => {
      if (!res)
        return;

      this.userService.deleteUser(id)
        .subscribe(() => this.getCustomers());
    });
  }
}

import { Helper } from "src/app/helpers/helper";

export class ClientData {
	userId: number; // Id utente
	name: string; // Nome visualizzato
	picture: string; // Foto profilo
	color: string; // Colore visualizzato se video non presente
	role: "publisher" | "moderator" | "presenter" | "participant"; // Ruolo nella conferenza
	mode: "subject" | "share" | "bidirectional"; // Modalità di video trasmesso
	type: "camera" | "screen"; // Tipo di video trasmesso
	hidden: boolean = false;
	index?: number = undefined;

	constructor(userId: number,
				name: string,
				picture: string,
				role: "publisher" | "moderator" | "presenter" | "participant",
				mode: "subject" | "share" | "bidirectional",
				type: "camera" | "screen",
				color?: string,
				hidden?: boolean,
				index?: number) {

		this.userId = userId;
		this.name = name;
		this.picture = picture;
		this.role = role;
		this.mode = mode;
		this.type = type;
		this.color = color;
		this.hidden = hidden ?? false;
		this.index = index;

		let lum = Helper.getColorBrightness(this.color);

		while (!this.color || lum > 190 || lum < 65) {

			this.color = Helper.getRandomColor();
			lum = Helper.getColorBrightness(this.color);

		}

	}
}

<div class="isola">
    
    <div class="pos1">
        <img [src]="getImg(1)"
            class="postazione1"
            (click)="openMenu($event, isola.pos1.station)"
            [hidden]="!isola.pos1.station"
            [matTooltip]="showTooltip(1)"
            loading="lazy">

        <div class="stationDash1" *ngIf="isola.pos1.station">
            <button mat-icon-button
                [disabled]="!isola.pos1.boardOnline"
                [matTooltip]="'Dashboard' | translate"
                (click)="goToDashboard(isola.pos1.station.id, isola.pos1.station.name)"
                [style.color]="!isola.pos1.boardOnline ? 'rgba(0, 0, 0, 0.26)' : ''">
                <mat-icon>dashboard</mat-icon>
            </button>
        </div>

        <div *ngIf="isola.pos1.station" class="stationName">
            {{ getStationName(isola.pos1.station.name) }}
        </div>

        <div class="student1-1" *ngIf="isola.pos1.user1" > 
            <app-author-info  [profile]="isola.pos1.user1" [compact]="true"></app-author-info>
        </div>
    
        <div class="student1-2" *ngIf="isola.pos1.user2" >
            <app-author-info  [profile]="isola.pos1.user2" [compact]="true"></app-author-info>
        </div>
    </div>
    
    <div class="pos2">
        <img [src]="getImg(2)"
            class="postazione2"
            (click)="openMenu($event, isola.pos2.station)"
            [hidden]="!isola.pos2.station"
            [matTooltip]="showTooltip(2)"
            loading="lazy">

        <div class="stationDash2" *ngIf="isola.pos2.station">
            <button mat-icon-button
                [disabled]="!isola.pos2.boardOnline"
                [matTooltip]="'Dashboard' | translate"
                (click)="goToDashboard(isola.pos2.station.id, isola.pos2.station.name)"
                [style.color]="!isola.pos2.boardOnline ? 'rgba(0, 0, 0, 0.26)' : ''">
                <mat-icon>dashboard</mat-icon>
            </button>
        </div>

        <div *ngIf="isola.pos2.station" class="stationName">
            {{ getStationName(isola.pos2.station.name) }}
        </div>

        <div class="student2-1" *ngIf="isola.pos2.user1" > 
            <app-author-info  [profile]="isola.pos2.user1" [compact]="true"></app-author-info>
        </div>
    
        <div class="student2-2" *ngIf="isola.pos2.user2" >
            <app-author-info  [profile]="isola.pos2.user2" [compact]="true"></app-author-info>
        </div>
    </div>


    <div class="pos3">
        <img [src]="getImg(3)"
            class="postazione3"
            (click)="openMenu($event, isola.pos3.station)"
            [hidden]="!isola.pos3.station"
            [matTooltip]="showTooltip(3)"
            loading="lazy">

        <div class="stationDash3" *ngIf="isola.pos3.station">
            <button mat-icon-button
                [disabled]="!isola.pos3.boardOnline"
                [matTooltip]="'Dashboard' | translate"
                (click)="goToDashboard(isola.pos3.station.id, isola.pos3.station.name)"
                [style.color]="!isola.pos3.boardOnline ? 'rgba(0, 0, 0, 0.26)' : ''">
                <mat-icon>dashboard</mat-icon>
            </button>
        </div>

        <div *ngIf="isola.pos3.station" class="stationName">
            {{ getStationName(isola.pos3.station.name) }}
        </div>
            
        <div class="student3-1" *ngIf="isola.pos3.user1" > 
            <app-author-info  [profile]="isola.pos3.user1" [compact]="true"></app-author-info>
        </div>
    
        <div class="student3-2" *ngIf="isola.pos3.user2" >
            <app-author-info  [profile]="isola.pos3.user2" [compact]="true"></app-author-info>
        </div>
    </div>

    <div class="pos4">
        <img [src]="getImg(4)"
            class="postazione4"
            (click)="openMenu($event, isola.pos4.station)"
            [hidden]="!isola.pos4.station"
            [matTooltip]="showTooltip(4)"
            loading="lazy">

        <div class="stationDash4" *ngIf="isola.pos4.station">
            <button mat-icon-button
                [disabled]="!isola.pos4.boardOnline"
                [matTooltip]="'Dashboard' | translate"
                (click)="goToDashboard(isola.pos4.station.id, isola.pos4.station.name)"
                [style.color]="!isola.pos4.boardOnline ? 'rgba(0, 0, 0, 0.26)' : ''">
                <mat-icon>dashboard</mat-icon>
            </button>
        </div>

        <div *ngIf="isola.pos4.station" class="stationName">
            {{ getStationName(isola.pos4.station.name) }}
        </div>

        <div class="student4-1" *ngIf="isola.pos4.user1" > 
            <app-author-info  [profile]="isola.pos4.user1" [compact]="true"></app-author-info>
        </div>
    
        <div class="student4-2" *ngIf="isola.pos4.user2" >
            <app-author-info  [profile]="isola.pos4.user2" [compact]="true"></app-author-info>
        </div>
    </div>
    
</div>

<mat-menu #actionMenu="matMenu" xPosition="after" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="showErrorPostazione(item)">{{ 'Problems' | translate }}</button>
        <button mat-menu-item
            *ngIf="currentUser.isCustomerAdmin || currentUser.isAdmin"
            (click)="editStation(item)">
            {{ 'Edit' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="(currentUser.isCustomerAdmin || currentUser.isAdmin) && isActive(item)"
            (click)="deactivateStation(item)">
            {{ 'Deactivate' | translate }}
        </button>
    </ng-template>
</mat-menu>
<div style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="actionMenu"
    #openActionMenu="matMenuTrigger">
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CourseState } from 'src/app/models/courseState';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-course-profile',
  templateUrl: './course-profile.component.html',
  styleUrls: ['./course-profile.component.scss']
})
export class CourseProfileComponent implements OnInit {

  id: number;
  currentUser: User;
  course: CourseDTO;
  currentUserIsParticipant: boolean = false;
  choiceCourse: string = 'showcase';
  receiving: boolean = true;
  isMobile: boolean = false;

  autoRedirect: boolean = false;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private deviceService: DeviceDetectorService,
              private courseService: CourseService) { }

  ngOnInit(): void {

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();
    this.isMobile = this.deviceService.isMobile();

    if (this.route.snapshot.queryParamMap.has('page'))
      this.choiceCourse = this.route.snapshot.queryParamMap.get('page');

    if (this.route.snapshot.queryParamMap.has('autoredirect'))
      this.autoRedirect = Boolean(this.route.snapshot.queryParamMap.get('autoredirect'));

    this.getCourse();
  }

  getCourse() {
    this.course = null;

    this.courseService.getCourseContent(this.id, this.auth.isAuthenticated())
      .subscribe({
        next: output => {
        
          this.course = output;
          this.receiving = false;

          if (this.auth.isAuthenticated()) {
            this.currentUserIsParticipant = output.classroom.classroomContent.findIndex(s => s.userId === this.currentUser.id) !== -1;

          if (this.autoRedirect == true &&
              !this.isMobile &&
              this.course.state === CourseState.online &&
              (this.currentUser.id === this.course.idAuthor || this.currentUserIsParticipant))
              this.choiceCourse = 'sidenav';
          }
        },
        error: err => {
          console.log(err);
          this.receiving = false;
        }
      });
  }

  getTime(startDate: Date, endDate: Date) {
    var start = new Date(startDate);
    var end = new Date(endDate);

    if(end.getTime() - start.getTime() < 1000)
        return 0;

    return end.getTime() - start.getTime();
  }

}

<div fxLayout="column" fxLayoutGap="20px" class="component-main">

    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-stroked-button (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
    </div>

    <mat-stepper linear (selectedIndexChange)="$event === 0 ? resetStepper() : undefined">

        <mat-step [label]="'Students' | role | translate" [completed]="selectedIdAnswer != undefined">
            <div class="stepperElement">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <mat-form-field fxFlex color="primary">
                        <mat-label>{{ 'Search' | translate }}</mat-label>
                        <input matInput type="text" (keyup)="doFilter($event)">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <button mat-raised-button color="primary"
                        (click)="showAnswer()"
                        [disabled]="!selectedUserId"
                        matStepperNext>
                        {{ 'Next' | translate }}
                    </button>
                </div>
                
                <table mat-table [dataSource]="dataSrc" class="full-width-table" matSort aria-label="Elements">
                
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Student' | role | translate }}</th>
                        <td mat-cell *matCellDef="let row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 5px">
                            <app-profile-picture [url]="row.profilePictureUrl"></app-profile-picture>
                            <span>{{ row.name + ' ' + row.surname }}</span>
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="score">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Score' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ row.surveyAnswer[0].score ?? '-' }}</span>
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="scoreA">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Calculated score' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ row.surveyAnswer[0].scoreA ?? '-' }}</span>
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="scoreNote">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Note' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ row.surveyAnswer[0].scoreNote ?? '-' }}</span>
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="scoreDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Score date' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ row.surveyAnswer.length > 0 ? (row.surveyAnswer[0].scoreDate | date: 'HH:mm - dd/MM/yyyy') : '-' }}</span>
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Answer date' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{ row.surveyAnswer.length > 0 ? (row.surveyAnswer[0].created | date: 'HH:mm - dd/MM/yyyy') : '-' }}</span>
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="survey">
                        <th mat-header-cell *matHeaderCellDef>{{ 'Survey' | translate }}</th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (change)="selectedUserId = ($event.checked ? row.id : undefined)"
                                [checked]="selectedUserId === row.id"
                                [disabled]="row.surveyAnswer.length === 0">
                                {{ (selectedUserId === row.id ? 'Selected' : 'Select') | translate }}
                            </mat-checkbox>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                
                <mat-paginator #paginator
                    [length]="dataSrc?.data.length ?? 0"
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[10, 20, 50]"
                    color="primary">
                </mat-paginator>
            </div>
        </mat-step>

        <mat-step [label]="'Survey' | translate" [completed]="surveyModel != undefined">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center" class="stepperElement">
                <button mat-stroked-button matStepperPrevious>
                    {{ 'Back' | translate }}
                </button>
                <div *ngIf="selectedUser" fxFlex class="center-item">
                    <h3> {{ 'Answer of name' | translate: { name: selectedUser } }}</h3>
                </div>
                <button mat-raised-button color="primary" matStepperNext>
                    {{ 'Next' | translate }}
                </button>
            </div>

            <div class="survey-container">
                <survey [model]="surveyModel"></survey>
            </div>
        </mat-step>

        <mat-step [label]="'Score' | translate" [completed]="lastStep">
            <mat-card appearance="outlined" class="stepperElement">
                <mat-card-header>
                    <mat-card-title>
                        {{ selectedScoreDate ? ('Score awarded to name' | translate: { name: selectedUser }) : ('Score to be awarded to name' | translate: { name: selectedUser }) }}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{ selectedScoreDate ? (selectedScoreDate | date: 'HH:mm - dd/MM/yyyy') : surveyDto?.name }}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxLayoutGap="10px" style="margin-top: 20px">

                    <div fxLayout="row" fxLayoutGap="10px">
                        <mat-form-field color="primary">
                            <mat-label>{{ 'Score' | translate }}</mat-label>
                            <input matInput [formControl]="selectedScore" type="number">
                        </mat-form-field>

                        <mat-form-field *ngIf="selectedScoreA" color="primary">
                            <mat-label>{{ 'Calculated score' | translate }}</mat-label>
                            <input matInput [value]="selectedScoreA" readonly>
                        </mat-form-field>
                    </div>

                    <mat-form-field color="primary" style="width: 100%">
                        <mat-label>{{ 'Notes' | translate }}</mat-label>
                        <input matInput [formControl]="selectedScoreNote">
                    </mat-form-field>

                </mat-card-content>
                <mat-card-actions style="justify-content: space-between">
                    <button mat-stroked-button matStepperPrevious>
                        {{ 'Back' | translate }}
                    </button>
                    <button mat-raised-button
                        (click)="saveScore()"
                        [disabled]="!selectedScore.valid || !selectedScoreNote.valid || selectedScoreDate || lastStep"
                        color="accent">
                        {{ 'Save' | translate }}
                        <mat-icon>check</mat-icon>
                    </button>
                </mat-card-actions>
            </mat-card>
        </mat-step>

    </mat-stepper>

</div>

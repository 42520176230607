import { LessonSessionDTO } from "./dto/lessonSessionDTO";
import { TranslationEdit } from "./dto/translationDTO";

export class ConferencePost extends LessonSessionDTO {
   public name: string;
   public description: string;
   public header: string;
   public footer: string;
   public summary: string;
   public visibility: number;
   public idMode: number;
   public isPublic: boolean;
   public imageUrl: string;
   public language: string;
   public moderator: number;
   public substitute?: number;
   public presenters: number[];
   public classrooms: number[];
   public virtualRooms: number[];
   public nameTranslation: TranslationEdit;
   public descriptionTranslation: TranslationEdit;
   public headerTranslation: TranslationEdit;
   public footerTranslation: TranslationEdit;
   public summaryTranslation: TranslationEdit;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-split-button',
  templateUrl: './split-button.component.html',
  styleUrl: './split-button.component.scss'
})
export class SplitButtonComponent {

  @Input()
  icon: string = undefined;

  @Input()
  text: string = undefined;

  @Input()
  disabled: boolean = false;

  @Input()
  options: SplitButtonOption[] = [];

  @Input()
  color: string = 'accent';

  @Output()
  onMainButtonClick: EventEmitter<boolean> = new EventEmitter();

  @Output()
  onOptionButtonClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  itemClick(value: any) {
    this.onOptionButtonClick.emit(value);
  }

}

export class SplitButtonOption {
  icon?: string = undefined;
  text: string = undefined;
  value: any = undefined;
  disabled?: boolean = false;
}

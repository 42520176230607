import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerDTO, CustomerStorage } from 'src/app/models/dto/customerDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { MatDialog } from '@angular/material/dialog';
import { DashboardPopupComponent } from './dashboard-popup/dashboard-popup.component';
import { ChartDataset, ChartOptions } from 'chart.js';
import { firstBy } from 'thenby';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { CsvExporterService, StreamingTime } from 'src/app/services/csv-exporter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addDays, differenceInDays } from 'date-fns';
import { Helper } from 'src/app/helpers/helper';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { firstValueFrom } from 'rxjs';
import { CustomerStripePopupComponent } from 'src/app/popup/customer-stripe-popup/customer-stripe-popup.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  lineChartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        fill: 'origin',
        tension: 0.4
      },
      point: {
        radius: 1,
        hitRadius: 1
      }
    },
    scales: {
      x: {
        display: true,
        ticks: {
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          color: this.darkService.isSetDark ? 'grey' : ''
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        ticks: {
          color: this.darkService.isSetDark ? 'white' : ''
        },
        grid: {
          color: this.darkService.isSetDark ? 'grey' : ''
        }
      }
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false
      },
      tooltip: {
        enabled: true
      }
    }
  };
  
  public lineChartType = 'line';
  public lineChartData: ChartDataset[][] = [[{ data: [0], label: '%' }], [{ data: [0], label: '%' }], [{ data: [0], label: '%' }]];
  public lineChartLabels: string[][] = [];

  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  currentUser: User = undefined;

  types: string[] = ['line', 'bar'];
  nullGraph: boolean[] = [true, true, true];
  typeTime: string[] = ["", "", ""];
  activeUsersPerc: number = 0;
  remainingDays: number = 0;
  remainingDaysPerc: number = 0;
  expirationDate: Date;

  customerData: CustomerStorage = undefined;
  customersStorage: CustomerStorage[] = [];
  customers: CustomerDTO[] = [];
  customersBackup: CustomerDTO[] = [];
  selectedCustomer: CustomerDTO = undefined;
  selectedType: string = 'line';

  value: string;
  pageIndex: number = 0;
  pageNo: number = 0;
  pageSize: number = 10;
  pageStart: number = 0;
  pageEnd: number = this.pageSize;

  constructor(
    private auth: AuthService,
    private calendar: CalendarService,
    private dialog: MatDialog,
    private exporter: CsvExporterService,
    private snackBar: MatSnackBar,
    private darkService: DarkThemeService
  ) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    
    this.getCustomers();
    this.getCustomersStorage();
  }
 
  async getCustomers(reset: boolean = true) {
    this.customersBackup = await firstValueFrom(this.calendar.getCustomerForAdmin());
    
    if (reset)
      this.resetCustomers();
  }

  async getCustomersStorage() {
    this.customersStorage = await firstValueFrom(this.calendar.getCurrentAzureStates());
  }

  getStorageValue(data: CustomerStorage) {
    return (data.currentSize / data.maxSize) * 100;
  }

  spinnerValue(value: number) {
    return `${value}%`;
  }

  popupOpen(data: CustomerDTO, type: string) {
    let dialogRef = this.dialog.open(DashboardPopupComponent, {
      data: { customer: data, type: type }, width: '600px'
    });

    dialogRef.afterClosed()
      .subscribe(async res => {

        if (!res)
          return;

        await this.getCustomers(false);
        await this.getCustomersStorage();

        if (this.selectedCustomer) {

          this.selectedCustomer = this.customersBackup.find(c => c.id === this.selectedCustomer.id);

          this.expansionHeader(this.selectedCustomer);

        }

      });
  }

  stripeSettingsPopup(customer: CustomerDTO) {
    let dialogRef = this.dialog.open(CustomerStripePopupComponent, {
      data: { customerId: customer.id, customerName: customer.name }, width: '600px'
    });

    dialogRef.afterClosed()
      .subscribe(async res => {

        if (!res)
          return;

        await this.getCustomers(false);
        await this.getCustomersStorage();

        if (this.selectedCustomer) {

          this.selectedCustomer = this.customersBackup.find(c => c.id === this.selectedCustomer.id);

          this.expansionHeader(this.selectedCustomer);

        }

      });
  }

  getStripeStatus(state: number, type: 'text' | 'icon' | 'color') {
    return state === 0
         ? type === 'text' ? 'Unset' : type === 'icon' ? 'pending' : '#929292'
         : state === 1
         ? type === 'text' ? 'Unvalid' : type === 'icon' ? 'error' : '#ed5565'
         : state === 2
         ? type === 'text' ? 'Valid' : type === 'icon' ? 'verified' : '#a0d468'
         : state === 3
         ? type === 'text' ? 'Test' : type === 'icon' ? 'bug_report' : '#ffce54'
         : state === 4
         ? type === 'text' ? 'Live' : type === 'icon' ? 'verified' : '#a0d468'
         : type === 'text' ? 'Unknown' : type === 'icon' ? 'help' : '#5d9cec';
  }

  expansionHeader(data: CustomerDTO) {
    this.setTime(data);
    this.getData(data.id);

    this.customerData = this.customersStorage.find(s => s.customer.id === data.id);
    this.activeUsersPerc = Math.round((data.activeUsers / data.maxPassiveUsers * 10000) / 100);
  }

  resetData(index: number) {
    this.nullGraph[index] = true;
    this.lineChartLabels[index] = [];
    this.lineChartData[index] = [{ data: [0], label: '%' }];
  }

  getData(id: number) {
    this.calendar.getCustomerPublishedStreaming(id)
      .subscribe({
        next: output => this.setChartData(output, 0),
        error: () => this.resetData(0)
      });

    this.calendar.getCustomerOnlineTime(id)
      .subscribe({
        next: output => this.setChartData(output, 1),
        error: () => this.resetData(1)
      });

    this.calendar.getCustomerStreaming(id)
      .subscribe({
        next: output => this.setChartData(output, 2),
        error: () => this.resetData(2)
      });
  }

  setTime(data: CustomerDTO) {
    this.expirationDate = addDays(data.created, data.demoDuration);

    this.remainingDays = 0;
    this.remainingDaysPerc = 0;

    let diff = differenceInDays(this.expirationDate, new Date());

    if (diff > 0) {
      this.remainingDays = diff;
      this.remainingDaysPerc = (diff / data.demoDuration) * 100; 
    }
  }

  setChartData(data: any[], index: number) {
    if (!data || data.length === 0) {
      this.resetData(index);
      return;
    }

    this.nullGraph[index] = false;

    data.sort(firstBy((d: any) => d.year, 1).thenBy((d: any) => d.month, 1));

    let yValues = [];
    let xValues = [];

    if (data.length === 1) {
      yValues.push(0);
      xValues.push(`${data[0].month === 1 ? 12 : data[0].month - 1}/${data[0].month === 1 ? data[0].year - 1 : data[0].year}`);
    }

    let minuteValues = data.map(d => d.totalSeconds / 60);
    let hoursValues = minuteValues.map(d => d / 60);

    if (hoursValues.filter(d => d <= 1).length >= data.length / 3) {
      this.typeTime[index] = "min";
      yValues = yValues.concat(minuteValues);
    } else {
      this.typeTime[index] = "hours";
      yValues = yValues.concat(hoursValues);
    }

    this.lineChartLabels[index] = xValues.concat(data.map(d => `${d.month}/${d.year}`));
    this.lineChartData[index] = [
      {
        data: yValues,
        label: this.typeTime[index],
        borderColor: 'rgba(103, 58, 183, 1)',
        backgroundColor: 'rgba(103, 58, 183, 0.5)'
      }
    ];
  }

  changePage(event?: PageEvent) {
    this.accordion.closeAll();

    let minIndex = 0; 
    let maxIndex = this.pageSize;
    
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;

      minIndex = event.pageIndex * event.pageSize;
      maxIndex = (event.pageIndex + 1) * event.pageSize;
    }

    this.pageStart = minIndex;
    this.pageEnd = maxIndex;

    this.customers = this.customersBackup.slice();

    if (!Helper.isNullOrEmpty(this.value))
      this.customers = this.customers.filter(c => c.name?.toLowerCase().includes(this.value) || c.description?.toLowerCase().includes(this.value));
  }

  resetCustomers(resetSearch: boolean = true) {
    if (resetSearch)
      this.value = undefined;
  
    this.pageIndex = 0;
    this.pageStart = 0;
    this.pageEnd = this.pageSize;
    this.changePage();
  }

  exportStreamingTimeToCsv(id: number, name: string) {
    this.streamingTimeTransmitted(id, name);
    this.totalStreamingTime(id, name);
  }

  totalStreamingTime(id: number, name: string) {

    let data: StreamingTime[] = [];

    this.calendar.getCustomerStreaming(id)
      .subscribe({
        next: output => {
        if (output.length != 0) {
          output.forEach(u => {
            data.push({
              month: u.month,
              year: u.year,
              minutes: u.totalSeconds / 60,
              seconds: u.totalSeconds
            });
          });
        }

        this.exporter.exportDataToCsv(
          data,
          ["Year", "Month", "Minutes", "Seconds"],
          `Total streaming time of ${name}`
        );
      },
      error: err => {
        console.log(err);
        this.snackBar.open(err.status, undefined, { duration: 3000 });
      }
    })
  }

  streamingTimeTransmitted(id: number, name: string) {
    let data: StreamingTime[] = [];

    this.calendar.getCustomerPublishedStreaming(id)
      .subscribe({
        next: output => {
        if (output.length != 0) {
          output.forEach(u => {
            data.push({
              month: u.month,
              year: u.year,
              minutes: u.totalSeconds / 60,
              seconds: u.totalSeconds
            });
          });
        }

        this.exporter.exportDataToCsv(
          data,
          ["Year", "Month", "Minutes", "Seconds"],
          `Streaming time trasmitted of ${name}`
        );
      },
      error: err => {
        console.log(err);
        this.snackBar.open(err.status, undefined, { duration: 3000 });
      }
    })
  }
  
}

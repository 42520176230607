import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ScormGlobalObjective } from 'src/app/models/scorm/scormGlobalObjective';
import { ScormRegistrationState } from 'src/app/models/scorm/scormRegistrationState';
import { ScormRuntimeInteraction } from 'src/app/models/scorm/scormRuntimeInteraction';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-content-scorm-view',
  templateUrl: './content-scorm-view.component.html',
  styleUrls: ['./content-scorm-view.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class ContentScormViewComponent implements OnInit {

  private contentId: number;
  private selectedType: 'objectives' | 'interactions';
  private _selectedResult: ScormRegistrationState;

  title: string;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  get selectedResult(): ScormRegistrationState { return this._selectedResult; }
  set selectedResult(value: ScormRegistrationState) {
    if (value != this._selectedResult) {
      this.objectivesDataSource = null;
      this.interactionsDataSource = null;
    }

    this._selectedResult = value;

    if (value?.ActivityDetails?.Children[0]?.Objectives != null)
      this.objectivesDataSource = new MatTableDataSource(value?.ActivityDetails?.Children[0]?.Objectives);

    if (value?.ActivityDetails?.Children[0]?.Runtime?.RuntimeInteractions != null)
      this.interactionsDataSource = new MatTableDataSource(value?.ActivityDetails?.Children[0]?.Runtime?.RuntimeInteractions);
  }

  resultsDataSource: MatTableDataSource<ScormRegistrationState>;
  resultsColumns: string[] = ['RegistrationCompletion',
                              'RegistrationSuccess',
                              'Score.Scaled',
                              'TotalSecondsTracked',
                              'LastAccessDate',
                              'CompletedDate',
                              'Learner',
                              'Actions'];

  objectivesDataSource: MatTableDataSource<ScormGlobalObjective>;
  objectivesColumns: string[] = ['Id', 'Score.Scaled', 'ScoreMin', 'ScoreMax', 'ProgressMeasure'];

  interactionsDataSource: MatTableDataSource<ScormRuntimeInteraction>;
  interactionsColumns: string[] = ['Id', 'Objectives', 'Result', 'Description'];

  constructor(private route: ActivatedRoute,
              private courseService: CourseService) { }

  ngOnInit(): void {
    this.contentId = Number(this.route.snapshot.paramMap.get('id'));

    if (this.route.snapshot.queryParamMap.has('title'))
      this.title = this.route.snapshot.queryParamMap.get('title');

    this.courseService.getScormResults(this.contentId)
      .subscribe(res => {

        let results: ScormRegistrationState[] = res.map(r => JSON.parse(r.result));
        
        let i = 0;
        results.forEach(r => {
          r.Id = `${i}`;
          i++;
        });

        this.resultsDataSource = new MatTableDataSource(results);
        this.resultsDataSource.paginator = this.paginator;
        this.resultsDataSource.sort = this.sort;

        this.resultsDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'Score.Scaled':
              return item.Score?.Scaled ?? '-';
            case 'Learner':
              return `${item.Learner?.FirstName} ${item.Learner?.LastName}`;
            default:
              return item[property];
          }
        };

        this.resultsDataSource.filterPredicate = (data, filter) => {
          return `${data.Learner?.FirstName} ${data.Learner?.LastName}`.toLowerCase().includes(filter)
              || String(data.TotalSecondsTracked).includes(filter)
              || String(data.Score?.Scaled ?? '-').includes(filter)
              || data.RegistrationSuccess.toLowerCase().includes(filter)
              || data.RegistrationCompletion.toLowerCase().includes(filter);
        };

      });
  }

  applyFilter (event: Event) {
    let value = (event.target as HTMLInputElement).value;

    this.resultsDataSource.filter = value.trim().toLowerCase();
  }

  toggleRow(result: ScormRegistrationState, type: string) {
    if (this.selectedResult?.Id !== result.Id) {
      this.selectedType = type === 'objectives' ? 'objectives' : 'interactions';
      this.selectedResult = result;
    } else {
      this.selectedResult = null;
      this.selectedType = null;
    }
  }

  collapseRow(result: ScormRegistrationState) {
    if (result.Id !== this.selectedResult?.Id)
      this.selectedResult = null;
  }

}

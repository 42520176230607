import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { Helper } from 'src/app/helpers/helper';
import { SubscriptionLevelDTO } from 'src/app/models/dto/subscriptionLevelDTO';

@Component({
  selector: 'app-showcase-header',
  templateUrl: './showcase-header.component.html',
  styleUrls: ['./showcase-header.component.scss']
})
export class ShowcaseHeaderComponent implements OnInit {

  textColor: string = 'white';

  subscriptionLevels: SubscriptionLevelDTO[] = [];

  constructor(private router: Router,
              private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.subscriptionService.getSubscriptionLevels()
      .subscribe(res => this.subscriptionLevels = res);
  }

  setImageBrightness($event: any, elementName: string): void {
    this.textColor = Helper.getImageBrightness($event, elementName);
  }

  goTo(nameKey: string) {
    this.router.navigate(['/level', nameKey]);
  }

  truncate(value: string) {
    return Helper.getTruncateShowcase(value, 'No summary', 150);
  }

}

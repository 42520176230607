<div>

    <div class="row-arrange">

        <mat-form-field color="primary">
            <mat-label>{{ 'Search' | translate }}</mat-label>
            <input matInput [formControl]="searchWordForm" (keyup)="applyFilter()">
            <button matSuffix mat-icon-button
                (click)="searchWordForm.setValue(undefined); applyFilter();"
                [disabled]="!searchWordForm.value"
                [matTooltip]="'Clear' | translate">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'Author' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="authorForm">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="searchForm"
                        [placeholderLabel]="'Search' | translate"
                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="0">{{ 'All' | translate }}</mat-option>
                <mat-option *ngFor="let author of courseAuthorsList | search: searchForm.value: ['name']" [value]="author.id">
                    {{ author.name }} {{ author.surname }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'State' | translate }}</mat-label>  
            <mat-select (selectionChange)="filter()" [formControl]="stateForm">
                <mat-option *ngFor="let state of states" [value]="state.value">
                    {{ state.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <mat-table matSort [dataSource]="dataSource">

        <ng-container matColumnDef="joinDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Joined' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.joinDate | date: 'dd/MM/yyyy' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Author' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.author }}
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.name }}
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="mode">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Mode' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span class="truncate-text">
                    {{ element.mode | translate }}
                </span>
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Price' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.price | translate }}
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="totalViews">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Total views' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.totalViews }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstView">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'First view' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ getMonthName(element.firstView?.month) | translate }} {{ element.firstView?.year }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastView">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Last view' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ getMonthName(element.lastView?.month) | translate }} {{ element.lastView?.year }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'State' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element" [style.color]="getCourseState(element.course.state, 'color')">
                <span class="truncate-text">{{ element.state | translate }}</span>
            </mat-cell> 
        </ng-container>

        <ng-container matColumnDef="view">
            <mat-header-cell *matHeaderCellDef>{{ 'View' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">

                <button mat-icon-button
                    (click)="viewUserGraph(element)"
                    [disabled]="element.totalViews === 0"
                    [matTooltip]="'View analytics' | translate">
                    <mat-icon>analytics</mat-icon>
                </button>

                <button mat-icon-button [matTooltip]="'Go to course' | translate" (click)="goToCourse(element.id)">
                    <mat-icon>launch</mat-icon>
                </button>

            </mat-cell> 
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        <tr class="mat-row" style="height: 40px" *matNoDataRow>
            <td class="mat-cell" [colSpan]="displayedColumns.length" style="vertical-align: middle">
                <span style="margin: 10px">
                    {{ 'No data' | translate }}
                </span>
            </td>
        </tr>

    </mat-table>

    <mat-paginator #paginator
        [pageSize]="10"
        [pageSizeOptions]="[10, 25, 50]"
        [showFirstLastButtons]="true">
    </mat-paginator>

</div>

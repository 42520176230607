<div class="component-main">

    <h2>{{'Start Receiving Payments' | translate}}</h2>


    <div fxLayout="column" fxLayoutGap="40px" style="width: 100%;">
        <mat-card appearance="outlined" class="mat-elevation-z8">

            <mat-card-content fxLayout="row" fxLayoutAlign="center center">

                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint
                occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.


            </mat-card-content>

            <mat-card-content fxLayout="row" fxLayoutAlign="center center">

                <mat-form-field style="margin-left: 30px; margin-top: 15px;">
                    <mat-label>{{ '' | translate }}</mat-label>
                    <input matInput value={{stripeid}} readonly>
                </mat-form-field>

            </mat-card-content>

            <mat-card-content fxLayout="row" fxLayoutAlign="center center">

                <button mat-raised-button color="primary" (click)="createStripeacct()" *ngIf="!btndis">
                    <mat-icon>
                        account_box
                    </mat-icon>
                    {{ 'Create account' | translate }}
                </button>
                <button mat-raised-button color="primary" style="margin-left:10px;" (click)="getstripeacctLink()"
                *ngIf="btndis">
                    <mat-icon>
                        connected_tv
                    </mat-icon>
                    {{ 'Connect with Stripe' | translate }}
                </button>
            </mat-card-content>
        </mat-card>



    </div>



</div>
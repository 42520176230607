import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent {

  @Input()
  errorTitle: string;

  @Input()
  errorContent: string;

  @Input()
  customClass: string;
  
  @Input()
  center: boolean = true;

  @Input()
  bold: boolean = false;

  constructor() { }

}

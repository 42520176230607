import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-widget',
  templateUrl: './data-widget.component.html',
  styleUrls: ['./data-widget.component.scss']
})
export class DataWidgetComponent implements OnInit {

  @Input()
  title: string = null;

  @Input()
  icon: string = null;

  @Input()
  color: string = null;

  @Input()
  value: string = null;

  @Input()
  unit: string = null;

  @Input()
  nextValue: number = null;

  constructor() { }

  ngOnInit(): void { }

}

<div class="component-main">

    <div fxLayout="column">
      <div fxLayoutAlign="center center">
        <button mat-stroked-button (click)="goBack()">
          <mat-icon matListIcon>arrow_back</mat-icon>
          {{ 'Back' | translate }}
        </button>
      </div>

      <div fxFlex="grow" fxLayout="column" fxLayoutAlign="space-between center">
        <h1 class="text-center"><strong>{{ surveyDto?.name }}</strong></h1>
        <h3 class="text-center">{{ surveyDto?.description }}</h3>
        <div></div>
        <button mat-raised-button color="primary" (click)="startSurvey()" *ngIf="!json">{{ 'start survey' | translate }}</button>
      </div>
    </div>

    <app-survey *ngIf="json" [json]="json" [isPreview]="isPreview" (submitSurvey)="sendData($event)"></app-survey>
</div>

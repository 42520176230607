<div class="component-main">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">

        <h2 style="align-self: center">
            {{ (isAdmin() || isCustomerAdmin() ? 'Lessons' : 'My Lessons') | translate }}
        </h2>

    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">

        <div fxFlex="25">
            <mat-form-field color="primary">
                <mat-label>{{ 'Search' | translate }}...</mat-label>
                <input matInput [(ngModel)]="value" (ngModelChange)="resetLessons(false)">
                <button matSuffix mat-icon-button [disabled]="!value" [matTooltip]="'Clear' | translate" (click)="resetLessons(true)">
                  <mat-icon>clear</mat-icon>
                </button>  
            </mat-form-field>
        </div>

        <div
            fxLayout="row"
            [fxLayoutAlign]="showAgenda ? 'end center' : 'center center'"
            fxLayoutGap="10px"
            fxFlex
            style="margin-right: 20px">

            <button mat-fab extended
                *ngIf="isAdmin() || isCustomerAdmin() || isTeacher() || isTutor()"
                (click)="addEasyLesson()"
                [disabled]="(!isTeacher() && !isTutor()) || !currentUser.streamingEnabled"
                [style.background-color]="(isTeacher() || isTutor()) && currentUser.streamingEnabled ? '#5092CF' : ''"
                [style.color]="(isTeacher() || isTutor()) && currentUser.streamingEnabled ? 'white' : ''"
                style="width: 150px"
                matTooltip="Easy Stream"
                matTooltipPosition="below">
                Easy Stream!
            </button>      
            <button mat-fab
                (click)="getLessons()"
                [matTooltip]="'Refresh' | translate"
                matTooltipPosition="below"
                color="primary">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-fab
                *ngIf="isTeacher() || isTutor()"
                (click)="forceCloseLessons()"
                [matTooltip]="'Teacher Stuck' | translate"
                matTooltipPosition="below"
                color="warn">
                <mat-icon>videocam_off</mat-icon>
            </button>
            <button mat-fab extended
                *ngIf="isAdmin() || isCustomerAdmin() || isTeacher() || isTutor()"
                (click)="addStandardLesson()"
                [disabled]="(!isTeacher() && !isTutor()) || !currentUser.streamingEnabled"
                [matTooltip]="'Add Live Lesson' | translate"
                matTooltipPosition="below"
                color="accent">
                <mat-icon>add</mat-icon>
                {{ 'Lesson' | translate }}
            </button>
            <button mat-fab extended
                *ngIf="isAdmin() || isCustomerAdmin() || isTeacher() || isTutor()"
                (click)="addSurvey()"
                [disabled]="!isTeacher() && !isTutor()"
                [matTooltip]="'Add survey' | translate"
                matTooltipPosition="below"
                color="primary">
                <mat-icon>add</mat-icon>
                {{ 'Survey' | translate }}
            </button>
        </div>

        <div fxFlex="25">

            <h3 *ngIf="showAgenda">{{ 'On the agenda' | translate }}</h3>

            <div *ngIf="isAdmin()" fxLayoutAlign="end center">
                <mat-form-field color="primary">
                    <mat-label>{{ 'Customer' | translate }}</mat-label>
                    <mat-select [formControl]="selectedCustomer" (selectionChange)="getLessons()">
                        <mat-option>
                            <ngx-mat-select-search
                                [formControl]="selectedCustomerSearch"
                                [placeholderLabel]="'Search' | translate"
                                [noEntriesFoundLabel]="'No data matching the word' | translate">
                            </ngx-mat-select-search>
                        </mat-option>
                       <mat-option [value]="-1">{{ 'All Customers' | translate }}</mat-option>
                       <mat-option *ngFor="let item of customers | search: selectedCustomerSearch.value: ['name']" [value]="item.id">
                            {{ item.name }}
                        </mat-option>
                   </mat-select>
                </mat-form-field>
            </div>
        </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px" style="width: 100%">

        <div fxLayout="column" fxLayoutAlign="center center" fxFlex>

            <mat-accordion #lessonsList class="my-headers-align" style="width: 100%">
                <mat-expansion-panel *ngFor="let lesson of lessons | slice:pageStart:pageEnd"
                    (opened)="getLessonContents(lesson); getLessonSmilyRecordings(lesson);"
                    [style]="border(lesson)"
                    style="margin-bottom: 10px">
        
                    <mat-expansion-panel-header>

                        <mat-panel-title class="truncate-text">
                            <mat-icon [matTooltip]="getLessonType(lesson) | translate">
                                {{ getLessonIcon(lesson) }}
                            </mat-icon>

                            <span class="lesson-title truncate-text" style="white-space: normal">
                                {{ lesson.name }}
                            </span>
                        </mat-panel-title>

                        <mat-panel-description>
                            <div class="lesson-description truncate-text" style="width: 20%">
                                {{ lesson.teacher.name }} {{ lesson.teacher.surname }}
                            </div>

                            <div *ngIf="lesson.stopDate; else lessonStatus" class="lesson-description" style="width: 30%">
                                {{ (lesson.typeId === 4 ? lesson.startPlanned : lesson.startDate) | date: 'dd/MM/yyyy' }}
                                
                                {{ (lesson.typeId === 4 ? lesson.startPlanned : lesson.startDate) | date: 'HH:mm' }}
                                -
                                {{ lesson.typeId === 4 ? (lesson.endPlanned | date: 'HH:mm') : (lesson.stopDate ? (lesson.stopDate | date: 'HH:mm') : '??:??') }}
                            </div>

                            <ng-template #lessonStatus>
                                <div class="lesson-description" style="width: 30%">
                                    {{ getTextStatus(lesson) | translate }}
                                </div>
                            </ng-template>

                            <div class="lesson-description truncate-text" style="width: 20%">
                                {{ lesson.classroom.name }}
                            </div>

                            <div style="text-align: end; width: 30%">
                                &nbsp;

                                <button mat-icon-button
                                    *ngIf="lesson.recodingPlanned && (!lesson.privateRecording || isLessonPublisher(lesson))"
                                    [matTooltip]="(lesson.stopDate ? 'Recording available' : 'Recording planned') | translate"
                                    [style.color]="lesson.stopDate ? 'red' : 'grey'">
                                    <mat-icon>
                                        fiber_manual_record
                                    </mat-icon>
                                </button>

                                <button mat-icon-button
                                    *ngIf="(lesson.typeId === 1 || lesson.typeId === 3) && !lesson.stopDate"
                                    (click)="goToLesson(lesson)"
                                    [matTooltip]="'Go to lesson!' | translate">
                                    <mat-icon>
                                        play_circle_filled
                                    </mat-icon>
                                </button>

                                <button mat-icon-button
                                    *ngIf="lesson.typeId === 4 && !lesson.stopDate"
                                    (click)="goToSurvey(lesson, isLessonPublisher(lesson))"
                                    [disabled]="goToSurveyDisabled(lesson)"
                                    [matTooltip]="'Go to survey!' | translate">
                                    <mat-icon>
                                        play_lesson
                                    </mat-icon>
                                </button>

                                <button mat-icon-button
                                    *ngIf="isLessonPublisher(lesson) && lesson.typeId === 4"
                                    (click)="showResultSurvey(lesson)"
                                    [matTooltip]="'Show survey answer' | translate">
                                    <mat-icon
                                        [matBadgeHidden]="!lesson.surveyAnswer || lesson.surveyAnswer?.length === 0" 
                                        [matBadge]="lesson.surveyAnswer?.length ?? 0">
                                        people
                                    </mat-icon>
                                </button>

                                <button mat-icon-button
                                    *ngIf="!isLessonPublisher(lesson) && lesson.typeId === 4 && lesson.surveyAnswer?.length > 0"
                                    (click)="goToSurveyResult(lesson)"
                                    [disabled]="goToSurveyAnswerDisabled(lesson)"
                                    [matTooltip]="'View survey results' | translate">
                                    <mat-icon>
                                        content_paste
                                    </mat-icon>
                                </button>

                                <button mat-icon-button
                                    *ngIf="(isLessonPublisher(lesson) || (isClassPublisher(lesson) && lesson.typeId === 1)) && !lesson.stopDate"
                                    (click)="closeLesson(lesson)"
                                    [matTooltip]="'Close' | translate">
                                    <mat-icon>
                                        close
                                    </mat-icon>
                                </button>

                                <button mat-icon-button color="warn"
                                    *ngIf="isLessonPublisher(lesson) && lesson.stopDate"
                                    (click)="deleteLesson(lesson.id, lesson.name, lesson.recodingPlanned)"
                                    [matTooltip]="'Delete' | translate">
                                    <mat-icon>
                                        delete
                                    </mat-icon>
                                </button>

                                <button mat-icon-button
                                    *ngIf="(isLessonPublisher(lesson) || isClassPublisher(lesson)) && lesson.stopDate && lesson.typeId !== 4"
                                    (click)="goToReport(lesson)"
                                    [matTooltip]="'Go to report' | translate">
                                    <mat-icon>
                                        assessment
                                    </mat-icon>
                                </button>

                                <button mat-icon-button
                                    *ngIf="isLessonPublisher(lesson) && lesson.typeId === 4"
                                    (click)="showAnalyticsSurvey(lesson)"
                                    [matTooltip]="'View analytics' | translate">
                                    <mat-icon>analytics</mat-icon>
                                </button>

                            </div>

                        </mat-panel-description>

                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>

                        <mat-divider style="margin-bottom: 20px" [horizontal]="true"></mat-divider>

                        <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
        
                            <!-- Descrizione e Durata della lezione -->
                            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
        
                                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" [fxFlex]="lesson.stopDate != null ? 65 : ''"
                                    *ngIf="lesson.description">
                                    <span class="lesson-panel-title">{{ 'Description' | translate }}</span>
                                    <div [innerText]="lesson.description"></div>
                                </div>
        
                                <mat-divider [vertical]="true" *ngIf="lesson.description && lesson.stopDate != null"></mat-divider>
        
                                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex
                                    *ngIf="lesson.stopDate != null">
                                    <span class="lesson-panel-title">{{ 'Duration' | translate }}</span>
                                    <p>
                                        {{ 'The lesson lasted' | translate }} {{ dateDiff(lesson.startDate, lesson.stopDate) }}
                                        {{ 'of the' | translate }} {{ dateDiff(lesson.startPlanned, lesson.endPlanned) }}
                                        {{ 'planned' | translate }}
                                    </p>
                                </div>
        
                            </div>
        
                            <!-- Contenuti e bottone per visualizzare eventuali registrazioni -->
                            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
        
                                <div *ngIf="attachment.length > 0"fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex>
                                    <span class="lesson-panel-title">{{ 'Contents' | translate }}</span>
                                    <div fxLayout="row wrap">
                                        <mat-card appearance="outlined" *ngFor="let content of attachment"
                                            fxLayout="column"
                                            style="width: 200px; height: 220px; cursor: pointer; margin-top: 10px; margin-right: 10px;"
                                            class="mat-elevation-z2">
                                            <img mat-card-image [src]="content.headerImageUrl" (click)="goToAttachment(content)" loading="lazy" style="height: 100px; min-height: 100px; object-fit: cover;">
                                            <mat-card-header>
                                                <mat-card-title class="truncate-text" style="max-width: 168px; font-size: 14px;">
                                                    {{ content.name }}
                                                </mat-card-title>
                                            </mat-card-header>
                                            <div fxFlex></div>
                                            <mat-card-actions>
                                                <button mat-raised-button color="accent"
                                                    (click)="goToAttachment(content)">
                                                    <mat-icon>launch</mat-icon>
                                                    <div class="truncate-text" style="max-width: 130px">{{ 'Go to content' | translate }}</div>
                                                </button>
                                            </mat-card-actions>
                                        </mat-card>
                                    </div>
                                </div>
        
                                <mat-divider
                                    [vertical]="true"
                                    *ngIf="attachment.length > 0 &&
                                           lesson.recodingPlanned &&
                                           lesson.stopDate &&
                                           (!lesson.privateRecording || isLessonPublisher(lesson))">
                                </mat-divider>
        
                                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" [fxFlex]="attachment.length > 0 ? 35 : 100"
                                    *ngIf="lesson.recodingPlanned &&
                                           lesson.stopDate &&
                                           (!lesson.privateRecording || isLessonPublisher(lesson))">
        
                                    <span class="lesson-panel-title">{{ 'Recordings' | translate }}</span>
        
                                    <button mat-raised-button color="primary" (click)="goToVideo(lesson)">
                                        <mat-icon>launch</mat-icon> 
                                        {{ 'Go to recording' | translate }}
                                    </button>
        
                                </div>
        
                            </div>
        
                            <!-- Elenco utenti con registrazioni provenienti dallo Smily -->
                            <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px"
                                *ngIf="userWithSimlyRec.length > 0 && isLessonPublisher(lesson)">
        
                                <span class="lesson-panel-title">Smily</span>
        
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                    <app-profile-picture *ngFor="let usr of userWithSimlyRec"
                                        [url]="usr.profilePictureUrl"
                                        [text]="usr.surname + ' ' + usr.name"
                                        [hover]="true"
                                        [routerLink]="['/smily-recordings', lesson.id]"
                                        [queryParams]="{ userId: usr.id }">
                                    </app-profile-picture>
                                </div>
        
                            </div>
        
                        </div>
                    </ng-template>
        
                </mat-expansion-panel>
        
            </mat-accordion>
        
            <mat-paginator
                *ngIf="lessons.length > 0"
                [showFirstLastButtons]="true"
                [length]="lessons.length"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                [pageSizeOptions]="[10, 25, 50]"
                (page)="changePage($event)"
                class="mat-elevation-z3 lesson-paginator"
                color="primary">
            </mat-paginator>
        
            <div *ngIf="backupLessons.length === 0">
                <app-error-message customClass="warning" fxFlex="85" fxLayoutAlign="end center"
                    [errorTitle]="'You do not have any lessons' | translate"
                    [errorContent]="'There are no active lessons available for you' | translate"
                    [center]="false">
                </app-error-message>
            </div>
    
        </div>

        <div *ngIf="showAgenda" fxLayout="column" fxLayoutAlign="center center"  fxFlex="25">

            <mat-card *ngFor="let lesson of scheduledLessons"
                [style]="border(lesson)"
                class="scheduled-lessons-card"
                fxLayout="column"
                fxLayoutGap="10px">

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <mat-icon [matTooltip]="getLessonType(lesson) | translate">
                        {{ getLessonIcon(lesson) }}
                    </mat-icon>
    
                    <div fxLayout="column" fxFlex>

                        <mat-card-title style="display: flex; justify-content: space-between;">
                            <span>{{ lesson.name }}</span>

                            <button mat-icon-button *ngIf="isLessonPublisher(lesson) || (isClassPublisher(lesson) && lesson.typeId === 1); else lessonInfo"
                                (click)="activateLesson(lesson)"
                                [disabled]="activateLessonDisabled(lesson)"
                                [matTooltip]="'Activate the lesson!'| translate">
                                <mat-icon>
                                    {{ lesson.typeId === 4 ? 'play_lesson' : 'play_circle_filled' }}
                                </mat-icon>
                            </button>
                            <ng-template #lessonInfo>
                                <span class="lesson-description">{{ getTextStatus(lesson) }}</span>
                            </ng-template>

                        </mat-card-title>

                        <mat-card-subtitle style="display: flex; justify-content: space-between;">
                            <span>{{ lesson.teacher.name }} {{ lesson.teacher.surname }}</span>

                            <span>
                                {{ lesson.startPlanned | date: 'dd/MM' }}
                                
                                {{ lesson.startPlanned | date: 'HH:mm' }}
                                -
                                {{ lesson.endPlanned | date: 'HH:mm' }}
                            </span>
                        </mat-card-subtitle>

                    </div>

                </div>

            </mat-card>

            <div *ngIf="scheduledLessons.length === 0">
                <app-error-message customClass="warning"
                    [errorTitle]="'You do not have any scheduled lessons' | translate"
                    [errorContent]="'You can check the calendar for weeks to come' | translate" 
                    [center]="false">
                </app-error-message>
            </div>

        </div>

    </div>

</div>

import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/ecommerce/service/cart.service';
import { SubscriptionService } from 'src/app/ecommerce/service/subscription.service';
import { Helper } from 'src/app/helpers/helper';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { MasterDTO, MasterType } from 'src/app/models/dto/masterDTO';
import { MasterState } from 'src/app/models/masterState';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MasterService } from 'src/app/services/master.service';
import { Location } from '@angular/common';
import { SubscriptionUserDTO } from 'src/app/models/dto/subscriptionUserDTO';
import { SharePopupComponent } from 'src/app/popup/share-popup/share-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageCodes, TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-master-showcase',
  templateUrl: './master-showcase.component.html',
  styleUrls: ['./master-showcase.component.scss']
})
export class MasterShowcaseComponent implements OnInit {

  private _master: MasterDTO;

  @Input()
  get master(): MasterDTO { return this._master; }
  set master(value: MasterDTO) {
    this._master = value;

    //this.contents = value.masterContent.filter(mc => mc.type === MasterContentType.video);
  }

  @Input()
  availablePlaces: number = 0;

  @Input()
  lessons: LessonSessionDTO[] = [];

  @Input()
  lessonPlannedDuration: number[] = [0, 0];

  @Input()
  currentUserIsParticipant: boolean = false;

  @Input()
  currentUserIsTeacher: boolean = false;

  @Input()
  currentUserHavePaid: boolean = false;

  id: number;
  currentUser: User;
  player: HTMLVideoElement;
  subscriptionUser: SubscriptionUserDTO;
  //contents: MasterContentDTO[] = [];

  canpurchased : boolean;
  currentstate : number;
  isstartget : boolean;

  href: string = "";

  fromPackage: boolean = false;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private snackBar: MatSnackBar,
              private masterService: MasterService,
              private cart: CartService,
              private dialog: MatDialog,
              private subscription : SubscriptionService,
              private translation: TranslationService) { }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();
    this.href = Helper.getUrl();

    if (this.auth.isAuthenticated()) {
      this.subscriptionresult();

      if (this.master.type === MasterType.oneToOne || this.master.type === MasterType.template)
        this.checkUserHasSubscription();
    }

    if (this.route.snapshot.queryParamMap.has('from'))
      this.fromPackage = this.route.snapshot.queryParamMap.get('from') === 'package';
  }

  subscriptionresult(){
    this.subscription.getCanPurchase(this.master.subscription.id)
      .subscribe(result => this.canpurchased = result);

    this.subscription.getState(this.master.subscription.id)
      .subscribe(result => this.currentstate = result);

    this.subscription.getsubscriptionlimituser(this.master.subscription.id)
      .subscribe(result => this.isstartget = result);
  }

  checkUserHasSubscription() {
    this.masterService.userHasBoughtSubscriptionWithOrigin(this.master.idSubscription)
      .subscribe({
        next: output => {
          this.subscriptionUser = output;
          console.log('user has already bought this master');
        },
        error: () => {
          console.log('user has not already bought this master');
          this.subscriptionUser = null;
        }
      });
  }

  joinMaster() {
    this.masterService.joinMaster(this.master.id)
      .subscribe({
        next: () => {
          this.snackBar.open('Master joined', '', { duration: 3000 });
          
          this.router.navigate(['/master', this.master.id], { queryParams: { page: 'sidenav' } });
        },
        error: err => {
          this.snackBar.open(err.error.Message, '', { duration: 3000 });
        }
      });
  }

  getAvailablePlaces() {
    let res = Math.round((this.availablePlaces / this.master.maxParticipants) * 10000) / 100;
    return res < 0 ? 0 : res;
  }

  getMasterState() {
    return MasterState;
  }

  addToCart () {
    this.cart.addMaster(this.master);
    this.router.navigateByUrl('/cart');
  }

  getHelper() {
    return Helper;
  }

  spinnerValue(value: number) {
    return value + "%";
  }

  goBack() {
    this.location.back();
  }

  payMasterFree() {
    this.masterService.buyMaster(this.master.idSubscription)
      .subscribe({
        next: res => {

          //this.snackBar.open('Master paid', '', { duration: 3000 });
          this.router.navigate(['/master', (res as any).Message], { queryParams: { page: 'sidenav' } });

        },
        error: err => this.snackBar.open(err.error.Message, '', { duration: 3000 })
      });
  }

  getMasterDuration() {
    var duration = this.master.durationInDays ? 
                   this.master.durationInDays : 
                   (new Date(this.master.stopDate).getTime() - new Date(this.master.startDate).getTime()) / (1000 * 60 * 60 * 24);

    if (duration < 28)
      return 'Weekly';

    if (duration < 90)
      return 'Monthly';

    if (duration < 180)
      return 'Quarterly';

    if (duration < 360)
      return 'Semiannual';

    return 'Yearly';
  }

  isOneToOne() {
    return this.master.type === MasterType.oneToOne;
  }

  isTemplate() {
    return this.master.type === MasterType.template;
  }

  share() {
    this.dialog.open(SharePopupComponent, {
      width: '400px', data: { title: this.master.name }
    });
  }

  getSubTitle() {
    return this.translation.currentLang.code === LanguageCodes.IT ? 'Iva inclusa' : null;
  }

  changeVisibility($event: any) {
    this.masterService.updateMasterVisibility(this.master.id, $event.value)
      .subscribe({
        next: () => this.snackBar.open('Visibility changed', '', { duration: 3000 }),
        error: err => this.snackBar.open(err.error.message, '', { duration: 3000 })
      });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { StationDTO } from 'src/app/models/dto/stationDTO';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-add-station',
  templateUrl: './add-station.component.html',
  styleUrls: ['./add-station.component.scss']
})
export class AddStationComponent implements OnInit {

  name: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(4)]);
  description: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  room: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
  row: UntypedFormControl = new UntypedFormControl(1, [Validators.required]);
  column: UntypedFormControl = new UntypedFormControl(1, [Validators.required]);
  position: UntypedFormControl = new UntypedFormControl(1, [Validators.required]);

  rooms: RoomDTO[] = [];
  rows: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  columns: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  positions: { value: number, description: string }[] = [
    { value: 1, description: "top left" },
    { value: 2, description: "top right" },
    { value: 3, description: "bottom left" },
    { value: 4, description: "bottom right" }
  ];

  isEdit: boolean = false;

  constructor(private dialogRef: MatDialogRef<AddStationComponent>,
              @Inject(MAT_DIALOG_DATA) private data,
              private calendar: CalendarService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.rooms = this.data.rooms ? this.data.rooms : [];

    if (this.data.station != null) {
      this.isEdit = true;

      let station: StationDTO = this.data.station;

      this.name.setValue(station.name);
      this.description.setValue(station.description);
      this.room.setValue(station.roomId);
      this.row.setValue(station.mapRow);
      this.column.setValue(station.mapColumn);
      this.position.setValue(station.mapPosition);
    } else {
      if (this.data.selectedRoom != null)
        this.room.setValue(this.data.selectedRoom);
      else if (this.rooms.length > 0)
        this.room.setValue(this.rooms[0]);
    }
  }

  saveStation() {
    let station = new StationDTO();
    station.name = this.name.value;
    station.description = this.description.value;
    station.roomId = this.room.value;
    station.mapRow = this.row.value;
    station.mapColumn = this.column.value;
    station.mapPosition = this.position.value;

    if (this.isEdit) {
      this.calendar.editStation(this.data.station.id, station)
        .subscribe({
          next: () => {
            this.snackBar.open('Station edited successfully', 'Dismiss', { duration: 3000 });
            this.dialogRef.close(true);
          },
          error: err => {
            this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
          }
        });
    } else {
      this.calendar.addStation(station)
        .subscribe({
          next: () => {
            this.snackBar.open('Station created successfully', 'Dismiss', { duration: 3000 });
            this.dialogRef.close(true);
          },
          error: err => {
            this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
          }
        });
    }
  }

  btnDisabled() {
    return !this.name.valid ||
           !this.description.valid ||
           !this.room.valid ||
           !this.row.valid ||
           !this.column.valid ||
           !this.position.valid;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}

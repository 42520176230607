<div id="container">
    <img id="headerImg"
        src="../assets/img/aixp/aixp_background.jpg"
        (load)="setImageBrightness($event, 'headerImg')"
        loading="lazy">

    <!--div class="absoluteText" [style.color]="textColor">
        <h1>{{ title }}</h1>
        <h3>{{ subtitle }}</h3>
        <div fxLayout="row">
            <button mat-raised-button
                (click)="goTo()"
                color="accent">
                {{ 'Subscriptions' | translate }}
            </button>
        </div>
    </div-->

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="cardContainer">
        <mat-card appearance="outlined" fxLayout="column" *ngFor="let level of subscriptionLevels">

            <img mat-card-image
                [src]="level.imageUrl"
                (click)="goTo(level.nameKey)"
                style="cursor: pointer;">

            <mat-card-header>
                <mat-card-title>
                    {{ level.name }}
                </mat-card-title>
            </mat-card-header>
            
            <mat-card-content fxFlex>
                {{ truncate(level.summary) }}
            </mat-card-content>

            <mat-card-actions>
                <button mat-raised-button color="accent" (click)="goTo(level.nameKey)">
                    <mat-icon style="margin-right: 5px;">launch</mat-icon> 
                    {{ 'More info' | translate }}
                </button>
            </mat-card-actions>

        </mat-card>
    </div>
    
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Helper } from "../helpers/helper";
import { EduPlanClassDTO } from "../models/dto/eduPlanClassDTO";
import { EduPlanContentDTO } from "../models/dto/eduPlanContentDTO";
import { EduPlanDTO } from "../models/dto/eduPlanDTO";

@Injectable({
    providedIn: 'root'
})
export class EduPlanService {
    private bckEndUrlBase = `${environment.apiUrl}/api/v2`;
    private bckEndUrlEduPlan = `${this.bckEndUrlBase}/eduplan`;
    private bckEndUrlEduPlanClassifications = `${this.bckEndUrlEduPlan}/classification`;

    constructor(private http: HttpClient) { }

    public getEduPlans(): Observable<EduPlanDTO[]> {
        return this.http.get<EduPlanDTO[]>(this.bckEndUrlEduPlan, { headers: Helper.getAuthHeader() });
    }

    public getEduPlansOfAuthor(): Observable<EduPlanDTO[]> {
        return this.http.get<EduPlanDTO[]>(`${this.bckEndUrlEduPlan}/author`, { headers: Helper.getAuthHeader() });
    }
    
    public getEduPlansClassifications(): Observable<EduPlanClassDTO[]> {
        return this.http.get<EduPlanClassDTO[]>(this.bckEndUrlEduPlanClassifications, { headers: Helper.getAuthHeader() });
    }

    public getLatestEduPlansOfUser(): Observable<EduPlanDTO[]> {
        return this.http.get<EduPlanDTO[]>(`${this.bckEndUrlEduPlan}/latests`, { headers: Helper.getAuthHeader() });
    }

    public getEduPlanContents(planId: number): Observable<EduPlanContentDTO[]> {
        return this.http.get<EduPlanContentDTO[]>(`${this.bckEndUrlEduPlan}/${planId}/contents`, { headers: Helper.getAuthHeader() });
    }

    public postEduPlan(newPlan: EduPlanDTO) {
        return this.http.post(this.bckEndUrlEduPlan, newPlan, { headers: Helper.getAuthHeader() });
    }

    public putEduPlan(planId: number, newPlan: EduPlanDTO) {
        return this.http.put(`${this.bckEndUrlEduPlan}/${planId}`, newPlan, { headers: Helper.getAuthHeader() });
    }

    public changeEduPlanState(planId: number, state: number) {
        return this.http.put(`${this.bckEndUrlEduPlan}/${planId}/state/${state}`, null, { headers: Helper.getAuthHeader() });
    }

    public addEduPlanContents(planId: number, contents: EduPlanContentDTO[]) {
        return this.http.post(`${this.bckEndUrlEduPlan}/${planId}/contents`, contents, { headers: Helper.getAuthHeader() });
    }

    public addUserToEduPlan(planId: number, userId: number) {
        return this.http.post(`${this.bckEndUrlEduPlan}/${planId}/user/${userId}`, null, { headers: Helper.getAuthHeader() });
    }

    public removeUserFromEduPlan(planId: number, userId: number) {
        return this.http.delete(`${this.bckEndUrlEduPlan}/${planId}/user/${userId}`, { headers: Helper.getAuthHeader() });
    }

    public editEduPlanContent(planId: number, contentId: number, content: EduPlanContentDTO) {
        return this.http.put(`${this.bckEndUrlEduPlan}/${planId}/content/${contentId}`, content, { headers: Helper.getAuthHeader() });
    }
}

import { CourseContentTrackerDTO } from './courseContentTrackerDTO';
import { CustomerDTO } from './customerDTO';
import { ProvinceDTO } from './provinceDTO';
import { QualificationDTO } from './qualificationDTO';
import { SocialWallLikeDTO } from './SocialWallLikeDTO';
import { SurveyAnswerDTO } from './surveyAnswerDTO';

export class UserDTO {
    id: number;
    customerId: number;
    name: string;
    surname: string;
    isAdmin: boolean;
    isCustomerAdmin: boolean;
    isTutor: boolean;
    isTeacher: boolean;
    isSpeaker: boolean;
    isStudent: boolean;
    isAuthor: boolean;
    haveIButton: boolean;
    state: number;
    email: string;
    firstPassword: number;
    changeStateDesc: string;
    profilePictureUrl: string;
    userSummary: string;
    timezone: number;
    uniqueId: string;
    created: Date;
    customer: CustomerDTO;
    idprovinceNavigation: ProvinceDTO;
    idqualificationNavigation: QualificationDTO;
    telephone: string;
    surveyAnswer: SurveyAnswerDTO[];
    courseContentTracker: CourseContentTrackerDTO[];
    
    socialwallLike : SocialWallLikeDTO;
    //Non restituito dal backend e popolato solo a livello UI
    public havePaid: boolean = false;
}

<div class="video-container">

    <video #videoplayer disablepictureinpicture
        [controlsList]="controls"
        [style.object-fit]="objectFit"
        [class.mat-elevation-z8]="shadow"
        crossorigin="anonymous"
        oncontextmenu="return false;">
        <source *ngIf="media" [src]="media" type="video/mp4"/>
        <track *ngIf="caption" [src]="caption.url" [label]="caption.label" kind="captions" />
    </video>

    <div *ngIf="showPlay" class="play-btn">
        <button mat-icon-button
            (click)="pause = !pause"
            [matTooltip]="(pause ? 'Play' : 'Pause') | translate"
            matTooltipPosition="below"
            color="accent">
            <mat-icon>{{ pause ? 'play_circle_filled' : 'pause_circle_filled' }}</mat-icon>
        </button>
    </div>

    <div *ngIf="showMute" class="mute-btn">
        <button mat-icon-button
            (click)="mute = !mute"
            [matTooltip]="(mute ? 'Unmute' : 'Mute') | translate"
            matTooltipPosition="below"
            color="accent">
            <mat-icon>{{ mute ? 'volume_off' : 'volume_up' }}</mat-icon>
        </button>
    </div>

</div>

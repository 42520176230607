export const MIN_OPERATION_LOCK_TIME: number = 2000; //ms
export const MAX_OPERATION_LOCK_TIME: number = 10000; //ms
export const CONFERENCE_START_CHECK_INTERVAL: number = 5000; //ms
export const DEVICES_CONNECTED_CHECK_INTERVAL: number = 5000; //ms
export const STREAMING_QUALITY_CHECK_INTERVAL: number = 15000; //ms

export const PRIORITY_CHECK_TIMEOUT: number = 30000; //ms
export const PRIORITY_MAX_VALUE: number = 1200;
export const PRIORITY_MIN_VALUE: number = 0;
export const PRIORITY_ADD_VALUE: number = 400;
export const PRIORITY_REMOVE_VALUE: number = 600;
export const PRIORITY_QUEUE_LENGTH: number = 10;
export const CONFERENCE_DURATION_MINUTES: number = 360;

export const PRESENTER_RESOLUTION: string = '1280x720';
export const PARTICIPANT_RESOLUTION: string = '320x180';

import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { EduPlanContentDTO } from 'src/app/models/dto/eduPlanContentDTO';
import { MasterDTO, MasterType } from 'src/app/models/dto/masterDTO';
import { MasterState } from 'src/app/models/masterState';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MasterService } from 'src/app/services/master.service';
import { SelectionElement, SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { CARDS_MARGIN, COMPACT_CARD_WIDTH, STANDARD_CARD_WIDTH } from '../../showcase/showcase.component';
import { ActivatedRoute } from '@angular/router';

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 40; //px

@Component({
  selector: 'app-master-list',
  templateUrl: './master-list.component.html',
  styleUrls: ['./master-list.component.scss']
})
export class MasterListComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.rowSize = Math.floor((window.innerWidth - (PAGE_PADDING * 2)) / this.cardSize);
  }

  currentUser: User;
  masters: MasterDTO[] = [];
  search: string;
  backupMasters: MasterDTO[] = [];
  customer: UntypedFormControl = new UntypedFormControl(0);
  customers: CustomerDTO[] = [];
  classroom: UntypedFormControl = new UntypedFormControl(0);
  classrooms: ClassroomDTO[] = [];

  pageMode: 'standard' | 'mymasters' = 'standard';
  compactMode: boolean = false;
  cardSize: number = 0;
  rowSize: number = 0;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private masterService: MasterService,
              private selectionService: SelectionService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.compactMode = this.currentUser.frontEndType === 1;
    this.cardSize = CARDS_GAP + CARDS_MARGIN + (this.compactMode ? COMPACT_CARD_WIDTH : STANDARD_CARD_WIDTH);
    this.onResize(undefined);

    if (this.route.snapshot.queryParamMap.has('mode'))
      this.pageMode = this.route.snapshot.queryParamMap.get('mode') === 'mymasters' ? 'mymasters' : 'standard';

    if (this.selectionService.showSelection && this.selectionService.mode !== SelectionMode.eduPlan)
      this.pageMode = 'mymasters';
    
    this.getMasters();

    if (this.isAuthenticated())
      this.fetchCustomers();
  }

  getMasters() {
    this.toggleCustomer(true);

    let result = this.pageMode === 'mymasters'
               ? this.masterService.getUserMaster()
               : this.masterService.getMasters(this.auth.isAuthenticated());

    result.subscribe(output => {

      if (output.length === 0)
        return;

      this.masters = this.selectionService.showSelection && this.selectionService.mode === SelectionMode.eduPlan
                   ? output.filter(m => m.idCustomer === this.selectionService.data)
                   : output;
      this.backupMasters = this.masters.slice();

    });
  }

  getMasterState() {
    return MasterState;
  }

  onCustomerChange() {
    this.masters = this.customer.value !== 0
                 ? this.backupMasters.filter(m => m.idCustomer === this.customer.value)
                 : this.backupMasters.slice();
  }

  fetchCustomers() {
    this.masterService.getCustomersOfMaster()
      .subscribe(output => this.customers = output);
  }
  
  toggleCustomer(toggle: boolean) {
    if(toggle) {
      this.customer.enable();
      this.customer.setValue(0);
    } else {
      this.customer.disable();
      this.customer.setValue(0);
    }
  }

  getSelectionEntity(entity: MasterDTO) {
    //if (this.selectionService.mode === SelectionMode.package && entity.state > MasterState.online && entity.idAuthor === this.currentUser?.id)
    //  return new SelectionElement(entity.id, entity.name, entity.imageUrl, <PackageContentDTO>{ idMaster: entity.id, type: PackageContentType.master }, PackageContentType.master);
    if (this.selectionService.mode === SelectionMode.eduPlan && entity.type === MasterType.standard)
      return new SelectionElement(entity.id, entity.name, entity.imageUrl, <EduPlanContentDTO>{ idCourse: entity.id, sequenceIndex: 0, expirationDate: new Date() }, 1);

    return null;
  }

  getSelectionEnabledMode() {
    return [SelectionMode.eduPlan];
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  gridTemplateColumns() {
    let total = this.rowSize;

    if (this.masters.length < total)
      total = this.masters.length;

    let columns = '';

    for (let i=0; i<total; i++)
      columns += 'auto ';

    return columns;
  }

}

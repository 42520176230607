import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { AuthService } from 'src/app/services/auth.service';
import { WallService } from '../service/wall.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-dialogdispuserlike',
  templateUrl: './dialogdispuserlike.component.html',
  styleUrls: ['./dialogdispuserlike.component.scss']
})
export class DialogdispuserlikeComponent implements OnInit {

  currentUser: User;
  userArr: UserDTO[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private wallService : WallService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    
    this.wallService.getSocialWallLikeBySocialWallId(this.data.socialWallId).subscribe((output) => {

      this.userArr = output.map(l => {

        let user = l.iduserNavigation;

        user.socialwallLike = l;
        user.name = user.id === this.currentUser.id
                  ? " You"
                  : user.name + " " + user.surname;

        return user;

      });

    });
  }

}

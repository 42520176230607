import { Router } from "@angular/router";
import { CourseContentDTO } from "../models/dto/courseContentDTO";
import { ContentLockedPopupComponent } from "../pages/course/content-locked-popup/content-locked-popup.component";
import { CourseService } from "../services/course.service";
import { User } from "../models/user";
import { MatDialog } from "@angular/material/dialog";
import { CourseContentType } from "../models/courseContentType";

const surveyIcon: string = '/assets/img/survey_preview.png';

export class ContentHelper {

    private router: Router = undefined;
    private dialog: MatDialog = undefined;
    private courseService: CourseService = undefined;

    private user: User = undefined;
    private contents: CourseContentDTO[] = undefined;
    private content: CourseContentDTO = undefined;

    private courseAuthorId: number =  undefined;
    private courseMode: number = undefined;

    private _typeTitle: string = undefined;
    private _typeIcon: string = undefined;
    private _headerImg: string = undefined;
    //private _customOrder: boolean = false;

    constructor(router: Router,
                dialog: MatDialog,
                courseService: CourseService,
                user: User,
                content: CourseContentDTO,
                contents: CourseContentDTO[],
                courseAuthorId: number,
                courseMode: number) {

        this.router = router;
        this.dialog = dialog;
        this.courseService = courseService;

        this.user = user;
        this.contents = contents;
        this.content = content;
        this.courseAuthorId = courseAuthorId;
        this.courseMode = courseMode;

        this._typeTitle = this.getContentTypeTitle();
        this._typeIcon = this.getContentTypeIcon();
        this._headerImg = ContentHelper.headerImg(content);
        //this._customOrder = courseMode === 0 && contents.some(cc => cc.orderIndex < 1 || cc.orderIndex > 5);
    }

    typeTitle() {
        return this._typeTitle;
    }

    typeIcon() {
        return this._typeIcon;
    }

    headerImg() {
        return this._headerImg;
    }

    static headerImg(content: CourseContentDTO) {
        return content.type === CourseContentType.Survey ? surveyIcon : content.headerImageUrl;
    }

    //forceCustomOrder() {
    //    this._customOrder = true;
    //}

    goTo() {
        if (this.content.type === CourseContentType.Survey) {

            if (this.user.id === this.courseAuthorId) {
                this.goToSurvey();
                return;
            } else if (this.content.lessonSession.surveyAnswer && this.content.lessonSession.surveyAnswer.length > 0) {
                this.goToSurveyResults();
                return;
            }
            
        }

        this.courseService.getContentTracker(this.content.id)
          .subscribe(output => {
    
            if (this.user.id != this.courseAuthorId && output.nextContentId != this.content.id) {
                let prevContent = this.contents.find(c => c.id === output.nextContentId);
    
                const dialogRef = this.dialog.open(ContentLockedPopupComponent, {
                    data: { contentName: prevContent.name, orderIndex: prevContent.orderIndex },
                    width: '400px'
                });
    
            } else {
                if (this.content.type === CourseContentType.Survey) {
                    this.router.navigate(['/survey', this.content.lessonSession.idSurvey], {
                        queryParams: {
                            idLesson: this.content.idLesson,
                            idContent: this.content.id
                        }
                    });
                } else {
                    this.router.navigate(['/course-content', this.content.id], {
                        queryParams: {
                            start: output.courseContentTracker ? output.courseContentTracker.stopPosition : 0
                            //customOrder: this._customOrder
                        }
                    });
                }
            }
        });
    }

    goToSurvey() {
        this.router.navigate(['/survey', this.content.lessonSession.idSurvey], { queryParams: { view: 'preview' } } );
    }
    
    goToSurveyResults() {
        this.router.navigate(['/survey-result', this.content.lessonSession.id], { queryParams: { idSurvey: this.content.lessonSession.idSurvey } } );
    }

    goToSurveyResultsForAuthor() {
        this.router.navigate(['survey-results',  this.content.lessonSession.idSurvey], { queryParams: { idLesson: this.content.lessonSession.id, idClassroom: this.content.lessonSession.classId } } );
    }

    goToSurveyAnalytics() {
        this.router.navigate(['survey-analytics', this.content.lessonSession.idSurvey], { queryParams: { idLesson: this.content.lessonSession.id, idClassroom: this.content.lessonSession.classId } } );
    }

    private getContentTypeTitle() {
        return this.content.type === CourseContentType.Video
             ? 'VIDEO'
             : this.content.type === CourseContentType.Pdf
             ? 'PDF'
             : this.content.type === CourseContentType.Survey
             ? 'SURVEY'
             : this.content.type === CourseContentType.Scorm
             ? 'SCORM'
             : this.content.type === CourseContentType.Audio
             ? 'AUDIO'
             : this.content.type === CourseContentType.Folder
             ? 'FOLDER'
             : 'FILE';
    }
    
    private getContentTypeIcon() {
        return this.content.type === CourseContentType.Video
             ? 'smart_display'
             : this.content.type === CourseContentType.Pdf
             ? 'picture_as_pdf'
             : this.content.type === CourseContentType.Survey
             ? 'assignment'
             : this.content.type === CourseContentType.Scorm
             ? 'quiz'
             : this.content.type === CourseContentType.Audio
             ? 'mic'
             : this.content.type === CourseContentType.Folder
             ? 'folder'
             : 'insert_drive_file';
    }

}

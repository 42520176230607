import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { User } from 'src/app/models/user';
import { UserRole } from 'src/app/models/userRole';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { AuthService } from 'src/app/services/auth.service';
import { ClassroomService } from 'src/app/services/classroom.service';

@Component({
  selector: 'app-conference-participants',
  templateUrl: './conference-participants.component.html',
  styleUrls: ['./conference-participants.component.scss']
})
export class ConferenceParticipantsComponent implements OnInit {

  @Input()
  conference: ConferenceDTO;

  @Input()
  connectedUserIds: number[] = [];

  @Input()
  dragBoundary: string = null;

  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  participantsSource: MatTableDataSource<UserDTO> = new MatTableDataSource();
  participantsSourceColumns: string[] = ['name', 'surname', 'email', 'role'];

  isDragging: boolean = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private classroomService: ClassroomService,
              private auth: AuthService,
              private rolePipe: RolePipe) { }

  ngOnInit(): void {
    if (this.auth.isDT())
      this.participantsSourceColumns = this.participantsSourceColumns.concat(['province', 'qualification', 'telephone']);

    this.getWebinarParticipants();
  }

  getWebinarParticipants() {
    this.classroomService.getUsersOfClassroom(this.conference.lessonSession.classId)
      .subscribe(res => {

        let users = res.filter((u: UserDTO) => this.connectedUserIds.findIndex(cu => cu === u.id) !== -1);

        this.participantsSource = new MatTableDataSource(users);

        this.participantsSource.paginator = this.paginator;
        this.participantsSource.sort = this.sort;

        this.participantsSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'role':
              return this.getRole(item);
            case 'status':
              return this.getConnStatus(item.id);
            default:
              return item[property];
          }
        };

      });
  }

  getRole(user: UserDTO) {
    return this.rolePipe.transform(UserRole.role(user));
  }

  getConnStatus(userId: number) {
    return this.connectedUserIds.includes(userId);
  }

}

import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { CourseService } from 'src/app/services/course.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { SelectionElement, SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { PackageContentType } from 'src/app/models/packageState';
import { PackageContentDTO } from 'src/app/models/dto/packageContentDTO';
import { EduPlanContentDTO } from 'src/app/models/dto/eduPlanContentDTO';
import { Helper } from 'src/app/helpers/helper';
import { CARDS_MARGIN, COMPACT_CARD_WIDTH, STANDARD_CARD_WIDTH } from '../../showcase/showcase.component';
import { ClassroomService } from 'src/app/services/classroom.service';

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 40; //px

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.rowSize = Math.floor((window.innerWidth - (PAGE_PADDING * 2)) / this.cardSize);
  }

  currentUser: User;
  courses: CourseDTO[] = [];
  backupCourses: CourseDTO[] = [];
  customer: UntypedFormControl = new UntypedFormControl(0);
  customers: CustomerDTO[] = [];
  classroom: UntypedFormControl = new UntypedFormControl(0);
  classrooms: ClassroomDTO[] = [];
  hashTag: UntypedFormControl = new UntypedFormControl(0);
  hashTags: string[] = [];
  search: UntypedFormControl = new UntypedFormControl(null);

  compactMode: boolean = false;
  cardSize: number = 0;
  rowSize: number = 0;

  constructor(private auth: AuthService,
              private courseService: CourseService,
              private classroomService: ClassroomService,
              private location: Location,
              private selectionService: SelectionService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.compactMode = this.currentUser.frontEndType === 1;
    this.cardSize = CARDS_GAP + CARDS_MARGIN + (this.compactMode ? COMPACT_CARD_WIDTH : STANDARD_CARD_WIDTH);
    this.onResize(undefined);

    this.getCourses();

    if (this.isAuthenticated()) {
      this.fetchCustomers();
      this.fetchClassRoom();
      this.fetchHashtag();
    }
  }

  getCourses() {
    this.search.setValue(null);
    this.toggleClassroom(true);
    this.toggleCustomer(true);
    this.toggleHashtag(true);

    this.courseService.getCourses(this.isAuthenticated())
      .subscribe(output => {
        if(output.length > 0)
          this.courses = this.selectionService.showSelection && this.selectionService.mode === SelectionMode.eduPlan ?
                         output.filter(c => c.idCustomer === this.selectionService.data) :
                         output;

        this.backupCourses = this.courses.slice();
      });
  }

  getCoursesOfClass() {
    this.courseService.getCoursesOfClass(this.classroom.value)
      .subscribe(output => {
        this.courses = output;
        this.backupCourses = this.courses.slice();
      });
  }

  fetchCustomers() {
    this.courseService.getCustomersOfUser()
      .subscribe(output => {
        this.customers = output;
      });
  }

  fetchClassRoom() {
    this.classroomService.getClassrooms()
      .subscribe(output => this.classrooms = output as ClassroomDTO[]);
  }

  fetchHashtag() {
    this.courseService.getHashtags()
      .subscribe(output => {
        this.hashTags = output;
    });
  }

  onCustomerChange() {
    this.classroom.setValue(0);
    this.courses = this.customer.value !== 0
                 ? this.backupCourses.filter(c => c.idCustomer === this.customer.value)
                 : this.backupCourses.slice();
  }

  onClassroomChange() {
    this.search.setValue(null);

    if(this.classroom.value != 0) {
      this.toggleCustomer(false);
      this.getCoursesOfClass();
    } else {
      this.getCourses();
      this.toggleCustomer(true);
    }
  }

  onHashTagsChange() {
    this.courses = this.hashTag.value !== 0
                 ? this.backupCourses.filter(c => c.hashTag != null && c.hashTag.includes(this.hashTag.value))
                 : this.backupCourses.slice();
  }

  //vecchio metodo, necessita di click per cercare
  // searchWord() {
  //   this.toggleCustomer(true);

  //   if(this.search.value) {
  //     this.courseService.searchString(this.search.value)
  //     .subscribe(output => {
  //       this.courses = output.courses;
  //       this.backupCourses = this.courses.slice();

  //       this.toggleClassroom(false);
  //     });
  //   }
  // }

  searchWord() {
    this.toggleCustomer(true);
    this.courses = this.backupCourses.filter(a => (a.author.name.toLowerCase() + ' ' + a.author.surname.toLowerCase()).includes(this.search.value.toLowerCase()) || 
                                                   a.name.toLowerCase().includes(this.search.value.toLowerCase()) || 
                                                   a.hashTag.find(h => h.toLowerCase().includes(this.search.value.toLowerCase())) || 
                                                   a.description?.toLowerCase().includes(this.search.value.toLowerCase()) ||
                                                   a.footer?.toLowerCase().includes(this.search.value.toLowerCase()) ||
                                                   a.header?.toLowerCase().includes(this.search.value.toLowerCase()));                         
    this.toggleClassroom(false);

    if (Helper.isNullOrEmpty(this.search.value))
      this.courses = this.backupCourses.slice();
  }

  toggleCustomer(toggle: boolean) {
    if(toggle) {
      this.customer.enable();
      this.customer.setValue(0);
    } else {
      this.customer.disable();
      this.customer.setValue(0);
    }
  }

  toggleClassroom(toggle: boolean) {
    if(toggle) {
      this.classroom.enable();
      this.classroom.setValue(0);
    } else {
      this.classroom.disable();
      this.classroom.setValue(0);
    }
  }

  toggleHashtag(toggle: boolean) {
    if (toggle) {
      this.hashTag.enable();
      this.hashTag.setValue(0);
    } else {
      this.hashTag.disable();
      this.hashTag.setValue(0);
    }
  }

  goBack() {
    this.location.back();
  }

  getQueryParam() {
    return {
      page: this.selectionService.showSelection ? 'sidenav' : 'showcase'
    };
  }

  getSelectionEntity(entity: CourseDTO) {
    if (this.selectionService.mode === SelectionMode.package && (entity.idCustomer === this.currentUser?.idCustomer || entity.idAuthor === this.currentUser?.id))
      return new SelectionElement(entity.id, entity.name, entity.imageUrl, <PackageContentDTO>{ idCourse: entity.id, type: PackageContentType.course }, PackageContentType.course);
    if (this.selectionService.mode === SelectionMode.eduPlan)
      return new SelectionElement(entity.id, entity.name, entity.imageUrl, <EduPlanContentDTO>{ idCourse: entity.id, sequenceIndex: 0, expirationDate: new Date() }, 0);

    return null;
  }

  getSelectionEnabledMode() {
    return [SelectionMode.package, SelectionMode.eduPlan];
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }
  
  isDT() {
    return this.auth.isDT();
  }

  gridTemplateColumns() {
    let total = this.rowSize;

    if (this.courses.length < total)
      total = this.courses.length;

    let columns = '';

    for (let i=0; i<total; i++)
      columns += 'auto ';

    return columns;
  }

}

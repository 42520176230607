<div fxLayout="column">

    <div *ngIf="submitProcessing" class="cssload-container" style="top: 30%">
        <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
    </div>

    <div [style.flex-direction]="isMobile ? 'column' : 'row'"  style="display:flex;gap:3%;">

        <mat-form-field fxFlex="48">
            <mat-label>{{ 'firstName' | translate }}</mat-label>
            <input matInput [formControl]="name">
            <mat-error *ngIf="name.hasError('required')" [style.font-size]="!isMobile ? '10px' : ''">
                {{ ('First name is required') | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48">
            <mat-label>{{ 'lastName'| translate }}</mat-label>
            <input matInput [formControl]="surname">
            <mat-error *ngIf="surname.hasError('required')" [style.font-size]="!isMobile ? '9px' : ''">
                {{ ('Last name is required') | translate  }}
            </mat-error>
        </mat-form-field>
    </div>

    <!--div fxLayout="row" fxLayoutGap="4%">

        <mat-form-field appearance="standard" fxFlex="48">
            <mat-label>{{ 'Email' | translate }}</mat-label>
            <input matInput [formControl]="email">
            <mat-error *ngIf="email.hasError('required')">
                {{ 'Email is required' | translate }}
            </mat-error>
            <mat-error *ngIf="email.hasError('email')">
                {{ 'This is not an email address' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="standard" fxFlex="48">
            <mat-label>{{ 'username' | translate }}</mat-label>
            <input matInput [formControl]="username">
            <mat-error *ngIf="username.hasError('required')">
                {{ 'Username is required' | translate }}
            </mat-error>
            <mat-error *ngIf="username.hasError('pattern')">
                {{ 'Length between 6 and 200 characters and only letters, digits, dot, underscore dash' | translate }}
            </mat-error>
        </mat-form-field>

    </div-->

    <mat-form-field fxFlex>
        <mat-label>{{ 'Email' | translate }}</mat-label>
        <input matInput [formControl]="email">
        <mat-error *ngIf="email.hasError('required')">
            {{ 'Email is required' | translate }}
        </mat-error>
        <mat-error *ngIf="email.hasError('email')">
            {{ 'This is not an email address' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="showUsername == true" fxFlex>
        <mat-label>{{ 'username' | translate }}</mat-label>
        <input matInput [formControl]="username">
        <mat-error *ngIf="username.hasError('required')">
            {{ 'Username is required' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-error *ngIf="username.hasError('pattern')" style="font-size:12px;margin-top:-5%">
        {{ 'Length between 6 and 200 characters and only letters, digits, dot, underscore dash' | translate }}
    </mat-error>

    <mat-form-field fxFlex>
        <mat-label>{{ 'Password' | translate }}</mat-label>
        <input matInput [formControl]="password" [type]="showPassword ? 'text' : 'password'">
        <button mat-icon-button (click)="showPassword = !showPassword" [matTooltip]="(showPassword ? 'Hide' : 'Show') | translate" matSuffix>
            <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="password.hasError('required')">
            {{ 'Password is required' | translate }}
        </mat-error>
    </mat-form-field>
    <mat-error *ngIf="password.hasError('pattern')" style="font-size:12px;margin-top:-5%">
        {{ 'Length 8 char minimum and at least one of the following: (0..9), (a..z), (A..Z) and one special character' | translate }}
    </mat-error>

    <mat-form-field fxFlex>
        <mat-label>{{ 'Repeat password' | translate }}</mat-label>
        <input matInput [formControl]="confirmPassword" [type]="showPassword ? 'text' : 'password'">
        <button mat-icon-button (click)="showPassword = !showPassword" [matTooltip]="(showPassword ? 'Hide' : 'Show') | translate" matSuffix>
            <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="confirmPassword.hasError('required')">
            {{ (isMobile ? 'Required' : 'Password confirmation is required') | translate }} 
        </mat-error>
        <mat-error *ngIf="confirmPassword.hasError('minlength')">
            {{ 'Password should be at least 8 characters' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-error *ngIf="!checkPassword() &&
                      !password.hasError('required') &&
                      !password.hasError('minlength') &&
                      !confirmPassword.hasError('required') &&
                      !confirmPassword.hasError('minlength') &&
                      !submitProcessing" style="font-size: 12px;margin-top:-5%">
        {{ 'New password and password confirmation are not the same' | translate }}
    </mat-error>

    <mat-form-field *ngIf="!codeCustomer" fxFlex>
        <mat-label>{{ 'Customer code' | translate }}</mat-label>
        <input matInput [formControl]="customerCode">
        <mat-error *ngIf="customerCode.hasError('required')">
            {{ 'Customer code is required' | translate }}
        </mat-error>
    </mat-form-field>

    <!--div fxLayout="row" fxLayoutGap="4%">
  
        <mat-form-field appearance="standard" fxFlex="48">
            <mat-label>{{ 'Province' | translate }}</mat-label>
            <mat-select [formControl]="province">
                <mat-option *ngFor="let city of provinces" [value]="city.id">
                    {{ city.name + ' (' + city.description + ')' }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="province.hasError('required')">
                {{ 'Province is required' | translate }} 
            </mat-error>
        </mat-form-field>

        <mat-form-field  appearance="standard" fxFlex="48">
            <mat-label>{{ 'Qualification' | translate }}</mat-label>
            <mat-select [formControl]="qualification">
                <mat-option *ngFor="let qualification of qualifications" [value]="qualification.id">
                    {{ qualification.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="province.hasError('required')">
                {{ 'Qualification is required' | translate }} 
            </mat-error>
        </mat-form-field>

    </div>

    <mat-form-field appearance="standard" fxFlex>
        <mat-label>{{ 'Phone number' | translate }}</mat-label>
        <input matInput [formControl]="phoneNumber">
        <mat-error *ngIf="phoneNumber.hasError('pattern')">
            {{ 'Only numbers are allowed' | translate }} 
        </mat-error>
        <mat-error *ngIf="phoneNumber.hasError('maxlength')">
            {{ 'Max 15 numbers' | translate }} 
        </mat-error>
        <mat-error *ngIf="phoneNumber.hasError('minlength')">
            {{ 'Min 9 numbers' | translate }} 
        </mat-error>
    </mat-form-field-->

    <mat-form-field fxFlex>
        <mat-label>{{ 'Select a language' | translate }}</mat-label>
        <mat-select [formControl]="language" (selectionChange)="changeLanguage($event)">
            <mat-option *ngFor="let lang of languages" [value]="lang.code">
                {{ lang.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="showDlgs == true" fxLayout="row" fxLayoutAlign="center center" fxFlex>
        <div fxFlex>
            <mat-checkbox color="accent" [formControl]="dlgs">
                <span style="white-space: pre-wrap;">Accetto l'informativa sulla privacy</span>
            </mat-checkbox>
        </div>
        <div>
            <button mat-icon-button (click)="openDlgs()">
                <mat-icon>info_outline</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayoutAlign="center center">
      <button mat-raised-button color="accent"
        (click)="signUp()"
        [disabled]="getBtnDisabled()">
        <mat-icon>person_add</mat-icon>
        {{ 'Sign Up' | translate }}
      </button>
    </div> <!-- {{ (isOrganizationMode() ? 'Sign Up existing organization' : 'Sign Up') | translate }} -->

</div>

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'search' })
export class SearchPipe implements PipeTransform {
    transform(values: any[], value: any, fields?: string[]): any {
        
        if (!values)
            return [];

        if (!value)
            return values;

        const isString = typeof value == "string";
        const val = isString ? value.toLowerCase() : value;

        if (!fields)
            return values.filter(v => isString ? v?.toLowerCase().includes(val) : v == val);

        let res = [];

        fields.forEach(field => {
            res = res.concat(values.filter(v => isString ? v[field]?.toLowerCase().includes(val) : v[field] == val));
        });

        return res.filter((v, i, s) => s.indexOf(v) === i);
    }
}

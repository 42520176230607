export class SetDataPointRequest {
    public setPoint: string;
    public value: string;

    constructor(setPoint: string,
                value: string) {
                    
        this.setPoint = setPoint;
        this.value = value;
    }
}

<div class="component-main container">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>{{ 'Active courses' | translate }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isAuthenticated()">
        <!--button mat-stroked-button (click)="goBack()" style="margin-right: 30px;">
          <mat-icon matListIcon>arrow_back</mat-icon>
          {{ 'Back' | translate }}
        </button-->

        <mat-form-field style="margin-left: 30px; margin-top: 15px;" color="primary">
            <mat-label>{{ 'Search' | translate }}...</mat-label>
            <input matInput [formControl]="search" (keydown.enter)="searchWord()" (keyup)="searchWord()" (isFocused)="searchWord()">
            <button matSuffix mat-icon-button [disabled]="!search.value" [matTooltip]="'Search' | translate"
                (click)="searchWord()">
                <mat-icon>search</mat-icon>
            </button>
            <button matSuffix mat-icon-button [disabled]="!search.value" [matTooltip]="'Clear' | translate"
                (click)="getCourses()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field *ngIf="!isDT()" style="margin-left: 30px; margin-top: 15px;" color="primary">
            <mat-label>{{ 'Digital Content Supplier' | translate }}</mat-label>
            <mat-select [formControl]="customer" (selectionChange)="onCustomerChange()">
                <mat-option [value]="0">{{ 'All Digital Content Supplier' | translate }}</mat-option>
                <mat-option *ngFor="let item of customers" [value]="item?.id">
                    {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!isDT()" style="margin-left: 30px; margin-top: 15px;" color="primary">
            <mat-label>{{ 'Virtual Classroom' | translate }}</mat-label>
            <mat-select [formControl]="classroom" (selectionChange)="onClassroomChange()">
                <mat-option [value]="0">{{ 'All Classroom' | translate }}</mat-option>
                <mat-option *ngFor="let item of classrooms" [value]="item?.id">
                    {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="margin-left: 30px; margin-top: 15px;" color="primary">
            <mat-label>{{ 'Hashtags' | translate }}</mat-label>
            <mat-select [formControl]="hashTag" (selectionChange)="onHashTagsChange()">
                <mat-option [value]="0">{{ 'All hashtags' | translate }}</mat-option>
                <mat-option *ngFor="let item of hashTags" [value]="item">
                    {{item}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="courses.length > 0" [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">
        <app-showcase-card *ngFor="let course of courses"
            [routeUrl]="'/course'"                                                                                                                    
            [routeId]="course.id"
            [queryParams]="getQueryParam()"
            [routeMessage]="'Go to course'"
            [imgUrl]="course.imageUrl"
            [title]="course.name"
            [subtitle_extra]="course.header"
            [subtitle]="course.created | date: 'dd/MM/yyyy'"
            [description]="course.description"
            [subscription]="course.subscription"
            [enableSelectionOn]="getSelectionEnabledMode()"
            [selectionEntity]="getSelectionEntity(course)"
            [compact]="compactMode"
            [enableHighlight]="compactMode">
        
            <app-showcase-card-author-info bodyBottom
                [author]="course.author"
                mode="author"
                padding="0 16px">
            </app-showcase-card-author-info>
        
        </app-showcase-card>
    </div>

    <div *ngIf="courses.length == 0">
        <app-error-message customClass="warning"
            [errorTitle]="'There are no courses' | translate"
            [errorContent]="'Currently there are none to show' | translate">
        </app-error-message>
    </div>
</div>

<div class="component-main" fxLayoutAlign="center center" style="height: 100%">
    <div class="card mat-elevation-z8" style="text-align: center;">
        <div style="border-radius: 200px; height: 200px; width: 200px; background: #F8FAF5; margin: 0 auto;">
            <i>✓</i>
        </div>
        <h1>{{ 'Payment success' | translate }}</h1>
        <p>
            {{ 'We received your payment, thank you!' | translate }} 
        </p>
        <br />
        <p *ngIf="!isMobile">
            {{ 'Click here to go to your purchases' | translate }}:
        </p>
        <br *ngIf="!isMobile" />
        <button mat-raised-button color="primary" (click)="redirect()">
            <mat-icon>
                {{ isMobile ? 'close' : 'done' }}
            </mat-icon>
            {{ (isMobile ? 'Close' : 'Go to your purchases') | translate }}
        </button>
    </div>
</div>

<h1 mat-dialog-title>{{ 'Data import' | translate }}</h1>
<div mat-dialog-content>
    <div>
        <mat-label>{{ 'Spreadsheet' | translate }}</mat-label>
        <app-file-input
            [control]="mediaSrc"
            [maxSize]="5000"
            [acceptedTypes]="'.csv,.xls,.xlsx'"
            [rejectedErrorMessage]="('The file type must be a spreadsheet and not exceed' | translate) + ' 5MB'"
            [deletedErrorMessage]="'Please select a file' | translate"
            style="width: 100%">
        </app-file-input>
    </div>
    <span *ngIf="error" class="error-message">
        {{ error }}
    </span>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()" [disabled]="sending">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" (click)="addEvents()" [disabled]="!mediaSrc.valid || sending">Ok</button>
</div>

<div *ngIf="sending" class="cssload-container" style="top: 50%">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-station-list-popup',
  templateUrl: './station-list-popup.component.html',
  styleUrls: ['./station-list-popup.component.scss']
})
export class StationListPopupComponent implements OnInit {

  stationName: string = "";

  constructor(public dialogRef: MatDialogRef<StationListPopupComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.stationName = this.data.name;
  }

  closePopUp() {
    this.dialogRef.close(true);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { from } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddUserComponent } from './add-user/add-user.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { environment } from 'src/environments/environment';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { UserRole } from 'src/app/models/userRole';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Helper } from 'src/app/helpers/helper';
import { MatAccordion } from '@angular/material/expansion';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

    state: FormControl = new FormControl(false);
    currentUser: User;
    env = environment;

    customers: any[] = [];
    customersBackup: any = [];
    currentCustomerUsers: User[] = [];
    currentCustomerId: number = null;
    currentCustomerName: string = null;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatAccordion) accordion: MatAccordion;

    value: string;
    pageIndex: number = 0;
    pageNo: number = 0;
    pageSize: number = 10;
    pageStart: number = 0;
    pageEnd: number = this.pageSize;

    constructor(
        private auth: AuthService,
        private userService: UserService,
        private dialog: MatDialog,
        private rolePipe: RolePipe
    ) { }

    ngOnInit() {
        this.currentUser = this.auth.getCurrentUser();
        this.getUsers();
    }

    isTeacher() {
        return this.currentUser.isTeacher;
    }

    isCustomerAdmin() {
        return this.currentUser.isCustomerAdmin;
    }

    isAdmin() {
        return this.currentUser.isAdmin;
    }

    getUsers(inactive?: boolean) {
        let roles = [];

        if (this.currentUser.isTeacher || this.currentUser.isTutor || this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) {
            roles.push(UserRole.Student);
        }

        if (this.currentUser.isTutor || this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) {
            roles.push(UserRole.Speaker);
        }

        if (this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) {
            roles.push(UserRole.Teacher);
            roles.push(UserRole.Tutor);
        }

        //if (this.currentUser.isAdmin) {
        //    roles.push(UserRole.CustomerAdmin);
        //}

        this.userService.getAll(roles, undefined, inactive ? 0 : null, true)
            .subscribe(res => this.groupByClients(res as User[]));
    }

    getRole(user: User) {
        return this.rolePipe.transform(UserRole.role(user));
    }

    addUser() {
        const dialogRef = this.dialog.open(AddUserComponent,
        {
            width: '600px',
            data: {
                customerName: this.currentCustomerName,
                customerId: this.currentCustomerId
            }
        });
    
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;
    
            this.getUsers();
        });
    }

    inviteUser() {
        const dialogRef = this.dialog.open(InviteUserComponent,
        {
            width: '600px',
            data: {
                customerName: this.currentCustomerName,
                customerId: this.currentCustomerId
            }
        });
    
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;
    
            this.getUsers();
        });
    }

    groupByClients(data: User[]) {
        this.customersBackup = [];

        if (!data || data.length === 0) {
            this.setCustomer([]);
            return;
        }

        from(data)
            .pipe(
                groupBy(person => person.customerName),
                mergeMap(group => group.pipe(toArray()))
            )
            .subscribe(val => this.customersBackup.push(val))
            .add(() => {
                this.setCustomer(this.customersBackup[0]);
                this.resetCustomers();
            });
    }

    setCustomer(users: User[]) {
        this.currentCustomerUsers = users;

        this.currentCustomerId = users.length > 0 ? users[0].idCustomer : this.currentUser.idCustomer;
        this.currentCustomerName = users.length > 0 ? users[0].customerName : this.currentUser.customerName;
    }

    changePage(event?: PageEvent) {
        this.accordion?.closeAll();
    
        let minIndex = 0; 
        let maxIndex = this.pageSize;
        
        if (event) {
          this.pageIndex = event.pageIndex;
          this.pageSize = event.pageSize;
    
          minIndex = event.pageIndex * event.pageSize;
          maxIndex = (event.pageIndex + 1) * event.pageSize;
        }
    
        this.pageStart = minIndex;
        this.pageEnd = maxIndex;
    
        this.customers = this.customersBackup.slice();
    
        if (!Helper.isNullOrEmpty(this.value))
          this.customers = this.customers.filter(c => c[0].customerName?.toLowerCase().includes(this.value));
    }
    
    resetCustomers(resetSearch: boolean = true) {
        if (resetSearch)
          this.value = undefined;
      
        this.pageIndex = 0;
        this.pageStart = 0;
        this.pageEnd = this.pageSize;
        this.changePage();
    }

    isAixpMode() {
        return this.env.mode === 'aixp';
    }

    isSaratogaMode() {
        return this.env.mode === 'saratoga';
    }

    isDT() {
        return this.auth.isDT();
    }
}

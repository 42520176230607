import { Component, OnInit } from '@angular/core';
import { PurchasedService } from 'src/app/services/purchased.service';
import { SubscriptionSessionDTO } from 'src/app/models/dto/subscriptionSessionDTO';
import { SubscriptionService } from '../service/subscription.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchased',
  templateUrl: './purchased.component.html',
  styleUrls: ['./purchased.component.scss']
})
export class PurchasedComponent implements OnInit {

  purchases: SubscriptionSessionDTO[] = [];

  constructor(private purchasedService: PurchasedService) { }

  ngOnInit() {
    this.purchasedService.getAll()
      .subscribe(res => {

        this.purchases = res.subscriptionList.slice();
        this.purchases.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf());

    });
  }

}

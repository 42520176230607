import { Component, HostListener, OnInit } from '@angular/core';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { MasterDTO, MasterType } from 'src/app/models/dto/masterDTO';
import { PackageContentDTO } from 'src/app/models/dto/packageContentDTO';
import { PackageDTO } from 'src/app/models/dto/packageDTO';
import { MasterState } from 'src/app/models/masterState';
import { PackageContentType, PackageState } from 'src/app/models/packageState';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CourseService } from 'src/app/services/course.service';
import { MasterService } from 'src/app/services/master.service';
import { PackageService } from 'src/app/services/package.service';
import { SelectionElement, SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { EduPlanContentDTO } from 'src/app/models/dto/eduPlanContentDTO';
import { ConferenceService } from 'src/app/services/conference.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { WebinarState } from 'src/app/models/webinarState';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { EnvironmentHelper, EnvironmentParameterType } from 'src/app/models/environmentVariables';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { differenceInMinutes } from 'date-fns';

export const SCROLL_TIMEOUT: number = 100; //ms
export const STANDARD_CARD_WIDTH: number = 335; //px
export const COMPACT_CARD_WIDTH: number = 260; //px
export const CARDS_MARGIN: number = 10; //px

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 20; //px

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.pageSize = Math.floor((window.innerWidth - (PAGE_PADDING * 2)) / this.animationWidth);

    return this.pageSize;
  }

  currentUser: User;

  masters: MasterDTO[] = [];
  shownMasters: MasterDTO[] = [];
  shownMastersIndex: number = 0;
  selectedMasterIndex: number = undefined;

  courses: CourseDTO[] = [];
  shownCourses: CourseDTO[] = [];
  shownCoursesIndex: number = 0;
  selectedCourseIndex: number = undefined;

  packages: PackageDTO[] = [];
  shownPackages: PackageDTO[] = [];
  shownPackagesIndex: number = 0;
  selectedPackageIndex: number = undefined;

  conferences: ConferenceDTO[] = [];
  shownConferences: ConferenceDTO[] = [];
  shownConferencesIndex: number = 0;
  selectedConferenceIndex: number = undefined;

  compactMode: boolean = false;
  animationWidth: number = 0;
  pageSize: number = 0;

  appName: string = null;
  past: boolean = true;

  constructor(private auth: AuthService,
              private masterServise: MasterService,
              private courseService: CourseService,
              private packageService: PackageService,
              private conferenceService: ConferenceService,
              private selectionService: SelectionService,
              private navBar: NavBarService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    this.appName = this.currentUser?.platformName ?? EnvironmentHelper.getConfigAuto(this.auth, EnvironmentParameterType.Title);
    
    this.setCardMode(this.currentUser.frontEndType === 1);

    this.getMasters();
    this.getCourses();
    this.getPackages();
    this.getConferences();
  }

  getMasters() {
    this.masterServise.getMasters(this.auth.isAuthenticated())
      .subscribe(output => {
        
        if (output.length > 0) {
          this.masters = this.selectionService.showSelection && this.selectionService.mode === SelectionMode.eduPlan ?
                         output.filter(m => m.idCustomer === this.selectionService.data) :
                         output;
          this.shownMasters = output.slice();
        }

      });
  }

  getCourses() {
    this.courseService.getCourses(this.auth.isAuthenticated())
      .subscribe(output => {

        if (output.length > 0) {
          this.courses = this.selectionService.showSelection && this.selectionService.mode === SelectionMode.eduPlan ?
                         output.filter(c => c.idCustomer === this.selectionService.data) :
                         output;
          this.shownCourses = output.slice();
        }

      });
  }

  getPackages() {
    this.packageService.getPackages(this.auth.isAuthenticated())
      .subscribe(output => {

        if (output.length > 0) {
          this.packages = output;
          this.shownPackages = output.slice();
        }

      });
  }

  getConferences() {
      this.conferenceService.getConferencesShowcase()
        .subscribe(output => {

          if (output.length > 0) {
            this.conferences = output;
            this.shownConferences = this.conferences.slice();
          }
        });
    
  }

  getSelectionEntity(entity: any, type: PackageContentType) {
    if (this.selectionService.mode === SelectionMode.package) {

      //if (type === PackageContentType.master && entity.state > MasterState.online && entity.idAuthor === this.currentUser?.id)
      //  return new SelectionElement(entity.id, entity.name, entity.imageUrl, <PackageContentDTO>{ idMaster: entity.id, type: PackageContentType.master }, PackageContentType.master);
      if (type === PackageContentType.course && (entity.idCustomer === this.currentUser?.idCustomer || entity.idAuthor === this.currentUser?.id))
        return new SelectionElement(entity.id, entity.name, entity.imageUrl, <PackageContentDTO>{ idCourse: entity.id, type: PackageContentType.course }, PackageContentType.course);

    }

    if (this.selectionService.mode === SelectionMode.eduPlan) {
      if (type === PackageContentType.master && entity.type === MasterType.standard)
        return new SelectionElement(entity.id, entity.name, entity.imageUrl, <EduPlanContentDTO>{ idMaster: entity.id, sequenceIndex: 0, expirationDate: new Date() }, 1);
      if (type === PackageContentType.course)
        return new SelectionElement(entity.id, entity.name, entity.imageUrl, <EduPlanContentDTO>{ idCourse: entity.id, sequenceIndex: 0, expirationDate: new Date() }, 0);
    }
    
    return null;
  }

  getModerator(conference: ConferenceDTO) {
    return conference.conferencePresenter.find(r => r.role === ConferencePresenterRole.Moderator)?.presenter;
  }

  getPresenters(conference: ConferenceDTO) {
    return conference.conferencePresenter.filter(r => r.role === ConferencePresenterRole.Presenter).map(p => p.presenter);
  }

  border(conference: ConferenceDTO) {
    if (conference?.lessonSession?.stopDate)
      return null;

    let border = 'outline: solid thick ';

    let participant = conference?.lessonSession?.classroom?.classroomContent.find(p => p.userId === this.currentUser.id);
    let presenter = conference?.conferencePresenter?.find(p => p.idPresenter === this.currentUser.id);

    if ((participant || presenter) && conference?.lessonSession?.startDate && !conference?.lessonSession?.stopDate)
      return border += 'green';

    let diff = differenceInMinutes(new Date(conference?.lessonSession?.startPlanned), new Date());

    if (diff < 0)
      return null;

    if (diff <= 120)
      return border += 'red';
    
    if (diff <= 1440)
      return border += 'orange';
    
    return null;
  }

  checkIfPaidWebinarIsOnline(conference: ConferenceDTO) {
    let result = conference.lessonSession.classroom.classroomContent.filter(p => p.userId === this.currentUser.id);
    return result.length > 0 && conference.lessonSession.startDate && !conference.lessonSession.stopDate;
  }

  getSelectionEnabledMode() {
    return [SelectionMode.package, SelectionMode.eduPlan];
  }

  getPackageContentType() {
    return PackageContentType;
  }

  getMasterState() {
    return MasterState;
  }

  getPackageState() {
    return PackageState;
  }

  getWebinarState() {
    return WebinarState;
  }
  
  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  isDT() {
    return this.auth.isDT();
  }

  isSL() {
    return this.auth.isSL();
  }
  
  positionCard(index: number, carouselId: string) {
    document.getElementById(carouselId).style.transform = `translateX(-${index * this.animationWidth}px)`;
  }

  selectElement(index: number, type: 'course' | 'master' | 'package' | 'conference') {
    let elementId = undefined;

    if (type === 'course') {
      this.selectedCourseIndex = this.selectedCourseIndex === index ? undefined : index;
      elementId = 'selectedCourseInfo';
    }

    if (type === 'master') {
      this.selectedMasterIndex = this.selectedMasterIndex === index ? undefined : index;
      elementId = 'selectedMasterInfo';
    }

    if (type === 'package') {
      this.selectedPackageIndex = this.selectedPackageIndex === index ? undefined : index;
      elementId = 'selectedPackageInfo';
    }

    if (type === 'conference') {
      this.selectedConferenceIndex = this.selectedConferenceIndex === index ? undefined : index;
      elementId = 'selectedConferenceInfo';
    }

    if (type !== 'course')
      this.selectedCourseIndex = undefined;

    if (type !== 'master')
      this.selectedMasterIndex = undefined;

    if (type !== 'package')
      this.selectedPackageIndex = undefined;

    if (type !== 'conference')
      this.selectedConferenceIndex = undefined;

    this.navBar.hide('sidenav');

    if (elementId != undefined)
      setTimeout(() => document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' }), SCROLL_TIMEOUT);
  }

  private setCardMode(compact: boolean) {
    this.compactMode = compact;
    this.animationWidth = CARDS_GAP + CARDS_MARGIN + (compact ? COMPACT_CARD_WIDTH : STANDARD_CARD_WIDTH);

    this.onResize(undefined);
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PackageDTO } from 'src/app/models/dto/packageDTO';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { PackageEdit } from 'src/app/models/packageEdit';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { PackageService } from 'src/app/services/package.service';
import { SideNavService } from 'src/app/services/sidenav.service';
import { AzureStorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-package-add',
  templateUrl: './package-add.component.html',
  styleUrls: ['./package-add.component.scss']
})
export class PackageAddComponent implements OnInit {

  currentUser: User = null;
  packageToEdit: PackageDTO = null;
  sending: boolean = false;

  imageSrc: FormControl = new FormControl(undefined, [Validators.required]);
  name: FormControl = new FormControl('', [Validators.required, Validators.minLength(4)]);
  description: FormControl = new FormControl('');
  header: FormControl = new FormControl('');
  footer: FormControl = new FormControl('');
  showcase: FormControl = new FormControl('');
  duration: FormControl = new FormControl(7, [Validators.required, Validators.min(1)]);
  visibility: FormControl = new FormControl(false, [Validators.required]);

  nameTranslation: TranslationDTO = undefined;
  descriptionTranslation: TranslationDTO = undefined;
  headerTranslation: TranslationDTO = undefined;
  footerTranslation: TranslationDTO = undefined;
  showcaseTranslation: TranslationDTO = undefined;
  
  constructor(private auth: AuthService,
              public sideNav: SideNavService,
              public dialogRef: MatDialogRef<PackageAddComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private packageService: PackageService,
              private snackBar: MatSnackBar,
              private azureService: AzureStorageService,
              private router: Router) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    if (this.data?.package) {
      this.packageToEdit = this.data.package;

      this.visibility.setValue(this.packageToEdit.visibility === 2 ? true : false);
      this.duration.setValue(this.packageToEdit.durationDays);

      if (this.packageToEdit.nameTranslation)
        this.name.setValue(this.packageToEdit.nameTranslation[this.currentUser.defaultLanguage]);

      if (this.packageToEdit.descriptionTranslation)
        this.description.setValue(this.packageToEdit.descriptionTranslation[this.currentUser.defaultLanguage]);

      if (this.packageToEdit.headerTranslation)
        this.header.setValue(this.packageToEdit.headerTranslation[this.currentUser.defaultLanguage]);

      if (this.packageToEdit.footerTranslation)
        this.footer.setValue(this.packageToEdit.footerTranslation[this.currentUser.defaultLanguage]);

      if (this.packageToEdit.showcaseTranslation)
        this.showcase.setValue(this.packageToEdit.showcaseTranslation[this.currentUser.defaultLanguage]);

      this.nameTranslation = this.packageToEdit.nameTranslation;
      this.descriptionTranslation = this.packageToEdit.descriptionTranslation;
      this.headerTranslation = this.packageToEdit.headerTranslation;
      this.footerTranslation = this.packageToEdit.footerTranslation;
      this.showcaseTranslation = this.packageToEdit.showcaseTranslation;
    }
  }

  async savePackage() {
    this.toggleDisable(true);
    
    let newPackage = new PackageEdit();

    newPackage.Name = this.name.value;
    newPackage.Description = this.description.value;
    newPackage.Header = this.header.value;
    newPackage.Footer = this.footer.value;
    newPackage.ShowcaseSummary = this.showcase.value;
    newPackage.DurationDays = this.duration.value;
    newPackage.Visibility = this.visibility.value === true ? 2 : 1;
    newPackage.ImageUrl = await this.azureService.uploadFile(this.imageSrc.value);
    newPackage.NameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    newPackage.DescriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);
    newPackage.HeaderTranslation = TranslationEdit.fromDTO(this.headerTranslation);
    newPackage.FooterTranslation = TranslationEdit.fromDTO(this.footerTranslation);
    newPackage.ShowcaseTranslation = TranslationEdit.fromDTO(this.showcaseTranslation);

    if (this.packageToEdit == null) {
      this.packageService.postPackage(newPackage)
        .subscribe({
          next: success => {
            this.apiSucc('Package created successfully');
            this.router.navigate(['/package', (success as any).Message]);
          },
          error: err => this.apiErr(err)
        });
    } else {
      this.packageService.updatePackage(this.packageToEdit.id, newPackage)
        .subscribe({
          next: () => {
            this.apiSucc('Package updated successfully');
          },
          error: err => this.apiErr(err)
        });
    }
  }

  apiSucc(msg: string) {
    this.snackBar.open(msg, 'Dismiss', { duration: 3000 });
    this.toggleDisable(false);
    this.dialogRef.close(true);
  }

  apiErr(err: any) {
    console.log(err);

    this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
    this.toggleDisable(false);
    this.onNoClick();
  }

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if (toggle) {
      this.name.disable();
      this.description.disable();
      this.header.disable();
      this.footer.disable();
      this.showcase.disable();
      this.imageSrc.disable();
      this.visibility.disable();
    } else {
      this.name.enable();
      this.description.enable();
      this.header.enable();
      this.footer.enable();
      this.showcase.enable();
      this.imageSrc.enable();
      this.visibility.enable();
    }
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  disabledOkBtn() {
    var check = !this.name.valid ||
                !this.duration.valid ||
                !this.visibility.valid;

    if (!this.packageToEdit)
      return check || !this.imageSrc.valid;

    return check;
  }
}

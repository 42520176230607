import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { UserRole } from 'src/app/models/userRole';
import { CourseService } from 'src/app/services/course.service';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { CARDS_MARGIN, COMPACT_CARD_WIDTH } from '../showcase/showcase.component';
import { ConferenceService } from 'src/app/services/conference.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { differenceInMilliseconds, differenceInSeconds, isBefore } from 'date-fns';
import { Helper } from 'src/app/helpers/helper';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MasterDTO, MasterType } from 'src/app/models/dto/masterDTO';
import { MasterService } from 'src/app/services/master.service';
import { MasterState } from 'src/app/models/masterState';
import { SubscriptionSessionDTO } from 'src/app/models/dto/subscriptionSessionDTO';
import { PurchasedService } from 'src/app/services/purchased.service';
import { UserResultDTO } from 'src/app/models/dto/userResultDTO';
import { FormControl } from '@angular/forms';
import { ChartData, ChartOptions } from 'chart.js';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { TranslateService } from '@ngx-translate/core';
import { CsvExporterService } from 'src/app/services/csv-exporter.service';
import { UserResultsTotalDTO } from 'src/app/models/dto/userResultsTotalDTO';
import { UserCourseViews } from 'src/app/models/courseView';

// Fare tab package (?)

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 40; //px
const CONTAINER_EXT_GAP: number = 20; //px
//const CONTAINER_INT_GAP: number = 16; //px

const ANALYTICS_TAB_INDEX: number = 5;
const ANALYTICS_TAB_REFRESH_TIMEOUT: number = 200; //ms

const PUBLISHED_STREAMING_CHART_INDEX: number = 0;
const ONLINE_TIME_CHART_INDEX: number = 1
const WEBINAR_PERFORMED_CHART_INDEX: number = 2;
const CONFERENCE_PERFORMED_CHART_INDEX: number = 3;
const LESSON_PERFORMED_CHART_INDEX: number = 4;

const PUBLISHED_STREAMING_CHART_LABEL1: string = "Total published streaming time";
const PUBLISHED_STREAMING_CHART_LABEL2: string = "Total streaming time";
const PUBLISHED_STREAMING_CHART_COLOR1: string = "#5d9cec";
const PUBLISHED_STREAMING_CHART_COLOR2: string = "#ed5565";

const ONLINE_TIME_CHART_LABEL1: string = "Total online time";
const ONLINE_TIME_CHART_COLOR1: string = "#ac92ec";

const WEBINAR_PERFORMED_CHART_LABEL1: string = "Participated webinar";
const CONFERENCE_PERFORMED_CHART_LABEL1: string = "Participated conference";
const LESSON_PERFORMED_CHART_LABEL1: string = "Participated lessons";
const LESSON_PERFORMED_CHART_LABEL2: string = "Total streaming time";
const LESSON_PERFORMED_CHART_COLOR1: string = "#48cfad";
const LESSON_PERFORMED_CHART_COLOR2: string = "#4fc1e9";

@Component({
  selector: 'app-dashboard-user',
  templateUrl: './dashboard-user.component.html',
  styleUrl: './dashboard-user.component.scss'
})
export class DashboardUserComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let pageWidth: number = window.innerWidth - (PAGE_PADDING * 2);
    let containerWidth: number = (pageWidth * 75 / 100) - CONTAINER_EXT_GAP; // - (CONTAINER_INT_GAP * 2);  75 perchè occupa il 75% della pagina in larghezza

    this.rowSize = Math.floor(containerWidth / this.cardSize);
  }

  id: number;

  user: User;
  classrooms: ClassroomDTO[] = [];
  courses: CourseDTO[] = [];
  masters: MasterDTO[] = [];
  webinars: ConferenceDTO[] = [];
  conferences: ConferenceDTO[] = [];
  lessons: LessonSessionDTO[] = [];
  purchased: SubscriptionSessionDTO[] = [];
  userCourseViews: UserCourseViews[] = [];

  userResults: UserResultDTO[] = [];
  userResultsLastUpdate: Date = undefined;

  userLessonResults: UserResultsTotalDTO[] = [];
  userWebinarResults: UserResultsTotalDTO[] = [];
  userConferenceResults: UserResultsTotalDTO[] = [];

  publishedStreamingTimePerMonth: number = 0;
  streamingTimePerMonth: number = 0;
  onlineTimePerMonth: number = 0;

  userLessonPerformedPerMonth: number = 0;
  userLessonStreamingTimePerMonth: number = 0;
  userWebinarPerformedPerMonth: number = 0;
  userWebinarStreamingTimePerMonth: number = 0;
  userConferencePerformedPerMonth: number = 0;
  userConferenceStreamingTimePerMonth: number = 0;

  publishedStreamingTimePerYear: number[] = [];
  streamingTimePerYear: number[] = [];
  onlineTimePerYear: number[] = [];

  userLessonStreamingTimePerYear: number[] = [];
  userLessonPerformedPerYear: number[] = [];
  userWebinarStreamingTimePerYear: number[] = [];
  userWebinarPerformedPerYear: number[] = [];
  userConferenceStreamingTimePerYear: number[] = [];
  userConferencePerformedPerYear: number[] = [];

  currentDay = new Date().getDate();
  currentMonth = new Date().getMonth() + 1;
  currentYear = new Date().getFullYear();
  
  years: number[] = [
    this.currentYear,
    this.currentYear - 1,
    this.currentYear - 2,
    this.currentYear - 3,
    this.currentYear - 4,
    this.currentYear - 5
  ];
  months: { name: string, value: number }[] = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];

  yearDate: FormControl<number> = new FormControl<number>(this.currentYear);
  monthDate: FormControl<number> = new FormControl<number>(this.currentMonth);

  cardSize: number = 0;
  rowSize: number = 0;

  conferenceSource: MatTableDataSource<ConferenceDTO> = new MatTableDataSource();
  conferenceCols: string[] = ['isPublic', 'name', 'publisher', 'date', 'status'];

  webinarViewMode: 'cards' | 'table' = 'cards';
  masterViewMode: 'cards' | 'table' = 'cards';
  courseViewMode: 'cards' | 'table' = 'cards';

  @ViewChild('conferenceSort') set matConferenceSort(matSort: MatSort) {
    this.conferenceSource.sort = matSort;

    this.conferenceSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'publisher':
          let pub = this.getPublisher(item);

          if (pub)
            return `${pub.name} ${pub.surname}`;

          return null;
        case 'date':
          return item.lessonSession.startPlanned;
        case 'status':
          return item.lessonSession.startDate && item.lessonSession.stopDate
               ? item.lessonSession.startDate
               : this.getConferenceStatus(item);
        default:
          return item[property];
      }
    };
  }

  @ViewChild('conferencePaginator') set matConferencePaginator(matPaginator: MatPaginator) {
    this.conferenceSource.paginator = matPaginator;
  }

  lessonsSource: MatTableDataSource<LessonSessionDTO> = new MatTableDataSource();
  lessonsCols: string[] = ['type', 'name', 'teacher', 'date', 'classroom'];

  @ViewChild('lessonsSort') set matLessonsSort(matSort: MatSort) {
    this.lessonsSource.sort = matSort;

    this.lessonsSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'type':
          return item.typeId;
        case 'teacher':
          return `${item.teacher.name} ${item.teacher.surname}`;
        case 'date':
          return item.startPlanned;
        case 'classroom':
          return item.classroom.name;
        default:
          return item[property];
      }
    };
  }

  @ViewChild('lessonsPaginator') set matLessonsPaginator(matPaginator: MatPaginator) {
    this.lessonsSource.paginator = matPaginator;
  }

  currentChart: number = 0;
  charts: { options: ChartOptions, data: ChartData, title: string, export: () => void }[] = [
    {
      options: undefined,
      data: undefined,
      title: undefined,
      export: () => this.exportStreamingTime()
    },
    {
      options: undefined,
      data: undefined,
      title: undefined,
      export: () => this.exportOnlineTime()
    },
    {
      options: undefined,
      data: undefined,
      title: undefined,
      export: () => this.exportWebinarData()
    },
    {
      options: undefined,
      data: undefined,
      title: undefined,
      export: () => this.exportConferenceData()
    },
    {
      options: undefined,
      data: undefined,
      title: undefined,
      export: () => this.exportLessonData()
    }
  ];

  columnsToDisplay: string[] = this.months.map(m => m.name);

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private userService: UserService,
    private courseService: CourseService,
    private masterService: MasterService,
    private conferenceService: ConferenceService,
    private calendarService: CalendarService,
    private purchasedService: PurchasedService,
    private darkService: DarkThemeService,
    private translate: TranslateService,
    private exporter: CsvExporterService
  ) { }

  ngOnInit(): void {

    this.cardSize = CARDS_GAP + CARDS_MARGIN + COMPACT_CARD_WIDTH;
    this.onResize(undefined);

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    
    this.getUserData();

  }

  async filterData(type?: 'month' | 'year') {

    let userResultsOfYear = this.userResults.filter(ur => ur.year === this.yearDate.value);
    let userResultsOfMonth = userResultsOfYear.find(ur => ur.month === this.monthDate.value);

    this.publishedStreamingTimePerMonth = userResultsOfMonth?.totalPublishedStreamTime ?? 0;
    this.streamingTimePerMonth = userResultsOfMonth?.totalStreamTime ?? 0;
    this.onlineTimePerMonth = userResultsOfMonth?.totalOnlineTime ?? 0;

    let userWebinarResultsOfYear = this.userWebinarResults.filter(ur => ur.year === this.yearDate.value);
    let userWebinarResultsOfMonth = userWebinarResultsOfYear.find(ur => ur.month === this.monthDate.value);

    this.userWebinarPerformedPerMonth = userWebinarResultsOfMonth?.lessonResultCount ?? 0;
    this.userWebinarStreamingTimePerMonth = userWebinarResultsOfMonth?.totalStreamingTime ?? 0;

    let userConferenceResultsOfYear = this.userConferenceResults.filter(ur => ur.year === this.yearDate.value);
    let userConferenceResultsOfMonth = userConferenceResultsOfYear.find(ur => ur.month === this.monthDate.value);

    this.userConferencePerformedPerMonth = userConferenceResultsOfMonth?.lessonResultCount ?? 0;
    this.userConferenceStreamingTimePerMonth = userConferenceResultsOfMonth?.totalStreamingTime ?? 0;

    let userLessonResultsOfYear = this.userLessonResults.filter(ur => ur.year === this.yearDate.value);
    let userLessonResultsOfMonth = userLessonResultsOfYear.find(ur => ur.month === this.monthDate.value);

    this.userLessonPerformedPerMonth = userLessonResultsOfMonth?.lessonResultCount ?? 0;
    this.userLessonStreamingTimePerMonth = userLessonResultsOfMonth?.totalStreamingTime ?? 0;

    this.publishedStreamingTimePerYear = [];
    this.streamingTimePerYear = [];
    this.onlineTimePerYear = [];

    this.userWebinarPerformedPerYear = [];
    this.userWebinarStreamingTimePerYear = [];
    this.userConferencePerformedPerYear = [];
    this.userConferenceStreamingTimePerYear = [];
    this.userLessonPerformedPerYear = [];
    this.userLessonStreamingTimePerYear = [];

    this.months.forEach(month => {

      let currentUserResult = userResultsOfYear.find(ur => ur.month === month.value);

      this.publishedStreamingTimePerYear.push(currentUserResult?.totalPublishedStreamTime ?? 0);
      this.streamingTimePerYear.push(currentUserResult?.totalStreamTime ?? 0);
      this.onlineTimePerYear.push(currentUserResult?.totalOnlineTime ?? 0);

      let currentUserWebinarResult = userWebinarResultsOfYear.find(ur => ur.month === month.value);

      this.userWebinarPerformedPerYear.push(currentUserWebinarResult?.lessonResultCount ?? 0);
      this.userWebinarStreamingTimePerYear.push(currentUserWebinarResult?.totalStreamingTime ?? 0);

      let currentUserConferenceResult = userConferenceResultsOfYear.find(ur => ur.month === month.value);

      this.userConferencePerformedPerYear.push(currentUserConferenceResult?.lessonResultCount ?? 0);
      this.userConferenceStreamingTimePerYear.push(currentUserConferenceResult?.totalStreamingTime ?? 0);

      let currentUserLessonResult = userLessonResultsOfYear.find(ur => ur.month === month.value);

      this.userLessonPerformedPerYear.push(currentUserLessonResult?.lessonResultCount ?? 0);
      this.userLessonStreamingTimePerYear.push(currentUserLessonResult?.totalStreamingTime ?? 0);

    });

    this.updatePublishedStreamingTimeChart();
    this.updateOnlineTimeChart();
    this.updateWebinarPerformedChart();
    this.updateConferencePerformedChart();
    this.updateLessonPerformedChart();

  }

  async getUserData() {
    this.getUser();
    this.getUserClassrooms();
    this.getUserConferences();
    this.getUserLessons();
    this.getUserCourses();
    this.getUserMasters();
    this.getUserWebinars();
    this.getUserPurchased();
    this.getCourseUserViews();

    await this.getUserResults();
    await this.getUserLessonResults();

    this.filterData();
  }

  tabChange(index: number) {

    // Funzione di refresh dei dati per evitare bug di visualizzazione dati nei grafici
    if (index === ANALYTICS_TAB_INDEX && this.charts.some(c => c.data != undefined))
      setTimeout(() => this.filterData(), ANALYTICS_TAB_REFRESH_TIMEOUT);

  }

  getRole() {
    return UserRole.role(this.user);
  }

  goBack() {
    this.location.back();
  }

  gridTemplateColumns() {
    let total = this.rowSize;

    if (this.courses.length < total)
      total = this.courses.length;

    let columns = '';

    for (let i=0; i<total; i++)
      columns += 'auto ';

    return columns;
  }

  getModerator(conference: ConferenceDTO) {
    return conference.conferencePresenter.find(r => r.role === ConferencePresenterRole.Moderator)?.presenter;
  }

  getPresenters(conference: ConferenceDTO) {
    return conference.conferencePresenter.filter(r => r.role === ConferencePresenterRole.Presenter).map(p => p.presenter);
  }

  getPublisher(conference: ConferenceDTO) {
    return conference.conferencePresenter?.find(p => p.role === ConferencePresenterRole.Moderator)?.presenter;
  }

  getConferenceStatus(conference: ConferenceDTO) {
    if (conference.lessonSession.stopDate)
      return null;

    if (conference.lessonSession.state !== 2)
      return 'Conference in progress';

    if (isBefore(conference.lessonSession.endPlanned, new Date()) && !conference.lessonSession.startDate)
      return 'Expired';

    let diff = differenceInMilliseconds(conference.lessonSession.startPlanned, new Date());

    if (diff < 0 || diff > (24 * 60 * 60000)) // 24 ore
      return null;

    return Helper.convertToHMS(diff);
  }

  getLessonStatus(lesson: LessonSessionDTO) {
    if (lesson.stopDate)
      return null;

    if (lesson.state === 1) {

      if (lesson.typeId === 1 || lesson.typeId === 3)
        return 'Lesson in progress';

      if (lesson.typeId === 4)
        return 'Survey in progress';

      return null;
    }

    let diff = differenceInSeconds(lesson.startPlanned, new Date());
    
    if (lesson.state === 2 && diff < 7200)
      return Helper.convertToHMS(diff < 0 ? 0 : (diff * 1000));

    return null;
  }

  getLessonType(lesson: LessonSessionDTO) {
    if (lesson.typeId === 3)
      return lesson.classroom.master.type === MasterType.oneToOne ? 'Personal Live Digital Academy' : 'Live Digital Academy'

    if (lesson.typeId === 4)
      return 'Survey';

    return 'Lesson';
  }

  getLessonIcon(lesson: LessonSessionDTO) {
    if (lesson.typeId === 3)
      return lesson.classroom.master.type === MasterType.oneToOne ? 'person' : 'people';

    if (lesson.typeId === 4)
      return 'assignment';

    return 'school';
  }

  getMasterState() {
    return MasterState;
  }

  secondsToDhms(seconds: string | number) {
    if (!seconds)
      return '0m';

    seconds = Number(seconds);

    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor(seconds % (3600 * 24) / 3600);
    let m = Math.floor(seconds % 3600 / 60);

    return `${d > 0 ? d + 'd' : ''} ${h > 0 ? h + 'h' : ''} ${m > 0 ? m + 'm' : '0m'}`;
  }

  exportStreamingTime() {

    let exportedData: {
      year: number,
      month: number,
      totalStreamHours: string,
      totalPublishedStreamTimeHours: string
    }[] = [];
    
    this.months.forEach(month => {

      let currentUserResult = this.userResults.find(c => c.year === this.yearDate.value && c.month === month.value);

      exportedData.push({
        year: this.yearDate.value,
        month: month.value,
        totalStreamHours: this.secondsToDhms(currentUserResult?.totalStreamTime ?? 0),
        totalPublishedStreamTimeHours: this.secondsToDhms(currentUserResult?.totalPublishedStreamTime ?? 0)
      });

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "TotalStreamingTime",
        "TotalPublishedStreamingTime"
      ],
      `Total Streaming Time of ${this.user.name} ${this.user.surname}`
    );

  }

  exportOnlineTime() {

    let exportedData: {
      year: number,
      month: number,
      hours: string
    }[] = [];
    
    this.months.forEach(month => {

      let currentUserResult = this.userResults.find(c => c.year === this.yearDate.value && c.month === month.value);

      exportedData.push({
        year: this.yearDate.value,
        month: month.value,
        hours: this.secondsToDhms(currentUserResult?.totalOnlineTime ?? 0)
      });

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "Time"
      ],
      `Total Online Time of ${this.user.name} ${this.user.surname}`
    );

  }

  exportWebinarData() {

    let exportedData: {
      year: number,
      month: number,
      webinarPerformed: number,
      streamingTime: string
    }[] = [];
    
    this.months.forEach(month => {

      let currentWebinarResult = this.userWebinarResults.find(c => c.year === this.yearDate.value && c.month === month.value);

      exportedData.push({
        year: this.yearDate.value,
        month: month.value,
        webinarPerformed: currentWebinarResult?.lessonResultCount ?? 0,
        streamingTime: this.secondsToDhms(currentWebinarResult?.totalStreamingTime ?? 0)
      });

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "Webinar Participated",
        "Streaming Time",
      ],
      `Webinar Data of ${this.user.name} ${this.user.surname}`
    );

  }

  exportConferenceData() {

    let exportedData: {
      year: number,
      month: number,
      conferencePerformed: number,
      streamingTime: string
    }[] = [];
    
    this.months.forEach(month => {

      let currentConferenceResult = this.userConferenceResults.find(c => c.year === this.yearDate.value && c.month === month.value);

      exportedData.push({
        year: this.yearDate.value,
        month: month.value,
        conferencePerformed: currentConferenceResult?.lessonResultCount ?? 0,
        streamingTime: this.secondsToDhms(currentConferenceResult?.totalStreamingTime ?? 0)
      });

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "Conference Participated",
        "Streaming Time",
      ],
      `Conference Data of ${this.user.name} ${this.user.surname}`
    );

  }

  exportLessonData() {

    let exportedData: {
      year: number,
      month: number,
      lessonPerformed: number,
      streamingTime: string
    }[] = [];
    
    this.months.forEach(month => {

      let currentLessonResult = this.userLessonResults.find(c => c.year === this.yearDate.value && c.month === month.value);

      exportedData.push({
        year: this.yearDate.value,
        month: month.value,
        lessonPerformed: currentLessonResult?.lessonResultCount ?? 0,
        streamingTime: this.secondsToDhms(currentLessonResult?.totalStreamingTime ?? 0)
      });

    });

    this.exporter.exportDataToCsv(
      exportedData,
      [
        "Year",
        "Month",
        "Lesson Participated",
        "Streaming Time",
      ],
      `Lesson Data of ${this.user.name} ${this.user.surname}`
    );

  }

  private translateColumns() {
    return this.columnsToDisplay.map(c => this.translate.instant(c));
  }

  private async getUser() {
    await firstValueFrom(this.userService.get(this.id, true))
      .then((usr: User) => this.user = usr)
      .catch(err => console.error(err));
  }

  private async getUserClassrooms() {
    await firstValueFrom(this.userService.getUserClassrooms(this.id))
      .then(cls => this.classrooms = cls)
      .catch(err => console.error(err));
  }

  private async getUserCourses() {
    await firstValueFrom(this.courseService.getCoursesOfUser(this.id, true))
      .then(crs => this.courses = crs)
      .catch(err => console.error(err));
  }

  private async getUserMasters() {
    await firstValueFrom(this.masterService.getUserMaster(this.id))
      .then(mst => this.masters = mst)
      .catch(err => console.error(err));
  }

  private async getUserWebinars() {
    await firstValueFrom(this.conferenceService.getWebinarCustomer(this.id))
      .then(wbn => this.webinars = wbn)
      .catch(err => console.error(err));
  }

  private async getUserConferences() {
    await firstValueFrom(this.conferenceService.getConferences([1, 2], true, this.id))
      .then(cnf => {
        this.conferences = cnf;
        this.conferenceSource.data = cnf;
      })
      .catch(err => console.error(err));
  }

  private async getUserLessons() {
    await firstValueFrom(
      this.calendarService.getLessons(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1,
        undefined,
        [1, 3, 4],
        undefined,
        true,
        true,
        this.id)
      )
      .then(lss => {
        this.lessons = lss;
        this.lessonsSource.data = lss.reverse();
      })
      .catch(err => console.error(err));
  }

  private async getUserPurchased() {
    await firstValueFrom(this.purchasedService.getAll(this.id))
      .then(ord => this.purchased = ord.subscriptionList)
      .catch(err => console.error(err));
  }

  private async getUserResults() {
    this.userResults = [];
    this.userResultsLastUpdate = undefined;

    await firstValueFrom(this.userService.getUserResults(this.id))
      .then(rst => {
        this.userResults = rst;
        this.userResultsLastUpdate = this.userResults.map(u => u.updated).at(-1);
      })
      .catch(err => console.error(err));
  }

  private async getUserLessonResults() {

    let types: { description: 'webinar' | 'conference' | 'lesson', lesson: number[], conference: number[] }[] = [
      {
        description: 'webinar',
        lesson: [6, 8],
        conference: [3]
      },
      {
        description: 'conference',
        lesson: [6, 8],
        conference: [1, 2]
      },
      {
        description: 'lesson',
        lesson: [1, 2, 3],
        conference: []
      }
    ];

    this.userLessonResults = [];

    for (let i = 0; i < types.length; i++) {

      await firstValueFrom(
        this.calendarService.getLessonUserResults(
          this.id,
          undefined,
          undefined,
          undefined,
          types[i].lesson,
          types[i].conference
        ))
        .then(ulr => {

          if (types[i].description === 'webinar')
            this.userWebinarResults = ulr;

          if (types[i].description === 'conference')
            this.userConferenceResults = ulr;

          if (types[i].description === 'lesson')
            this.userLessonResults = ulr;
          
        })
        .catch(err => console.error(err));

    }

  }

  private async getCourseUserViews() {
    await firstValueFrom(this.courseService.getCourseUserViews(undefined, this.id))
      .then(cv => this.userCourseViews = cv)
      .catch(err => console.error(err));
  }

  private async updatePublishedStreamingTimeChart() {

    this.charts[PUBLISHED_STREAMING_CHART_INDEX].title = `${this.translate.instant('Total streaming time in')} ${this.yearDate.value}`;

    if (!this.charts[PUBLISHED_STREAMING_CHART_INDEX].options)
      this.charts[PUBLISHED_STREAMING_CHART_INDEX].options = {
        maintainAspectRatio: false,
        aspectRatio: 4,
        scales: {
          A: {
            type: 'linear',
            position: 'left',
            ticks: {
              callback: v => this.secondsToDhms(v),
              color: PUBLISHED_STREAMING_CHART_COLOR1
            }
          },
          B: {
            type: 'linear',
            position: 'right',
            ticks: {
              callback: v => this.secondsToDhms(v),
              color: PUBLISHED_STREAMING_CHART_COLOR2
            }
          },
          x: {
            ticks: {
              color: this.darkService.isSetDark ? 'white' : ''
            }
          }
        },
        plugins: {
          tooltip:{
            callbacks: {
            label: context => {
              if (!context?.parsed.y)
                return '';

              return `${context?.dataset?.label ?? ''} : ${this.secondsToDhms(context.parsed.y)}`;
            }
          }
          },
          legend: {
            display: true,
            labels: {
              color: this.darkService.isSetDark ? 'white' : ''
            }
          }
        }
      };

    this.charts[PUBLISHED_STREAMING_CHART_INDEX].data = {
      labels: this.translateColumns(),
      datasets: []
    };

    this.charts[PUBLISHED_STREAMING_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(PUBLISHED_STREAMING_CHART_LABEL1)),
      data: this.publishedStreamingTimePerYear,
      backgroundColor: PUBLISHED_STREAMING_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.charts[PUBLISHED_STREAMING_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(PUBLISHED_STREAMING_CHART_LABEL2)),
      data: this.streamingTimePerYear,
      backgroundColor: PUBLISHED_STREAMING_CHART_COLOR2,
      maxBarThickness: 50,
      yAxisID: 'B'
    });

  }

  private async updateOnlineTimeChart() {

    this.charts[ONLINE_TIME_CHART_INDEX].title = `${this.translate.instant('Total online time in')} ${this.yearDate.value}`;

    if (!this.charts[ONLINE_TIME_CHART_INDEX].options)
      this.charts[ONLINE_TIME_CHART_INDEX].options = {
        maintainAspectRatio: false,
        aspectRatio:4,
        scales: {
          A: {
            type: 'linear',
            position: 'left',
            ticks:{
              callback: v => this.secondsToDhms(v),
              color: this.darkService.isSetDark ? 'white' : ''
            },
          },
          x: {
            ticks: {
              color: this.darkService.isSetDark ? 'white' : ''
            }
          },
        },
        plugins: {
          tooltip:{
            callbacks: {
            label: context => {
              if (!context?.parsed.y)
                return '';

              return `${context?.dataset?.label ?? ''} : ${this.secondsToDhms(context.parsed.y)}`;
            }
          }
          },
          legend: {
            display: true,
            labels: {
              color: this.darkService.isSetDark ? 'white' : ''
            }
          }
        }
      };

    this.charts[ONLINE_TIME_CHART_INDEX].data = {
      labels: this.translateColumns(),
      datasets: []
    };

    this.charts[ONLINE_TIME_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(ONLINE_TIME_CHART_LABEL1)),
      data: this.onlineTimePerYear,
      backgroundColor: ONLINE_TIME_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

  }

  private async updateWebinarPerformedChart() {

    this.charts[WEBINAR_PERFORMED_CHART_INDEX].title = `${this.translate.instant('Webinar data')} ${this.yearDate.value}`;

    if (!this.charts[WEBINAR_PERFORMED_CHART_INDEX].options)
      this.charts[WEBINAR_PERFORMED_CHART_INDEX].options = {
        maintainAspectRatio: false,
          aspectRatio: 4,
          scales: {
            A: {
              type: 'linear',
              position: 'left',
              ticks: {
                color: LESSON_PERFORMED_CHART_COLOR1
              }
            },
            B: {
              type: 'linear',
              position: 'right',
              ticks: {
                color: LESSON_PERFORMED_CHART_COLOR2
              }
            },
            x: {
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          },
          plugins: {
            tooltip:{
              callbacks: {
                label: context => {
                  if (!context?.parsed.y)
                    return '';
    
                  return context.dataset.yAxisID === 'B' ?
                         `${context?.dataset?.label ?? ''} : ${this.secondsToDhms(context.parsed.y)}` :
                         `${context?.dataset?.label ?? ''} : ${context.parsed.y}`;
                }
              }
            },
            legend: {
              display: true,
              labels: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          }
        };

    this.charts[WEBINAR_PERFORMED_CHART_INDEX].data = {
      labels: this.translateColumns(),
      datasets: []
    };

    this.charts[WEBINAR_PERFORMED_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(WEBINAR_PERFORMED_CHART_LABEL1)),
      data: this.userWebinarPerformedPerYear,
      backgroundColor: LESSON_PERFORMED_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.charts[WEBINAR_PERFORMED_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(LESSON_PERFORMED_CHART_LABEL2)),
      data: this.userWebinarStreamingTimePerYear,
      backgroundColor: LESSON_PERFORMED_CHART_COLOR2,
      maxBarThickness: 50,
      yAxisID: 'B'
    });

  }

  private async updateConferencePerformedChart() {

    this.charts[CONFERENCE_PERFORMED_CHART_INDEX].title = `${this.translate.instant('Conferences data')} ${this.yearDate.value}`;

    if (!this.charts[CONFERENCE_PERFORMED_CHART_INDEX].options)
      this.charts[CONFERENCE_PERFORMED_CHART_INDEX].options = {
        maintainAspectRatio: false,
          aspectRatio: 4,
          scales: {
            A: {
              type: 'linear',
              position: 'left',
              ticks: {
                color: LESSON_PERFORMED_CHART_COLOR1
              }
            },
            B: {
              type: 'linear',
              position: 'right',
              ticks: {
                color: LESSON_PERFORMED_CHART_COLOR2
              }
            },
            x: {
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          },
          plugins: {
            tooltip:{
              callbacks: {
                label: context => {
                  if (!context?.parsed.y)
                    return '';
    
                  return context.dataset.yAxisID === 'B' ?
                         `${context?.dataset?.label ?? ''} : ${this.secondsToDhms(context.parsed.y)}` :
                         `${context?.dataset?.label ?? ''} : ${context.parsed.y}`;
                }
              }
            },
            legend: {
              display: true,
              labels: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          }
        };

    this.charts[CONFERENCE_PERFORMED_CHART_INDEX].data = {
      labels: this.translateColumns(),
      datasets: []
    };

    this.charts[CONFERENCE_PERFORMED_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(CONFERENCE_PERFORMED_CHART_LABEL1)),
      data: this.userConferencePerformedPerYear,
      backgroundColor: LESSON_PERFORMED_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.charts[CONFERENCE_PERFORMED_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(LESSON_PERFORMED_CHART_LABEL2)),
      data: this.userConferenceStreamingTimePerYear,
      backgroundColor: LESSON_PERFORMED_CHART_COLOR2,
      maxBarThickness: 50,
      yAxisID: 'B'
    });

  }

  private async updateLessonPerformedChart() {

    this.charts[LESSON_PERFORMED_CHART_INDEX].title = `${this.translate.instant('Lessons data')} ${this.yearDate.value}`;

    if (!this.charts[LESSON_PERFORMED_CHART_INDEX].options)
      this.charts[LESSON_PERFORMED_CHART_INDEX].options = {
        maintainAspectRatio: false,
          aspectRatio: 4,
          scales: {
            A: {
              type: 'linear',
              position: 'left',
              ticks: {
                color: LESSON_PERFORMED_CHART_COLOR1
              }
            },
            B: {
              type: 'linear',
              position: 'right',
              ticks: {
                color: LESSON_PERFORMED_CHART_COLOR2
              }
            },
            x: {
              ticks: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          },
          plugins: {
            tooltip:{
              callbacks: {
                label: context => {
                  if (!context?.parsed.y)
                    return '';
    
                  return context.dataset.yAxisID === 'B' ?
                         `${context?.dataset?.label ?? ''} : ${this.secondsToDhms(context.parsed.y)}` :
                         `${context?.dataset?.label ?? ''} : ${context.parsed.y}`;
                }
              }
            },
            legend: {
              display: true,
              labels: {
                color: this.darkService.isSetDark ? 'white' : ''
              }
            }
          }
        };

    this.charts[LESSON_PERFORMED_CHART_INDEX].data = {
      labels: this.translateColumns(),
      datasets: []
    };

    this.charts[LESSON_PERFORMED_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(LESSON_PERFORMED_CHART_LABEL1)),
      data: this.userLessonPerformedPerYear,
      backgroundColor: LESSON_PERFORMED_CHART_COLOR1,
      maxBarThickness: 50,
      yAxisID: 'A'
    });

    this.charts[LESSON_PERFORMED_CHART_INDEX].data.datasets.push({
      label: await firstValueFrom(this.translate.get(LESSON_PERFORMED_CHART_LABEL2)),
      data: this.userLessonStreamingTimePerYear,
      backgroundColor: LESSON_PERFORMED_CHART_COLOR2,
      maxBarThickness: 50,
      yAxisID: 'B'
    });

  }

}

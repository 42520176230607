<div style="margin-top: 10px">

    <input #fileInput type="file" [accept]="acceptedTypes" [hidden]="true">

    <button #mainBtn mat-button
        (click)="fileSelection()"
        [disabled]="control.disabled"
        [style.display]="fileSelected ? 'none' : 'block'"
        class="main-btn">
        <mat-icon>add</mat-icon>
    </button>

    <div *ngIf="showCropper" class="cropper-container"> <!-- [style.display]="showCropper ? 'block' : 'none'" -->

        <div style="display: flex">

            <image-cropper
                [imageBase64]="originalImg"
                [aspectRatio]="aspectRatio"
                [maintainAspectRatio]="aspectRatio != undefined"
                [cropperMinWidth]="100"
                [cropperMinHeight]="100"
                [cropper]="cropperPosition"
                [disabled]="control.disabled"
                (cropperChange)="cropper.position = $event"
                (imageCropped)="cropper.img = $event.blob"
                (imageLoaded)="cropperImageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="cropperLoadFailed()"
                output="blob"
                format="png">
            </image-cropper>

        </div>

        <div fxLayout="row" style="width: 400px">

            <button mat-button
                (click)="toggleCropper(false)"
                [disabled]="control.disabled"
                [matTooltip]="'Cancel' | translate"
                class="option-btn"
                style="width: 100%">
                <mat-icon>close</mat-icon>
            </button>

            <button mat-button
                (click)="saveCroppedImg()"
                [disabled]="control.disabled || cropper.img == undefined"
                [matTooltip]="'Save' | translate"
                class="option-btn"
                style="width: 100%">
                <mat-icon>done</mat-icon>
            </button>

        </div>

    </div>

    <div *ngIf="fileSelected && !showCropper">

        <div matRipple [matRippleDisabled]="control.disabled" class="file-selected-container">
            <img *ngIf="fileImg; else noFileImg" [src]="fileImg" loading="lazy" class="selected-image">
        </div>

        <div fxLayout="row" style="width: 160px">

            <button mat-button
                (click)="deleteFile()"
                [disabled]="control.disabled"
                [matTooltip]="'Delete' | translate"
                class="option-btn"
                fxFlex>
                <mat-icon>delete</mat-icon>
            </button>

            <button mat-button
                *ngIf="enableCrop && fileImg"
                (click)="toggleCropper(true)"
                [disabled]="control.disabled"
                [matTooltip]="'Crop' | translate"
                class="option-btn"
                fxFlex>
                <mat-icon>crop</mat-icon>
            </button>

        </div>

    </div>

    <div *ngIf="action && action !== 'accepted'">
        <mat-error *ngIf="action === 'rejected'">
            {{ rejectedErrorMessage }}
        </mat-error>
        <mat-error *ngIf="action === 'deleted' && control.hasError('required')">
            {{ deletedErrorMessage }}
        </mat-error>
    </div>

</div>

<ng-template #noFileImg>
    <mat-icon>insert_drive_file</mat-icon>
    <span class="selected-name">{{ fileName }}</span>
</ng-template>

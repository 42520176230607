import { UserDTO } from "./userDTO"

export class SocialWallLikeDTO { 
    id: number;
    likeIt: number;
    iduser: number;
    idsocialWall: number;
    state: number;
    created: Date;
    iduserNavigation: UserDTO = new UserDTO();
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  usernameOrEmail: FormControl<string> = new FormControl("", [Validators.required]);
  token: string = null;
  sending: boolean = false;

  newPasswordForm: FormGroup = new FormGroup(
    {
      newPassword: new FormControl<string>(null, [Validators.required, Validators.pattern(Helper.getValidPasswordRegex())]),
      newPasswordRepeat: new FormControl<string>(null, [Validators.required])
    },
    this.samePasswordValidator
  );

  isMobile: boolean = false;

  constructor(private user: UserService,
              private snackBar: MatSnackBar,
              private deviceService: DeviceDetectorService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private navBar: NavBarService) {
    this.isMobile = this.deviceService.isMobile();

    if (this.isMobile)
      this.navBar.hide('all');
  }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');

    if (this.token != null)
      this.checkToken();
  }

  ngOnDestroy(): void {
    this.navBar.show('topbar');
    this.navBar.show('bottombar');
  }

  sendCredential() {
    this.sending = true;

    this.usernameOrEmail.disable();

    firstValueFrom(this.user.forgotPassword(this.usernameOrEmail.value))
      .then(res => {

        this.snackBar.open("Request send", 'Dismiss', { duration: 3000 });
        this.router.navigate(['/login']);

      }).catch(err => {

        console.error(err);
        this.snackBar.open("Error sending request", 'Dismiss', { duration: 3000 });

      }).finally(() => {

        this.sending = false;
        this.usernameOrEmail.enable();

      });
  }

  checkToken() {
    firstValueFrom(this.user.forgotToken(this.token))
      .catch(err => {
        this.token = null;
        this.snackBar.open('The link provided is no more valid, please request another password change', 'Dismiss', { duration: 4000 });
      });
  }

  updatePassword() {
    this.sending = true;

    this.newPasswordForm.disable();

    firstValueFrom(this.user.forgotChangePassword(this.newPasswordForm.controls.newPassword.value, this.token))
      .then(async res => {

        this.snackBar.open('Password changed successfully', 'Dismiss', {
          duration: 3000,
          verticalPosition: 'bottom'
        });
    
        this.router.navigate(['/login']);

      }).catch(err => {

        console.error(err);

        this.snackBar.open(err.error.Message, 'Dismiss', {
          duration: 3000,
          verticalPosition: 'bottom'
        });

      }).finally(() => {

        this.sending = false;
        this.newPasswordForm.enable();

      });
  }

  samePasswordValidator(group: FormGroup) {
    if (!group)
      return null;

    let password = group.controls.newPassword;
    let passwordRepeat = group.controls.newPasswordRepeat;

    if (password.value !== passwordRepeat.value)
      passwordRepeat.setErrors({ different: true });
    else
      passwordRepeat.setErrors(null);

    return null;
  }

  goBack() {
    this.location.back();
  }

}

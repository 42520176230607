import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-graph-preview',
  templateUrl: './graph-preview.component.html',
  styleUrl: './graph-preview.component.scss'
})
export class GraphPreviewComponent {

  @Input()
  title: string = undefined;

  @Input()
  width: string = '200px';

  @Input()
  height: string = '100px';

  @Input()
  datasets: ChartDataset[] = [];
  
  @Input()
  labels: string[] = [];

  @Input()
  plugins: any[] = [];

  @Input()
  options: ChartOptions = undefined;

  @Input()
  type: string = undefined;

  @Output()
  onClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

}

<mat-card appearance="outlined" class="mat-elevation-z4" style="width: 100%; height: 100%;">
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between">
        <mat-card-title>
            {{ 'Contents' | translate }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table #contentsTable
            mat-table
            matSort #contentsSort="matSort"
            [dataSource]="dataSource"
            style="width: 100%;">

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Type' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon [matTooltip]="getLabel(element.type)">{{ getIcon(element.type) }}</mat-icon>
                </td>
            </ng-container>

            <!--ng-container matColumnDef="author">
                <th mat-header-cell *matHeaderCellDef>{{ 'Author' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.author.name + ' ' + element.author.surname }}
                </td>
            </ng-container-->

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 5px">
                        <div>
                            <app-profile-picture [url]="element.headerImageUrl" size="65px"></app-profile-picture>
                        </div>
                        <div fxLayoutAlign="center start">
                            {{ element.name?.length > 20 ? (element.name | slice:0:17) + '...' : element.name }}
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Description' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description?.length > 50 ? (element.description | slice:0:47) + '...' : element.description }}
                </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Created' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.created | date: 'dd/MM/yyyy' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>{{ 'View' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <div>

                        <button mat-icon-button
                            (click)="onView.emit(element)"
                            [matTooltip]="'Go to content' | translate">
                            <mat-icon>launch</mat-icon>
                        </button>

                        <button mat-icon-button
                            *ngIf="masterAuthorId === currentUser.id && element.provenance === 0 && !hideEdit"
                            (click)="onEdit.emit(element)"
                            [matTooltip]="'Edit' | translate">
                            <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button color="warn"
                            *ngIf="masterAuthorId === currentUser.id && element.provenance === 0 && !hideDelete"
                            (click)="onDelete.emit(element)"
                            [matTooltip]="'Delete' | translate">
                            <mat-icon>delete</mat-icon>
                        </button>

                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;">
            </tr>
        </table>
        <mat-paginator #lessonsPag 
            [length]="dataSource.data.length"
            [pageSize]="10"
            [pageSizeOptions]="[10, 20, 50]">
        </mat-paginator>
    </mat-card-content>
    <mat-card-actions></mat-card-actions>
</mat-card>
